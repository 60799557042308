import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { AsyncDispatch } from '@redux/types';
import { getGymsList, getSelectedGymId } from '@redux/modules/gyms/selectors';
import { fetchGymsList } from '@redux/modules/gyms/actions';
import BoxButton from '@components/BoxButton';
import Box from '@components/Box';
import Info from '@components/Icons/Info';
import { fetchGroupWorkoutsByGymId } from '@redux/modules/group-workouts/actions';
import { getGroupWorkouts } from '@redux/modules/group-workouts/selectors';

import styles from './styles.module.css';
import GymFilterForm from '../GymFilterForm';
import GroupWorkoutsTable from './GroupWorkoutsTable';

export const GroupWorkoutslist: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const groupWorkouts = useSelector(getGroupWorkouts);
    const gyms = useSelector(getGymsList);
    const selectedGymId = useSelector(getSelectedGymId);

    const gymsHasGroupWorkoutsOptions: {
        label: string;
        value: string;
    }[] = gyms.reduce((acc: { label: string; value: string }[], gym) => {
        if (!gym.isHidden && gym.hasGroupWorkouts) {
            acc.push({ label: gym.title, value: gym._id });
        }
        return acc;
    }, []);

    useEffect(() => {
        if (!gyms.length) {
            dispatch(fetchGymsList());
        }
    }, [dispatch]);

    useEffect(() => {
        if (!selectedGymId) {
            return;
        }
        dispatch(fetchGroupWorkoutsByGymId(selectedGymId)).catch(() => {
            cogoToast.error('Ошибка при загрузке групповых тренировок', {
                position: 'top-right',
                hideAfter: 4,
            });
        });
    }, [dispatch, selectedGymId]);

    const goToAddGroupWorkout = useCallback(
        () => history.push(`/dashboard/group-workouts/add`),
        [history]
    );

    const goToGroupWorkouts = useCallback(
        () => history.push('/dashboard/group-workouts'),
        [history]
    );

    const goToCalendar = useCallback(
        () => history.push('/dashboard/group-workouts/calendar'),
        [history]
    );

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Групповые тренировки</h1>
            <div className={styles.wrap}>
                <GymFilterForm gyms={gymsHasGroupWorkoutsOptions} />
                <button
                    onClick={goToAddGroupWorkout}
                    type="button"
                    className={styles.btnAdd}
                >
                    Создать тренировку
                </button>
            </div>
            <div>
                <div className={styles.nav}>
                    <BoxButton
                        icon=""
                        title="Тренировки"
                        onClick={goToGroupWorkouts}
                        className={styles.actionNav}
                    />
                    <BoxButton
                        icon=""
                        title="Календарь"
                        onClick={goToCalendar}
                        className={styles.actionBtn}
                    />
                </div>

                <Box>
                    {!selectedGymId && (
                        <div className={styles.info}>
                            <Info /> Для просмотра тренировок выберите
                            фитнес-площадку
                        </div>
                    )}

                    {selectedGymId && (
                        <div className={styles.tableWrapper}>
                            <GroupWorkoutsTable groupWorkouts={groupWorkouts} />
                        </div>
                    )}
                </Box>
            </div>
        </div>
    );
};

export default GroupWorkoutslist;
