import React from 'react';
import moment from 'moment';
import { useTable, usePagination, useSortBy, Column } from 'react-table';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Booking, BookingType } from '@t/booking';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import LoaderVertical from '@components/LoaderVertical';

import styles from './styles.module.css';

export interface Props {
    bookings: Booking[];
    loading: boolean;
}

export const getDuration = (end: string, start: string): number =>
    Math.round(moment.duration(moment(end).diff(moment(start))).asMinutes());

export const isValidDate = (value: Date): boolean =>
    value?.toLocaleString() !== 'Invalid Date';

const columns: Column<Booking>[] = [
    {
        Header: 'Фитнес площадка',
        accessor: 'gymTitle',
    },
    {
        Header: 'Дата начала',
        id: 'timeStarted',
        accessor: (booking: Booking) =>
            !booking.timeStarted
                ? new Date('')
                : new Date(
                      new Date(booking.timeStarted).getTime() +
                          new Date(booking.timeStarted).getTimezoneOffset() *
                              60000
                  ),
        // @ts-ignore
        sortType: 'datetime',
        Cell: ({ cell: { value } }: any) =>
            isValidDate(value) ? value.toLocaleString() : '',
    },
    {
        Header: 'Дата завершения',
        id: 'timeFinished',
        accessor: (booking: Booking) =>
            !booking.timeFinished
                ? new Date(' ')
                : booking.timeFinishedForSubscribe
                ? new Date(
                      new Date(booking.timeFinishedForSubscribe).getTime() +
                          new Date(
                              booking.timeFinishedForSubscribe
                          ).getTimezoneOffset() *
                              60000
                  )
                : new Date(
                      new Date(booking.timeFinished).getTime() +
                          new Date(booking.timeFinished).getTimezoneOffset() *
                              60000
                  ),
        // @ts-ignore
        sortType: 'datetime',
        Cell: ({ cell: { value } }: any) =>
            isValidDate(value) ? value.toLocaleString() : '',
    },
    {
        Header: 'Длительность',
        accessor: (booking: Booking) =>
            !booking.timeFinished
                ? ''
                : booking.timeFinishedForSubscribe
                ? `${getDuration(
                      booking.timeFinishedForSubscribe,
                      booking.timeStarted
                  )} мин`
                : `${getDuration(
                      booking.timeFinished,
                      booking.timeStarted
                  )} мин`,
    },
    {
        Header: 'Статус оплаты',
        accessor: (booking: Booking) => {
            return {
                title:
                    booking?.membershipId && booking.paid
                        ? 'Абонемент'
                        : booking.paid
                        ? 'Оплачено'
                        : 'Не оплачено',
                styleClass:
                    !booking.paid && !booking.active
                        ? styles.unpaid
                        : styles.paid,
            };
        },
        Cell: ({ cell: { value } }: any) => (
            <div className={value.styleClass}>{value.title}</div>
        ),
    },
    {
        Header: 'Статус',
        accessor: (booking: Booking) => {
            return booking.active ? 'В процессе' : 'Не активна';
        },
        Cell: ({ cell: { value } }: any) => <div>{value}</div>,
    },
    {
        Header: 'Цена',
        accessor: (booking: Booking) =>
            !booking.timeFinished
                ? ' '
                : `${
                      booking.initialPriceForSubscribe
                          ? booking.initialPriceForSubscribe
                          : booking.couponApplied ||
                            booking.isBonusWithdrawn ||
                            booking.prepayment ||
                            booking.type === BookingType.GroupWorkout
                          ? booking.initialPrice
                          : booking.price
                  }₽`,
    },
];

const columnsForAdmin: Column<Booking>[] = [
    {
        Header: 'Фитнес площадка',
        accessor: 'gymTitle',
    },
    {
        Header: 'Дата начала',
        id: 'timeStarted',
        accessor: (booking: Booking) =>
            !booking.timeStarted
                ? new Date('')
                : new Date(
                      new Date(booking.timeStarted).getTime() +
                          new Date(booking.timeStarted).getTimezoneOffset() *
                              60000
                  ),
        // @ts-ignore
        sortType: 'datetime',
        Cell: ({ cell: { value } }: any) =>
            isValidDate(value) ? value.toLocaleString() : '',
    },
    {
        Header: 'Дата завершения',
        id: 'timeFinished',
        accessor: (booking: Booking) =>
            !booking.timeFinished
                ? new Date(' ')
                : booking.timeFinishedForSubscribe
                ? new Date(
                      new Date(booking.timeFinishedForSubscribe).getTime() +
                          new Date(
                              booking.timeFinishedForSubscribe
                          ).getTimezoneOffset() *
                              60000
                  )
                : new Date(
                      new Date(booking.timeFinished).getTime() +
                          new Date(booking.timeFinished).getTimezoneOffset() *
                              60000
                  ),
        // @ts-ignore
        sortType: 'datetime',
        Cell: ({ cell: { value } }: any) =>
            isValidDate(value) ? value.toLocaleString() : '',
    },
    {
        Header: 'Длительность',
        accessor: (booking: Booking) =>
            !booking.timeFinished
                ? ''
                : booking.timeFinishedForSubscribe
                ? `${getDuration(
                      booking.timeFinishedForSubscribe,
                      booking.timeStarted
                  )} мин`
                : `${getDuration(
                      booking.timeFinished,
                      booking.timeStarted
                  )} мин`,
    },
    {
        Header: 'Статус оплаты',
        accessor: (booking: Booking) => {
            return {
                title:
                    booking?.membershipId && booking.paid
                        ? 'Абонемент'
                        : booking?.subscriptionId && booking.paid
                        ? 'Подписка'
                        : booking.paid
                        ? 'Оплачено'
                        : 'Не оплачено',
                styleClass:
                    !booking.paid && !booking.active
                        ? styles.unpaid
                        : styles.paid,
            };
        },
        Cell: ({ cell: { value } }: any) => (
            <div className={value.styleClass}>{value.title}</div>
        ),
    },
    {
        Header: 'Статус',
        accessor: (booking: Booking) => {
            return booking.active ? 'В процессе' : 'Не активна';
        },
        Cell: ({ cell: { value } }: any) => <div>{value}</div>,
    },
    {
        Header: 'Цена',
        accessor: (booking: Booking) =>
            !booking.timeFinished
                ? ' '
                : `${
                      booking.initialPriceForSubscribe
                          ? booking.initialPriceForSubscribe
                          : booking.couponApplied ||
                            booking.isBonusWithdrawn ||
                            booking.prepayment ||
                            booking.type === BookingType.GroupWorkout
                          ? booking.initialPrice
                          : booking.price
                  }₽`,
    },
    {
        Header: 'Цена со скидкой',
        accessor: (booking: Booking) =>
            !booking.timeFinished
                ? ' '
                : booking.initialPrice - booking.price === 0
                ? '一'
                : `${booking.price}₽`,
    },
];

export const BookingListTable: React.FC<Props> = ({ bookings, loading }) => {
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable(
        {
            columns: isSuperAdmin ? columnsForAdmin : columns,
            data: bookings || [],
            initialState: {
                pageIndex: 0,
                pageSize: 10000,
            },
        },
        useSortBy,
        usePagination
    );
    const history = useHistory();

    return (
        <>
            <table {...getTableProps()} className={styles.table}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={Math.random()}
                        >
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                    key={Math.random()}
                                >
                                    {column.render('Header')}
                                    <span className={styles.sorted}>
                                        <FontAwesomeIcon
                                            icon={
                                                column.isSorted
                                                    ? column.isSortedDesc
                                                        ? 'sort-up'
                                                        : 'sort-down'
                                                    : 'sort'
                                            }
                                        />
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        const onClick = () => {
                            const booking = row.original;
                            history.push(`/dashboard/bookings/${booking._id}`);
                        };
                        return (
                            <tr
                                {...row.getRowProps()}
                                className={styles.tableRow}
                                onClick={onClick}
                                key={Math.random()}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            key={Math.random()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {loading && <LoaderVertical />}
        </>
    );
};

export default BookingListTable;
