import cogoToast from 'cogo-toast';

const MAX_SIZE = 300000;

export const useAddGoGymSubscription = () => {
    const onUploadFile = (event: any) => {
        const numOfFiles = Array.from(event.target.files).length;
        for (let i = 0; i < numOfFiles; ++i) {
            const validExtensions = ['png', 'svg+xml'];
            const fileExtension = event.target.files[i].type.split('/')[1];
            const fileSize = event.target.files[i].size;

            if (!validExtensions.includes(fileExtension)) {
                event.preventDefault();
                cogoToast.error(
                    `Можно загрузить файлы с расширением png, svg`,
                    {
                        position: 'top-right',
                        hideAfter: 4,
                    }
                );
                event.target.value = null;
                return;
            }

            if (fileSize > MAX_SIZE) {
                event.preventDefault();
                cogoToast.error(
                    `Нельзя загрузить файл размера более 300KB, файл "${event.target.files[i].name}" слишком большой`,
                    {
                        position: 'top-right',
                        hideAfter: 4,
                    }
                );
                event.target.value = null;
                return;
            }
        }
    };

    return {
        onUploadFile,
    };
};
