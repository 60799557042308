import React from 'react';
import classNames from 'classnames';

import GoGym from '../Icons/GoGym';
import styles from './styles.module.css';

export interface BrandLogoProps {
    className?: string;
}

export const BrandLogo: React.FC<BrandLogoProps> = ({ className }) => (
    <div className={classNames(styles.logo, className)}>
        <GoGym />
        <span className={styles.logoTitle}>GOGYM</span>
        <span className={styles.logoNote}>business</span>
    </div>
);

export default BrandLogo;
