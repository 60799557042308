import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.css';

export const DEFAULT_MAP_CENTER: google.maps.LatLngLiteral = {
    lat: 55.7610334,
    lng: 37.6424772,
};

export interface Props {
    coordinate: google.maps.LatLngLiteral;
    className?: string;
    onCoordinateUpdate: (coordinate: google.maps.LatLngLiteral) => void;
}

export class GoogleMap extends React.PureComponent<Props> {
    private googleMapRef = React.createRef<HTMLDivElement>();
    private googleMap: null | google.maps.Map<HTMLDivElement> = null;
    private markers: google.maps.Marker[] = [];

    componentDidMount() {
        const { onCoordinateUpdate, coordinate } = this.props;

        this.googleMap = this.createGoogleMap();

        const marker = this.createMarker(
            coordinate,
            this.googleMap as google.maps.Map
        );
        marker.addListener('dragend', ({ latLng }) => {
            const position = latLng.toJSON();
            onCoordinateUpdate(position);
        });
        this.markers.push(marker);
    }

    componentDidUpdate(prevProps: Props) {
        const { onCoordinateUpdate, coordinate } = this.props;

        if (!this.googleMap) {
            return;
        }

        if (
            coordinate.lat === prevProps.coordinate.lat &&
            coordinate.lng === prevProps.coordinate.lng
        ) {
            return;
        }

        this.markers.forEach((marker) => {
            marker.setMap(null);
        });
        this.markers.length = 0;

        this.googleMap.setCenter(coordinate);
        const marker = this.createMarker(coordinate, this.googleMap);
        marker.addListener('dragend', ({ latLng }) => {
            const position = latLng.toJSON();
            onCoordinateUpdate(position);
        });
        this.markers.push(marker);
    }

    createGoogleMap = () => {
        const { coordinate } = this.props;
        const node = this.googleMapRef?.current;

        if (!node) {
            return null;
        }

        return new window.google.maps.Map(node, {
            zoom: 16,
            center: coordinate || DEFAULT_MAP_CENTER,
            disableDefaultUI: false,
        });
    };

    createMarker = (
        position: google.maps.LatLngLiteral,
        map: google.maps.Map
    ) =>
        new window.google.maps.Marker({
            position,
            map,
            draggable: true,
        });

    render() {
        const { className } = this.props;

        return (
            <div
                ref={this.googleMapRef}
                className={classNames(styles.map, className)}
            />
        );
    }
}

export default GoogleMap;
