import React, { useState } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

import styles from './styles.module.css';
import Close from '@components/Icons/Close';

export interface Props {
    label: string;
    name: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    renderClearButton?: boolean;
}

const SingleDatePickerInput: React.FC<Props & WrappedFieldProps> = ({
    input,
    meta,
    name,
    label,
    disabled,
    readOnly,
    renderClearButton,
}) => {
    const [focusedInput, setFocusedInput] = useState<any>(false);
    const date = moment(input.value).isValid()
        ? moment(input.value).utcOffset(0)
        : null;

    const onDateChange = (date: moment.Moment | null) => {
        input.onChange(date?.toISOString());
    };

    const onClearChange = () => {
        input.onChange(null);
    };

    const onFocusChange = ({ focused }: { focused: boolean }) =>
        setFocusedInput(focused);
    return (
        <div className={styles.wrapper}>
            <label htmlFor={name} className={styles.label}>
                {label}
            </label>
            <div className={styles.fieldWrapper}>
                <SingleDatePicker
                    date={date}
                    onDateChange={onDateChange}
                    focused={focusedInput}
                    onFocusChange={onFocusChange}
                    id="singleDatePicker"
                    disabled={disabled}
                    readOnly={readOnly}
                    placeholder={''}
                />
                {!disabled && date && renderClearButton && (
                    <div className={styles.clear} onClick={onClearChange}>
                        <Close />
                    </div>
                )}
            </div>

            {meta && meta.touched && meta.error && (
                <div className={styles.error}>{meta.error}</div>
            )}
        </div>
    );
};

export default SingleDatePickerInput;
