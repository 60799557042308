import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cogoToast from 'cogo-toast';

import { getReservations } from '@redux/modules/reservations/selectors';
import {
    fetchReservations,
    fetchReservationsReport,
    fetchScrollReservations,
} from '@redux/modules/reservations/actions';
import Box from '@components/Box';
import { getSelectedGymId } from '@redux/modules/gyms/selectors';
import BoxButton from '@components/BoxButton';
import Toggle from '@components/Toggle';
import { AsyncDispatch } from '@redux/types';

import ReservationsListTable from './ReservationsListTable';
import styles from './styles.module.css';

export const ReservationsList: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const reservations = useSelector(getReservations);
    const selectedGymId = useSelector(getSelectedGymId);
    const [isToggled, setIsToggled] = useState<boolean>(false);
    const [fetching, setFetching] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [generateReport, setGenerateReport] = useState<boolean>(false);
    const [reservationsIsOver, setReservationsIsOver] = useState<boolean>(
        false
    );

    useEffect(() => {
        dispatch(fetchReservations({ selectedGymId, isToggled, count: 0 }))
            .then((reservations) => {
                if (reservations.length < 99) {
                    setLoading(false);
                    setReservationsIsOver(true);
                } else {
                    setLoading(false);
                    setReservationsIsOver(false);
                }
            })
            .catch(() => {
                cogoToast.error('Ошибка при загрузке бронирований', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            })
            .finally(() => setFetching(false));
    }, [dispatch, selectedGymId, isToggled]);

    const scrollHandler = (event: Event) => {
        if (
            (event.target as Document).documentElement.scrollHeight -
                ((event.target as Document).documentElement.scrollTop +
                    window.innerHeight) <
                500 &&
            (event.target as Document).documentElement.scrollTop > 1000
        ) {
            setFetching(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    useEffect(() => {
        if (fetching && !reservationsIsOver) {
            setLoading(true);
            dispatch(
                fetchScrollReservations({
                    selectedGymId,
                    isToggled,
                    count: reservations.length,
                })
            )
                .then((reservations) => {
                    if (reservations.length < 99) {
                        setLoading(false);
                        setReservationsIsOver(true);
                    } else {
                        setLoading(false);
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при загрузке бронирований', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => setFetching(false));
        }
    }, [dispatch, selectedGymId, fetching]);

    const generateReportReservationsList = () => {
        const { hide } = cogoToast.loading(
            'Формируем отчет по тренировкам...',
            {
                position: 'top-right',
                hideAfter: 0,
            }
        );
        setGenerateReport(true);
        dispatch(fetchReservationsReport())
            .then(() => {
                cogoToast.success('Отчет по тренировкам готов', {
                    position: 'top-right',
                    hideAfter: 5,
                });
            })
            .catch(() => {
                cogoToast.error('Ошибка при формирования отчета', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            })
            .finally(() => {
                hide && hide();
                setGenerateReport(false);
            });
    };

    return (
        <div>
            <h1>Бронирования групповых тренировок</h1>
            <h3>Действия</h3>
            <div className={styles.actions}>
                <BoxButton
                    icon="spreadsheet"
                    title={
                        generateReport
                            ? 'Формируем отчет...'
                            : 'Выгрузить данные'
                    }
                    onClick={() => generateReportReservationsList()}
                    className={styles.actionBtn}
                    disabled={generateReport}
                />
                <div className={styles.toggle}>
                    <p className={styles.toggleTitle}>
                        Показать все бронирования
                    </p>

                    <Toggle
                        isToggled={isToggled}
                        onToggle={() => {
                            setIsToggled(!isToggled);
                        }}
                    />
                </div>
            </div>
            <Box>
                <div className={styles.tableWrapper}>
                    <ReservationsListTable
                        reservations={reservations}
                        loading={loading}
                    />
                </div>
            </Box>
        </div>
    );
};

export default ReservationsList;
