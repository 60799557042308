import { AdCampaign, Coupon } from '@t/coupon';

export const FETCH_COUPONS_REQUEST = 'FETCH_COUPONS_REQUEST';
export const FETCH_COUPONS_SUCCESS = 'FETCH_COUPONS_SUCCESS';
export const FETCH_COUPONS_ERROR = 'FETCH_COUPONS_ERROR';

export const FETCH_SCROLL_COUPONS_REQUEST = 'FETCH_SCROLL_COUPONS_REQUEST';
export const FETCH_SCROLL_COUPONS_SUCCESS = 'FETCH_SCROLL_COUPONS_SUCCESS';
export const FETCH_SCROLL_COUPONS_ERROR = 'FETCH_SCROLL_COUPONS_ERROR';

export const FETCH_COUPON_REQUEST = 'FETCH_COUPON_REQUEST';
export const FETCH_COUPON_SUCCESS = 'FETCH_COUPON_SUCCESS';
export const FETCH_COUPON_ERROR = 'FETCH_COUPON_ERROR';

export const CREATE_COUPON_REQUEST = 'CREATE_COUPON_REQUEST';
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS';
export const CREATE_COUPON_ERROR = 'CREATE_COUPON_ERROR';

export const DELETE_COUPON_REQUEST = 'DELETE_COUPON_REQUEST';
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';
export const DELETE_COUPON_ERROR = 'DELETE_COUPON_ERROR';

export const UPDATE_COUPON_REQUEST = 'UPDATE_COUPON_REQUEST';
export const UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCESS';
export const UPDATE_COUPON_ERROR = 'UPDATE_COUPON_ERROR';

export const FETCH_COUPONS_AD_CAMPAIGNS_REQUEST =
    'FETCH_COUPONS_AD_CAMPAIGNS_REQUEST';
export const FETCH_COUPONS_AD_CAMPAIGNS_SUCCESS =
    'FETCH_COUPONS_AD_CAMPAIGNS_SUCCESS';
export const FETCH_COUPONS_AD_CAMPAIGNS_ERROR =
    'FETCH_COUPONS_AD_CAMPAIGNS_ERROR';

export interface CouponState {
    ids: string[];
    byId: {
        [id: string]: Coupon;
    };
    couponsAdCampaigns: AdCampaign[];
    lastUpdated: number;
}
