import React from 'react';
import { InjectedFormProps, Field, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';

import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import TextInput from '@components/TextInput';
import TextAreaInput from '@components/TextAreaInput';

import styles from './styles.module.css';

export interface MembershipFormData {
    userId: string;
    membershipId: string;
    gymTitle: string;
    dateStart: string;
    dateFinish: string;
    type: string;
    duration: string;
    status: string;
    price: number;
}

const MembershipForm: React.FC<InjectedFormProps<MembershipFormData>> = () => {
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;
    return (
        <form className={styles.form}>
            <Field
                disabled
                name="userId"
                label="ID клиента"
                component={TextInput}
            />

            <Field
                disabled
                name="membershipId"
                label="ID абонемента"
                component={TextInput}
            />

            {isSuperAdmin && (
                <Field
                    disabled
                    name="appUserFirstName"
                    label="Имя"
                    component={TextInput}
                />
            )}

            {isSuperAdmin && (
                <Field
                    disabled
                    name="appUserLastName"
                    label="Фамилия"
                    component={TextInput}
                />
            )}

            {isSuperAdmin && (
                <Field
                    disabled
                    name="appUserEmail"
                    label="Email"
                    component={TextInput}
                />
            )}

            {isSuperAdmin && (
                <Field
                    disabled
                    name="appUserPhone"
                    label="Телефон"
                    component={TextInput}
                />
            )}

            {isSuperAdmin && (
                <Field
                    disabled
                    name="passportData"
                    label="Паспортные данные"
                    component={TextAreaInput}
                />
            )}

            <Field
                disabled
                name="gymTitle"
                label="Фитнес-площадка"
                component={TextInput}
            />

            <Field
                disabled
                name="dateStart"
                label="Начало"
                component={TextInput}
            />

            <Field
                disabled
                name="dateFinish"
                label="Конец"
                component={TextInput}
            />

            <Field disabled name="type" label="Тип" component={TextInput} />

            <Field
                disabled
                name="duration"
                label="Длительность"
                component={TextInput}
            />

            <Field disabled name="rest" label="Остаток" component={TextInput} />

            <Field
                disabled
                name="status"
                label="Статус"
                component={TextInput}
            />

            <Field disabled name="price" label="Цена" component={TextInput} />
        </form>
    );
};

export default reduxForm<MembershipFormData>({})(MembershipForm);
