import React from 'react';
import Select, { createFilter } from 'react-select';
import { ErrorMessage } from 'formik';
import styles from './styles.module.css';

type OptionValue = { label: string; value: string };

interface SelectFieldProps {
    label: string;
    name: string;
    placeholder: string;
    onChange: (optionData: OptionValue | OptionValue[] | any) => void;
    isMulti: boolean;
    options: OptionValue[];
    defaultValue: OptionValue | OptionValue[] | null;
}

export const SelectField: React.FC<SelectFieldProps> = ({
    label,
    name,
    placeholder,
    onChange,
    isMulti,
    options,
    defaultValue,
}) => {
    return (
        <div className={styles.selectContainer}>
            <span className={styles.selectLabel}>{label}</span>

            <Select
                className={styles.select}
                styles={{
                    input: (provided) => ({
                        ...provided,
                        minHeight: 40,
                        borderRadius: '0px 0px 20px 20px',
                    }),
                }}
                isMulti={isMulti}
                onChange={onChange}
                options={options}
                defaultValue={defaultValue}
                filterOption={createFilter({
                    stringify: (option) => `${option.label}`,
                })}
                placeholder={placeholder}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: 'rgb(225, 129, 65)',
                    },
                })}
                noOptionsMessage={() => 'Не найдено'}
            />
            <ErrorMessage
                className={styles.error}
                name={name}
                component="div"
            />
        </div>
    );
};
