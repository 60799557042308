import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { UserRole } from '@t/user';
import Box from '@components/Box';
import BoxButton from '@components/BoxButton';
import { fetchTagCategories, fetchTags } from '@redux/modules/tags/actions';
import { getTagCategories, getTags } from '@redux/modules/tags/selectors';
import { getUser } from '@redux/modules/auth/selectors';

import TagListTable from './TagListTable';
import styles from './styles.module.css';

export const TagList: React.FC = () => {
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;
    const dispatch = useDispatch();
    const history = useHistory();
    const tags = useSelector(getTags);
    const tagCategories = useSelector(getTagCategories);

    useEffect(() => {
        dispatch(fetchTags());
        dispatch(fetchTagCategories());
    }, [dispatch]);

    const onAddTagBtnClick = useCallback(
        () => history.push('/dashboard/add-tag'),
        [history]
    );

    const tagsWithCategories = tags.map((tag) => ({
        ...tag,
        categories: tagCategories.filter((category) =>
            tag?.categoryIds?.includes(category._id)
        ),
    }));

    return (
        <div>
            {isSuperAdmin && (
                <>
                    <h1>Тэги</h1>
                    <h3>Действия</h3>
                    <div className={styles.actions}>
                        <BoxButton
                            icon="gym"
                            title="Добавить тэг"
                            onClick={onAddTagBtnClick}
                            className={styles.actionBtn}
                        />
                    </div>

                    <Box className={styles.container}>
                        <div className={styles.tableWrapper}>
                            <TagListTable tags={tagsWithCategories} />
                        </div>
                    </Box>
                </>
            )}
        </div>
    );
};

export default TagList;
