import React, { useState, useCallback } from 'react';
import {
    Formik,
    Field,
    Form,
    ErrorMessage,
    FieldArray,
    FieldProps,
} from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import cogoToast from 'cogo-toast';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import ReactInputMask from 'react-input-mask';

import {
    SpecialPrice,
    SubwayRoute,
    WeekDaysOptions,
    WorkingHours,
} from '@t/gym';
import { AsyncDispatch } from '@redux/types';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import GeoInputYandexNew from '@components/GeoInputYandexNew';
import styles from './styles.module.css';
import Select, { createFilter } from 'react-select';
import Image from '@components/Icons/Image';
import Downloading from '@components/Icons/Downloading';
import { ButtonNew } from '@components/ButtonNew';
import ModalPhoto from '@components/ModalPhoto';
import ModalDelete from '@components/Modal';
import ModalGroupWorkout from '@components/Modal';
import { selectGym, uploadImage } from '@redux/modules/gyms/actions';
import CityInputNew from '@components/CityInputNew';
import QuestionMark from '@components/Icons/QuestionMark';
import Delete from '@components/Icons/Delete';
import { TIME_FORMAT } from '@config';

import UploadIcon from '../UploadIcon';

export interface FormData {
    hasPerMinWorkouts: boolean;
    hasGroupWorkouts: boolean;
    hasSingleWorkouts: boolean;
    withSingleWorkoutsOption: boolean;
    withGroupWorkoutsOption: boolean;
    hasMinWorkoutDuration: boolean;
    title: string;
    authorized: boolean;
    deleted: boolean;
    address: string;
    description: string;
    descriptionLong: string;
    geo: {
        address: string;
        shortAddress: string;
        location: google.maps.LatLngLiteral;
    };
    social: {
        instagram: string;
        vk: string;
        facebook: string;
        telegram: string;
    };
    imagesSrc: Array<string>;
    specialPrices: Array<SpecialPrice>;
    subwayRoutes: Array<SubwayRoute>;
    workingHours: WorkingHours[];
    fixedPrice: number;
    minWorkoutDuration: number;
    singleWorkoutPriceInfo: {
        weekDayStartTime: Date;
        weekDayFinishTime: Date;
        weekDayPrice: number;
        weekDayOffPeakStartTime: Date;
        weekDayOffPeakFinishTime: Date;
        weekDayOffPeakPrice: number;
        weekendStartTime: Date;
        weekendFinishTime: Date;
        weekendPrice: number;
        weekendOffPeakStartTime: Date;
        weekendOffPeakFinishTime: Date;
        weekendOffPeakPrice: number;
    };
    tags: {
        dayAndNight?: boolean;
        drinkingWater?: boolean;
        towels?: boolean;
        pilates?: boolean;
        crossfit?: boolean;
        yoga?: boolean;
        stretching?: boolean;
        combatZone?: boolean;
        finnishSauna?: boolean;
        turkishHammam?: boolean;
        fitnessBar?: boolean;
        swimmingPool?: boolean;
        childrenRoom?: boolean;
        parking?: boolean;
        cardioEquipment?: boolean;
        shower?: boolean;
    };
    services: string[];
    benefits: string[];
    website: string;
    phone: string;
    hasWeekendPrimeTime: boolean;
    hasWeekDayPrimeTime: boolean;
    legalEntityId: string;
    managersId: string[];
    managersIds: string[];
    hasPassport: boolean;
    isHidden: boolean;
    city: { title: string; id: string };
    test: boolean;
    yclientsId: string;
    discountFromYclientsPrice: number;
    prepaymentPercentFromYclientsPrice: number;
    docs: string[];
    seasonal: boolean;
    prepayment: number;
}

export interface Props {
    onSubmit: any;
    initialValues: any;
    legalEntitysOptions: {
        label: string;
        value: string;
    }[];
    AllManagersOptions: {
        label: string;
        value: string;
    }[];
    gymServicesOptions: {
        label: string;
        value: string;
    }[];
    gymAdvantagesOptions: {
        label: string;
        value: string;
    }[];
    onDeleteImageClick: any;
    onDropFiles: any;
    getRootPropsFiles: any;
    getInputPropsFiles: any;
    isDragActiveFiles: any;
    modalFileActive: any;
    setModalFileActive: any;
    onDeleteDocsClick: any;
    onDelete: any;
    gymId: string;
    loading: boolean;
}

const isEmptyError = (errors: { [i: number]: string }) => {
    for (const key in errors) {
        if (
            Object.prototype.hasOwnProperty.call(errors, key) &&
            Object.keys(errors[key]).length !== 0
        ) {
            return false;
        }
    }
    return true;
};

const validate = (values: any) => {
    const errors: {
        title: string;
        description: string;
        fixedPrice: string;
        minWorkoutDuration: string;
        geo: string;
        city: string;
        discountFromYclientsPrice: string;
        prepaymentPercentFromYclientsPrice: string;
        subwayRoutes: any;
        specialPrices: any;
        workingHours: any;
        phone: string;
        prepayment: string;
        shortAddress: string;
    } = {
        title: '',
        description: '',
        fixedPrice: '',
        minWorkoutDuration: '',
        geo: '',
        city: '',
        discountFromYclientsPrice: '',
        prepaymentPercentFromYclientsPrice: '',
        subwayRoutes: {},
        specialPrices: {},
        workingHours: {},
        phone: '',
        prepayment: '',
        shortAddress: '',
    };

    if (!values.title) {
        errors.title = 'Обязательное поле';
    }

    if (!values.title.replace(/\s/g, '')) {
        errors.title = 'Обязательное поле';
    }

    if (!values.description) {
        errors.description = 'Обязательное поле';
    }

    if (!values.description.replace(/\s/g, '')) {
        errors.description = 'Обязательное поле';
    }

    if (
        values.hasPerMinWorkouts &&
        (!Number.isInteger(Number(values.fixedPrice)) ||
            Number(values.fixedPrice) <= 0 ||
            String(values.fixedPrice).replace(/^\d+$/g, '').length)
    ) {
        errors.fixedPrice = 'Должно быть целым числом';
    }

    if (values.hasPerMinWorkouts && !values.fixedPrice) {
        errors.fixedPrice = 'Обязательное поле';
    }

    if (!values.city.title && !values.city.id) {
        errors.city = 'Обязательное поле';
    }

    if (!values.geo.address) {
        errors.geo = 'Обязательное поле';
    }

    if (
        values.hasMinWorkoutDuration &&
        (!Number.isInteger(Number(values.minWorkoutDuration)) ||
            Number(values.minWorkoutDuration) <= 0 ||
            String(values.minWorkoutDuration).replace(/^\d+$/g, '').length)
    ) {
        errors.minWorkoutDuration = 'Должно быть числом';
    }

    if (values.hasMinWorkoutDuration && !values.minWorkoutDuration) {
        errors.minWorkoutDuration = 'Обязательное поле';
    }

    if (
        !!values.yclientsId &&
        (!Number.isInteger(Number(values.discountFromYclientsPrice)) ||
            String(values.discountFromYclientsPrice).replace(/^\d+$/g, '')
                .length)
    ) {
        errors.discountFromYclientsPrice = 'Введите число от 0 до 100';
    }

    if (
        !!values.yclientsId &&
        (!Number.isInteger(Number(values.prepaymentPercentFromYclientsPrice)) ||
            String(values.prepaymentPercentFromYclientsPrice).replace(
                /^\d+$/g,
                ''
            ).length)
    ) {
        errors.prepaymentPercentFromYclientsPrice = 'Введите число от 0 до 100';
    }

    if (!!values.yclientsId && Number(values.discountFromYclientsPrice) > 100) {
        errors.discountFromYclientsPrice = 'Введите число от 0 до 100';
    }

    if (
        !!values.yclientsId &&
        Number(values.prepaymentPercentFromYclientsPrice) < 0
    ) {
        errors.prepaymentPercentFromYclientsPrice = 'Введите число от 0 до 100';
    }

    if (
        !Number.isInteger(Number(values.prepayment)) ||
        String(values.prepayment).replace(/^\d+$/g, '').length ||
        Number(values.prepayment) < 50 ||
        Number(values.prepayment) > 100
    ) {
        errors.prepayment = 'Укажите размер предоплаты от 50% до 100%';
    }

    if (!values.prepayment) {
        errors.prepayment = 'Обязательное поле';
    }

    if (!values?.shortAddress) {
        errors.shortAddress = 'Обязательное поле';
    }

    values.subwayRoutes.forEach((subwayRoute: SubwayRoute, i: number) => {
        if (!subwayRoute.station) {
            errors.subwayRoutes[i] = {
                ...errors.subwayRoutes[i],
                station: 'Обязательное поле',
            };
        }

        if (
            !Number.isInteger(Number(subwayRoute.duration)) ||
            Number(subwayRoute.duration) <= 0 ||
            String(subwayRoute.duration).replace(/^\d+$/g, '').length
        ) {
            errors.subwayRoutes[i] = {
                ...errors.subwayRoutes[i],
                duration: 'Должно быть числом',
            };
        }

        if (!subwayRoute.duration) {
            errors.subwayRoutes[i] = {
                ...errors.subwayRoutes[i],
                duration: 'Обязательное поле',
            };
        }
    });

    values.specialPrices.forEach((specialPrice: SpecialPrice, i: number) => {
        if (!String(specialPrice.weekDay)) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                weekDay: 'Обязательное поле',
            };
        }

        if (!specialPrice.timeStart) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                timeStart: 'Обязательное поле',
            };
        }

        if (!specialPrice.timeFinish) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                timeFinish: 'Обязательное поле',
            };
        }

        if (
            !Number.isInteger(Number(specialPrice.price)) ||
            Number(specialPrice.price) <= 0 ||
            String(specialPrice.price).replace(/^\d+$/g, '').length
        ) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                price: 'Должно быть числом',
            };
        }

        if (!specialPrice.price) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                price: 'Обязательное поле',
            };
        }

        if (
            moment(specialPrice.timeStart).utcOffset(0).format(TIME_FORMAT) ===
                '00:00' &&
            moment(specialPrice.timeFinish).utcOffset(0).format(TIME_FORMAT) ===
                '00:00'
        ) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                timeStart: 'Обязательное поле',
                timeFinish: 'Обязательное поле',
            };
        }
    });

    values.workingHours.forEach((workingHour: WorkingHours, i: number) => {
        if (!String(workingHour.weekDay)) {
            errors.workingHours[i] = {
                ...errors.workingHours[i],
                weekDay: 'Обязательное поле',
            };
        }

        if (!workingHour.timeStart) {
            errors.workingHours[i] = {
                ...errors.workingHours[i],
                timeStart: 'Обязательное поле',
            };
        }

        if (!workingHour.timeFinish) {
            errors.workingHours[i] = {
                ...errors.workingHours[i],
                timeFinish: 'Обязательное поле',
            };
        }

        if (
            moment(workingHour.timeStart).utcOffset(0).format(TIME_FORMAT) ===
                '00:00' &&
            moment(workingHour.timeFinish).utcOffset(0).format(TIME_FORMAT) ===
                '00:00'
        ) {
            errors.workingHours[i] = {
                ...errors.workingHours[i],
                timeStart: 'Обязательное поле',
                timeFinish: 'Обязательное поле',
            };
        }
    });

    if (
        values?.phone?.length > 0 &&
        !/[+]7\s[(][0-9]{3}[)]\s[0-9]{3}[-][0-9]{2}[-][0-9]{2}/.test(
            values.phone
        )
    ) {
        errors.phone = 'Проверьте корректность номера';
    }

    const isEmpty = isEmptyError(errors);

    return isEmpty ? {} : errors;
};

const GymDetailsNewForm: React.FC<Props> = ({
    onSubmit,
    initialValues,
    legalEntitysOptions,
    AllManagersOptions,
    gymServicesOptions,
    gymAdvantagesOptions,
    onDeleteImageClick,
    onDelete,
    gymId,
    loading,
}) => {
    const history = useHistory();
    const dispatch = useDispatch<AsyncDispatch>();
    const onDrop = useCallback((acceptedFiles) => {
        if (!acceptedFiles.length) {
            cogoToast.error(
                `Можно загрузить файлы с расширением png, jpeg, jpg, webp`,
                {
                    position: 'top-right',
                    hideAfter: 4,
                }
            );
            return;
        } else {
            const MAX_LENGTH = 5;
            const MAX_SIZE = 5000000;
            const numOfFiles = Array.from(acceptedFiles).length;
            if (
                initialValues.previewSources.images.length + numOfFiles >
                MAX_LENGTH
            ) {
                cogoToast.error(`Нельзя загрузить более ${MAX_LENGTH} фото`, {
                    position: 'top-right',
                    hideAfter: 4,
                });
                return;
            }
            for (let i = 0; i < numOfFiles; ++i) {
                const validExtensions = ['png', 'jpeg', 'jpg', 'webp'];
                const fileExtension = acceptedFiles[i].type.split('/')[1];
                const fileSize = acceptedFiles[i].size;

                if (!validExtensions.includes(fileExtension)) {
                    cogoToast.error(
                        `Можно загрузить документы с расширением png, jpeg, jpg, webp`,
                        {
                            position: 'top-right',
                            hideAfter: 4,
                        }
                    );
                    return;
                }

                if (fileSize > MAX_SIZE) {
                    cogoToast.error(
                        `Нельзя загрузить фото размера более 5MB, фото "${acceptedFiles[i].name}" слишком большое`,
                        {
                            position: 'top-right',
                            hideAfter: 4,
                        }
                    );
                    return;
                }
            }
            const { hide } = cogoToast.loading('Загружаем фото...', {
                position: 'top-right',
                hideAfter: 0,
            });
            setModalActive(false);
            dispatch(
                uploadImage({
                    files: acceptedFiles,
                    gymId: initialValues._id,
                })
            )
                .then(() => {
                    cogoToast.success('Загрузка фото завершена', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .catch(() => {
                    cogoToast.error('Ошибка при загрузке фото', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => {
                    hide && hide();
                });
        }
    }, []);
    const [showYClients, setShowYClients] = useState(
        !!initialValues.yclientsId
    );
    const [modalActive, setModalActive] = useState(false);
    const [modalActiveDelete, setModalActiveDelete] = useState(false);
    const [modalActiveGroupWorkouts, setModalActiveGroupWorkouts] = useState(
        false
    );
    const [tooltips, setTooltips] = useState({
        groupWorkoutsOption: false,
        yclientsId: false,
    });
    const [shortAddressCount, setShortAddressCount] = useState<number>(
        initialValues?.shortAddress?.length ?? 0
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/webp': [],
        },
    });

    const gymsImagesUrls = initialValues?.previewSources?.images || [];

    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;
    const isAdmin = user?.role === UserRole.Admin;

    const getCurrentLegalEntityOption = (legalEntityId: string) => {
        return legalEntitysOptions.filter(
            (legalEntitysOption) => legalEntitysOption.value === legalEntityId
        );
    };

    const getCurrentManagerOptions = (managersId: string[]) => {
        return AllManagersOptions.filter((managersOption) =>
            managersId.includes(managersOption.value)
        );
    };

    const getCurrentGymServicesOptions = (services: string[]) => {
        return gymServicesOptions.filter((gymService) =>
            services.includes(gymService.value)
        );
    };

    const getCurrentGymAdvantagesOptions = (advantages: string[]) => {
        return gymAdvantagesOptions.filter((gymAdvantage) =>
            advantages.includes(gymAdvantage.value)
        );
    };

    return (
        <div>
            <Formik
                enableReinitialize={true}
                validate={validate}
                initialValues={initialValues}
                onSubmit={onSubmit}
            >
                {({ values, setFieldValue, errors }) => (
                    <>
                        <Form>
                            {isSuperAdmin && (
                                <div className={styles.adminMain}>
                                    <div className={styles.options}>
                                        Параметры карточки площадки
                                    </div>
                                    <div className={styles.adminMenu}>
                                        <div className={styles.row}>
                                            <label
                                                className={styles.checkboxLabel}
                                            >
                                                <Field
                                                    className={styles.checkbox}
                                                    type="checkbox"
                                                    name="authorized"
                                                />
                                                Авторизован
                                            </label>
                                            <label
                                                className={styles.checkboxLabel}
                                            >
                                                <Field
                                                    className={styles.checkbox}
                                                    type="checkbox"
                                                    name="deleted"
                                                />
                                                Скрыт с карты
                                            </label>
                                            <label
                                                className={styles.checkboxLabel}
                                            >
                                                <Field
                                                    className={styles.checkbox}
                                                    type="checkbox"
                                                    name="isHidden"
                                                />
                                                Удалить из списка
                                            </label>
                                            <label
                                                className={styles.checkboxLabel}
                                            >
                                                <Field
                                                    className={styles.checkbox}
                                                    type="checkbox"
                                                    name="test"
                                                />
                                                Тестовый
                                            </label>
                                        </div>
                                        <div className={styles.row}>
                                            <label
                                                className={styles.checkboxLabel}
                                            >
                                                <Field
                                                    className={styles.checkbox}
                                                    type="checkbox"
                                                    name="withMembershipsOption"
                                                />
                                                Абонементы
                                            </label>
                                            <label
                                                className={styles.checkboxLabel}
                                            >
                                                <Field
                                                    className={styles.checkbox}
                                                    type="checkbox"
                                                    name="withGroupWorkoutsOption"
                                                />
                                                Групповые тренировки
                                            </label>
                                            {/* <label
                                                className={styles.checkboxLabel}
                                            >
                                                <Field
                                                    className={styles.checkbox}
                                                    type="checkbox"
                                                    name="withSingleWorkoutsOption"
                                                />
                                                Разовые тренировки
                                            </label> */}
                                            <label
                                                className={styles.checkboxLabel}
                                            >
                                                <Field
                                                    className={styles.checkbox}
                                                    type="checkbox"
                                                    name="spirit"
                                                />
                                                Spirit
                                            </label>
                                            <label
                                                className={styles.checkboxLabel}
                                            >
                                                <Field
                                                    className={styles.checkbox}
                                                    type="checkbox"
                                                    name="seasonal"
                                                />
                                                Сезонное
                                            </label>
                                        </div>
                                    </div>
                                    <div className={styles.legal}>
                                        <div className={styles.legalEntity}>
                                            <span
                                                className={styles.selectLabel}
                                            >
                                                Юридическое лицо
                                            </span>

                                            <Select
                                                className={styles.select}
                                                styles={{
                                                    input: (provided) => ({
                                                        ...provided,
                                                        minHeight: 40,
                                                        borderRadius:
                                                            '0px 0px 20px 20px',
                                                    }),
                                                }}
                                                value={getCurrentLegalEntityOption(
                                                    values.legalEntityId
                                                )}
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        'legalEntityId',
                                                        e && e.value
                                                    );
                                                }}
                                                options={legalEntitysOptions}
                                                filterOption={createFilter({
                                                    stringify: (option) =>
                                                        `${option.label}`,
                                                })}
                                                placeholder="Выберите из списка"
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary:
                                                            'rgb(225, 129, 65)',
                                                    },
                                                })}
                                                noOptionsMessage={() =>
                                                    'Не найдено'
                                                }
                                            />
                                        </div>
                                        <div className={styles.manager}>
                                            <span
                                                className={styles.selectLabel}
                                            >
                                                Менеджер
                                            </span>
                                            <Select
                                                className={styles.select}
                                                isMulti
                                                styles={{
                                                    input: (provided) => ({
                                                        ...provided,
                                                        minHeight: 40,
                                                        borderRadius: '8px',
                                                    }),
                                                }}
                                                value={getCurrentManagerOptions(
                                                    values.managersId
                                                )}
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        'managersId',
                                                        e.map((el) => el.value)
                                                    );
                                                }}
                                                options={AllManagersOptions}
                                                filterOption={createFilter({
                                                    stringify: (option) =>
                                                        `${option.label}`,
                                                })}
                                                closeMenuOnSelect={false}
                                                placeholder="Выберите из списка"
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary:
                                                            'rgb(225, 129, 65)',
                                                    },
                                                })}
                                                noOptionsMessage={() =>
                                                    'Не найдено'
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className={styles.box}>
                                <h2>Параметры площадки</h2>
                                <div className={styles.wrap}>
                                    <label
                                        htmlFor="title"
                                        className={styles.gymLabel}
                                    >
                                        Название площадки
                                        <Field
                                            disabled={isAdmin}
                                            className={styles.gymTitle1}
                                            id="title"
                                            name="title"
                                            placeholder="Название площадки"
                                        />
                                        <ErrorMessage
                                            className={styles.error}
                                            name="title"
                                            component="div"
                                        />
                                    </label>

                                    {values.hasGroupWorkouts && (
                                        <label
                                            htmlFor="prepayment"
                                            className={styles.gymLabel}
                                        >
                                            Размер предоплаты (значение не может
                                            быть меньше 50%)
                                            <Field
                                                disabled={isAdmin}
                                                className={styles.gymTitle1}
                                                id="prepayment"
                                                name="prepayment"
                                                placeholder="Размер предоплаты"
                                            />
                                            <ErrorMessage
                                                className={styles.error}
                                                name="prepayment"
                                                component="div"
                                            />
                                        </label>
                                    )}
                                </div>

                                <div className={styles.row}>
                                    <label className={styles.checkboxLabel}>
                                        <Field
                                            disabled={isAdmin}
                                            className={styles.checkbox}
                                            type="checkbox"
                                            name="hasPerMinWorkouts"
                                        />
                                        Поминутные
                                    </label>
                                    {values.withGroupWorkoutsOption && (
                                        <>
                                            <label
                                                className={styles.checkboxLabel}
                                            >
                                                <Field
                                                    disabled={isAdmin}
                                                    className={styles.checkbox}
                                                    type="checkbox"
                                                    name="hasGroupWorkouts"
                                                    onChange={() => {
                                                        if (
                                                            values.hasGroupWorkouts
                                                        ) {
                                                            setModalActiveGroupWorkouts(
                                                                true
                                                            );
                                                        } else {
                                                            setFieldValue(
                                                                'hasGroupWorkouts',
                                                                !values.hasGroupWorkouts
                                                            );
                                                        }
                                                    }}
                                                />
                                                Групповые
                                            </label>
                                            <span
                                                title="Подсказка про опцию групповых тренировок"
                                                className={styles.info}
                                                onClick={() =>
                                                    setTooltips(
                                                        (prevState) => ({
                                                            ...prevState,
                                                            groupWorkoutsOption: !prevState.groupWorkoutsOption,
                                                        })
                                                    )
                                                }
                                            >
                                                <QuestionMark />
                                            </span>
                                            {tooltips.groupWorkoutsOption && (
                                                <div className={styles.tooltip}>
                                                    <p>
                                                        Данная функция
                                                        разблокирует опцию
                                                        групповых тренировок в
                                                        приложении
                                                    </p>
                                                    <p>
                                                        Для того чтобы
                                                        тренировки появились
                                                        нужно заполнить
                                                        календарь в разделе{' '}
                                                        <span
                                                            className={
                                                                styles.tooltipLink
                                                            }
                                                            onClick={() => {
                                                                history.push(
                                                                    `/dashboard/group-workouts`
                                                                );
                                                                dispatch(
                                                                    selectGym(
                                                                        gymId
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            Групповые тренировки
                                                        </span>
                                                    </p>
                                                    <p>
                                                        Перед тем как переходить
                                                        по ссылке сохраните все
                                                        изменения на странице
                                                    </p>
                                                    <div
                                                        onClick={() =>
                                                            setTooltips(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    groupWorkoutsOption: false,
                                                                })
                                                            )
                                                        }
                                                    >
                                                        <Delete
                                                            className={
                                                                styles.tooltipClose
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}

                                    <ModalGroupWorkout
                                        active={modalActiveGroupWorkouts}
                                        setActive={setModalActiveGroupWorkouts}
                                    >
                                        <h3>Изменение групповых</h3>

                                        <p>
                                            Вы пытаетесь изменить формат зала.
                                            <br />
                                            Перед тем как скрыть из него
                                            групповые тренировки проверьте
                                            наличие <br /> бронирований на
                                            занятия в этой студии и ручным
                                            образом отмените все из них.
                                        </p>

                                        <button
                                            onClick={() => {
                                                setModalActiveGroupWorkouts(
                                                    false
                                                );
                                                setFieldValue(
                                                    'hasGroupWorkouts',
                                                    true
                                                );
                                            }}
                                            type="button"
                                            className={styles.modalBtn}
                                        >
                                            Отмена
                                        </button>
                                        <button
                                            onClick={() => {
                                                setModalActiveGroupWorkouts(
                                                    false
                                                );
                                                setFieldValue(
                                                    'hasGroupWorkouts',
                                                    false
                                                );
                                            }}
                                            type="button"
                                            className={styles.modalBtn}
                                        >
                                            Продолжить
                                        </button>
                                    </ModalGroupWorkout>

                                    {/* {values.withSingleWorkoutsOption && (
                                        <label className={styles.checkboxLabel}>
                                            <Field
                                                className={styles.checkbox}
                                                type="checkbox"
                                                name="hasSingleWorkouts"
                                            />
                                            Разовые
                                        </label>
                                    )} */}
                                    {values.withMembershipsOption && (
                                        <label className={styles.checkboxLabel}>
                                            <Field
                                                disabled={isAdmin}
                                                className={styles.checkbox}
                                                type="checkbox"
                                                name="hasMemberships"
                                            />
                                            Абонементы
                                        </label>
                                    )}
                                </div>
                                <div className={styles.row}>
                                    <label className={styles.checkboxLabel}>
                                        <Field
                                            disabled={isAdmin}
                                            className={styles.checkbox}
                                            type="checkbox"
                                            name="hasPassport"
                                        />
                                        Нужен паспорт
                                    </label>
                                </div>

                                {values.hasGroupWorkouts && (
                                    <>
                                        {!showYClients ? (
                                            <div className={styles.rowYC}>
                                                <ButtonNew
                                                    disabled={isAdmin}
                                                    appearance="plus"
                                                    icon="plus"
                                                    onClick={() =>
                                                        setShowYClients(
                                                            (prev) => !prev
                                                        )
                                                    }
                                                >
                                                    Добавить YCLIENTS ID
                                                </ButtonNew>
                                                <span
                                                    title="Подсказка про опцию групповых тренировок"
                                                    className={styles.infoYC}
                                                    onClick={() =>
                                                        setTooltips(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                yclientsId: !prevState.yclientsId,
                                                            })
                                                        )
                                                    }
                                                >
                                                    <QuestionMark /> Что это
                                                </span>
                                            </div>
                                        ) : (
                                            <>
                                                <div
                                                    className={
                                                        styles.YClientsSection
                                                    }
                                                >
                                                    <label
                                                        htmlFor="yclientsId"
                                                        className={
                                                            styles.yclientsLabel
                                                        }
                                                    >
                                                        YCLIENTS ID
                                                        <Field
                                                            className={
                                                                styles.gymTitle1
                                                            }
                                                            id="yclientsId"
                                                            name="yclientsId"
                                                            placeholder="ID компании в системе YCLIENTS"
                                                        />
                                                    </label>
                                                    <label
                                                        htmlFor="yclientsDiscount"
                                                        className={
                                                            styles.yclientsLabel
                                                        }
                                                    >
                                                        % скидки от цены
                                                        YCLIENTS
                                                        <Field
                                                            className={
                                                                styles.gymTitle1
                                                            }
                                                            id="yclientsDiscount"
                                                            name="discountFromYclientsPrice"
                                                            placeholder="Скидка"
                                                        />
                                                        <ErrorMessage
                                                            className={
                                                                styles.error
                                                            }
                                                            name="discountFromYclientsPrice"
                                                            component="div"
                                                        />
                                                    </label>
                                                    <label
                                                        htmlFor="yclientsDiscount"
                                                        className={
                                                            styles.yclientsLabel
                                                        }
                                                    >
                                                        % предоплаты
                                                        <Field
                                                            className={
                                                                styles.gymTitle1
                                                            }
                                                            id="yclientsDiscount"
                                                            name="prepaymentPercentFromYclientsPrice"
                                                            placeholder="Предоплата"
                                                        />
                                                        <ErrorMessage
                                                            className={
                                                                styles.error
                                                            }
                                                            name="prepaymentPercentFromYclientsPrice"
                                                            component="div"
                                                        />
                                                    </label>
                                                </div>
                                            </>
                                        )}
                                        {tooltips.yclientsId && (
                                            <div className={styles.tooltipYC}>
                                                <p>
                                                    Для того, чтобы найти ID
                                                    компании, в системе YCLIENTS
                                                    выберите в боковом меню
                                                    кнопку Обзор и далее Сводка
                                                </p>
                                                <p>
                                                    Скопируйте номер, показанный
                                                    в поле Идентификатор филиала
                                                    (ID) без символа #
                                                </p>
                                                <p>
                                                    Если ваша компания не
                                                    является партнером YCLIENTS,
                                                    просто оставьте данное поле
                                                    пустым.
                                                </p>
                                                <div
                                                    onClick={() =>
                                                        setTooltips(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                yclientsId: false,
                                                            })
                                                        )
                                                    }
                                                >
                                                    <Delete
                                                        className={
                                                            styles.tooltipCloseYC
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}

                                <div>
                                    <h3>Теги</h3>
                                    <div className={styles.tagsDesc}>
                                        Выберите теги, которые соответствуют
                                        площадке, что бы упростить поиск
                                        клиенту.
                                    </div>
                                </div>

                                <div className={styles.tags}>
                                    <div className={styles.services}>
                                        <span className={styles.selectLabel}>
                                            Услуги зала
                                        </span>
                                        <Select
                                            isDisabled={isAdmin}
                                            className={styles.select}
                                            isMulti
                                            styles={{
                                                input: (provided) => ({
                                                    ...provided,
                                                    minHeight: 40,
                                                    borderRadius: '8px',
                                                }),
                                            }}
                                            onChange={(e) => {
                                                setFieldValue(
                                                    'services',
                                                    e.map((el) => el.value)
                                                );
                                            }}
                                            value={getCurrentGymServicesOptions(
                                                values.services
                                            )}
                                            options={gymServicesOptions}
                                            filterOption={createFilter({
                                                stringify: (option) =>
                                                    `${option.label}`,
                                            })}
                                            closeMenuOnSelect={false}
                                            placeholder="Выберите из списка"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary:
                                                        'rgb(225, 129, 65)',
                                                },
                                            })}
                                            noOptionsMessage={() =>
                                                'Не найдено'
                                            }
                                        />
                                    </div>

                                    <div className={styles.bookingType}>
                                        <span className={styles.selectLabel}>
                                            Преимущества зала
                                        </span>

                                        <Select
                                            isDisabled={isAdmin}
                                            className={styles.select}
                                            isMulti
                                            styles={{
                                                input: (provided) => ({
                                                    ...provided,
                                                    minHeight: 40,
                                                    borderRadius: '8px',
                                                }),
                                            }}
                                            onChange={(e) => {
                                                setFieldValue(
                                                    'advantages',
                                                    e.map((el) => el.value)
                                                );
                                            }}
                                            value={getCurrentGymAdvantagesOptions(
                                                values.advantages
                                            )}
                                            options={gymAdvantagesOptions}
                                            filterOption={createFilter({
                                                stringify: (option) =>
                                                    `${option.label}`,
                                            })}
                                            closeMenuOnSelect={false}
                                            placeholder="Выберите из списка"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary:
                                                        'rgb(225, 129, 65)',
                                                },
                                            })}
                                            noOptionsMessage={() =>
                                                'Не найдено'
                                            }
                                        />
                                    </div>
                                </div>

                                <div>
                                    <h3 className={styles.infoTitle}>
                                        Информация о зале
                                    </h3>
                                    <div className={styles.locationTitle}>
                                        Расположение
                                    </div>
                                    <div className={styles.location}>
                                        <div>
                                            <label
                                                htmlFor="city"
                                                className={styles.label}
                                            >
                                                Город
                                                <Field
                                                    disabled={isAdmin}
                                                    id="city"
                                                    name="city"
                                                    placeholder="Выберите из списка"
                                                    as={CityInputNew}
                                                    setFieldValue={
                                                        setFieldValue
                                                    }
                                                />
                                                <ErrorMessage
                                                    className={styles.error}
                                                    name="city"
                                                    component="div"
                                                />
                                            </label>

                                            <label
                                                htmlFor="shortAddress"
                                                className={styles.label}
                                            >
                                                Короткий адрес
                                                <Field
                                                    className={
                                                        styles.shortAddressInput
                                                    }
                                                    disabled={isAdmin}
                                                    id="shortAddress"
                                                    name="shortAddress"
                                                    placeholder="Введите адрес"
                                                    onChange={(e: any) => {
                                                        setFieldValue(
                                                            'shortAddress',
                                                            e.target.value
                                                        );
                                                        setFieldValue(
                                                            'geo.shortAddress',
                                                            e.target.value
                                                        );
                                                        setShortAddressCount(
                                                            e.target.value
                                                                .length
                                                        );
                                                    }}
                                                />
                                                {!errors.shortAddress && (
                                                    <span
                                                        className={
                                                            shortAddressCount >
                                                            38
                                                                ? styles.addressCountError
                                                                : styles.addressCount
                                                        }
                                                    >
                                                        {shortAddressCount}/38
                                                    </span>
                                                )}
                                                <ErrorMessage
                                                    className={styles.error}
                                                    name="shortAddress"
                                                    component="div"
                                                />
                                            </label>

                                            <FieldArray
                                                name="subwayRoutes"
                                                render={(arrayHelpers) => (
                                                    <div>
                                                        {values.subwayRoutes &&
                                                            values.subwayRoutes
                                                                .length > 0 &&
                                                            values.subwayRoutes.map(
                                                                (
                                                                    subwayRoute: any,
                                                                    index: number
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={
                                                                                styles.subwaySection
                                                                            }
                                                                        >
                                                                            <label
                                                                                htmlFor="station"
                                                                                className={
                                                                                    styles.subwayLabel
                                                                                }
                                                                            >
                                                                                Станция
                                                                                метро
                                                                                (если
                                                                                есть){' '}
                                                                                <Field
                                                                                    id="station"
                                                                                    className={
                                                                                        styles.subwayInput
                                                                                    }
                                                                                    name={`subwayRoutes.${index}.station`}
                                                                                />
                                                                                <ErrorMessage
                                                                                    className={
                                                                                        styles.error
                                                                                    }
                                                                                    name={`subwayRoutes.${index}.station`}
                                                                                    component="div"
                                                                                />
                                                                            </label>

                                                                            <label
                                                                                htmlFor="duration"
                                                                                className={
                                                                                    styles.subwayLabelMinut
                                                                                }
                                                                            >
                                                                                Время
                                                                                от
                                                                                метро{' '}
                                                                                <Field
                                                                                    id="duration"
                                                                                    className={
                                                                                        styles.subwayInputMinut
                                                                                    }
                                                                                    name={`subwayRoutes.${index}.duration`}
                                                                                />
                                                                                <ErrorMessage
                                                                                    className={
                                                                                        styles.error
                                                                                    }
                                                                                    name={`subwayRoutes.${index}.duration`}
                                                                                    component="div"
                                                                                />
                                                                            </label>
                                                                            <button
                                                                                disabled={
                                                                                    isAdmin
                                                                                }
                                                                                type="button"
                                                                                className={
                                                                                    styles.singleDelBtn
                                                                                }
                                                                                onClick={() =>
                                                                                    arrayHelpers.remove(
                                                                                        index
                                                                                    )
                                                                                }
                                                                            >
                                                                                &#10060;
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                        {values.subwayRoutes
                                                            .length < 3 && (
                                                            <ButtonNew
                                                                disabled={
                                                                    isAdmin
                                                                }
                                                                type="button"
                                                                appearance="plus"
                                                                icon="plus"
                                                                onClick={() =>
                                                                    arrayHelpers.push(
                                                                        {
                                                                            station:
                                                                                '',
                                                                            duration:
                                                                                '',
                                                                        }
                                                                    )
                                                                }
                                                            >
                                                                Добавить метро
                                                            </ButtonNew>
                                                        )}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <div className={styles.googleMap}>
                                            <label
                                                htmlFor="geo"
                                                className={styles.gymLabel}
                                            >
                                                Полный адрес
                                                <Field
                                                    disabled={isAdmin}
                                                    className={styles.gymTitle1}
                                                    id="geo"
                                                    name="geo"
                                                    placeholder="Выберите из списка"
                                                    as={GeoInputYandexNew}
                                                    setFieldValue={
                                                        setFieldValue
                                                    }
                                                    setShortAddressCount={
                                                        setShortAddressCount
                                                    }
                                                    errorMessage={ErrorMessage}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    <div className={styles.section}>
                                        <h3>Время работы</h3>
                                        <div>
                                            <FieldArray
                                                name="workingHours"
                                                render={(arrayHelpers) => (
                                                    <div>
                                                        {values.workingHours &&
                                                            values.workingHours
                                                                .length > 0 &&
                                                            values.workingHours.map(
                                                                (
                                                                    workingHour: any,
                                                                    index: number
                                                                ) => (
                                                                    <div
                                                                        className={
                                                                            styles.workingHoursInputs
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <label
                                                                            className={
                                                                                styles.workingHoursLabel
                                                                            }
                                                                            htmlFor="timeStart"
                                                                        >
                                                                            День
                                                                            недели
                                                                            <Field
                                                                                disabled={
                                                                                    isAdmin
                                                                                }
                                                                                className={
                                                                                    styles.selectDay
                                                                                }
                                                                                as="select"
                                                                                name={`workingHours.${index}.weekDay`}
                                                                            >
                                                                                <option value="">
                                                                                    Выбрать
                                                                                    день/период
                                                                                </option>
                                                                                {WeekDaysOptions.map(
                                                                                    (
                                                                                        WeekDaysOption
                                                                                    ) => {
                                                                                        return (
                                                                                            <option
                                                                                                key={
                                                                                                    WeekDaysOption.value
                                                                                                }
                                                                                                value={
                                                                                                    WeekDaysOption.value
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    WeekDaysOption.title
                                                                                                }
                                                                                            </option>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </Field>
                                                                            <ErrorMessage
                                                                                className={
                                                                                    styles.error
                                                                                }
                                                                                name={`workingHours.${index}.weekDay`}
                                                                                component="div"
                                                                            />
                                                                        </label>
                                                                        <span
                                                                            className={
                                                                                styles.aroundTheClock
                                                                            }
                                                                        >
                                                                            Круглосуточно
                                                                        </span>
                                                                        <label
                                                                            className={
                                                                                styles.switch
                                                                            }
                                                                        >
                                                                            <Field
                                                                                disabled={
                                                                                    isAdmin
                                                                                }
                                                                                className={
                                                                                    styles.toggle
                                                                                }
                                                                                type="checkbox"
                                                                                name={`workingHours.${index}.aroundTheClock`}
                                                                            />
                                                                            <span
                                                                                className={
                                                                                    styles.slider
                                                                                }
                                                                            />
                                                                        </label>
                                                                        <label
                                                                            className={
                                                                                styles.workingHoursLabel
                                                                            }
                                                                            htmlFor="timeStart"
                                                                        >
                                                                            Время
                                                                            открытия
                                                                            <Field
                                                                                disabled={
                                                                                    isAdmin
                                                                                }
                                                                                className={
                                                                                    styles.workingHoursInput
                                                                                }
                                                                                id="timeStart"
                                                                                name={`workingHours.${index}.timeStart`}
                                                                            >
                                                                                {({
                                                                                    field,
                                                                                }: FieldProps) => (
                                                                                    <>
                                                                                        <TimePicker
                                                                                            disabled={
                                                                                                isAdmin
                                                                                            }
                                                                                            name={
                                                                                                field.name
                                                                                            }
                                                                                            showSecond={
                                                                                                false
                                                                                            }
                                                                                            defaultValue={
                                                                                                field.value
                                                                                                    ? moment(
                                                                                                          field.value
                                                                                                      ).utcOffset(
                                                                                                          0
                                                                                                      )
                                                                                                    : moment()
                                                                                                          .utcOffset(
                                                                                                              0
                                                                                                          )
                                                                                                          .startOf(
                                                                                                              'day'
                                                                                                          )
                                                                                            }
                                                                                            onChange={(
                                                                                                value
                                                                                            ) => {
                                                                                                setFieldValue(
                                                                                                    `workingHours.${index}.timeStart`,
                                                                                                    value.utcOffset(
                                                                                                        0
                                                                                                    )
                                                                                                );
                                                                                            }}
                                                                                            hideDisabledOptions
                                                                                            className={
                                                                                                styles.inputTimePicker
                                                                                            }
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            </Field>
                                                                            <ErrorMessage
                                                                                className={
                                                                                    styles.error
                                                                                }
                                                                                name={`workingHours.${index}.timeStart`}
                                                                                component="div"
                                                                            />
                                                                        </label>
                                                                        <label
                                                                            className={
                                                                                styles.workingHoursLabel
                                                                            }
                                                                            htmlFor="timeFinish"
                                                                        >
                                                                            Время
                                                                            закрытия
                                                                            <Field
                                                                                disabled={
                                                                                    isAdmin
                                                                                }
                                                                                className={
                                                                                    styles.workingHoursInput
                                                                                }
                                                                                id="timeFinish"
                                                                                name={`workingHours.${index}.timeFinish`}
                                                                            >
                                                                                {({
                                                                                    field,
                                                                                }: FieldProps) => (
                                                                                    <>
                                                                                        <TimePicker
                                                                                            disabled={
                                                                                                isAdmin
                                                                                            }
                                                                                            name={
                                                                                                field.name
                                                                                            }
                                                                                            showSecond={
                                                                                                false
                                                                                            }
                                                                                            defaultValue={
                                                                                                field.value
                                                                                                    ? moment(
                                                                                                          field.value
                                                                                                      ).utcOffset(
                                                                                                          0
                                                                                                      )
                                                                                                    : moment()
                                                                                                          .utcOffset(
                                                                                                              0
                                                                                                          )
                                                                                                          .startOf(
                                                                                                              'day'
                                                                                                          )
                                                                                            }
                                                                                            onChange={(
                                                                                                value
                                                                                            ) => {
                                                                                                setFieldValue(
                                                                                                    `workingHours.${index}.timeFinish`,
                                                                                                    value.utcOffset(
                                                                                                        0
                                                                                                    )
                                                                                                );
                                                                                            }}
                                                                                            hideDisabledOptions
                                                                                            className={
                                                                                                styles.inputTimePicker
                                                                                            }
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            </Field>
                                                                            <ErrorMessage
                                                                                className={
                                                                                    styles.error
                                                                                }
                                                                                name={`workingHours.${index}.timeFinish`}
                                                                                component="div"
                                                                            />
                                                                        </label>
                                                                        <button
                                                                            disabled={
                                                                                isAdmin
                                                                            }
                                                                            type="button"
                                                                            className={
                                                                                styles.deleteWorkingHoursBtn
                                                                            }
                                                                            onClick={() =>
                                                                                arrayHelpers.remove(
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            &#10060;
                                                                        </button>
                                                                    </div>
                                                                )
                                                            )}
                                                        <ButtonNew
                                                            disabled={isAdmin}
                                                            type="button"
                                                            appearance="plus"
                                                            icon="plus"
                                                            onClick={() =>
                                                                arrayHelpers.push(
                                                                    {
                                                                        weekDay:
                                                                            '',
                                                                        aroundTheClock: false,
                                                                        timeStart:
                                                                            '',
                                                                        timeFinish:
                                                                            '',
                                                                    }
                                                                )
                                                            }
                                                        >
                                                            Добавить время
                                                            работы
                                                        </ButtonNew>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.section}>
                                        <ModalPhoto
                                            active={modalActive}
                                            setActive={setModalActive}
                                        >
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                {isDragActive ? (
                                                    <>
                                                        <div
                                                            className={
                                                                styles.drop
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.dropZone
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        styles.dropTitle
                                                                    }
                                                                >
                                                                    Загрузка
                                                                    фото
                                                                </div>
                                                                <Downloading />
                                                                <span
                                                                    className={
                                                                        styles.dropSubTitle
                                                                    }
                                                                >
                                                                    Отпустите
                                                                    для загрузки
                                                                    файла
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div
                                                            className={
                                                                styles.drop
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.dropZone
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        styles.dropTitle
                                                                    }
                                                                >
                                                                    Загрузка
                                                                    фото
                                                                </div>
                                                                <Image />
                                                                <span
                                                                    className={
                                                                        styles.dropSubTitle
                                                                    }
                                                                >
                                                                    Перетащите
                                                                    файл в
                                                                    выделенную
                                                                    область
                                                                </span>
                                                                <span>
                                                                    или нажмите
                                                                    для выбора
                                                                    из
                                                                    проводника
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </ModalPhoto>
                                        <div className={styles.locationTitle}>
                                            Фото зала
                                        </div>
                                        <div className={styles.label}>
                                            Загрузите до 5 фото с расширением
                                            PNG/JPG/WEBP
                                        </div>
                                        <div className={styles.images}>
                                            {gymsImagesUrls.length > 0 &&
                                                gymsImagesUrls.map(
                                                    (
                                                        url: string,
                                                        index: number
                                                    ) => (
                                                        <div
                                                            className={
                                                                styles.rowImage
                                                            }
                                                            key={url}
                                                        >
                                                            <a
                                                                href={url}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <img
                                                                    id={`img${index}`}
                                                                    src={url}
                                                                    className={
                                                                        styles.gymImage
                                                                    }
                                                                />
                                                            </a>
                                                            <button
                                                                type="button"
                                                                disabled={
                                                                    isAdmin
                                                                }
                                                                className={
                                                                    styles.DeleteImageBtn
                                                                }
                                                                id={url}
                                                                onClick={
                                                                    onDeleteImageClick
                                                                }
                                                            >
                                                                &#10060;
                                                            </button>
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                        <ButtonNew
                                            disabled={isAdmin}
                                            type="button"
                                            appearance="plus"
                                            icon="plus"
                                            onClick={() => setModalActive(true)}
                                        >
                                            Загрузить фото
                                        </ButtonNew>
                                    </div>
                                    {isSuperAdmin && (
                                        <div className={styles.section}>
                                            <UploadIcon
                                                gymId={gymId}
                                                iconSrc={initialValues.iconSrc}
                                            />
                                        </div>
                                    )}
                                    <div className={styles.descriptionArea}>
                                        <div>
                                            <div
                                                className={styles.locationTitle}
                                            >
                                                Описание площадки
                                            </div>
                                            <label
                                                htmlFor="description"
                                                className={styles.label}
                                            >
                                                Описание площадки
                                            </label>
                                            <Field
                                                disabled={isAdmin}
                                                as="textarea"
                                                className={styles.gymTitle1}
                                                id="description"
                                                name="description"
                                                placeholder="Ваш текст"
                                                rows="6"
                                            />
                                            <ErrorMessage
                                                className={styles.error}
                                                name="description"
                                                component="div"
                                            />
                                        </div>
                                        <div className={styles.description}>
                                            Коротко опишите главные преимущества
                                            вашей площадки. Время работы
                                            заполняется отдельно
                                        </div>
                                    </div>
                                    <div className={styles.contacts}>
                                        <div>
                                            <div
                                                className={styles.locationTitle}
                                            >
                                                Контакты площадки
                                            </div>
                                            <label
                                                htmlFor="telegram"
                                                className={styles.gymLabel}
                                            >
                                                Ссылка Телеграм
                                                <Field
                                                    disabled={isAdmin}
                                                    className={styles.gymTitle1}
                                                    id="telegram"
                                                    name="social.telegram"
                                                    placeholder="t.me/"
                                                />
                                                <ErrorMessage
                                                    className={styles.error}
                                                    name="telegram"
                                                    component="div"
                                                />
                                            </label>
                                            <label
                                                htmlFor="vk"
                                                className={styles.gymLabel}
                                            >
                                                Ссылка во Вконтакте
                                                <Field
                                                    disabled={isAdmin}
                                                    className={styles.gymTitle1}
                                                    id="vk"
                                                    name="social.vk"
                                                    placeholder="vk.com/"
                                                />
                                                <ErrorMessage
                                                    className={styles.error}
                                                    name="vk"
                                                    component="div"
                                                />
                                            </label>
                                        </div>
                                        <div className={styles.contactsRow}>
                                            <label
                                                htmlFor="website"
                                                className={styles.gymLabel}
                                            >
                                                Ссылка на сайт
                                                <Field
                                                    disabled={isAdmin}
                                                    className={styles.gymTitle1}
                                                    id="website"
                                                    name="website"
                                                    placeholder="Введите адрес сайта"
                                                />
                                                <ErrorMessage
                                                    className={styles.error}
                                                    name="website"
                                                    component="div"
                                                />
                                            </label>

                                            <label
                                                htmlFor="phone"
                                                className={styles.gymLabel}
                                            >
                                                Номер телефона
                                                <Field
                                                    disabled={isAdmin}
                                                    id="phone"
                                                    name="phone"
                                                >
                                                    {({
                                                        field,
                                                    }: FieldProps) => (
                                                        <ReactInputMask
                                                            disabled={isAdmin}
                                                            {...field}
                                                            className={
                                                                styles.gymTitle1
                                                            }
                                                            placeholder="+7"
                                                            mask="+7 (999) 999-99-99"
                                                            // maskChar={null}
                                                            onChange={(e) => {
                                                                const {
                                                                    value,
                                                                } = e.target;

                                                                if (
                                                                    value ===
                                                                    '+7 ('
                                                                ) {
                                                                    setFieldValue(
                                                                        'phone',
                                                                        ''
                                                                    );
                                                                    return;
                                                                }

                                                                setFieldValue(
                                                                    'phone',
                                                                    value
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage
                                                    className={styles.error}
                                                    name="phone"
                                                    component="div"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    {values.hasPerMinWorkouts && (
                                        <div className={styles.sectionPerMin}>
                                            <h3>Поминутные тренировки</h3>
                                            <div
                                                className={
                                                    styles.sliderMinWorkoutDuration
                                                }
                                            >
                                                <span
                                                    className={
                                                        styles.sliderAroundTheClock
                                                    }
                                                >
                                                    Есть минимальное время
                                                    тренировки
                                                </span>
                                                <label
                                                    className={
                                                        styles.sliderSwitch
                                                    }
                                                >
                                                    <Field
                                                        disabled={isAdmin}
                                                        className={
                                                            styles.toggle
                                                        }
                                                        type="checkbox"
                                                        name="hasMinWorkoutDuration"
                                                        onClick={(e: any) => {
                                                            if (
                                                                e.target
                                                                    .value ===
                                                                'true'
                                                            ) {
                                                                setFieldValue(
                                                                    'minWorkoutDuration',
                                                                    ''
                                                                );
                                                            }
                                                        }}
                                                    />
                                                    <span
                                                        className={
                                                            styles.slider
                                                        }
                                                    />
                                                </label>
                                            </div>
                                            <div className={styles.rowPrice}>
                                                <label
                                                    htmlFor="fixedPrice"
                                                    className={
                                                        styles.perMinPriceLabel
                                                    }
                                                >
                                                    Стоимость
                                                    <Field
                                                        disabled={isAdmin}
                                                        className={
                                                            styles.perMinPrice
                                                        }
                                                        id="fixedPrice"
                                                        name="fixedPrice"
                                                        placeholder="0 ₽"
                                                    />
                                                    <ErrorMessage
                                                        className={styles.error}
                                                        name="fixedPrice"
                                                        component="div"
                                                    />
                                                </label>
                                                {values.hasMinWorkoutDuration && (
                                                    <label
                                                        htmlFor="minWorkoutDuration"
                                                        className={
                                                            styles.perMinPriceLabel
                                                        }
                                                    >
                                                        Минимальное время
                                                        тренировки
                                                        <Field
                                                            disabled={isAdmin}
                                                            className={
                                                                styles.perMinPrice
                                                            }
                                                            id="minWorkoutDuration"
                                                            name="minWorkoutDuration"
                                                            placeholder="0 мин."
                                                        />
                                                        <ErrorMessage
                                                            className={
                                                                styles.error
                                                            }
                                                            name="minWorkoutDuration"
                                                            component="div"
                                                        />
                                                    </label>
                                                )}
                                            </div>
                                            <FieldArray
                                                name="specialPrices"
                                                render={(arrayHelpers) => (
                                                    <div>
                                                        {/* Специальная цена: */}
                                                        {values.specialPrices &&
                                                            values.specialPrices
                                                                .length > 0 &&
                                                            values.specialPrices.map(
                                                                (
                                                                    specialPrice: any,
                                                                    index: number
                                                                ) => (
                                                                    <div
                                                                        className={
                                                                            styles.specialPricesInputs
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <label
                                                                            className={
                                                                                styles.workingHoursLabel
                                                                            }
                                                                            htmlFor="timeStart"
                                                                        >
                                                                            День
                                                                            недели
                                                                            <Field
                                                                                disabled={
                                                                                    isAdmin
                                                                                }
                                                                                className={
                                                                                    styles.selectDay
                                                                                }
                                                                                as="select"
                                                                                name={`specialPrices.${index}.weekDay`}
                                                                            >
                                                                                <option value="">
                                                                                    Выбрать
                                                                                    день/период
                                                                                </option>
                                                                                {WeekDaysOptions.map(
                                                                                    (
                                                                                        WeekDaysOption
                                                                                    ) => {
                                                                                        return (
                                                                                            <>
                                                                                                <option
                                                                                                    key={
                                                                                                        WeekDaysOption.value
                                                                                                    }
                                                                                                    value={
                                                                                                        WeekDaysOption.value
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        WeekDaysOption.title
                                                                                                    }
                                                                                                </option>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </Field>
                                                                            <ErrorMessage
                                                                                className={
                                                                                    styles.error
                                                                                }
                                                                                name={`specialPrices.${index}.weekDay`}
                                                                                component="div"
                                                                            />
                                                                        </label>

                                                                        <label
                                                                            className={
                                                                                styles.workingHoursLabel
                                                                            }
                                                                            htmlFor="timeStart"
                                                                        >
                                                                            Начало
                                                                            <Field
                                                                                className={
                                                                                    styles.workingHoursInput
                                                                                }
                                                                                id="timeStart"
                                                                                name={`specialPrices.${index}.timeStart`}
                                                                            >
                                                                                {({
                                                                                    field,
                                                                                }: FieldProps) => (
                                                                                    <>
                                                                                        <TimePicker
                                                                                            disabled={
                                                                                                isAdmin
                                                                                            }
                                                                                            name={
                                                                                                field.name
                                                                                            }
                                                                                            showSecond={
                                                                                                false
                                                                                            }
                                                                                            defaultValue={
                                                                                                field.value
                                                                                                    ? moment(
                                                                                                          field.value
                                                                                                      ).utcOffset(
                                                                                                          0
                                                                                                      )
                                                                                                    : moment()
                                                                                                          .utcOffset(
                                                                                                              0
                                                                                                          )
                                                                                                          .startOf(
                                                                                                              'day'
                                                                                                          )
                                                                                            }
                                                                                            onChange={(
                                                                                                value
                                                                                            ) => {
                                                                                                setFieldValue(
                                                                                                    `specialPrices.${index}.timeStart`,
                                                                                                    moment(
                                                                                                        value.utcOffset(
                                                                                                            0
                                                                                                        )
                                                                                                    )
                                                                                                );
                                                                                            }}
                                                                                            hideDisabledOptions
                                                                                            className={
                                                                                                styles.inputTimePicker
                                                                                            }
                                                                                            clearIcon={
                                                                                                null
                                                                                            }
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            </Field>
                                                                            <ErrorMessage
                                                                                className={
                                                                                    styles.error
                                                                                }
                                                                                name={`specialPrices.${index}.timeStart`}
                                                                                component="div"
                                                                            />
                                                                        </label>
                                                                        <label
                                                                            className={
                                                                                styles.workingHoursLabel
                                                                            }
                                                                            htmlFor="timeFinish"
                                                                        >
                                                                            Завершение
                                                                            <Field
                                                                                className={
                                                                                    styles.workingHoursInput
                                                                                }
                                                                                id="timeFinish"
                                                                                name={`specialPrices.${index}.timeFinish`}
                                                                            >
                                                                                {({
                                                                                    field,
                                                                                }: FieldProps) => (
                                                                                    <>
                                                                                        <TimePicker
                                                                                            disabled={
                                                                                                isAdmin
                                                                                            }
                                                                                            name={
                                                                                                field.name
                                                                                            }
                                                                                            showSecond={
                                                                                                false
                                                                                            }
                                                                                            defaultValue={
                                                                                                field.value
                                                                                                    ? moment(
                                                                                                          field.value
                                                                                                      ).utcOffset(
                                                                                                          0
                                                                                                      )
                                                                                                    : moment()
                                                                                                          .utcOffset(
                                                                                                              0
                                                                                                          )
                                                                                                          .startOf(
                                                                                                              'day'
                                                                                                          )
                                                                                            }
                                                                                            onChange={(
                                                                                                value
                                                                                            ) => {
                                                                                                setFieldValue(
                                                                                                    `specialPrices.${index}.timeFinish`,
                                                                                                    moment(
                                                                                                        value.utcOffset(
                                                                                                            0
                                                                                                        )
                                                                                                    )
                                                                                                );
                                                                                            }}
                                                                                            hideDisabledOptions
                                                                                            className={
                                                                                                styles.inputTimePicker
                                                                                            }
                                                                                            clearIcon={
                                                                                                null
                                                                                            }
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            </Field>
                                                                            <ErrorMessage
                                                                                className={
                                                                                    styles.error
                                                                                }
                                                                                name={`specialPrices.${index}.timeFinish`}
                                                                                component="div"
                                                                            />
                                                                        </label>
                                                                        <label
                                                                            className={
                                                                                styles.specialPricesLabel
                                                                            }
                                                                            htmlFor="price"
                                                                        >
                                                                            Стоимость
                                                                            <Field
                                                                                disabled={
                                                                                    isAdmin
                                                                                }
                                                                                className={
                                                                                    styles.workingHoursInput
                                                                                }
                                                                                id="price"
                                                                                name={`specialPrices.${index}.price`}
                                                                                placeholder="0 ₽"
                                                                            />
                                                                            <ErrorMessage
                                                                                className={
                                                                                    styles.error
                                                                                }
                                                                                name={`specialPrices.${index}.price`}
                                                                                component="div"
                                                                            />
                                                                        </label>
                                                                        <button
                                                                            disabled={
                                                                                isAdmin
                                                                            }
                                                                            type="button"
                                                                            className={
                                                                                styles.specialPricesDelBtn
                                                                            }
                                                                            onClick={() =>
                                                                                arrayHelpers.remove(
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            &#10060;
                                                                        </button>
                                                                    </div>
                                                                )
                                                            )}
                                                        <ButtonNew
                                                            disabled={isAdmin}
                                                            type="button"
                                                            appearance="plus"
                                                            icon="plus"
                                                            onClick={() =>
                                                                arrayHelpers.push(
                                                                    {
                                                                        weekDay:
                                                                            '',
                                                                        timeStart:
                                                                            '',
                                                                        timeFinish:
                                                                            '',
                                                                        price:
                                                                            '',
                                                                    }
                                                                )
                                                            }
                                                        >
                                                            Специальная цена
                                                        </ButtonNew>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    )}

                                    {/* {values.hasSingleWorkouts && (
                                        <div className={styles.sectionSingle}>
                                            <h3>Разовые занятия</h3>
                                            <div
                                                className={styles.locationTitle}
                                            >
                                                Цена в будни:
                                            </div>
                                            <div className={styles.mb}>
                                                <div
                                                    className={
                                                        styles.singleInputs
                                                    }
                                                >
                                                    <label
                                                        className={
                                                            styles.workingHoursLabel
                                                        }
                                                        htmlFor="weekDayStartTime"
                                                    >
                                                        Начало
                                                        <Field
                                                            className={
                                                                styles.workingHoursInput
                                                            }
                                                            id="timeFinish"
                                                            name={`singleWorkoutPriceInfo.weekDayStartTime`}
                                                        >
                                                            {({
                                                                field,
                                                            }: FieldProps) => (
                                                                <>
                                                                    <TimePicker
                                                                        name={
                                                                            field.name
                                                                        }
                                                                        showSecond={
                                                                            false
                                                                        }
                                                                        defaultValue={
                                                                            field.value
                                                                                ? moment(
                                                                                      field.value
                                                                                  ).utcOffset(
                                                                                      0
                                                                                  )
                                                                                : moment().startOf(
                                                                                      'day'
                                                                                  )
                                                                        }
                                                                        onChange={(
                                                                            value
                                                                        ) => {
                                                                            setFieldValue(
                                                                                `singleWorkoutPriceInfo.weekDayStartTime`,
                                                                                moment(
                                                                                    value
                                                                                ).utcOffset(
                                                                                    0
                                                                                )
                                                                            );
                                                                        }}
                                                                        hideDisabledOptions
                                                                        className={
                                                                            styles.inputTimePicker
                                                                        }
                                                                        clearIcon={
                                                                            null
                                                                        }
                                                                    />
                                                                </>
                                                            )}
                                                        </Field>
                                                    </label>
                                                    <label
                                                        className={
                                                            styles.workingHoursLabel
                                                        }
                                                        htmlFor="weekDayFinishTime"
                                                    >
                                                        Завершение
                                                        <Field
                                                            className={
                                                                styles.workingHoursInput
                                                            }
                                                            id="weekDayFinishTime"
                                                            name={`singleWorkoutPriceInfo.weekDayFinishTime`}
                                                        >
                                                            {({
                                                                field,
                                                            }: FieldProps) => (
                                                                <>
                                                                    <TimePicker
                                                                        name={
                                                                            field.name
                                                                        }
                                                                        showSecond={
                                                                            false
                                                                        }
                                                                        defaultValue={
                                                                            field.value
                                                                                ? moment(
                                                                                      field.value
                                                                                  ).utcOffset(
                                                                                      0
                                                                                  )
                                                                                : moment().startOf(
                                                                                      'day'
                                                                                  )
                                                                        }
                                                                        onChange={(
                                                                            value
                                                                        ) => {
                                                                            setFieldValue(
                                                                                `singleWorkoutPriceInfo.weekDayFinishTime`,
                                                                                moment(
                                                                                    value
                                                                                ).utcOffset(
                                                                                    0
                                                                                )
                                                                            );
                                                                        }}
                                                                        hideDisabledOptions
                                                                        className={
                                                                            styles.inputTimePicker
                                                                        }
                                                                        clearIcon={
                                                                            null
                                                                        }
                                                                    />
                                                                </>
                                                            )}
                                                        </Field>
                                                    </label>
                                                    <label
                                                        htmlFor="weekDayPrice"
                                                        className={styles.label}
                                                    >
                                                        Стоимость
                                                        <Field
                                                            className={
                                                                styles.inputSingle
                                                            }
                                                            id="weekDayPrice"
                                                            name="singleWorkoutPriceInfo.weekDayPrice"
                                                            placeholder="0 ₽"
                                                        />
                                                        <ErrorMessage
                                                            className={
                                                                styles.error
                                                            }
                                                            name="singleWorkoutPriceInfo.weekDayPrice"
                                                            component="div"
                                                        />
                                                    </label>
                                                </div>
                                                {values.hasWeekDayPrimeTime && (
                                                    <>
                                                        <div
                                                            className={
                                                                styles.locationTitle
                                                            }
                                                        >
                                                            Специальная цена в
                                                            будни:
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.singleInputs
                                                            }
                                                        >
                                                            <label
                                                                className={
                                                                    styles.workingHoursLabel
                                                                }
                                                                htmlFor="weekDayOffPeakStartTime"
                                                            >
                                                                Начало
                                                                <Field
                                                                    className={
                                                                        styles.workingHoursInput
                                                                    }
                                                                    id="weekDayOffPeakStartTime"
                                                                    name={`singleWorkoutPriceInfo.weekDayOffPeakStartTime`}
                                                                >
                                                                    {({
                                                                        field,
                                                                    }: FieldProps) => (
                                                                        <>
                                                                            <TimePicker
                                                                                name={
                                                                                    field.name
                                                                                }
                                                                                showSecond={
                                                                                    false
                                                                                }
                                                                                defaultValue={
                                                                                    field.value
                                                                                        ? moment(
                                                                                              field.value
                                                                                          ).utcOffset(
                                                                                              0
                                                                                          )
                                                                                        : moment().startOf(
                                                                                              'day'
                                                                                          )
                                                                                }
                                                                                onChange={(
                                                                                    value
                                                                                ) => {
                                                                                    setFieldValue(
                                                                                        `singleWorkoutPriceInfo.weekDayOffPeakStartTime`,
                                                                                        moment(
                                                                                            value
                                                                                        ).utcOffset(
                                                                                            0
                                                                                        )
                                                                                    );
                                                                                }}
                                                                                hideDisabledOptions
                                                                                className={
                                                                                    styles.inputTimePicker
                                                                                }
                                                                                clearIcon={
                                                                                    null
                                                                                }
                                                                            />
                                                                        </>
                                                                    )}
                                                                </Field>
                                                            </label>
                                                            <label
                                                                className={
                                                                    styles.workingHoursLabel
                                                                }
                                                                htmlFor="weekDayOffPeakFinishTime"
                                                            >
                                                                Завершение
                                                                <Field
                                                                    className={
                                                                        styles.workingHoursInput
                                                                    }
                                                                    id="weekDayOffPeakFinishTime"
                                                                    name={`singleWorkoutPriceInfo.weekDayOffPeakFinishTime`}
                                                                >
                                                                    {({
                                                                        field,
                                                                    }: FieldProps) => (
                                                                        <>
                                                                            <TimePicker
                                                                                name={
                                                                                    field.name
                                                                                }
                                                                                showSecond={
                                                                                    false
                                                                                }
                                                                                defaultValue={
                                                                                    field.value
                                                                                        ? moment(
                                                                                              field.value
                                                                                          ).utcOffset(
                                                                                              0
                                                                                          )
                                                                                        : moment().startOf(
                                                                                              'day'
                                                                                          )
                                                                                }
                                                                                onChange={(
                                                                                    value
                                                                                ) => {
                                                                                    setFieldValue(
                                                                                        `singleWorkoutPriceInfo.weekDayOffPeakFinishTime`,
                                                                                        moment(
                                                                                            value
                                                                                        ).utcOffset(
                                                                                            0
                                                                                        )
                                                                                    );
                                                                                }}
                                                                                hideDisabledOptions
                                                                                className={
                                                                                    styles.inputTimePicker
                                                                                }
                                                                                clearIcon={
                                                                                    null
                                                                                }
                                                                            />
                                                                        </>
                                                                    )}
                                                                </Field>
                                                            </label>
                                                            <label
                                                                htmlFor="weekDayOffPeakPrice"
                                                                className={
                                                                    styles.label
                                                                }
                                                            >
                                                                Стоимость
                                                                <Field
                                                                    className={
                                                                        styles.inputSingle
                                                                    }
                                                                    id="weekDayOffPeakPrice"
                                                                    name="singleWorkoutPriceInfo.weekDayOffPeakPrice"
                                                                    placeholder="0 ₽"
                                                                />
                                                                <ErrorMessage
                                                                    className={
                                                                        styles.error
                                                                    }
                                                                    name="singleWorkoutPriceInfo.weekDayOffPeakPrice"
                                                                    component="div"
                                                                />
                                                            </label>
                                                            <button
                                                                className={
                                                                    styles.singleDelBtn
                                                                }
                                                                type="button"
                                                                onClick={() => {
                                                                    setFieldValue(
                                                                        'singleWorkoutPriceInfo.weekDayOffPeakStartTime',
                                                                        null
                                                                    );
                                                                    setFieldValue(
                                                                        'singleWorkoutPriceInfo.weekDayOffPeakFinishTime',
                                                                        null
                                                                    );
                                                                    setFieldValue(
                                                                        'singleWorkoutPriceInfo.weekDayOffPeakPrice',
                                                                        null
                                                                    );
                                                                    setFieldValue(
                                                                        'hasWeekDayPrimeTime',
                                                                        false
                                                                    );
                                                                }}
                                                            >
                                                                &#10060;
                                                            </button>
                                                        </div>
                                                    </>
                                                )}

                                                {!values.hasWeekDayPrimeTime && (
                                                    <ButtonNew
                                                        type="button"
                                                        appearance="plus"
                                                        icon="plus"
                                                        onClick={() =>
                                                            setFieldValue(
                                                                'hasWeekDayPrimeTime',
                                                                !values.hasWeekDayPrimeTime
                                                            )
                                                        }
                                                    >
                                                        Специальная цена
                                                    </ButtonNew>
                                                )}
                                            </div>

                                            <div
                                                className={styles.locationTitle}
                                            >
                                                Цена в выходные:
                                            </div>
                                            <div>
                                                <div
                                                    className={
                                                        styles.singleInputs
                                                    }
                                                >
                                                    <label
                                                        className={
                                                            styles.workingHoursLabel
                                                        }
                                                        htmlFor="weekendStartTime"
                                                    >
                                                        Начало
                                                        <Field
                                                            className={
                                                                styles.workingHoursInput
                                                            }
                                                            id="weekendStartTime"
                                                            name={`singleWorkoutPriceInfo.weekendStartTime`}
                                                        >
                                                            {({
                                                                field,
                                                            }: FieldProps) => (
                                                                <>
                                                                    <TimePicker
                                                                        name={
                                                                            field.name
                                                                        }
                                                                        showSecond={
                                                                            false
                                                                        }
                                                                        defaultValue={
                                                                            field.value
                                                                                ? moment(
                                                                                      field.value
                                                                                  ).utcOffset(
                                                                                      0
                                                                                  )
                                                                                : moment().startOf(
                                                                                      'day'
                                                                                  )
                                                                        }
                                                                        onChange={(
                                                                            value
                                                                        ) => {
                                                                            setFieldValue(
                                                                                `singleWorkoutPriceInfo.weekendStartTime`,
                                                                                moment(
                                                                                    value
                                                                                ).utcOffset(
                                                                                    0
                                                                                )
                                                                            );
                                                                        }}
                                                                        hideDisabledOptions
                                                                        className={
                                                                            styles.inputTimePicker
                                                                        }
                                                                        clearIcon={
                                                                            null
                                                                        }
                                                                    />
                                                                </>
                                                            )}
                                                        </Field>
                                                    </label>
                                                    <label
                                                        className={
                                                            styles.workingHoursLabel
                                                        }
                                                        htmlFor="weekendFinishTime"
                                                    >
                                                        Завершение
                                                        <Field
                                                            className={
                                                                styles.workingHoursInput
                                                            }
                                                            id="weekendFinishTime"
                                                            name={`singleWorkoutPriceInfo.weekendFinishTime`}
                                                        >
                                                            {({
                                                                field,
                                                            }: FieldProps) => (
                                                                <>
                                                                    <TimePicker
                                                                        name={
                                                                            field.name
                                                                        }
                                                                        showSecond={
                                                                            false
                                                                        }
                                                                        defaultValue={
                                                                            field.value
                                                                                ? moment(
                                                                                      field.value
                                                                                  ).utcOffset(
                                                                                      0
                                                                                  )
                                                                                : moment().startOf(
                                                                                      'day'
                                                                                  )
                                                                        }
                                                                        onChange={(
                                                                            value
                                                                        ) => {
                                                                            setFieldValue(
                                                                                `singleWorkoutPriceInfo.weekendFinishTime`,
                                                                                moment(
                                                                                    value
                                                                                ).utcOffset(
                                                                                    0
                                                                                )
                                                                            );
                                                                        }}
                                                                        hideDisabledOptions
                                                                        className={
                                                                            styles.inputTimePicker
                                                                        }
                                                                        clearIcon={
                                                                            null
                                                                        }
                                                                    />
                                                                </>
                                                            )}
                                                        </Field>
                                                    </label>
                                                    <label
                                                        htmlFor="weekendPrice"
                                                        className={styles.label}
                                                    >
                                                        Стоимость
                                                        <Field
                                                            className={
                                                                styles.inputSingle
                                                            }
                                                            id="weekendPrice"
                                                            name="singleWorkoutPriceInfo.weekendPrice"
                                                            placeholder="0 ₽"
                                                        />
                                                        <ErrorMessage
                                                            className={
                                                                styles.error
                                                            }
                                                            name="singleWorkoutPriceInfo.weekendPrice"
                                                            component="div"
                                                        />
                                                    </label>
                                                </div>

                                                {values.hasWeekendPrimeTime && (
                                                    <>
                                                        <div
                                                            className={
                                                                styles.locationTitle
                                                            }
                                                        >
                                                            Специальная цена в
                                                            выходные:
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.singleInputs
                                                            }
                                                        >
                                                            <label
                                                                className={
                                                                    styles.workingHoursLabel
                                                                }
                                                                htmlFor="weekendOffPeakStartTime"
                                                            >
                                                                Начало
                                                                <Field
                                                                    className={
                                                                        styles.workingHoursInput
                                                                    }
                                                                    id="weekendOffPeakStartTime"
                                                                    name={`singleWorkoutPriceInfo.weekendOffPeakStartTime`}
                                                                >
                                                                    {({
                                                                        field,
                                                                    }: FieldProps) => (
                                                                        <>
                                                                            <TimePicker
                                                                                name={
                                                                                    field.name
                                                                                }
                                                                                showSecond={
                                                                                    false
                                                                                }
                                                                                defaultValue={
                                                                                    field.value
                                                                                        ? moment(
                                                                                              field.value
                                                                                          ).utcOffset(
                                                                                              0
                                                                                          )
                                                                                        : moment().startOf(
                                                                                              'day'
                                                                                          )
                                                                                }
                                                                                onChange={(
                                                                                    value
                                                                                ) => {
                                                                                    setFieldValue(
                                                                                        `singleWorkoutPriceInfo.weekendOffPeakStartTime`,
                                                                                        moment(
                                                                                            value
                                                                                        ).utcOffset(
                                                                                            0
                                                                                        )
                                                                                    );
                                                                                }}
                                                                                hideDisabledOptions
                                                                                className={
                                                                                    styles.inputTimePicker
                                                                                }
                                                                                clearIcon={
                                                                                    null
                                                                                }
                                                                            />
                                                                        </>
                                                                    )}
                                                                </Field>
                                                            </label>
                                                            <label
                                                                className={
                                                                    styles.workingHoursLabel
                                                                }
                                                                htmlFor="weekendOffPeakFinishTime"
                                                            >
                                                                Завершение
                                                                <Field
                                                                    className={
                                                                        styles.workingHoursInput
                                                                    }
                                                                    id="weekendOffPeakFinishTime"
                                                                    name={`singleWorkoutPriceInfo.weekendOffPeakFinishTime`}
                                                                >
                                                                    {({
                                                                        field,
                                                                    }: FieldProps) => (
                                                                        <>
                                                                            <TimePicker
                                                                                name={
                                                                                    field.name
                                                                                }
                                                                                showSecond={
                                                                                    false
                                                                                }
                                                                                defaultValue={
                                                                                    field.value
                                                                                        ? moment(
                                                                                              field.value
                                                                                          ).utcOffset(
                                                                                              0
                                                                                          )
                                                                                        : moment().startOf(
                                                                                              'day'
                                                                                          )
                                                                                }
                                                                                onChange={(
                                                                                    value
                                                                                ) => {
                                                                                    setFieldValue(
                                                                                        `singleWorkoutPriceInfo.weekendOffPeakFinishTime`,
                                                                                        moment(
                                                                                            value
                                                                                        ).utcOffset(
                                                                                            0
                                                                                        )
                                                                                    );
                                                                                }}
                                                                                hideDisabledOptions
                                                                                className={
                                                                                    styles.inputTimePicker
                                                                                }
                                                                                clearIcon={
                                                                                    null
                                                                                }
                                                                            />
                                                                        </>
                                                                    )}
                                                                </Field>
                                                            </label>
                                                            <label
                                                                htmlFor="weekendOffPeakPrice"
                                                                className={
                                                                    styles.label
                                                                }
                                                            >
                                                                Стоимость
                                                                <Field
                                                                    className={
                                                                        styles.inputSingle
                                                                    }
                                                                    id="weekendOffPeakPrice"
                                                                    name="singleWorkoutPriceInfo.weekendOffPeakPrice"
                                                                    placeholder="0 ₽"
                                                                />
                                                                <ErrorMessage
                                                                    className={
                                                                        styles.error
                                                                    }
                                                                    name="singleWorkoutPriceInfo.weekendOffPeakPrice"
                                                                    component="div"
                                                                />
                                                            </label>
                                                            <button
                                                                className={
                                                                    styles.singleDelBtn
                                                                }
                                                                type="button"
                                                                onClick={() => {
                                                                    setFieldValue(
                                                                        'singleWorkoutPriceInfo.weekendOffPeakStartTime',
                                                                        null
                                                                    );
                                                                    setFieldValue(
                                                                        'singleWorkoutPriceInfo.weekendOffPeakFinishTime',
                                                                        null
                                                                    );
                                                                    setFieldValue(
                                                                        'singleWorkoutPriceInfo.weekendOffPeakPrice',
                                                                        null
                                                                    );
                                                                    setFieldValue(
                                                                        'hasWeekendPrimeTime',
                                                                        false
                                                                    );
                                                                }}
                                                            >
                                                                &#10060;
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                                {!values.hasWeekendPrimeTime && (
                                                    <ButtonNew
                                                        type="button"
                                                        appearance="plus"
                                                        icon="plus"
                                                        onClick={() =>
                                                            setFieldValue(
                                                                'hasWeekendPrimeTime',
                                                                !values.hasWeekendPrimeTime
                                                            )
                                                        }
                                                    >
                                                        Специальная цена
                                                    </ButtonNew>
                                                )}
                                            </div>
                                        </div>
                                    )} */}
                                </div>
                                <ModalDelete
                                    active={modalActiveDelete}
                                    setActive={setModalActiveDelete}
                                >
                                    <h3>
                                        Вы действительно хотите удалить
                                        фитнес-площадку?
                                    </h3>
                                    {isSuperAdmin && (
                                        <button
                                            onClick={onDelete}
                                            type="button"
                                            className={styles.btnSubmit}
                                        >
                                            Удалить
                                        </button>
                                    )}
                                </ModalDelete>
                                <div className={styles.rowBtn}>
                                    <button
                                        disabled={isAdmin || loading}
                                        className={styles.btnSubmit}
                                        type="submit"
                                    >
                                        Сохранить изменения
                                    </button>
                                    {isSuperAdmin && (
                                        <button
                                            onClick={() =>
                                                setModalActiveDelete(true)
                                            }
                                            type="button"
                                            className={styles.btnDel}
                                        >
                                            Удалить площадку
                                        </button>
                                    )}
                                </div>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </div>
    );
};

export default GymDetailsNewForm;
