import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './styles.module.css';

export interface Props {
    gotoPage: (page: number) => void;
    previousPage: () => void;
    nextPage: () => void;
    canPreviousPage: boolean;
    canNextPage: boolean;
    pageCount: number;
    pageIndex: number;
    pageOptions: any;
    pageSize: number;
    setPageSize: (pageSize: number) => void;
}

export const Pagination: React.FC<Props> = ({
    gotoPage,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    pageCount,
    pageIndex,
    pageOptions,
    pageSize,
    setPageSize,
}) => (
    <div className={styles.container}>
        <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className={styles.prevNextPageBtn}
        >
            <FontAwesomeIcon icon="chevron-left" />
            <FontAwesomeIcon icon="chevron-left" />
        </button>{' '}
        <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className={styles.prevNextPageBtn}
        >
            <FontAwesomeIcon icon="chevron-left" />
        </button>{' '}
        <span className={styles.text}>
            Страница {pageIndex + 1} из {pageOptions.length}{' '}
        </span>
        <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className={styles.prevNextPageBtn}
        >
            <FontAwesomeIcon icon="chevron-right" />
        </button>{' '}
        <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className={styles.prevNextPageBtn}
        >
            <FontAwesomeIcon icon="chevron-right" />
            <FontAwesomeIcon icon="chevron-right" />
        </button>{' '}
        <span>
            <span className={styles.text}>| К странице: </span>
            <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                    const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                    gotoPage(page);
                }}
                className={styles.input}
            />
        </span>{' '}
        <select
            value={pageSize}
            onChange={(e) => {
                setPageSize(Number(e.target.value));
            }}
            className={styles.select}
        >
            {[10, 20, 30, 40, 50, 100].map((amount) => (
                <option key={amount} value={amount}>
                    Показать {amount}
                </option>
            ))}
        </select>
    </div>
);

export default Pagination;
