import React, { useState } from 'react';
import { WrappedFieldProps } from 'redux-form';
import classNames from 'classnames';
import Eye from '../Icons/Eye';

import styles from './styles.module.css';

export interface Props {
    hidden?: boolean;
    label: string;
    name: string;
    type: 'email' | 'text' | 'password';
    maxLength?: number;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    toggleVisibility?: boolean;
    className?: string;
    autoFocus?: boolean;
    pattern?: string;
    data: string;
    phone: boolean;
}

const TextInput: React.FC<Props & WrappedFieldProps> = ({
    input,
    meta,
    name,
    type,
    maxLength,
    label,
    placeholder,
    required,
    disabled,
    hidden,
    className,
    autoFocus,
    pattern,
}) => {
    const [sensitiveDataVisible, setSensitiveDataVisible] = useState<boolean>(
        false
    );

    const onClickVisibilityBtn = () =>
        setSensitiveDataVisible(!sensitiveDataVisible);

    return (
        <div className={classNames(styles.wrapper, className)} hidden={hidden}>
            <label htmlFor={name} className={styles.label}>
                {label}
            </label>
            <input
                {...input}
                type={
                    type === 'password'
                        ? sensitiveDataVisible
                            ? 'text'
                            : 'password'
                        : type
                }
                maxLength={maxLength}
                placeholder={placeholder}
                required={required}
                disabled={disabled}
                className={styles.input}
                autoFocus={autoFocus}
                pattern={pattern}
            />
            {type === 'password' && (
                <button
                    type="button"
                    onClick={onClickVisibilityBtn}
                    className={styles.toggleVisibilityBtn}
                >
                    <Eye />
                </button>
            )}
            {meta && meta.touched && (meta.error || meta.warning) && (
                <span className={styles.error}>
                    {meta.error || meta.warning}
                </span>
            )}
        </div>
    );
};

export default TextInput;
