import React from 'react';
import { InjectedFormProps, Field, reduxForm } from 'redux-form';
import moment from 'moment';
import XLSX from 'xlsx-js-style';
import fileSaver from 'file-saver';

import TextInput from '@components/TextInput';
import Button from '@components/Button';
import { Report } from '@t/report';

import styles from './styles.module.css';

export interface ReportFormData {
    date: string;
    status: string;
}

export interface Props {
    report: Report;
}

const ReportForm: React.FC<
    Props & InjectedFormProps<ReportFormData, Props>
> = ({ handleSubmit, report }) => {
    const generateReport = () => {
        const ws = XLSX.utils.json_to_sheet(report.bookings);
        for (const i in ws) {
            if (Object.prototype.hasOwnProperty.call(ws, i)) {
                if (i === `${i[0]}1`)
                    ws[i].s = {
                        font: {
                            bold: true,
                        },
                    };
            }
        }

        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, {
            bookType: 'xlsx',
            type: 'array',
        });
        const data = new Blob([excelBuffer], {
            type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        });
        fileSaver.saveAs(
            data,
            `sports-compensation-${moment().format('DD-MM-YYYY-HH-mm')}.xlsx`
        );
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Field
                disabled
                name="date"
                label="Дата отчета"
                component={TextInput}
            />

            <Field
                disabled
                name="status"
                label="Статус отчета"
                component={TextInput}
            />

            <Button
                onClick={generateReport}
                type="button"
                className={styles.btn}
            >
                Скачать отчет
            </Button>
        </form>
    );
};

export default reduxForm<ReportFormData, Props>({ form: 'Report' })(ReportForm);
