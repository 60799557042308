import React, {
    ButtonHTMLAttributes,
    DetailedHTMLProps,
    ReactNode,
} from 'react';
import cn from 'classnames';

import Plus from '@components/Icons/Plus';
import Download from '@components/Icons/Download';

import styles from './styles.module.css';

export interface ButtonProps
    extends DetailedHTMLProps<
        ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    children: ReactNode;
    appearance: 'qr' | 'plus';
    icon?: 'download' | 'plus' | 'none';
}

export const ButtonNew = ({
    appearance,
    icon = 'none',
    children,
    className,
    ...props
}: ButtonProps): JSX.Element => {
    return (
        <button
            className={cn(styles.button, className, {
                [styles.qr]: appearance === 'qr',
                [styles.plus]: appearance === 'plus',
            })}
            {...props}
        >
            {icon === 'download' && (
                <span className={styles.download}>
                    <Download />
                </span>
            )}
            {icon === 'plus' && (
                <span className={styles.plusIcon}>
                    <Plus />
                </span>
            )}
            {children}
        </button>
    );
};
