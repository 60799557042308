import { ApplicationState } from '@redux/types';
import { IGroupWorkoutReservationNew } from '@t/reservation';

export const getReservations = (
    state: ApplicationState
): IGroupWorkoutReservationNew[] =>
    state.reservations.ids.map((id: string) => state.reservations.byId[id]);

export const getReservation = (id: string) => (
    state: ApplicationState
): IGroupWorkoutReservationNew => state.reservations.byId[id];

export const getReservationsLastUpdated = (state: ApplicationState): number =>
    state.reservations.lastUpdated;
