import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import XLSX from 'xlsx-js-style';
import fileSaver from 'file-saver';
import cogoToast from 'cogo-toast';

import { getBookings } from '@redux/modules/bookings/selectors';
import {
    fetchCorporateBookings,
    fetchScrollCorporateBookings,
    fetchCorporateBookingsForReport,
} from '@redux/modules/bookings/actions';
import Box from '@components/Box';
import BoxButton from '@components/BoxButton';
import { getSelectedGymId } from '@redux/modules/gyms/selectors';
import { Booking } from '@t/booking';
import { AsyncDispatch } from '@redux/types';

import BookingListTable from './CorporateBookingListTable';
import styles from './styles.module.css';

export const defaultBookingSort = (bookings: Booking[]): Booking[] => {
    const sortedBookings = bookings.sort((a, b) => {
        if (!a.paid && !a.active) {
            return -1;
        } else if (!b.paid && !b.active) {
            return 1;
        } else {
            return (
                new Date(b.timeStarted).getTime() -
                new Date(a.timeStarted).getTime()
            );
        }
    });
    return sortedBookings;
};

export const CorporateBookingList: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const unsortedBookings = useSelector(getBookings);
    const bookings = defaultBookingSort(unsortedBookings);
    const selectedGymId = useSelector(getSelectedGymId);
    const [loading, setLoading] = useState(false);
    const [reportIsGenerated, setReportIsGenerated] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [bookingIsOver, setBookingIsOver] = useState(false);

    const scrollHandler = (event: any) => {
        if (
            event.target.documentElement.scrollHeight -
                (event.target.documentElement.scrollTop + window.innerHeight) <
                400 &&
            event.target.documentElement.scrollTop > 1000
        ) {
            setFetching(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    useEffect(() => {
        dispatch(fetchCorporateBookings({ bookingCount: 0 }))
            .then((bookings) => {
                if (bookings.length < 99) {
                    setLoading(false);
                    setBookingIsOver(true);
                } else {
                    setLoading(false);
                    setBookingIsOver(false);
                }
            })
            .catch(() => {
                cogoToast.error('Ошибка при загрузке тренировок', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            })
            .finally(() => setFetching(false));
    }, [dispatch, selectedGymId]);

    useEffect(() => {
        if (fetching && !bookingIsOver) {
            setLoading(true);
            dispatch(
                fetchScrollCorporateBookings({
                    bookingCount: unsortedBookings.length,
                })
            )
                .then((bookings) => {
                    if (bookings.length < 99) {
                        setLoading(false);
                        setBookingIsOver(true);
                    } else {
                        setLoading(false);
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при загрузке тренировок', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => setFetching(false));
        }
    }, [dispatch, fetching]);

    const generateReport = () => {
        setReportIsGenerated(true);
        dispatch(fetchCorporateBookingsForReport())
            .then((dataForXLSX) => {
                const ws = XLSX.utils.json_to_sheet(dataForXLSX);
                for (const i in ws) {
                    if (Object.prototype.hasOwnProperty.call(ws, i)) {
                        if (i === `${i[0]}1`)
                            ws[i].s = {
                                font: {
                                    bold: true,
                                },
                            };
                    }
                }

                const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, {
                    bookType: 'xlsx',
                    type: 'array',
                });
                const data = new Blob([excelBuffer], {
                    type:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
                });
                fileSaver.saveAs(
                    data,
                    `sports-compensation-${moment().format(
                        'DD-MM-YYYY-HH-mm'
                    )}.xlsx`
                );
            })
            .catch(() => {
                cogoToast.error('Ошибка при формирования отчета', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            })
            .finally(() => setReportIsGenerated(false));
    };

    return (
        <div>
            <h1>Тренировки</h1>

            <h3>Действия</h3>
            <div className={styles.actions}>
                <BoxButton
                    icon="spreadsheet"
                    title={
                        reportIsGenerated
                            ? 'Формируем отчет...'
                            : 'Выгрузить данные'
                    }
                    onClick={() => generateReport()}
                    className={styles.actionBtn}
                    disabled={!unsortedBookings.length}
                />
            </div>

            <Box>
                <div className={styles.tableWrapper}>
                    <BookingListTable bookings={bookings} loading={loading} />
                </div>
            </Box>
        </div>
    );
};

export default CorporateBookingList;
