import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { AbortReasonType, DATE_TIME_FORMAT } from '@config';
import { getDuration } from '@containers/Dashboard/BookingList/BookingListTable';
import { getAppUser, getBooking } from '@redux/modules/bookings/selectors';
import { fetchBooking } from '@redux/modules/bookings/actions';
import { AsyncDispatch } from '@redux/types';
import { BookingType } from '@t/booking';
import BackButton from '@components/BackButton';
import Loader from '@components/Loader';

import styles from './styles.module.css';
import CorporateBookingForm from './Form';

const abortReasonsMap = {
    [AbortReasonType.INTERNET_DISCONNECT]: 'Отсутствие интернет соединения',
    [AbortReasonType.DISCHARGED_PHONE]: 'Разряженный телефон',
    [AbortReasonType.FORGOT_TO_SCAN_QR_CODE]: 'Забыл отсканировать QR код',
};

export const CorporateBookingDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const booking = useSelector(getBooking(id || ''));
    const appUser = useSelector(getAppUser(id || ''));

    useEffect(() => {
        if (!id) {
            return;
        }
        dispatch(fetchBooking(id));
    }, [dispatch, id]);

    if (!booking) {
        return null;
    }

    const isCouponApplied = booking.couponApplied;
    const isBonusWithdrawn = booking.isBonusWithdrawn || false;

    const initialValues = {
        userId: booking.mongoUserId,
        bookingId: booking._id,
        groupWorkoutReservationId: booking?.groupWorkoutReservationId || '',
        gymTitle: booking.gymTitle,
        dateStart: !booking.timeStarted
            ? ''
            : moment(booking.timeStarted).format(DATE_TIME_FORMAT),
        dateFinish: !booking.timeFinished
            ? ''
            : moment(booking.timeFinished).format(DATE_TIME_FORMAT),
        duration: !booking.timeFinished
            ? ''
            : `${getDuration(booking.timeFinished, booking.timeStarted)} мин`,
        payStatus: booking.paid ? 'Оплачено' : 'Не оплачено',
        status: booking.active ? 'В процессе' : 'Завершена',
        price: !booking.timeFinished
            ? ' '
            : booking.couponApplied ||
              booking.isBonusWithdrawn ||
              booking.type === BookingType.GroupWorkout
            ? `${booking.initialPrice}₽`
            : `${booking.price}₽`,
        abortReason:
            booking.abortReasonType === AbortReasonType.OTHER
                ? booking.abortReasonMessage
                : abortReasonsMap[booking.abortReasonType],
        appUserFirstName: !appUser?.firstName ? '' : appUser.firstName,
        appUserLastName: !appUser?.lastName ? '' : appUser.lastName,
        appUserEmail: !appUser?.email ? '' : appUser.email,
        appUserPhone: !appUser?.phoneNumber
            ? ''
            : appUser.phoneNumber.replace(
                  /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
                  '$1 ($2) $3-$4-$5'
              ),
        discountPrice: !booking.timeFinished ? ' ' : `${booking.price}₽`,
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <BackButton
                    to="/dashboard/gyms"
                    title="К списку тренировок"
                    className={styles.backBtn}
                />
                {initialValues.appUserFirstName ? (
                    <div className={styles.box}>
                        <CorporateBookingForm
                            form="bookingInfo"
                            initialValues={initialValues}
                            isCouponApplied={isCouponApplied}
                            isBonusWithdrawn={isBonusWithdrawn}
                        />
                    </div>
                ) : (
                    <Loader />
                )}
            </div>
        </div>
    );
};

export default CorporateBookingDetails;
