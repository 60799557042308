import { User } from '@t/user';

export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const SIGN_IN_2FA_URL_SUCCESS = 'SIGN_IN_2FA_URL_SUCCESS';
export const SIGN_IN_2FA_REQUEST = 'SIGN_IN_2FA_REQUEST';
export const SIGN_IN_2FA_SUCCESS = 'SIGN_IN_2FA_SUCCESS';
export const SIGN_IN_2FA_ERROR = 'SIGN_IN_2FA_ERROR';
export const SIGN_UP_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_IN_ERROR';
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const REGISTRATION_COMPLETE_REQUEST = 'REGISTRATION_COMPLETE_REQUEST';
export const REGISTRATION_COMPLETE_SUCCESS = 'REGISTRATION_COMPLETE_SUCCESS';
export const REGISTRATION_COMPLETE_ERROR = 'REGISTRATION_COMPLETE_ERROR';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const SAVE_PASSWORD_REQUEST = 'SAVE_PASSWORD_REQUEST';
export const SAVE_PASSWORD_SUCCESS = 'SAVE_PASSWORD_SUCCESS';
export const SAVE_PASSWORD_ERROR = 'SAVE_PASSWORD_ERROR';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const REGISTRATION_PARTNER_REQUEST = 'REGISTRATION_PARTNER_REQUEST';
export const REGISTRATION_PARTNER_SUCCESS = 'REGISTRATION_PARTNER_SUCCESS';
export const REGISTRATION_PARTNER_ERROR = 'REGISTRATION_PARTNER_ERROR';

export interface AuthState {
    token: string;
    token2fa: string;
    dataUrl: string;
    qrVerified: boolean;
    user: User | null | undefined;
}
