import React from 'react';
import { IconProps } from './types';

export const Dice: React.FC<IconProps> = ({ ...props }) => (
    <svg
        enableBackground="new 0 0 512 512"
        height="512"
        viewBox="0 0 1000 1000"
        width="512"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g>
            <g transform="translate(0.000000,472.000000) scale(0.100000,-0.100000)">
                <path d="M2562.2,4012.2c-1559.5-390.4-2354.8-600.1-2383.9-625c-20.8-18.7-49.8-68.5-62.3-110c-14.5-49.8-18.7-1027.9-14.5-3017.2l6.2-2944.6l62.3-62.3c91.4-91.4,4935.9-2415,5035.6-2415c43.6,0,114.2,24.9,176.5,62.3c58.1,35.3,1067.4,564.8,2242.7,1177.4c1177.4,612.6,2157.5,1129.6,2182.4,1150.4c22.9,18.7,54,68.5,66.4,105.9c33.2,91.4,35.3,5828.9,4.2,5943.1c-12.5,41.5-39.5,91.4-62.3,112.1C9765.7,3432.9,5110.1,4604,4987.6,4602C4941.9,4599.9,3849.7,4336.2,2562.2,4012.2z M7012.2,3680L8933,3200.3l-93.4-33.2c-51.9-16.6-888.8-309.4-1860.6-647.9c-971.8-338.5-1775.4-614.7-1785.8-614.7c-33.2,0-4076.2,1295.8-4067.9,1304.1c4.2,4.2,874.2,226.4,1935.3,492.1c1196.1,299,1945.7,479.7,1978.9,471.4C5068.6,4165.9,5955.3,3943.7,7012.2,3680z M2921.5,2178.6l2066.2-662.4l6.2-3067c2.1-1686.2-2.1-3065-10.4-3062.9c-10.4,0-1011.3,479.7-2228.1,1065.3L543.8-2485.3l-6.2,2712l-4.2,2712l159.9-49.8C782.6,2861.8,1785.6,2542,2921.5,2178.6z M9462.6,218.4v-2705.7L7460.8-3529.8C6360.2-4105,5448.6-4574.3,5436.2-4574.3c-35.3,0-33.2,6076,4.1,6098.8c31.1,20.8,3962,1393.3,3997.3,1395.4C9456.3,2922,9462.6,2367.6,9462.6,218.4z" />
                <path d="M4915,3563.7c-274.1-31.2-477.6-164.1-477.6-307.3c0-72.7,118.4-182.7,245-232.6c166.1-62.3,581.4-78.9,770.4-31.2c182.7,47.8,294.9,112.1,342.6,201.4c35.3,66.4,37.4,83.1,12.5,141.2c-29.1,72.7-153.7,155.7-296.9,199.3C5388.4,3569.9,5097.7,3584.4,4915,3563.7z" />
                <path d="M3374.2,741.7C3079.3,585.9,3137.4,17,3480.1-302.9c238.8-220.1,473.5-238.8,639.6-49.8c87.2,99.7,118.4,243,87.2,425.7C4132.1,546.5,3685.6,905.7,3374.2,741.7z" />
                <path d="M2319.3-51.6c-130.8-68.5-186.9-182.7-186.9-380c0-627.1,679-1092.2,930.3-637.5c122.5,220.1,22.8,623-213.9,863.9C2674.4-24.6,2479.2,31.5,2319.3-51.6z" />
                <path d="M1287.2-813.7c-245-153.7-197.3-670.7,87.2-976c228.4-245,523.3-259.6,641.6-33.2c62.3,118.4,60.2,390.4-6.2,554.4c-62.3,159.9-189,323.9-313.6,409.1C1561.3-765.9,1391.1-749.3,1287.2-813.7z" />
                <path d="M6991.5,384.5c-292.8-143.3-512.9-569-454.8-876.3c37.4-203.5,137.1-305.3,313.6-319.8c431.9-35.3,851.4,674.9,631.3,1067.4C7386,428.1,7186.7,480,6991.5,384.5z" />
                <path d="M8268.6-533.3c-207.7-95.5-402.9-380-446.5-647.9c-51.9-303.2,87.2-527.5,326-527.5c255.4,2.1,546.1,324,612.6,677c56.1,303.2-85.1,548.2-315.6,548.2C8405.6-483.5,8326.7-506.3,8268.6-533.3z" />
            </g>
        </g>
    </svg>
);

export default Dice;
