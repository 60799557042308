export enum CouponType {
    month = 'MONTH',
    single = 'SINGLE',
    percentOff = 'PERCENT_OFF',
    full = 'FULL',
    percentOffWithRestrictions = 'PERCENT_OFF_WITH_RESTRICTIONS',
    amount = 'AMOUNT',
}

export const CouponTypeOptions = [
    { title: 'Процентный', value: CouponType.percentOff },
    { title: 'Балльный', value: CouponType.amount },
];

export const couponTypeMap = {
    [CouponType.month]: 'На месяц',
    [CouponType.single]: 'Единоразовый',
    [CouponType.percentOff]: 'Процентный',
    [CouponType.full]: 'На полную стоимость',
    [CouponType.percentOffWithRestrictions]: 'Процентный с ограничением',
    [CouponType.amount]: 'Бальный',
};

export interface Coupon {
    _id: string;
    couponName: string;
    couponType: CouponType;
    discount: number;
    activatedByUserId: string;
    activatedUsersId: string[];
    activatedStart: Date;
    activatedEnd: Date;
    activatedCount: number;
    actionStart: Date;
    actionEnd: Date;
    adCampaignId: string;
    unlimited?: boolean;
    limitPerUser?: number;
    gymIds?: string[];
    userIds?: string;
    active: boolean;
    users?: Array<string>;
    initialPrice?: number;
    bookingDuration?: number;
    createdAt: Date;
    comment: string;
}

export interface AdCampaign {
    _id: string;
    name: string;
    description: string;
}
