import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { DATE_TIME_FORMAT } from '@config';
import {
    getDuration,
    getRest,
} from '@containers/Dashboard/MembershipList/MembershipListTable/index';
import { AsyncDispatch } from '@redux/types';
import BackButton from '@components/BackButton';
import {
    getMembershipAppUser,
    getMembership,
} from '@redux/modules/memberships/selectors';
import { fetchMembership } from '@redux/modules/memberships/actions';

import styles from './styles.module.css';
import MembershipForm from './Form';

export const MembershipDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const membership = useSelector(getMembership(id || ''));
    const appUser = useSelector(getMembershipAppUser(id || ''));

    useEffect(() => {
        if (!id) {
            return;
        }
        dispatch(fetchMembership(id));
    }, [dispatch, id]);

    if (!membership) {
        return null;
    }

    const initialValues = {
        userId: membership.userId,
        membershipId: membership._id,
        gymTitle: membership.gymTitle,
        dateStart: !membership.timeStarted
            ? ''
            : moment(membership.timeStarted).format(DATE_TIME_FORMAT),
        dateFinish: !membership.timeFinished
            ? ''
            : moment(membership.timeFinished).format(DATE_TIME_FORMAT),
        type: membership.type === 'TIME' ? 'Время' : 'Посещения',
        duration: getDuration(membership.type, membership.duration),
        rest: getRest(
            membership.type,
            membership.timeFinished,
            membership.balance
        ),
        status: membership.active ? 'Активный' : 'Не активный',
        price: membership.price,
        appUserFirstName: !appUser?.firstName ? '' : appUser.firstName,
        appUserLastName: !appUser?.lastName ? '' : appUser.lastName,
        appUserEmail: !appUser?.email ? '' : appUser.email,
        appUserPhone: !appUser?.phoneNumber
            ? ''
            : appUser.phoneNumber.replace(
                  /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
                  '$1 ($2) $3-$4-$5'
              ),
        passportData: !appUser?.passportData
            ? ' '
            : `Серия: ${
                  appUser.passportData.serial
                      ? appUser.passportData.serial
                      : '一'
              }\n` +
              `Номер: ${
                  appUser.passportData.number
                      ? appUser.passportData.number
                      : '一'
              }\n` +
              `Кем выдан: ${
                  appUser.passportData.departmentName
                      ? appUser.passportData.departmentName
                      : '一'
              }\n` +
              `Дата выдачи: ${
                  appUser.passportData.dateOfIssue
                      ? appUser.passportData.dateOfIssue
                      : '一'
              }`,
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <BackButton
                    to="/dashboard/gyms"
                    title="К списку абонементов"
                    className={styles.backBtn}
                />
                <div className={styles.box}>
                    {initialValues.appUserFirstName && (
                        <MembershipForm
                            form="membershipInfo"
                            initialValues={initialValues}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default MembershipDetails;
