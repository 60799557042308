import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cogoToast from 'cogo-toast';

import { fetchContacts, updateContacts } from '@redux/modules/contacts/actions';
import { getContacts } from '@redux/modules/contacts/selectors';
import { AsyncDispatch } from '@redux/types';
import BackButton from '@components/BackButton';

import ContactsForm from './Form';

import styles from './styles.module.css';

export const Contacts: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const contacts = useSelector(getContacts);
    const [disabled, setDisabled] = useState<boolean>(true);

    useEffect(() => {
        dispatch(fetchContacts());
    }, [dispatch]);

    const onSubmit = useCallback(
        (values) => {
            dispatch(updateContacts({ values }))
                .then((contacts) => {
                    if (contacts) {
                        cogoToast.success('Контакты обновлены', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                        setDisabled(true);
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при обновлении контактов', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                });
        },
        [dispatch]
    );

    if (!contacts) {
        return null;
    }

    return (
        <div className={styles.container}>
            <h1>Контакты GOGYM</h1>

            <BackButton title="Назад" className={styles.backBtn} />

            {contacts.phone.length > 0 && (
                <div className={styles.box}>
                    <ContactsForm
                        onSubmit={onSubmit}
                        contacts={contacts}
                        disabled={disabled}
                        setDisabled={setDisabled}
                    />
                </div>
            )}
        </div>
    );
};

export default Contacts;
