import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import cogoToast from 'cogo-toast';
import Select, { createFilter, SingleValue } from 'react-select';

import { FILTER_DATE_FORMAT } from '@config';
import {
    getBookings,
    getTestBookings,
    getUnpaidBookings,
} from '@redux/modules/bookings/selectors';
import {
    fetchBookings,
    fetchScrollBookings,
    fetchUnpaidBookings,
    fetchTestBookings,
    filterBookings,
    clearTestBookings,
    clearUnpaidBookings,
    runAutoPaidBookings,
    fetchBookingsForReport,
} from '@redux/modules/bookings/actions';
import Box from '@components/Box';
import BoxButton from '@components/BoxButton';
import { getGymsList, getSelectedGymId } from '@redux/modules/gyms/selectors';
import { Booking } from '@t/booking';
import { ApplicationState, AsyncDispatch } from '@redux/types';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import Button from '@components/Button';
import { getAppUserBookings } from '@redux/modules/app-users/selectors';
import { fetchGymsList } from '@redux/modules/gyms/actions';

import BookingListTable from './BookingListTable';
import Form from './Form';
import styles from './styles.module.css';

export const defaultBookingSort = (bookings: Booking[]): Booking[] => {
    const sortedBookings = bookings.sort((a, b) => {
        if (!a.paid && !a.active) {
            return -1;
        } else if (!b.paid && !b.active) {
            return 1;
        } else {
            return (
                new Date(b.timeStarted).getTime() -
                new Date(a.timeStarted).getTime()
            );
        }
    });
    return sortedBookings;
};

export const BookingList: React.FC = () => {
    const user = useSelector(getUser);
    const gyms = useSelector(getGymsList);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;
    const isFinance = user?.role === UserRole.Finance;
    const dispatch = useDispatch<AsyncDispatch>();
    const unsortedBookings = useSelector(getBookings);
    const bookings = defaultBookingSort(unsortedBookings);
    const unpaidBookings = useSelector(getUnpaidBookings);
    const testBookings = useSelector(getTestBookings);
    const selectedGymId = useSelector(getSelectedGymId);
    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [bookingIsOver, setBookingIsOver] = useState(false);
    const [unpaidBooking, setUnpaidBooking] = useState(
        unpaidBookings.length > 0
    );
    const [testBooking, setTestBooking] = useState(testBookings.length > 0);
    const [selectedGym, setSelectedGym] = useState<
        SingleValue<{ label: string; value: string }>
    >({ label: 'Все фитнес-площадки', value: '' });
    const [generateReport, setGenerateReport] = useState(false);

    const nowDate = new Date();
    const endDateDef = moment(new Date(nowDate)).format(FILTER_DATE_FORMAT);
    const startDateDef = moment(new Date(nowDate))
        .subtract(7, 'days')
        .format(FILTER_DATE_FORMAT);

    const selector = formValueSelector('filterBookings');
    const concatenatedDatesSelector = (state: ApplicationState) =>
        selector(state, 'concatenatedDates');
    let concatenatedDates = useSelector(concatenatedDatesSelector);

    if (!concatenatedDates) {
        concatenatedDates = `${startDateDef}:${endDateDef}`;
    }

    const [startDate, endDate] = concatenatedDates.split(':');
    const initialValueDates = { concatenatedDates: `${startDate}:${endDate}` };

    const location = useLocation<{
        appUserId: string;
    }>();

    const appUserBookings = useSelector(
        getAppUserBookings(location.state?.appUserId)
    );

    if (appUserBookings?.length && !bookingIsOver) {
        setBookingIsOver(true);
    }

    if (unpaidBookings?.length && !bookingIsOver) {
        setBookingIsOver(true);
    }

    if (testBookings?.length && !bookingIsOver) {
        setBookingIsOver(true);
    }

    const scrollHandler = (event: Event) => {
        if (
            (event.target as Document).documentElement.scrollHeight -
                ((event.target as Document).documentElement.scrollTop +
                    window.innerHeight) <
                400 &&
            (event.target as Document).documentElement.scrollTop > 1000
        ) {
            setFetching(true);
        }
    };

    const onSubmitFilteredBookings = () => {
        dispatch(
            filterBookings({ startDate, endDate, selectedGymId })
        ).then(() => setBookingIsOver(true));
    };

    const onUnpaidBookings = () => {
        dispatch(fetchUnpaidBookings({ selectedGymId })).then(() => {
            setBookingIsOver(true);
            setUnpaidBooking(true);
        });
    };

    const onTestBookings = () => {
        dispatch(fetchTestBookings({ selectedGymId })).then(() => {
            setBookingIsOver(true);
            setTestBooking(true);
        });
    };

    const onRunAutoPaidBookings = useCallback(() => {
        dispatch(
            runAutoPaidBookings({ selectedGymId: selectedGym?.value || '' })
        )
            .then((res) => {
                if (res.message?.length) {
                    for (const msg of res.message) {
                        cogoToast.success(msg, {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                    }
                }
            })
            .catch(() => {
                cogoToast.error('Ошибка при оплате тренировок', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            })
            .finally(() => onUnpaidBookings());
    }, [dispatch, selectedGym]);

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    useEffect(() => {
        dispatch(fetchGymsList());
        dispatch(
            fetchBookings({
                selectedGymId,
                bookingCount: 0,
            })
        )
            .then((bookings) => {
                if (bookings.length < 99) {
                    setLoading(false);
                    setBookingIsOver(true);
                } else {
                    setLoading(false);
                    setBookingIsOver(false);
                }
            })
            .catch(() => {
                cogoToast.error('Ошибка при загрузке тренировок', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            })
            .finally(() => setFetching(false));
    }, [dispatch, selectedGymId]);

    useEffect(() => {
        if (fetching && !bookingIsOver) {
            setLoading(true);
            dispatch(
                fetchScrollBookings({
                    selectedGymId,
                    bookingCount: unsortedBookings.length,
                })
            )
                .then((bookings) => {
                    if (bookings.length < 99) {
                        setLoading(false);
                        setBookingIsOver(true);
                    } else {
                        setLoading(false);
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при загрузке тренировок', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => setFetching(false));
        }
    }, [dispatch, fetching]);

    const generateReportBookingsList = () => {
        const { hide } = cogoToast.loading(
            'Формируем отчет по тренировкам...',
            {
                position: 'top-right',
                hideAfter: 0,
            }
        );
        setGenerateReport(true);
        dispatch(fetchBookingsForReport())
            .then(() => {
                cogoToast.success('Отчет по тренировкам готов', {
                    position: 'top-right',
                    hideAfter: 5,
                });
            })
            .catch(() => {
                cogoToast.error('Ошибка при формирования отчета', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            })
            .finally(() => {
                hide && hide();
                setGenerateReport(false);
            });
    };

    const gymsOptions = gyms.map((gym) => {
        return {
            label: gym.title,
            value: gym._id,
        };
    });

    return (
        <div>
            <h1>Тренировки</h1>

            <h3>Действия</h3>
            <div className={styles.actions}>
                <div className={styles.actions}>
                    <BoxButton
                        icon="spreadsheet"
                        title={
                            generateReport
                                ? 'Формируем отчет...'
                                : 'Выгрузить данные'
                        }
                        onClick={() => generateReportBookingsList()}
                        className={styles.actionBtn}
                        disabled={!unsortedBookings.length || generateReport}
                    />
                </div>
                {unpaidBooking && isSuperAdmin && (
                    <div className={styles.paidBookings}>
                        <span className={styles.label}>Автосписание</span>
                        <Select
                            className={styles.select}
                            styles={{
                                input: (provided) => ({
                                    ...provided,
                                    minHeight: 40,
                                }),
                            }}
                            onChange={setSelectedGym}
                            defaultValue={[
                                { label: 'Все фитнес-площадки', value: '' },
                            ]}
                            options={gymsOptions}
                            filterOption={createFilter({
                                stringify: (option) => `${option.label}`,
                            })}
                            placeholder="Выберите фитнес-площадку"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: 'rgb(225, 129, 65)',
                                },
                            })}
                            noOptionsMessage={() => 'Не найдено'}
                        />
                        <Button
                            onClick={() => {
                                onRunAutoPaidBookings();
                            }}
                            type="button"
                        >
                            Списать
                        </Button>
                    </div>
                )}
            </div>

            {(isSuperAdmin || isFinance) && (
                <div className={styles.filterWrapper}>
                    <Form
                        onSubmit={onSubmitFilteredBookings}
                        initialValues={initialValueDates}
                    />

                    {unpaidBooking ? (
                        <Button
                            onClick={() => {
                                dispatch(clearUnpaidBookings());
                                setUnpaidBooking(false);
                                setBookingIsOver(false);
                            }}
                            type="button"
                            className={styles.btn}
                        >
                            Все
                        </Button>
                    ) : (
                        <Button
                            onClick={() => {
                                onUnpaidBookings();
                                setBookingIsOver(true);
                                setUnpaidBooking(true);
                            }}
                            type="button"
                            className={styles.btn}
                        >
                            Неоплаченные
                        </Button>
                    )}

                    {testBooking ? (
                        <Button
                            onClick={() => {
                                dispatch(clearTestBookings());
                                setTestBooking(false);
                                setBookingIsOver(false);
                            }}
                            type="button"
                            className={styles.btn}
                        >
                            Все
                        </Button>
                    ) : (
                        <Button
                            onClick={() => {
                                onTestBookings();
                                setBookingIsOver(true);
                                setTestBooking(true);
                            }}
                            type="button"
                            className={styles.btn}
                        >
                            Тестовые
                        </Button>
                    )}
                </div>
            )}

            <Box>
                <div className={styles.tableWrapper}>
                    {appUserBookings?.length ? (
                        <BookingListTable
                            bookings={appUserBookings}
                            loading={loading}
                        />
                    ) : (
                        <BookingListTable
                            bookings={
                                unpaidBooking
                                    ? unpaidBookings
                                    : testBooking
                                    ? testBookings
                                    : bookings
                            }
                            loading={loading}
                        />
                    )}
                </div>
            </Box>
        </div>
    );
};

export default BookingList;
