import React from 'react';
import { InjectedFormProps, Field, reduxForm } from 'redux-form';

import TextInput from '@components/TextInput';

import styles from './styles.module.css';

export interface BookingFormData {
    userId: string;
    bookingId: string;
    groupWorkoutReservationId: string;
    gymTitle: string;
    dateStart: string;
    dateFinish: string;
    duration: string;
    payStatus: string;
    status: string;
    price: string;
    abortReason: string;
    appUserFirstName: string;
    appUserLastName: string;
    appUserEmail: string;
    appUserPhone: string;
}

export interface Props {
    isCouponApplied: boolean;
    isBonusWithdrawn: boolean;
}

const CorporateBookingForm: React.FC<
    Props & InjectedFormProps<BookingFormData, Props>
> = ({ isCouponApplied, isBonusWithdrawn }) => {
    return (
        <form className={styles.form}>
            <Field
                disabled
                name="userId"
                label="ID клиента"
                component={TextInput}
            />

            <Field
                disabled
                name="bookingId"
                label="ID тренировки"
                component={TextInput}
            />

            <Field
                disabled
                name="gymTitle"
                label="Фитнес-площадка"
                component={TextInput}
            />

            <Field
                disabled
                name="appUserFirstName"
                label="Имя"
                component={TextInput}
            />

            <Field
                disabled
                name="appUserLastName"
                label="Фамилия"
                component={TextInput}
            />

            <Field
                disabled
                name="appUserEmail"
                label="Email"
                component={TextInput}
            />

            <Field
                disabled
                name="appUserPhone"
                label="Телефон"
                component={TextInput}
            />

            <Field
                disabled
                name="dateStart"
                label="Начало"
                component={TextInput}
            />

            <Field
                disabled
                name="dateFinish"
                label="Конец"
                component={TextInput}
            />

            <Field
                disabled
                name="duration"
                label="Длительность"
                component={TextInput}
            />

            <Field
                disabled
                name="payStatus"
                label="Статус оплаты"
                component={TextInput}
            />

            <Field
                disabled
                name="status"
                label="Статус"
                component={TextInput}
            />

            <Field disabled name="price" label="Цена" component={TextInput} />

            {(isCouponApplied || isBonusWithdrawn) && (
                <Field
                    disabled
                    name="discountPrice"
                    label="Цена со скидкой"
                    component={TextInput}
                />
            )}
        </form>
    );
};

export default reduxForm<BookingFormData, Props>({
    form: 'bookingInfo',
})(CorporateBookingForm);
