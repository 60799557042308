import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { AsyncDispatch } from '@redux/types';
import { createGym } from '@redux/modules/gyms/actions';
import { fetchTags } from '@redux/modules/tags/actions';
import BackButton from '@components/BackButton';
import { fetchLegalEntitiesList } from '@redux/modules/legal-entity/actions';
import { fetchManagersList } from '@redux/modules/managers/actions';
import { getLegalEntitiesList } from '@redux/modules/legal-entity/selectors';
import { getManagersList } from '@redux/modules/managers/selectors';
import { getTags } from '@redux/modules/tags/selectors';

import Form from './Form';
import styles from './styles.module.css';

export const AddGym: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const tags = useSelector(getTags);
    const legalEntitiesList = useSelector(getLegalEntitiesList);
    const managersList = useSelector(getManagersList);
    const onSubmit = useCallback(
        (values) => {
            dispatch(createGym(values))
                .then((gym) => {
                    if (gym) {
                        cogoToast.success('Фитнес-площадка создана', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                        history.push('/dashboard/gyms');
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при создании фитнес-площадки', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                });
        },
        [dispatch, history]
    );

    useEffect(() => {
        dispatch(fetchLegalEntitiesList());
        dispatch(fetchManagersList());
        dispatch(fetchTags());
    }, [dispatch]);

    const initialValues = {
        tags: { shower: true },
    };

    const legalEntitysOptions = legalEntitiesList.map((legalEntity) => {
        return {
            title: legalEntity.name,
            value: legalEntity._id,
        };
    });

    const managersOptions = managersList.map((manager) => {
        return {
            title: `${manager.firstName}  -  ${manager.email}`,
            value: manager._id,
        };
    });

    const tagsServicesOptions = tags.reduce<{ label: string; value: string }[]>(
        (acc, tag) => {
            if (
                tag.tagType.find((type) => type.tagTypeValue === 'GYMSERVICE')
            ) {
                acc.push({
                    label: tag.tagName,
                    value: tag._id,
                });
            }
            return acc;
        },
        []
    );

    const tagsAdvantagesOptions = tags.reduce<
        { label: string; value: string }[]
    >((acc, tag) => {
        if (tag.tagType.find((type) => type.tagTypeValue === 'GYMADVANTAGE')) {
            acc.push({
                label: tag.tagName,
                value: tag._id,
            });
        }
        return acc;
    }, []);

    return (
        <div className={styles.container}>
            <h1>Добавить новую фитнес-площадку</h1>

            <BackButton
                title="К списку фитнес-площадок"
                className={styles.backBtn}
            />

            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                legalEntitysOptions={legalEntitysOptions}
                managersOptions={managersOptions}
                tagsServicesOptions={tagsServicesOptions}
                tagsAdvantagesOptions={tagsAdvantagesOptions}
            />
        </div>
    );
};

export default AddGym;
