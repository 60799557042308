import React, { useEffect } from 'react';
import classNames from 'classnames';

import Delete from '@components/Icons/Delete';

import styles from './styles.module.css';

export interface Props {
    active: boolean;
    setActive: (prev: boolean) => void;
    children: React.ReactNode;
}

const Modal: React.FC<Props> = ({ active, setActive, children }) => {
    useEffect(() => {
        document.body.style.overflow = active ? 'hidden' : 'unset';

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [active]);

    return (
        <div
            className={classNames(active ? styles.modalActive : styles.modal)}
            onClick={() => setActive(false)}
        >
            <div
                className={classNames(
                    active ? styles.modalContentActive : styles.modalContent
                )}
                onClick={(e) => e.stopPropagation()}
            >
                <div
                    onClick={() => {
                        setActive(false);
                    }}
                >
                    <Delete className={styles.modalClose} />
                </div>
                {children}
            </div>
        </div>
    );
};

export default Modal;
