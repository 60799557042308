import React from 'react';
import { InjectedFormProps, Field, reduxForm } from 'redux-form';

import DateRangePickerInput from '@components/DateRangePickerInput';

import styles from './styles.module.css';

export interface FormData {
    concatenatedDates: string;
}

const Form: React.FC<InjectedFormProps<FormData>> = ({ handleSubmit }) => {
    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Field
                name="concatenatedDates"
                label="Выберите даты"
                component={DateRangePickerInput}
            />
        </form>
    );
};

export default reduxForm<FormData>({
    form: 'filterHRUsers',
})(Form);
