import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { FILTER_DATE_FORMAT } from '@config';
import { ApplicationState, AsyncDispatch } from '@redux/types';
import {
    fetchBookingsStatistics,
    filter,
} from '@redux/modules/dashboard/actions';
import {
    getDailyBookings,
    getDailyIncome,
} from '@redux/modules/dashboard/selectors';
import { getUser } from '@redux/modules/auth/selectors';
import { User } from '@t/user';
import { getSelectedGymId } from '@redux/modules/gyms/selectors';
import Toggle from '@components/Toggle';
import BookingsChart from '@components/BookingsChart';
import BookingCounter from '@components/BookingCounter';
import RevenueChart from '@components/RevenueChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './styles.module.css';
import Form from './Form';

const greetUser = (user?: User | null) => {
    const now = moment();
    const hours = now.get('hours');

    let greeting = 'Доброе утро';
    if (hours >= 12 && hours <= 17) {
        greeting = 'Добрый день';
    }

    if (hours > 17) {
        greeting = 'Добрый вечер';
    }

    const result = `${greeting}, ${user?.firstName}!`;

    return result;
};

export const Home: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const user = useSelector(getUser);
    const selectedGymId = useSelector(getSelectedGymId);
    const [isToggled, setIsToggled] = useState(false);

    const nowDate = new Date();
    const endDateDef = moment(new Date(nowDate)).format(FILTER_DATE_FORMAT);
    const startDateDef = moment(new Date(nowDate))
        .subtract(29, 'days')
        .format(FILTER_DATE_FORMAT);

    const selector = formValueSelector('filter');
    const concatenatedDatesSelector = (state: ApplicationState) =>
        selector(state, 'concatenatedDates');
    let concatenatedDates = useSelector(concatenatedDatesSelector);

    if (!concatenatedDates) {
        concatenatedDates = `${startDateDef}:${endDateDef}`;
    }

    const [startDate, endDate] = concatenatedDates.split(':');

    useEffect(() => {
        const offset = new Date().getTimezoneOffset();
        dispatch(filter({ selectedGymId, startDate, endDate, offset }));
    }, [dispatch, selectedGymId, endDate]);

    useEffect(() => {
        dispatch(fetchBookingsStatistics({ selectedGymId }));
    }, [dispatch, selectedGymId]);

    const dailyIncome = useSelector(getDailyIncome);
    const dailyBookings = useSelector(getDailyBookings);
    const initialValueDates = { concatenatedDates: `${startDate}:${endDate}` };

    const greeting = greetUser(user);

    const onRefresh = () => {
        const offset = new Date().getTimezoneOffset();
        dispatch(fetchBookingsStatistics({ selectedGymId }));
        dispatch(filter({ selectedGymId, startDate, endDate, offset }));
    };

    return (
        <div>
            <h1 className={styles.greeting}>{greeting}</h1>

            <div className={styles.row}>
                <button
                    title="Обновить"
                    className={styles.refreshBtn}
                    onClick={onRefresh}
                >
                    <FontAwesomeIcon icon="sync" />
                </button>
                <BookingCounter
                    bookingType="all"
                    className={styles.bookingCounter}
                />
                <BookingCounter
                    bookingType="active"
                    className={styles.bookingCounter}
                />
                <BookingCounter
                    bookingType="price"
                    className={styles.bookingCounter}
                />
            </div>

            <div className={styles.wpapper}>
                <Form initialValues={initialValueDates} />
                <div className={styles.toggle}>
                    <Toggle
                        isToggled={isToggled}
                        onToggle={() => setIsToggled(!isToggled)}
                    />
                    {isToggled ? (
                        <p className={styles.toggleTitle}>График тренировок</p>
                    ) : (
                        <p className={styles.toggleTitle}>График выручки</p>
                    )}
                </div>
            </div>

            {isToggled ? (
                <div className={styles.dailyIncome}>
                    <BookingsChart dailyBookings={dailyBookings} />
                    <div className={styles.filterBookingCounter}>
                        <BookingCounter bookingType="selected" />
                    </div>
                </div>
            ) : (
                <div className={styles.dailyIncome}>
                    <RevenueChart dailyIncome={dailyIncome} />
                    <div className={styles.filterBookingCounter}>
                        <BookingCounter bookingType="selected" />
                        <BookingCounter bookingType="selectedPrice" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Home;
