import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';
import cogoToast from 'cogo-toast';

import { ApplicationState, AsyncDispatch } from '@redux/types';
import {
    getGroupWorkoutById,
    getGymsList,
} from '@redux/modules/gyms/selectors';
import { createGroupWorkout, fetchGymsList } from '@redux/modules/gyms/actions';
import GroupWorkoutForm, {
    validate,
} from '@containers/Dashboard/GroupWorkoutForm';
import { fetchTags } from '@redux/modules/tags/actions';
import { getTags } from '@redux/modules/tags/selectors';

import styles from './styles.module.css';

export const GroupWorkoutDuplicate: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const groupWorkout = useSelector(getGroupWorkoutById(id || ''));
    const tags = useSelector(getTags);
    const history = useHistory();
    const [selectedTagType, setSelectedTagType] = useState<string[]>(
        groupWorkout?.tags
    );

    useEffect(() => {
        // dispatch(fetchGroupWorkoutById({ id }));
    }, [dispatch, id]);

    useEffect(() => {
        dispatch(fetchGymsList());
        dispatch(fetchTags());
    }, [dispatch]);

    const gyms = useSelector(getGymsList);
    const selector = formValueSelector('duplicateGroupWorkout');
    const gymIdSelector = (state: ApplicationState) => selector(state, 'gymId');
    const priceSelector = (state: ApplicationState) => selector(state, 'price');
    const prepaymentSelector = (state: ApplicationState) =>
        selector(state, 'prepayment');
    const isRecurringSelector = (state: ApplicationState) =>
        selector(state, 'isRecurring');
    const withPrepaymentSelector = (state: ApplicationState) =>
        selector(state, 'withPrepayment');
    const dateSelector = (state: ApplicationState) => selector(state, 'date');
    const selectedGymId = useSelector(gymIdSelector);
    const price = useSelector(priceSelector);
    const prepayment = useSelector(prepaymentSelector);
    const isRecurring = useSelector(isRecurringSelector);
    const withPrepayment = useSelector(withPrepaymentSelector);
    const originDate = useSelector(dateSelector);

    const payment = price - prepayment;

    const onSubmit = useCallback(
        (values) => {
            dispatch(createGroupWorkout({ values, tags: selectedTagType }))
                .then(() => {
                    cogoToast.success('Групповая тренировка создана', {
                        position: 'top-right',
                        hideAfter: 5,
                    });
                    history.push(`/dashboard/group-workouts`);
                })
                .catch(() => {
                    cogoToast.error(
                        'Ошибка при создании групповой тренировки',
                        {
                            position: 'top-right',
                            hideAfter: 4,
                        }
                    );
                });
        },
        [id, dispatch, selectedTagType]
    );
    const initialValues = useMemo(() => {
        if (!groupWorkout || !groupWorkout?._id) {
            return {};
        }

        const values = {
            ...groupWorkout,
            date: groupWorkout.dateStart,
        };

        return values;
    }, [groupWorkout]);

    if (!groupWorkout || !groupWorkout?._id) {
        return <div className={styles.container}>Загрузка...</div>;
    }

    const options = gyms.map((gym) => ({ title: gym.title, value: gym._id }));

    return (
        <div className={styles.container}>
            <div className={styles.box}>
                <GroupWorkoutForm
                    form="duplicateGroupWorkout"
                    validate={validate}
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    options={options}
                    gymId={selectedGymId}
                    isRecurring={isRecurring}
                    withPrepayment={withPrepayment}
                    payment={payment}
                    enableReinitialize={true}
                    originDate={originDate}
                    isPastGroupWorkout={false}
                    setSelectedTagType={setSelectedTagType}
                    tags={tags}
                    tagsId={groupWorkout?.tags}
                    btnTitle="Дублировать"
                />
            </div>
        </div>
    );
};

export default GroupWorkoutDuplicate;
