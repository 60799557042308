import { AppUser } from '@t/app-user';
import { Booking } from '@t/booking';

export const FETCH_APP_USERS_REQUEST = 'FETCH_APP_USERS_REQUEST';
export const FETCH_APP_USERS_SUCCESS = 'FETCH_APP_USERS_SUCCESS';
export const FETCH_APP_USERS_ERROR = 'FETCH_APP_USERS_ERROR';

export const FETCH_APP_USER_REQUEST = 'FETCH_APP_USER_REQUEST';
export const FETCH_APP_USER_SUCCESS = 'FETCH_APP_USER_SUCCESS';
export const FETCH_APP_USER_ERROR = 'FETCH_APP_USER_ERROR';

export const DELETE_APP_USER_REQUEST = 'DELETE_APP_USER_REQUEST';
export const DELETE_APP_USER_SUCCESS = 'DELETE_APP_USER_SUCCESS';
export const DELETE_APP_USER_ERROR = 'DELETE_APP_USER_ERROR';

export const UPDATE_APP_USER_REQUEST = 'UPDATE_APP_USER_REQUEST';
export const UPDATE_APP_USER_SUCCESS = 'UPDATE_APP_USER_SUCCESS';
export const UPDATE_APP_USER_ERROR = 'UPDATE_APP_USER_ERROR';

export const FETCH_APP_USERS_STATISTICS_REQUEST =
    'FETCH_APP_USERS_STATISTICS_REQUEST';
export const FETCH_APP_USERS_STATISTICS_SUCCESS =
    'FETCH_APP_USERS_STATISTICS_SUCCESS';
export const FETCH_APP_USERS_STATISTICS_ERROR =
    'FETCH_APP_USERS_STATISTICS_ERROR';

export const FILTER_APP_USERS_REQUEST = 'FILTER_APP_USERS_REQUEST';
export const FILTER_APP_USERS_SUCCESS = 'FILTER_APP_USERS_SUCCESS';
export const FILTER_APP_USERS_ERROR = 'FILTER_APP_USERS_ERROR';

export interface AppUserState {
    ids: string[];
    byId: {
        [id: string]: AppUser;
    };
    bookingsIds: string[];
    bookingsByUserId: {
        [id: string]: Booking[];
    };
    invitedUsersByUserId: {
        [id: string]: AppUser[];
    };
    users: number;
    registeredLastWeek: number;
    usersHaveDoneOneWorkout: number;
    usersIOS: number;
    usersAndroid: number;
    dailyAppUsers: [];
    numOfFilterAppUsers: number;
    lastUpdated: number;
}
