import { AppUser } from '@t/app-user';
import { GymMembership, Membership } from '@t/membership';

export const FETCH_MEMBERSHIPS_REQUEST = 'FETCH_MEMBERSHIPS_REQUEST';
export const FETCH_MEMBERSHIPS_SUCCESS = 'FETCH_MEMBERSHIPS_SUCCESS';
export const FETCH_MEMBERSHIPS_ERROR = 'FETCH_MEMBERSHIPS_ERROR';

export const FETCH_MEMBERSHIP_REQUEST = 'FETCH_MEMBERSHIP_REQUEST';
export const FETCH_MEMBERSHIP_SUCCESS = 'FETCH_MEMBERSHIP_SUCCESS';
export const FETCH_MEMBERSHIP_ERROR = 'FETCH_MEMBERSHIP_ERROR';

export const CREATE_MEMBERSHIP_REQUEST = 'CREATE_MEMBERSHIP_REQUEST';
export const CREATE_MEMBERSHIP_SUCCESS = 'CREATE_MEMBERSHIP_SUCCESS';
export const CREATE_MEMBERSHIP_ERROR = 'CREATE_MEMBERSHIP_ERROR';

export const FETCH_GYM_MEMBERSHIPS_REQUEST = 'FETCH_GYM_MEMBERSHIPS_REQUEST';
export const FETCH_GYM_MEMBERSHIPS_SUCCESS = 'FETCH_GYM_MEMBERSHIPS_SUCCESS';
export const FETCH_GYM_MEMBERSHIPS_ERROR = 'FETCH_GYM_MEMBERSHIPS_ERROR';

export const FETCH_GYM_MEMBERSHIP_REQUEST = 'FETCH_GYM_MEMBERSHIP_REQUEST';
export const FETCH_GYM_MEMBERSHIP_SUCCESS = 'FETCH_GYM_MEMBERSHIP_SUCCESS';
export const FETCH_GYM_MEMBERSHIP_ERROR = 'FETCH_GYM_MEMBERSHIP_ERROR';

export const UPDATE_GYM_MEMBERSHIP_REQUEST = 'UPDATE_GYM_MEMBERSHIP_REQUEST';
export const UPDATE_GYM_MEMBERSHIP_SUCCESS = 'UPDATE_GYM_MEMBERSHIP_SUCCESS';
export const UPDATE_GYM_MEMBERSHIP_ERROR = 'UPDATE_GYM_MEMBERSHIP_ERROR';

export const DELETE_GYM_MEMBERSHIP_REQUEST = 'DELETE_GYM_MEMBERSHIP_REQUEST';
export const DELETE_GYM_MEMBERSHIP_SUCCESS = 'DELETE_GYM_MEMBERSHIP_SUCCESS';
export const DELETE_GYM_MEMBERSHIP_ERROR = 'DELETE_GYM_MEMBERSHIP_ERROR';

export const FETCH_MEMBERSHIPS_USERS_SUCCESS =
    'FETCH_MEMBERSHIPS_USERS_SUCCESS';
export const FETCH_MEMBERSHIP_USER_SUCCESS = 'FETCH_MEMBERSHIP_USER_SUCCESS';

export const UPLOAD_GYM_MEMBERSHIP_DOCS_REQUEST =
    'UPLOAD_GYM_MEMBERSHIP_DOCS_REQUEST';
export const UPLOAD_GYM_MEMBERSHIP_DOCS_SUCCESS =
    'UPLOAD_GYM_MEMBERSHIP_DOCS_SUCCESS';
export const UPLOAD_GYM_MEMBERSHIP_DOCS_ERROR =
    'UPLOAD_GYM_MEMBERSHIP_DOCS_ERROR';

export const DELETE_GYM_MEMBERSHIP_DOCS_REQUEST =
    'DELETE_GYM_MEMBERSHIP_DOCS_REQUEST';
export const DELETE_GYM_MEMBERSHIP_DOCS_SUCCESS =
    'DELETE_GYM_MEMBERSHIP_DOCS_SUCCESS';
export const DELETE_GYM_MEMBERSHIP_DOCS_ERROR =
    'DELETE_GYM_MEMBERSHIP_DOCS_ERROR';

export interface MembershipState {
    ids: string[];
    byId: {
        [id: string]: Membership;
    };
    usersByMembershipId: {
        [id: string]: AppUser;
    };
    lastUpdated: number;
}

export interface GymMembershipState {
    ids: string[];
    byId: {
        [id: string]: GymMembership;
    };
    lastUpdated: number;
}
