import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { formValueSelector } from 'redux-form';
import cogoToast from 'cogo-toast';

import BackButton from '@components/BackButton';
import {
    updateGym,
    fetchGym,
    deleteGym,
    updateManagerGym,
    updateLegalEntityGym,
    fetchTabletent,
    deleteImage,
} from '@redux/modules/gyms/actions';
import { getGym } from '@redux/modules/gyms/selectors';
import { ApplicationState, AsyncDispatch } from '@redux/types';
import { getLegalEntitiesList } from '@redux/modules/legal-entity/selectors';
import { fetchLegalEntitiesList } from '@redux/modules/legal-entity/actions';
import { fetchManagersList } from '@redux/modules/managers/actions';
import { getManagersList } from '@redux/modules/managers/selectors';
import Button from '@components/Button';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import { TableTentType } from '@t/gym';
import DeleteButton from '@components/DeleteButton';

import Form from './Form';
import styles from './styles.module.css';
import UploadImage from '../UploadImage';

export const getKeyFromUrl = (url: string): string => {
    const list = url.split('/');
    return list[list.length - 1];
};

export const GymDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;
    const isAdmin = user?.role === UserRole.Admin;
    const legalEntitiesList = useSelector(getLegalEntitiesList);
    const managersList = useSelector(getManagersList);
    const [addManager, setAddManager] = useState<boolean>(false);
    const [addLegalEntity, setAddLegalEntity] = useState<boolean>(false);
    const [changedGymForm, setChangedGymForm] = useState<boolean>(false);
    const [isDeleting, updateIsDeleting] = useState<boolean>(false);
    const [loadingQR, setLoadingQR] = useState<boolean>(false);

    const gym = useSelector(getGym(id || ''));
    const selector = formValueSelector('updateGym');
    const hasSingleWorkoutsSelector = (state: ApplicationState) =>
        selector(state, 'hasSingleWorkouts');
    const hasWeekDayPrimeTimeSelector = (state: ApplicationState) =>
        selector(state, 'hasWeekDayPrimeTime');
    const hasWeekendPrimeTimeSelector = (state: ApplicationState) =>
        selector(state, 'hasWeekendPrimeTime');
    const hasPerMinWorkoutsSelector = (state: ApplicationState) =>
        selector(state, 'hasPerMinWorkouts');
    const hasGroupWorkoutsSelector = (state: ApplicationState) =>
        selector(state, 'hasGroupWorkouts');
    const withSingleWorkoutsOptionSelector = (state: ApplicationState) =>
        selector(state, 'withSingleWorkoutsOption');
    const withGroupWorkoutsOptionSelector = (state: ApplicationState) =>
        selector(state, 'withGroupWorkoutsOption');
    const hasMinWorkoutDurationSelector = (state: ApplicationState) =>
        selector(state, 'hasMinWorkoutDuration');
    const hasSingleWorkouts = useSelector(hasSingleWorkoutsSelector);
    const hasWeekDayPrimeTime = useSelector(hasWeekDayPrimeTimeSelector);
    const hasWeekendPrimeTime = useSelector(hasWeekendPrimeTimeSelector);
    const hasPerMinWorkouts = useSelector(hasPerMinWorkoutsSelector);
    const hasGroupWorkouts = useSelector(hasGroupWorkoutsSelector);
    const hasMinWorkoutDuration = useSelector(hasMinWorkoutDurationSelector);
    const withSingleWorkoutsOption = useSelector(
        withSingleWorkoutsOptionSelector
    );
    const withGroupWorkoutsOption = useSelector(
        withGroupWorkoutsOptionSelector
    );

    const gymsImagesUrls =
        gym?.previewSources?.images.filter((src) => src !== '') || [];

    useEffect(() => {
        if (!id) {
            return;
        }
        if (isSuperAdmin) {
            dispatch(fetchLegalEntitiesList());
            dispatch(fetchManagersList());
        }
        dispatch(fetchGym(id));
    }, [dispatch, id]);

    const onSubmit = useCallback(
        (values) => {
            if (!id) {
                return;
            }
            dispatch(updateGym({ id, values }))
                .then((gym) => {
                    setChangedGymForm(false);
                    if (!gym) {
                        cogoToast.error(
                            'Ошибка при обновлении деталей фитнес-площадки',
                            {
                                position: 'top-right',
                                hideAfter: 4,
                            }
                        );
                    } else {
                        cogoToast.success('Изменения сохранены', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                    }
                })
                .catch(() => {
                    cogoToast.error(
                        'Ошибка при обновлении деталей фитнес-площадки',
                        {
                            position: 'top-right',
                            hideAfter: 4,
                        }
                    );
                });
        },
        [id, dispatch, changedGymForm]
    );
    const onDeleteImageClick = useCallback(
        async (event) => {
            try {
                if (!id) {
                    return;
                }
                updateIsDeleting(true);
                const s3Key: string = getKeyFromUrl(event.target.id);
                await dispatch(deleteImage({ gymId: id, key: s3Key }));
                updateIsDeleting(false);
            } catch (err) {
                cogoToast.error(`Ошибка при удалении фото: ${err}`, {
                    position: 'top-right',
                    hideAfter: 4,
                });
            }
        },
        [id, dispatch]
    );

    const onDelete = useCallback(() => {
        dispatch(deleteGym(id))
            .then(() => {
                history.push(`/dashboard/gyms`);
            })
            .catch(() => {
                cogoToast.error('Ошибка при удалении фитнес-площадки', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    }, [dispatch, id]);

    const onUpdateManagerGym = useCallback(
        (managersIds) => {
            dispatch(updateManagerGym({ id, managersIds }))
                .then(() => {
                    cogoToast.success('Менеджер добавлен', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                    setAddManager(false);
                })
                .catch(() => {
                    cogoToast.error('Ошибка при добавлении менеджера', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                });
        },
        [dispatch, id]
    );

    const onUpdateLegalEntityGym = useCallback(
        (legalEntityId) => {
            dispatch(updateLegalEntityGym({ id, legalEntityId }))
                .then(() => {
                    cogoToast.success('Юридическое лицо добавлено', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                    setAddLegalEntity(false);
                })
                .catch(() => {
                    cogoToast.error('Ошибка при добавлении юридического лица', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                });
        },
        [dispatch, id]
    );

    const onDownloadQR = useCallback(() => {
        const { hide } = cogoToast.loading('Формируем тейблтент...', {
            position: 'top-right',
            hideAfter: 0,
        });
        setLoadingQR(true);
        dispatch(
            fetchTabletent({
                params: {
                    id,
                },
                query: {
                    type: TableTentType.TENT_DEFAULT_OLD,
                },
            })
        )
            .then((tabletent) => {
                const link = document.createElement('a');
                link.download = 'gogym-tabletent.jpeg';
                link.href = tabletent.base64;
                link.click();
                cogoToast.success('Тейблтент готов', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            })
            .catch(() => {
                cogoToast.error('Ошибка при скачивании тейблтента', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            })
            .finally(() => {
                setLoadingQR(false);
                hide && hide();
            });
    }, [dispatch, id]);

    const legalEntitysOptions = legalEntitiesList.map((legalEntity) => {
        return {
            title: legalEntity.name,
            value: legalEntity._id,
        };
    });

    const gymLegalEntity =
        gym &&
        legalEntitiesList.find(
            (legalEntity) => legalEntity._id === gym.legalEntityId
        );

    const legalEntitysOption = [
        {
            title: gymLegalEntity ? gymLegalEntity.name : ' ',
            value: gymLegalEntity ? gymLegalEntity._id : ' ',
        },
    ];

    const AllManagersOptions = managersList.map((manager) => {
        return {
            title: `${manager.firstName}  -  ${manager.email}`,
            value: manager._id,
        };
    });

    const managersOptions =
        gym &&
        gym.managersId.map((managerId: string) => {
            const manager = managersList.find(
                (manager) => manager._id === managerId
            );
            if (manager) {
                return {
                    title: `${manager.firstName}  -  ${manager.email}`,
                    value: manager._id,
                };
            } else {
                return {
                    title: managerId,
                    value: managerId,
                };
            }
        });

    const initialValues = useMemo(() => {
        if (!gym || !gym?._id) {
            return {};
        }

        const values = {
            ...gym,
            geo: {
                address: gym.address,
                shortAddress: gym.shortAddress,
                position:
                    gym.location?.coordinates?.length > 1
                        ? {
                              lat: gym.location?.coordinates[1],
                              lng: gym.location?.coordinates[0],
                          }
                        : {
                              lat: 0,
                              lng: 0,
                          },
            },
            fixedPrice: gym.pricePerMinRub,
            website: gym.website,
            phone: gym.phone,
            city: { title: gym?.city, id: gym?.cityId },
        };

        return values;
    }, [gym]);

    if (!gym || !gym?._id) {
        return <div className={styles.container}>Загрузка...</div>;
    }

    return (
        <div className={styles.container}>
            <BackButton
                to="/dashboard/gyms"
                title="К списку фитнес-площадок"
                className={styles.backBtn}
            />

            <div className={styles.box}>
                <h1>{gym.title}</h1>

                <div className={styles.qr}>
                    <QRCode value={`gogym://gyms/${gym._id}`} />

                    {isSuperAdmin && (
                        <Button className={styles.btn} onClick={onDownloadQR}>
                            {loadingQR ? 'Формируем...' : 'Скачать QR код'}
                        </Button>
                    )}
                </div>

                {!gym.authorized && (
                    <div className={styles.notAuthorizedBadge}>
                        Фитнес-площадка ожидает подтверждения от команды GOGYM.
                        В настоящее время фитнес-площадка не отображается у
                        пользователей приложения. Пожалуйста, ожидайте.
                    </div>
                )}

                {gymsImagesUrls.length > 0 && (
                    <div>
                        <h4>Фото зала</h4>
                        {isDeleting && <div>Удаление ...</div>}
                        <div className={styles.images}>
                            {gymsImagesUrls.map(
                                (url: string, index: number) => (
                                    <div className={styles.row} key={url}>
                                        <a
                                            href={url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                id={`img${index}`}
                                                src={url}
                                                className={styles.gymImage}
                                            />
                                        </a>
                                        <DeleteButton
                                            disabled={isAdmin}
                                            id={url}
                                            onClick={onDeleteImageClick}
                                        />
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                )}

                {isSuperAdmin && gymsImagesUrls.length < 5 && (
                    <UploadImage
                        disabled={isAdmin}
                        gymId={id}
                        gymImagesLength={gymsImagesUrls.length}
                    />
                )}
                <Form
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    gymId={gym._id}
                    hasSingleWorkouts={hasSingleWorkouts}
                    hasPerMinWorkouts={hasPerMinWorkouts}
                    hasGroupWorkouts={hasGroupWorkouts}
                    hasMinWorkoutDuration={hasMinWorkoutDuration}
                    withSingleWorkoutsOption={withSingleWorkoutsOption}
                    withGroupWorkoutsOption={withGroupWorkoutsOption}
                    hasWeekDayPrimeTime={hasWeekDayPrimeTime}
                    hasWeekendPrimeTime={hasWeekendPrimeTime}
                    onDelete={onDelete}
                    legalEntitysOptions={legalEntitysOptions}
                    legalEntitysOption={legalEntitysOption}
                    AllManagersOptions={AllManagersOptions}
                    managersOptions={managersOptions}
                    onUpdateManagerGym={onUpdateManagerGym}
                    addManager={addManager}
                    setAddManager={setAddManager}
                    addLegalEntity={addLegalEntity}
                    setAddLegalEntity={setAddLegalEntity}
                    onUpdateLegalEntityGym={onUpdateLegalEntityGym}
                    setChangedGymForm={setChangedGymForm}
                    changedGymForm={changedGymForm}
                />
            </div>
        </div>
    );
};

export default GymDetails;
