import { BookingType } from './booking';

export interface Advantages {
    title: string;
    description: string;
    imageUrl: string;
}

export enum SubscriptionType {
    ACTIVE = 'ACTIVE',
    CANCELLED = 'CANCELLED',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
}

export const SubscriptionTypeOptions = [
    { title: 'Активная', value: SubscriptionType.ACTIVE },
    { title: 'Отменена', value: SubscriptionType.CANCELLED },
];
export interface Subscription {
    _id: string;
    title: string;
    titleDescription: string;
    dashboardDescription: string;
    description: string;
    additionalDescription: string;
    iconUrl: string;
    pricePerMonth: number;
    gyms: string[];
    gymsExclusionList: string[];
    minPerMinPrice: number;
    maxPerMinPrice: number;
    workoutType: BookingType;
    active: boolean;
    activeForProlongation: boolean;
    advantages: Advantages[];
    isAdmin: boolean;
}

export interface UserSubscription {
    _id: string;
    userId: string;
    subscriptionId: string;
    cloudPaymentsSubscriptionId: string;
    active: boolean;
    paymentStatus: SubscriptionType;
    nextPaymentDate: Date;
    workoutType: BookingType;
    dateStarted: Date;
    dateFinished: Date;
    subscriptionTitle: string;
}
