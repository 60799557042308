import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { AsyncDispatch } from '@redux/types';
import BackButton from '@components/BackButton';
import { getGymsList } from '@redux/modules/gyms/selectors';
import { addSpecialPrice } from '@redux/modules/gyms/actions';

import Form from './Form';
import styles from './styles.module.css';

export const AddSpecialPrice: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const gyms = useSelector(getGymsList);

    const [selectedGyms, setSelectedGyms] = useState<string[]>([]);

    const onSubmit = useCallback(
        (values) => {
            dispatch(addSpecialPrice({ values, gymsId: selectedGyms }))
                .then((gyms) => {
                    cogoToast.success(
                        `Специальная стоимость добавлена для ${
                            gyms.length
                        } фитнес ${gyms.length > 1 ? 'площадок' : 'площадки'}`,
                        {
                            position: 'top-right',
                            hideAfter: 5,
                        }
                    );
                    history.push('/dashboard/gyms');
                })
                .catch(() => {
                    cogoToast.error(
                        'Ошибка при добавлении специальной стоимость',
                        {
                            position: 'top-right',
                            hideAfter: 4,
                        }
                    );
                });
        },
        [dispatch, selectedGyms]
    );

    const gymsOptions = gyms
        .filter((gym) => !gym.isHidden)
        .map((gym) => {
            return {
                label: gym.title,
                value: gym._id,
            };
        });

    return (
        <div className={styles.container}>
            <h1>Добавить специальную стоимость</h1>

            <BackButton
                title="К списку фитнес-площадок"
                className={styles.backBtn}
            />

            <Form
                onSubmit={onSubmit}
                gymsOptions={gymsOptions}
                setSelectedGyms={setSelectedGyms}
            />
        </div>
    );
};

export default AddSpecialPrice;
