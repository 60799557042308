import { AppUser, UserPaymentData } from '@t/app-user';
import { Booking } from '@t/booking';
import { Coupon } from '@t/coupon';

export const FETCH_BOOKINGS_REQUEST = 'FETCH_BOOKINGS_REQUEST';
export const FETCH_BOOKINGS_SUCCESS = 'FETCH_BOOKINGS_SUCCESS';
export const FETCH_BOOKINGS_ERROR = 'FETCH_BOOKINGS_ERROR';

export const FETCH_BOOKING_REQUEST = 'FETCH_BOOKING_REQUEST';
export const FETCH_BOOKING_SUCCESS = 'FETCH_BOOKING_SUCCESS';
export const FETCH_BOOKING_ERROR = 'FETCH_BOOKING_ERROR';

export const FETCH_BOOKINGS_USERS_SUCCESS = 'FETCH_BOOKINGS_USERS_SUCCESS';
export const FETCH_BOOKING_USER_SUCCESS = 'FETCH_BOOKING_USER_SUCCESS';
export const FETCH_BOOKING_COUPON_SUCCESS = 'FETCH_BOOKING_COUPON_SUCCESS';

export const UPDATE_BOOKING_REQUEST = 'UPDATE_BOOKING_REQUEST';
export const UPDATE_BOOKING_SUCCESS = 'UPDATE_BOOKING_SUCCESS';
export const UPDATE_BOOKING_ERROR = 'UPDATE_BOOKING_ERROR';

export const ABORT_BOOKING_REQUEST = 'ABORT_BOOKING_REQUEST';
export const ABORT_BOOKING_SUCCESS = 'ABORT_BOOKING_SUCCESS';
export const ABORT_BOOKING_ERROR = 'ABORT_BOOKING_ERROR';

export const WRITEOFFCOST_BOOKING_REQUEST = 'WRITEOFFCOST_BOOKING_REQUEST';
export const WRITEOFFCOST_BOOKING_SUCCESS = 'WRITEOFFCOST_BOOKING_SUCCESS';
export const WRITEOFFCOST_BOOKING_ERROR = 'WRITEOFFCOST_BOOKING_ERROR';

export const FETCH_SCROLL_BOOKINGS_REQUEST = 'FETCH_SCROLL_BOOKINGS_REQUEST';
export const FETCH_SCROLL_BOOKINGS_SUCCESS = 'FETCH_SCROLL_BOOKINGS_SUCCESS';
export const FETCH_SCROLL_BOOKINGS_ERROR = 'FETCH_SCROLL_BOOKINGS_ERROR';

export const FETCH_FILTER_BOOKINGS_REQUEST = 'FETCH_FILTER_BOOKINGS_REQUEST';
export const FETCH_FILTER_BOOKINGS_SUCCESS = 'FETCH_FILTER_BOOKINGS_SUCCESS';
export const FETCH_FILTER_BOOKINGS_ERROR = 'FETCH_FILTER_BOOKINGS_ERROR';

export const FETCH_UNPAID_BOOKINGS_REQUEST = 'FETCH_UNPAID_BOOKINGS_REQUEST';
export const FETCH_UNPAID_BOOKINGS_SUCCESS = 'FETCH_UNPAID_BOOKINGS_SUCCESS';
export const FETCH_UNPAID_BOOKINGS_ERROR = 'FETCH_UNPAID_BOOKINGS_ERROR';

export const FETCH_BOOKINGS_FOR_REPORT_REQUEST =
    'FETCH_BOOKINGS_FOR_REPORT_REQUEST';
export const FETCH_BOOKINGS_FOR_REPORT_SUCCESS =
    'FETCH_BOOKINGS_FOR_REPORT_SUCCESS';
export const FETCH_BOOKINGS_FOR_REPORT_ERROR =
    'FETCH_BOOKINGS_FOR_REPORT_ERROR';

export const DELETE_BOOKING_REQUEST = 'DELETE_TEST_BOOKING_REQUEST';
export const DELETE_BOOKING_SUCCESS = 'DELETE_TEST_BOOKING_SUCCESS';
export const DELETE_BOOKING_ERROR = 'DELETE_TEST_BOOKING_ERROR';

export const FETCH_TEST_BOOKINGS_REQUEST = 'FETCH_TEST_BOOKINGS_REQUEST';
export const FETCH_TEST_BOOKINGS_SUCCESS = 'FETCH_TEST_BOOKINGS_SUCCESS';
export const FETCH_TEST_BOOKINGS_ERROR = 'FETCH_TEST_BOOKINGS_ERROR';

export const START_USER_REMOTE_BOOKING_REQUEST =
    'START_USER_REMOTE_BOOKING_REQUEST';
export const START_USER_REMOTE_BOOKING_SUCCESS =
    'START_USER_REMOTE_BOOKING_SUCCESS';
export const START_USER_REMOTE_BOOKING_ERROR =
    'START_USER_REMOTE_BOOKING_ERROR';

export const CLEAR_TEST_BOOKINGS_SUCCESS = 'CLEAR_TEST_BOOKINGS_SUCCESS';
export const CLEAR_UNPAID_BOOKINGS_SUCCESS = 'CLEAR_UNPAID_BOOKINGS_SUCCESS';

export const RUN_AUTO_PAID_BOOKINGS_REQUEST = 'RUN_AUTO_PAID_BOOKINGS_REQUEST';
export const RUN_AUTO_PAID_BOOKINGS_SUCCESS = 'RUN_AUTO_PAID_BOOKINGS_SUCCESS';
export const RUN_AUTO_PAID_BOOKINGS_ERROR = 'RUN_AUTO_PAID_BOOKINGS_ERROR';

export interface BookingState {
    ids: string[];
    byId: {
        [id: string]: Booking;
    };
    unpaidBookingsIds: string[];
    unpaidBookingsById: {
        [id: string]: Booking;
    };
    testBookingsIds: string[];
    testBookingById: {
        [id: string]: Booking;
    };
    usersByBookingId: {
        [id: string]: AppUser;
    };
    couponByBookingId: {
        [id: string]: Coupon;
    };
    bookingsByUserPaymentDatas: {
        [id: string]: UserPaymentData[];
    };
    lastUpdated: number;
}
