import React from 'react';
import classNames from 'classnames';
import { InjectedFormProps, reduxForm } from 'redux-form';

import styles from './styles.module.css';

export type FormProps = {
    className?: string;
    shakeOnError?: boolean;
};

export const Form: React.FC<FormProps & InjectedFormProps<any, FormProps>> = ({
    handleSubmit,
    error,
    children,
    className,
    shakeOnError = false,
}) => (
    <form
        onSubmit={handleSubmit}
        className={classNames(
            styles.form,
            !!error && shakeOnError && styles.formShake,
            className
        )}
    >
        {children}

        {!!error && <div className={styles.error}>{error}</div>}
    </form>
);

export default reduxForm<any, FormProps>({})(Form);
