import { Subscription, UserSubscription } from '@t/subscriptions';
import { ApplicationState } from '@redux/types';

export const getSubscriptions = (state: ApplicationState): Subscription[] =>
    state.subscriptions.ids.map((id: string) => state.subscriptions.byId[id]);

export const getSubscription = (id: string) => (
    state: ApplicationState
): Subscription => state.subscriptions.byId[id];

export const getUserSubscriptions = (
    state: ApplicationState
): UserSubscription[] =>
    state.userSubscriptions.ids.map(
        (id: string) => state.userSubscriptions.byId[id]
    );

export const getUserSubscription = (id: string) => (
    state: ApplicationState
): UserSubscription => state.userSubscriptions.byId[id];
