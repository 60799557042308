import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import moment from 'moment';

import { DATE_TIME_FORMAT } from '@config';
import { getMemberships } from '@redux/modules/memberships/selectors';
import Box from '@components/Box';
import BoxButton from '@components/BoxButton';
import { getSelectedGymId } from '@redux/modules/gyms/selectors';
import { fetchMemberships } from '@redux/modules/memberships/actions';
import { Membership } from '@t/membership';

import MembershipListTable, {
    getDuration,
    getRest,
} from './MembershipListTable';
import styles from './styles.module.css';

export const MembershipList: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const memberships = useSelector(getMemberships);
    const selectedGymId = useSelector(getSelectedGymId);
    const [foundMemberships, setFoundMemberships] = useState<
        Membership[] | null
    >(null);

    useEffect(() => {
        const promises = [dispatch(fetchMemberships(selectedGymId))];

        Promise.all(promises).catch(() => {
            // TODO
        });
    }, [dispatch, selectedGymId]);

    const dataForCSV = useMemo(
        () => [
            [
                'ID Абонемента',
                'ID Клиента',
                'ID Зала',
                'Тренажерный зал',
                'Тип абонемента',
                'Срок действия',
                'Остаток',
                'Стоимость',
                'Статус',
                'Дата начала абонемента',
                'Дата завершения абонемента',
            ],
            ...memberships.map((membership) => [
                membership._id,
                membership.userId,
                membership.gymId,
                membership.gymTitle,
                membership.type === 'TIME' ? 'Время' : 'Посещения',
                `${getDuration(membership.type, membership.duration)}`,
                getRest(
                    membership.type,
                    membership.timeFinished,
                    membership.duration
                ),
                membership.price,
                membership.active ? 'Активный' : 'Не активный',
                !membership.timeStarted
                    ? ''
                    : moment(membership.timeStarted).format(DATE_TIME_FORMAT),
                !membership.timeFinished
                    ? ''
                    : moment(membership.timeFinished).format(DATE_TIME_FORMAT),
            ]),
        ],
        [memberships]
    );

    const filenameForCSV = `gogym_memberships_${Date.now()}`;

    const goToGymMemberships = useCallback(
        () => history.push('/dashboard/gym-memberships'),
        [history]
    );

    const goToMemberships = useCallback(
        () => history.push('/dashboard/memberships'),
        [history]
    );

    const handleMemberships = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFoundMemberships(
            memberships.filter((membership) =>
                membership.gymTitle
                    .toString()
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
    };

    return (
        <div>
            <h1>Абонементы</h1>

            <h3>Действия</h3>
            <div className={styles.actions}>
                <CSVLink filename={filenameForCSV} data={dataForCSV}>
                    <BoxButton
                        icon="spreadsheet"
                        title="Выгрузить данные"
                        onClick={() => {
                            // do nothing
                        }}
                        className={styles.actionBtn}
                    />
                </CSVLink>
            </div>
            <div className={styles.membershipsNav}>
                <BoxButton
                    icon=""
                    title="Абонементы фитнес-площадок"
                    onClick={goToGymMemberships}
                />

                <BoxButton
                    icon=""
                    title="Абонементы пользователей"
                    onClick={goToMemberships}
                    className={styles.actionNav}
                />
            </div>

            <input
                className={styles.input}
                type="search"
                placeholder="Поиск по названию фитнес-площадки"
                onChange={handleMemberships}
            />

            <Box className={styles.container}>
                <div className={styles.tableWrapper}>
                    {foundMemberships ? (
                        <MembershipListTable memberships={foundMemberships} />
                    ) : (
                        <MembershipListTable memberships={memberships} />
                    )}
                </div>
            </Box>
        </div>
    );
};

export default MembershipList;
