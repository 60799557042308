import React, { useState } from 'react';
import {
    InjectedFormProps,
    Field,
    reduxForm,
    formValueSelector,
} from 'redux-form';
import { useSelector } from 'react-redux';
import Select, { createFilter, components, MultiValue } from 'react-select';
import { useHistory } from 'react-router-dom';

import { getUser } from '@redux/modules/auth/selectors';
import { UserRole, UserRoleOptions } from '@t/user';
import TextInput from '@components/TextInput';
import Button from '@components/Button';
import SelectInput from '@components/SelectInput';
import CheckBoxInput from '@components/CheckBoxInput';
import Modal from '@components/Modal';
import { ApplicationState } from '@redux/types';
import MultiSelectInput from '@components/MultiSelectInput';

import styles from './styles.module.css';
import PhoneInput from '@components/PhoneInput';

export interface ManagerFormData {
    managerId: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    createdAt: string;
    role: string;
    domainsId: string[];
    verified: boolean;
    showAllGym: boolean;
}

export interface Props {
    onDeleteManager: () => void;
    gymsOptions: { label: string; value: string }[];
    gymsValue: { label: string; value: string }[];
    corporateDomainsOption: { title: string; value: string }[];
    setSelectedGyms: (prev: string[]) => void;
    disabled: boolean;
    setDisabled: (prev: boolean) => void;
    phone: string;
    setPhone: (prev: string) => void;
    phoneError: boolean;
    setPhoneError: React.Dispatch<React.SetStateAction<boolean>>;
    yclientsId: string;
    yclientsGyms: { label: string; value: string }[];
}

const validate = (values: ManagerFormData) => {
    const errors: {
        email: string;
        phone: string;
    } = {
        email: '',
        phone: '',
    };

    if (!values.email) {
        errors.email = 'Обязательное поле';
    }

    if (
        !/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(
            values.email
        )
    ) {
        errors.email = 'Введите валидный email';
    }

    return errors;
};

const ManagerForm: React.FC<
    Props & InjectedFormProps<ManagerFormData, Props>
> = ({
    handleSubmit,
    onDeleteManager,
    gymsOptions,
    gymsValue,
    corporateDomainsOption,
    setSelectedGyms,
    disabled,
    setDisabled,
    phone,
    setPhone,
    phoneError,
    setPhoneError,
    yclientsId,
    yclientsGyms,
}) => {
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;
    const [modalActive, setModalActive] = useState(false);
    const selector = formValueSelector('updateManager');
    const roleSelector = (state: ApplicationState) => selector(state, 'role');
    const roleSelected = useSelector(roleSelector);
    const history = useHistory();

    const handleChange = (
        value: MultiValue<{ label: string; value: string }>
    ) => {
        setSelectedGyms(
            value.map((gym: { label: string; value: string }) => gym.value)
        );
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Field
                disabled
                name="managerId"
                label="ID менеджера"
                component={TextInput}
            />

            {yclientsId && (
                <Field
                    disabled
                    name="yclientsId"
                    label="YCLIENTS ID"
                    component={TextInput}
                />
            )}

            {yclientsGyms.length > 0 && (
                <>
                    <span className={styles.label}>Площадки YCLIENTS</span>
                    <Select
                        className={styles.select}
                        isDisabled={true}
                        isClearable={false}
                        isMulti
                        styles={{
                            input: (provided) => ({
                                ...provided,
                                minHeight: 40,
                            }),
                            multiValueRemove: (base) => ({
                                ...base,
                                display: 'none',
                            }),
                        }}
                        defaultValue={yclientsGyms}
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                        }}
                        filterOption={createFilter({
                            stringify: (option) => `${option.label}`,
                        })}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: 'rgb(225, 129, 65)',
                            },
                        })}
                        noOptionsMessage={() => 'Не найдено'}
                    />
                </>
            )}

            <Field
                disabled
                name="createdAt"
                label="Дата регистрации"
                component={TextInput}
            />

            <span className={styles.label}>Фитнес-площадки</span>
            <Select
                className={styles.select}
                isDisabled={disabled}
                isMulti
                styles={{
                    input: (provided) => ({
                        ...provided,
                        minHeight: 40,
                    }),
                }}
                components={{
                    MultiValueLabel: (props) => {
                        return (
                            <div
                                onClick={() =>
                                    history.push(
                                        `/dashboard/gyms/${props.data.value}`
                                    )
                                }
                            >
                                <components.MultiValueLabel {...props} />
                            </div>
                        );
                    },
                }}
                defaultValue={gymsValue}
                onChange={handleChange}
                options={gymsOptions}
                filterOption={createFilter({
                    stringify: (option) => `${option.label}`,
                })}
                closeMenuOnSelect={false}
                placeholder="Выберите фитнес-площадку"
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: 'rgb(225, 129, 65)',
                    },
                })}
                noOptionsMessage={() => 'Не найдено'}
            />

            <Field
                disabled={disabled}
                name="firstName"
                label="Имя"
                component={TextInput}
            />

            <Field
                disabled={disabled}
                name="email"
                label="Email"
                component={TextInput}
            />

            <PhoneInput
                disabled={disabled}
                type="tel"
                name="phone"
                label="Телефон"
                placeholder="Телефон"
                phone={phone}
                setPhone={setPhone}
                phoneError={phoneError}
                setPhoneError={setPhoneError}
            />

            <Field
                disabled={disabled}
                name="role"
                label="Роль"
                component={SelectInput}
                options={UserRoleOptions}
            />

            {roleSelected === UserRole.HR && (
                <Field
                    disabled={disabled}
                    name="domainsId"
                    label="Домены"
                    component={MultiSelectInput}
                    options={corporateDomainsOption}
                />
            )}

            <Field
                disabled={disabled}
                name="verified"
                label="Верифицирован"
                component={CheckBoxInput}
            />

            <Field
                disabled={disabled}
                name="showAllGym"
                label="Показать все фитнес-площадки на карте?"
                component={CheckBoxInput}
            />

            {isSuperAdmin && !disabled && (
                <Button type="submit" className={styles.btn}>
                    Обновить
                </Button>
            )}

            {isSuperAdmin && disabled && (
                <Button
                    type="button"
                    onClick={() => setDisabled(false)}
                    className={styles.btn}
                >
                    Изменить
                </Button>
            )}

            <Modal active={modalActive} setActive={setModalActive}>
                <h3>Вы действительно хотите удалить менеджера?</h3>
                {isSuperAdmin && (
                    <Button
                        onClick={onDeleteManager}
                        type="button"
                        className={styles.btnModal}
                    >
                        Удалить
                    </Button>
                )}
            </Modal>

            {isSuperAdmin && (
                <Button
                    onClick={() => setModalActive(true)}
                    type="button"
                    className={styles.btn}
                >
                    Удалить
                </Button>
            )}
        </form>
    );
};

export default reduxForm<ManagerFormData, Props>({
    form: 'updateManager',
    validate,
})(ManagerForm);
