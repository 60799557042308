import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Field, SubmissionError, FormSubmitHandler, reset } from 'redux-form';
import cogoToast from 'cogo-toast';

import {
    changePassword,
    fetchUser,
    signOut,
} from '@redux/modules/auth/actions';
import { getUser } from '@redux/modules/auth/selectors';
import Button from '@components/Button';
import Form from '@components/Form';
import TextInput from '@components/TextInput';
import { AsyncDispatch } from '@redux/types';
import Modal from '@components/Modal';
import { UserRole } from '@t/user';
import { getCorporateDomains } from '@redux/modules/managers/selectors';
import { fetchCorporateDomains } from '@redux/modules/managers/actions';

import ProfileForm from './Form';
import styles from './styles.module.css';

export interface FormData {
    oldPassword: string;
    password: string;
    password2: string;
}

export const Profile: React.FC = () => {
    const [modalActive, setModalActive] = useState(false);
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const corporateDomains = useSelector(getCorporateDomains);
    const user = useSelector(getUser);
    const domains = corporateDomains
        .filter((corporateDomain) =>
            user?.domainsId.includes(corporateDomain._id)
        )
        .map((domain) => `${domain.name} - ${domain.domain}\n`)
        .join('');

    const isSuperAdmin = user?.role === UserRole.SuperAdmin;
    const isHR = user?.role === UserRole.HR;

    useEffect(() => {
        dispatch(fetchUser());
        dispatch(fetchCorporateDomains());
    }, [dispatch]);

    const onClick = () => {
        dispatch(signOut());
        history.push('/');
    };

    const onSubmit: FormSubmitHandler<FormData> = async ({
        oldPassword,
        password,
        password2,
    }) => {
        if (password !== password2) {
            throw new SubmissionError({
                password2: 'Пароли не совпадают',
            });
        }
        try {
            const user = await dispatch(
                changePassword({ oldPassword, password })
            );
            if (user) {
                dispatch(reset('changePassword'));
                setModalActive(false);
                cogoToast.success('Пароль изменен', {
                    position: 'top-right',
                    hideAfter: 5,
                });
            } else {
                throw new SubmissionError({});
            }
        } catch (err) {
            throw new SubmissionError({
                _error: 'Ошибка при изменении пароля',
            });
        }
    };

    const validate = (values: FormData) => {
        const errors: { oldPassword: string } = { oldPassword: '' };
        if (!values.oldPassword) {
            errors.oldPassword = 'Обязательное поле';
        }
        return errors;
    };

    const initialValues = {
        userId: user?._id,
        firstName: user?.firstName,
        email: user?.email,
        phone: user?.phone,
        role: user?.role,
        domain: domains,
    };

    return (
        <div className={styles.container}>
            {corporateDomains.length > 0 && (
                <div className={styles.box}>
                    <ProfileForm
                        form="profileInfo"
                        initialValues={initialValues}
                        isSuperAdmin={isSuperAdmin}
                        isHR={isHR}
                    />

                    <Modal active={modalActive} setActive={setModalActive}>
                        <Form
                            form="changePassword"
                            shakeOnError
                            validate={validate}
                            onSubmit={onSubmit}
                            className={styles.changePasswordForm}
                        >
                            <Field
                                required
                                type="password"
                                name="oldPassword"
                                label="Старый пароль"
                                placeholder="Старый пароль"
                                component={TextInput}
                            />

                            <Field
                                required
                                type="password"
                                name="password"
                                label="Пароль"
                                placeholder="Пароль"
                                component={TextInput}
                            />

                            <Field
                                required
                                type="password"
                                name="password2"
                                label="Повторите пароль"
                                placeholder="Повторите пароль"
                                component={TextInput}
                            />

                            <Button type="submit" className={styles.btn}>
                                Изменить пароль
                            </Button>
                        </Form>
                    </Modal>

                    <Button
                        type="button"
                        onClick={() => setModalActive(true)}
                        className={styles.signOutBtn}
                    >
                        Изменить пароль
                    </Button>

                    <Button
                        type="button"
                        onClick={onClick}
                        className={styles.signOutBtn}
                    >
                        Выйти из аккаунта
                    </Button>
                </div>
            )}
        </div>
    );
};

export default Profile;
