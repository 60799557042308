import React from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Coupon, CouponType, couponTypeMap } from '@t/coupon';
import LoaderVertical from '@components/LoaderVertical';

import styles from './styles.module.css';

export interface Props {
    coupons: Coupon[];
    loading: boolean;
}

const columns = [
    {
        Header: 'Название',
        accessor: 'couponName',
    },
    {
        Header: 'Тип',
        accessor: (coupon: Coupon) => couponTypeMap[coupon.couponType],
    },
    {
        Header: 'Скидка',
        accessor: (coupon: Coupon) =>
            coupon.couponType === CouponType.amount
                ? `${coupon.initialPrice}₽`
                : `${Math.round(coupon.discount * 100)}%`,
    },
    {
        Header: 'Количество тренировок',
        accessor: (coupon: Coupon) =>
            coupon.limitPerUser ? coupon.limitPerUser : '一',
    },
];

export const CouponsListTable: React.FC<Props> = ({ coupons, loading }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable(
        {
            // @ts-ignore
            columns,
            data: coupons || [],
            initialState: {
                pageIndex: 0,
                pageSize: 1000,
            },
        },
        useSortBy,
        usePagination
    );

    const history = useHistory();

    return (
        <>
            <table {...getTableProps()} className={styles.table}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={Math.random()}
                        >
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                    key={Math.random()}
                                >
                                    {column.render('Header')}
                                    <span className={styles.sorted}>
                                        <FontAwesomeIcon
                                            icon={
                                                column.isSorted
                                                    ? column.isSortedDesc
                                                        ? 'sort-up'
                                                        : 'sort-down'
                                                    : 'sort'
                                            }
                                        />
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        const onClick = () => {
                            const coupon = row.original;
                            history.push(`/dashboard/coupons/${coupon._id}`);
                        };
                        const onDuplicate = () => {
                            const coupon = row.original;
                            history.push(`/dashboard/add-coupon`, {
                                coupon,
                            });
                        };
                        return (
                            <>
                                <span
                                    title="Дублировать"
                                    onClick={onDuplicate}
                                    className={styles.btn}
                                >
                                    ...
                                </span>
                                <tr
                                    {...row.getRowProps()}
                                    className={styles.tableRow}
                                    onClick={onClick}
                                >
                                    {row.cells.map((cell) => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                key={Math.random()}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            </>
                        );
                    })}
                </tbody>
            </table>
            {loading && <LoaderVertical />}
        </>
    );
};

export default CouponsListTable;
