import React from 'react';
import { useTable, usePagination, useSortBy, Column } from 'react-table';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LegalEntity, СollaborationType } from '@t/legal-entity';
import { EXCLUSIVE_TYPE_OF_COLLABORATION } from '@config';
import LoaderVertical from '@components/LoaderVertical';

import styles from './styles.module.css';

export interface Props {
    legalEntitys: LegalEntity[];
    loading: boolean;
}

const columns: Column<LegalEntity>[] = [
    {
        Header: 'Название',
        accessor: 'name',
    },
    {
        Header: 'Фитнес-площадка',
        accessor: (legalEntity: LegalEntity) => {
            return {
                title: legalEntity.gymsTitle.map(
                    (gymTitle) => gymTitle && `${gymTitle}; `
                ),
                styleClass: styles.line,
            };
        },
        Cell: ({ cell: { value } }: any) => (
            <div className={value.styleClass}>{value.title}</div>
        ),
    },
    {
        Header: 'Комиссия GOGYM',
        accessor: (legalEntity: LegalEntity) => {
            if (legalEntity.goGymCommission.length) {
                let perMinCommission = '';
                let groupWorkoutCommission = '';
                let singleWorkoutCommission = '';
                let membershipCommission = '';
                for (const goGymFee of legalEntity.goGymCommission) {
                    if (
                        goGymFee.collaborationType ===
                        СollaborationType.PerMinWorkout
                    ) {
                        perMinCommission = `Поминутные 一 ${goGymFee.commission}%\n`;
                    } else if (
                        goGymFee.collaborationType ===
                        СollaborationType.GroupWorkout
                    ) {
                        groupWorkoutCommission = `Групповые 一 ${goGymFee.commission}%\n`;
                    } else if (
                        goGymFee.collaborationType ===
                        СollaborationType.SingleWorkout
                    ) {
                        singleWorkoutCommission = `Разовые 一 ${goGymFee.commission}₽\n`;
                    } else if (
                        goGymFee.collaborationType ===
                        СollaborationType.Membership
                    ) {
                        membershipCommission = `Абонемент 一 ${goGymFee.commission}₽\n`;
                    }
                }
                return `${perMinCommission}${groupWorkoutCommission}${singleWorkoutCommission}${membershipCommission}`;
            } else {
                return '一';
            }
        },
    },
    {
        Header: 'Комиссия эквайринга',
        accessor: (legalEntity: LegalEntity) =>
            legalEntity.acquiringCommission
                ? `${legalEntity.acquiringCommission}%`
                : '一',
    },
    {
        Header: 'Тип сотрудничества',
        accessor: (legalEntity: LegalEntity) =>
            EXCLUSIVE_TYPE_OF_COLLABORATION.includes(legalEntity._id.toString())
                ? 'Эксклюзивный'
                : 'Обычный',
    },
    {
        Header: 'Договор',
        accessor: (legalEntity: LegalEntity) => {
            const docs = legalEntity.docs || [];
            return docs.map((doc) => (
                <a
                    key={doc}
                    href={doc}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        alt="Договор"
                        src={'https://api.gogym.fit/s3-gogym-icons/pdf.svg'}
                        className={styles.docImage}
                    />
                </a>
            ));
        },
    },
];

export const LegalEntityListTable: React.FC<Props> = ({
    legalEntitys,
    loading,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable(
        {
            columns,
            data: legalEntitys || [],
            initialState: {
                pageIndex: 0,
                pageSize: 1000,
            },
        },
        useSortBy,
        usePagination
    );
    const history = useHistory();

    return (
        <>
            <table {...getTableProps()} className={styles.table}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={Math.random()}
                        >
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                    key={Math.random()}
                                >
                                    {column.render('Header')}
                                    <span className={styles.sorted}>
                                        <FontAwesomeIcon
                                            icon={
                                                column.isSorted
                                                    ? column.isSortedDesc
                                                        ? 'sort-up'
                                                        : 'sort-down'
                                                    : 'sort'
                                            }
                                        />
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        const onClick = (legalentityId: string) => {
                            history.push(
                                `/dashboard/legalentity/${legalentityId}`
                            );
                        };
                        return (
                            <tr
                                {...row.getRowProps()}
                                className={styles.tableRow}
                                key={row.id}
                            >
                                {row.cells.map((cell, index) => {
                                    if (index === row.cells.length - 1) {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                key={cell.getCellProps().key}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    } else {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                key={cell.getCellProps().key}
                                                onClick={() =>
                                                    onClick(row.original._id)
                                                }
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    }
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {loading && <LoaderVertical />}
        </>
    );
};

export default LegalEntityListTable;
