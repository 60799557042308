import React from 'react';
import { InjectedFormProps, Field, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';

import TextInput from '@components/TextInput';
import Button from '@components/Button';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import PhoneInput from '@components/PhoneInput';

import styles from './styles.module.css';

export interface FormData {
    email: string;
    password: string;
    password2: string;
    firstName: string;
    phone: string;
}

export interface Props {
    phone: string;
    setPhone: (prev: string) => void;
    phoneError: boolean;
    setPhoneError: React.Dispatch<React.SetStateAction<boolean>>;
}

const validate = (values: FormData) => {
    const errors: {
        email: string;
        firstName: string;
        password: string;
        password2: string;
        phone: string;
    } = {
        email: '',
        firstName: '',
        password: '',
        password2: '',
        phone: '',
    };

    if (!values.email) {
        errors.email = 'Обязательное поле';
    }

    if (
        !/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(
            values.email
        )
    ) {
        errors.email = 'Введите валидный email';
    }

    if (!values.firstName) {
        errors.firstName = 'Обязательное поле';
    }

    if (!values.password) {
        errors.password = 'Обязательное поле';
    }

    if (!values.password2) {
        errors.password2 = 'Обязательное поле';
    }

    if (
        !/[+]7\s[(][0-9]{3}[)]\s[0-9]{3}[-][0-9]{2}[-][0-9]{2}/.test(
            values.phone
        )
    ) {
        errors.phone = 'Формат +7 (900) 123-45-67';
    }

    return errors;
};

const AddManagerForm: React.FC<Props & InjectedFormProps<FormData, Props>> = ({
    handleSubmit,
    phone,
    setPhone,
    phoneError,
    setPhoneError,
}) => {
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Field
                required
                type="email"
                name="email"
                label="Email"
                placeholder="Email"
                component={TextInput}
            />

            <Field
                required
                type="password"
                name="password"
                label="Пароль"
                placeholder="Пароль"
                component={TextInput}
            />

            <Field
                required
                type="password"
                name="password2"
                label="Повторите пароль"
                placeholder="Повторите пароль"
                component={TextInput}
            />

            <Field
                required
                type="text"
                name="firstName"
                label="Имя"
                placeholder="Имя"
                component={TextInput}
            />

            <PhoneInput
                type="tel"
                name="phone"
                label="Телефон"
                placeholder="Телефон"
                phone={phone}
                setPhone={setPhone}
                phoneError={phoneError}
                setPhoneError={setPhoneError}
            />

            {isSuperAdmin && (
                <Button type="submit" className={styles.btn}>
                    Добавить менеджера
                </Button>
            )}
        </form>
    );
};

export default reduxForm<FormData, Props>({
    form: 'addManager',
    validate,
})(AddManagerForm);
