import React from 'react';
import { IconProps } from './types';

export const Gym: React.FC<IconProps> = ({ ...props }) => (
    <svg
        {...props}
        enableBackground="new 0 0 512 512"
        height="512"
        viewBox="0 0 512 512"
        width="512"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <g>
                <path d="m502 160h-44v-150c0-5.522-4.477-10-10-10h-384c-5.523 0-10 4.478-10 10v150h-44c-5.523 0-10 4.478-10 10v332c0 5.522 4.477 10 10 10h492c5.523 0 10-4.478 10-10v-332c0-5.522-4.477-10-10-10zm-160 120v212h-76v-212zm20 0h130v164h-130zm-151-100c5.523 0 10-4.478 10-10s-4.477-10-10-10h-137v-140h364v140h-137c-5.523 0-10 4.478-10 10s4.477 10 10 10h191v80h-472v-80zm-61 264h-130v-164h130zm-130 20h130v28h-130zm150-184h76v212h-76zm322 212h-130v-28h130z" />
                <path d="m314.899 139.216c5.436.979 10.636-2.629 11.617-8.064l7.917-43.865 13.073 44.313c.035.118.072.236.112.354 1.619 4.819 6.111 8.047 11.191 8.047h.06c5.104-.025 9.594-3.305 11.171-8.159.038-.115.073-.231.106-.349l12.621-44.274 8.416 44.034c1.037 5.425 6.277 8.979 11.699 7.945 5.425-1.037 8.982-6.274 7.945-11.699l-14.893-77.929c-.008-.04-.016-.079-.023-.119-1.069-5.25-5.553-9.129-10.902-9.432-5.355-.291-10.242 3.045-11.897 8.142-.037.115-.072.231-.105.348l-14.33 50.27-14.861-50.371c-.035-.119-.073-.238-.112-.355-1.708-5.081-6.644-8.375-11.984-8.02-5.348.359-9.791 4.287-10.803 9.551-.007.037-.014.075-.021.112l-14.061 77.904c-.981 5.434 2.629 10.635 8.064 11.616z" />
                <path d="m185.778 80h-23.932c-5.523 0-10 4.478-10 10s4.477 10 10 10h12.775c-2.552 10.601-9.565 20-23.374 20-16.542 0-30-13.458-30-30s13.458-30 30-30c6.013 0 11.811 1.77 16.767 5.118 4.578 3.092 10.793 1.888 13.884-2.689s1.887-10.793-2.689-13.885c-8.274-5.589-17.943-8.544-27.961-8.544-27.57 0-50 22.43-50 50s22.43 50 50 50c26.218 0 44.53-20.561 44.53-50 0-5.522-4.477-10-10-10z" />
                <path d="m241.298 130c0 5.522 4.477 10 10 10s10-4.478 10-10v-37.894l23.755-36.442c3.016-4.627 1.71-10.822-2.917-13.838-4.626-3.019-10.823-1.71-13.838 2.916l-17.001 26.083-17.142-26.287c-3.017-4.626-9.212-5.933-13.839-2.914-4.626 3.017-5.931 9.212-2.914 13.838l23.896 36.645z" />
                <path d="m66.429 418.571c1.953 1.952 4.512 2.929 7.071 2.929s5.119-.977 7.071-2.929l41-41c3.905-3.905 3.905-10.237 0-14.143-3.905-3.904-10.237-3.904-14.143 0l-41 41c-3.905 3.906-3.905 10.238.001 14.143z" />
                <path d="m41.595 368.404c1.953 1.952 4.512 2.929 7.071 2.929s5.119-.977 7.071-2.929l41-41c3.905-3.905 3.905-10.237 0-14.143-3.905-3.904-10.237-3.904-14.143 0l-41 41c-3.904 3.906-3.904 10.238.001 14.143z" />
                <path d="m463.571 363.429c-3.905-3.904-10.237-3.904-14.143 0l-41 41c-3.905 3.905-3.905 10.237 0 14.143 1.953 1.952 4.512 2.929 7.071 2.929s5.119-.977 7.071-2.929l41-41c3.907-3.906 3.907-10.238.001-14.143z" />
                <path d="m438.738 313.262c-3.905-3.904-10.237-3.904-14.143 0l-41 41c-3.905 3.905-3.905 10.237 0 14.143 1.953 1.952 4.512 2.929 7.071 2.929s5.119-.977 7.071-2.929l41-41c3.906-3.906 3.906-10.238.001-14.143z" />
                <path d="m256 160.04c-2.63 0-5.21 1.06-7.07 2.92-1.86 1.87-2.93 4.439-2.93 7.08 0 2.63 1.07 5.21 2.93 7.069 1.86 1.86 4.44 2.931 7.07 2.931s5.21-1.07 7.07-2.931c1.86-1.859 2.93-4.439 2.93-7.069 0-2.641-1.07-5.21-2.93-7.08-1.86-1.86-4.44-2.92-7.07-2.92z" />
            </g>
        </g>
    </svg>
);

export default Gym;
