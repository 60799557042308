import React, { useState } from 'react';
import { SubmissionError } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { addManager } from '@redux/modules/auth/actions';
import { AsyncDispatch } from '@redux/types';
import BackButton from '@components/BackButton';

import Form from './Form';
import styles from './styles.module.css';

export interface FormData {
    email: string;
    password: string;
    password2: string;
    firstName: string;
    phone: string;
}

export const AddManager: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const [phone, setPhone] = useState<string>('');
    const [phoneError, setPhoneError] = useState<boolean>(false);

    const onSubmit = async ({
        email,
        password,
        password2,
        firstName,
    }: {
        email: string;
        password: string;
        password2: string;
        firstName: string;
    }) => {
        if (password !== password2) {
            throw new SubmissionError({
                password2: 'Пароли не совпадают',
            });
        }

        const phoneNumber = phone.replace(/[^\d]/g, '');

        try {
            if (phoneNumber.length === 11) {
                dispatch(addManager({ email, password, firstName, phone }))
                    .then(() => {
                        cogoToast.success('Менеджер создан', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                        history.push('/dashboard/managers');
                    })
                    .catch((e) => {
                        cogoToast.error(
                            e.response.data.error.length === 52
                                ? 'Электронная почта уже занята'
                                : 'Ошибка при создании менеджера',
                            {
                                position: 'top-right',
                                hideAfter: 4,
                            }
                        );
                    });
            } else {
                setPhoneError(true);
            }
        } catch (err) {
            throw new SubmissionError({
                _error: 'Ошибка при регистрации',
            });
        }
    };

    return (
        <div className={styles.container}>
            <h1>Зарегистрировать нового партнера</h1>

            <BackButton title="Назад к менеджерам" className={styles.backBtn} />

            <Form
                onSubmit={onSubmit}
                phone={phone}
                setPhone={setPhone}
                phoneError={phoneError}
                setPhoneError={setPhoneError}
            />
        </div>
    );
};

export default AddManager;
