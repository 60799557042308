import React, { useEffect, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Box from '@components/Box';
import BoxButton from '@components/BoxButton';
import { getSelectedGymId } from '@redux/modules/gyms/selectors';
import { fetchSubscriptions } from '@redux/modules/subscriptions/actions';
import { getSubscriptions } from '@redux/modules/subscriptions/selectors';

import GoGymSubscriptionsListTable from './GoGymSubscriptionsListTable';
import styles from './styles.module.css';

export const GoGymSubscriptionsList: React.FC = memo(() => {
    const dispatch = useDispatch();
    const history = useHistory();
    const subscriptions = useSelector(getSubscriptions);
    const selectedGymId = useSelector(getSelectedGymId);

    useEffect(() => {
        dispatch(fetchSubscriptions(selectedGymId));
    }, [dispatch, selectedGymId]);

    const onAddSubscriptionBtnClick = useCallback(
        () => history.push('/dashboard/add-gogym-subscriptions'),
        [history]
    );

    const goToGoGymSubscriptions = useCallback(
        () => history.push('/dashboard/gogym-subscriptions'),
        [history]
    );

    const goToSubscriptions = useCallback(
        () => history.push('/dashboard/subscriptions'),
        [history]
    );

    return (
        <div>
            <h1>Подписки</h1>

            <h3>Действия</h3>
            <div className={styles.actions}>
                <BoxButton
                    icon="gym"
                    title="Добавить подписку"
                    onClick={onAddSubscriptionBtnClick}
                    className={styles.actionBtn}
                />
            </div>

            <div className={styles.membershipsNav}>
                <BoxButton
                    icon=""
                    title="Подписки пользователей"
                    onClick={goToSubscriptions}
                />

                <BoxButton
                    icon=""
                    title="Подписки GOGYM"
                    onClick={goToGoGymSubscriptions}
                    className={styles.actionNav}
                />
            </div>

            <Box className={styles.container}>
                <div className={styles.tableWrapper}>
                    <GoGymSubscriptionsListTable
                        subscriptions={subscriptions}
                    />
                </div>
            </Box>
        </div>
    );
});

export default GoGymSubscriptionsList;
