import { ApplicationState } from '@redux/types';

export const getDailyIncomeDate = (state: ApplicationState): Date[] => {
    return state.dashboard.dailyIncome.map(
        (item: { date: Date; revenue: number }) => item.date
    );
};

export const getDailyIncomeRevenue = (state: ApplicationState): number[] => {
    return state.dashboard.dailyIncome.map(
        (item: { date: Date; revenue: number }) => item.revenue
    );
};

export const getDailyIncome = (
    state: ApplicationState
): { date: Date; revenue: number }[] => {
    return state.dashboard.dailyIncome;
};

export const getDailyBookings = (
    state: ApplicationState
): { date: Date; bookings: number }[] => {
    return state.dashboard.dailyBookings;
};

export const getAllBookingsCount = () => (state: ApplicationState): number =>
    state.dashboard.allBookings;

export const getActiveBookingsCount = () => (state: ApplicationState): number =>
    state.dashboard.activeBookings;

export const getTotalRavenues = () => (state: ApplicationState): number =>
    state.dashboard.totalRevenue;

export const getDailyStatisticsLastUpdated = (
    state: ApplicationState
): number => state.dashboard.dailyLastUpdated;

export const getAllStatisticsLastUpdated = (state: ApplicationState): number =>
    state.dashboard.allLastUpdated;

export const getAllUsers = (state: ApplicationState): number =>
    state.dashboard.allUsers;

export const getTotalBooking = (state: ApplicationState): number =>
    state.dashboard.totalBooking;

export const getTotalBookingTime = (state: ApplicationState): number =>
    state.dashboard.totalBookingTime;

export const getAverageBookingTime = (state: ApplicationState): number =>
    state.dashboard.averageBookingTime;

export const getTotalNumberOfVisitedClubs = (state: ApplicationState): number =>
    state.dashboard.totalNumberOfVisitedClubs;

export const getFavoriteClub = (state: ApplicationState): string =>
    state.dashboard.favoriteClub;

export const getTotalCostOfBooking = (state: ApplicationState): number =>
    state.dashboard.totalCostOfBooking;

export const getAverageCostOfBooking = (state: ApplicationState): number =>
    state.dashboard.averageCostOfBooking;

export const getMostActiveEmployee = (state: ApplicationState): string =>
    state.dashboard.mostActiveEmployee;

export const getDailyUsers = (
    state: ApplicationState
): { date: Date; users: number }[] => {
    return state.dashboard.dailyUsers;
};

export const getAllUsersCount = (state: ApplicationState): number =>
    state.dashboard.totalUsers;

export const getHRStatisticsLastUpdated = (state: ApplicationState): number =>
    state.dashboard.HRLastUpdated;
