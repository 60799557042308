import React, { memo } from 'react';
import { useTable, usePagination, useSortBy, Column } from 'react-table';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Subscription } from '@t/subscriptions';

import styles from './styles.module.css';

export interface Props {
    subscriptions: Subscription[];
}

const columns: Column<Subscription>[] = [
    {
        Header: 'Название',
        accessor: (subscription: Subscription) => subscription.title,
    },
    {
        Header: 'Описание',
        accessor: (subscription: Subscription) => subscription.description,
    },
    {
        Header: 'Доп описание',
        accessor: (subscription: Subscription) =>
            subscription.additionalDescription,
    },
    {
        Header: 'Стоимость',
        accessor: (subscription: Subscription) => subscription.pricePerMonth,
    },
    {
        Header: 'Статус',
        accessor: (subscription: Subscription) =>
            subscription.active ? 'Активная' : 'Не активная',
    },
];

export const GoGymSubscriptionsListTable: React.FC<Props> = memo(
    ({ subscriptions }) => {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
        } = useTable(
            {
                columns,
                data: subscriptions || [],
                initialState: {
                    pageIndex: 0,
                    pageSize: 1000,
                },
            },
            useSortBy,
            usePagination
        );
        const history = useHistory();

        return (
            <>
                <table {...getTableProps()} className={styles.table}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                key={Math.random()}
                            >
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps()
                                        )}
                                        key={Math.random()}
                                    >
                                        {column.render('Header')}
                                        <span className={styles.sorted}>
                                            <FontAwesomeIcon
                                                icon={
                                                    column.isSorted
                                                        ? column.isSortedDesc
                                                            ? 'sort-up'
                                                            : 'sort-down'
                                                        : 'sort'
                                                }
                                            />
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            const onClick = () => {
                                const subscriptions = row.original;
                                history.push(
                                    `/dashboard/gogym-subscriptions/${subscriptions._id}`
                                );
                            };
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    className={styles.tableRow}
                                    onClick={onClick}
                                    key={Math.random()}
                                >
                                    {row.cells.map((cell) => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                key={Math.random()}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </>
        );
    }
);

export default GoGymSubscriptionsListTable;
