import React from 'react';

import ScrollToTop from '@components/ScrollToTop';

import GoGymLogoWhite from '../Icons/GoGymLogoWhite';
import styles from './styles.module.css';

export const AuthFooter: React.FC = () => (
    <>
        <footer className={styles.container}>
            <div className={styles.content}>
                <div className={styles.list}>
                    <GoGymLogoWhite />
                    <ul className={styles.support}>
                        <li>Служба поддержки:</li>
                        <li>
                            <a
                                href="mailto:info@gogym.fit"
                                className={styles.link}
                            >
                                Написать на почту
                            </a>
                        </li>
                        <li>
                            <a href="tel:+88002506613" className={styles.link}>
                                Позвонить
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://t.me/gogym_msk"
                                className={styles.link}
                            >
                                Telegram
                            </a>
                        </li>
                    </ul>
                </div>
                <div
                    className={styles.copyright}
                >{`© ${new Date().getFullYear()} GOGYM`}</div>
            </div>
        </footer>
        <ScrollToTop />
    </>
);

export default AuthFooter;
