import { View as CalendarView } from 'react-big-calendar';

import { ApplicationState } from '@redux/types';
import { GroupWorkoutNew } from '@t/gym';

export const getCalendarView = (state: ApplicationState): CalendarView =>
    state.groupWorkouts.calendarView;

export const getGroupWorkouts = (state: ApplicationState): GroupWorkoutNew[] =>
    state.groupWorkouts.ids.map((id: string) => state.groupWorkouts.byId[id]);

export const getGroupWorkout = (id: string) => (
    state: ApplicationState
): GroupWorkoutNew => state.groupWorkouts.byId[id];

export const getGroupWorkoutDetail = (id: string) => (
    state: ApplicationState
): GroupWorkoutNew => state.groupWorkouts.detail[id];

export const getGroupWorkoutsCalendar = () => (
    state: ApplicationState
): GroupWorkoutNew[] => state.groupWorkouts.calendar;
