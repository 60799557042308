/**
 * Функционал cклонения для количества
 * Пример morphNoun(1, ['рубль', 'рубля', 'рублей']) => рубль
 * Пример morphNoun(2, ['рубль', 'рубля', 'рублей']) => рубля
 * Пример morphNoun(5, ['рубль', 'рубля', 'рублей']) => рублей
 * @param count
 * @param words
 */
export const morphNoun = (count: number, words: string[]): string => {
    count = Math.abs(count) % 100;
    const num = count % 10;
    if (count > 10 && count < 20) {
        return words[2];
    }
    if (num > 1 && num < 5) {
        return words[1];
    }
    if (num === 1) {
        return words[0];
    }
    return words[2];
};
