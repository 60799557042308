import React from 'react';
import { IconProps } from './types';

export const Increase: React.FC<IconProps> = ({ ...props }) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_1994_13935)">
            <path
                d="M15.5712 16.7207C15.5694 16.7189 15.5696 16.7192 15.5697 16.7192L15.5653 16.7148C15.3185 16.4665 15.2195 16.1088 15.3036 15.7689L15.8806 13.4598C16.4646 11.1227 16.8462 9.57407 16.9642 8.44591L5.70719 19.7069C5.31665 20.0977 4.68345 20.0977 4.29291 19.7069C3.90236 19.3162 3.90236 18.6827 4.29291 18.2919L15.5426 7.03825C14.4161 7.15788 12.8713 7.539 10.5446 8.12099L8.23658 8.69832C7.89688 8.78242 7.5393 8.68338 7.29118 8.43649L7.28676 8.43207C7.28683 8.43214 7.28692 8.43224 7.28526 8.43055C6.74527 7.88418 7.00423 6.94844 7.74819 6.75777L7.7465 6.75818L10.0595 6.17962C14.6809 5.02367 16.9916 4.44569 18.2728 5.72755C19.554 7.00941 18.9763 9.3213 17.821 13.9451L17.2428 16.2588C17.2422 16.2615 17.2438 16.2548 17.2431 16.2575C17.0525 17.0019 16.1173 17.261 15.5712 16.7207Z"
                fill="#969FA8"
            />
        </g>
        <defs>
            <clipPath id="clip0_1994_13935">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default Increase;
