import { LegalEntity } from '@t/legal-entity';
import { ApplicationState } from '@redux/types';

export const getLegalEntitiesList = (state: ApplicationState): LegalEntity[] =>
    state.legalentitys.legalEntitiesList;

export const getLegalentitys = (state: ApplicationState): LegalEntity[] =>
    state.legalentitys.ids.map((id: string) => state.legalentitys.byId[id]);

export const getLegalentity = (id: string) => (
    state: ApplicationState
): LegalEntity => state.legalentitys.byId[id];
