import * as React from 'react';
import { YMaps, Map } from 'react-yandex-maps';

import styles from './styles.module.css';
import { Placemark } from './Placemark';

export interface Props {
    coordinate: number[];
    className?: string;
    onCoordinateUpdate?: any;
    isNew?: boolean;
}

const YandexMap: React.FC<Props> = ({ coordinate, onCoordinateUpdate }) => {
    const handleDragEnd = React.useCallback((e: ymaps.IEvent) => {
        const coordinates: number[] = e
            .get('target')
            .geometry.getCoordinates()
            .reverse();

        onCoordinateUpdate?.(coordinates);
    }, []);

    return (
        <YMaps query={{ apikey: process.env.REACT_APP_YANDEX_API_KEY }}>
            <Map
                state={{
                    center: [coordinate[1], coordinate[0]],
                    zoom: 15,
                }}
                className={styles.map}
            >
                <Placemark
                    geometry={[coordinate[1], coordinate[0]]}
                    options={{ draggable: true }}
                    onDragEnd={handleDragEnd}
                />
            </Map>
        </YMaps>
    );
};

export default YandexMap;
