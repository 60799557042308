import { AnyAction } from 'redux';

import {
    FETCH_CONTACTS_SUCCESS,
    UPDATE_CONTACTS_SUCCESS,
    ContactsState,
} from './types';

const initialState: ContactsState = {
    phone: [],
    email: [],
    tg: [],
    vk: [],
};

export function contactsReducer(
    state = initialState,
    action: AnyAction
): ContactsState {
    if (action.type === FETCH_CONTACTS_SUCCESS) {
        const { phone, email, tg, vk } = action.payload;

        return {
            ...state,
            phone,
            email,
            tg,
            vk,
        };
    }

    if (action.type === UPDATE_CONTACTS_SUCCESS) {
        const { phone, email, tg, vk } = action.payload;

        return {
            ...state,
            phone,
            email,
            tg,
            vk,
        };
    }

    return state;
}
