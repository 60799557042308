import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { AsyncDispatch } from '@redux/types';
import { createTag, fetchTagCategories } from '@redux/modules/tags/actions';
import BackButton from '@components/BackButton';
import { getTagCategories } from '@redux/modules/tags/selectors';

import Form from './Form';
import styles from './styles.module.css';

export const AddTag: React.FC = memo(() => {
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const tagCategories = useSelector(getTagCategories);
    const [selectedTagType, setSelectedTagType] = useState<
        { name: string; tagTypeValue: string }[]
    >([]);
    const [selectedTagCategories, setSelectedTagCategories] = useState<
        string[]
    >([]);
    const [
        groupWorkoutFilterTagType,
        setGroupWorkoutFilterTagType,
    ] = useState<boolean>(false);

    const fileInput = useRef<null>(null);
    const [tagError, setTagError] = useState<boolean>(false);
    const [tagCategoryError, setTagCategoryError] = useState<boolean>(false);
    const tagCategoriesOptions = tagCategories.map((tagC) => ({
        label: tagC.title,
        value: tagC._id,
    }));

    useEffect(() => {
        selectedTagType.some((tag) => tag.tagTypeValue === 'GROUPWORKOUTFILTER')
            ? setGroupWorkoutFilterTagType(true)
            : setGroupWorkoutFilterTagType(false);
    }, [selectedTagType]);

    useEffect(() => {
        if (!groupWorkoutFilterTagType) {
            setSelectedTagCategories([]);
        }
    }, [groupWorkoutFilterTagType]);

    useEffect(() => {
        dispatch(fetchTagCategories());
    }, [dispatch]);

    const onSubmit = useCallback(
        (values) => {
            const files = (fileInput.current || { files: new FileList() })
                .files;

            if (!selectedTagType.length) {
                return setTagError(true);
            }

            if (groupWorkoutFilterTagType && !selectedTagCategories.length) {
                return setTagCategoryError(true);
            }

            if (tagError || tagCategoryError) {
                return cogoToast.error('Заполните обязательные поля', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            }

            dispatch(
                createTag({
                    values,
                    tagType: selectedTagType,
                    categoryIds: selectedTagCategories,
                    files,
                })
            )
                .then(() => {
                    cogoToast.success('Тэг создан', {
                        position: 'top-right',
                        hideAfter: 5,
                    });
                    history.push('/dashboard/tags');
                })
                .catch(() => {
                    cogoToast.error('Ошибка при создании тэга', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                });
        },
        [
            dispatch,
            history,
            selectedTagType,
            selectedTagCategories,
            fileInput,
            groupWorkoutFilterTagType,
            tagError,
            tagCategoryError,
        ]
    );

    const onUploadFile = (event: any) => {
        const numOfFiles = Array.from(event.target.files).length;
        for (let i = 0; i < numOfFiles; ++i) {
            const validExtensions = ['svg+xml'];
            const fileExtension = event.target.files[i].type.split('/')[1];
            if (!validExtensions.includes(fileExtension)) {
                event.preventDefault();
                cogoToast.error(`Можно загрузить файлы с расширением .svg`, {
                    position: 'top-right',
                    hideAfter: 4,
                });
                event.target.value = null;
                return;
            }
        }
    };

    return (
        <div className={styles.container}>
            <h1>Добавить новый тэг</h1>

            <BackButton title="К списку тэгов" className={styles.backBtn} />

            <Form
                onSubmit={onSubmit}
                selectedTagType={selectedTagType}
                setSelectedTagType={setSelectedTagType}
                fileInput={fileInput}
                onUploadFile={onUploadFile}
                groupWorkoutFilterTagType={groupWorkoutFilterTagType}
                tagCategoriesOptions={tagCategoriesOptions}
                selectedTagCategories={selectedTagCategories}
                setSelectedTagCategories={setSelectedTagCategories}
                tagError={tagError}
                setTagError={setTagError}
                tagCategoryError={tagCategoryError}
                setTagCategoryError={setTagCategoryError}
            />
        </div>
    );
});

export default AddTag;
