import React from 'react';
import moment from 'moment';
import { useTable, usePagination, useSortBy, Column } from 'react-table';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Membership, MembershipType } from '@t/membership';

import styles from './styles.module.css';

export interface Props {
    memberships: Membership[];
}

export const getDuration = (type: string, duration: number): string => {
    if (type === MembershipType.TIME) {
        return `${duration} дней`;
    } else {
        return `${duration} посещений`;
    }
};

export const getRest = (
    type: string,
    timeFinished: Date,
    balance: number
): string => {
    if (type === MembershipType.TIME) {
        let rest = moment(timeFinished).diff(moment(), 'days') + 1;
        if (moment(timeFinished).diff(moment(), 'minutes') < 1) {
            rest--;
        }
        return rest > 0 ? rest + ' дней' : '0 дней';
    } else {
        return `${balance} посещений`;
    }
};

export const isValidDate = (value: Date): boolean =>
    value?.toLocaleString() !== 'Invalid Date';

const columns: Column<Membership>[] = [
    {
        Header: 'Фитнес-площадка',
        accessor: 'gymTitle',
    },
    {
        Header: 'Дата начала',
        id: 'timeStarted',
        accessor: (membership: Membership) =>
            !membership.timeStarted
                ? new Date('')
                : new Date(membership.timeStarted),
        // @ts-ignore
        sortType: 'datetime',
        Cell: ({ cell: { value } }: any) =>
            isValidDate(value) ? value.toLocaleString() : '',
    },
    {
        Header: 'Дата завершения',
        id: 'timeFinished',
        accessor: (membership: Membership) =>
            !membership.timeFinished
                ? new Date(' ')
                : new Date(membership.timeFinished),
        // @ts-ignore
        sortType: 'datetime',
        Cell: ({ cell: { value } }: any) =>
            isValidDate(value) ? value.toLocaleString() : '',
    },
    {
        Header: 'Тип',
        accessor: (membership: Membership) => {
            return membership.type === 'TIME' ? 'Время' : 'Посещения';
        },
        Cell: ({ cell: { value } }: any) => <div>{value}</div>,
    },
    {
        Header: 'Срок действия',
        accessor: (membership: Membership) =>
            `${getDuration(membership.type, membership.duration)}`,
    },
    {
        Header: 'Остаток',
        accessor: (membership: Membership) =>
            `${getRest(
                membership.type,
                membership.timeFinished,
                membership.balance
            )}`,
    },
    {
        Header: 'Статус',
        accessor: (membership: Membership) => {
            return {
                title: membership.active ? 'Активный' : 'Не активный',
                styleClass: !membership.active
                    ? styles.notActive
                    : styles.active,
            };
        },
        Cell: ({ cell: { value } }: any) => (
            <div className={value.styleClass}>{value.title}</div>
        ),
    },
    {
        Header: 'Цена',
        accessor: (membership: Membership) => {
            return membership.price;
        },
    },
];

export const MembershipListTable: React.FC<Props> = ({ memberships }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable(
        {
            columns,
            data: memberships || [],
            initialState: {
                pageIndex: 0,
                pageSize: 1000,
            },
        },
        useSortBy,
        usePagination
    );
    const history = useHistory();

    return (
        <>
            <table {...getTableProps()} className={styles.table}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={Math.random()}
                        >
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                    key={Math.random()}
                                >
                                    {column.render('Header')}
                                    <span className={styles.sorted}>
                                        <FontAwesomeIcon
                                            icon={
                                                column.isSorted
                                                    ? column.isSortedDesc
                                                        ? 'sort-up'
                                                        : 'sort-down'
                                                    : 'sort'
                                            }
                                        />
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        const onClick = () => {
                            const membership = row.original;
                            history.push(
                                `/dashboard/memberships/${membership._id}`
                            );
                        };
                        return (
                            <tr
                                {...row.getRowProps()}
                                className={styles.tableRow}
                                onClick={onClick}
                                key={Math.random()}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            key={Math.random()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};

export default MembershipListTable;
