import React from 'react';
import {
    InjectedFormProps,
    Field,
    reduxForm,
    formValueSelector,
} from 'redux-form';
import { useSelector } from 'react-redux';
import Select, { createFilter, MultiValue } from 'react-select';

import TextInput from '@components/TextInput';
import Button from '@components/Button';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import TextAreaInput from '@components/TextAreaInput';
import SelectInput from '@components/SelectInput';
import { ApplicationState } from '@redux/types';
import { MembershipType, MembershipTypeOptions } from '@t/membership';
import CheckBoxInput from '@components/CheckBoxInput';
import FileInput from '@components/FileInput';

import styles from './styles.module.css';

export interface AddMembershipFormData {
    type: string;
    active: boolean;
    duration: number;
    limit: number;
    price: number;
    gymPrice: number;
    gymIds: string[];
    comment: string;
}

export interface Props {
    gymsOptions: {
        label: string;
        value: string;
    }[];
    setSelectedGyms: React.Dispatch<React.SetStateAction<string[]>>;
    fileInput: React.MutableRefObject<null>;
    uploadMultipleFiles: (event: React.MouseEvent<HTMLInputElement>) => void;
}

const validate = (values: AddMembershipFormData) => {
    const errors: {
        type: string;
        price: string;
        gymPrice: string;
        duration: string;
        limit: string;
    } = {
        type: '',
        price: '',
        gymPrice: '',
        duration: '',
        limit: '',
    };

    if (!values.type) {
        errors.type = 'Обязательное поле';
    }

    if (
        values.price &&
        (!Number.isInteger(Number(values.price)) ||
            Number(values.price) <= 0 ||
            String(values.price).replace(/^\d+$/g, '').length)
    ) {
        errors.price = 'Должно быть целым положительным числом';
    }

    if (
        values.gymPrice &&
        (!Number.isInteger(Number(values.gymPrice)) ||
            Number(values.gymPrice) <= 0 ||
            String(values.gymPrice).replace(/^\d+$/g, '').length)
    ) {
        errors.gymPrice = 'Должно быть целым положительным числом';
    }

    if (
        values.duration &&
        (!Number.isInteger(Number(values.duration)) ||
            Number(values.duration) <= 0 ||
            String(values.duration).replace(/^\d+$/g, '').length)
    ) {
        errors.duration = 'Должно быть целым положительным числом';
    }

    if (
        values.limit &&
        (!Number.isInteger(Number(values.limit)) ||
            Number(values.limit) <= 0 ||
            String(values.limit).replace(/^\d+$/g, '').length)
    ) {
        errors.limit = 'Должно быть целым положительным числом';
    }

    return errors;
};

const AddMembershipForm: React.FC<
    Props & InjectedFormProps<AddMembershipFormData, Props>
> = ({
    handleSubmit,
    gymsOptions,
    setSelectedGyms,
    fileInput,
    uploadMultipleFiles,
}) => {
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;
    const selector = formValueSelector('AddMembership');
    const membershipTypeSelector = (state: ApplicationState) =>
        selector(state, 'type');
    const membershipType = useSelector(membershipTypeSelector);
    const membershipDurationSelector = (state: ApplicationState) =>
        selector(state, 'duration');
    const membershipDuration = useSelector(membershipDurationSelector);

    const handleChange = (
        value: MultiValue<{ label: string; value: string }>
    ) => {
        setSelectedGyms(
            value.map((gym: { label: string; value: string }) => gym.value)
        );
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Field
                required
                name="type"
                label="Тип абонемента"
                options={MembershipTypeOptions}
                component={SelectInput}
            />

            {(membershipType === MembershipType.TIME ||
                membershipType === MembershipType.SESSION) && (
                <Field
                    name="active"
                    label="Активность абонемента"
                    component={CheckBoxInput}
                />
            )}

            {membershipType === MembershipType.TIME && (
                <Field
                    required
                    name="duration"
                    label="Продолжительность абонемента (дни)"
                    component={TextInput}
                />
            )}

            {membershipType === MembershipType.SESSION && (
                <Field
                    required
                    name="duration"
                    label="Продолжительность абонемента (тренировки)"
                    component={TextInput}
                />
            )}

            {membershipType === MembershipType.SESSION &&
                membershipDuration && (
                    <Field
                        required
                        name="limit"
                        label="Ограничение абонемента по времени (дни)"
                        component={TextInput}
                    />
                )}

            {membershipDuration && (
                <Field
                    required
                    name="price"
                    label="Цена абонемента"
                    placeholder="2000"
                    component={TextInput}
                />
            )}

            {membershipDuration && (
                <Field
                    name="gymPrice"
                    label="Цена абонемента в клубе"
                    placeholder="2500"
                    component={TextInput}
                />
            )}

            {membershipDuration && (
                <>
                    <span className={styles.label}>Фитнес-площадки</span>
                    <Select
                        className={styles.select}
                        isMulti
                        styles={{
                            input: (provided) => ({
                                ...provided,
                                minHeight: 40,
                            }),
                        }}
                        onChange={handleChange}
                        options={gymsOptions}
                        filterOption={createFilter({
                            stringify: (option) => `${option.label}`,
                        })}
                        closeMenuOnSelect={false}
                        placeholder="Выберите фитнес-площадку"
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: 'rgb(225, 129, 65)',
                            },
                        })}
                        noOptionsMessage={() => 'Не найдено'}
                    />
                </>
            )}

            {membershipDuration && (
                <Field
                    required
                    name="comment"
                    label="Комментарий"
                    placeholder="Здесь можно перечислить услуги абонемента"
                    component={TextAreaInput}
                />
            )}

            {membershipDuration && (
                <FileInput
                    className="docs-input"
                    name="docs"
                    label="Загрузить документы"
                    accept=".pdf"
                    fileInput={fileInput}
                    multiple
                    onChange={uploadMultipleFiles}
                />
            )}

            {isSuperAdmin && (
                <Button type="submit" className={styles.btn}>
                    Добавить
                </Button>
            )}
        </form>
    );
};

export default reduxForm<AddMembershipFormData, Props>({
    form: 'AddMembership',
    validate,
})(AddMembershipForm);
