export enum MembershipType {
    TIME = 'TIME',
    SESSION = 'SESSION',
}

export interface Membership {
    _id: string;
    gymId: string;
    gymTitle: string;
    userId: string;
    gymMembershipsId: string;
    timeStarted: Date;
    timeFinished: Date;
    price: number;
    duration: number;
    balance: number;
    limit: number;
    type: MembershipType;
    deleted: boolean;
    active: boolean;
    paid: boolean;
    createdAt: Date;
    updatedAt: Date;
    acquiringCommission: number;
    goGymFee: number;
    priceOfGogymFee: number;
    netPrice: number;
}

export interface GymMembership {
    _id: string;
    type: string;
    duration: number;
    limit: number;
    price: number;
    gymPrice: number;
    gyms: string[];
    comment: string;
    offer: string[];
    active: boolean;
}

export const GymMembershipTypeOptions = [
    { title: 'Время', value: MembershipType.TIME },
    { title: 'Посещения', value: MembershipType.SESSION },
];

export enum MembershipTime {
    THREEDAYS = 3,
    SEVENDAYS = 7,
    FOURTEENDAYS = 14,
    TWENTYONEDAYS = 21,
    ONEMONTH = 30,
    TWOMONTHS = 60,
    THREEMONTHS = 90,
    FOURMONTHS = 120,
    FIVEMONTHS = 150,
    SIXMONTHS = 180,
    YEAR = 365,
}

export enum MembershipLimitTime {
    THREEDAYS = 3,
    SEVENDAYS = 7,
    FOURTEENDAYS = 14,
    TWENTYONEDAYS = 21,
    ONEMONTH = 30,
    MONTHSANDHALF = 45,
    TWOMONTHS = 60,
    THREEMONTHS = 90,
    YEAR = 365,
}

export enum MembershipSession {
    BOOKINGS3 = 3,
    BOOKINGS4 = 4,
    BOOKINGS5 = 5,
    BOOKINGS6 = 6,
    BOOKINGS7 = 7,
    BOOKINGS8 = 8,
    BOOKINGS9 = 9,
    BOOKINGS10 = 10,
    BOOKINGS11 = 11,
    BOOKINGS12 = 12,
    BOOKINGS13 = 13,
    BOOKINGS14 = 14,
    BOOKINGS15 = 15,
    BOOKINGS16 = 16,
    BOOKINGS17 = 17,
    BOOKINGS18 = 18,
    BOOKINGS19 = 19,
    BOOKINGS20 = 20,
    BOOKINGS21 = 21,
    BOOKINGS22 = 22,
    BOOKINGS23 = 23,
    BOOKINGS24 = 24,
    BOOKINGS25 = 25,
    BOOKINGS26 = 26,
    BOOKINGS27 = 27,
    BOOKINGS28 = 28,
    BOOKINGS29 = 29,
    BOOKINGS30 = 30,
    BOOKINGS45 = 45,
    BOOKINGS60 = 60,
    BOOKINGS90 = 90,
}

export const MembershipTypeOptions = [
    { title: 'Выберете тип абонемента', value: '' },
    { title: 'По количеству времени', value: MembershipType.TIME },
    { title: 'По количеству тренировок', value: MembershipType.SESSION },
];

export const MembershipDurationTimeOptions = [
    { title: 'Выберете время абонемента', value: '' },
    { title: '3', value: MembershipTime.THREEDAYS },
    { title: '7', value: MembershipTime.SEVENDAYS },
    { title: '14', value: MembershipTime.FOURTEENDAYS },
    { title: '21', value: MembershipTime.TWENTYONEDAYS },
    { title: '30', value: MembershipTime.ONEMONTH },
    { title: '60', value: MembershipTime.TWOMONTHS },
    { title: '90', value: MembershipTime.THREEMONTHS },
    { title: '120', value: MembershipTime.FOURMONTHS },
    { title: '150', value: MembershipTime.FIVEMONTHS },
    { title: '180', value: MembershipTime.SIXMONTHS },
    { title: '365', value: MembershipTime.YEAR },
];

export const MembershipLimitTimeOptions = [
    { title: 'Выберете ограничение времени для абонемента', value: '' },
    { title: 'Без ограничений', value: MembershipLimitTime.YEAR },
    { title: '3', value: MembershipLimitTime.THREEDAYS },
    { title: '7', value: MembershipLimitTime.SEVENDAYS },
    { title: '14', value: MembershipLimitTime.FOURTEENDAYS },
    { title: '21', value: MembershipLimitTime.TWENTYONEDAYS },
    { title: '30', value: MembershipLimitTime.ONEMONTH },
    { title: '45', value: MembershipLimitTime.MONTHSANDHALF },
    { title: '60', value: MembershipLimitTime.TWOMONTHS },
    { title: '90', value: MembershipLimitTime.THREEMONTHS },
];

export const MembershipDurationSessionOptions = [
    { title: 'Выберете количество тренировок абонемента', value: '' },
    { title: '3', value: MembershipSession.BOOKINGS3 },
    { title: '4', value: MembershipSession.BOOKINGS4 },
    { title: '5', value: MembershipSession.BOOKINGS5 },
    { title: '6', value: MembershipSession.BOOKINGS6 },
    { title: '7', value: MembershipSession.BOOKINGS7 },
    { title: '8', value: MembershipSession.BOOKINGS8 },
    { title: '9', value: MembershipSession.BOOKINGS9 },
    { title: '10', value: MembershipSession.BOOKINGS10 },
    { title: '11', value: MembershipSession.BOOKINGS11 },
    { title: '12', value: MembershipSession.BOOKINGS12 },
    { title: '13', value: MembershipSession.BOOKINGS13 },
    { title: '14', value: MembershipSession.BOOKINGS14 },
    { title: '15', value: MembershipSession.BOOKINGS15 },
    { title: '16', value: MembershipSession.BOOKINGS16 },
    { title: '17', value: MembershipSession.BOOKINGS17 },
    { title: '18', value: MembershipSession.BOOKINGS18 },
    { title: '19', value: MembershipSession.BOOKINGS19 },
    { title: '20', value: MembershipSession.BOOKINGS20 },
    { title: '21', value: MembershipSession.BOOKINGS21 },
    { title: '22', value: MembershipSession.BOOKINGS22 },
    { title: '23', value: MembershipSession.BOOKINGS23 },
    { title: '24', value: MembershipSession.BOOKINGS24 },
    { title: '25', value: MembershipSession.BOOKINGS25 },
    { title: '26', value: MembershipSession.BOOKINGS26 },
    { title: '27', value: MembershipSession.BOOKINGS27 },
    { title: '28', value: MembershipSession.BOOKINGS28 },
    { title: '29', value: MembershipSession.BOOKINGS29 },
    { title: '30', value: MembershipSession.BOOKINGS30 },
    { title: '45', value: MembershipSession.BOOKINGS45 },
    { title: '60', value: MembershipSession.BOOKINGS60 },
    { title: '90', value: MembershipSession.BOOKINGS90 },
];
