import { AnyAction } from 'redux';

import { Coupon } from '@t/coupon';

import {
    FETCH_COUPONS_SUCCESS,
    CouponState,
    FETCH_COUPON_SUCCESS,
    FETCH_COUPONS_AD_CAMPAIGNS_SUCCESS,
    FETCH_SCROLL_COUPONS_SUCCESS,
} from './types';

const initialState: CouponState = {
    ids: [],
    byId: {},
    couponsAdCampaigns: [],
    lastUpdated: 0,
};

export function couponsReducer(
    state = initialState,
    action: AnyAction
): CouponState {
    if (action.type === FETCH_COUPON_SUCCESS) {
        const coupon = action.payload;
        const included = state.ids.includes(coupon._id);
        const ids = included ? state.ids : [...state.ids, coupon._id];
        const byId = {
            ...state.byId,
            [coupon._id]: coupon,
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === FETCH_COUPONS_SUCCESS) {
        const coupons = action.payload;
        const ids = coupons.map((coupon: Coupon) => coupon._id);
        const byId = coupons.reduce(
            (total: { [id: string]: Coupon }, coupon: Coupon) => ({
                ...total,
                [coupon._id]: coupon,
            }),
            {}
        );
        const lastUpdated = Date.now();

        return {
            ...state,
            ids,
            byId,
            lastUpdated,
        };
    }

    if (action.type === FETCH_SCROLL_COUPONS_SUCCESS) {
        const coupons = action.payload;
        const ids = coupons.map((coupon: Coupon) => coupon._id);
        const byId = coupons.reduce(
            (total: { [id: string]: Coupon }, coupon: Coupon) => ({
                ...total,
                [coupon._id]: coupon,
            }),
            {}
        );
        const lastUpdated = Date.now();

        return {
            ...state,
            ids: [...state.ids, ...ids],
            byId: { ...state.byId, ...byId },
            lastUpdated,
        };
    }

    if (action.type === FETCH_COUPONS_AD_CAMPAIGNS_SUCCESS) {
        const couponsAdCampaigns = action.payload;

        return {
            ...state,
            couponsAdCampaigns,
        };
    }

    return state;
}
