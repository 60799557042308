import { ErrorMessage, Field } from 'formik';
import React from 'react';
import styles from './styles.module.css';

interface TextareaFieldProps {
    label: string;
    name: string;
    hint?: string;
    maxLength?: number;
}

export const TextareaField: React.FC<TextareaFieldProps> = ({
    label,
    name,
    hint,
    maxLength,
}) => {
    return (
        <div className={styles.textareaContainer}>
            <label htmlFor={name} className={styles.label}>
                {label}
            </label>
            <Field
                as="textarea"
                id={name}
                name={name}
                placeholder="Ваш текст"
                rows="6"
                maxLength={maxLength}
                className={styles.textarea}
            />
            <ErrorMessage
                className={styles.error}
                name={name}
                component="div"
            />
            {hint && <div className={styles.hint}>{hint}</div>}
        </div>
    );
};
