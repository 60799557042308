import { MomentTimeSlot } from '@containers/Dashboard/GroupWorkoutNewForm/types';
import moment, { Moment } from 'moment';
import { GroupWorkoutNewData, ITimeSlot } from '@t/gym';
import { getDuration } from '@containers/Dashboard/GroupWorkoutNewEdit';

const $localGenerateEqualDateMomentObject = (inputDate: Moment) => {
    if (!inputDate) {
        return null;
    }

    return moment({
        year: 1970,
        month: 0,
        day: 1,
        hour: inputDate.hour(),
        minute: inputDate.minute(),
        second: 0,
    });
};

export const useGroupWorkoutNewFormNormalizeData = () => {
    const generateMomentTimeSlotToValidData = (
        timeSlots: MomentTimeSlot[] | any[]
    ) => {
        const clonedTimeSlots = [...timeSlots];
        return clonedTimeSlots.reduce<
            { _id: string; timeStart: any; timeFinish: any }[]
        >((acc, slot) => {
            if (slot.timeStart && slot.timeFinish) {
                acc.push({
                    _id: slot._id,
                    timeStart: moment(slot.timeStart).utcOffset(0),
                    timeFinish: moment(slot.timeFinish).utcOffset(0),
                });
            }
            return acc;
        }, []);
    };

    const normalizeScheduleByWeekDay: any = (weekDaySlotsArr: ITimeSlot[]) => {
        if (weekDaySlotsArr.length > 0) {
            return weekDaySlotsArr
                .map((slot) => ({
                    _id: slot._id,
                    timeStart: moment(slot.timeStart).isValid()
                        ? moment(slot.timeStart).utcOffset(0)
                        : null,
                    timeFinish: moment(slot.timeStart).isValid()
                        ? moment(slot.timeStart).utcOffset(0)
                        : null,
                }))
                .sort((a, b) => {
                    if (a?.timeStart === null) {
                        return 1;
                    }

                    if (b?.timeStart === null) {
                        return -1;
                    }

                    return (
                        ($localGenerateEqualDateMomentObject(
                            a.timeStart
                        ) as Moment).valueOf() -
                        ($localGenerateEqualDateMomentObject(
                            b.timeStart
                        ) as Moment).valueOf()
                    );
                });
        }
        weekDaySlotsArr[0] = {
            _id: '',
            timeStart: null,
            timeFinish: null,
        };
        return weekDaySlotsArr;
    };

    const normalizeDuration = (initialScheduleData: {
        mon: GroupWorkoutNewData['groupworkoutschedule']['mon'];
        tue: GroupWorkoutNewData['groupworkoutschedule']['tue'];
        wed: GroupWorkoutNewData['groupworkoutschedule']['wed'];
        thu: GroupWorkoutNewData['groupworkoutschedule']['thu'];
        fri: GroupWorkoutNewData['groupworkoutschedule']['fri'];
        sat: GroupWorkoutNewData['groupworkoutschedule']['sat'];
        sun: GroupWorkoutNewData['groupworkoutschedule']['sun'];
    }): number => {
        let resultDuration = 0;
        for (const [index, [key, slots]] of Object.entries(
            Object.entries(initialScheduleData)
        )) {
            if (slots.length) {
                const firstElement = slots.find(
                    (slot) => slot.timeStart && slot.timeFinish
                );

                if (firstElement) {
                    const { timeStart, timeFinish } = firstElement;

                    resultDuration = getDuration(
                        (timeFinish as Date).toString(),
                        (timeStart as Date).toString()
                    );
                    break;
                }
            }
        }

        return resultDuration;
    };

    return {
        generateMomentTimeSlotToValidData,
        normalizeScheduleByWeekDay,
        normalizeDuration,
    };
};
