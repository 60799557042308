import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Calendar as С,
    EventProps,
    momentLocalizer,
    View as CalendarView,
} from 'react-big-calendar';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { AsyncDispatch } from '@redux/types';
import { getSelectedGymId } from '@redux/modules/gyms/selectors';
import { getCalendarView } from '@redux/modules/group-workouts/selectors';
import { setCalendarView } from '@redux/modules/group-workouts/actions';

const CustomEvent: React.FC<
    EventProps<{
        groupWorkoutId: string;
        timeSlotId: string;
        title: string;
        start: Date;
        end: Date;
        reservedSlots: number;
        availableSlots: number;
    }>
> = (
    event: EventProps<{
        groupWorkoutId: string;
        timeSlotId: string;
        title: string;
        start: Date;
        end: Date;
        reservedSlots: number;
        availableSlots: number;
    }>
) => {
    return (
        <div>
            {event.title} {event.event.reservedSlots}/
            {event.event.availableSlots}
        </div>
    );
};

const localizer = momentLocalizer(moment);

export interface Props {
    events: any;
    handleCalendarNavigate?: (date: Date) => void;
    screen?: string;
}

export const Calendar: React.FC<Props> = ({
    events,
    handleCalendarNavigate,
    screen,
}) => {
    const dispatch = useDispatch<AsyncDispatch>();
    const calendarView = useSelector(getCalendarView);
    const selectedGymId = useSelector(getSelectedGymId);
    const history = useHistory();

    const onCalendarViewChange = (newView: CalendarView) =>
        dispatch(setCalendarView(newView));

    const todayStart = new Date(
        new Date().getTime() + new Date().getTimezoneOffset() * 60000
    );
    todayStart.setHours(6, 0, 0, 0);
    const todayEnd = new Date(
        new Date().getTime() + new Date().getTimezoneOffset() * 60000
    );
    todayEnd.setHours(23, 0, 0, 0);

    return (
        <С
            onNavigate={handleCalendarNavigate}
            selectable
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ width: 1300, fontSize: 12 }}
            min={todayStart}
            max={todayEnd}
            onSelectSlot={({ start: dateStart, end: dateFinish }) => {
                if (new Date(dateStart) >= todayStart) {
                    history.push(`/dashboard/group-workouts/add`, {
                        dateStart,
                        dateFinish,
                        selectedGym: selectedGymId,
                    });
                }
            }}
            view={calendarView}
            views={['week', 'day', 'agenda']}
            defaultView={'week'}
            onSelectEvent={(event) => {
                history.push(
                    `/dashboard/group-workouts/${event.groupWorkoutId}/timeslots/${event.timeSlotId}`,
                    {
                        dateStart: event.start,
                        dateFinish: event.end,
                        screen: screen ? screen : '',
                    }
                );
            }}
            messages={{
                week: 'Неделя',
                day: 'День',
                agenda: 'План',
                today: 'Сегодня',
                previous: 'Назад',
                next: 'Вперед',
            }}
            onView={onCalendarViewChange}
            eventPropGetter={(event) => {
                let backgroundColor = 'var(--color-primary)';
                const now = new Date();
                if (event.start < now) {
                    backgroundColor = '#a9a9a9';
                }

                const eventStyle = {
                    backgroundColor: backgroundColor,
                    color: 'black',
                };
                return {
                    style: eventStyle,
                };
            }}
            dayPropGetter={(date) => {
                let backgroundColor = 'var(--color-light-bg)';
                if (date < todayStart) {
                    backgroundColor = '#e9e9e9';
                }

                const slotStyle = {
                    backgroundColor: backgroundColor,
                };
                return {
                    style: slotStyle,
                };
            }}
            components={{
                event: CustomEvent, //EventComponent,
            }}
        />
    );
};

export default Calendar;
