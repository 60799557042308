import React from 'react';
import { InjectedFormProps, Field, reduxForm } from 'redux-form';

import TextInput from '@components/TextInput';
import Button from '@components/Button';
import SelectInput from '@components/SelectInput';

import styles from './styles.module.css';

export interface AddUserSubscriptionFormData {
    subscriptionId: string;
    userId: string;
}

export interface Props {
    subscriptionsOptions: {
        title: string;
        value: string;
    }[];
}

const validate = (values: AddUserSubscriptionFormData) => {
    const errors: {
        subscriptionId: string;
        userId: string;
    } = {
        subscriptionId: '',
        userId: '',
    };

    if (!values.subscriptionId) {
        errors.subscriptionId = 'Обязательное поле';
    }

    if (!values.userId) {
        errors.userId = 'Обязательное поле';
    }

    return errors;
};

const AddSubscriptionForm: React.FC<
    Props & InjectedFormProps<AddUserSubscriptionFormData, Props>
> = ({ handleSubmit, subscriptionsOptions }) => {
    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Field
                name="subscriptionId"
                label="ID подписки GOGYM"
                placeholder="ID подписки GOGYM"
                component={SelectInput}
                options={subscriptionsOptions}
                emptyTitle={'Выбрать подписку...'}
            />

            <Field
                name="userId"
                label="ID пользователя"
                placeholder="ID пользователя"
                component={TextInput}
            />

            <Button type="submit" className={styles.btn}>
                Добавить
            </Button>
        </form>
    );
};

export default reduxForm<AddUserSubscriptionFormData, Props>({
    form: 'addSubscription',
    validate,
})(AddSubscriptionForm);
