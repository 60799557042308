import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

import BackBtn from '../BackBtn';
import NextBtn from '../NextBtn';
import styles from './styles.module.css';

export type FormProps = {
    backBtnTo?: string;
};

export const Form: React.FC<FormProps & InjectedFormProps<any, FormProps>> = ({
    handleSubmit,
    error,
    children,
    backBtnTo,
}) => (
    <form onSubmit={handleSubmit} className={styles.form}>
        {children}

        {!!error && <div className={styles.error}>{error}</div>}

        <div className={styles.actions}>
            {backBtnTo && <BackBtn title="Предыдущий шаг" to={backBtnTo} />}

            <NextBtn title="Далее" />
        </div>
    </form>
);

export default reduxForm<any, FormProps>({})(Form);
