import { AnyAction } from 'redux';

import { LegalEntity } from '@t/legal-entity';

import {
    FETCH_LEGALENTITIES_SUCCESS,
    LegalEntityState,
    FETCH_LEGALENTITY_SUCCESS,
    DELETE_LEGALENTITY_GYM_SUCCESS,
    UPLOAD_LEGALENTITY_DOCS_SUCCESS,
    DELETE_LEGALENTITY_DOCS_SUCCESS,
    FETCH_LEGALENTITIES_LIST_SUCCESS,
    FETCH_SCROLL_LEGALENTITIES_SUCCESS,
} from './types';

const initialState: LegalEntityState = {
    ids: [],
    byId: {},
    lastUpdated: 0,
    legalEntitiesList: [],
};

export function legalentityReducer(
    state = initialState,
    action: AnyAction
): LegalEntityState {
    if (action.type === FETCH_LEGALENTITIES_LIST_SUCCESS) {
        const legalEntities = action.payload;

        return {
            ...state,
            legalEntitiesList: legalEntities,
        };
    }
    if (action.type === FETCH_LEGALENTITY_SUCCESS) {
        const legalEntity = action.payload;
        const included = state.ids.includes(legalEntity._id);
        const ids = included ? state.ids : [...state.ids, legalEntity._id];
        const byId = {
            ...state.byId,
            [legalEntity._id]: legalEntity,
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === FETCH_LEGALENTITIES_SUCCESS) {
        const legalEntitys = action.payload;
        const ids = legalEntitys.map(
            (legalEntity: LegalEntity) => legalEntity._id
        );
        const byId = legalEntitys.reduce(
            (
                total: { [id: string]: LegalEntity },
                legalEntity: LegalEntity
            ) => ({
                ...total,
                [legalEntity._id]: legalEntity,
            }),
            {}
        );
        const lastUpdated = Date.now();

        return {
            ...state,
            ids,
            byId,
            lastUpdated,
        };
    }

    if (action.type === FETCH_SCROLL_LEGALENTITIES_SUCCESS) {
        const legalEntitys = action.payload;
        const ids = legalEntitys.map(
            (legalEntity: LegalEntity) => legalEntity._id
        );
        const byId = legalEntitys.reduce(
            (
                total: { [id: string]: LegalEntity },
                legalEntity: LegalEntity
            ) => ({
                ...total,
                [legalEntity._id]: legalEntity,
            }),
            {}
        );
        const lastUpdated = Date.now();

        return {
            ...state,
            ids: [...state.ids, ...ids],
            byId: { ...state.byId, ...byId },
            lastUpdated,
        };
    }

    if (action.type === DELETE_LEGALENTITY_GYM_SUCCESS) {
        const legalEntity = action.payload;
        const included = state.ids.includes(legalEntity._id);
        const ids = included ? state.ids : [...state.ids, legalEntity._id];
        const byId = {
            ...state.byId,
            [legalEntity._id]: legalEntity,
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === UPLOAD_LEGALENTITY_DOCS_SUCCESS) {
        const legalEntity = action.payload;
        const included = state.ids.includes(legalEntity._id);
        const ids = included ? state.ids : [...state.ids, legalEntity._id];
        const byId = {
            ...state.byId,
            [legalEntity._id]: {
                ...state.byId[legalEntity._id],
                ...legalEntity,
            },
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === DELETE_LEGALENTITY_DOCS_SUCCESS) {
        const legalEntity = action.payload;
        const included = state.ids.includes(legalEntity._id);
        const ids = included ? state.ids : [...state.ids, legalEntity._id];
        const byId = {
            ...state.byId,
            [legalEntity._id]: {
                ...state.byId[legalEntity._id],
                ...legalEntity,
            },
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    return state;
}
