import React from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.css';

export interface BackBtnProps {
    title: string;
    to: string;
}

export const BackBtn: React.FC<BackBtnProps> = ({ to, title }) => (
    <Link to={to} className={styles.backBtn}>
        {title}
    </Link>
);

export default BackBtn;
