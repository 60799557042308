import { RRule } from 'rrule';
import moment from 'moment';
import { AnyAction } from 'redux';

import { DATE_TIME_FORMAT } from '@config';
import { Gym, GroupWorkout, CancelEventType } from '@t/gym';

import {
    FETCH_GYM_SUCCESS,
    FETCH_GYMS_SUCCESS,
    UPDATE_GYM_SUCCESS,
    CANCEL_GROUP_WORKOUT_SUCCESS,
    GymState,
    SELECT_GYM,
    FETCH_RESERVATION_STATUS_SUCCESS,
    DELETE_GYM_IMAGE_SUCCESS,
    UPLOAD_IMAGE_SUCCESS,
    CREATE_GYM_SUCCESS,
    UPDATE_GYM_IMAGES_SUCCESS,
    UPDATE_MANAGER_GYM_SUCCESS,
    FETCH_HIDDEN_GYMS_SUCCESS,
    FETCH_HIDDEN_GYM_SUCCESS,
    UPDATE_LEGALENTITY_GYM_SUCCESS,
    FETCH_GYMS_LIST_SUCCESS,
    FETCH_SCROLL_GYMS_SUCCESS,
    SEARCH_GYMS_SUCCESS,
    FETCH_GYMS_SPECIAL_PRICE_SUCCESS,
    REMOVE_SPECIAL_PRICE_SUCCESS,
    FILTER_GYMS_SPECIAL_PRICE_SUCCESS,
    FETCH_CITIES_SUCCESS,
    UPLOAD_ICON_SUCCESS,
    DELETE_GYM_ICON_SUCCESS,
} from './types';

const initialState: GymState = {
    ids: [],
    byId: {},
    hiddenGymsIds: [],
    hiddenGymsById: {},
    foundGymsIds: [],
    foundGymsById: {},
    withSpecialPriceIds: [],
    withSpecialPriceById: {},
    groupWorkoutsIds: [],
    groupWorkoutsById: {},
    groupWorkoutsNewById: {},
    calendarGroupWorkouts: [],
    selectedGymId: '',
    reservationStatuses: {},
    currentGym: undefined,
    gymsList: [],
    cities: [],
};

export function containsDate(
    events: Array<CancelEventType>,
    date: Date
): boolean {
    for (const event of events) {
        if (
            new Date(
                new Date(event.cancelDate).getTime() +
                    new Date(event.cancelDate).getTimezoneOffset() * 60000
            ).getTime() == new Date(date).getTime()
        )
            return true;
    }
    return false;
}

export function gymReducer(state = initialState, action: AnyAction): GymState {
    if (action.type === FETCH_GYMS_LIST_SUCCESS) {
        const { gymsList } = action.payload;
        const sortGymsList = gymsList.sort((a: Gym, b: Gym) => {
            if (a.title > b.title) return 1;
            if (a.title < b.title) return -1;
            return 0;
        });

        return {
            ...state,
            gymsList: sortGymsList,
        };
    }

    if (action.type === FETCH_CITIES_SUCCESS) {
        const { cities } = action.payload;

        return {
            ...state,
            cities,
        };
    }

    if (action.type === FETCH_GYMS_SUCCESS) {
        const { gyms } = action.payload;
        const ids = gyms.map((gym: Gym) => gym._id);
        const byId = gyms.reduce(
            (total: { [id: string]: Gym }, gym: Gym) => ({
                ...total,
                [gym._id]: gym,
            }),
            {}
        );

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === FETCH_SCROLL_GYMS_SUCCESS) {
        const { gyms } = action.payload;
        const ids = gyms.map((gym: Gym) => gym._id);
        const byId = gyms.reduce(
            (total: { [id: string]: Gym }, gym: Gym) => ({
                ...total,
                [gym._id]: gym,
            }),
            {}
        );

        return {
            ...state,
            ids: [...state.ids, ...ids],
            byId: { ...state.byId, ...byId },
        };
    }

    if (action.type === FETCH_HIDDEN_GYMS_SUCCESS) {
        const { items } = action.payload;
        const hiddenGymsIds = items.map((gym: Gym) => gym._id);
        const hiddenGymsById = items.reduce(
            (total: { [id: string]: Gym }, gym: Gym) => ({
                ...total,
                [gym._id]: gym,
            }),
            {}
        );

        return {
            ...state,
            hiddenGymsIds,
            hiddenGymsById,
        };
    }

    if (action.type === FETCH_GYM_SUCCESS) {
        const gym = action.payload;
        const included = state.ids.includes(gym._id);
        const ids = included ? state.ids : [...state.ids, gym._id];
        const byId = {
            ...state.byId,
            [gym._id]: gym,
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === FETCH_HIDDEN_GYM_SUCCESS) {
        const gym = action.payload;
        const included = state.hiddenGymsIds.includes(gym._id);
        const hiddenGymsIds = included
            ? state.hiddenGymsIds
            : [...state.hiddenGymsIds, gym._id];
        const hiddenGymsById = {
            ...state.hiddenGymsById,
            [gym._id]: gym,
        };

        return {
            ...state,
            hiddenGymsIds,
            hiddenGymsById,
        };
    }

    if (action.type === SEARCH_GYMS_SUCCESS) {
        const { gyms } = action.payload;
        const foundGymsIds = gyms.map((gym: Gym) => gym._id);
        const foundGymsById = gyms.reduce(
            (total: { [id: string]: Gym }, gym: Gym) => ({
                ...total,
                [gym._id]: gym,
            }),
            {}
        );

        return {
            ...state,
            foundGymsIds,
            foundGymsById,
        };
    }

    if (
        action.type === UPDATE_GYM_SUCCESS ||
        action.type === UPDATE_GYM_IMAGES_SUCCESS
    ) {
        const gym = action.payload;
        const included = state.ids.includes(gym._id);
        const ids = included ? state.ids : [...state.ids, gym._id];
        const byId = {
            ...state.byId,
            [gym._id]: {
                ...state.byId[gym._id],
                ...gym,
            },
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === UPDATE_MANAGER_GYM_SUCCESS) {
        const gym = action.payload;
        const included = state.ids.includes(gym._id);
        const ids = included ? state.ids : [...state.ids, gym._id];
        const byId = {
            ...state.byId,
            [gym._id]: {
                ...state.byId[gym._id],
                ...gym,
            },
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === UPDATE_LEGALENTITY_GYM_SUCCESS) {
        const gym = action.payload;
        const included = state.ids.includes(gym._id);
        const ids = included ? state.ids : [...state.ids, gym._id];
        const byId = {
            ...state.byId,
            [gym._id]: {
                ...state.byId[gym._id],
                ...gym,
            },
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === SELECT_GYM) {
        const gymId = action.payload;
        return {
            ...state,
            selectedGymId: gymId,
        };
    }

    if (action.type === CANCEL_GROUP_WORKOUT_SUCCESS) {
        const { groupWorkout } = action.payload;

        const groupWorkoutsById = {
            ...state.groupWorkoutsById,
        };
        groupWorkoutsById[groupWorkout._id] = groupWorkout;

        return {
            ...state,
            groupWorkoutsById,
        };
    }

    if (action.type === FETCH_RESERVATION_STATUS_SUCCESS) {
        const reservationStatusesList = action.payload;
        const reservationStatuses: {
            [groupWorkoutId: string]: { [dateStart: string]: number };
        } = {};
        for (const reservationStatusItem of reservationStatusesList) {
            if (
                !(reservationStatusItem.groupWorkoutId in reservationStatuses)
            ) {
                reservationStatuses[reservationStatusItem.groupWorkoutId] = {};
            }
            reservationStatuses[reservationStatusItem.groupWorkoutId][
                moment(reservationStatusItem.dateStart).format(DATE_TIME_FORMAT)
            ] = reservationStatusItem.reservedSlots;
        }
        return {
            ...state,
            reservationStatuses,
        };
    }

    if (action.type === UPLOAD_IMAGE_SUCCESS) {
        const { gym } = action.payload;
        const included = state.ids.includes(gym._id);
        const ids = included ? state.ids : [...state.ids, gym._id];
        const byId = {
            ...state.byId,
            [gym._id]: {
                ...state.byId[gym._id],
                ...gym,
            },
        };
        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === DELETE_GYM_IMAGE_SUCCESS) {
        const { gym } = action.payload;
        const included = state.ids.includes(gym._id);
        const ids = included ? state.ids : [...state.ids, gym._id];
        const byId = {
            ...state.byId,
            [gym._id]: {
                ...state.byId[gym._id],
                ...gym,
            },
        };
        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === UPLOAD_ICON_SUCCESS) {
        const gym = action.payload;
        const included = state.ids.includes(gym._id);
        const ids = included ? state.ids : [...state.ids, gym._id];
        const byId = {
            ...state.byId,
            [gym._id]: {
                ...state.byId[gym._id],
                ...gym,
            },
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === DELETE_GYM_ICON_SUCCESS) {
        const gym = action.payload;
        const included = state.ids.includes(gym._id);
        const ids = included ? state.ids : [...state.ids, gym._id];
        const byId = {
            ...state.byId,
            [gym._id]: {
                ...state.byId[gym._id],
                ...gym,
            },
        };
        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === CREATE_GYM_SUCCESS) {
        const gym = action.payload;
        const included = state.ids.includes(gym._id);
        const ids = included ? state.ids : [...state.ids, gym._id];
        const byId = {
            ...state.byId,
            [gym._id]: {
                ...state.byId[gym._id],
                ...gym,
            },
        };
        return { ...state, currentGym: gym._id, ids, byId };
    }

    if (action.type === FETCH_GYMS_SPECIAL_PRICE_SUCCESS) {
        const gyms = action.payload;
        const withSpecialPriceIds = gyms.map((gym: Gym) => gym._id);
        const withSpecialPriceById = gyms.reduce(
            (total: { [id: string]: Gym }, gym: Gym) => ({
                ...total,
                [gym._id]: gym,
            }),
            {}
        );

        return {
            ...state,
            withSpecialPriceIds,
            withSpecialPriceById,
        };
    }

    if (action.type === FILTER_GYMS_SPECIAL_PRICE_SUCCESS) {
        const gyms = action.payload;
        const filterGyms = gyms.filter((gym: Gym) => gym.hasSpecialPrice);
        const withSpecialPriceIds = filterGyms.map((gym: Gym) => gym._id);
        const withSpecialPriceById = filterGyms.reduce(
            (total: { [id: string]: Gym }, gym: Gym) => ({
                ...total,
                [gym._id]: gym,
            }),
            {}
        );

        return {
            ...state,
            withSpecialPriceIds,
            withSpecialPriceById,
        };
    }

    if (action.type === REMOVE_SPECIAL_PRICE_SUCCESS) {
        const gym = action.payload;
        const included = state.ids.includes(gym._id);
        const ids = included ? state.ids : [...state.ids, gym._id];
        const byId = {
            ...state.byId,
            [gym._id]: {
                ...state.byId[gym._id],
                ...gym,
            },
        };

        const withSpecialPriceIds = state.withSpecialPriceIds.filter(
            (gymId) => gymId !== gym._id
        );

        const withSpecialPriceById = {
            ...state.withSpecialPriceById,
        };

        delete withSpecialPriceById[gym._id];

        return {
            ...state,
            ids,
            byId,
            withSpecialPriceIds,
            withSpecialPriceById,
        };
    }

    return state;
}
