import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Verification, Auth, RegistrationSteps, Dashboard } from '@containers';
import { fetchUser } from '@redux/modules/auth/actions';
import { getToken, getUser } from '@redux/modules/auth/selectors';
import { AsyncDispatch } from '@redux/types';
import PrivateRoute, { AuthType } from './components/PrivateRoute';

export const AppRoutes = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const token = useSelector(getToken);
    const user = useSelector(getUser);

    const isAuthenticated = !!token;
    const isVerified = user?.verified;
    const isRegistrationCompleted = user?.registrationCompleted;

    useEffect(() => {
        dispatch(fetchUser()).catch(() => {
            // do nothing
        });
    }, [dispatch]);

    return (
        <>
            {!isAuthenticated && (
                <Switch>
                    <Route path="/auth" component={Auth} />
                    <Route
                        path="*"
                        render={() => <Redirect to="/auth/login" />}
                    />
                </Switch>
            )}

            {isAuthenticated && user && !isRegistrationCompleted && (
                <Switch>
                    <Route
                        path="/registration-steps"
                        component={RegistrationSteps}
                    />
                    <Route
                        render={() => <Redirect to="/registration-steps" />}
                    />
                </Switch>
            )}

            {isAuthenticated && user && isRegistrationCompleted && !isVerified && (
                <Switch>
                    <PrivateRoute
                        type={AuthType.Authenticated}
                        path="/verify"
                        component={Verification}
                    />
                    <Route path="/" render={() => <Redirect to="/verify" />} />
                </Switch>
            )}

            {isRegistrationCompleted && isVerified && (
                <Switch>
                    <Route path="*" component={Dashboard} />
                </Switch>
            )}
        </>
    );
};
