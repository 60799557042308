export const FETCH_CONFIG_REQUEST = 'FETCH_CONFIG_REQUEST';
export const FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS';
export const FETCH_CONFIG_ERROR = 'FETCH_CONFIG_ERROR';

export interface ConfigState {
    isServerUnavailable: boolean;
    minVersionAndroid: string;
    minVersionIOS: string;
    whatsNewAndroid: string;
    whatsNewIOS: string;
    isAuthUnavailable: boolean;
    updateRequired: boolean;
    courses: {
        ios: string[];
        android: string[];
    };
    reservationMinHours: number;
    freeCancellationMinHours: number;
    confirmationLimit: number;
    subscriptions: {
        iconUrl: string;
        title: string;
        description: string;
    };
    groupworkoutreservations: {
        iconUrl: string;
        title: string;
        description: string;
    };
    certificates: {
        active: boolean;
        webUrl: string;
        iconUrl: string;
        title: string;
        description: string;
    };
}
