import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { TESTGYM } from '@config';
import { AsyncDispatch } from '@redux/types';
import BackButton from '@components/BackButton';
import { getAppUser } from '@redux/modules/app-users/selectors';
import { fetchAppUser } from '@redux/modules/app-users/actions';
import AppUserStartBookingForm, { AppUserStartBookingFormData } from './Form';
import { getGymsList } from '@redux/modules/gyms/selectors';
import { fetchGymsList } from '@redux/modules/gyms/actions';
import { startUserRemoteBooking } from '@redux/modules/bookings/actions';

import styles from './styles.module.css';

export const AppUserStartBooking: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const appUser = useSelector(getAppUser(id || ''));
    const history = useHistory();

    const gymsList = useSelector(getGymsList);

    useEffect(() => {
        if (!id) {
            return;
        }
        dispatch(fetchGymsList());
        dispatch(fetchAppUser(id));
    }, [dispatch, id]);

    const onSubmit = useCallback(
        (values: AppUserStartBookingFormData, setSubmitting) => {
            const {
                gymId,
                type,
                groupWorkoutReservationNewId,
                timeStarted,
                timeFinished,
            } = values;

            dispatch(
                startUserRemoteBooking({
                    userId: id,
                    gymId,
                    type,
                    groupWorkoutReservationNewId,
                    timeStarted,
                    timeFinished,
                })
            )
                .then((booking) => {
                    if (booking) {
                        cogoToast.success('Тренировка запущена', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                        history.push(`/dashboard/bookings`);
                        setSubmitting(false);
                    }
                })
                .catch((error) => {
                    cogoToast.error(error?.response?.data?.message, {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                    setSubmitting(false);
                });
        },
        [id, dispatch]
    );

    const onCancel = () => {
        history.goBack();
    };

    const gymOptions = gymsList.map((gym) => {
        return {
            title: gym.title,
            value: gym._id,
        };
    });

    if (!appUser) {
        return null;
    }

    const initialValues = {
        gymId: TESTGYM,
        type: '',
        groupWorkoutReservationNewId: '',
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <h1>Создать тренировку</h1>
                <BackButton
                    to={`/dashboard/app-users/${id}`}
                    title="Назад"
                    className={styles.backBtn}
                />
                <div className={styles.box}>
                    <AppUserStartBookingForm
                        initialValues={initialValues}
                        userId={id}
                        gymsList={gymsList}
                        gymOptions={gymOptions}
                        onSubmit={onSubmit}
                        onCancel={onCancel}
                    />
                </div>
            </div>
        </div>
    );
};

export default AppUserStartBooking;
