export interface LegalEntity {
    _id: string;
    name: string;
    contractNumber: string;
    managerEmail: string;
    address: string;
    actualAddress: string;
    inn: string;
    checkingAccount: string;
    correspondentAccount: string;
    bik: string;
    goGymFee: number;
    goGymCommission: GoGymCommission[];
    acquiringCommission: number;
    createdAt: Date;
    gyms: string[];
    comment: string;
    docs: string[];
    gymsTitle: string[];
}

export interface GoGymCommission {
    collaborationType: string;
    commissionType: string;
    membershipType: string;
    membershipDuration: string;
    commission: number;
}

export enum СollaborationType {
    PerMinWorkout = 'PER_MIN_WORKOUT',
    GroupWorkout = 'GROUP_WORKOUT',
    SingleWorkout = 'SINGLE_WORKOUT',
    Membership = 'MEMBERSHIP',
}

export enum СommissionType {
    Сommission = 'COMMISSION',
    Cost = 'COST',
    TransfersToPartner = 'TRANSFERS_TO_PARTNER',
}

export const СollaborationTypeOptions = [
    { title: 'Поминутная', value: СollaborationType.PerMinWorkout },
    { title: 'Групповая', value: СollaborationType.GroupWorkout },
    { title: 'Абонемент', value: СollaborationType.Membership },
];

export const СommissionTypeOptions = [
    { title: 'Комиссия', value: СommissionType.Сommission },
    {
        title: 'Перечисление партнеру',
        value: СommissionType.TransfersToPartner,
    },
];

export enum MembershipTimeForLegalEntity {
    ONEMONTH = 30,
    TWOMONTHS = 60,
    THREEMONTHS = 90,
    FOURMONTHS = 120,
    FIVEMONTHS = 150,
    SIXMONTHS = 180,
    SEVENMONTHS = 210,
    EIGHTMONTHS = 240,
    NINEMONTHS = 270,
    TENMONTHS = 300,
    ELEVENMONTHS = 330,
    TWELVEMONTHS = 365,
}
