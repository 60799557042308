import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { getReservations } from '@redux/modules/reservations/selectors';
import {
    abortMultipleReservations,
    fetchReservations,
    fetchScrollReservations,
} from '@redux/modules/reservations/actions';
import Box from '@components/Box';
import { getSelectedGymId } from '@redux/modules/gyms/selectors';
import BoxButton from '@components/BoxButton';
import Toggle from '@components/Toggle';
import { AsyncDispatch } from '@redux/types';
import BackButton from '@components/BackButton';

import ReservationsListTable from './ReservationsListTable';
import styles from './styles.module.css';
import { selectGym } from '@redux/modules/gyms/actions';
import moment from 'moment';
import Modal from '@components/Modal';
import Button from '@components/Button';
import classNames from 'classnames';

export const TimeSlotReservationsList: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { timeSlotId } = useParams<{ timeSlotId: string }>();
    const location = useLocation<{ dateStart: Date }>();
    const date = moment(location.state?.dateStart)
        .startOf('day')
        .utc(true)
        .toDate();
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const reservations = useSelector(getReservations);
    const selectedGymId = useSelector(getSelectedGymId);
    const [isToggled, setIsToggled] = useState<boolean>(false);
    const [fetching, setFetching] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [reservationsIsOver, setReservationsIsOver] = useState<boolean>(
        false
    );
    const [
        modalConfirmDeleteReservationsActive,
        setModalConfirmDeleteReservationsActive,
    ] = useState(false);

    const $fetchReservations = () => {
        dispatch(
            fetchReservations({
                groupWorkoutId: id,
                timeSlotId,
                date,
                isToggled,
                count: 0,
            })
        )
            .then((reservations) => {
                if (reservations.length < 99) {
                    setLoading(false);
                    setReservationsIsOver(true);
                } else {
                    setLoading(false);
                    setReservationsIsOver(false);
                }
            })
            .catch(() => {
                cogoToast.error('Ошибка при загрузке бронирований', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            })
            .finally(() => setFetching(false));
    };

    useEffect(() => {
        $fetchReservations();
    }, [dispatch, selectedGymId, isToggled]);

    const scrollHandler = (event: Event) => {
        if (
            (event.target as Document).documentElement.scrollHeight -
                ((event.target as Document).documentElement.scrollTop +
                    window.innerHeight) <
                500 &&
            (event.target as Document).documentElement.scrollTop > 1000
        ) {
            setFetching(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    useEffect(() => {
        if (fetching && !reservationsIsOver) {
            setLoading(true);
            dispatch(
                fetchScrollReservations({
                    groupWorkoutId: id,
                    timeSlotId,
                    isToggled,
                    count: reservations.length,
                })
            )
                .then((reservations) => {
                    if (reservations.length < 99) {
                        setLoading(false);
                        setReservationsIsOver(true);
                    } else {
                        setLoading(false);
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при загрузке бронирований', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => setFetching(false));
        }
    }, [dispatch, selectedGymId, fetching]);

    const goToCalendar = useCallback(() => {
        dispatch(selectGym(selectedGymId));
        history.push('/dashboard/group-workouts/calendar');
    }, [dispatch]);

    const goToTimeSlotDetails = useCallback(
        () =>
            history.push(
                `/dashboard/group-workouts/${id}/timeslots/${timeSlotId}`,
                {
                    dateStart: date,
                }
            ),
        [history]
    );

    const onConfirmDeleteAllReservations = useCallback(() => {
        const groupWorkoutReservationsIds = reservations.map(
            (reservation) => reservation._id
        );

        setModalConfirmDeleteReservationsActive(false);
        dispatch(
            abortMultipleReservations({
                groupWorkoutReservations: groupWorkoutReservationsIds,
            })
        )
            .then(() => {
                cogoToast.success('Бронирования успешно отменены', {
                    position: 'top-right',
                    hideAfter: 5,
                });
                $fetchReservations();
            })
            .catch(() => {
                cogoToast.error('Ошибка при отмене бронирований', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    }, [reservations]);

    return (
        <div>
            <BackButton
                title="В календарь"
                className={styles.backBtn}
                onClick={goToCalendar}
            />
            <div className={styles.actions}>
                <div className={styles.actionsColumn}>
                    <div className={styles.nav}>
                        <BoxButton
                            icon=""
                            title="Общая информация"
                            onClick={goToTimeSlotDetails}
                            className={styles.actionBtn}
                        />
                        <BoxButton
                            icon=""
                            title="Бронирования"
                            className={styles.actionNav}
                        />
                    </div>
                </div>
                <div className={styles.actionsColumn}>
                    <div className={styles.actionsControls}>
                        <div
                            className={styles.cancelBtn}
                            onClick={() =>
                                setModalConfirmDeleteReservationsActive(true)
                            }
                        >
                            Отменить все бронирования
                        </div>
                        <div className={styles.toggle}>
                            <p className={styles.toggleTitle}>
                                Показать все бронирования
                            </p>

                            <Toggle
                                isToggled={isToggled}
                                onToggle={() => {
                                    setIsToggled(!isToggled);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Box>
                <div className={styles.tableWrapper}>
                    <ReservationsListTable
                        reservations={reservations}
                        loading={loading}
                    />
                </div>
            </Box>
            <Modal
                active={modalConfirmDeleteReservationsActive}
                setActive={(prev) => {
                    setModalConfirmDeleteReservationsActive(prev);
                }}
            >
                <div className={styles.modalContainer}>
                    <h2>Внимание!</h2>
                    <p>Это действие невозможно отменить!</p>
                    <p>
                        Все бронирования по данной тренировке на выбранную дату
                        будут отменены, а <br />
                        средства возвращены пользователям.
                    </p>
                    <div className={styles.modalActionControls}>
                        <Button
                            className={styles.modalActionBtn}
                            onClick={() =>
                                setModalConfirmDeleteReservationsActive(false)
                            }
                            buttonType={'primary'}
                            type={'button'}
                        >
                            Оставить бронирования
                        </Button>
                        <Button
                            className={classNames(
                                styles.modalActionBtn,
                                styles.modalActionBtnSecondary
                            )}
                            onClick={onConfirmDeleteAllReservations}
                            buttonType={'primary'}
                            type={'button'}
                        >
                            Удалить все
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default TimeSlotReservationsList;
