export interface Tag {
    _id: string;
    tagType: {
        name: string;
        tagTypeValue: string;
    }[];
    tagName: string;
    iconLink: string;
    comment: string;
    categoryIds: string[];
    categories: TagCategorie[];
    createdAt: Date;
}

export interface TagCategorie {
    _id: string;
    title: string;
    description: string;
    preview: string;
    priority: number;
    active: boolean;
}

export const tagTypeOptions = [
    {
        label: 'Услуги зала',
        value: 'GYMSERVICE',
    },
    {
        label: 'Преимущества зала',
        value: 'GYMADVANTAGE',
    },
    {
        label: 'Фильтрация групповых тренировок',
        value: 'GROUPWORKOUTFILTER',
    },
];
