import React from 'react';
import {
    Formik,
    Field,
    Form,
    ErrorMessage,
    FieldArray,
    FieldProps,
} from 'formik';
import ReactInputMask from 'react-input-mask';

import Button from '@components/Button';

import styles from './styles.module.css';

export interface ContactsFormData {
    phone: string[];
    email: string[];
    tg: string[];
    vk: string[];
}

export interface Props {
    onSubmit: (values: {
        phone: string[];
        email: string[];
        tg: string[];
        vk: string[];
    }) => void;
    contacts: {
        phone: string[];
        email: string[];
        tg: string[];
        vk: string[];
    };
    disabled: boolean;
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const isEmptyError = (errors: { [i: number]: string }) => {
    for (const key in errors) {
        if (
            Object.prototype.hasOwnProperty.call(errors, key) &&
            Object.keys(errors[key]).length !== 0
        ) {
            return false;
        }
    }
    return true;
};

const validate = (values: {
    phone: string[];
    email: string[];
    tg: string[];
    vk: string[];
}) => {
    const errors: {
        phone: { [i: number]: string };
        email: { [i: number]: string };
        tg: { [i: number]: string };
        vk: { [i: number]: string };
    } = { phone: {}, email: {}, tg: {}, vk: {} };

    values.phone.forEach((p, i) => {
        if (p.length > 18) {
            errors.phone[i] = 'Невалидный телефон';
        }

        if (p.replace(/[^\d$]/g, '').length !== 11) {
            errors.phone[i] = 'Обязательное поле';
        }

        if (!p) {
            errors.phone[i] = 'Обязательное поле';
        }
    });

    values.email.forEach((e, i) => {
        if ((e.match(/ /gi) || []).length > 0) {
            errors.email[i] = 'Пробелы запрещены';
        }

        if (
            !/^(([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})|([а-яА-Я0-9_\-.])+@([а-яА-Я0-9_\-.])+\.([а-яА-Я]{2,4}))$/.test(
                e
            )
        ) {
            errors.email[i] = 'Невалидный email';
        }

        if (e.length >= 30) {
            errors.email[i] = 'Максимальное количество символов для ввода 30';
        }

        if (!e) {
            errors.email[i] = 'Обязательное поле';
        }
    });

    values.tg.forEach((t, i) => {
        if ((t.match(/ /gi) || []).length > 0) {
            errors.tg[i] = 'Пробелы запрещены';
        }

        if (t.length >= 100) {
            errors.tg[i] = 'Максимальное количество символов для ввода 100';
        }

        if (!t) {
            errors.tg[i] = 'Обязательное поле';
        }
    });

    values.vk.forEach((v, i) => {
        if ((v.match(/ /gi) || []).length > 0) {
            errors.vk[i] = 'Пробелы запрещены';
        }

        if (v.length >= 100) {
            errors.vk[i] = 'Максимальное количество символов для ввода 100';
        }

        if (!v) {
            errors.vk[i] = 'Обязательное поле';
        }
    });

    const isEmpty = isEmptyError(errors);

    return isEmpty ? {} : errors;
};

const ContactsForm: React.FC<Props> = ({
    onSubmit,
    contacts,
    disabled,
    setDisabled,
}) => {
    return (
        <div>
            <Formik
                validate={validate}
                initialValues={contacts}
                onSubmit={onSubmit}
            >
                {({ values }) => (
                    <>
                        <Form>
                            <FieldArray name="phone">
                                {({ remove, push }) => (
                                    <div>
                                        {values.phone.length > 0 &&
                                            values.phone.map((phone, index) => (
                                                <div
                                                    className={styles.row}
                                                    key={index}
                                                >
                                                    <div className={styles.col}>
                                                        <label
                                                            className={
                                                                styles.label
                                                            }
                                                            htmlFor={`phone.${index}`}
                                                        >
                                                            Телефон поддержки
                                                        </label>
                                                        <Field
                                                            name={`phone.${index}`}
                                                        >
                                                            {({
                                                                field,
                                                            }: FieldProps) => (
                                                                <ReactInputMask
                                                                    {...field}
                                                                    disabled={
                                                                        disabled
                                                                    }
                                                                    name={`phone.${index}`}
                                                                    type="tel"
                                                                    mask="+7 (999) 999-99-99"
                                                                    className={
                                                                        styles.input
                                                                    }
                                                                    placeholder="Телефон поддержки"
                                                                ></ReactInputMask>
                                                            )}
                                                        </Field>
                                                        <ErrorMessage
                                                            className={
                                                                styles.error
                                                            }
                                                            name={`phone.${index}`}
                                                            component="div"
                                                        />
                                                    </div>
                                                    <div className={styles.col}>
                                                        {!disabled &&
                                                            values.phone
                                                                .length > 1 && (
                                                                <Button
                                                                    className={
                                                                        styles.btn
                                                                    }
                                                                    type="button"
                                                                    onClick={() =>
                                                                        remove(
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    Удалить
                                                                </Button>
                                                            )}
                                                    </div>
                                                </div>
                                            ))}
                                        {!disabled && (
                                            <Button
                                                className={styles.btn}
                                                type="button"
                                                onClick={() => push('')}
                                            >
                                                + Добавить телефон
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </FieldArray>

                            <FieldArray name="email">
                                {({ remove, push }) => (
                                    <div>
                                        {values.email.length > 0 &&
                                            values.email.map((email, index) => (
                                                <div
                                                    className={styles.row}
                                                    key={index}
                                                >
                                                    <div className={styles.col}>
                                                        <label
                                                            className={
                                                                styles.label
                                                            }
                                                            htmlFor={`email.${index}`}
                                                        >
                                                            Email
                                                        </label>
                                                        <Field
                                                            disabled={disabled}
                                                            className={
                                                                styles.input
                                                            }
                                                            name={`email.${index}`}
                                                            placeholder="Email"
                                                            type="text"
                                                        />
                                                        <ErrorMessage
                                                            className={
                                                                styles.error
                                                            }
                                                            name={`email.${index}`}
                                                            component="div"
                                                        />
                                                    </div>
                                                    <div className={styles.col}>
                                                        {!disabled &&
                                                            values.email
                                                                .length > 1 && (
                                                                <Button
                                                                    className={
                                                                        styles.btn
                                                                    }
                                                                    type="button"
                                                                    onClick={() =>
                                                                        remove(
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    Удалить
                                                                </Button>
                                                            )}
                                                    </div>
                                                </div>
                                            ))}
                                        {!disabled && (
                                            <Button
                                                className={styles.btn}
                                                type="button"
                                                onClick={() => push('')}
                                            >
                                                + Добавить Email
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </FieldArray>

                            <FieldArray name="tg">
                                {({ remove, push }) => (
                                    <div>
                                        {values.tg.length > 0 &&
                                            values.tg.map((tg, index) => (
                                                <div
                                                    className={styles.row}
                                                    key={index}
                                                >
                                                    <div className={styles.col}>
                                                        <label
                                                            className={
                                                                styles.label
                                                            }
                                                            htmlFor={`tg.${index}`}
                                                        >
                                                            Telegram канал
                                                        </label>
                                                        <Field
                                                            disabled={disabled}
                                                            className={
                                                                styles.input
                                                            }
                                                            name={`tg.${index}`}
                                                            placeholder="Telegram канал"
                                                            type="text"
                                                        />
                                                        <ErrorMessage
                                                            className={
                                                                styles.error
                                                            }
                                                            name={`tg.${index}`}
                                                            component="div"
                                                        />
                                                    </div>
                                                    <div className={styles.col}>
                                                        {!disabled &&
                                                            values.tg.length >
                                                                1 && (
                                                                <Button
                                                                    className={
                                                                        styles.btn
                                                                    }
                                                                    type="button"
                                                                    onClick={() =>
                                                                        remove(
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    Удалить
                                                                </Button>
                                                            )}
                                                    </div>
                                                </div>
                                            ))}
                                        {!disabled && (
                                            <Button
                                                className={styles.btn}
                                                type="button"
                                                onClick={() => push('')}
                                            >
                                                + Добавить Telegram канал
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </FieldArray>

                            <FieldArray name="vk">
                                {({ remove, push }) => (
                                    <div>
                                        {values.vk.length > 0 &&
                                            values.vk.map((vk, index) => (
                                                <div
                                                    className={styles.row}
                                                    key={index}
                                                >
                                                    <div className={styles.col}>
                                                        <label
                                                            className={
                                                                styles.label
                                                            }
                                                            htmlFor={`vk.${index}`}
                                                        >
                                                            Группа Вконтакте
                                                        </label>
                                                        <Field
                                                            disabled={disabled}
                                                            className={
                                                                styles.input
                                                            }
                                                            name={`vk.${index}`}
                                                            placeholder="Группа Вконтакте"
                                                            type="text"
                                                        />
                                                        <ErrorMessage
                                                            className={
                                                                styles.error
                                                            }
                                                            name={`vk.${index}`}
                                                            component="div"
                                                        />
                                                    </div>
                                                    <div className={styles.col}>
                                                        {!disabled &&
                                                            values.vk.length >
                                                                1 && (
                                                                <Button
                                                                    className={
                                                                        styles.btn
                                                                    }
                                                                    type="button"
                                                                    onClick={() =>
                                                                        remove(
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    Удалить
                                                                </Button>
                                                            )}
                                                    </div>
                                                </div>
                                            ))}
                                        {!disabled && (
                                            <Button
                                                className={styles.btn}
                                                type="button"
                                                onClick={() => push('')}
                                            >
                                                + Добавить группу Вконтакте
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </FieldArray>

                            {!disabled && (
                                <Button
                                    className={styles.btnSubmit}
                                    type="submit"
                                >
                                    Обновить
                                </Button>
                            )}
                        </Form>
                        {disabled && (
                            <Button
                                className={styles.btn}
                                onClick={() => setDisabled(false)}
                            >
                                Изменить
                            </Button>
                        )}
                    </>
                )}
            </Formik>
        </div>
    );
};

export default ContactsForm;
