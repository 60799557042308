import React, { useEffect, useCallback, useState, useRef, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { AsyncDispatch } from '@redux/types';
import BackButton from '@components/BackButton';
import { getTag, getTagCategories } from '@redux/modules/tags/selectors';
import {
    deleteIcon,
    deleteTag,
    fetchTag,
    fetchTagCategories,
    updateTag,
} from '@redux/modules/tags/actions';

import { getKeyFromUrl } from '../GymDetails';
import styles from './styles.module.css';
import TagForm from './Form';

export const TagDetails: React.FC = memo(() => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const tagCategories = useSelector(getTagCategories);
    const tag = useSelector(getTag(id || ''));
    const [disabled, setDisabled] = useState<boolean>(true);
    const [selectedErrors, setSelectedErrors] = useState({
        tagError: false,
        tagCategoryError: false,
    });
    const fileInput = useRef<null>(null);

    const [selectedTagType, setSelectedTagType] = useState<
        { label: string; value: string }[]
    >([]);
    const [selectedTagCategories, setSelectedTagCategories] = useState<
        { label: string; value: string }[]
    >([]);
    const [
        groupWorkoutFilterTagType,
        setGroupWorkoutFilterTagType,
    ] = useState<boolean>(false);

    useEffect(() => {
        if (!id) {
            return;
        }
        dispatch(fetchTagCategories()).then(() => dispatch(fetchTag(id)));
    }, [dispatch, id]);

    useEffect(() => {
        if (!tag) {
            return;
        }
        setSelectedTagType(
            tag.tagType.map((type) => {
                return {
                    label: type.name,
                    value: type.tagTypeValue,
                };
            })
        );
        if (!tag?.categoryIds?.length) {
            return;
        }

        setSelectedTagCategories(
            tagCategories.reduce(
                (acc: { label: string; value: string }[], tagC) => {
                    if (tag?.categoryIds?.includes(tagC._id))
                        acc.push({
                            label: tagC.title,
                            value: tagC._id,
                        });
                    return acc;
                },
                []
            )
        );
    }, [tag]);

    useEffect(() => {
        if (!tag) {
            return;
        }
        selectedTagType.some((tag) => tag.value === 'GROUPWORKOUTFILTER')
            ? setGroupWorkoutFilterTagType(true)
            : setGroupWorkoutFilterTagType(false);
    }, [selectedTagType]);

    const onSubmit = useCallback(
        (values) => {
            if (!id) {
                return;
            }
            const files = (fileInput.current || { files: new FileList() })
                .files;

            if (!selectedTagType.length) {
                setSelectedErrors((prevState) => ({
                    ...prevState,
                    tagError: true,
                }));
                return;
            }

            if (groupWorkoutFilterTagType && !selectedTagCategories.length) {
                setSelectedErrors((prevState) => ({
                    ...prevState,
                    tagCategoryError: true,
                }));
                return;
            }

            if (
                files?.length &&
                !selectedErrors.tagError &&
                selectedTagType.length !== 0
            ) {
                dispatch(
                    updateTag({
                        id,
                        values,
                        tagType: selectedTagType?.length
                            ? selectedTagType.map((type) => {
                                  return {
                                      name: type.label,
                                      tagTypeValue: type.value,
                                  };
                              })
                            : [],
                        categoryIds: selectedTagType.some(
                            (tag) => tag.value === 'GROUPWORKOUTFILTER'
                        )
                            ? selectedTagCategories.map((c) => c.value)
                            : [],
                        files,
                    })
                )
                    .then(() => {
                        cogoToast.success('Тэг обновлен', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                        setDisabled(true);
                        history.push(`/dashboard/tags`);
                    })
                    .catch(() => {
                        cogoToast.error('Ошибка при обновлении тэга', {
                            position: 'top-right',
                            hideAfter: 4,
                        });
                    });
            } else if (
                !selectedErrors.tagError &&
                selectedTagType.length !== 0
            ) {
                dispatch(
                    updateTag({
                        id,
                        values,
                        tagType: selectedTagType?.length
                            ? selectedTagType.map((type) => {
                                  return {
                                      name: type.label,
                                      tagTypeValue: type.value,
                                  };
                              })
                            : [],
                        categoryIds: selectedTagType.some(
                            (tag) => tag.value === 'GROUPWORKOUTFILTER'
                        )
                            ? selectedTagCategories.map((c) => c.value)
                            : [],
                    })
                )
                    .then(() => {
                        cogoToast.success('Тэг обновлен', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                        setDisabled(true);
                        history.push(`/dashboard/tags`);
                    })
                    .catch(() => {
                        cogoToast.error('Ошибка при обновлении тэга', {
                            position: 'top-right',
                            hideAfter: 4,
                        });
                    });
            }
        },
        [
            id,
            dispatch,
            selectedTagType,
            selectedTagCategories,
            groupWorkoutFilterTagType,
            selectedErrors,
            tag,
        ]
    );

    const onDeleteTag = useCallback(() => {
        dispatch(deleteTag(id))
            .then(() => {
                cogoToast.success('Тэг удален', {
                    position: 'top-right',
                    hideAfter: 5,
                });
                history.push(`/dashboard/tags`);
            })
            .catch(() => {
                cogoToast.error('Ошибка при удалении тэга', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    }, [dispatch, id]);

    const onDeleteIconClick = useCallback(
        async (event) => {
            try {
                if (!id) {
                    return;
                }
                const s3Key: string = getKeyFromUrl(event.target.id);
                await dispatch(deleteIcon({ id, key: s3Key }));
                setDisabled(true);
            } catch (err) {
                cogoToast.error(`Ошибка при удалении иконки: ${err}`, {
                    position: 'top-right',
                    hideAfter: 4,
                });
            }
        },
        [id, dispatch]
    );

    const onUploadFile = (event: any) => {
        const numOfFiles = Array.from(event.target.files).length;
        for (let i = 0; i < numOfFiles; ++i) {
            const validExtensions = ['svg+xml'];
            const fileExtension = event.target.files[i].type.split('/')[1];
            if (!validExtensions.includes(fileExtension)) {
                event.preventDefault();
                cogoToast.error(`Можно загрузить файлы с расширением svg`, {
                    position: 'top-right',
                    hideAfter: 4,
                });
                event.target.value = null;
                return;
            }
        }
    };

    const tagTypeValue =
        tag &&
        tag.tagType.map((type) => {
            return {
                label: type.name,
                value: type.tagTypeValue,
            };
        });

    const iconLink = tag?.iconLink;

    const tagCategoriesOptions = tagCategories.map((tagC) => ({
        label: tagC.title,
        value: tagC._id,
    }));

    if (!tag) {
        return null;
    }

    const initialValues = {
        tagId: tag._id,
        tagName: tag.tagName,
        iconLink: tag.iconLink,
        comment: tag.comment,
        createdAt: tag.createdAt,
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <BackButton
                    to="/dashboard/tags"
                    title="К списку тэгов"
                    className={styles.backBtn}
                />
                <div className={styles.box}>
                    <TagForm
                        onSubmit={onSubmit}
                        initialValues={initialValues}
                        disabled={disabled}
                        setDisabled={setDisabled}
                        onDeleteTag={onDeleteTag}
                        selectedTagType={tagTypeValue}
                        setSelectedTagType={setSelectedTagType}
                        iconLink={iconLink}
                        onDeleteIconClick={onDeleteIconClick}
                        fileInput={fileInput}
                        selectedErrors={selectedErrors}
                        setSelectedErrors={setSelectedErrors}
                        onUploadFile={onUploadFile}
                        selectedTagCategories={selectedTagCategories}
                        setSelectedTagCategories={setSelectedTagCategories}
                        tagCategoriesOptions={tagCategoriesOptions}
                        groupWorkoutFilterTagType={groupWorkoutFilterTagType}
                    />
                </div>
            </div>
        </div>
    );
});

export default TagDetails;
