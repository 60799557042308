import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { useHistory, useParams } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { abortBooking } from '@redux/modules/bookings/actions';
import { ApplicationState, AsyncDispatch } from '@redux/types';
import BackButton from '@components/BackButton';
import { AbortReasonType } from '@config';

import styles from './styles.module.css';
import Form, { FormData } from './Form';

export const BookingCancel: React.FC<{}> = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const selector = formValueSelector('cancelBooking');
    const abortReasonSelector = (state: ApplicationState) =>
        selector(state, 'abortReasonOption');
    const abortReasonOption = useSelector(abortReasonSelector);

    const onSubmit = useCallback(
        (values: FormData) => {
            const abortReasonType = values.abortReasonOption;
            const abortReasonMessage =
                abortReasonType !== AbortReasonType.OTHER
                    ? ''
                    : values.abortReasonText;

            dispatch(
                abortBooking({
                    id,
                    abortReasonType,
                    abortReasonMessage,
                })
            )
                .then(() => {
                    cogoToast.success('Тренировка завершена', {
                        position: 'top-right',
                        hideAfter: 5,
                    });
                    history.push(`/dashboard/bookings`);
                })
                .catch(() => {
                    cogoToast.error('Ошибка при завершении тренировки', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                });
        },
        [dispatch, id]
    );
    const initialValues = {
        abortReasonOption: AbortReasonType.INTERNET_DISCONNECT,
    };
    return (
        <div className={styles.container}>
            <BackButton
                to={`/dashboard/bookings/${id}`}
                title="Назад к тренировке"
                className={styles.backBtn}
            />

            <h2>Причина отмены тренировки</h2>

            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                enableReinitialize={true}
                abortReasonOption={abortReasonOption}
            />
        </div>
    );
};

export default BookingCancel;
