import { LegalEntity } from '@t/legal-entity';

export const FETCH_LEGALENTITIES_LIST_REQUEST =
    'FETCH_LEGALENTITIES_LIST_REQUEST';
export const FETCH_LEGALENTITIES_LIST_SUCCESS =
    'FETCH_LEGALENTITIES_LIST_SUCCESS';
export const FETCH_LEGALENTITIES_LIST_ERROR = 'FETCH_LEGALENTITIES_LIST_ERROR';

export const FETCH_LEGALENTITIES_REQUEST = 'FETCH_LEGALENTITIES_REQUEST';
export const FETCH_LEGALENTITIES_SUCCESS = 'FETCH_LEGALENTITIES_SUCCESS';
export const FETCH_LEGALENTITIES_ERROR = 'FETCH_LEGALENTITIES_ERROR';

export const FETCH_SCROLL_LEGALENTITIES_REQUEST =
    'FETCH_SCROLL_LEGALENTITIES_REQUEST';
export const FETCH_SCROLL_LEGALENTITIES_SUCCESS =
    'FETCH_SCROLL_LEGALENTITIES_SUCCESS';
export const FETCH_SCROLL_LEGALENTITIES_ERROR =
    'FETCH_SCROLL_LEGALENTITIES_ERROR';

export const FETCH_LEGALENTITY_REQUEST = 'FETCH_LEGALENTITY_REQUEST';
export const FETCH_LEGALENTITY_SUCCESS = 'FETCH_LEGALENTITY_SUCCESS';
export const FETCH_LEGALENTITY_ERROR = 'FETCH_LEGALENTITY_ERROR';

export const CREATE_LEGALENTITY_REQUEST = 'CREATE_LEGALENTITY_REQUEST';
export const CREATE_LEGALENTITY_SUCCESS = 'CREATE_LEGALENTITY_SUCCESS';
export const CREATE_LEGALENTITY_ERROR = 'CREATE_LEGALENTITY_ERROR';

export const DELETE_LEGALENTITY_REQUEST = 'DELETE_LEGALENTITY_REQUEST';
export const DELETE_LEGALENTITY_SUCCESS = 'DELETE_LEGALENTITY_SUCCESS';
export const DELETE_LEGALENTITY_ERROR = 'DELETE_LEGALENTITY_ERROR';

export const UPDATE_LEGALENTITY_REQUEST = 'UPDATE_LEGALENTITY_REQUEST';
export const UPDATE_LEGALENTITY_SUCCESS = 'UPDATE_LEGALENTITY_SUCCESS';
export const UPDATE_LEGALENTITY_ERROR = 'UPDATE_LEGALENTITY_ERROR';

export const DELETE_LEGALENTITY_GYM_REQUEST = 'DELETE_LEGALENTITY_GYM_REQUEST';
export const DELETE_LEGALENTITY_GYM_SUCCESS = 'DELETE_LEGALENTITY_GYM_SUCCESS';
export const DELETE_LEGALENTITY_GYM_ERROR = 'DELETE_LEGALENTITY_GYM_ERROR';

export const UPLOAD_LEGALENTITY_DOCS_REQUEST =
    'UPLOAD_LEGALENTITY_DOCS_REQUEST';
export const UPLOAD_LEGALENTITY_DOCS_SUCCESS =
    'UPLOAD_LEGALENTITY_DOCS_SUCCESS';
export const UPLOAD_LEGALENTITY_DOCS_ERROR = 'UPLOAD_LEGALENTITY_DOCS_ERROR';

export const DELETE_LEGALENTITY_DOCS_REQUEST =
    'DELETE_LEGALENTITY_DOCS_REQUEST';
export const DELETE_LEGALENTITY_DOCS_SUCCESS =
    'DELETE_LEGALENTITY_DOCS_SUCCESS';
export const DELETE_LEGALENTITY_DOCS_ERROR = 'DELETE_LEGALENTITY_DOCS_ERROR';

export interface LegalEntityState {
    ids: string[];
    byId: {
        [id: string]: LegalEntity;
    };
    legalEntitiesList: LegalEntity[];
    lastUpdated: number;
}
