import React from 'react';
import {
    InjectedFormProps,
    Field,
    reduxForm,
    FieldArray,
    change,
} from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import Select, { createFilter, MultiValue } from 'react-select';

import TextInput from '@components/TextInput';
import Button from '@components/Button';
import TimeInput from '@components/TimeInput';
import SingleDatePickerInput from '@components/SingleDatePickerInput';
import SelectInput from '@components/SelectInput';
import CheckBoxInput from '@components/CheckBoxInput';
import { getGym } from '@redux/modules/gyms/selectors';
import { GenderType, WeekDaySchedule } from '@t/gym';
import TextAreaInput from '@components/TextAreaInput';
import { Tag } from '@t/tag';

import styles from './styles.module.css';

export const validate = (
    values: GroupWorkoutFormData
): { prepayment: string } => {
    const errors: { prepayment: string } = { prepayment: '' };
    if (values.prepayment > 0.6 * values.price) {
        errors.prepayment =
            'Предоплата должна составлять не более 60% от общей стоимости';
    }
    return errors;
};

export interface GroupWorkoutFormData {
    gymId: string;
    dateStart: Date;
    dateFinish: Date;
    date: Date;
    title: string;
    limit: number;
    price: number;
    weekDaySchedule: Array<WeekDaySchedule>;
    prepayment: number;
    withPrepayment: boolean;
    genderType: GenderType;
    tags: string[];
}

const OPTIONS = [
    { title: 'Выберите день недели', value: -1 },
    { title: 'Понедельник', value: 1 },
    { title: 'Вторник', value: 2 },
    { title: 'Среда', value: 3 },
    { title: 'Четверг', value: 4 },
    { title: 'Пятница', value: 5 },
    { title: 'Суббота', value: 6 },
    { title: 'Воскресенье', value: 0 },
];

export const GENDER_TYPES = [
    { title: 'Для всех', value: GenderType.ALL },
    { title: 'Для женщин', value: GenderType.FEMALE },
    { title: 'Для мужчин', value: GenderType.MALE },
];

export interface Props {
    options: { title: string; value: string }[];
    gymId: string;
    isRecurring: boolean;
    withPrepayment: boolean;
    payment: number;
    originDate: string;
    isPastGroupWorkout: boolean;
    btnTitle: string;
    selectedDateStart?: Date;
    selectedDateFinish?: Date;
    setSelectedTagType: React.Dispatch<React.SetStateAction<string[]>>;
    tags?: Tag[];
    tagsId?: string[];
}

const GroupWorkoutForm: React.FC<
    Props & InjectedFormProps<GroupWorkoutFormData, Props>
> = ({
    handleSubmit,
    options,
    gymId,
    originDate,
    isRecurring,
    withPrepayment,
    payment,
    isPastGroupWorkout,
    btnTitle,
    selectedDateStart,
    selectedDateFinish,
    setSelectedTagType,
    tags = [],
    tagsId = [],
}) => {
    const dispatch = useDispatch();
    const gym = useSelector(getGym(gymId));
    const availableHours = gym ? gym.availableHours : [];

    const handleChange = (
        value: MultiValue<{ label: string; value: string }>
    ) => {
        setSelectedTagType(
            value.map((type: { label: string; value: string }) => type.value)
        );
    };

    const tagTypeOptions = tags
        .filter((tag: Tag) =>
            tag.tagType.find(
                (type) => type.tagTypeValue === 'GROUPWORKOUTFILTER'
            )
        )
        .map((tag: Tag) => {
            return {
                label: tag.tagName,
                value: tag._id,
            };
        });

    const tagTypeValue = tagTypeOptions.filter(
        (tagTypeOption: { label: string; value: string }) =>
            tagsId.includes(tagTypeOption.value)
    );

    const renderRegularGroupWorkouts = ({ fields }: any) => {
        return (
            <div>
                <ul className={styles.list}>
                    {fields.map(
                        (
                            regularGroupWorkout: Array<{
                                weekDay: number;
                                dateStart: Date;
                                dateFinish: Date;
                            } | null>,
                            index: number
                        ) => (
                            <li key={index} className={styles.listItem}>
                                <div className={styles.row}>
                                    <div className={styles.col}>
                                        <Field
                                            required
                                            name={`${regularGroupWorkout}.weekDay`}
                                            label="День недели"
                                            placeholder="понедельник"
                                            options={OPTIONS}
                                            defaulVal={-1}
                                            component={SelectInput}
                                        />
                                    </div>
                                    <div className={styles.col}>
                                        <button
                                            className={styles.removeBtn}
                                            type="button"
                                            onClick={() => fields.remove(index)}
                                        >
                                            Удалить день недели
                                        </button>
                                    </div>
                                </div>

                                <div className={styles.row}>
                                    <div className={styles.col}>
                                        <Field
                                            required
                                            name={`${regularGroupWorkout}.dateStart`}
                                            label="Начало тренировки"
                                            placeholder="3 сентября 10:00"
                                            originDate={originDate}
                                            defaultValue={
                                                selectedDateStart
                                                    ? selectedDateStart.toString()
                                                    : undefined
                                            }
                                            availableHours={availableHours}
                                            component={TimeInput}
                                        />
                                    </div>

                                    <div className={styles.col}>
                                        <Field
                                            required
                                            name={`${regularGroupWorkout}.dateFinish`}
                                            label="Конец тренировки"
                                            placeholder="3 сентября 12:00"
                                            availableHours={availableHours}
                                            originDate={originDate}
                                            defaultValue={
                                                selectedDateFinish
                                                    ? selectedDateFinish.toString()
                                                    : undefined
                                            }
                                            component={TimeInput}
                                        />
                                    </div>
                                </div>
                            </li>
                        )
                    )}
                </ul>
                <Button
                    className={styles.addBtn}
                    type="button"
                    onClick={() => fields.push({})}
                >
                    Добавить день недели
                </Button>
            </div>
        );
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.row}>
                <div className={styles.col}>
                    <Field
                        required
                        name="title"
                        label="Название тренировки"
                        placeholder="Йога для начинающих"
                        component={TextInput}
                    />

                    <Field
                        required
                        name="gymId"
                        options={options}
                        label="Фитнес площадка, в котором проводится тренировка"
                        placeholder="Название фитнес-площадки"
                        component={SelectInput}
                    />

                    <div className={styles.row}>
                        <div className={styles.col}>
                            <Field
                                required
                                name="limit"
                                label="Лимит на число участников"
                                placeholder="25"
                                component={TextInput}
                            />
                        </div>

                        <div className={styles.col}>
                            <Field
                                required
                                name="price"
                                label="Цена (руб)"
                                placeholder="1500"
                                component={TextInput}
                            />
                        </div>
                    </div>

                    <Field
                        name="withPrepayment"
                        label="Установить предоплату?"
                        component={CheckBoxInput}
                        onChange={() => {
                            dispatch(
                                change('updateGroupWorkout', 'prepayment', null)
                            );
                        }}
                    />

                    {withPrepayment && (
                        <>
                            <Field
                                required
                                name="prepayment"
                                label="Предоплата (не более 60% от общей стоимости)"
                                placeholder="0"
                                component={TextInput}
                            />
                            <Field
                                disabled
                                name="payment"
                                label="Оставшаяся сумма"
                                input={{ value: payment || 0 }}
                                component={TextInput}
                            />
                        </>
                    )}

                    <Field
                        name="genderType"
                        label="Тип тренировки (для всех / для женщин / для мужчин)"
                        options={GENDER_TYPES}
                        component={SelectInput}
                    />

                    <Field
                        name="comment"
                        label="Комментарий"
                        placeholder="Комментарий к бронированию"
                        component={TextAreaInput}
                    />
                </div>

                <div className={styles.col}>
                    <Field
                        name="isRecurring"
                        label="Повторять регулярно?"
                        placeholder="1500"
                        component={CheckBoxInput}
                    />

                    {isRecurring && (
                        <div>
                            <FieldArray
                                name="weekDaySchedule"
                                component={renderRegularGroupWorkouts}
                            />
                        </div>
                    )}

                    {!isRecurring && (
                        <>
                            <Field
                                required
                                name="date"
                                label="Дата тренировки"
                                placeholder="3 сентября"
                                component={SingleDatePickerInput}
                            />

                            <div className={styles.row}>
                                <div className={styles.col}>
                                    <Field
                                        required
                                        name="dateStart"
                                        label="Время начала тренировки"
                                        availableHours={availableHours}
                                        originDate={originDate}
                                        component={TimeInput}
                                    />
                                </div>

                                <div className={styles.col}>
                                    <Field
                                        required
                                        name="dateFinish"
                                        label="Время завершения тренировки"
                                        availableHours={availableHours}
                                        originDate={originDate}
                                        component={TimeInput}
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    <span className={styles.label}>Тип</span>
                    <Select
                        className={styles.select}
                        isMulti
                        styles={{
                            input: (provided) => ({
                                ...provided,
                                minHeight: 40,
                            }),
                        }}
                        onChange={handleChange}
                        options={tagTypeOptions}
                        defaultValue={tagTypeValue}
                        filterOption={createFilter({
                            stringify: (option) => `${option.label}`,
                        })}
                        closeMenuOnSelect={false}
                        placeholder="Тип"
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: 'rgb(225, 129, 65)',
                            },
                        })}
                        noOptionsMessage={() => 'Не найдено'}
                    />
                </div>
            </div>
            {!isPastGroupWorkout && (
                <Button type="submit" className={styles.submitBtn}>
                    {btnTitle}
                </Button>
            )}
        </form>
    );
};

export default reduxForm<GroupWorkoutFormData, Props>({})(GroupWorkoutForm);
