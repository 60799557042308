import React from 'react';
import { IconProps } from './types';

export const Info: React.FC<IconProps> = ({ ...props }) => (
    <svg
        {...props}
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.18597 1.68597C0.873095 2.99884 0.775748 4.30684 0.581053 6.92282C0.530583 7.60095 0.5 8.30048 0.5 9C0.5 9.69952 0.530583 10.399 0.581053 11.0772C0.775748 13.6932 0.873095 15.0012 2.18597 16.314C3.49884 17.6269 4.80684 17.7243 7.42282 17.9189C8.10095 17.9694 8.80048 18 9.5 18C10.1995 18 10.899 17.9694 11.5772 17.9189C14.1932 17.7243 15.5012 17.6269 16.814 16.314C18.1269 15.0012 18.2243 13.6932 18.4189 11.0772C18.4694 10.399 18.5 9.69952 18.5 9C18.5 8.30048 18.4694 7.60095 18.4189 6.92282C18.2243 4.30684 18.1269 2.99884 16.814 1.68597C15.5012 0.373095 14.1932 0.275748 11.5772 0.0810531C10.899 0.0305833 10.1995 0 9.5 0C8.80048 0 8.10095 0.0305834 7.42282 0.0810532C4.80684 0.275748 3.49884 0.373095 2.18597 1.68597ZM10.5001 4.99887C10.5001 5.68859 9.94092 6.24773 9.25119 6.24773C8.56146 6.24773 8.00233 5.68859 8.00233 4.99887C8.00233 4.30914 8.56146 3.75 9.25119 3.75C9.94092 3.75 10.5001 4.30914 10.5001 4.99887ZM10.2071 8.29289C10.3946 8.48043 10.5 8.73479 10.5 9L10.5 13C10.5 13.5523 10.0523 14 9.49998 14C8.9477 14 8.49999 13.5523 8.49999 13L8.5 10H8C7.44772 10 7 9.55228 7 9C7 8.44772 7.44772 8 8 8H9.5C9.76522 8 10.0196 8.10536 10.2071 8.29289Z"
            fill="#007CFF"
        />
    </svg>
);

export default Info;
