import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.css';

export interface CheckersDivProps {
    children: any;
    className?: string;
}

export const CheckersDiv: React.FC<CheckersDivProps> = ({
    children,
    className,
}) => <div className={classNames(styles.container, className)}>{children}</div>;

export default CheckersDiv;
