import React from 'react';
import { useDispatch } from 'react-redux';
import { Field, FormSubmitHandler, SubmissionError } from 'redux-form';
import { Link, useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import Form from '@components/Form';
import TextInput from '@components/TextInput';
import Button from '@components/Button';
import { AsyncDispatch } from '@redux/types';
import { resetPassword } from '@redux/modules/auth/actions';

import styles from './styles.module.css';

export interface FormData {
    email: string;
}

export const ResetPassword: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();

    const onSubmit: FormSubmitHandler<FormData> = async ({ email }) => {
        try {
            await dispatch(resetPassword({ email }));
            cogoToast.success('Ссылка отправлена', {
                position: 'top-right',
                hideAfter: 5,
            });
            history.push('/auth/login');
        } catch (err) {
            throw new SubmissionError({
                _error: 'Пользователь с данным email не найден',
            });
        }
    };

    const validate = (values: FormData) => {
        const errors: { email: string } = { email: '' };
        if (!values.email) {
            errors.email = 'Обязательное поле';
        }
        return errors;
    };

    return (
        <div className={styles.container}>
            <div className={styles.box}>
                <h2 className={styles.title}>Сбросить пароль</h2>

                <p className={styles.subTitle}>
                    Введите адрес электронной почты вашей учетной записи
                    пользователя, и мы вышлем вам ссылку для сброса пароля.
                </p>

                <Form
                    form="resetPassword"
                    shakeOnError
                    validate={validate}
                    onSubmit={onSubmit}
                    className={styles.form}
                >
                    <Field
                        autoFocus
                        type="email"
                        name="email"
                        label="Введите ваш адрес электронной почты"
                        placeholder="Email"
                        component={TextInput}
                    />

                    <Button type="submit" className={styles.btn}>
                        Сбросить пароль
                    </Button>

                    <Link to="/" className={styles.link}>
                        У меня уже есть аккаунт
                    </Link>
                </Form>
            </div>
        </div>
    );
};

export default ResetPassword;
