import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.css';

export interface Props {
    children: React.ReactNode[] | React.ReactNode;
    className?: string;
}

export const Box: React.FC<Props> = ({ children, className }) => (
    <div className={classNames(styles.container, className)}>{children}</div>
);

export default Box;
