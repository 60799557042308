import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Field, FormSubmitHandler, SubmissionError } from 'redux-form';

import { getUser } from '@redux/modules/auth/selectors';
import {
    updateUser,
    fetchUser,
    registrationPartner,
} from '@redux/modules/auth/actions';
import TextInput from '@components/TextInput';
import PhoneInput from '@components/PhoneInput';

import Title from '../../Title';
import Subtitle from '../../Subtitle';
import Form from '../../Form';

export const Name: React.FC = () => {
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const history = useHistory();
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState<boolean>(false);
    const yclientsId = localStorage.getItem('salonId') || '';
    const initialValues = React.useMemo(
        () => (user ? { firstName: user.firstName, phone: user.phone } : {}),
        [user]
    );

    if (!user) {
        return null;
    }

    useEffect(() => {
        if (!user) {
            return;
        }
        setPhone(user.phone);
    }, [user]);

    useEffect(() => {
        if (!yclientsId) {
            return;
        }
        dispatch(registrationPartner(yclientsId));
    }, [yclientsId]);

    const onSubmit: FormSubmitHandler<any> = async ({ firstName }) => {
        try {
            await dispatch(
                updateUser({
                    firstName,
                    phone,
                })
            );
            await dispatch(fetchUser());
            history.push('/registration-steps/gym-info');
        } catch (err) {
            throw new SubmissionError({
                _error: 'Ошибка при регистрации',
            });
        }
    };

    return (
        <div>
            <Title message="Добро пожаловать!" />
            <Subtitle message="Как мы можем к вам обращаться?" />
            <Form form="name" onSubmit={onSubmit} initialValues={initialValues}>
                <Field
                    required
                    name="firstName"
                    label="Имя"
                    component={TextInput}
                />
                <PhoneInput
                    type="tel"
                    name="phone"
                    label="Телефон"
                    placeholder="Телефон"
                    phone={phone}
                    setPhone={setPhone}
                    phoneError={phoneError}
                    setPhoneError={setPhoneError}
                />
            </Form>
        </div>
    );
};

export default Name;
