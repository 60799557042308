import React, { useState, useEffect, memo, useRef } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import Select, { createFilter } from 'react-select';

import { AsyncDispatch } from '@redux/types';
import { selectGym } from '@redux/modules/gyms/actions';
import { getSelectedGymId } from '@redux/modules/gyms/selectors';
import { ROUTES_WITH_GYM_GROUPWORKOUT_FILTER_SUPPORT } from '@config';

import styles from './styles.module.css';
import { getSelectedManagerId } from '@redux/modules/managers/selectors';
import { selectManager } from '@redux/modules/managers/actions';

export interface ManagerFilterFormData {
    manager: string;
}

type SelectValue = {
    label: string;
    value: string;
};

export interface Props {
    managers: { label: string; value: string }[];
}

const ManagerFilterForm: React.FC<
    Props & InjectedFormProps<ManagerFilterFormData, Props>
> = memo(({ managers }) => {
    const dispatch = useDispatch<AsyncDispatch>();
    const selectedManagerId = useSelector(getSelectedManagerId);
    const willMount = useRef(true);

    const groupWorkoutLocation = ROUTES_WITH_GYM_GROUPWORKOUT_FILTER_SUPPORT.includes(
        location.pathname
    );

    const managersOptions = groupWorkoutLocation
        ? managers
        : [{ label: 'Все менеджеры', value: '' }].concat(managers);

    const [selectedOption, setSelectedOption] = useState<SelectValue[] | null>(
        selectedManagerId
            ? managersOptions.filter(
                  (managerOption) => managerOption.value === selectedManagerId
              )
            : [{ label: 'Все менеджеры', value: '' }]
    );

    useEffect(() => {
        if (willMount.current && selectedManagerId) {
            dispatch(selectManager(selectedManagerId));
        } else {
            dispatch(selectManager(selectedOption && selectedOption[0].value));
        }
        willMount.current = false;
    }, [selectedOption]);

    return (
        <Select
            className={styles.select}
            styles={{
                input: (provided) => ({
                    ...provided,
                    minHeight: 40,
                }),
            }}
            defaultValue={selectedOption}
            onChange={(e: SelectValue | null) => e && setSelectedOption([e])}
            options={managersOptions}
            filterOption={createFilter({
                stringify: (option) => `${option.label}`,
            })}
            placeholder="Все менеджеры"
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: 'rgb(225, 129, 65)',
                },
            })}
            noOptionsMessage={() => 'Не найдено'}
        />
    );
});

export default reduxForm<ManagerFilterFormData, Props>({
    form: 'managerFilter',
})(ManagerFilterForm);
