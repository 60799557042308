import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip as RechartsTooltip,
    CartesianGrid,
} from 'recharts';

import Tooltip from './Tooltip';
import XAxisTick from './XAxisTick';
import styles from './styles.module.css';

export interface Props {
    dailyIncome: { date: Date; revenue: number }[];
}

export const RevenueChart: React.FC<Props> = ({ dailyIncome }) => {
    const data = dailyIncome;
    const daysCount = dailyIncome.length;
    return (
        <div className={styles.container}>
            <h4 className={styles.subtitle}>
                Выручка по дням за {daysCount} дней:
            </h4>
            <LineChart
                width={780}
                height={240}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" tick={XAxisTick} />
                <YAxis />
                <RechartsTooltip content={<Tooltip />} />
                <Line
                    type="monotone"
                    dataKey="revenue"
                    stroke="#F07C2D"
                    activeDot={{ r: 8 }}
                    strokeWidth={2}
                />
            </LineChart>
        </div>
    );
};

export default RevenueChart;
