import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import NavigationBar from '@components/NavigationBar';
import { AsyncDispatch } from '@redux/types';
import GroupWorkouts from '@containers/Dashboard/GroupWorkouts';
import GroupWorkoutReservations from '@containers/Dashboard/GroupWorkouts/GroupWorkoutsReservations';
import TimeSlotReservations from '@containers/Dashboard/GroupWorkouts/TimeSlotReservations';
import TimeSlotDetails from '@containers/Dashboard/GroupWorkouts/TimeSlotDetails';
import GroupWorkoutsCalendar from '@containers/Dashboard/GroupWorkouts/GroupWorkoutsCalendar';
import GroupWorkoutCancel from '@containers/Dashboard/GroupWorkouts/GroupWorkoutCancel';
import GroupWorkoutDuplicate from '@containers/Dashboard/GroupWorkoutDuplicate';
import BookingDetails from '@containers/Dashboard/BookingDetails';
import BookingCancel from '@containers/Dashboard/BookingCancel';
import ReservationDetails from '@containers/Dashboard/ReservationDetails';
import AuthFooter from '@components/AuthFooter';
import GymFilterForm from '@containers/Dashboard/GymFilterForm';
import { getGymsList } from '@redux/modules/gyms/selectors';
import { fetchGymsList } from '@redux/modules/gyms/actions';
import {
    ROUTES_WITH_GYM_FILTER_SUPPORT,
    ROUTES_WITH_GYM_GROUPWORKOUT_FILTER_SUPPORT,
} from '@config';
import { getUser } from '@redux/modules/auth/selectors';
import { fetchUser, registrationPartner } from '@redux/modules/auth/actions';
import { UserRole } from '@t/user';

import BookingList from './BookingList';
import ReservationsList from './ReservationsList';
import GymDetails from './GymDetails';
import Profile from './Profile';
import GymList from './GymList';
import ManagerList from './ManagerList';
import ManagerDetails from './ManagerDetails';
import AddManager from './AddManager';
import Home from './Home';
import AddGym from './AddGym';
import styles from './styles.module.css';
import MembershipList from './MembershipList';
import MembershipDetails from './MembershipDetails';
import AddPartnerManager from './AddPartner/AddManager';
import AddPartnerGym from './AddPartner/AddGym';
import AddPartnerLegalEntity from './AddPartner/AddLegalEntity';
import LegalEntityList from './LegalEntityList';
import LegalEntityDetails from './LegalEntityDetails';
import AddLegalEntity from './AddLegalEntity';
import AppUserList from './AppUserList';
import AppUserDetails from './AppUserDetails';
import ReservationCancel from './ReservationCancel';
import CouponsList from './CouponsList';
import CouponsDetails from './CouponsDetails';
import AddCoupon from './AddCoupon';
import AddMembership from './AddMembership';
import GymMembershipDetails from './GymMembershipDetails';
import GymMembershipList from './GymMembershipList';
import HiddenGym from './HiddenGymDetails';
import CorporateBookingList from './CorporateBookingList';
import CorporateBookingDetails from './CorporateBookingDetails';
import HRStatistics from './HRStatistics';
import HRReportsList from './HRReportList';
import HRReportDetails from './HRReportDetails';
import AddSpecialPrice from './GymList/AddSpecialPrice';
import Reporting from './Reporting';
import Contacts from './Contacts';
import TagList from './TagList';
import TagDetails from './TagDetails';
import AddTag from './AddTag';
import AppUserStartBooking from '@containers/Dashboard/AppUserStartBooking';
import GymDetailsNew from './GymDetailsNew';
import SubscriptionsList from './SubscriptionsList';
import SubscriptionsDetails from './SubscriptionsDetails';
import GoGymSubscriptionsList from './GoGymSubscriptionsList';
import ReportListBookings from './ReportListBookings';
import ReportListMemberships from './ReportListMemberships';
import AddSubscription from './AddSubscription';
import GroupWorkoutNewAdd from '@containers/Dashboard/GroupWorkouts/GroupWorkoutNewAdd';
import GroupWorkoutNewDetails from '@containers/Dashboard/GroupWorkoutNewDetails';
import GroupWorkoutNewEdit from '@containers/Dashboard/GroupWorkoutNewEdit';
import GroupWorkoutNewDuplicate from './GroupWorkouts/GroupWorkoutNewDuplicate';
import GroupWorkoutCalendar from './GroupWorkouts/GroupWorkoutCalendar';
import AddGoGymSubscription from '@containers/Dashboard/AddGoGymSubscription';
import GoGymSubscriptionDetails from './GoGymSubscriptionDetails';
import AddCoupons from './AddCoupons';

export const Dashboard: React.FC = () => {
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;
    const isAnalitics = user?.role === UserRole.Analytics;
    const isHR = user?.role === UserRole.HR;
    const dispatch = useDispatch<AsyncDispatch>();
    const gyms = useSelector(getGymsList);

    const yclientsId = localStorage.getItem('salonId') || '';

    const gymsOptions: {
        label: string;
        value: string;
    }[] = gyms
        .filter((gym) => !gym.isHidden)
        .map((gym) => ({ label: gym.title, value: gym._id }));

    const gymsHasGroupWorkoutsOptions: {
        label: string;
        value: string;
    }[] = gyms
        .filter((gym) => !gym.isHidden && gym.hasGroupWorkouts)
        .map((gym) => ({ label: gym.title, value: gym._id }));

    useEffect(() => {
        if (!yclientsId) {
            return;
        }
        if (yclientsId && user) {
            dispatch(registrationPartner(yclientsId)).then((message) => {
                if (message) {
                    localStorage.removeItem('salonId');
                }
            });
        }
    }, [dispatch, yclientsId]);

    useEffect(() => {
        dispatch(fetchGymsList());
        dispatch(fetchUser());
    }, [dispatch]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const showFilter = ROUTES_WITH_GYM_FILTER_SUPPORT.includes(
        location.pathname
    );

    const groupWorkoutLocation = ROUTES_WITH_GYM_GROUPWORKOUT_FILTER_SUPPORT.includes(
        location.pathname
    );

    return (
        <div className={styles.container}>
            <NavigationBar />
            <div className={styles.content}>
                <div className={styles.header}>
                    {showFilter && (
                        <GymFilterForm
                            gyms={
                                groupWorkoutLocation
                                    ? gymsHasGroupWorkoutsOptions
                                    : gymsOptions
                            }
                        />
                    )}
                </div>

                <Switch>
                    <Route
                        exact
                        path="/dashboard"
                        render={() =>
                            user?.role === UserRole.HR ? (
                                <Redirect to="/dashboard/hr-statistics" />
                            ) : (
                                <Home />
                            )
                        }
                    />
                    <Route exact path="/dashboard/add-gym" component={AddGym} />
                    <Route exact path="/dashboard/gyms" component={GymList} />
                    <Route
                        exact
                        path="/dashboard/gyms/:id"
                        component={GymDetailsNew}
                    />
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/old-gyms/:id"
                            component={GymDetails}
                        />
                    )}
                    <Route
                        exact
                        path="/dashboard/hidden-gyms/:id"
                        component={HiddenGym}
                    />
                    <Route
                        exact
                        path="/dashboard/bookings/:id"
                        component={BookingDetails}
                    />
                    <Route
                        exact
                        path="/dashboard/reservations/:id"
                        component={ReservationDetails}
                    />
                    <Route
                        exact
                        path="/dashboard/bookings"
                        component={BookingList}
                    />
                    <Route
                        exact
                        path="/dashboard/reservations"
                        component={ReservationsList}
                    />
                    <Route
                        exact
                        path="/dashboard/reservation-cancel/:id"
                        component={ReservationCancel}
                    />
                    <Route
                        exact
                        path="/dashboard/group-workouts"
                        component={GroupWorkouts}
                    />
                    <Route
                        exact
                        path="/dashboard/group-workouts/calendar"
                        component={GroupWorkoutsCalendar}
                    />
                    <Route
                        exact
                        path="/dashboard/group-workout-cancel/:id"
                        component={GroupWorkoutCancel}
                    />
                    <Route
                        exact
                        path="/dashboard/booking-cancel/:id"
                        component={BookingCancel}
                    />
                    <Route
                        exact
                        path="/dashboard/group-workouts/add"
                        component={GroupWorkoutNewAdd}
                    />
                    <Route
                        exact
                        path="/dashboard/group-workouts/:id"
                        component={GroupWorkoutNewDetails}
                    />
                    <Route
                        exact
                        path="/dashboard/group-workouts/:id/edit"
                        component={GroupWorkoutNewEdit}
                    />
                    <Route
                        exact
                        path="/dashboard/group-workouts/:id/duplicate"
                        component={GroupWorkoutNewDuplicate}
                    />
                    <Route
                        exact
                        path="/dashboard/group-workouts/:id/reservations"
                        component={GroupWorkoutReservations}
                    />
                    <Route
                        exact
                        path="/dashboard/group-workouts/:id/calendar"
                        component={GroupWorkoutCalendar}
                    />
                    <Route
                        exact
                        path="/dashboard/group-workouts/:id/timeslots/:timeSlotId"
                        component={TimeSlotDetails}
                    />
                    <Route
                        exact
                        path="/dashboard/group-workouts/:id/timeslots/:timeSlotId/reservations"
                        component={TimeSlotReservations}
                    />
                    <Route
                        exact
                        path="/dashboard/group-workout-duplicate/:id"
                        component={GroupWorkoutDuplicate}
                    />
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/managers/:id"
                            component={ManagerDetails}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/managers"
                            component={ManagerList}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/add-manager"
                            component={AddManager}
                        />
                    )}
                    <Route
                        exact
                        path="/dashboard/profile"
                        component={Profile}
                    />
                    <Route
                        exact
                        path="/dashboard/memberships/:id"
                        component={MembershipDetails}
                    />
                    <Route
                        exact
                        path="/dashboard/memberships"
                        component={MembershipList}
                    />
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/legalentity/:id"
                            component={LegalEntityDetails}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/legalentity"
                            component={LegalEntityList}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/add-legalentity"
                            component={AddLegalEntity}
                        />
                    )}
                    <Route
                        exact
                        path="/dashboard/reports-bookings"
                        component={ReportListBookings}
                    />
                    <Route
                        exact
                        path="/dashboard/reports-memberships"
                        component={ReportListMemberships}
                    />
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/app-users/:id"
                            component={AppUserDetails}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/app-user-start-booking/:id"
                            component={AppUserStartBooking}
                        />
                    )}
                    {(isSuperAdmin || isAnalitics) && (
                        <Route
                            exact
                            path="/dashboard/app-users"
                            component={AppUserList}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/add-partner-manager"
                            component={AddPartnerManager}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/add-partner-legalentity"
                            component={AddPartnerLegalEntity}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/add-partner-gym"
                            component={AddPartnerGym}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/coupons/:id"
                            component={CouponsDetails}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/coupons"
                            component={CouponsList}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/add-coupon"
                            component={AddCoupon}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/add-coupons"
                            component={AddCoupons}
                        />
                    )}
                    <Route
                        exact
                        path="/dashboard/add-membership"
                        component={AddMembership}
                    />
                    <Route
                        exact
                        path="/dashboard/gym-memberships/:id"
                        component={GymMembershipDetails}
                    />
                    <Route
                        exact
                        path="/dashboard/gym-memberships"
                        component={GymMembershipList}
                    />
                    {isHR && (
                        <Route
                            exact
                            path="/dashboard/corporate-bookings"
                            component={CorporateBookingList}
                        />
                    )}
                    {isHR && (
                        <Route
                            exact
                            path="/dashboard/corporate-bookings/:id"
                            component={CorporateBookingDetails}
                        />
                    )}
                    {isHR && (
                        <Route
                            exact
                            path="/dashboard/hr-statistics"
                            component={HRStatistics}
                        />
                    )}
                    {isHR && (
                        <Route
                            exact
                            path="/dashboard/hr-reports"
                            component={HRReportsList}
                        />
                    )}
                    {isHR && (
                        <Route
                            exact
                            path="/dashboard/hr-reports/:id"
                            component={HRReportDetails}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/add-special-price"
                            component={AddSpecialPrice}
                        />
                    )}
                    {(isSuperAdmin || isAnalitics) && (
                        <Route
                            exact
                            path="/dashboard/reporting"
                            component={Reporting}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/contacts"
                            component={Contacts}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/tags"
                            component={TagList}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/tags/:id"
                            component={TagDetails}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/add-tag"
                            component={AddTag}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/subscriptions/:id"
                            component={SubscriptionsDetails}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/subscriptions"
                            component={SubscriptionsList}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/gogym-subscriptions/:id"
                            component={GoGymSubscriptionDetails}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/gogym-subscriptions"
                            component={GoGymSubscriptionsList}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/add-subscriptions"
                            component={AddSubscription}
                        />
                    )}
                    {isSuperAdmin && (
                        <Route
                            exact
                            path="/dashboard/add-gogym-subscriptions"
                            component={AddGoGymSubscription}
                        />
                    )}
                    <Route
                        path="/"
                        render={() => <Redirect to="/dashboard" />}
                    />
                </Switch>
            </div>
            <AuthFooter />
        </div>
    );
};
