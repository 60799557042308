import React from 'react';
import { IconProps } from './types';

export const GoGym: React.FC<IconProps> = ({ ...props }) => (
    <svg
        width="101"
        height="144"
        viewBox="0 0 101 144"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M76.0809 0.548553L86.2788 7.03284V18.6119L21.8466 56.5913V98.7391L59.3934 120.971L79.3257 108.466V54.2755L48.732 71.8757V82.5284L69.5913 95.497L70.0549 106.15L59.3934 112.634L28.3362 94.1075L27.8726 60.7597L89.5236 25.0962L100.185 31.1173V120.971L58.9299 143.666L0.987259 111.245V44.0859L76.0809 0.548553Z"
            fill="white"
        />
    </svg>
);

export default GoGym;
