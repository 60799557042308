import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import styles from './styles.module.css';

export interface Props {
    hidden?: boolean;
    label: string;
    name: string;
    options: { title: string; value: string }[];
    defaultVal?: number | string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    emptyTitle?: string;
    toggleVisibility?: boolean;
    multiple?: boolean;
}

const MultiSelectInput: React.FC<Props & WrappedFieldProps> = ({
    input,
    name,
    options,
    label,
    defaultVal,
    placeholder,
    required,
    disabled,
    emptyTitle,
    hidden,
}) => {
    const optionsList =
        options.length > 0 &&
        options.map((item, i) => {
            return (
                <option
                    key={i}
                    value={item.value}
                    onClick={() => addToMultiSelect(item.value)}
                >
                    {item.title}
                </option>
            );
        });

    const addToMultiSelect = (value: string) => {
        if (!input.value.includes(value)) {
            input.onChange([...input.value, value]);
        } else {
            input.onChange(
                [...input.value].filter((inputValue) => inputValue !== value)
            );
        }
    };

    return (
        <div className={styles.wrapper} hidden={hidden}>
            <label htmlFor={name} className={styles.label}>
                {label}
            </label>
            <select
                className={styles.select}
                value={input.value ? input.value : defaultVal}
                name={name}
                defaultValue={defaultVal}
                placeholder={placeholder}
                required={required}
                disabled={disabled}
                multiple={true}
            >
                {emptyTitle && <option value="">{emptyTitle}</option>}
                {optionsList}
            </select>
        </div>
    );
};

export default MultiSelectInput;
