import axios from 'axios';
import { Action } from 'redux';

import { API_URL } from '@config';
import { User } from '@t/user';
import { AsyncActionCreator } from '@redux/types';

import {
    SIGN_OUT,
    SIGN_IN_REQUEST,
    SIGN_IN_SUCCESS,
    SIGN_IN_ERROR,
    SIGN_UP_ERROR,
    SIGN_UP_REQUEST,
    SIGN_UP_SUCCESS,
    FETCH_USER_REQUEST,
    FETCH_USER_SUCCESS,
    FETCH_USER_ERROR,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    REGISTRATION_COMPLETE_REQUEST,
    REGISTRATION_COMPLETE_SUCCESS,
    REGISTRATION_COMPLETE_ERROR,
    SIGN_IN_2FA_REQUEST,
    SIGN_IN_2FA_SUCCESS,
    SIGN_IN_2FA_ERROR,
    SIGN_IN_2FA_URL_SUCCESS,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_ERROR,
    SAVE_PASSWORD_REQUEST,
    SAVE_PASSWORD_ERROR,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_ERROR,
    REGISTRATION_PARTNER_REQUEST,
    REGISTRATION_PARTNER_SUCCESS,
    REGISTRATION_PARTNER_ERROR,
} from './types';

export const signOut = (): Action => ({
    type: SIGN_OUT,
});

export const signIn: AsyncActionCreator<
    { email: string; password: string },
    string
> = ({ email, password }) => async (dispatch) => {
    dispatch({
        type: SIGN_IN_REQUEST,
    });

    try {
        const {
            data: { token, token2fa, dataUrl, qrVerified },
        } = await axios.post(`${API_URL}/dashboard/login`, {
            email,
            password,
        });

        if (token) {
            dispatch({
                type: SIGN_IN_SUCCESS,
                payload: {
                    token,
                },
            });

            return token;
        } else {
            dispatch({
                type: SIGN_IN_2FA_URL_SUCCESS,
                payload: {
                    dataUrl,
                    token2fa,
                    qrVerified,
                },
            });

            return '2fa';
        }
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: SIGN_IN_ERROR,
            });

            throw err;
        }
    }
};

export const signUp: AsyncActionCreator<
    { email: string; password: string },
    string
> = ({ email, password }) => async (dispatch) => {
    dispatch({
        type: SIGN_UP_REQUEST,
    });

    try {
        const {
            data: { token },
        } = await axios.post(`${API_URL}/dashboard/register`, {
            email,
            password,
        });

        dispatch({
            type: SIGN_UP_SUCCESS,
            payload: {
                token,
            },
        });

        return token;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: SIGN_UP_ERROR,
            });

            throw err;
        }
    }
};

export const updateUser: AsyncActionCreator<Partial<User>, User> = (
    userData
) => async (dispatch, getState) => {
    const {
        auth: { token },
    } = getState();

    dispatch({
        type: UPDATE_USER_REQUEST,
    });

    try {
        const { data: user } = await axios.post(
            `${API_URL}/dashboard/update-user`,
            userData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: UPDATE_USER_SUCCESS,
            payload: {
                user,
            },
        });

        return user;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPDATE_USER_ERROR,
            });

            throw err;
        }
    }
};

export const registrationComplete: AsyncActionCreator<
    void,
    User
> = () => async (dispatch, getState) => {
    const {
        auth: { token },
    } = getState();

    dispatch({
        type: REGISTRATION_COMPLETE_REQUEST,
    });

    try {
        const { data: user } = await axios.post(
            `${API_URL}/dashboard/registration-complete`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: REGISTRATION_COMPLETE_SUCCESS,
            payload: {
                user,
            },
        });

        return user;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: REGISTRATION_COMPLETE_ERROR,
            });

            throw err;
        }
    }
};

export const registrationPartner: AsyncActionCreator<string, User> = (
    yclientsId: string
) => async (dispatch, getState) => {
    const {
        auth: { token },
    } = getState();

    dispatch({
        type: REGISTRATION_PARTNER_REQUEST,
    });

    try {
        const { data: message } = await axios.post(
            `${API_URL}/dashboard/registration-partner`,
            { yclientsId },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: REGISTRATION_PARTNER_SUCCESS,
            payload: {
                message,
            },
        });

        return message;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: REGISTRATION_PARTNER_ERROR,
            });

            throw err;
        }
    }
};

export const fetchUser: AsyncActionCreator<void, User> = () => async (
    dispatch,
    getState
) => {
    dispatch({
        type: FETCH_USER_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: user } = await axios.get(`${API_URL}/dashboard/me`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: FETCH_USER_SUCCESS,
            payload: {
                user,
            },
        });

        return user;
    } catch (err) {
        dispatch(signOut());
        if (err instanceof Error) {
            dispatch({
                type: FETCH_USER_ERROR,
            });

            throw err;
        }
    }
};

export const fetchAdmin: AsyncActionCreator<string, User> = (
    code: string
) => async (dispatch, getState) => {
    dispatch({
        type: SIGN_IN_2FA_REQUEST,
    });

    const {
        auth: { token2fa },
    } = getState();

    try {
        const { data } = await axios.post(
            `${API_URL}/dashboard/verify`,
            { code },
            {
                headers: {
                    Authorization: `Bearer ${token2fa}`,
                },
            }
        );

        if (data.user) {
            dispatch({
                type: SIGN_IN_2FA_SUCCESS,
                payload: { token: token2fa },
            });
        } else {
            dispatch({
                type: SIGN_IN_2FA_URL_SUCCESS,
                payload: {
                    dataUrl: data.dataUrl,
                    token2fa: data.token2fa,
                    qrVerified: data.qrVerified,
                },
            });
        }

        return data.user;
    } catch (err) {
        dispatch(signOut());
        if (err instanceof Error) {
            dispatch({
                type: SIGN_IN_2FA_ERROR,
            });

            throw err;
        }
    }
};

export const resetPassword: AsyncActionCreator<{ email: string }> = ({
    email,
}) => async (dispatch) => {
    dispatch({
        type: RESET_PASSWORD_REQUEST,
    });

    try {
        const { data } = await axios.post(
            `${API_URL}/dashboard/reset-password`,
            { email }
        );

        return data.email;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: RESET_PASSWORD_ERROR,
            });

            throw err;
        }
    }
};

export const savePassword: AsyncActionCreator<{
    password: string;
    token: string;
}> = ({ password, token }) => async (dispatch) => {
    dispatch({
        type: SAVE_PASSWORD_REQUEST,
    });

    try {
        const { data } = await axios.post(
            `${API_URL}/dashboard/reset-password/${token}`,
            {
                password,
            }
        );

        return data.token;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: SAVE_PASSWORD_ERROR,
            });

            throw err;
        }
    }
};

export const changePassword: AsyncActionCreator<{
    oldPassword: string;
    password: string;
}> = ({ oldPassword, password }) => async (dispatch, getState) => {
    dispatch({
        type: CHANGE_PASSWORD_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data } = await axios.post(
            `${API_URL}/dashboard/change-password`,
            {
                oldPassword,
                password,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return data.user;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: CHANGE_PASSWORD_ERROR,
            });

            throw err;
        }
    }
};

export const addManager: AsyncActionCreator<
    { email: string; password: string; firstName: string; phone: string },
    User
> = ({ email, password, firstName, phone }) => async (dispatch) => {
    dispatch({
        type: SIGN_UP_REQUEST,
    });

    try {
        const {
            data: { token },
        } = await axios.post(`${API_URL}/dashboard/signup-new-manager`, {
            email,
            password,
            firstName,
            phone,
        });

        return token;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: SIGN_UP_ERROR,
            });

            throw err;
        }
    }
};

export const addManagers: AsyncActionCreator<{ values: any }, unknown> = (
    values
) => async (dispatch) => {
    dispatch({
        type: SIGN_UP_REQUEST,
    });

    try {
        const {
            data: { managers },
        } = await axios.post(`${API_URL}/dashboard/signup-new-managers`, {
            managers: values,
        });

        return managers;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: SIGN_UP_ERROR,
            });

            throw err;
        }
    }
};
