import { AnyAction } from 'redux';

import { AppUser } from '@t/app-user';
import { Booking } from '@t/booking';

import {
    FETCH_BOOKINGS_SUCCESS,
    BookingState,
    FETCH_BOOKING_SUCCESS,
    FETCH_BOOKINGS_USERS_SUCCESS,
    FETCH_BOOKING_USER_SUCCESS,
    UPDATE_BOOKING_SUCCESS,
    FETCH_BOOKING_COUPON_SUCCESS,
    FETCH_SCROLL_BOOKINGS_SUCCESS,
    FETCH_FILTER_BOOKINGS_SUCCESS,
    FETCH_UNPAID_BOOKINGS_SUCCESS,
    FETCH_TEST_BOOKINGS_SUCCESS,
    CLEAR_TEST_BOOKINGS_SUCCESS,
    CLEAR_UNPAID_BOOKINGS_SUCCESS,
} from './types';

const initialState: BookingState = {
    ids: [],
    byId: {},
    unpaidBookingsIds: [],
    unpaidBookingsById: {},
    testBookingsIds: [],
    testBookingById: {},
    usersByBookingId: {},
    couponByBookingId: {},
    bookingsByUserPaymentDatas: {},
    lastUpdated: 0,
};

export function bookingReducer(
    state = initialState,
    action: AnyAction
): BookingState {
    if (action.type === FETCH_BOOKING_SUCCESS) {
        const booking = action.payload;
        const included = state.ids.includes(booking._id);
        const ids = included ? state.ids : [...state.ids, booking._id];
        const byId = {
            ...state.byId,
            [booking._id]: booking,
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === FETCH_BOOKINGS_SUCCESS) {
        const bookings = action.payload;
        const ids = bookings.map((booking: Booking) => booking._id);
        const byId = bookings.reduce(
            (total: { [id: string]: Booking }, booking: Booking) => ({
                ...total,
                [booking._id]: booking,
            }),
            {}
        );
        const lastUpdated = Date.now();

        return {
            ...state,
            ids,
            byId,
            lastUpdated,
        };
    }

    if (action.type === FETCH_SCROLL_BOOKINGS_SUCCESS) {
        const bookings = action.payload;
        const ids = bookings.map((booking: Booking) => booking._id);
        const byId = bookings.reduce(
            (total: { [id: string]: Booking }, booking: Booking) => ({
                ...total,
                [booking._id]: booking,
            }),
            {}
        );
        const lastUpdated = Date.now();

        return {
            ...state,
            ids: [...state.ids, ...ids],
            byId: { ...state.byId, ...byId },
            lastUpdated,
        };
    }

    if (action.type === FETCH_FILTER_BOOKINGS_SUCCESS) {
        const bookings = action.payload;
        const ids = bookings.map((booking: Booking) => booking._id);
        const byId = bookings.reduce(
            (total: { [id: string]: Booking }, booking: Booking) => ({
                ...total,
                [booking._id]: booking,
            }),
            {}
        );
        const lastUpdated = Date.now();

        return {
            ...state,
            ids,
            byId,
            lastUpdated,
        };
    }

    if (action.type === FETCH_UNPAID_BOOKINGS_SUCCESS) {
        const bookings = action.payload;
        const unpaidBookingsIds = bookings.map(
            (booking: Booking) => booking._id
        );
        const unpaidBookingsById = bookings.reduce(
            (total: { [id: string]: Booking }, booking: Booking) => ({
                ...total,
                [booking._id]: booking,
            }),
            {}
        );
        const lastUpdated = Date.now();

        return {
            ...state,
            unpaidBookingsIds,
            unpaidBookingsById,
            lastUpdated,
        };
    }

    if (action.type === FETCH_TEST_BOOKINGS_SUCCESS) {
        const bookings = action.payload;
        const testBookingsIds = bookings.map((booking: Booking) => booking._id);
        const testBookingById = bookings.reduce(
            (total: { [id: string]: Booking }, booking: Booking) => ({
                ...total,
                [booking._id]: booking,
            }),
            {}
        );
        const lastUpdated = Date.now();

        return {
            ...state,
            testBookingsIds,
            testBookingById,
            lastUpdated,
        };
    }

    if (action.type === CLEAR_UNPAID_BOOKINGS_SUCCESS) {
        return {
            ...state,
            unpaidBookingsIds: [],
            unpaidBookingsById: {},
        };
    }

    if (action.type === CLEAR_TEST_BOOKINGS_SUCCESS) {
        return {
            ...state,
            testBookingsIds: [],
            testBookingById: {},
        };
    }

    if (action.type === FETCH_BOOKINGS_USERS_SUCCESS) {
        const { bookings, users } = action.payload;
        const usersByBookingId = bookings.reduce(
            (total: { [id: string]: Booking }, booking: Booking) => ({
                ...total,
                [booking._id]: users.find(
                    (user: AppUser) => user._id === booking.mongoUserId
                ),
            }),
            {}
        );

        return {
            ...state,
            usersByBookingId,
        };
    }

    if (action.type === FETCH_BOOKING_USER_SUCCESS) {
        const { booking, user, userPaymentDatas } = action.payload;
        const included = state.ids.includes(booking._id);
        const ids = included ? state.ids : [...state.ids, booking._id];
        const byId = {
            ...state.byId,
            [booking._id]: booking,
        };

        const usersByBookingId = {
            ...state.usersByBookingId,
            [booking._id]: user,
        };

        const bookingsByUserPaymentDatas = {
            ...state.bookingsByUserPaymentDatas,
            [booking._id]: userPaymentDatas,
        };

        return {
            ...state,
            ids,
            byId,
            usersByBookingId,
            bookingsByUserPaymentDatas,
        };
    }

    if (action.type === UPDATE_BOOKING_SUCCESS) {
        const booking = action.payload;
        const included = state.ids.includes(booking._id);
        const ids = included ? state.ids : [...state.ids, booking._id];
        const byId = {
            ...state.byId,
            [booking._id]: booking,
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === FETCH_BOOKING_COUPON_SUCCESS) {
        const { booking, coupon } = action.payload;
        const included = state.ids.includes(booking._id);
        const ids = included ? state.ids : [...state.ids, booking._id];
        const byId = {
            ...state.byId,
            [booking._id]: booking,
        };

        const couponByBookingId = {
            ...state.usersByBookingId,
            [booking._id]: coupon,
        };

        return {
            ...state,
            ids,
            byId,
            couponByBookingId,
        };
    }

    return state;
}
