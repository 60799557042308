import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import classNames from 'classnames';

import styles from './styles.module.css';

export interface Props {
    label: string;
    name: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    className?: string;
}

const TextAreaInput: React.FC<Props & WrappedFieldProps> = ({
    input,
    meta,
    name,
    label,
    placeholder,
    required,
    disabled,
    className,
}) => {
    return (
        <div className={classNames(styles.wrapper, className)}>
            <label htmlFor={name} className={styles.label}>
                {label}
            </label>
            <textarea
                {...input}
                required={required}
                disabled={disabled}
                placeholder={placeholder}
                className={styles.input}
            />
            {meta && meta.touched && meta.error && (
                <span className={styles.error}>{meta.error}</span>
            )}
        </div>
    );
};

export default TextAreaInput;
