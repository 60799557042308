import React from 'react';
import { IconProps } from './types';

export const LeftArrow: React.FC<IconProps> = ({ ...props }) => (
    <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 25 25"
        xmlSpace="preserve"
        {...props}
    >
        <g>
            <path
                d="M12.5,0C5.597,0,0,5.597,0,12.5S5.597,25,12.5,25S25,19.403,25,12.5S19.403,0,12.5,0z M12.5,23.173
		c-5.885,0-10.673-4.788-10.673-10.673S6.615,1.827,12.5,1.827S23.173,6.615,23.173,12.5S18.385,23.173,12.5,23.173z"
            />
            <polygon points="7.295,14 19,14 19,11 7.295,11 6.295,12.5 	" />
            <polygon points="5.383,12.5 10.883,7 13.742,7 8.242,12.5 	" />
            <polygon points="13.742,18 8.242,12.5 5.383,12.5 10.883,18 	" />
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
    </svg>
);

export default LeftArrow;
