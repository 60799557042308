import axios from 'axios';
import qs from 'qs';

import { User } from '@t/user';
import { LegalEntity } from '@t/legal-entity';
import { B2B_API_URL } from '@config';
import { FormData as AddLegalEntityFormData } from '@containers/Dashboard/AddLegalEntity/Form';
import { AsyncActionCreator } from '@redux/types';
import { LegalEntityFormData } from '@containers/Dashboard/LegalEntityDetails/Form';

import {
    FETCH_LEGALENTITIES_REQUEST,
    FETCH_LEGALENTITIES_SUCCESS,
    FETCH_LEGALENTITIES_ERROR,
    FETCH_LEGALENTITY_REQUEST,
    FETCH_LEGALENTITY_SUCCESS,
    FETCH_LEGALENTITY_ERROR,
    CREATE_LEGALENTITY_REQUEST,
    CREATE_LEGALENTITY_SUCCESS,
    CREATE_LEGALENTITY_ERROR,
    DELETE_LEGALENTITY_REQUEST,
    DELETE_LEGALENTITY_SUCCESS,
    DELETE_LEGALENTITY_ERROR,
    UPDATE_LEGALENTITY_REQUEST,
    UPDATE_LEGALENTITY_SUCCESS,
    UPDATE_LEGALENTITY_ERROR,
    DELETE_LEGALENTITY_GYM_REQUEST,
    DELETE_LEGALENTITY_GYM_SUCCESS,
    DELETE_LEGALENTITY_GYM_ERROR,
    UPLOAD_LEGALENTITY_DOCS_REQUEST,
    UPLOAD_LEGALENTITY_DOCS_SUCCESS,
    UPLOAD_LEGALENTITY_DOCS_ERROR,
    DELETE_LEGALENTITY_DOCS_REQUEST,
    DELETE_LEGALENTITY_DOCS_SUCCESS,
    DELETE_LEGALENTITY_DOCS_ERROR,
    FETCH_LEGALENTITIES_LIST_REQUEST,
    FETCH_LEGALENTITIES_LIST_SUCCESS,
    FETCH_LEGALENTITIES_LIST_ERROR,
    FETCH_SCROLL_LEGALENTITIES_REQUEST,
    FETCH_SCROLL_LEGALENTITIES_SUCCESS,
    FETCH_SCROLL_LEGALENTITIES_ERROR,
} from './types';

export const fetchLegalEntitiesList: AsyncActionCreator<
    void,
    LegalEntity[]
> = () => async (dispatch, getState) => {
    dispatch({
        type: FETCH_LEGALENTITIES_LIST_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: legalEntities } = await axios.get(
            `${B2B_API_URL}/legal-entities/list`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_LEGALENTITIES_LIST_SUCCESS,
            payload: legalEntities,
        });

        return legalEntities;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_LEGALENTITIES_LIST_ERROR,
            });

            throw err;
        }
    }
};

export const fetchLegalEntitys: AsyncActionCreator<number, LegalEntity[]> = (
    count?: number
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_LEGALENTITIES_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: legalEntitys } = await axios.get(
            `${B2B_API_URL}/legal-entities`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { count },
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                },
            }
        );

        dispatch({
            type: FETCH_LEGALENTITIES_SUCCESS,
            payload: legalEntitys,
        });

        return legalEntitys;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_LEGALENTITIES_ERROR,
            });

            throw err;
        }
    }
};

export const fetchScrollLegalEntitys: AsyncActionCreator<
    number,
    LegalEntity[]
> = (count?: number) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_SCROLL_LEGALENTITIES_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: legalEntitys } = await axios.get(
            `${B2B_API_URL}/legal-entities`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { count },
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                },
            }
        );

        dispatch({
            type: FETCH_SCROLL_LEGALENTITIES_SUCCESS,
            payload: legalEntitys,
        });

        return legalEntitys;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_SCROLL_LEGALENTITIES_ERROR,
            });

            throw err;
        }
    }
};

export const searchLegalEntitys: AsyncActionCreator<string, LegalEntity[]> = (
    value?: string
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_LEGALENTITIES_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: legalEntitys } = await axios.get(
            `${B2B_API_URL}/legal-entities`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { value },
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                },
            }
        );

        dispatch({
            type: FETCH_LEGALENTITIES_SUCCESS,
            payload: legalEntitys,
        });

        return legalEntitys;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_LEGALENTITIES_ERROR,
            });

            throw err;
        }
    }
};

export const fetchLegalEntity: AsyncActionCreator<string> = (
    id?: string
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_LEGALENTITY_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: legalEntity } = await axios.get(
            `${B2B_API_URL}/legal-entities/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_LEGALENTITY_SUCCESS,
            payload: legalEntity,
        });

        return legalEntity;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_LEGALENTITY_ERROR,
            });

            throw err;
        }
    }
};

export const createLegalEntity: AsyncActionCreator<
    { values: AddLegalEntityFormData; files: FileList },
    LegalEntity
> = ({ values, files }) => async (dispatch, getState) => {
    dispatch({
        type: CREATE_LEGALENTITY_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    const formData = new FormData();

    if (files?.length) {
        for (let k = 0; k < files.length; ++k) {
            formData.append('docs', files[k]);
        }
    }

    const newValues = { ...values };
    newValues.acquiringCommission = Number(
        String(newValues.acquiringCommission).replace(/,/, '.')
    );

    try {
        const { data: legalEntity } = await axios.post(
            `${B2B_API_URL}/legal-entities`,
            newValues,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (files?.length) {
            await axios.put(
                `${B2B_API_URL}/legal-entities/docs-upload/${legalEntity._id}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
        }

        dispatch({
            type: CREATE_LEGALENTITY_SUCCESS,
            payload: legalEntity,
        });

        return legalEntity;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: CREATE_LEGALENTITY_ERROR,
            });

            throw err;
        }
    }
};

export const deleteLegalEntity: AsyncActionCreator<string> = (id) => async (
    dispatch,
    getState
) => {
    dispatch({
        type: DELETE_LEGALENTITY_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: legalEntity } = await axios.delete(
            `${B2B_API_URL}/legal-entities/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: DELETE_LEGALENTITY_SUCCESS,
            payload: legalEntity,
        });

        return legalEntity;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: DELETE_LEGALENTITY_ERROR,
            });

            throw err;
        }
    }
};

export const updateLegalEntity: AsyncActionCreator<
    { id: string; values: LegalEntityFormData },
    User
> = ({ id, values }) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_LEGALENTITY_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    const newValues = { ...values };
    newValues.acquiringCommission = Number(
        String(newValues.acquiringCommission).replace(/,/, '.')
    );

    try {
        const { data: legalEntity } = await axios.put(
            `${B2B_API_URL}/legal-entities/${id}`,
            newValues,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: UPDATE_LEGALENTITY_SUCCESS,
            payload: legalEntity,
        });

        return legalEntity;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPDATE_LEGALENTITY_ERROR,
            });

            throw err;
        }
    }
};

export const deleteLegalEntityGym: AsyncActionCreator<{
    gymId: string;
    id: string;
}> = ({ gymId, id }) => async (dispatch, getState) => {
    dispatch({
        type: DELETE_LEGALENTITY_GYM_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: legalEntity } = await axios.put(
            `${B2B_API_URL}/legal-entities/${id}/${gymId}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: DELETE_LEGALENTITY_GYM_SUCCESS,
            payload: legalEntity,
        });

        return legalEntity;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: DELETE_LEGALENTITY_GYM_ERROR,
            });

            throw err;
        }
    }
};

export const uploadDocs: AsyncActionCreator<
    { files: FileList; legalEntityId: string },
    string
> = ({ files, legalEntityId }) => async (dispatch, getState) => {
    dispatch({
        type: UPLOAD_LEGALENTITY_DOCS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    const bodyFormData = new FormData();

    for (let k = 0; k < files.length; ++k) {
        bodyFormData.append('docs', files[k]);
    }

    try {
        const {
            data: { legalEntity },
        } = await axios.put(
            `${B2B_API_URL}/legal-entities/docs-upload/${legalEntityId}`,
            bodyFormData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        dispatch({
            type: UPLOAD_LEGALENTITY_DOCS_SUCCESS,
            payload: legalEntity,
        });
        return legalEntity;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPLOAD_LEGALENTITY_DOCS_ERROR,
            });

            throw err;
        }
    }
};

export const deleteDocs: AsyncActionCreator<
    { legalEntityId: string; key: string },
    string[]
> = ({ legalEntityId, key }) => async (dispatch, getState) => {
    dispatch({
        type: DELETE_LEGALENTITY_DOCS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const {
            data: { legalEntity },
        } = await axios.put(
            `${B2B_API_URL}/legal-entities/docs-delete/${legalEntityId}`,
            { key },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        dispatch({
            type: DELETE_LEGALENTITY_DOCS_SUCCESS,
            payload: legalEntity,
        });

        return legalEntity;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: DELETE_LEGALENTITY_DOCS_ERROR,
            });

            throw err;
        }
    }
};
