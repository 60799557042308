import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import { formValueSelector } from 'redux-form';

import { ApplicationState, AsyncDispatch } from '@redux/types';
import {
    createCoupon,
    createCouponForGroupWorkouts,
    fetchCouponsAdCampaigns,
} from '@redux/modules/coupons/actions';
import BackButton from '@components/BackButton';
import { getGymsList } from '@redux/modules/gyms/selectors';
import { getCouponsAdCampaigns } from '@redux/modules/coupons/selectors';
import { Coupon } from '@t/coupon';

import Form from './Form';
import styles from './styles.module.css';

export const AddCoupon: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const gyms = useSelector(getGymsList);
    const couponsAdCampaigns = useSelector(getCouponsAdCampaigns);
    const location = useLocation<{
        coupon: Coupon;
    }>();

    const selector = formValueSelector('addCoupon');
    const perMinWorkoutsSelector = (state: ApplicationState) =>
        selector(state, 'perMinWorkouts');
    const groupWorkoutsSelector = (state: ApplicationState) =>
        selector(state, 'groupWorkouts');
    const perMinWorkouts = useSelector(perMinWorkoutsSelector);
    const groupWorkouts = useSelector(groupWorkoutsSelector);

    useEffect(() => {
        dispatch(fetchCouponsAdCampaigns());
    }, [dispatch]);

    const onSubmit = useCallback(
        (values) => {
            if (perMinWorkouts) {
                dispatch(createCoupon(values))
                    .then((coupon) => {
                        if (coupon) {
                            cogoToast.success(
                                'Купон для поминутных тренировок успешно добавлен',
                                {
                                    position: 'top-right',
                                    hideAfter: 5,
                                }
                            );
                            history.push('/dashboard/coupons');
                        }
                    })
                    .catch(() => {
                        cogoToast.error(
                            'Не удалось создать купон для поминутных тренировок',
                            {
                                position: 'top-right',
                                hideAfter: 4,
                            }
                        );
                    });
            }

            if (groupWorkouts) {
                dispatch(createCouponForGroupWorkouts(values))
                    .then((coupon) => {
                        if (coupon) {
                            cogoToast.success(
                                'Купон для занятий успешно добавлен',
                                {
                                    position: 'top-right',
                                    hideAfter: 5,
                                }
                            );
                            history.push('/dashboard/coupons');
                        }
                    })
                    .catch(() => {
                        cogoToast.error(
                            'Не удалось создать купон для занятий',
                            {
                                position: 'top-right',
                                hideAfter: 4,
                            }
                        );
                    });
            }
        },
        [dispatch, history, perMinWorkouts, groupWorkouts]
    );

    const initialValues = {
        couponName: location.state?.coupon.couponName,
        couponType: location.state?.coupon.couponType,
        discount: location.state?.coupon.discount,
        initialPrice: location.state?.coupon.initialPrice,
        bookingDuration: location.state?.coupon.bookingDuration,
        active: location.state?.coupon.active,
        unlimited: location.state?.coupon.unlimited,
        limitPerUser: location.state?.coupon.limitPerUser,
        activatedStart: location.state?.coupon.activatedStart,
        activatedByUserId: location.state?.coupon.activatedByUserId,
        activatedCount: location.state?.coupon.activatedCount,
        actionStart: location.state?.coupon.actionStart
            ? location.state?.coupon.actionStart
            : new Date().toISOString().slice(0, 16),
        actionEnd: location.state?.coupon.actionEnd
            ? location.state?.coupon.actionEnd
            : new Date(new Date().setDate(new Date().getDate() + 1))
                  .toISOString()
                  .slice(0, 16),
        userIds: '',
        gymIds: location.state?.coupon.gymIds,
        comment: location.state?.coupon.comment,
        perMinWorkouts: true,
        groupWorkouts: true,
    };

    const gymsOptions = gyms.reduce(
        (options: { title: string; value: string }[], gym) => {
            if (!gym.isHidden) {
                options.push({ title: gym.title, value: gym._id });
            }
            return options;
        },
        []
    );

    const couponsAdCampaignsOptions = couponsAdCampaigns.map(
        (couponsAdCampaign) => {
            return {
                title: couponsAdCampaign.name,
                value: couponsAdCampaign._id,
            };
        }
    );

    return (
        <div className={styles.container}>
            <h1>Добавить новый промокод</h1>

            <BackButton
                title="К списку промокодов"
                className={styles.backBtn}
            />

            <Form
                initialValues={initialValues}
                onSubmit={onSubmit}
                gymsOptions={gymsOptions}
                couponsAdCampaignsOptions={couponsAdCampaignsOptions}
            />
        </div>
    );
};

export default AddCoupon;
