import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.css';

export interface Props {
    hidden?: boolean;
    label: string;
    name?: string;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    accept: string;
    fileInput?: React.MutableRefObject<null>;
    multiple?: boolean;
    onChange?: any;
}

const FileInput: React.FC<Props> = ({
    name,
    label,
    required,
    disabled,
    accept,
    className,
    fileInput,
    multiple,
    onChange,
}) => {
    return (
        <form className={classNames(styles.wrapper, className)}>
            <label htmlFor={name} className={styles.label}>
                {label}
            </label>
            <input
                type="file"
                disabled={disabled}
                required={required}
                ref={fileInput}
                accept={accept}
                className={styles.input}
                multiple={multiple}
                onChange={onChange}
            />
        </form>
    );
};

export default FileInput;
