import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import cogoToast from 'cogo-toast';

import { AbortReasonType, DATE_TIME_FORMAT } from '@config';
import { getDuration } from '@containers/Dashboard/BookingList/BookingListTable';
import {
    getAppUser,
    getBooking,
    getCoupon,
} from '@redux/modules/bookings/selectors';
import {
    fetchBooking,
    updateBooking,
    writeOffCostOfBooking,
    deleteBooking,
} from '@redux/modules/bookings/actions';
import { AsyncDispatch } from '@redux/types';
import { BookingType } from '@t/booking';
import BackButton from '@components/BackButton';
import { getUserPaymentDatas } from '@redux/modules/bookings/selectors';
import { UserPaymentData } from '@t/app-user';
import { CouponType, couponTypeMap } from '@t/coupon';
import Loader from '@components/Loader';
import { getGymsList } from '@redux/modules/gyms/selectors';
import { UserSubscription } from '@t/subscriptions';

import styles from './styles.module.css';
import BookingForm from './Form';

const abortReasonsMap = {
    [AbortReasonType.INTERNET_DISCONNECT]: 'Отсутствие интернет соединения',
    [AbortReasonType.DISCHARGED_PHONE]: 'Разряженный телефон',
    [AbortReasonType.FORGOT_TO_SCAN_QR_CODE]: 'Забыл отсканировать QR код',
};

export const BookingDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const booking = useSelector(getBooking(id || ''));
    const appUser = useSelector(getAppUser(id || ''));
    const coupon = useSelector(getCoupon(id || ''));
    const userPaymentDatas = useSelector(getUserPaymentDatas(id || ''));
    const gymsList = useSelector(getGymsList);
    const history = useHistory();
    const [selectedGym, setSelectedGym] = useState<{
        label: string;
        value: string;
    }>({ label: booking?.gymTitle, value: booking?.gymId });
    const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [userSubscriptions, setUserSubscriptions] = useState<
        UserSubscription[]
    >([]);

    useEffect(() => {
        if (!id) {
            return;
        }
        setLoading(true);
        dispatch(fetchBooking(id)).then((data) => {
            setUserSubscriptions(data.userSubscriptions);
            setLoading(false);
        });
    }, [dispatch, id]);

    useEffect(() => {
        if (!booking) {
            return;
        }
        setSelectedGym({ label: booking.gymTitle, value: booking.gymId });
    }, [booking]);

    const onSubmit = useCallback(
        (values) => {
            if (!id) {
                return;
            }

            dispatch(
                updateBooking({
                    id,
                    timeStarted: values.dateStart,
                    timeFinished: values.dateFinish,
                    gymTitle: selectedGym.label,
                    gymId: selectedGym.value,
                    subscriptionId: values.subscriptionId,
                })
            )
                .then((booking) => {
                    if (!booking) {
                        cogoToast.error('Ошибка при обновлении тренировки', {
                            position: 'top-right',
                            hideAfter: 4,
                        });
                    } else {
                        cogoToast.success('Тренировка обновлена', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                        history.push(`/dashboard/bookings`);
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при обновлении тренировки', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                });
        },
        [dispatch, id, selectedGym]
    );

    const onAborted = useCallback(() => {
        if (!id) {
            return;
        }
        history.push(`/dashboard/booking-cancel/${id}`);
    }, [id]);

    const writeOffCostOfBookings = useCallback(
        (userPaymentDataId) => {
            if (!id) {
                return;
            }
            setBtnDisabled(true);
            dispatch(writeOffCostOfBooking({ id, userPaymentDataId }))
                .then((paidBooking) => {
                    if (paidBooking) {
                        cogoToast.success('Тренировка отплачена', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                        history.push(`/dashboard/bookings`);
                    }
                })
                .catch((err) => {
                    cogoToast.error(`${err.response.data.error}`, {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => setBtnDisabled(false));
        },
        [id]
    );

    const onDeleteBooking = useCallback(() => {
        if (!id) {
            return;
        }
        dispatch(deleteBooking(id))
            .then(() => {
                cogoToast.success('Тренировка удалена', {
                    position: 'top-right',
                    hideAfter: 5,
                });
                history.push(`/dashboard/bookings`);
            })
            .catch(() => {
                cogoToast.error('Ошибка при удалении тренировки', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    }, [id]);

    if (!booking) {
        return null;
    }

    const isGroupWorkout = booking.type === BookingType.GroupWorkout;
    const isActiveBooking = booking.active;
    const isCouponApplied = booking.couponApplied;
    const isBonusWithdrawn = booking.isBonusWithdrawn || false;
    const paidByMembership = !!booking?.membershipId;
    const paidBySubscription = !!booking?.subscriptionId;

    const userPaymentDatasOptions =
        userPaymentDatas &&
        userPaymentDatas.map((userPaymentData: UserPaymentData) => {
            return {
                title: `${userPaymentData.cardType} - ${userPaymentData.cardExpDate}`,
                value: userPaymentData._id,
            };
        });

    const gymsOptions: { label: string; value: string }[] = gymsList.reduce(
        (options: { label: string; value: string }[], gym) => {
            if (gym.title) {
                options.push({ label: gym.title, value: gym._id });
            }
            return options;
        },
        []
    );

    const gymValue: {
        label: string;
        value: string;
    }[] = gymsOptions.filter(
        (gymsOption) => gymsOption.value === booking.gymId
    );

    const userSubscriptionsOptions = userSubscriptions.map(
        (userSubscription: { _id: string }) => ({
            title: userSubscription._id,
            value: userSubscription._id,
        })
    );

    if (!gymValue.length) {
        return null;
    }

    const initialValues = {
        userId: booking.mongoUserId,
        bookingId: booking._id,
        groupWorkoutReservationId: booking?.groupWorkoutReservationNewId
            ? booking.groupWorkoutReservationNewId
            : booking?.groupWorkoutReservationId || '',
        membershipId: booking?.membershipId || '',
        subscriptionId: booking?.subscriptionId || '',
        gymTitle: booking.gymTitle,
        dateStart: !booking.timeStarted
            ? ''
            : moment(booking.timeStarted).utcOffset(0).format(DATE_TIME_FORMAT),
        dateFinish: !booking.timeFinished
            ? ''
            : booking.timeFinishedForSubscribe
            ? moment(booking.timeFinishedForSubscribe)
                  .utcOffset(0)
                  .format(DATE_TIME_FORMAT)
            : moment(booking.timeFinished)
                  .utcOffset(0)
                  .format(DATE_TIME_FORMAT),
        duration: !booking.timeFinished
            ? ''
            : booking.timeFinishedForSubscribe
            ? `${getDuration(
                  booking.timeFinishedForSubscribe,
                  booking.timeStarted
              )} мин`
            : `${getDuration(booking.timeFinished, booking.timeStarted)} мин`,
        payStatus:
            paidByMembership && booking.paid
                ? 'Абонемент'
                : paidBySubscription && booking.paid
                ? 'Подписка'
                : booking.paid
                ? 'Оплачено'
                : 'Не оплачено',
        status: booking.active ? 'В процессе' : 'Завершена',
        price: !booking.timeFinished
            ? ' '
            : booking.initialPriceForSubscribe
            ? `${booking.initialPriceForSubscribe}₽`
            : booking.couponApplied ||
              booking.isBonusWithdrawn ||
              booking.prepayment ||
              booking.type === BookingType.GroupWorkout
            ? `${booking.initialPrice}₽`
            : `${booking.price}₽`,
        abortReason:
            booking.abortReasonType === AbortReasonType.OTHER
                ? booking.abortReasonMessage
                : abortReasonsMap[booking.abortReasonType],
        appUserFirstName: !appUser?.firstName ? '一' : appUser.firstName,
        appUserLastName: !appUser?.lastName ? '一' : appUser.lastName,
        appUserEmail: !appUser?.email ? '一' : appUser.email,
        appUserPhone: !appUser?.phoneNumber
            ? '一'
            : appUser.phoneNumber.replace(
                  /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
                  '$1 ($2) $3-$4-$5'
              ),
        discountPrice: !booking.timeFinished ? ' ' : `${booking.price}₽`,
        discountType: !booking.timeFinished
            ? '一'
            : booking.couponApplied
            ? coupon && coupon.couponName
            : 'Баллы',
        couponType: !coupon ? '一' : couponTypeMap[coupon.couponType],
        couponAmount: !coupon
            ? '一'
            : coupon.couponType === CouponType.amount
            ? coupon.initialPrice
            : `${Math.round(coupon.discount * 100)}%`,
        discountAmount: !booking.timeFinished
            ? '一'
            : booking.initialPrice - booking.price,
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <BackButton
                    to="/dashboard/gyms"
                    title="К списку тренировок"
                    className={styles.backBtn}
                />
                {!loading ? (
                    <div className={styles.box}>
                        <BookingForm
                            form="bookingInfo"
                            onSubmit={onSubmit}
                            initialValues={initialValues}
                            isActiveBooking={isActiveBooking}
                            isGroupWorkout={isGroupWorkout}
                            isCouponApplied={isCouponApplied}
                            isBonusWithdrawn={isBonusWithdrawn}
                            isAborted={booking.aborted}
                            paidByMembership={paidByMembership}
                            paidBySubscription={paidBySubscription}
                            userId={booking.mongoUserId}
                            onAborted={onAborted}
                            writeOffCostOfBookings={writeOffCostOfBookings}
                            userPaymentDatasOptions={userPaymentDatasOptions}
                            onDeleteBooking={onDeleteBooking}
                            btnDisabled={btnDisabled}
                            disabled={disabled}
                            setDisabled={setDisabled}
                            gymsOptions={gymsOptions}
                            gymValue={gymValue}
                            setSelectedGym={setSelectedGym}
                            userSubscriptionsOptions={userSubscriptionsOptions}
                            userSubscriptions={!!userSubscriptions.length}
                            btnTitle="Завершить тренировку"
                        />
                    </div>
                ) : (
                    <Loader />
                )}
            </div>
        </div>
    );
};

export default BookingDetails;
