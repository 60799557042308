import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, FormSubmitHandler, SubmissionError } from 'redux-form';

import TextInput from '@components/TextInput';
import GeoInput from '@components/GeoInput';
import { AsyncDispatch } from '@redux/types';
import { createGym } from '@redux/modules/gyms/actions';
import { getUser } from '@redux/modules/auth/selectors';
import {
    fetchUser,
    registrationComplete,
    updateUser,
} from '@redux/modules/auth/actions';
import Button from '@components/Button';

import Form from '../../Form';
import Title from '../../Title';
import Subtitle from '../../Subtitle';

import styles from './styles.module.css';

export const Name: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const user = useSelector(getUser);

    const initialValues = useMemo(
        () =>
            user
                ? {
                      gymTitle: user.onboardingGymMeta?.gymTitle,
                      gymAddress: user.onboardingGymMeta?.gymAddress,
                  }
                : {},
        [user]
    );

    if (!user) {
        return null;
    }

    const onSubmitWithoutGym = async () => {
        try {
            await dispatch(registrationComplete());
            await dispatch(fetchUser());
        } catch (err) {
            throw new SubmissionError({
                _error: 'Ошибка при регистрации',
            });
        }
    };

    const onSubmit: FormSubmitHandler<any> = async ({
        gymTitle,
        description,
        descriptionLong,
        gymAddress,
    }) => {
        try {
            await dispatch(
                updateUser({
                    onboardingGymMeta: {
                        gymTitle,
                        gymAddress,
                    },
                })
            );

            await dispatch(fetchUser());

            await dispatch(
                createGym({
                    title: gymTitle,
                    authorized: false,
                    deleted: true,
                    description,
                    descriptionLong,
                    address: gymAddress.address,
                    shortAddress: gymAddress.address,
                    geo: {
                        address: gymAddress.address,
                        shortAddress: gymAddress.address,
                        position: gymAddress.position,
                    },
                    social: {
                        instagram: '',
                        vk: '',
                        facebook: '',
                        telegram: '',
                    },
                    specialPrices: [],
                    fixedPrice: 0,
                    tags: { shower: true },
                    services: [],
                    advantages: [],
                    website: '',
                    phone: '',
                    subwayRoutes: [],
                    hasPerMinWorkouts: true,
                    hasGroupWorkouts: false,
                    hasSingleWorkouts: false,
                    withSingleWorkoutsOption: false,
                    withGroupWorkoutsOption: false,
                    hasMinWorkoutDuration: false,
                    hasWeekendPrimeTime: false,
                    hasWeekDayPrimeTime: false,
                    legalEntityId: '',
                    managersId: [],
                    hasPassport: false,
                })
            );

            await dispatch(registrationComplete());
            await dispatch(fetchUser());
        } catch (err) {
            throw new SubmissionError({
                _error: 'Ошибка при регистрации',
            });
        }
    };

    return (
        <div>
            <Title message="Теперь мы будем на связи!" />
            <Subtitle message="Расскажите немного о своем фитнес-клубе" />
            <Form
                form="gymInfo"
                onSubmit={onSubmit}
                backBtnTo="/registration-steps/"
                initialValues={initialValues}
            >
                <Field
                    required
                    name="gymTitle"
                    label="Название фитнес-площадки"
                    component={TextInput}
                />

                {/* <Field
                    required
                    name="description"
                    label="Описание"
                    placeholder="Лучшее, что было с вашим телом"
                    component={TextAreaInput}
                /> */}

                {/* <Field
                    required
                    name="descriptionLong"
                    label="Услуги"
                    placeholder="Лучшее, что было с вашим телом. Действительно, это лучшее, что было с вашим телом. Наверное, это лучшее, что случилось в вашей жизни. Самый лучший зал открывает для вас свои двери. Тренируйтесь как в последний раз. До конца, до упора. С нами вы забудете, что такое спокойная тренировка. Самый лучший зал не только открывает двери, но и размывает границы болевого порога для всех желающих."
                    component={TextAreaInput}
                /> */}

                <Field
                    required
                    name="gymAddress"
                    label="Адрес фитнес-площадки"
                    component={GeoInput}
                />
                <Button
                    buttonType="secondary"
                    className={styles.backBtn}
                    onClick={onSubmitWithoutGym}
                >
                    Пропустить шаг
                </Button>
            </Form>
        </div>
    );
};

export default Name;
