import React, { useState } from 'react';
import {
    InjectedFormProps,
    Field,
    reduxForm,
    formValueSelector,
    FieldArray,
    change,
} from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import TextInput from '@components/TextInput';
import Button from '@components/Button';
import SelectInput from '@components/SelectInput';
import Modal from '@components/Modal';
import TextAreaInput from '@components/TextAreaInput';
import { ApplicationState } from '@redux/types';
import {
    GoGymCommission,
    СollaborationType,
    СollaborationTypeOptions,
    СommissionType,
    СommissionTypeOptions,
} from '@t/legal-entity';
import { MembershipType, MembershipTypeOptions } from '@t/membership';
import DeleteButton from '@components/DeleteButton';
import FileInput from '@components/FileInput';
import MultiSelectInputV2 from '@components/MultiSelectInputV2';

import styles from './styles.module.css';

export interface LegalEntityFormData {
    legalEntityId: string;
    name: string;
    contractNumber: string;
    managerEmail: string;
    address: string;
    actualAddress: string;
    inn: string;
    checkingAccount: string;
    correspondentAccount: string;
    bik: string;
    goGymCommission: GoGymCommission[];
    acquiringCommission: number;
    createdAt: string;
    comment: string;
}

export interface Props {
    onDeleteLegalEntity: () => void;
    onDeleteLegalEntityGym: (gymId: string) => void;
    goToGym: (gymId: string) => void;
    gymsOptions: { label: string; value: string }[];
    gymsValue: { label: string; value: string }[];
    fileInput: React.MutableRefObject<null>;
    onUploadDocs: () => Promise<void>;
    legalEntityDocsUrls: string[];
    onDeleteDocsClick: (
        event: React.MouseEvent<HTMLInputElement>
    ) => Promise<void>;
    uploadMultipleFiles: (event: React.MouseEvent<HTMLInputElement>) => void;
    isLoading: boolean;
    disabled: boolean;
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const validate = (values: LegalEntityFormData) => {
    const errors: {
        name: string;
        contractNumber: string;
        managerEmail: string;
        inn: string;
        checkingAccount: string;
        correspondentAccount: string;
        bik: string;
        acquiringCommission: string;
    } = {
        name: '',
        contractNumber: '',
        managerEmail: '',
        inn: '',
        checkingAccount: '',
        correspondentAccount: '',
        bik: '',
        acquiringCommission: '',
    };

    if (!values.name) {
        errors.name = 'Обязательное поле';
    }

    if (!values.contractNumber) {
        errors.contractNumber = 'Введите номер договора';
    }

    if (!values.managerEmail) {
        errors.managerEmail = 'Введите email';
    }

    if (isNaN(Number(values.inn))) {
        errors.inn = 'Должно быть числом';
    }

    if (isNaN(Number(values.checkingAccount))) {
        errors.checkingAccount = 'Должно быть числом';
    }

    if (values?.checkingAccount?.length !== 20) {
        errors.checkingAccount = 'Номер счета должен состоять из 20 цифр';
    }

    if (isNaN(Number(values.correspondentAccount))) {
        errors.correspondentAccount = 'Должно быть числом';
    }

    if (isNaN(Number(values.bik))) {
        errors.bik = 'Должно быть числом';
    }

    if (isNaN(Number(String(values.acquiringCommission).replace(/,/, '.')))) {
        errors.acquiringCommission = 'Должно быть числом';
    }

    return errors;
};

const LegalEntityForm: React.FC<
    Props & InjectedFormProps<LegalEntityFormData, Props>
> = ({
    handleSubmit,
    onDeleteLegalEntity,
    gymsOptions,
    gymsValue,
    fileInput,
    onUploadDocs,
    legalEntityDocsUrls,
    onDeleteDocsClick,
    uploadMultipleFiles,
    isLoading,
    disabled,
    setDisabled,
}) => {
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;
    const [modalActive, setModalActive] = useState(false);

    const renderCommission = ({ fields }: any) => {
        const dispatch = useDispatch();
        const selector = formValueSelector('updateLegalEntity');
        const goGymCommissionSelector = (state: ApplicationState) =>
            selector(state, 'goGymCommission');
        const hasGoGymCommission = useSelector(goGymCommissionSelector);

        if (hasGoGymCommission?.length) {
            for (let i = 0; i < hasGoGymCommission.length; i++) {
                if (
                    (hasGoGymCommission[i].commission &&
                        Number(hasGoGymCommission[i].commission) <= 0) ||
                    isNaN(Number(hasGoGymCommission[i].commission))
                ) {
                    hasGoGymCommission[i].commission = '0';
                    dispatch(
                        change(
                            'updateLegalEntity',
                            hasGoGymCommission[i].commission,
                            '0'
                        )
                    );
                }
            }
        }

        return (
            <>
                {fields.map(
                    (
                        goGymCommission: Array<{
                            collaborationType: string;
                            commissionType: string;
                            membershipType: string;
                            membershipDuration: number;
                            commission: number;
                        }>,
                        index: number
                    ) => (
                        <div key={index}>
                            <>
                                <Field
                                    disabled={disabled}
                                    name={`${goGymCommission}.collaborationType`}
                                    label="Тип сотрудничества"
                                    component={SelectInput}
                                    options={СollaborationTypeOptions}
                                    onChange={() => {
                                        dispatch(
                                            change(
                                                'updateLegalEntity',
                                                `${goGymCommission}.commissionType`,
                                                null
                                            )
                                        );
                                        dispatch(
                                            change(
                                                'updateLegalEntity',
                                                `${goGymCommission}.commission`,
                                                null
                                            )
                                        );
                                    }}
                                    emptyTitle={
                                        'Выберите тип сотрудничества...'
                                    }
                                />

                                {hasGoGymCommission[index].collaborationType ===
                                    СollaborationType.PerMinWorkout && (
                                    <Field
                                        disabled={disabled}
                                        name={`${goGymCommission}.commissionType`}
                                        label="Вид комиссии"
                                        component={SelectInput}
                                        options={СommissionTypeOptions}
                                        emptyTitle={'Выберите тип комиссии...'}
                                    />
                                )}

                                {hasGoGymCommission[index].collaborationType ===
                                    СollaborationType.GroupWorkout && (
                                    <Field
                                        disabled={disabled}
                                        name={`${goGymCommission}.commissionType`}
                                        label="Вид комиссии"
                                        component={SelectInput}
                                        options={СommissionTypeOptions}
                                        emptyTitle={'Выберите тип комиссии...'}
                                    />
                                )}

                                {((hasGoGymCommission[index].commissionType &&
                                    hasGoGymCommission[index].commissionType ===
                                        СommissionType.Сommission) ||
                                    hasGoGymCommission[index].commissionType ===
                                        СommissionType.Cost) && (
                                    <Field
                                        disabled={disabled}
                                        name={`${goGymCommission}.commission`}
                                        label="Комиссия GOGYM"
                                        placeholder="Комиссия GOGYM"
                                        component={TextInput}
                                    />
                                )}

                                {hasGoGymCommission[index].commissionType &&
                                    hasGoGymCommission[index].commissionType ===
                                        СommissionType.TransfersToPartner && (
                                        <Field
                                            disabled={disabled}
                                            name={`${goGymCommission}.commission`}
                                            label="Перечисление партнеру"
                                            placeholder="Перечисление партнеру"
                                            component={TextInput}
                                        />
                                    )}

                                {hasGoGymCommission[index].collaborationType ===
                                    СollaborationType.Membership && (
                                    <Field
                                        disabled={disabled}
                                        name={`${goGymCommission}.membershipType`}
                                        label="Тип абонемента"
                                        placeholder="Тип абонемента"
                                        component={SelectInput}
                                        options={MembershipTypeOptions}
                                        onChange={() => {
                                            dispatch(
                                                change(
                                                    'updateLegalEntity',
                                                    `${goGymCommission}.membershipDuration`,
                                                    null
                                                )
                                            );
                                            dispatch(
                                                change(
                                                    'updateLegalEntity',
                                                    `${goGymCommission}.commission`,
                                                    null
                                                )
                                            );
                                        }}
                                    />
                                )}

                                {hasGoGymCommission[index].collaborationType ===
                                    СollaborationType.Membership &&
                                    hasGoGymCommission[index].membershipType ===
                                        MembershipType.TIME && (
                                        <Field
                                            disabled={disabled}
                                            name={`${goGymCommission}.membershipDuration`}
                                            label="Продолжительность абонемента (дни)"
                                            placeholder="Продолжительность абонемента (дни)"
                                            component={TextInput}
                                        />
                                    )}

                                {hasGoGymCommission[index].collaborationType ===
                                    СollaborationType.Membership &&
                                    hasGoGymCommission[index].membershipType ===
                                        MembershipType.SESSION && (
                                        <Field
                                            disabled={disabled}
                                            name={`${goGymCommission}.membershipDuration`}
                                            label="Продолжительность абонемента (тренировки)"
                                            placeholder="Продолжительность абонемента (тренировки)"
                                            component={TextInput}
                                        />
                                    )}

                                {hasGoGymCommission[index].collaborationType ===
                                    СollaborationType.Membership &&
                                    hasGoGymCommission[index]
                                        .membershipDuration && (
                                        <Field
                                            disabled={disabled}
                                            name={`${goGymCommission}.commission`}
                                            label="Перечисление партнеру"
                                            placeholder="Перечисление партнеру"
                                            component={TextInput}
                                        />
                                    )}

                                {!disabled && (
                                    <div className={styles.wrapBtn}>
                                        <button
                                            className={styles.acquiringBtn}
                                            type="button"
                                            disabled={disabled}
                                            onClick={() => fields.remove(index)}
                                        >
                                            Удалить
                                        </button>
                                    </div>
                                )}
                            </>
                        </div>
                    )
                )}

                {!disabled && (
                    <button
                        className={styles.acquiringBtn}
                        type="button"
                        disabled={disabled}
                        onClick={() => fields.push({})}
                    >
                        Добавить тип сотрудничества
                    </button>
                )}
            </>
        );
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Field
                disabled
                name="legalEntityId"
                label="ID юридического лица"
                component={TextInput}
            />

            <Field
                disabled
                name="createdAt"
                label="Дата добаления"
                component={TextInput}
            />

            <Field
                disabled={disabled}
                name={`gyms`}
                label="Фитнес-площадки"
                placeholder="Выберите из списка"
                initialValue={gymsValue}
                options={gymsOptions}
                isMulti={true}
                component={MultiSelectInputV2}
            />

            <Field
                disabled={disabled}
                name="name"
                label="Название юридического лица"
                placeholder="Название юридического лица"
                component={TextInput}
            />

            <Field
                disabled={disabled}
                name="contractNumber"
                label="Номер договора"
                placeholder="Номер договора"
                component={TextInput}
            />

            <Field
                disabled={disabled}
                name="managerEmail"
                label="Email менеджера"
                placeholder="Email менеджера"
                component={TextInput}
            />

            <Field
                disabled={disabled}
                name="address"
                label="Юридический адрес"
                placeholder="Юридический адрес"
                component={TextAreaInput}
            />

            <Field
                disabled={disabled}
                name="actualAddress"
                label="Фактический адрес"
                placeholder="Фактический адрес"
                component={TextAreaInput}
            />

            <Field
                disabled={disabled}
                name="inn"
                label="ИНН"
                placeholder="ИНН"
                component={TextInput}
            />

            <Field
                disabled={disabled}
                name="checkingAccount"
                label="Расчетный счет"
                placeholder="Расчетный счет"
                component={TextInput}
            />

            <Field
                disabled={disabled}
                name="correspondentAccount"
                label="Корреспондентский счёт"
                placeholder="Корреспондентский счёт"
                component={TextInput}
            />

            <Field
                disabled={disabled}
                name="bik"
                label="БИК"
                placeholder="БИК"
                component={TextInput}
            />

            <FieldArray name="goGymCommission" component={renderCommission} />

            <Field
                disabled={disabled}
                name="acquiringCommission"
                label="Комиссия эквайринга"
                placeholder="Комиссия эквайринга"
                component={TextInput}
            />

            <Field
                disabled={disabled}
                name="comment"
                label="Комментарий"
                placeholder="Комментарий для юр лица"
                component={TextAreaInput}
            />

            {legalEntityDocsUrls.length > 0 && (
                <span className={styles.label}>Документы</span>
            )}
            <div className={styles.images}>
                {legalEntityDocsUrls.map((url: string, index: number) => (
                    <div className={styles.row} key={url}>
                        <a href={url} target="_blank" rel="noopener noreferrer">
                            <img
                                alt="pdf"
                                id={`img${index}`}
                                src={
                                    'https://api.gogym.fit/s3-gogym-icons/pdf.svg'
                                }
                                className={styles.docImage}
                            />
                        </a>
                        <DeleteButton
                            disabled={disabled}
                            id={url}
                            onClick={onDeleteDocsClick}
                        />
                    </div>
                ))}
            </div>

            {legalEntityDocsUrls.length < 3 && (
                <>
                    <FileInput
                        disabled={disabled}
                        className="docs-input"
                        name="docs"
                        label="Загрузить документы"
                        accept=".pdf"
                        fileInput={fileInput}
                        multiple
                        onChange={uploadMultipleFiles}
                    />
                    <Button
                        disabled={isLoading ? isLoading : disabled}
                        onClick={onUploadDocs}
                        type="button"
                        className={styles.btn}
                    >
                        {isLoading ? 'Загрузка...' : 'Загрузить документы'}
                    </Button>
                </>
            )}

            {isSuperAdmin && !disabled && (
                <Button type="submit" className={styles.btn}>
                    Обновить
                </Button>
            )}

            {isSuperAdmin && disabled && (
                <Button
                    type="button"
                    onClick={() => setDisabled(false)}
                    className={styles.btn}
                >
                    Изменить
                </Button>
            )}

            <Modal active={modalActive} setActive={setModalActive}>
                <h3>Вы действительно хотите удалить юридическое лицо?</h3>
                <p>
                    Удаление юридического лица приведет к полному удалению его
                    данных <br /> и оно не будет использованно для построения
                    отчетов
                </p>
                {isSuperAdmin && (
                    <Button
                        onClick={onDeleteLegalEntity}
                        type="button"
                        className={styles.btnModal}
                    >
                        Удалить
                    </Button>
                )}
            </Modal>

            {isSuperAdmin && (
                <Button
                    onClick={() => setModalActive(true)}
                    type="button"
                    className={styles.btn}
                >
                    Удалить
                </Button>
            )}
        </form>
    );
};

export default reduxForm<LegalEntityFormData, Props>({
    form: 'updateLegalEntity',
    validate,
})(LegalEntityForm);
