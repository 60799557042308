import React from 'react';
import moment from 'moment';

export interface Props {
    x: any;
    y: any;
    stroke: number;
    payload: {
        value: any;
    };
}

export const XAxisTick: React.FC<Props> = ({ x, y, payload }) => {
    const date = moment(payload.value).format('DD/MM/YYYY');

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill="#1B1C1B">
                {date}
            </text>
        </g>
    );
};

export default XAxisTick;
