import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { AsyncDispatch } from '@redux/types';
import { getGymsList, getSelectedGymId } from '@redux/modules/gyms/selectors';
import BoxButton from '@components/BoxButton';
import { getGroupWorkoutsCalendar } from '@redux/modules/group-workouts/selectors';
import { fetchGymGroupWorkoutsSchedule } from '@redux/modules/group-workouts/actions';
import Box from '@components/Box';
import GymFilterForm from '@containers/Dashboard/GymFilterForm';
import Info from '@components/Icons/Info';
import Calendar from '@components/Calendar';
import { combineDates } from '@containers/Dashboard/GroupWorkouts/GroupWorkoutCalendar';

import styles from './styles.module.css';

export const GroupWorkoutsCalendar: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const groupWorkouts = useSelector(getGroupWorkoutsCalendar());
    const selectedGymId = useSelector(getSelectedGymId);
    const gyms = useSelector(getGymsList);
    const history = useHistory();
    const [startWeek, setStartWeek] = useState(
        moment().utc(true).startOf('week').toISOString()
    );

    const gymsHasGroupWorkoutsOptions: {
        label: string;
        value: string;
    }[] = gyms.reduce((acc: { label: string; value: string }[], gym) => {
        if (!gym.isHidden && gym.hasGroupWorkouts) {
            acc.push({ label: gym.title, value: gym._id });
        }
        return acc;
    }, []);

    useEffect(() => {
        if (!selectedGymId) {
            return;
        }
        dispatch(
            fetchGymGroupWorkoutsSchedule({
                gymId: selectedGymId,
                date: startWeek,
            })
        );
    }, [dispatch, selectedGymId, startWeek]);

    const events = groupWorkouts.map(
        ({
            _id,
            timeSlotId,
            title,
            date,
            timeStart,
            timeFinish,
            limit,
            freeReserve,
        }) => ({
            groupWorkoutId: _id,
            timeSlotId,
            title: title || '',
            start: combineDates(date, timeStart),
            end: combineDates(date, timeFinish),
            reservedSlots: freeReserve,
            availableSlots: limit,
        })
    );

    const handleCalendarNavigate = (date: Date) => {
        setStartWeek(moment(date).startOf('week').utc(true).toISOString());
    };

    const onAddGroupWorkout = () => {
        history.push(`/dashboard/group-workouts/add`);
    };

    const goToGroupWorkouts = useCallback(
        () => history.push('/dashboard/group-workouts'),
        [history]
    );

    const goToCalendar = useCallback(
        () => history.push('/dashboard/group-workouts/calendar'),
        [history]
    );

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Групповые тренировки</h1>
            <div className={styles.wrap}>
                <GymFilterForm gyms={gymsHasGroupWorkoutsOptions} />
                <button
                    onClick={onAddGroupWorkout}
                    type="button"
                    className={styles.btnAdd}
                >
                    Создать тренировку
                </button>
            </div>
            <div>
                <div className={styles.nav}>
                    <BoxButton
                        icon=""
                        title="Тренировки"
                        onClick={goToGroupWorkouts}
                        className={styles.actionBtn}
                    />
                    <BoxButton
                        icon=""
                        title="Календарь"
                        onClick={goToCalendar}
                        className={styles.actionNav}
                    />
                </div>

                <Box>
                    {!selectedGymId && (
                        <div className={styles.info}>
                            <Info /> Для просмотра календаря выберите
                            фитнес-площадку
                        </div>
                    )}
                    {selectedGymId && (
                        <>
                            <h3>
                                Нажмите на необходимую вам дату / время для
                                создания новой тренировки
                            </h3>

                            <Calendar
                                events={events}
                                handleCalendarNavigate={handleCalendarNavigate}
                                screen={'GroupWorkouts'}
                            />
                        </>
                    )}
                </Box>
            </div>
        </div>
    );
};

export default GroupWorkoutsCalendar;
