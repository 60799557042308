import React, { useMemo, useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { UserRole } from '@t/user';
import { getUser } from '@redux/modules/auth/selectors';
import { getGymsList } from '@redux/modules/gyms/selectors';

import GoGymLogoBlack from '../Icons/GoGymLogoBlack';
import Profile from '../Icons/Profile';
import ShapeDown from '../Icons/ShapeDown';
import styles from './styles.module.css';

export const NavigationBar: React.FC<{}> = () => {
    const [dropDownAdmin, setDropDownAdmin] = useState<boolean>(false);
    const [dropDownBookings, setDropDownBookings] = useState<boolean>(false);
    const user = useSelector(getUser);
    const history = useHistory();
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;
    const isAnalitics = user?.role === UserRole.Analytics;
    const isAdmin = user?.role === UserRole.Admin;
    const isHR = user?.role === UserRole.HR;
    const hasGroupWorkouts =
        useSelector(getGymsList).filter(
            (gym) => !gym.isHidden && gym.hasGroupWorkouts
        ).length > 0;
    const hasMemberships =
        useSelector(getGymsList).filter(
            (gym) => !gym.isHidden && gym.memberships && gym.memberships.length
        ).length > 0;

    const isUserInWorkoutSection = useMemo(() => {
        const workoutsSubLinksArray = [
            'dashboard/bookings',
            'dashboard/group-workouts',
        ];
        const path = history.location.pathname;
        let isActiveSection = false;
        if (workoutsSubLinksArray.some((link) => path.includes(link))) {
            isActiveSection = true;
        }

        return isActiveSection;
    }, [history.location]);

    if (isHR) {
        return (
            <div className={styles.container}>
                <div className={styles.content}>
                    <Link to="/dashboard/hr-statistics" className={styles.logo}>
                        <GoGymLogoBlack />
                    </Link>
                    <div className={styles.list}>
                        <NavLink
                            exact
                            to="/dashboard/hr-statistics"
                            className={styles.listItem}
                            activeClassName={styles.listItemActive}
                        >
                            Дашборд
                        </NavLink>
                        <NavLink
                            to="/dashboard/corporate-bookings"
                            className={styles.listItem}
                            activeClassName={styles.listItemActive}
                        >
                            Тренировки
                        </NavLink>
                        <NavLink
                            to="/dashboard/hr-reports"
                            className={styles.listItem}
                            activeClassName={styles.listItemActive}
                        >
                            Отчеты
                        </NavLink>
                        <NavLink
                            to="/dashboard/profile"
                            className={styles.listItem}
                            activeClassName={styles.listItemActive}
                        >
                            <Profile />
                        </NavLink>
                    </div>
                </div>
            </div>
        );
    }

    if (isAdmin) {
        return (
            <div className={styles.container}>
                <div className={styles.content}>
                    <Link to="/" className={styles.logo}>
                        <GoGymLogoBlack />
                    </Link>
                    <div className={styles.list}>
                        <NavLink
                            exact
                            to="/dashboard"
                            className={styles.listItem}
                            activeClassName={styles.listItemActive}
                        >
                            Дашборд
                        </NavLink>
                        <NavLink
                            to="/dashboard/gyms"
                            className={styles.listItem}
                            activeClassName={styles.listItemActive}
                        >
                            Площадки
                        </NavLink>
                        <div
                            className={classNames(styles.listItem, {
                                [styles.listItemActive]: isUserInWorkoutSection,
                            })}
                            onClick={() => setDropDownBookings((prev) => !prev)}
                        >
                            Тренировки{' '}
                            <ShapeDown
                                className={
                                    isUserInWorkoutSection
                                        ? styles.listItemIconActive
                                        : undefined
                                }
                            />
                        </div>
                        <div
                            className={
                                dropDownBookings
                                    ? styles.dropDownBookings
                                    : styles.dropDownHide
                            }
                            onClick={() => setDropDownBookings(false)}
                        >
                            <NavLink
                                to="/dashboard/bookings"
                                className={styles.listSubItem}
                                activeClassName={styles.listSubItemActive}
                            >
                                Поминутные
                            </NavLink>
                            {hasGroupWorkouts && (
                                <NavLink
                                    to="/dashboard/group-workouts"
                                    className={styles.listSubItem}
                                    activeClassName={styles.listSubItemActive}
                                >
                                    Групповые
                                </NavLink>
                            )}
                        </div>
                        {hasGroupWorkouts && (
                            <NavLink
                                to="/dashboard/reservations"
                                className={styles.listItem}
                                activeClassName={styles.listItemActive}
                            >
                                Бронирования
                            </NavLink>
                        )}
                        {hasMemberships && (
                            <NavLink
                                to="/dashboard/gym-memberships"
                                className={styles.listItem}
                                activeClassName={styles.listItemActive}
                            >
                                Абонементы
                            </NavLink>
                        )}
                        <NavLink
                            to="/dashboard/reports-bookings"
                            className={styles.listItem}
                            activeClassName={styles.listItemActive}
                        >
                            Отчеты
                        </NavLink>
                        <NavLink
                            to="/dashboard/profile"
                            className={styles.listItem}
                            activeClassName={styles.listItemActive}
                        >
                            <Profile />
                        </NavLink>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Link to="/" className={styles.logo}>
                    <GoGymLogoBlack />
                </Link>
                <div className={styles.list}>
                    <NavLink
                        exact
                        to="/dashboard"
                        className={styles.listItem}
                        activeClassName={styles.listItemActive}
                    >
                        Дашборд
                    </NavLink>
                    <NavLink
                        to="/dashboard/gyms"
                        className={styles.listItem}
                        activeClassName={styles.listItemActive}
                    >
                        Площадки
                    </NavLink>
                    <div
                        className={classNames(styles.listItem, {
                            [styles.listItemActive]: isUserInWorkoutSection,
                        })}
                        onClick={() => setDropDownBookings((prev) => !prev)}
                    >
                        Тренировки{' '}
                        <ShapeDown
                            className={
                                isUserInWorkoutSection
                                    ? styles.listItemIconActive
                                    : undefined
                            }
                        />
                    </div>
                    <div
                        className={
                            dropDownBookings
                                ? styles.dropDownBookings
                                : styles.dropDownHide
                        }
                        onClick={() => setDropDownBookings(false)}
                    >
                        <NavLink
                            to="/dashboard/bookings"
                            className={styles.listSubItem}
                            activeClassName={styles.listSubItemActive}
                        >
                            Поминутные
                        </NavLink>
                        {hasGroupWorkouts && (
                            <NavLink
                                to="/dashboard/group-workouts"
                                className={styles.listSubItem}
                                activeClassName={styles.listSubItemActive}
                            >
                                Занятия
                            </NavLink>
                        )}
                    </div>
                    {hasGroupWorkouts && (
                        <NavLink
                            to="/dashboard/reservations"
                            className={styles.listItem}
                            activeClassName={styles.listItemActive}
                        >
                            Бронирования
                        </NavLink>
                    )}
                    {hasMemberships && (
                        <NavLink
                            to="/dashboard/gym-memberships"
                            className={styles.listItem}
                            activeClassName={styles.listItemActive}
                        >
                            Абонементы
                        </NavLink>
                    )}
                    <NavLink
                        to="/dashboard/reports-bookings"
                        className={styles.listItem}
                        activeClassName={styles.listItemActive}
                    >
                        Отчеты
                    </NavLink>
                    {isAnalitics && (
                        <NavLink
                            to="/dashboard/app-users"
                            className={styles.listItem}
                            activeClassName={styles.listItemActive}
                        >
                            Пользователи
                        </NavLink>
                    )}
                    {isAnalitics && (
                        <NavLink
                            to="/dashboard/reporting"
                            className={styles.listItem}
                            activeClassName={styles.listItemActive}
                        >
                            Отчетность
                        </NavLink>
                    )}
                    <div
                        className={
                            dropDownAdmin
                                ? styles.dropDownAdmin
                                : styles.dropDownHide
                        }
                        onClick={() => setDropDownAdmin(false)}
                    >
                        {isSuperAdmin && (
                            <NavLink
                                to="/dashboard/subscriptions"
                                className={styles.listSubItem}
                                activeClassName={styles.listSubItemActive}
                            >
                                Подписки
                            </NavLink>
                        )}
                        {isSuperAdmin && (
                            <NavLink
                                to="/dashboard/managers"
                                className={styles.listSubItem}
                                activeClassName={styles.listSubItemActive}
                            >
                                Менеджеры
                            </NavLink>
                        )}
                        {isSuperAdmin && (
                            <NavLink
                                to="/dashboard/legalentity"
                                className={styles.listSubItem}
                                activeClassName={styles.listSubItemActive}
                            >
                                Юр лица
                            </NavLink>
                        )}
                        {isSuperAdmin && (
                            <NavLink
                                to="/dashboard/app-users"
                                className={styles.listSubItem}
                                activeClassName={styles.listSubItemActive}
                            >
                                Пользователи
                            </NavLink>
                        )}
                        {isSuperAdmin && (
                            <NavLink
                                to="/dashboard/coupons"
                                className={styles.listSubItem}
                                activeClassName={styles.listSubItemActive}
                            >
                                Промокоды
                            </NavLink>
                        )}
                        {isSuperAdmin && (
                            <NavLink
                                to="/dashboard/tags"
                                className={styles.listSubItem}
                                activeClassName={styles.listSubItemActive}
                            >
                                Тэги
                            </NavLink>
                        )}
                        {isSuperAdmin && (
                            <NavLink
                                to="/dashboard/contacts"
                                className={styles.listSubItem}
                                activeClassName={styles.listSubItemActive}
                            >
                                Контакты GOGYM
                            </NavLink>
                        )}
                        {isSuperAdmin && (
                            <NavLink
                                to="/dashboard/add-partner-manager"
                                className={styles.listSubItem}
                                activeClassName={styles.listSubItemActive}
                            >
                                Новый партнер
                            </NavLink>
                        )}
                        {isSuperAdmin && (
                            <NavLink
                                to="/dashboard/reporting"
                                className={styles.listSubItem}
                                activeClassName={styles.listSubItemActive}
                            >
                                Отчетность
                            </NavLink>
                        )}
                    </div>
                    {isSuperAdmin && (
                        <div
                            className={styles.listItem}
                            onClick={() => setDropDownAdmin((prev) => !prev)}
                        >
                            Админ <ShapeDown />
                        </div>
                    )}

                    <NavLink
                        to="/dashboard/profile"
                        className={styles.listItem}
                        activeClassName={styles.listItemActive}
                    >
                        <Profile />
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default NavigationBar;
