import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { getReservations } from '@redux/modules/reservations/selectors';
import {
    fetchReservations,
    fetchScrollReservations,
} from '@redux/modules/reservations/actions';
import Box from '@components/Box';
import { getSelectedGymId } from '@redux/modules/gyms/selectors';
import BoxButton from '@components/BoxButton';
import Toggle from '@components/Toggle';
import { AsyncDispatch } from '@redux/types';
import BackButton from '@components/BackButton';

import ReservationsListTable from './ReservationsListTable';
import styles from './styles.module.css';

export const ReservationsList: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const reservations = useSelector(getReservations);
    const selectedGymId = useSelector(getSelectedGymId);
    const [isToggled, setIsToggled] = useState<boolean>(false);
    const [fetching, setFetching] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [reservationsIsOver, setReservationsIsOver] = useState<boolean>(
        false
    );

    useEffect(() => {
        dispatch(fetchReservations({ groupWorkoutId: id, isToggled, count: 0 }))
            .then((reservations) => {
                if (reservations.length < 99) {
                    setLoading(false);
                    setReservationsIsOver(true);
                } else {
                    setLoading(false);
                    setReservationsIsOver(false);
                }
            })
            .catch(() => {
                cogoToast.error('Ошибка при загрузке бронирований', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            })
            .finally(() => setFetching(false));
    }, [dispatch, selectedGymId, isToggled]);

    const scrollHandler = (event: Event) => {
        if (
            (event.target as Document).documentElement.scrollHeight -
                ((event.target as Document).documentElement.scrollTop +
                    window.innerHeight) <
                500 &&
            (event.target as Document).documentElement.scrollTop > 1000
        ) {
            setFetching(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    useEffect(() => {
        if (fetching && !reservationsIsOver) {
            setLoading(true);
            dispatch(
                fetchScrollReservations({
                    groupWorkoutId: id,
                    isToggled,
                    count: reservations.length,
                })
            )
                .then((reservations) => {
                    if (reservations.length < 99) {
                        setLoading(false);
                        setReservationsIsOver(true);
                    } else {
                        setLoading(false);
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при загрузке бронирований', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => setFetching(false));
        }
    }, [dispatch, selectedGymId, fetching]);

    const goToGroupWorkout = useCallback(
        () => history.push(`/dashboard/group-workouts/${id}`),
        [history]
    );

    const goToCalendar = useCallback(
        () => history.push(`/dashboard/group-workouts/${id}/calendar`),
        [history]
    );

    return (
        <div>
            <BackButton title="Все тренировки" className={styles.backBtn} />
            <div className={styles.actions}>
                <div className={styles.nav}>
                    <BoxButton
                        icon=""
                        title="Общая информация"
                        onClick={goToGroupWorkout}
                        className={styles.actionBtn}
                    />
                    <BoxButton
                        icon=""
                        title="Календарь"
                        onClick={goToCalendar}
                        className={styles.actionBtn}
                    />
                    <BoxButton
                        icon=""
                        title="Бронирования"
                        className={styles.actionNav}
                    />
                </div>
                <div className={styles.toggle}>
                    <p className={styles.toggleTitle}>
                        Показать все бронирования
                    </p>

                    <Toggle
                        isToggled={isToggled}
                        onToggle={() => {
                            setIsToggled(!isToggled);
                        }}
                    />
                </div>
            </div>
            <Box>
                <div className={styles.tableWrapper}>
                    <ReservationsListTable
                        reservations={reservations}
                        loading={loading}
                    />
                </div>
            </Box>
        </div>
    );
};

export default ReservationsList;
