import React from 'react';
import moment from 'moment';
import { useTable, usePagination, useSortBy, Column } from 'react-table';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppUser } from '@t/app-user';
import { DATE_TIME_FORMAT } from '@config';

import styles from './styles.module.css';

export interface Props {
    appUsers: AppUser[];
}

const columns: Column<AppUser>[] = [
    {
        Header: 'Имя',
        accessor: 'firstName',
    },
    {
        Header: 'Фамилия',
        accessor: 'lastName',
    },
    {
        Header: 'Email',
        accessor: 'email',
    },
    {
        Header: 'Телефон',
        accessor: 'phoneNumber',
    },
    {
        Header: 'Баллы',
        accessor: 'bonusPoints',
    },
    {
        Header: 'Версия приложения',
        accessor: 'applicationVersion',
    },
    {
        Header: 'Дата регистрации',
        accessor: (appUser: AppUser) =>
            moment(appUser.createdAt).format(DATE_TIME_FORMAT),
    },
];

export const AppUserListTable: React.FC<Props> = ({ appUsers }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable(
        {
            columns,
            data: appUsers || [],
            initialState: {
                pageIndex: 0,
                pageSize: 1000,
            },
        },
        useSortBy,
        usePagination
    );
    const history = useHistory();

    return (
        <>
            <table {...getTableProps()} className={styles.table}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={Math.random()}
                        >
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                    key={Math.random()}
                                >
                                    {column.render('Header')}
                                    <span className={styles.sorted}>
                                        <FontAwesomeIcon
                                            icon={
                                                column.isSorted
                                                    ? column.isSortedDesc
                                                        ? 'sort-up'
                                                        : 'sort-down'
                                                    : 'sort'
                                            }
                                        />
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        const onClick = () => {
                            const appUser = row.original;
                            history.push(`/dashboard/app-users/${appUser._id}`);
                        };
                        return (
                            <tr
                                {...row.getRowProps()}
                                className={styles.tableRow}
                                onClick={onClick}
                                key={Math.random()}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            key={Math.random()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};

export default AppUserListTable;
