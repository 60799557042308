import React from 'react';
import classNames from 'classnames';

import Gym from '@components/Icons/Gym';
import Spreadsheet from '@components/Icons/Spreadsheet';

import styles from './styles.module.css';

export interface Props {
    className?: string;
    buttonType?: 'primary' | 'secondary';
    onClick?: () => void;
    icon?: string;
    title: string;
    disabled?: boolean;
}

const iconsMap: { [key: string]: any } = {
    gym: Gym,
    spreadsheet: Spreadsheet,
};

const BoxButton: React.FC<Props> = ({
    icon,
    title,
    className,
    onClick,
    disabled,
}) => {
    const Icon = icon ? iconsMap[icon] : null;

    return (
        <button
            type="button"
            className={classNames(styles.btn, className)}
            onClick={onClick}
            disabled={disabled}
        >
            {Icon && (
                <div className={styles.iconWrapper}>
                    <Icon className={styles.icon} />
                </div>
            )}
            <span className={styles.title}>{title}</span>
        </button>
    );
};

export default BoxButton;
