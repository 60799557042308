import React from 'react';
import { IconProps } from './types';

export const Shape: React.FC<IconProps> = ({ ...props }) => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_1126_13616)">
            <path
                d="M13.9999 7.2207C11.6365 7.2207 9.72064 9.1366 9.72064 11.5C9.72064 13.8634 11.6365 15.7793 13.9999 15.7793C16.3633 15.7793 18.2792 13.8634 18.2792 11.5C18.2792 9.1366 16.3633 7.2207 13.9999 7.2207Z"
                fill="#F07C2D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.09906 11.4612C3.33702 8.26391 3.456 6.66525 5.06063 5.06063C6.66525 3.45601 8.26391 3.33703 11.4612 3.09906C12.2901 3.03738 13.145 3 14 3C14.855 3 15.7099 3.03738 16.5388 3.09906C19.7361 3.33702 21.3348 3.456 22.9394 5.06063C24.544 6.66525 24.663 8.26391 24.9009 11.4612C24.9626 12.2901 25 13.145 25 14C25 14.855 24.9626 15.7099 24.9009 16.5388C24.663 19.7361 24.544 21.3348 22.9394 22.9394C21.3348 24.544 19.7361 24.663 16.5388 24.9009C15.7099 24.9626 14.855 25 14 25C13.145 25 12.2901 24.9626 11.4612 24.9009C8.26391 24.663 6.66525 24.544 5.06063 22.9394C3.45601 21.3348 3.33703 19.7361 3.09906 16.5388C3.03738 15.7099 3 14.855 3 14C3 13.145 3.03738 12.2901 3.09906 11.4612ZM11.6097 5.09355C12.3975 5.03491 13.2023 5 14 5C14.7977 5 15.6025 5.03491 16.3903 5.09355C19.722 5.34151 20.5272 5.47689 21.5252 6.47484C22.5231 7.47279 22.6585 8.27798 22.9065 11.6097C22.9651 12.3975 23 13.2023 23 14C23 14.7977 22.9651 15.6025 22.9065 16.3903C22.7986 17.839 22.7121 18.8101 22.5667 19.5269C18.9214 18.6024 16.9966 18.1237 15.0383 18.0259C14.6924 18.0086 14.3462 18 14 18C13.6557 18 13.3115 18.0086 12.9675 18.0257C11.0091 18.1233 9.08249 18.6021 5.43335 19.5271C5.28792 18.8102 5.20138 17.8391 5.09355 16.3903C5.03491 15.6025 5 14.7977 5 14C5 13.2023 5.03491 12.3975 5.09355 11.6097C5.34151 8.27798 5.47689 7.47279 6.47484 6.47484C7.47279 5.47689 8.27798 5.34151 11.6097 5.09355Z"
                fill="#F07C2D"
            />
        </g>
        <defs>
            <clipPath id="clip0_1126_13616">
                <rect width="28" height="28" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default Shape;
