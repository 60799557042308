import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import moment from 'moment';

import {
    getFilterBookingsCount,
    getFilterBookingsTotalPrice,
} from '@redux/modules/bookings/selectors';
import {
    getAllBookingsCount,
    getActiveBookingsCount,
    getTotalRavenues,
    getDailyStatisticsLastUpdated,
    getAllStatisticsLastUpdated,
} from '@redux/modules/dashboard/selectors';

import styles from './styles.module.css';

const getTimeFromNow = (timestamp: number) => {
    if (!timestamp) {
        return 'Не обновлялось';
    }

    return moment(timestamp).fromNow();
};

type BookingType = 'active' | 'all' | 'selected' | 'selectedPrice' | 'price';

export interface Props {
    bookingType: BookingType;
    className?: string;
}

export const BookingCounter: React.FC<Props> = ({ bookingType, className }) => {
    const [dailyLastUpdated, setDailyLastUpdated] = useState('');
    const [allLastUpdated, setAllLastUpdated] = useState('');

    const getCount = {
        active: getActiveBookingsCount(),
        all: getAllBookingsCount(),
        selected: getFilterBookingsCount(),
        price: getTotalRavenues(),
        selectedPrice: getFilterBookingsTotalPrice(),
    }[bookingType];

    const title = {
        active: 'Активных тренировок:',
        all: 'Всего тренировок:',
        selected: 'Всего тренировок за период:',
        price: 'Общая выручка:',
        selectedPrice: 'Выручка за период:',
    }[bookingType];

    const count = useSelector(getCount);
    const dailyStatisticsLastUpdated = useSelector(
        getDailyStatisticsLastUpdated
    );
    const allStatisticsLastUpdated = useSelector(getAllStatisticsLastUpdated);

    // update timer lastUpdated title every 1 minute
    useEffect(() => {
        setDailyLastUpdated(getTimeFromNow(dailyStatisticsLastUpdated));
        setAllLastUpdated(getTimeFromNow(allStatisticsLastUpdated));

        const interval = setInterval(() => {
            setDailyLastUpdated(getTimeFromNow(dailyStatisticsLastUpdated));
            setAllLastUpdated(getTimeFromNow(allStatisticsLastUpdated));
        }, 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, [dailyStatisticsLastUpdated, allStatisticsLastUpdated]);

    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.note}>
                {bookingType === 'selected' || bookingType === 'selectedPrice'
                    ? dailyLastUpdated
                    : allLastUpdated}
            </div>
            <h4 className={styles.subtitle}>{title}</h4>
            <h1
                className={classNames(
                    styles.title,
                    bookingType === 'price' && styles.titlePrice,
                    bookingType === 'selectedPrice' && styles.titlePrice
                )}
            >
                {(bookingType === 'all' ||
                    bookingType === 'active' ||
                    bookingType === 'selected') &&
                    count}
                {(bookingType === 'price' || bookingType === 'selectedPrice') &&
                    count.toFixed(1)}
                {(bookingType === 'price' && '₽') ||
                    (bookingType === 'selectedPrice' && '₽')}
            </h1>
        </div>
    );
};

export default BookingCounter;
