import { CorporateDomains, User } from '@t/user';

export const FETCH_MANAGERS_REQUEST = 'FETCH_MANAGERS_REQUEST';
export const FETCH_MANAGERS_SUCCESS = 'FETCH_MANAGERS_SUCCESS';
export const FETCH_MANAGERS_ERROR = 'FETCH_MANAGERS_ERROR';

export const FETCH_SCROLL_MANAGERS_REQUEST = 'FETCH_SCROLL_MANAGERS_REQUEST';
export const FETCH_SCROLL_MANAGERS_SUCCESS = 'FETCH_SCROLL_MANAGERS_SUCCESS';
export const FETCH_SCROLL_MANAGERS_ERROR = 'FETCH_SCROLL_MANAGERS_ERROR';

export const FETCH_MANAGER_REQUEST = 'FETCH_MANAGER_REQUEST';
export const FETCH_MANAGER_SUCCESS = 'FETCH_MANAGER_SUCCESS';
export const FETCH_MANAGER_ERROR = 'FETCH_MANAGER_ERROR';

export const DELETE_MANAGER_REQUEST = 'DELETE_MANAGER_REQUEST';
export const DELETE_MANAGER_SUCCESS = 'DELETE_MANAGER_SUCCESS';
export const DELETE_MANAGER_ERROR = 'DELETE_MANAGER_ERROR';

export const UPDATE_MANAGER_REQUEST = 'UPDATE_MANAGER_REQUEST';
export const UPDATE_MANAGER_SUCCESS = 'UPDATE_MANAGER_SUCCESS';
export const UPDATE_MANAGER_ERROR = 'UPDATE_MANAGER_ERROR';

export const DELETE_MANAGER_GYM_REQUEST = 'DELETE_MANAGER_GYM_REQUEST';
export const DELETE_MANAGER_GYM_SUCCESS = 'DELETE_MANAGER_GYM_SUCCESS';
export const DELETE_MANAGER_GYM_ERROR = 'DELETE_MANAGER_GYM_ERROR';

export const FETCH_MANAGER_CORPORATE_DOMAINS_REQUEST =
    'FETCH_MANAGER_CORPORATE_DOMAINS_REQUEST';
export const FETCH_MANAGER_CORPORATE_DOMAINS_SUCCESS =
    'FETCH_MANAGER_CORPORATE_DOMAINS_SUCCESS';
export const FETCH_MANAGER_CORPORATE_DOMAINS_ERROR =
    'FETCH_MANAGER_CORPORATE_DOMAINS_ERROR';

export const FETCH_MANAGERS_LIST_REQUEST = 'FETCH_MANAGERS_LIST_REQUEST';
export const FETCH_MANAGERS_LIST_SUCCESS = 'FETCH_MANAGERS_LIST_SUCCESS';
export const FETCH_MANAGERS_LIST_ERROR = 'FETCH_MANAGERS_LIST_ERROR';

export const SELECT_MANAGER = 'SELECT_MANAGER';

export interface ManagerState {
    ids: string[];
    byId: {
        [id: string]: User;
    };
    corporateDomains: CorporateDomains[];
    managersList: User[];
    selectedManagerId: string;
    lastUpdated: number;
}
