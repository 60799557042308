export const FETCH_CONTACTS_REQUEST = 'FETCH_CONTACTS_REQUEST';
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_ERROR = 'FETCH_CONTACTS_ERROR';

export const UPDATE_CONTACTS_REQUEST = 'UPDATE_CONTACTS_REQUEST';
export const UPDATE_CONTACTS_SUCCESS = 'UPDATE_CONTACTS_SUCCESS';
export const UPDATE_CONTACTS_ERROR = 'UPDATE_CONTACTS_ERROR';

export interface ContactsState {
    phone: string[];
    email: string[];
    tg: string[];
    vk: string[];
}
