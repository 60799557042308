import React from 'react';
import { IconProps } from './types';

export const QuestionMark: React.FC<IconProps> = ({ ...props }) => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6.40774 4.14689C7.05851 4.0791 8.02408 4 9.00004 4C9.86696 4 10.7248 4.06239 11.36 4.12359L11.4474 4.13171C11.8638 4.16966 12.4242 4.22073 12.9199 4.66451C13.4119 5.10494 13.5123 5.59075 13.5887 5.96032L13.6 6.01443C13.6607 6.30408 13.7059 6.63964 13.7059 7C13.7059 7.33166 13.6676 7.64752 13.6154 7.92583L13.6019 7.99903C13.5275 8.40582 13.4274 8.95265 12.9029 9.41294C12.3748 9.8764 11.7515 9.90681 11.2896 9.92935C11.2534 9.93112 11.2181 9.93284 11.184 9.93472L11.0515 9.94182L10 10C10 10 10 11 9.00001 11C8.23325 11 8.05441 10.4121 8.0127 10.1378C8.01898 10.014 8.02836 9.8961 8.04237 9.78597C8.08955 9.41512 8.20429 8.98359 8.54938 8.61999C8.89923 8.25137 9.33448 8.11673 9.70339 8.05149C10.041 7.99179 10.4569 7.97011 10.8949 7.94727C11.0707 7.93792 11.2468 7.93063 11.4222 7.9157C11.4973 7.90931 11.5464 7.90291 11.5793 7.89715C11.5839 7.88038 11.5895 7.85802 11.5962 7.82842C11.6117 7.75957 11.6271 7.67786 11.6496 7.55762C11.6847 7.3706 11.7059 7.18085 11.7059 7C11.7059 6.80465 11.6811 6.60883 11.6425 6.42451C11.6208 6.32088 11.6068 6.25477 11.593 6.19968C11.5895 6.18546 11.5863 6.17372 11.5837 6.1641C11.5592 6.15907 11.5225 6.15283 11.4668 6.14571C11.3884 6.13569 11.2966 6.12675 11.1682 6.11438C10.5683 6.05657 9.7796 6 9.00004 6C8.1207 6 7.23096 6.07196 6.61495 6.13612C6.5519 6.14269 6.509 6.1472 6.47237 6.15171C6.41463 6.15882 6.41272 6.19763 6.40429 6.2296C6.30072 6.67147 5.90411 7.00049 5.43066 7.00049C4.87838 7.00049 4.43066 6.55277 4.43066 6.00049C4.43066 5.90695 4.44351 5.81641 4.46753 5.73053C4.517 5.54178 4.5765 5.31482 4.77123 5.02925C4.88042 4.86913 5.02094 4.73383 5.12637 4.64341C5.23179 4.553 5.38694 4.43475 5.56183 4.35124C5.87651 4.20099 6.1532 4.17283 6.36717 4.15105L6.40774 4.14689Z"
            fill="#B5B2B0"
        />
        <path
            d="M9.00009 12.2513C9.68981 12.2513 10.249 12.8104 10.249 13.5002C10.249 14.1899 9.68981 14.749 9.00009 14.749C8.31036 14.749 7.75122 14.1899 7.75122 13.5002C7.75122 12.8104 8.31036 12.2513 9.00009 12.2513Z"
            fill="#B5B2B0"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.68597 1.68597C0.373095 2.99884 0.275748 4.30684 0.0810531 6.92282C0.0305833 7.60095 0 8.30048 0 9C0 9.69952 0.0305834 10.399 0.0810532 11.0772C0.275748 13.6932 0.373095 15.0012 1.68597 16.314C2.99884 17.6269 4.30684 17.7243 6.92282 17.9189C7.60095 17.9694 8.30048 18 9 18C9.69952 18 10.399 17.9694 11.0772 17.9189C13.6932 17.7243 15.0012 17.6269 16.314 16.314C17.6269 15.0012 17.7243 13.6932 17.9189 11.0772C17.9694 10.399 18 9.69952 18 9C18 8.30048 17.9694 7.60095 17.9189 6.92282C17.7243 4.30684 17.6269 2.99884 16.314 1.68597C15.0012 0.373095 13.6932 0.275748 11.0772 0.0810531C10.399 0.0305833 9.69952 0 9 0C8.30048 0 7.60095 0.0305834 6.92282 0.0810532C4.30684 0.275748 2.99884 0.373095 1.68597 1.68597ZM9 2C8.35779 2 7.70842 2.02812 7.07126 2.07554C4.3209 2.28023 3.80638 2.39398 3.10018 3.10018C2.39398 3.80638 2.28023 4.3209 2.07554 7.07126C2.02812 7.70842 2 8.35779 2 9C2 9.64221 2.02812 10.2916 2.07554 10.9287C2.28023 13.6791 2.39398 14.1936 3.10018 14.8998C3.80638 15.606 4.3209 15.7198 7.07126 15.9245C7.70842 15.9719 8.35779 16 9 16C9.64221 16 10.2916 15.9719 10.9287 15.9245C13.6791 15.7198 14.1936 15.606 14.8998 14.8998C15.606 14.1936 15.7198 13.6791 15.9245 10.9287C15.9719 10.2916 16 9.64221 16 9C16 8.35779 15.9719 7.70842 15.9245 7.07126C15.7198 4.3209 15.606 3.80638 14.8998 3.10018C14.1936 2.39398 13.6791 2.28023 10.9287 2.07554C10.2916 2.02812 9.64221 2 9 2Z"
            fill="#B5B2B0"
        />
    </svg>
);

export default QuestionMark;
