import React from 'react';
import classNames from 'classnames';
import Increase from '@components/Icons/Increase';
import styles from './styles.module.css';

interface DownloadButtonProps {
    title: string;
    onClick: () => void;
    contentContainerStyles?: string;
}

export const DownloadButton = ({
    title,
    onClick,
    contentContainerStyles,
}: DownloadButtonProps) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className={classNames(styles.btn, contentContainerStyles)}
        >
            <span className={styles.title}>{title}</span>
            <Increase className={styles.icon} />
        </button>
    );
};
