import React from 'react';
import { IconProps } from './types';

export const GoGym: React.FC<IconProps> = ({ ...props }) => (
    <svg
        width="142"
        height="36"
        viewBox="0 0 142 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M0.305195 9.20596C0.565284 4.63275 4.21045 1.01429 8.78491 0.777028C11.6919 0.626255 14.9107 0.5 17.5388 0.5C20.1486 0.5 23.3409 0.6245 26.2319 0.773878C30.8331 1.01163 34.4889 4.66877 34.7258 9.27008C34.8753 12.1721 35 15.3791 35 18C35 20.6209 34.8753 23.8279 34.7258 26.7299C34.4889 31.3312 30.8331 34.9884 26.2319 35.2261C23.3409 35.3755 20.1486 35.5 17.5388 35.5C14.9107 35.5 11.6919 35.3737 8.7849 35.223C4.21045 34.9857 0.565284 31.3672 0.305195 26.794C0.139209 23.8755 0 20.6406 0 18C0 15.3594 0.139209 12.1245 0.305195 9.20596Z"
            fill="#F07C2D"
        />
        <path
            d="M21.7471 5.8667L23.4361 6.96742V8.92332L12.6015 15.3583V22.5045L18.9519 26.2639L22.3298 24.1471V14.9688L17.1448 17.9492V19.7527L20.6747 21.9542L20.7507 23.7576L18.9519 24.8584L13.6993 21.7171L13.6233 16.0695L24.0357 10.024L25.8428 11.0486V26.2893L18.8675 30.1334L9.08008 24.6213V13.2415L21.7471 5.8667Z"
            fill="#FEFEFE"
        />
        <path
            d="M56.6675 19.2891C56.6665 19.2187 56.6855 19.1495 56.7223 19.0895C56.7591 19.0295 56.8122 18.9811 56.8754 18.9501L58.6916 18.0315C58.8005 17.9882 58.9219 17.9882 59.0308 18.0315L60.847 18.9501C60.9102 18.9811 60.9633 19.0295 61.0001 19.0895C61.0369 19.1495 61.0559 19.2187 61.0549 19.2891V26.2881C59.9996 27.0265 58.8269 27.5811 57.5866 27.9285C56.3156 28.3088 54.9964 28.504 53.6697 28.5082C51.9497 28.5523 50.2448 28.177 48.7025 27.4145C47.2646 26.6634 46.0638 25.5275 45.2343 24.1337C44.3823 22.7231 43.9461 21.1006 43.9761 19.4531C43.962 17.7933 44.4171 16.1633 45.289 14.7506C46.1257 13.3714 47.3357 12.2567 48.7791 11.5355C50.3225 10.7477 52.0354 10.3497 53.7682 10.3762C55.2667 10.36 56.7537 10.6387 58.1446 11.1964C59.2885 11.6697 60.3181 12.3818 61.1643 13.2852C61.2338 13.3549 61.2728 13.4493 61.2728 13.5477C61.2728 13.6461 61.2338 13.7405 61.1643 13.8102L58.5494 16.1505C58.4765 16.2181 58.3808 16.2557 58.2813 16.2557C58.1819 16.2557 58.0861 16.2181 58.0133 16.1505C57.4992 15.6149 56.8828 15.1879 56.2006 14.8948C55.5184 14.6018 54.7843 14.4486 54.0417 14.4444C53.3676 14.4161 52.6949 14.5257 52.0646 14.7664C51.4344 15.0071 50.86 15.3739 50.3765 15.8443C49.9089 16.3196 49.545 16.8867 49.3077 17.5098C49.0704 18.1328 48.965 18.7982 48.9979 19.4641C48.9973 20.3602 49.2271 21.2414 49.6653 22.0231C50.0754 22.7628 50.681 23.3757 51.4159 23.7947C52.2127 24.2207 53.1055 24.4353 54.0089 24.4181C54.9374 24.4213 55.8556 24.2236 56.7004 23.8385L56.6675 19.2891Z"
            fill="#1C1C1C"
        />
        <path
            d="M72.8058 28.5075C71.0614 28.5329 69.3372 28.1311 67.7839 27.3373C66.3424 26.5791 65.1327 25.4455 64.2828 24.0565C63.4434 22.649 63.0116 21.0362 63.0355 19.3978C62.9977 17.7659 63.4238 16.1567 64.2643 14.7571C65.1048 13.3576 66.3252 12.225 67.7839 11.491C69.3402 10.711 71.0654 10.3278 72.8058 10.3756C74.5439 10.3433 76.2626 10.7455 77.8058 11.5457C79.2594 12.2879 80.4764 13.4217 81.3194 14.8189C82.1623 16.216 82.5976 17.821 82.576 19.4524C82.5994 21.0952 82.1555 22.7108 81.2959 24.1112C80.4605 25.508 79.2518 26.6441 77.8058 27.392C76.2559 28.1681 74.5387 28.5511 72.8058 28.5075ZM72.8058 24.4174C73.6496 24.4297 74.4811 24.2143 75.2128 23.794C75.9347 23.3789 76.5289 22.7736 76.9305 22.0443C77.3553 21.2394 77.5698 20.3404 77.5541 19.4306C77.5739 18.5273 77.359 17.6343 76.9305 16.8387C76.5326 16.101 75.938 15.4878 75.2128 15.0671C74.4811 14.6468 73.6496 14.4315 72.8058 14.4437C71.9587 14.4332 71.1241 14.6484 70.3878 15.0671C69.6684 15.4916 69.0783 16.1041 68.681 16.8387C68.2526 17.6343 68.0377 18.5273 68.0574 19.4306C68.0417 20.3404 68.2563 21.2394 68.681 22.0443C69.0819 22.7706 69.6716 23.3751 70.3878 23.794C71.1241 24.2127 71.9587 24.4279 72.8058 24.4174Z"
            fill="#1C1C1C"
        />
        <path
            d="M96.7115 19.2886C96.7105 19.2182 96.7295 19.149 96.7663 19.089C96.8031 19.0289 96.8562 18.9806 96.9194 18.9496L98.7137 18.0309C98.8262 17.987 98.9512 17.987 99.0638 18.0309L100.88 18.9496C100.942 18.9825 100.993 19.0313 101.03 19.0909C101.066 19.1505 101.086 19.2187 101.088 19.2886V26.2876C100.03 27.0219 98.8583 27.5762 97.6196 27.928C96.3484 28.3076 95.0294 28.5028 93.7027 28.5076C91.9792 28.5518 90.2707 28.1765 88.7246 27.414C87.2866 26.6629 86.0858 25.527 85.2563 24.1332C84.4119 22.7194 83.9763 21.0991 83.9981 19.4526C83.9729 17.7988 84.4085 16.1706 85.2563 14.7501C86.0958 13.3731 87.305 12.2592 88.7465 11.5349C90.3102 10.7384 92.0465 10.3402 93.8012 10.3757C95.2999 10.3569 96.7875 10.6357 98.1776 11.1959C99.3245 11.6637 100.355 12.3766 101.197 13.2847C101.267 13.3544 101.306 13.4488 101.306 13.5472C101.306 13.6456 101.267 13.7399 101.197 13.8096L98.5933 16.1499C98.5187 16.2183 98.4211 16.2563 98.3198 16.2563C98.2185 16.2563 98.1209 16.2183 98.0463 16.1499C97.5283 15.6127 96.9059 15.1872 96.2172 14.8994C95.5285 14.6116 94.7882 14.4678 94.0419 14.4767C93.3754 14.4467 92.7097 14.5524 92.0852 14.7874C91.4608 15.0223 90.8907 15.3817 90.4095 15.8437C89.95 16.3171 89.5925 16.8797 89.3591 17.4967C89.1258 18.1136 89.0215 18.7719 89.0528 19.4307C89.0385 20.3335 89.253 21.2251 89.6765 22.0226C90.0909 22.7627 90.7001 23.3754 91.4379 23.7942C92.2308 24.2202 93.12 24.4349 94.02 24.4176C94.9486 24.4218 95.867 24.224 96.7115 23.838V19.2886Z"
            fill="#1C1C1C"
        />
        <path
            d="M113.123 21.8912V27.7748C113.123 27.8763 113.083 27.9736 113.011 28.0454C112.939 28.1172 112.842 28.1575 112.74 28.1575H108.517C108.415 28.1575 108.318 28.1172 108.246 28.0454C108.174 27.9736 108.134 27.8763 108.134 27.7748V21.8256L101.777 11.2833C101.743 11.226 101.724 11.1607 101.723 11.0939C101.721 11.0271 101.738 10.9611 101.77 10.9025C101.802 10.8439 101.849 10.7947 101.906 10.7598C101.963 10.725 102.028 10.7056 102.095 10.7037H106.471C106.537 10.7037 106.602 10.7208 106.66 10.7534C106.717 10.786 106.765 10.8329 106.799 10.8896L110.858 17.6371L114.917 10.8896C114.951 10.8321 114.998 10.7846 115.056 10.7519C115.114 10.7193 115.179 10.7026 115.246 10.7037H119.173C119.241 10.7037 119.308 10.7217 119.366 10.7558C119.425 10.7899 119.473 10.8389 119.506 10.8979C119.54 10.9568 119.557 11.0235 119.556 11.0912C119.555 11.1589 119.536 11.2252 119.502 11.2833L113.123 21.8912Z"
            fill="#1C1C1C"
        />
        <path
            d="M137.292 28.1577C137.19 28.1577 137.093 28.1173 137.021 28.0455C136.949 27.9738 136.909 27.8764 136.909 27.7749V18.9058L132.532 26.2548C132.499 26.3086 132.452 26.3529 132.397 26.3835C132.341 26.4141 132.279 26.4301 132.215 26.4298H130.432C130.367 26.4309 130.302 26.4154 130.245 26.3848C130.188 26.3542 130.139 26.3094 130.104 26.2548L125.727 19.1573V27.7749C125.727 27.8764 125.687 27.9738 125.615 28.0455C125.543 28.1173 125.446 28.1577 125.344 28.1577H121.515C121.413 28.1577 121.316 28.1173 121.244 28.0455C121.172 27.9738 121.132 27.8764 121.132 27.7749V11.0865C121.13 11.0359 121.139 10.9854 121.158 10.9383C121.177 10.8912 121.205 10.8484 121.241 10.8125C121.277 10.7767 121.319 10.7485 121.367 10.7298C121.414 10.7111 121.464 10.7023 121.515 10.7038H125.016C125.079 10.7035 125.142 10.7194 125.197 10.75C125.253 10.7807 125.3 10.825 125.333 10.8788L131.406 20.7758L137.325 10.9334C137.358 10.876 137.406 10.8285 137.463 10.7958C137.521 10.7631 137.586 10.7464 137.653 10.7475H141.154C141.254 10.7475 141.349 10.7863 141.421 10.8557C141.492 10.9252 141.534 11.0197 141.537 11.1193V27.8186C141.537 27.9202 141.497 28.0175 141.425 28.0893C141.353 28.1611 141.255 28.2014 141.154 28.2014L137.292 28.1577Z"
            fill="#1C1C1C"
        />
    </svg>
);

export default GoGym;
