import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchHRReports } from '@redux/modules/reports/actions';
import Box from '@components/Box';
import { getReports } from '@redux/modules/reports/selectors';

import { HRReportsListTable } from './ReportListTable';
import styles from './styles.module.css';

export const HRReportsList: React.FC = () => {
    const dispatch = useDispatch();
    const reports = useSelector(getReports);
    useEffect(() => {
        const promises = [dispatch(fetchHRReports())];

        Promise.all(promises).catch(() => {
            // TODO
        });
    }, [dispatch]);

    return (
        <div>
            <h1>Отчеты</h1>

            <Box>
                <div className={styles.tableWrapper}>
                    <HRReportsListTable reports={reports} />
                </div>
            </Box>
        </div>
    );
};

export default HRReportsList;
