import { AbortReasonType } from '@config';
import { Membership } from './membership';

export enum BookingType {
    PerMinWorkout = 'PER_MIN_WORKOUT',
    GroupWorkout = 'GROUP_WORKOUT',
    SingleWorkout = 'SINGLE_WORKOUT',
}

export interface Booking {
    _id: string;
    gymId: string;
    gymTitle: string;
    userId: string;
    mongoUserId: string;
    timeStarted: string;
    timeFinished: string;
    timeFinishedForSubscribe: string;
    price: number;
    priceBeforeCoupon: number;
    loyaltyPrice: number;
    initialPrice: number;
    initialPriceForSubscribe: number;
    specialPrice: number;
    netPrice: number;
    acquiringCommission: number;
    goGymFee: number;
    priceOfGogymFee: number;
    priceAfterCoupon: string;
    active: boolean;
    deleted: boolean;
    paid: boolean;
    type: BookingType;
    couponApplied: boolean;
    couponId: string;
    groupWorkoutReservationId: string;
    groupWorkoutReservationNewId: string;
    abortReasonType: AbortReasonType;
    abortReasonMessage: string;
    aborted: boolean;
    gogymFee: number;
    fixedPrice: number;
    createdAt: Date;
    updatedAt: Date;
    isBonusWithdrawn: boolean;
    memberships: Membership[];
    membershipId: string;
    depositTransactionId: number;
    prepayment: number;
    transactionId: number;
    receiptId: string;
    bonusPointsNum: number;
    isPaidBysubscription: boolean;
    subscriptionId: string;
    cashback: number;
}
