import React, { memo, useState } from 'react';
import { InjectedFormProps, Field, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import Select, { createFilter, MultiValue } from 'react-select';

import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import TextInput from '@components/TextInput';
import Button from '@components/Button';
import Modal from '@components/Modal';
import { tagTypeOptions } from '@t/tag';
import FileInput from '@components/FileInput';

import styles from './styles.module.css';

export interface TagFormData {
    tagType: { name: string; tagTypeValue: string }[];
    tagName: string;
    iconLink: string;
    comment: string;
}

export interface Props {
    onDeleteTag: () => void;
    disabled: boolean;
    setDisabled: (prev: boolean) => void;
    iconLink: string;
    selectedTagType: { label: string; value: string }[];
    setSelectedTagType: React.Dispatch<
        React.SetStateAction<
            {
                label: string;
                value: string;
            }[]
        >
    >;
    onDeleteIconClick: (event: any) => Promise<void>;
    fileInput: React.MutableRefObject<null>;
    selectedErrors: {
        tagError: boolean;
        tagCategoryError: boolean;
    };
    setSelectedErrors: React.Dispatch<
        React.SetStateAction<{
            tagError: boolean;
            tagCategoryError: boolean;
        }>
    >;
    onUploadFile: (event: any) => void;
    selectedTagCategories: {
        label: string;
        value: string;
    }[];
    setSelectedTagCategories: React.Dispatch<
        React.SetStateAction<
            {
                label: string;
                value: string;
            }[]
        >
    >;
    tagCategoriesOptions: {
        label: string;
        value: string;
    }[];
    groupWorkoutFilterTagType: boolean;
}

const validate = (values: TagFormData) => {
    const errors: { tagName: string; iconLink: string } = {
        tagName: '',
        iconLink: '',
    };

    if (values.tagName?.length > 30) {
        errors.tagName = 'Максимальное количество символов для ввода 30';
    }

    if (!values.tagName?.match(/^[0-9-.一 а-яА-Я]*$/)) {
        errors.tagName = 'Разрешенные символы: тире, точки, цифры';
    }

    if (!values.tagName) {
        errors.tagName = 'Обязательное поле';
    }

    if (!values.iconLink) {
        errors.iconLink = 'Обязательное поле';
    }

    return errors;
};

const TagForm: React.FC<Props & InjectedFormProps<TagFormData, Props>> = memo(
    ({
        handleSubmit,
        onDeleteTag,
        disabled,
        setDisabled,
        iconLink,
        selectedTagType,
        setSelectedTagType,
        fileInput,
        selectedErrors,
        setSelectedErrors,
        onUploadFile,
        selectedTagCategories,
        setSelectedTagCategories,
        tagCategoriesOptions,
        groupWorkoutFilterTagType,
    }) => {
        const user = useSelector(getUser);
        const isSuperAdmin = user?.role === UserRole.SuperAdmin;
        const [modalActive, setModalActive] = useState(false);

        const handleChange = (
            value: MultiValue<{ label: string; value: string }>
        ) => {
            setSelectedTagType(
                value.map((type: { label: string; value: string }) => {
                    return {
                        label: type.label,
                        value: type.value,
                    };
                })
            );
            setSelectedErrors((prevState) => ({
                ...prevState,
                tagError: false,
            }));
        };

        const onBlur = () => {
            if (selectedTagType.length === 0) {
                setSelectedErrors((prevState) => ({
                    ...prevState,
                    tagError: true,
                }));
            }
        };

        const handleChangeTagCategories = (
            value: MultiValue<{ label: string; value: string }>
        ) => {
            setSelectedTagCategories(
                value.map((type: { label: string; value: string }) => ({
                    label: type.label,
                    value: type.value,
                }))
            );
            setSelectedErrors((prevState) => ({
                ...prevState,
                tagCategoryError: false,
            }));
        };

        const onBlurTagCategories = () => {
            if (selectedTagCategories.length === 0) {
                setSelectedErrors((prevState) => ({
                    ...prevState,
                    tagCategoryError: true,
                }));
            }
        };

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <Field
                    disabled
                    name="tagId"
                    label="ID тэга"
                    component={TextInput}
                />

                <Field
                    disabled={disabled}
                    name="tagName"
                    label="Название тэга"
                    component={TextInput}
                />

                <span className={styles.label}>Тип тэга</span>
                <Select
                    className={styles.select}
                    isDisabled={disabled}
                    isMulti
                    styles={{
                        input: (provided) => ({
                            ...provided,
                            minHeight: 40,
                        }),
                    }}
                    defaultValue={selectedTagType}
                    onChange={handleChange}
                    onBlur={onBlur}
                    options={tagTypeOptions}
                    filterOption={createFilter({
                        stringify: (option) => `${option.label}`,
                    })}
                    closeMenuOnSelect={true}
                    placeholder="Тип тэга"
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: 'rgb(225, 129, 65)',
                        },
                    })}
                    noOptionsMessage={() => 'Не найдено'}
                />
                {selectedErrors.tagError && (
                    <div
                        style={{
                            fontSize: '14px',
                            marginTop: '3px',
                            color: '#ff3333',
                        }}
                    >
                        Обязательное поле
                    </div>
                )}

                {groupWorkoutFilterTagType && (
                    <>
                        <span className={styles.label}>Категории тэга</span>
                        <Select
                            className={styles.select}
                            isDisabled={disabled}
                            isMulti
                            styles={{
                                input: (provided) => ({
                                    ...provided,
                                    minHeight: 40,
                                }),
                            }}
                            defaultValue={selectedTagCategories}
                            onChange={handleChangeTagCategories}
                            onBlur={onBlurTagCategories}
                            options={tagCategoriesOptions}
                            filterOption={createFilter({
                                stringify: (option) => `${option.label}`,
                            })}
                            closeMenuOnSelect={false}
                            placeholder="Категория"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: 'rgb(225, 129, 65)',
                                },
                            })}
                            noOptionsMessage={() => 'Не найдено'}
                        />
                        {selectedErrors.tagCategoryError && (
                            <div
                                style={{
                                    fontSize: '14px',
                                    marginTop: '3px',
                                    color: '#ff3333',
                                }}
                            >
                                Обязательное поле
                            </div>
                        )}
                    </>
                )}

                <span className={styles.label}>Иконка</span>
                {iconLink && (
                    <div className={styles.row}>
                        <img src={iconLink} className={styles.tagIcon} />
                    </div>
                )}

                <FileInput
                    disabled={disabled}
                    name="iconLink"
                    label="Загрузка иконки"
                    accept=".svg"
                    fileInput={fileInput}
                    onChange={onUploadFile}
                />

                <Field
                    disabled={disabled}
                    name="comment"
                    label="Описание"
                    component={TextInput}
                />

                {isSuperAdmin && !disabled && (
                    <Button type="submit" className={styles.btn}>
                        Обновить
                    </Button>
                )}

                {isSuperAdmin && disabled && (
                    <Button
                        type="button"
                        onClick={() => setDisabled(false)}
                        className={styles.btn}
                    >
                        Изменить
                    </Button>
                )}

                <Modal active={modalActive} setActive={setModalActive}>
                    <h3>Вы действительно хотите удалить тэг?</h3>
                    {isSuperAdmin && (
                        <Button
                            onClick={onDeleteTag}
                            type="button"
                            className={styles.btnModal}
                        >
                            Удалить
                        </Button>
                    )}
                </Modal>

                {isSuperAdmin && (
                    <Button
                        onClick={() => setModalActive(true)}
                        type="button"
                        className={styles.btn}
                    >
                        Удалить
                    </Button>
                )}
            </form>
        );
    }
);

export default reduxForm<TagFormData, Props>({
    form: 'updateTag',
    validate,
})(TagForm);
