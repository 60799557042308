import React from 'react';
import styles from './styles.module.css';

const Loader: React.FC = () => (
    <div style={{ display: 'flex', justifyContent: 'center', margin: '1rem' }}>
        <div className={styles.ldsRing}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
);

export default Loader;
