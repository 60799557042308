import React, { useState, useRef } from 'react';
import {
    InjectedFormProps,
    Field,
    reduxForm,
    formValueSelector,
} from 'redux-form';
import { useSelector } from 'react-redux';
import moment from 'moment';

import TextInput from '@components/TextInput';
import Button from '@components/Button';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import SelectInput from '@components/SelectInput';
import { CouponType, CouponTypeOptions } from '@t/coupon';
import TextAreaInput from '@components/TextAreaInput';
import { ApplicationState } from '@redux/types';
import MultiSelectInput from '@components/MultiSelectInput';
import CheckBoxInput from '@components/CheckBoxInput';
import DateTimePickerInput from '@components/DateTimePickerInput';

import styles from './styles.module.css';

export interface FormData {
    couponType: CouponType;
    countCoupons: string;
    prefix: string;
    lengthAfterPrefix: string;
    discount: number;
    activatedStart: Date;
    activatedEnd: Date;
    activatedCount: number;
    actionStart: string | Date;
    actionEnd: string | Date;
    adCampaignId: string;
    unlimited?: boolean;
    limitPerUser?: number;
    gymIds?: string[];
    initialPrice?: number;
    comment: string;
    perMinWorkouts: boolean;
    groupWorkouts: boolean;
}

export interface Props {
    gymsOptions: {
        title: string;
        value: string;
    }[];
    couponsAdCampaignsOptions: {
        title: string;
        value: string;
    }[];
}

const validate = (values: FormData) => {
    const errors: {
        couponType: string;
        countCoupons: string;
        prefix: string;
        lengthAfterPrefix: string;
        discount: string;
        limitPerUser: string;
        initialPrice: string;
        activatedCount: string;
        active: string;
        actionStart: string;
        actionEnd: string;
        perMinWorkouts: string;
        groupWorkouts: string;
        comment: string;
    } = {
        couponType: '',
        countCoupons: '',
        prefix: '',
        lengthAfterPrefix: '',
        discount: '',
        limitPerUser: '',
        initialPrice: '',
        activatedCount: '',
        active: '',
        actionStart: '',
        actionEnd: '',
        perMinWorkouts: '',
        groupWorkouts: '',
        comment: '',
    };

    if (!values.couponType) {
        errors.couponType = 'Обязательное поле';
    }

    if (
        values.countCoupons &&
        (!Number.isInteger(Number(values.countCoupons)) ||
            Number(values.countCoupons) <= 0 ||
            String(values.countCoupons).replace(/^\d+$/g, '').length)
    ) {
        errors.countCoupons = 'Должно быть целым положительным числом';
    }

    if (!values.countCoupons) {
        errors.countCoupons = 'Обязательное поле';
    }

    if (
        values.lengthAfterPrefix &&
        (!Number.isInteger(Number(values.lengthAfterPrefix)) ||
            Number(values.lengthAfterPrefix) <= 0 ||
            String(values.lengthAfterPrefix).replace(/^\d+$/g, '').length)
    ) {
        errors.lengthAfterPrefix = 'Должно быть целым положительным числом';
    }

    if (!values.lengthAfterPrefix) {
        errors.lengthAfterPrefix = 'Обязательное поле';
    }

    if (!values.prefix) {
        errors.prefix = 'Обязательное поле';
    }

    if (!values.actionStart) {
        errors.actionStart = 'Обязательное поле';
    }

    if (!values.actionEnd) {
        errors.actionEnd = 'Обязательное поле';
    }

    if (moment(values.actionEnd).isBefore(moment(values.actionStart))) {
        errors.actionEnd = 'Не может быть меньше даты начала действия';
    }

    if (moment(values.actionStart).isBefore(moment().startOf('day'))) {
        errors.actionStart = 'Должно быть больше текущего времени';
    }

    if (
        values.discount &&
        (isNaN(values.discount) || values.discount < 0.1 || values.discount > 1)
    ) {
        errors.discount = 'Должно быть числом от 0.1 до 1';
    }

    if (!values.discount) {
        errors.discount = 'Обязательное поле';
    }

    if (
        values.limitPerUser &&
        (!Number.isInteger(Number(values.limitPerUser)) ||
            Number(values.limitPerUser) <= 0 ||
            String(values.limitPerUser).replace(/^\d+$/g, '').length)
    ) {
        errors.limitPerUser = 'Должно быть целым положительным числом';
    }

    if (!values.limitPerUser) {
        errors.limitPerUser = 'Обязательное поле';
    }

    if (
        values.activatedCount &&
        (!Number.isInteger(Number(values.activatedCount)) ||
            Number(values.activatedCount) <= 0 ||
            String(values.activatedCount).replace(/^\d+$/g, '').length)
    ) {
        errors.activatedCount = 'Должно быть целым положительным числом';
    }

    if (!values.activatedCount) {
        errors.activatedCount = 'Обязательное поле';
    }

    if (isNaN(Number(values.initialPrice))) {
        errors.initialPrice = 'Должно быть числом';
    }

    if (!values.comment) {
        errors.comment = 'Обязательное поле';
    }

    if (!values.perMinWorkouts && !values.groupWorkouts) {
        errors.perMinWorkouts = 'Обязательное поле';
        errors.groupWorkouts = 'Обязательное поле';
    }

    return errors;
};

const AddCouponsForm: React.FC<Props & InjectedFormProps<FormData, Props>> = ({
    handleSubmit,
    gymsOptions,
    couponsAdCampaignsOptions,
}) => {
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;

    const selector = formValueSelector('addCoupons');
    const couponTypeSelector = (state: ApplicationState) =>
        selector(state, 'couponType');
    const couponAdCampaignSelector = (state: ApplicationState) =>
        selector(state, 'adCampaignId');
    const couponType = useSelector(couponTypeSelector);
    const couponAdCampaign = useSelector(couponAdCampaignSelector);
    const isPercentOff = couponType === CouponType.percentOff;
    const isAmount = couponType === CouponType.amount;

    const [foundGyms, setFoundGyms] = useState<any>();
    const gymsSearchingField = useRef<any>();

    const handleSearchGyms = () => {
        setFoundGyms(
            gymsOptions.filter((gymsOption) =>
                gymsOption.title
                    .toLowerCase()
                    .includes(gymsSearchingField.current.value.toLowerCase())
            )
        );
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Field
                name="couponType"
                label="Тип"
                placeholder="Тип"
                emptyTitle={'Выберите тип промокодов...'}
                component={SelectInput}
                options={CouponTypeOptions}
            />

            {isAmount && (
                <Field
                    name="adCampaignId"
                    label="Рекламная кампания"
                    placeholder="Рекламная кампания"
                    emptyTitle={'Выберите рекламную кампанию...'}
                    component={SelectInput}
                    options={couponsAdCampaignsOptions}
                />
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <div className={styles.col}>
                    <Field
                        name="countCoupons"
                        label="Количество промокодов"
                        placeholder="Сколько создаем промокодов?"
                        component={TextInput}
                    />
                </div>
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <div className={styles.col}>
                    <Field
                        name="prefix"
                        label="Префикс к названию"
                        placeholder="Какой будет префикс у названия?"
                        component={TextInput}
                    />
                </div>
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <div className={styles.col}>
                    <Field
                        name="lengthAfterPrefix"
                        label="Длина названия после префикса"
                        placeholder="Какой длины будет промокод после префикса?"
                        component={TextInput}
                    />
                </div>
            )}

            {isPercentOff && (
                <Field
                    name="discount"
                    label="Скидка"
                    placeholder="Скидка в формате от 0.1 до 1"
                    component={TextInput}
                />
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <Field
                    name="limitPerUser"
                    label="Количество тренировок"
                    placeholder="Количество тренировок"
                    component={TextInput}
                />
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <Field
                    name="activatedCount"
                    label="Сколько человек может использовать"
                    placeholder="Сколько человек может использовать"
                    component={TextInput}
                />
            )}

            {isAmount && couponAdCampaign && (
                <Field
                    name="initialPrice"
                    label="Сумма промокода"
                    placeholder="Сумма промокода"
                    component={TextInput}
                />
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <Field
                    name="actionStart"
                    label="Дата начала действия"
                    placeholder="Дата начала действия"
                    component={DateTimePickerInput}
                />
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <Field
                    name="actionEnd"
                    label="Дата конца действия"
                    placeholder="Дата конца действия"
                    component={DateTimePickerInput}
                />
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && foundGyms ? (
                <Field
                    name="gymIds"
                    label="Фитнес-площадки"
                    component={MultiSelectInput}
                    options={foundGyms}
                    defaultValue={''}
                />
            ) : (
                ((isAmount && couponAdCampaign) || isPercentOff) && (
                    <Field
                        name="gymIds"
                        label="Фитнес-площадки"
                        component={MultiSelectInput}
                        options={gymsOptions}
                        defaultValue={''}
                    />
                )
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <input
                    className={styles.input}
                    type="search"
                    placeholder="Поиск по названию"
                    ref={gymsSearchingField}
                    onChange={handleSearchGyms}
                />
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <Field
                    name="comment"
                    label="Комментарий"
                    placeholder="Уникальный комментарий для промокодов"
                    component={TextAreaInput}
                />
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <Field
                    name="unlimited"
                    label="Неограниченный"
                    component={CheckBoxInput}
                />
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <Field
                    name="perMinWorkouts"
                    label="Для поминутных тренировок"
                    component={CheckBoxInput}
                />
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <Field
                    name="groupWorkouts"
                    label="Для занятий"
                    component={CheckBoxInput}
                />
            )}

            {isSuperAdmin && (
                <Button type="submit" className={styles.btn}>
                    Добавить
                </Button>
            )}
        </form>
    );
};

export default reduxForm<FormData, Props>({
    form: 'addCoupons',
    validate,
})(AddCouponsForm);
