import React from 'react';
import { useDispatch } from 'react-redux';
import { Field, FormSubmitHandler, SubmissionError } from 'redux-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import Form from '@components/Form';
import TextInput from '@components/TextInput';
import Button from '@components/Button';
import { AsyncDispatch } from '@redux/types';
import { savePassword } from '@redux/modules/auth/actions';

import styles from './styles.module.css';

export interface FormData {
    password: string;
    password2: string;
}

export const SavePassword: React.FC = () => {
    const { token } = useParams<{ token: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();

    const onSubmit: FormSubmitHandler<FormData> = async ({
        password,
        password2,
    }) => {
        if (password !== password2) {
            throw new SubmissionError({
                password2: 'Пароли не совпадают',
            });
        }
        try {
            const user = await dispatch(savePassword({ password, token }));
            if (user) {
                cogoToast.success('Пароль сохранен', {
                    position: 'top-right',
                    hideAfter: 5,
                });
                history.push('/auth/login');
            } else {
                throw new SubmissionError({});
            }
        } catch (err) {
            throw new SubmissionError({
                _error: 'Ошибка при сохранении пароля',
            });
        }
    };

    const validate = (values: FormData) => {
        const errors: { password: string } = { password: '' };
        if (!values.password) {
            errors.password = 'Обязательное поле';
        }
        return errors;
    };

    return (
        <div className={styles.container}>
            <div className={styles.box}>
                <h2 className={styles.title}>Сохранить новый пароль</h2>

                <Form
                    form="savePassword"
                    shakeOnError
                    validate={validate}
                    onSubmit={onSubmit}
                    className={styles.form}
                >
                    <Field
                        required
                        type="password"
                        name="password"
                        label="Пароль"
                        placeholder="Пароль"
                        component={TextInput}
                    />

                    <Field
                        required
                        type="password"
                        name="password2"
                        label="Повторите пароль"
                        placeholder="Повторите пароль"
                        component={TextInput}
                    />

                    <Button type="submit" className={styles.btn}>
                        Сохранить пароль
                    </Button>

                    <Link to="/" className={styles.link}>
                        У меня уже есть аккаунт
                    </Link>
                </Form>
            </div>
        </div>
    );
};

export default SavePassword;
