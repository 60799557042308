import React, { memo } from 'react';
import { InjectedFormProps, Field, reduxForm } from 'redux-form';

import TextInput from '@components/TextInput';
import { SubscriptionType, SubscriptionTypeOptions } from '@t/subscriptions';
import { BookingType } from '@t/booking';
import Button from '@components/Button';
import SelectInput from '@components/SelectInput';
import DateTimePickerInput from '@components/DateTimePickerInput';
import LeftArrow from '@components/Icons/LeftArrow';
import Modal from '@components/Modal';
import CheckBoxInput from '@components/CheckBoxInput';

import styles from './styles.module.css';

export interface SubscriptionFormData {
    userId: string;
    subscriptionId: string;
    cloudPaymentsSubscriptionId: string;
    active: boolean;
    paymentStatus: SubscriptionType;
    nextPaymentDate: string;
    workoutType: BookingType;
    dateStarted: string;
    dateFinished: string;
    finalize: boolean;
}

export interface Props {
    isFinished: boolean;
    disabled: boolean;
    setDisabled: (prev: boolean) => void;
    goToAppUserClick: () => void;
    modalActive: boolean;
    setModalActive: (prev: boolean) => void;
    deleteSubscription: () => void;
    finalize: boolean;
}

const SubscriptionsForm: React.FC<
    Props & InjectedFormProps<SubscriptionFormData, Props>
> = memo(
    ({
        handleSubmit,
        isFinished,
        disabled,
        setDisabled,
        goToAppUserClick,
        modalActive,
        setModalActive,
        deleteSubscription,
        finalize,
    }) => {
        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <Field
                    disabled
                    name="userSubscriptionId"
                    label="ID подписки пользователя"
                    component={TextInput}
                />

                <Field
                    disabled
                    name="workoutType"
                    label="Тип тренировки"
                    component={TextInput}
                />

                <div className={styles.col}>
                    <Field
                        disabled
                        name="userId"
                        label="ID пользователя"
                        component={TextInput}
                    />
                    <div
                        onClick={() => goToAppUserClick()}
                        title="Перейти к пользователю"
                        className={styles.wrap}
                    >
                        <LeftArrow className={styles.iconArrow} />
                    </div>
                </div>

                <Field
                    disabled
                    name="subscriptionId"
                    label="ID подписки"
                    component={TextInput}
                />

                <Field
                    disabled={disabled}
                    name="paymentStatus"
                    label="Статус"
                    component={SelectInput}
                    options={SubscriptionTypeOptions}
                />

                <Field
                    disabled={disabled}
                    name="dateStarted"
                    label="Дата начала"
                    component={DateTimePickerInput}
                />

                <Field
                    disabled={disabled}
                    name="nextPaymentDate"
                    label="Дата следующего платежа"
                    component={DateTimePickerInput}
                />

                <Field
                    disabled={disabled}
                    name="finalize"
                    label="Указать дату завершения?"
                    component={CheckBoxInput}
                />

                {isFinished && finalize && (
                    <Field
                        disabled={disabled}
                        name="dateFinished"
                        label="Дата завершения"
                        component={DateTimePickerInput}
                    />
                )}

                {disabled && (
                    <Button
                        type="button"
                        onClick={() => setDisabled(false)}
                        className={styles.btn}
                    >
                        Изменить
                    </Button>
                )}

                {!disabled && (
                    <Button type="submit" className={styles.btn}>
                        Обновить подписку
                    </Button>
                )}

                <Button
                    type="button"
                    onClick={() => setModalActive(true)}
                    className={styles.btn}
                >
                    Удалить
                </Button>

                <Modal active={modalActive} setActive={setModalActive}>
                    <h3>
                        Вы действительно хотите удалить подписку пользователя?
                    </h3>
                    <p>
                        Удаление подписки пользователя приведет к полному
                        удалению ее из базы данных
                    </p>

                    <Button
                        onClick={deleteSubscription}
                        type="button"
                        className={styles.btnModal}
                    >
                        Удалить
                    </Button>
                </Modal>
            </form>
        );
    }
);

export default reduxForm<SubscriptionFormData, Props>({
    form: 'subscriptionInfo',
})(SubscriptionsForm);
