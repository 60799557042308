import React from 'react';
import classNames from 'classnames';

import ModalClose from '@components/Icons/ModalClose';

import styles from './styles.module.css';

export interface Props {
    active: boolean;
    setActive: (prev: boolean) => void;
    children: React.ReactNode;
}

const ModalPhoto: React.FC<Props> = ({ active, setActive, children }) => (
    <div
        className={classNames(active ? styles.modalActive : styles.modal)}
        onClick={() => setActive(false)}
    >
        <div
            className={classNames(
                active ? styles.modalContentActive : styles.modalContent
            )}
            onClick={(e) => e.stopPropagation()}
        >
            <div
                onClick={() => {
                    setActive(false);
                }}
            >
                <ModalClose className={styles.modalClose} />
            </div>
            {children}
        </div>
    </div>
);

export default ModalPhoto;
