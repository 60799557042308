import React from 'react';
import { IconProps } from './types';

export const Spreadsheet: React.FC<IconProps> = ({ ...props }) => (
    <svg
        {...props}
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="446.969px"
        height="446.969px"
        viewBox="0 0 446.969 446.969"
        enableBackground="new 0 0 446.969 446.969;"
        xmlSpace="preserve"
    >
        <g>
            <path
                d="M430.266,23.857H16.699C7.487,23.857,0,31.354,0,40.555v365.86c0,5.654,2.841,10.637,7.155,13.663v2.632h5.989
            c1.149,0.252,2.332,0.401,3.555,0.401h413.575c1.22,0,2.401-0.149,3.545-0.401h0.821v-0.251
            c7.088-1.938,12.328-8.362,12.328-16.044V40.561C446.969,31.354,439.479,23.857,430.266,23.857z M66.006,408.396H15.459
            c-0.674-0.416-1.148-1.132-1.148-1.98v-43.35h51.695V408.396z M66.006,348.751H14.311v-47.01h51.695V348.751z M66.006,287.432
            H14.311V237.8h51.695V287.432z M66.006,223.487H14.311V169.8h51.695V223.487z M66.006,155.486H14.311v-52.493h51.695V155.486z
             M186.495,408.396H80.318v-45.33h106.176V408.396z M186.495,348.751H80.318v-47.01h106.176V348.751z M186.495,287.432H80.318V237.8
            h106.176V287.432z M186.495,223.487H80.318V169.8h106.176V223.487z M186.495,155.486H80.318v-52.493h106.176V155.486z
             M186.495,88.676H80.318v-50.51h106.176V88.676z M308.19,408.396H200.812v-45.33H308.19V408.396z M308.19,348.751H200.812v-47.01
            H308.19V348.751z M308.19,287.432H200.812V237.8H308.19V287.432z M308.19,223.487H200.812V169.8H308.19V223.487z M308.19,155.486
            H200.812v-52.493H308.19V155.486z M308.19,88.676H200.812v-50.51H308.19V88.676z M432.656,406.416c0,0.845-0.477,1.56-1.148,1.98
            H322.503v-45.33h110.153V406.416z M432.656,348.751H322.503v-47.01h110.153V348.751z M432.656,287.432H322.503V237.8h110.153
            V287.432z M432.656,223.487H322.503V169.8h110.153V223.487z M432.656,155.486H322.503v-52.493h110.153V155.486z M432.656,88.676
            H322.503v-50.51h107.763c1.312,0,2.391,1.073,2.391,2.389V88.676z M297.261,142.238h-85.921v-29.883h85.917v29.883H297.261z"
            />
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
    </svg>
);

export default Spreadsheet;
