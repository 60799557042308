import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import cogoToast from 'cogo-toast';
import { useDropzone } from 'react-dropzone';

import { AsyncDispatch } from '@redux/types';
import Image from '@components/Icons/Image';
import Downloading from '@components/Icons/Downloading';
import { ButtonNew } from '@components/ButtonNew';
import ModalIcon from '@components/ModalPhoto';
import { deleteGymIcon, uploadGymIcon } from '@redux/modules/gyms/actions';
import { getKeyFromUrl } from '@containers/Dashboard/GymDetails';

import styles from './styles.module.css';

export interface Props {
    gymId: string;
    iconSrc: string;
}

const UploadIcon: React.FC<Props> = ({ gymId, iconSrc }) => {
    const [modalActiveIcon, setModalActiveIcon] = useState(false);
    const dispatch = useDispatch<AsyncDispatch>();
    const onDrop = useCallback(
        (acceptedFiles) => {
            if (!acceptedFiles.length) {
                cogoToast.error(
                    `Можно загрузить файл с расширением png, разрешением от 48х48px и размером до 1МВ`,
                    {
                        position: 'top-right',
                        hideAfter: 4,
                    }
                );
                return;
            } else {
                const { hide } = cogoToast.loading('Загружаем иконку...', {
                    position: 'top-right',
                    hideAfter: 0,
                });
                const MAX_SIZE = 10000000;
                const numOfFiles = Array.from(acceptedFiles).length;

                for (let i = 0; i < numOfFiles; ++i) {
                    const validExtensions = ['png'];
                    const fileExtension = acceptedFiles[i].type.split('/')[1];
                    const fileSize = acceptedFiles[i].size;

                    if (!validExtensions.includes(fileExtension)) {
                        cogoToast.error(
                            `Можно загрузить иконку с расширением pdf`,
                            {
                                position: 'top-right',
                                hideAfter: 4,
                            }
                        );
                        return;
                    }

                    if (fileSize > MAX_SIZE) {
                        cogoToast.error(
                            `Нельзя загрузить иконку размера более 1MB, файл "${acceptedFiles[i].name}" слишком большой`,
                            {
                                position: 'top-right',
                                hideAfter: 4,
                            }
                        );
                        return;
                    }
                }
                setModalActiveIcon(false);

                dispatch(
                    uploadGymIcon({
                        files: acceptedFiles,
                        gymId,
                    })
                )
                    .then(() => {
                        cogoToast.success('Загрузка иконки завершена', {
                            position: 'top-right',
                            hideAfter: 4,
                        });
                    })
                    .catch(() => {
                        cogoToast.error('Ошибка при загрузке иконки', {
                            position: 'top-right',
                            hideAfter: 4,
                        });
                    })
                    .finally(() => {
                        hide && hide();
                    });
            }
        },
        [gymId, dispatch]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/png': [],
        },
    });

    const onDeleteDocsClick = useCallback(
        (event) => {
            if (!gymId) {
                return;
            }
            const { hide } = cogoToast.loading('Удаляем документы...', {
                position: 'top-right',
                hideAfter: 0,
            });
            const s3Key: string = getKeyFromUrl(event.target.id);
            dispatch(deleteGymIcon({ gymId, key: s3Key }))
                .then(() => {
                    cogoToast.success('Удаление документов завершено', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .catch(() => {
                    cogoToast.error(`Ошибка при удалении документов`, {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => {
                    hide && hide();
                });
        },
        [gymId, dispatch]
    );

    return (
        <div className={styles.section}>
            <ModalIcon active={modalActiveIcon} setActive={setModalActiveIcon}>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <>
                            <div className={styles.drop}>
                                <div className={styles.dropZone}>
                                    <div className={styles.dropTitle}>
                                        Загрузка иконки
                                    </div>
                                    <Downloading />
                                    <span className={styles.dropSubTitle}>
                                        Отпустите для загрузки файла
                                    </span>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles.drop}>
                                <div className={styles.dropZone}>
                                    <div className={styles.dropTitle}>
                                        Загрузка иконки
                                    </div>
                                    <Image />
                                    <span className={styles.dropSubTitle}>
                                        Перетащите файл в выделенную область
                                    </span>
                                    <span>
                                        или нажмите для выбора из проводника
                                    </span>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </ModalIcon>
            <div className={styles.sectionTitle}>Иконка зала</div>
            {!iconSrc && (
                <div className={styles.label}>
                    Загрузите иконку в формате PNG
                </div>
            )}
            <div className={styles.images}>
                {iconSrc && (
                    <div className={styles.rowImage}>
                        <a
                            href={iconSrc}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                id={iconSrc}
                                src={iconSrc}
                                className={styles.gymImage}
                            />
                        </a>
                        <button
                            type="button"
                            className={styles.DeleteImageBtn}
                            id={iconSrc}
                            onClick={onDeleteDocsClick}
                        >
                            &#10060;
                        </button>
                    </div>
                )}
            </div>
            {!iconSrc && (
                <ButtonNew
                    type="button"
                    appearance="plus"
                    icon="plus"
                    onClick={() => setModalActiveIcon(true)}
                >
                    Загрузить иконку
                </ButtonNew>
            )}
        </div>
    );
};

export default UploadIcon;
