import React, { useState, useRef, useCallback, memo } from 'react';
import {
    InjectedFormProps,
    Field,
    reduxForm,
    FieldArray,
    formValueSelector,
} from 'redux-form';
import { useSelector } from 'react-redux';
import { createTextMask } from 'redux-form-input-masks';
import moment from 'moment';

import {
    SpecialPrice,
    SubwayRoute,
    WeekDay,
    WeekDaysOptions,
    WorkingHours,
} from '@t/gym';
import SelectInput from '@components/SelectInput';
import TimeInput from '@components/TimeInput';
import CheckBoxInput from '@components/CheckBoxInput';
import GeoInputYandex from '@components/GeoInputYandex';
import TextInput from '@components/TextInput';
import TextAreaInput from '@components/TextAreaInput';
import Button from '@components/Button';
import { ApplicationState } from '@redux/types';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import MultiSelectInput from '@components/MultiSelectInput';
import MultiSelectInputV2 from '@components/MultiSelectInputV2';
import CityInput from '@components/CityInput';
import { TIME_FORMAT } from '@config';

import styles from './styles.module.css';

export interface FormData {
    hasPerMinWorkouts: boolean;
    hasGroupWorkouts: boolean;
    hasSingleWorkouts: boolean;
    withSingleWorkoutsOption: boolean;
    withGroupWorkoutsOption: boolean;
    hasMinWorkoutDuration: boolean;
    minWorkoutDuration?: number;
    title: string;
    authorized: boolean;
    deleted: boolean;
    address: string;
    description: string;
    descriptionLong: string;
    geo: {
        address: string;
        shortAddress: string;
        position: google.maps.LatLngLiteral;
    };
    social: {
        instagram: string;
        vk: string;
        facebook: string;
        telegram: string;
    };
    specialPrices: Array<SpecialPrice>;
    subwayRoutes: Array<SubwayRoute>;
    workingHours?: WorkingHours[];
    fixedPrice: number;
    tags: {
        dayAndNight?: boolean;
        drinkingWater?: boolean;
        towels?: boolean;
        pilates?: boolean;
        crossfit?: boolean;
        yoga?: boolean;
        stretching?: boolean;
        combatZone?: boolean;
        finnishSauna?: boolean;
        turkishHammam?: boolean;
        fitnessBar?: boolean;
        swimmingPool?: boolean;
        childrenRoom?: boolean;
        parking?: boolean;
        cardioEquipment?: boolean;
        shower?: boolean;
    };
    services: string[];
    advantages: string[];
    website: string;
    phone: string;
    city?: { title: string; id: string };
    hasWeekendPrimeTime: boolean;
    hasWeekDayPrimeTime: boolean;
    legalEntityId: string;
    managersId: string[];
    hasPassport: boolean;
    shortAddress: string;
}

export interface Props {
    legalEntitysOptions: {
        title: string;
        value: string;
    }[];
    managersOptions: {
        title: string;
        value: string;
    }[];
    tagsServicesOptions: {
        label: string;
        value: string;
    }[];
    tagsAdvantagesOptions: {
        label: string;
        value: string;
    }[];
}

const isEmptyError = (errors: { [i: number]: string }) => {
    for (const key in errors) {
        if (
            Object.prototype.hasOwnProperty.call(errors, key) &&
            Object.keys(errors[key]).length !== 0
        ) {
            return false;
        }
    }
    return true;
};

const validate = (values: FormData) => {
    const errors: {
        title: string;
        description: string;
        fixedPrice: string;
        minWorkoutDuration: string;
        phone: string;
        subwayRoutes: any;
        specialPrices: any;
        workingHours: any;
        geo: any;
    } = {
        title: '',
        description: '',
        fixedPrice: '',
        minWorkoutDuration: '',
        phone: '',
        subwayRoutes: {},
        specialPrices: {},
        workingHours: {},
        geo: {},
    };

    if (!values.title) {
        errors.title = 'Обязательное поле';
    }

    if (!values.title?.replace(/\s/g, '')) {
        errors.title = 'Обязательное поле';
    }

    if (!values.description) {
        errors.description = 'Обязательное поле';
    }

    if (!values.description?.replace(/\s/g, '')) {
        errors.description = 'Обязательное поле';
    }

    if (
        values.hasPerMinWorkouts &&
        (!Number.isInteger(Number(values.fixedPrice)) ||
            Number(values.fixedPrice) <= 0 ||
            String(values.fixedPrice).replace(/^\d+$/g, '').length)
    ) {
        errors.fixedPrice = 'Должно быть целым числом';
    }

    if (values.hasPerMinWorkouts && !values.fixedPrice) {
        errors.fixedPrice = 'Обязательное поле';
    }

    if (
        values.hasMinWorkoutDuration &&
        (!Number.isInteger(Number(values.minWorkoutDuration)) ||
            Number(values.minWorkoutDuration) <= 0 ||
            String(values.minWorkoutDuration).replace(/^\d+$/g, '').length)
    ) {
        errors.minWorkoutDuration = 'Должно быть числом';
    }

    if (values.hasMinWorkoutDuration && !values.minWorkoutDuration) {
        errors.minWorkoutDuration = 'Обязательное поле';
    }

    if (
        values?.phone?.length > 0 &&
        !/[+]7\s[(][0-9]{3}[)]\s[0-9]{3}[-][0-9]{2}[-][0-9]{2}/.test(
            values.phone
        )
    ) {
        errors.phone = 'Проверьте корректность номера';
    }

    if (!values?.geo?.shortAddress) {
        errors.geo.shortAddress = 'Обязательное поле';
    }

    values.subwayRoutes?.forEach((subwayRoute: SubwayRoute, i: number) => {
        if (!subwayRoute.station) {
            errors.subwayRoutes[i] = {
                ...errors.subwayRoutes[i],
                station: 'Обязательное поле',
            };
        }
        if (
            !Number.isInteger(Number(subwayRoute.duration)) ||
            Number(subwayRoute.duration) <= 0 ||
            String(subwayRoute.duration).replace(/^\d+$/g, '').length
        ) {
            errors.subwayRoutes[i] = {
                ...errors.subwayRoutes[i],
                duration: 'Должно быть числом',
            };
        }
        if (!subwayRoute.duration) {
            errors.subwayRoutes[i] = {
                ...errors.subwayRoutes[i],
                duration: 'Обязательное поле',
            };
        }
    });

    values.specialPrices?.forEach((specialPrice: SpecialPrice, i: number) => {
        if (!specialPrice.weekDay) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                weekDay: 'Обязательное поле',
            };
        }

        if (!specialPrice.timeStart) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                timeStart: 'Обязательное поле',
            };
        }

        if (!specialPrice.timeFinish) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                timeFinish: 'Обязательное поле',
            };
        }

        if (
            moment(specialPrice.timeStart).utcOffset(0).format(TIME_FORMAT) ===
                '00:00' &&
            moment(specialPrice.timeFinish).utcOffset(0).format(TIME_FORMAT) ===
                '00:00'
        ) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                timeStart: 'Обязательное поле',
                timeFinish: 'Обязательное поле',
            };
        }

        if (
            !Number.isInteger(Number(specialPrice.price)) ||
            Number(specialPrice.price) <= 0 ||
            String(specialPrice.price).replace(/^\d+$/g, '').length
        ) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                price: 'Должно быть числом',
            };
        }

        if (!specialPrice.price) {
            errors.specialPrices[i] = {
                ...errors.specialPrices[i],
                price: 'Обязательное поле',
            };
        }
    });

    values.workingHours?.forEach((workingHour: WorkingHours, i: number) => {
        if (!workingHour.weekDay) {
            errors.workingHours[i] = {
                ...errors.workingHours[i],
                weekDay: 'Обязательное поле',
            };
        }

        if (!workingHour.timeStart) {
            errors.workingHours[i] = {
                ...errors.workingHours[i],
                timeStart: 'Обязательное поле',
            };
        }

        if (!workingHour.timeFinish) {
            errors.workingHours[i] = {
                ...errors.workingHours[i],
                timeFinish: 'Обязательное поле',
            };
        }

        if (
            moment(workingHour.timeStart).utcOffset(0).format(TIME_FORMAT) ===
                '00:00' &&
            moment(workingHour.timeFinish).utcOffset(0).format(TIME_FORMAT) ===
                '00:00'
        ) {
            errors.workingHours[i] = {
                ...errors.workingHours[i],
                timeStart: 'Обязательное поле',
                timeFinish: 'Обязательное поле',
            };
        }
    });

    const isEmpty = isEmptyError(errors);

    return isEmpty ? {} : errors;
};

const Form: React.FC<Props & InjectedFormProps<FormData, Props>> = memo(
    ({
        handleSubmit,
        legalEntitysOptions,
        managersOptions,
        tagsServicesOptions,
        tagsAdvantagesOptions,
    }) => {
        const user = useSelector(getUser);
        const isSuperAdmin = user?.role === UserRole.SuperAdmin;
        const [foundManager, setFoundManager] = useState<any>();
        const managerSearchingField = useRef<any>();

        const handleSearchManagers = () => {
            setFoundManager(
                managersOptions.filter((managerOption) =>
                    managerOption.title
                        .toLowerCase()
                        .includes(
                            managerSearchingField.current.value.toLowerCase()
                        )
                )
            );
        };

        const selector = formValueSelector('addGym');
        // const hasSingleWorkoutsSelector = (state: ApplicationState) =>
        //     selector(state, 'hasSingleWorkouts');
        // const hasWeekDayPrimeTimeSelector = (state: ApplicationState) =>
        //     selector(state, 'hasWeekDayPrimeTime');
        // const hasWeekendPrimeTimeSelector = (state: ApplicationState) =>
        //     selector(state, 'hasWeekendPrimeTime');
        const hasPerMinWorkoutsSelector = (state: ApplicationState) =>
            selector(state, 'hasPerMinWorkouts');
        const hasMinWorkoutDurationSelector = (state: ApplicationState) =>
            selector(state, 'hasMinWorkoutDuration');
        // const hasSingleWorkouts = useSelector(hasSingleWorkoutsSelector);
        // const hasWeekDayPrimeTime = useSelector(hasWeekDayPrimeTimeSelector);
        // const hasWeekendPrimeTime = useSelector(hasWeekendPrimeTimeSelector);
        const hasPerMinWorkouts = useSelector(hasPerMinWorkoutsSelector);
        const hasMinWorkoutDuration = useSelector(
            hasMinWorkoutDurationSelector
        );

        const renderWorkingHours = useCallback(
            ({ fields }: any) => {
                return (
                    <div>
                        <Button
                            className={styles.btn}
                            type="button"
                            onClick={() => fields.push({})}
                        >
                            Добавить время работы
                        </Button>
                        <ul>
                            {fields.map(
                                (
                                    workingHours: Array<{
                                        weekDay: WeekDay;
                                        timeStart: Date;
                                        timeFinish: Date;
                                        aroundTheClock: boolean;
                                    } | null>,
                                    index: number
                                ) => (
                                    <li key={index}>
                                        <div className={styles.working}>
                                            <Field
                                                name={`${workingHours}.weekDay`}
                                                label="День недели"
                                                placeholder="понедельник"
                                                options={WeekDaysOptions}
                                                defaulVal={-1}
                                                emptyTitle={
                                                    'Выбрать день/период'
                                                }
                                                component={SelectInput}
                                            />

                                            <Field
                                                convertToString
                                                name={`${workingHours}.timeStart`}
                                                label="Открытие"
                                                placeholder="10:00"
                                                defaultValue={
                                                    '2023-01-01T00:00:00.000+00:00'
                                                }
                                                component={TimeInput}
                                            />

                                            <Field
                                                convertToString
                                                name={`${workingHours}.timeFinish`}
                                                label="Закрытие"
                                                placeholder="12:00"
                                                defaultValue={
                                                    '2023-01-01T00:00:00.000+00:00'
                                                }
                                                component={TimeInput}
                                            />
                                        </div>
                                        <div>
                                            <Field
                                                name={`${workingHours}.aroundTheClock`}
                                                label="Круглосуточно"
                                                component={CheckBoxInput}
                                            />

                                            <Button
                                                className={styles.btn}
                                                type="button"
                                                onClick={() =>
                                                    fields.remove(index)
                                                }
                                            >
                                                Удалить
                                            </Button>
                                        </div>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                );
            },
            [validate]
        );

        const renderSpecialPrices = useCallback(
            ({ fields }: any) => {
                return (
                    <div>
                        <Button
                            className={styles.btn}
                            type="button"
                            onClick={() => fields.push({})}
                        >
                            Добавить специальную цену
                        </Button>
                        <ul>
                            {fields.map(
                                (
                                    specialPrice: Array<{
                                        weekDay: WeekDay;
                                        timeStart: Date;
                                        timeFinish: Date;
                                        price: number;
                                    } | null>,
                                    index: number
                                ) => (
                                    <li key={index}>
                                        <div className={styles.special}>
                                            <Field
                                                name={`${specialPrice}.weekDay`}
                                                label="День недели"
                                                placeholder="понедельник"
                                                emptyTitle={
                                                    'Выбрать день/период'
                                                }
                                                options={WeekDaysOptions}
                                                defaulVal={-1}
                                                component={SelectInput}
                                            />
                                            <Field
                                                convertToString
                                                name={`${specialPrice}.timeStart`}
                                                label="Начало"
                                                placeholder="10:00"
                                                defaultValue={
                                                    '2023-01-01T00:00:00.000+00:00'
                                                }
                                                component={TimeInput}
                                            />

                                            <Field
                                                convertToString
                                                name={`${specialPrice}.timeFinish`}
                                                label="Конец"
                                                placeholder="12:00"
                                                defaultValue={
                                                    '2023-01-01T00:00:00.000+00:00'
                                                }
                                                component={TimeInput}
                                            />

                                            <Field
                                                name={`${specialPrice}.price`}
                                                label="Цена"
                                                placeholder="5"
                                                component={TextInput}
                                            />
                                        </div>
                                        <div>
                                            <Button
                                                className={styles.btn}
                                                type="button"
                                                onClick={() =>
                                                    fields.remove(index)
                                                }
                                            >
                                                Удалить
                                            </Button>
                                        </div>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                );
            },
            [validate]
        );

        const renderSubwayRoutes = useCallback(
            ({ fields }: any) => {
                return (
                    <div>
                        <Button
                            className={styles.btn}
                            type="button"
                            onClick={() => fields.push({})}
                        >
                            Добавить метро
                        </Button>
                        <ul>
                            {fields.map(
                                (
                                    subwayRoute: Array<{
                                        station: string;
                                        duration: number;
                                    } | null>,
                                    index: number
                                ) => (
                                    <li key={index}>
                                        <div>
                                            <Field
                                                name={`${subwayRoute}.station`}
                                                label="Название метро"
                                                placeholder="Алтуфьево"
                                                component={TextInput}
                                            />

                                            <Field
                                                name={`${subwayRoute}.duration`}
                                                label="Время от метро до площадки (мин)"
                                                placeholder="5"
                                                component={TextInput}
                                            />
                                            <Button
                                                className={styles.btn}
                                                type="button"
                                                onClick={() =>
                                                    fields.remove(index)
                                                }
                                            >
                                                Удалить метро
                                            </Button>
                                        </div>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                );
            },
            [validate]
        );

        // const renderImagesSrc = ({ fields }: { fields: any }) => {
        //     return (
        //         <>
        //             {fields.map((imageSrc: Array<string | null>, index: number) => (
        //                 <div key={index}>
        //                     <Field
        //                         name={`${imageSrc}`}
        //                         label={`Ссылка на фото ${index + 1}`}
        //                         placeholder="https://website.ru/images/image.jpg"
        //                         component={TextInput}
        //                     />
        //                 </div>
        //             ))}
        //         </>
        //     );
        // };

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                {isSuperAdmin && (
                    <div className={styles.row}>
                        <Field
                            name="authorized"
                            label="Авторизован"
                            component={CheckBoxInput}
                        />
                        <Field
                            name="deleted"
                            label="Скрыт с карты"
                            component={CheckBoxInput}
                        />
                        <Field
                            name="withGroupWorkoutsOption"
                            label="Разрешить групповые тренировки"
                            component={CheckBoxInput}
                        />
                        {/* <Field
                        name="withSingleWorkoutsOption"
                        label="Разрешить разовые тренировки"
                        component={CheckBoxInput}
                    /> */}
                    </div>
                )}

                <div className={styles.addForm}>
                    <div className={styles.wrap}>
                        <Field
                            name="title"
                            label="Название"
                            placeholder="Самая лучшая фитнес-площадка"
                            component={TextInput}
                        />

                        <Field
                            name="hasPassport"
                            label="Нужен паспорт"
                            component={CheckBoxInput}
                        />

                        <Field
                            name="hasPerMinWorkouts"
                            label="Есть опция поминутных тренировок"
                            component={CheckBoxInput}
                        />
                        {isSuperAdmin && (
                            <Field
                                name="hasGroupWorkouts"
                                label="Есть опция групповых тренировок"
                                component={CheckBoxInput}
                            />
                        )}

                        {/* {isSuperAdmin && (
                        <Field
                            name="hasSingleWorkouts"
                            label="Есть опция разовых тренировок"
                            component={CheckBoxInput}
                        />
                    )} */}

                        {isSuperAdmin && (
                            <FieldArray
                                name="workingHours"
                                component={renderWorkingHours}
                            />
                        )}

                        {hasPerMinWorkouts && (
                            <div>
                                <Field
                                    min="0"
                                    max="100"
                                    name="fixedPrice"
                                    placeholder="10"
                                    label="Цена (поминутная)"
                                    component={TextInput}
                                />

                                <FieldArray
                                    name="specialPrices"
                                    component={renderSpecialPrices}
                                />
                                <Field
                                    name="hasMinWorkoutDuration"
                                    label="Есть минимальное время поминутной тренировки"
                                    component={CheckBoxInput}
                                />
                                {hasMinWorkoutDuration && (
                                    <div>
                                        <Field
                                            min="0"
                                            max="30"
                                            name="minWorkoutDuration"
                                            placeholder="30"
                                            label="Минимальное время тренироки (в минутах)"
                                            component={TextInput}
                                        />
                                    </div>
                                )}
                            </div>
                        )}

                        {/* {hasSingleWorkouts && (
                        <div>
                            <div>
                                <h3>Будние дни:</h3>
                                <h4>Обычная цена:</h4>
                                <div className={styles.inputListWrapper}>
                                    <Field
                                        required
                                        convertToString
                                        name="singleWorkoutPriceInfo.weekDayStartTime"
                                        label="Начало"
                                        placeholder="10:00"
                                        className={styles.inputWrapper}
                                        component={TimeInput}
                                    />
                                    <Field
                                        required
                                        convertToString
                                        name="singleWorkoutPriceInfo.weekDayFinishTime"
                                        label="Конец"
                                        placeholder="11:00"
                                        className={styles.inputWrapper}
                                        component={TimeInput}
                                    />
                                    <Field
                                        required
                                        name="singleWorkoutPriceInfo.weekDayPrice"
                                        placeholder="2000"
                                        label="Цена"
                                        className={styles.inputWrapper}
                                        component={TextInput}
                                    />
                                </div>
                                <Field
                                    name="hasWeekDayPrimeTime"
                                    label="Во время низкой нагрузки цена отличается?"
                                    component={CheckBoxInput}
                                />
                                {hasWeekDayPrimeTime && (
                                    <div className={styles.inputListWrapper}>
                                        <Field
                                            required
                                            convertToString
                                            name="singleWorkoutPriceInfo.weekDayOffPeakStartTime"
                                            label="Начало"
                                            placeholder="10:00"
                                            className={styles.inputWrapper}
                                            component={TimeInput}
                                        />
                                        <Field
                                            required
                                            convertToString
                                            name="singleWorkoutPriceInfo.weekDayOffPeakFinishTime"
                                            label="Конец"
                                            placeholder="11:00"
                                            className={styles.inputWrapper}
                                            component={TimeInput}
                                        />
                                        <Field
                                            required
                                            name="singleWorkoutPriceInfo.weekDayOffPeakPrice"
                                            placeholder="1500"
                                            label="Цена"
                                            className={styles.inputWrapper}
                                            component={TextInput}
                                        />
                                    </div>
                                )}
                            </div>
                            <div>
                                <h3>Выходные дни:</h3>
                                <h4>Обычная цена:</h4>
                                <div className={styles.inputListWrapper}>
                                    <Field
                                        required
                                        convertToString
                                        name="singleWorkoutPriceInfo.weekendStartTime"
                                        label="Начало"
                                        placeholder="10:00"
                                        className={styles.inputWrapper}
                                        component={TimeInput}
                                    />
                                    <Field
                                        required
                                        convertToString
                                        name="singleWorkoutPriceInfo.weekendFinishTime"
                                        label="Конец"
                                        placeholder="11:00"
                                        className={styles.inputWrapper}
                                        component={TimeInput}
                                    />
                                    <Field
                                        required
                                        name="singleWorkoutPriceInfo.weekendPrice"
                                        placeholder="2000"
                                        label="Цена"
                                        className={styles.inputWrapper}
                                        component={TextInput}
                                    />
                                </div>

                                <Field
                                    name="hasWeekendPrimeTime"
                                    label="Во время низкой нагрузки цена отличается?"
                                    component={CheckBoxInput}
                                />
                                {hasWeekendPrimeTime && (
                                    <div className={styles.inputListWrapper}>
                                        <Field
                                            required
                                            convertToString
                                            name="singleWorkoutPriceInfo.weekendOffPeakStartTime"
                                            label="Начало"
                                            placeholder="10:00"
                                            className={styles.inputWrapper}
                                            component={TimeInput}
                                        />
                                        <Field
                                            required
                                            convertToString
                                            name="singleWorkoutPriceInfo.weekendOffPeakFinishTime"
                                            label="Конец"
                                            placeholder="11:00"
                                            className={styles.inputWrapper}
                                            component={TimeInput}
                                        />
                                        <Field
                                            required
                                            name="singleWorkoutPriceInfo.weekendOffPeakPrice"
                                            placeholder="1500"
                                            label="Цена"
                                            className={styles.inputWrapper}
                                            component={TextInput}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )} */}

                        <Field
                            name="description"
                            label="Описание"
                            placeholder="Лучшее, что было с вашим телом"
                            component={TextAreaInput}
                        />

                        {/* <Field
                name="descriptionLong"
                label="Услуги"
                placeholder="Лучшее, что было с вашим телом. Действительно, это лучшее, что было с вашим телом. Наверное, это лучшее, что случилось в вашей жизни. Самый лучший зал открывает для вас свои двери. Тренируйтесь как в последний раз. До конца, до упора. С нами вы забудете, что такое спокойная тренировка. Самый лучший зал не только открывает двери, но и размывает границы болевого порога для всех желающих."
                component={TextAreaInput}
            /> */}

                        <FieldArray
                            name="subwayRoutes"
                            component={renderSubwayRoutes}
                        />
                    </div>
                    <div className={styles.wrap}>
                        <Field
                            name={`services`}
                            label="Услуги зала"
                            placeholder="Выберите из списка"
                            options={tagsServicesOptions}
                            isMulti={true}
                            component={MultiSelectInputV2}
                        />
                        <Field
                            name={`advantages`}
                            label="Преимущества зала"
                            placeholder="Выберите из списка"
                            options={tagsAdvantagesOptions}
                            isMulti={true}
                            component={MultiSelectInputV2}
                        />

                        <Field
                            // required
                            name="city"
                            label="Город"
                            placeholder="Выберите из списка"
                            component={CityInput}
                        />

                        <Field
                            // required
                            name="geo"
                            label="Полный адрес"
                            placeholder="Выберите из списка"
                            component={GeoInputYandex}
                        />

                        <Field
                            // required
                            name="geo.shortAddress"
                            label="Короткий адрес"
                            placeholder="Введите адрес"
                            component={TextInput}
                        />

                        <Field
                            name="social.telegram"
                            label="Telegram (только ID)"
                            placeholder="gogym_msk"
                            component={TextInput}
                        />

                        <Field
                            name="social.vk"
                            label="VK (только ID)"
                            placeholder="gogym.fit"
                            component={TextInput}
                        />

                        <Field
                            name="website"
                            label="Ссылка на сайт"
                            placeholder="https://website.ru"
                            component={TextInput}
                        />

                        <Field
                            name="phone"
                            label="Телефон площадки"
                            placeholder="+7"
                            type="tel"
                            {...(createTextMask({
                                pattern: '+7 (999) 999-99-99',
                                allowEmpty: true,
                                guide: false,
                                stripMask: false,
                            }) as any)}
                            component={TextInput}
                        />

                        {/* <FieldArray
                        name="imagesSrc"
                        label="Ссылки на фото"
                        component={renderImagesSrc}
                    /> */}

                        {isSuperAdmin && (
                            <>
                                <label className={styles.label}>
                                    Юридические лица:
                                </label>
                                <Field
                                    name="legalEntityId"
                                    component="select"
                                    className={styles.select}
                                >
                                    <option value="">
                                        Выберите юридическое лицо
                                    </option>
                                    {legalEntitysOptions.map(
                                        (legalEntity, i) => (
                                            <option
                                                key={i}
                                                value={legalEntity.value}
                                            >
                                                {legalEntity.title}
                                            </option>
                                        )
                                    )}
                                </Field>
                            </>
                        )}

                        {isSuperAdmin && (
                            <>
                                {foundManager ? (
                                    <Field
                                        name="managersId"
                                        label="Все менеджеры"
                                        component={MultiSelectInput}
                                        options={foundManager}
                                        defaultValue={''}
                                    />
                                ) : (
                                    <Field
                                        name="managersId"
                                        label="Все менеджеры"
                                        component={MultiSelectInput}
                                        options={managersOptions}
                                        defaultValue={''}
                                    />
                                )}

                                <input
                                    className={styles.input}
                                    type="search"
                                    placeholder="Поиск по имени и email менеджера"
                                    ref={managerSearchingField}
                                    onChange={handleSearchManagers}
                                />
                            </>
                        )}
                    </div>
                </div>

                <Button type="submit" className={styles.btn}>
                    Добавить
                </Button>
            </form>
        );
    }
);

export default reduxForm<FormData, Props>({
    form: 'addGym',
    validate,
})(Form);
