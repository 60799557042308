import React from 'react';
import { IconProps } from './types';

export const Downloading: React.FC<IconProps> = ({ ...props }) => (
    <svg
        width="37"
        height="36"
        viewBox="0 0 37 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M20.5 10C20.5 8.89543 19.6046 8 18.5 8C17.3955 8 16.5 8.89543 16.5 10V21.2385L14.0277 18.3124C13.3149 17.4687 12.053 17.3626 11.2093 18.0755C10.3655 18.7883 10.2595 20.0502 10.9723 20.894L15.2953 26.4513C16.0552 27.4282 17.2244 27.9983 18.462 27.9954L18.5 27.9953L18.538 27.9954C19.7756 27.9983 20.9448 27.4282 21.7047 26.4514L26.0277 20.894C26.7406 20.0502 26.6346 18.7883 25.7908 18.0755C24.9471 17.3626 23.6852 17.4687 22.9723 18.3124L20.5 21.2385V10Z"
            fill="#F07C2D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.662106 13.8456C1.0515 8.61367 1.24619 5.99768 3.87194 3.37194C6.49768 0.746191 9.11367 0.551496 14.3456 0.162106C15.7019 0.0611667 17.101 0 18.5 0C19.899 0 21.2981 0.0611667 22.6544 0.162106C27.8863 0.551496 30.5023 0.746191 33.1281 3.37194C35.7538 5.99768 35.9485 8.61367 36.3379 13.8456C36.4388 15.2019 36.5 16.601 36.5 18C36.5 19.399 36.4388 20.7981 36.3379 22.1544C35.9485 27.3863 35.7538 30.0023 33.1281 32.6281C30.5023 35.2538 27.8863 35.4485 22.6544 35.8379C21.2981 35.9388 19.899 36 18.5 36C17.101 36 15.7019 35.9388 14.3456 35.8379C9.11367 35.4485 6.49768 35.2538 3.87194 32.6281C1.24619 30.0023 1.0515 27.3863 0.662106 22.1544C0.561167 20.7981 0.5 19.399 0.5 18C0.5 16.601 0.561167 15.2019 0.662106 13.8456ZM14.6425 4.15107C15.9168 4.05623 17.2156 4 18.5 4C19.7844 4 21.0832 4.05623 22.3575 4.15107C27.8582 4.56046 28.8872 4.78797 30.2996 6.20036C31.712 7.61276 31.9395 8.64181 32.3489 14.1425C32.4438 15.4168 32.5 16.7156 32.5 18C32.5 19.2844 32.4438 20.5832 32.3489 21.8575C31.9395 27.3582 31.712 28.3872 30.2996 29.7996C28.8872 31.212 27.8582 31.4395 22.3575 31.8489C21.0832 31.9438 19.7844 32 18.5 32C17.2156 32 15.9168 31.9438 14.6425 31.8489C9.14181 31.4395 8.11276 31.212 6.70036 29.7996C5.28797 28.3872 5.06046 27.3582 4.65107 21.8575C4.55623 20.5832 4.5 19.2844 4.5 18C4.5 16.7156 4.55623 15.4168 4.65107 14.1425C5.06046 8.64181 5.28797 7.61276 6.70036 6.20036C8.11276 4.78797 9.14181 4.56046 14.6425 4.15107Z"
            fill="#F07C2D"
        />
    </svg>
);

export default Downloading;
