import React from 'react';
import { ErrorMessage } from 'formik';

import styles from './styles.module.css';

export interface Props {
    name: string;
    label: string;
    accept: string;
    onChange?: any;
    fileRef?:
        | React.MutableRefObject<null>
        | React.MutableRefObject<HTMLInputElement>
        | any;
    hint?: string;
}

export const FileUpload: React.FC<Props> = ({
    name,
    label,
    accept,
    hint,
    fileRef,
    onChange,
}) => {
    return (
        <div className={styles.wrapper}>
            <form className="upload-image">
                <label className={styles.label}>{label}</label>
                <input
                    type="file"
                    ref={fileRef}
                    className={styles.input}
                    onChange={onChange}
                    accept={accept}
                />
                {hint && <div className={styles.hint}>{hint}</div>}
            </form>
            <ErrorMessage
                className={styles.error}
                name={name}
                component="div"
            />
        </div>
    );
};
