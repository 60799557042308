import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { formValueSelector } from 'redux-form';

import {
    getAllUsers,
    getAllUsersCount,
    getAverageBookingTime,
    getAverageCostOfBooking,
    getDailyUsers,
    getFavoriteClub,
    getMostActiveEmployee,
    getTotalBooking,
    getTotalCostOfBooking,
    getTotalNumberOfVisitedClubs,
} from '@redux/modules/dashboard/selectors';
import {
    fetchHRStatistics,
    filterHRUsers,
} from '@redux/modules/dashboard/actions';
import { ApplicationState, AsyncDispatch } from '@redux/types';
import { FILTER_DATE_FORMAT } from '@config';
import HRStatisticsCounter from '@components/HRStatisticsCounter';
import HRStatisticsChart from '@components/HRStatisticsChart';

import styles from './styles.module.css';
import Form from './Form';

export const HRStatistics: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const allUsers = useSelector(getAllUsers);
    const totalBooking = useSelector(getTotalBooking);
    const averageBookingTime = useSelector(getAverageBookingTime);
    const totalNumberOfVisitedClubs = useSelector(getTotalNumberOfVisitedClubs);
    const favoriteClub = useSelector(getFavoriteClub);
    const totalCostOfBooking = useSelector(getTotalCostOfBooking);
    const averageCostOfBooking = useSelector(getAverageCostOfBooking);
    const mostActiveEmployee = useSelector(getMostActiveEmployee);

    const nowDate = new Date();
    const endDateDef = moment(new Date(nowDate)).format(FILTER_DATE_FORMAT);
    const startDateDef = moment(new Date(nowDate))
        .subtract(29, 'days')
        .format(FILTER_DATE_FORMAT);

    const selector = formValueSelector('filterHRUsers');
    const concatenatedDatesSelector = (state: ApplicationState) =>
        selector(state, 'concatenatedDates');
    let concatenatedDates = useSelector(concatenatedDatesSelector);

    if (!concatenatedDates) {
        concatenatedDates = `${startDateDef}:${endDateDef}`;
    }

    const [startDate, endDate] = concatenatedDates.split(':');

    useEffect(() => {
        dispatch(filterHRUsers({ startDate, endDate }));
    }, [dispatch, endDate]);

    const dailyUsers = useSelector(getDailyUsers);
    const allUsersCount = useSelector(getAllUsersCount);
    const initialValueDates = { concatenatedDates: `${startDate}:${endDate}` };

    useEffect(() => {
        dispatch(fetchHRStatistics());
    }, [dispatch]);

    return (
        <>
            <div className={styles.row}>
                <HRStatisticsCounter
                    title="Всего пользователей:"
                    count={allUsers}
                />
                <HRStatisticsCounter
                    title="Всего тренировок:"
                    count={totalBooking}
                />
                <HRStatisticsCounter
                    title="Среднее время тренировки:"
                    count={averageBookingTime}
                />
                <HRStatisticsCounter
                    title="Количество посещенных фитнес-клубов:"
                    count={totalNumberOfVisitedClubs}
                />
            </div>

            <div className={styles.row}>
                <HRStatisticsCounter
                    title="Самый популярный фитнес-клуб:"
                    name={favoriteClub}
                />
                <HRStatisticsCounter
                    title="Общая стоимость тренировок:"
                    price={totalCostOfBooking}
                />
                <HRStatisticsCounter
                    title="Средняя стоимость тренировки:"
                    count={averageCostOfBooking}
                />
                <HRStatisticsCounter
                    title="Самый активный сотрудник:"
                    name={mostActiveEmployee}
                />
            </div>

            <Form initialValues={initialValueDates} />
            <div className={styles.dailyAppUsers}>
                <HRStatisticsChart dailyUsers={dailyUsers} />
                <HRStatisticsCounter
                    title="Всего за период:"
                    count={allUsersCount}
                />
            </div>
        </>
    );
};

export default HRStatistics;
