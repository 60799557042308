import { View as CalendarView } from 'react-big-calendar';

import { GroupWorkoutNew } from '@t/gym';

export const SET_CALENDAR_VIEW = 'SET_CALENDAR_VIEW';

export const FETCH_GYM_GROUP_WORKOUTS_REQUEST =
    'FETCH_GYM_GROUP_WORKOUTS_REQUEST';
export const FETCH_GYM_GROUP_WORKOUTS_SUCCESS =
    'FETCH_GYM_GROUP_WORKOUTS_SUCCESS';
export const FETCH_GYM_GROUP_WORKOUTS_ERROR = 'FETCH_GYM_GROUP_WORKOUTS_ERROR';

export const FETCH_GROUP_WORKOUT_REQUEST = 'FETCH_GROUP_WORKOUT_REQUEST';
export const FETCH_GROUP_WORKOUT_SUCCESS = 'FETCH_GROUP_WORKOUT_SUCCESS';
export const FETCH_GROUP_WORKOUT_ERROR = 'FETCH_GROUP_WORKOUT_ERROR';

export const FETCH_GROUP_WORKOUT_DETAIL_REQUEST =
    'FETCH_GROUP_WORKOUT_DETAIL_REQUEST';
export const FETCH_GROUP_WORKOUT_DETAIL_SUCCESS =
    'FETCH_GROUP_WORKOUT_DETAIL_SUCCESS';
export const FETCH_GROUP_WORKOUT_DETAIL_ERROR =
    'FETCH_GROUP_WORKOUT_DETAIL_ERROR';

export const CANCEL_GROUP_WORKOUT_REQUEST = 'CANCEL_GROUP_WORKOUT_REQUEST';
export const CANCEL_GROUP_WORKOUT_SUCCESS = 'CANCEL_GROUP_WORKOUT_SUCCESS';
export const CANCEL_GROUP_WORKOUT_ERROR = 'CANCEL_GROUP_WORKOUT_ERROR';

export const CREATE_GROUP_WORKOUT_REQUEST = 'CREATE_GROUP_WORKOUT_REQUEST';
export const CREATE_GROUP_WORKOUT_SUCCESS = 'CREATE_GROUP_WORKOUT_SUCCESS';
export const CREATE_GROUP_WORKOUT_ERROR = 'CREATE_GROUP_WORKOUT_ERROR';

export const UPDATE_GROUP_WORKOUT_REQUEST = 'EDIT_GROUP_WORKOUT_REQUEST';
export const UPDATE_GROUP_WORKOUT_SUCCESS = 'EDIT_GROUP_WORKOUT_SUCCESS';
export const UPDATE_GROUP_WORKOUT_ERROR = 'EDIT_GROUP_WORKOUT_ERROR';

export const DELETE_GROUP_WORKOUT_REQUEST = 'DELETE_GROUP_WORKOUT_REQUEST';
export const DELETE_GROUP_WORKOUT_SUCCESS = 'DELETE_GROUP_WORKOUT_SUCCESS';
export const DELETE_GROUP_WORKOUT_ERROR = 'DELETE_GROUP_WORKOUT_ERROR';

export const FETCH_GROUP_WORKOUTS_SCHEDULE_REQUEST =
    'FETCH_GROUP_WORKOUTS_SCHEDULE_REQUEST';
export const FETCH_GROUP_WORKOUTS_SCHEDULE_SUCCESS =
    'FETCH_GROUP_WORKOUTS_SCHEDULE_SUCCESS';
export const FETCH_GROUP_WORKOUTS_SCHEDULE_ERROR =
    'FETCH_GROUP_WORKOUTS_SCHEDULE_ERROR';

export interface GroupWorkoutsState {
    calendarView: CalendarView;
    ids: string[];
    byId: {
        [id: string]: GroupWorkoutNew;
    };
    detail: {
        [id: string]: GroupWorkoutNew;
    };
    calendar: GroupWorkoutNew[];
}
