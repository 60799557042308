import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { AsyncDispatch } from '@redux/types';
import {
    createCoupons,
    fetchCouponsAdCampaigns,
} from '@redux/modules/coupons/actions';
import BackButton from '@components/BackButton';
import { getGymsList } from '@redux/modules/gyms/selectors';
import { getCouponsAdCampaigns } from '@redux/modules/coupons/selectors';

import Form from './Form';
import styles from './styles.module.css';

export const AddCoupons: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const gyms = useSelector(getGymsList);
    const couponsAdCampaigns = useSelector(getCouponsAdCampaigns);

    useEffect(() => {
        dispatch(fetchCouponsAdCampaigns());
    }, [dispatch]);

    const onSubmit = useCallback(
        (values) => {
            dispatch(createCoupons(values))
                .then((coupon) => {
                    if (coupon) {
                        cogoToast.success('Купоны успешно добавлены', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                        history.push('/dashboard/coupons');
                    }
                })
                .catch(() => {
                    cogoToast.error('Не удалось создать купоны', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                });
        },
        [dispatch, history]
    );

    const gymsOptions = gyms.reduce(
        (options: { title: string; value: string }[], gym) => {
            if (!gym.isHidden) {
                options.push({ title: gym.title, value: gym._id });
            }
            return options;
        },
        []
    );

    const couponsAdCampaignsOptions = couponsAdCampaigns.map(
        (couponsAdCampaign) => {
            return {
                title: couponsAdCampaign.name,
                value: couponsAdCampaign._id,
            };
        }
    );

    const initialValues = {
        actionStart: new Date().toISOString().slice(0, 16),
        actionEnd: new Date(new Date().setDate(new Date().getDate() + 1))
            .toISOString()
            .slice(0, 16),
        perMinWorkouts: true,
        groupWorkouts: true,
    };

    return (
        <div className={styles.container}>
            <h1>Добавить новые промокоды</h1>

            <BackButton
                title="К списку промокодов"
                className={styles.backBtn}
            />

            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                gymsOptions={gymsOptions}
                couponsAdCampaignsOptions={couponsAdCampaignsOptions}
            />
        </div>
    );
};

export default AddCoupons;
