import React from 'react';
import { IconProps } from './types';

export const Shape: React.FC<IconProps> = ({ ...props }) => (
    <svg
        width="19"
        height="14"
        viewBox="0 0 19 14"
        fill="#F07C2D"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M18 8.00011C18.5523 8.00011 19 7.55239 19 7.00011C19 6.44782 18.5523 6.00011 18 6.00011L2.85136 6.00005C3.73292 5.28709 5.11473 4.46331 7.17544 3.22689L8.9419 2.16701C9.13695 2.04998 9.32569 1.93679 9.50843 1.8274L9.5166 1.82251C9.81607 1.64175 9.99855 1.31985 9.99984 0.970053L9.99984 0.96161C9.99984 0.961708 9.99984 0.961551 9.99981 0.958734C9.99549 0.383644 9.51715 0.00397435 9 3.20267e-05C8.57415 -0.00321431 8.2647 0.240949 7.91291 0.452021L6.14645 1.5119L6.0682 1.55885C2.03551 3.97838 0 5.19965 0 6.99985C0 8.80005 2.03551 10.0213 6.0682 12.4409L6.14645 12.4878L7.91292 13.5477C8.26447 13.7586 8.57436 14.0033 9 14C9.51715 13.9961 9.99549 13.6161 9.99981 13.041C9.99984 13.0375 9.99984 13.0381 9.99984 13.0382L9.99984 13.0296C9.99855 12.6799 9.81607 12.358 9.51659 12.1772L9.50843 12.1723C9.32569 12.0629 9.13696 11.9497 8.94191 11.8327L7.17544 10.7728C5.11511 9.53662 3.73343 8.71291 2.85185 8.00005L18 8.00011Z" />
    </svg>
);

export default Shape;
