import React from 'react';

import styles from './styles.module.css';

export interface TitleProps {
    message: string;
}

export const Title: React.FC<TitleProps> = ({ message }) => (
    <h2 className={styles.title}>{message}</h2>
);

export default Title;
