import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { getToken, getUser } from '@redux/modules/auth/selectors';

export enum AuthType {
    Any,
    Authenticated,
    NonAuthenticated,
    Verified,
}

export interface Props {
    component: React.FC<any>;
    path: string;
    type: AuthType;
}

export const PrivateRoute: React.FC<Props> = ({
    component: Component,
    type,
    ...rest
}) => {
    const token = useSelector(getToken);
    const user = useSelector(getUser);

    const isAuthenticated = !!token;
    const isVerified = user?.verified;

    const fallbackRouteMap: { [key in AuthType]: string } = {
        [AuthType.Any]: '/',
        [AuthType.NonAuthenticated]: '/',
        [AuthType.Authenticated]: '/auth/login',
        [AuthType.Verified]: '/verify',
    };
    const fallbackRoute = fallbackRouteMap[type];

    let isRouteAvailable = false;

    if (
        (type === AuthType.Authenticated && isAuthenticated) ||
        (type === AuthType.Verified && isVerified) ||
        type === AuthType.Any
    ) {
        isRouteAvailable = true;
    }

    return (
        <Route
            {...rest}
            render={(props) =>
                isRouteAvailable ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={fallbackRoute} />
                )
            }
        />
    );
};

export default PrivateRoute;
