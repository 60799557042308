import React, { useCallback, useState } from 'react';
import {
    InjectedFormProps,
    Field,
    reduxForm,
    formValueSelector,
} from 'redux-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select, { createFilter, SingleValue } from 'react-select';
import moment from 'moment';

import TextInput from '@components/TextInput';
import Button from '@components/Button';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import DateTimePickerInput from '@components/DateTimePickerInput';
import LeftArrow from '@components/Icons/LeftArrow';
import { ApplicationState } from '@redux/types';
import SelectInput from '@components/SelectInput';
import Modal from '@components/Modal';
import CheckBoxInput from '@components/CheckBoxInput';

import styles from './styles.module.css';

export interface BookingFormData {
    userId: string;
    bookingId: string;
    groupWorkoutReservationId: string;
    gymTitle: string;
    dateStart: string;
    dateFinish: string;
    duration: string;
    payStatus: string;
    status: string;
    price: string;
    abortReason: string;
    appUserFirstName: string;
    appUserLastName: string;
    appUserEmail: string;
    appUserPhone: string;
}

export interface Props {
    isActiveBooking: boolean;
    isGroupWorkout: boolean;
    isCouponApplied: boolean;
    isBonusWithdrawn: boolean;
    isAborted: boolean;
    paidByMembership: boolean;
    paidBySubscription: boolean;
    btnTitle: string;
    userId: string;
    onAborted: () => void;
    writeOffCostOfBookings: (userPaymentDatasValue: string) => void;
    onDeleteBooking: () => void;
    userPaymentDatasOptions: {
        title: string;
        value: string;
    }[];
    btnDisabled: boolean;
    disabled: boolean;
    setDisabled: (prev: boolean) => void;
    gymsOptions: { label: string; value: string }[];
    gymValue: { label: string; value: string }[];
    setSelectedGym: React.Dispatch<
        React.SetStateAction<{
            label: string;
            value: string;
        }>
    >;
    userSubscriptionsOptions: {
        title: string;
        value: string;
    }[];
    userSubscriptions: boolean;
}

const validate = (values: BookingFormData) => {
    const errors: {
        dateStart: string;
        dateFinish: string;
    } = { dateStart: '', dateFinish: '' };

    if (!values.dateStart) {
        errors.dateStart = 'Обязательное поле';
    }

    if (!values.dateFinish) {
        errors.dateFinish = 'Обязательное поле';
    }

    if (moment(values.dateFinish).isBefore(moment(values.dateStart))) {
        errors.dateFinish = 'Должно быть больше времени начала';
    }

    return errors;
};

const BookingForm: React.FC<
    Props & InjectedFormProps<BookingFormData, Props>
> = ({
    handleSubmit,
    isActiveBooking,
    isGroupWorkout,
    isCouponApplied,
    isBonusWithdrawn,
    isAborted,
    paidByMembership,
    paidBySubscription,
    btnTitle,
    userId,
    onAborted,
    writeOffCostOfBookings,
    onDeleteBooking,
    userPaymentDatasOptions,
    btnDisabled,
    disabled,
    setDisabled,
    gymsOptions,
    gymValue,
    setSelectedGym,
    userSubscriptionsOptions,
    userSubscriptions,
}) => {
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;
    const isFinance = user?.role === UserRole.Finance;
    const selector = formValueSelector('bookingInfo');
    const userPaymentDatasSelector = (state: ApplicationState) =>
        selector(state, 'userPaymentDatas');
    const userPaymentDatasValue = useSelector(userPaymentDatasSelector);
    const payBysubscriptionSelector = (state: ApplicationState) =>
        selector(state, 'payBysubscription');
    const payBysubscriptionValue = useSelector(payBysubscriptionSelector);
    const [modalActive, setModalActive] = useState<boolean>(false);
    const history = useHistory();
    const goToAppUserClick = useCallback(
        () => history.push(`/dashboard/app-users/${userId}`),
        [history]
    );

    const handleChange = (
        value: SingleValue<{ label: string; value: string }>
    ) => {
        if (value) setSelectedGym({ label: value.label, value: value.value });
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            {isSuperAdmin ? (
                <div className={styles.col}>
                    <Field
                        disabled
                        name="userId"
                        label="ID клиента"
                        component={TextInput}
                    />
                    <div
                        onClick={() => goToAppUserClick()}
                        title="Перейти к пользователю"
                        className={styles.wrap}
                    >
                        <LeftArrow className={styles.iconArrow} />
                    </div>
                </div>
            ) : (
                <Field
                    disabled
                    name="userId"
                    label="ID клиента"
                    component={TextInput}
                />
            )}

            <Field
                disabled
                name="bookingId"
                label="ID тренировки"
                component={TextInput}
            />

            {isGroupWorkout && (
                <div>
                    <Field
                        disabled
                        name="groupWorkoutReservationId"
                        label="ID бронирования"
                        component={TextInput}
                    />
                </div>
            )}

            {paidByMembership && (
                <Field
                    disabled
                    name="membershipId"
                    label="ID абонемента"
                    component={TextInput}
                />
            )}

            {paidBySubscription && (
                <Field
                    disabled
                    name="subscriptionId"
                    label="ID подписки"
                    component={TextInput}
                />
            )}

            <span className={styles.label}>Фитнес-площадка</span>
            <Select
                className={styles.select}
                isDisabled={disabled}
                styles={{
                    input: (provided) => ({
                        ...provided,
                        minHeight: 40,
                    }),
                }}
                defaultValue={gymValue}
                onChange={handleChange}
                options={gymsOptions}
                filterOption={createFilter({
                    stringify: (option) => `${option.label}`,
                })}
                closeMenuOnSelect={false}
                placeholder="Выберите фитнес-площадку"
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: 'rgb(225, 129, 65)',
                    },
                })}
                noOptionsMessage={() => 'Не найдено'}
            />

            {isSuperAdmin && (
                <Field
                    disabled
                    name="appUserFirstName"
                    label="Имя"
                    component={TextInput}
                />
            )}

            {isSuperAdmin && (
                <Field
                    disabled
                    name="appUserLastName"
                    label="Фамилия"
                    component={TextInput}
                />
            )}

            {isSuperAdmin && (
                <Field
                    disabled
                    name="appUserEmail"
                    label="Email"
                    component={TextInput}
                />
            )}

            {isSuperAdmin && (
                <Field
                    disabled
                    name="appUserPhone"
                    label="Телефон"
                    component={TextInput}
                />
            )}

            <Field
                disabled={disabled}
                name="dateStart"
                label="Начало"
                component={DateTimePickerInput}
            />

            <Field
                disabled={disabled}
                name="dateFinish"
                label="Конец"
                component={DateTimePickerInput}
            />

            <Field
                disabled
                name="duration"
                label="Длительность"
                component={TextInput}
            />

            {!paidBySubscription && !paidByMembership && userSubscriptions && (
                <Field
                    disabled={disabled}
                    name="payBysubscription"
                    label="Оплатить подиской?"
                    component={CheckBoxInput}
                />
            )}

            {!paidBySubscription &&
                !paidByMembership &&
                payBysubscriptionValue && (
                    <Field
                        disabled={disabled}
                        name="subscriptionId"
                        label="ID подписки"
                        component={SelectInput}
                        emptyTitle={'Выберите подписку...'}
                        options={userSubscriptionsOptions}
                    />
                )}

            <Field
                disabled
                name="payStatus"
                label="Статус оплаты"
                component={TextInput}
            />

            <Field
                disabled
                name="status"
                label="Статус"
                component={TextInput}
            />

            <Field disabled name="price" label="Цена" component={TextInput} />

            {isSuperAdmin && (isCouponApplied || isBonusWithdrawn) && (
                <Field
                    disabled
                    name="discountPrice"
                    label="Цена со скидкой"
                    component={TextInput}
                />
            )}

            {isSuperAdmin && (isCouponApplied || isBonusWithdrawn) && (
                <Field
                    disabled
                    name="discountType"
                    label="Тип скидки"
                    component={TextInput}
                />
            )}

            {isSuperAdmin && isCouponApplied && (
                <Field
                    disabled
                    name="couponType"
                    label="Тип купона"
                    component={TextInput}
                />
            )}

            {isSuperAdmin && isCouponApplied && (
                <Field
                    disabled
                    name="couponAmount"
                    label="Размер купона"
                    component={TextInput}
                />
            )}

            {isSuperAdmin && (isCouponApplied || isBonusWithdrawn) && (
                <Field
                    disabled
                    name="discountAmount"
                    label="Размер скидки"
                    component={TextInput}
                />
            )}

            {isAborted && (
                <Field
                    disabled
                    name="abortReason"
                    label="Причина отмены"
                    component={TextInput}
                />
            )}

            {isActiveBooking && !isAborted && (
                <Button onClick={onAborted} className={styles.btn}>
                    {btnTitle}
                </Button>
            )}

            {isFinance && !isActiveBooking && userPaymentDatasOptions && (
                <>
                    <Field
                        name="userPaymentDatas"
                        label="Платежные данные пользователя"
                        component={SelectInput}
                        options={userPaymentDatasOptions}
                        emptyTitle={'Все платежные данные пользователя...'}
                        defaultValue={''}
                    />
                    {userPaymentDatasValue && (
                        <Button
                            disabled={btnDisabled}
                            onClick={() =>
                                writeOffCostOfBookings(userPaymentDatasValue)
                            }
                            className={
                                btnDisabled ? styles.disabled : styles.btn
                            }
                        >
                            Списать стоимость тренировки
                        </Button>
                    )}
                </>
            )}

            <Modal active={modalActive} setActive={setModalActive}>
                <h3>Вы действительно хотите удалить тренировку?</h3>
                {isSuperAdmin && (
                    <Button
                        onClick={() => {
                            onDeleteBooking();
                            setModalActive(false);
                        }}
                        type="button"
                        className={styles.btnModal}
                    >
                        Удалить
                    </Button>
                )}
            </Modal>

            {isSuperAdmin && disabled && (
                <Button
                    type="button"
                    onClick={() => setDisabled(false)}
                    className={styles.btn}
                >
                    Изменить тренировку
                </Button>
            )}

            {isSuperAdmin && !disabled && (
                <Button type="submit" className={styles.btn}>
                    Обновить тренировку
                </Button>
            )}

            {isSuperAdmin && (
                <Button
                    type="button"
                    onClick={() => setModalActive(true)}
                    className={styles.btn}
                >
                    Удалить тренировку
                </Button>
            )}
        </form>
    );
};

export default reduxForm<BookingFormData, Props>({
    form: 'bookingInfo',
    validate,
})(BookingForm);
