import { AdCampaign, Coupon } from '@t/coupon';
import { ApplicationState } from '@redux/types';

export const getCoupons = (state: ApplicationState): Coupon[] =>
    state.coupons.ids.map((id: string) => state.coupons.byId[id]);

export const getCoupon = (id: string) => (state: ApplicationState): Coupon =>
    state.coupons.byId[id];

export const getCouponsAdCampaigns = (state: ApplicationState): AdCampaign[] =>
    state.coupons.couponsAdCampaigns;
