import React from 'react';
import { IconProps } from './types';

export const Shape: React.FC<IconProps> = ({ ...props }) => (
    <svg
        width="37"
        height="36"
        viewBox="0 0 37 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M16 12C16 10.067 14.433 8.5 12.5 8.5C10.567 8.5 9 10.067 9 12C9 13.933 10.567 15.5 12.5 15.5C14.433 15.5 16 13.933 16 12Z"
            fill="#F07C2D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.87194 3.37194C1.24619 5.99768 1.0515 8.61367 0.662106 13.8456C0.561167 15.2019 0.5 16.601 0.5 18C0.5 19.399 0.561167 20.7981 0.662107 22.1544C1.0515 27.3863 1.24619 30.0023 3.87194 32.6281C6.49768 35.2538 9.11367 35.4485 14.3456 35.8379C15.7019 35.9388 17.101 36 18.5 36C19.899 36 21.2981 35.9388 22.6544 35.8379C27.8863 35.4485 30.5023 35.2538 33.1281 32.6281C35.7538 30.0023 35.9485 27.3863 36.3379 22.1544C36.4388 20.7981 36.5 19.399 36.5 18C36.5 16.601 36.4388 15.2019 36.3379 13.8456C35.9485 8.61367 35.7538 5.99768 33.1281 3.37194C30.5023 0.746191 27.8863 0.551496 22.6544 0.162106C21.2981 0.0611668 19.899 0 18.5 0C17.101 0 15.7019 0.0611668 14.3456 0.162107C9.11367 0.551496 6.49768 0.746191 3.87194 3.37194ZM18.5 4C17.2156 4 15.9168 4.05623 14.6425 4.15107C9.14181 4.56046 8.11276 4.78797 6.70036 6.20036C5.28797 7.61276 5.06046 8.64181 4.65107 14.1425C4.55623 15.4168 4.5 16.7156 4.5 18C4.5 19.2844 4.55623 20.5832 4.65107 21.8575C4.78543 23.6627 4.9002 24.9864 5.04369 26H19.7735C19.978 25.5623 20.2897 25.028 20.6835 24.3531L22.0536 22.0051C20.6546 21.9951 19.9164 21.9226 19.6151 21.3979C19.2655 20.7891 19.7382 19.9791 20.6835 18.3589L22.4075 15.4044C23.3398 13.8067 23.8059 13.0078 24.5023 13.0078C25.1988 13.0078 25.6649 13.8067 26.5972 15.4044L28.3211 18.3589C29.2665 19.9791 29.7392 20.7891 29.3896 21.3979C29.0883 21.9226 28.35 21.9951 26.9511 22.0051L28.3211 24.3531C28.7149 25.0279 29.0267 25.5622 29.2312 26H31.9563C32.0998 24.9864 32.2146 23.6627 32.3489 21.8575C32.4438 20.5832 32.5 19.2844 32.5 18C32.5 16.7156 32.4438 15.4168 32.3489 14.1425C31.9395 8.64181 31.712 7.61276 30.2996 6.20036C28.8872 4.78797 27.8582 4.56046 22.3575 4.15107C21.0832 4.05623 19.7844 4 18.5 4Z"
            fill="#F07C2D"
        />
    </svg>
);

export default Shape;
