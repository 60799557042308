import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import { getGymMemberships } from '@redux/modules/memberships/selectors';
import Box from '@components/Box';
import BoxButton from '@components/BoxButton';
import { getGymsList, getSelectedGymId } from '@redux/modules/gyms/selectors';
import { fetchGymMemberships } from '@redux/modules/memberships/actions';
import { GymMembership } from '@t/membership';

import GymMembershipListTable, { getDuration } from './GymMembershipListTable';
import styles from './styles.module.css';

export const MembershipList: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const gyms = useSelector(getGymsList);
    const gymMemberships = useSelector(getGymMemberships);
    const selectedGymId = useSelector(getSelectedGymId);
    const [foundGymMemberships, setFoundGymMemberships] = useState<
        GymMembership[] | null
    >(null);

    useEffect(() => {
        dispatch(fetchGymMemberships(selectedGymId));
    }, [dispatch, selectedGymId]);

    const dataForCSV = useMemo(
        () => [
            ['ID Абонемента', 'Тип абонемента', 'Срок действия', 'Цена'],
            ...gymMemberships.map((membership) => [
                membership._id,
                membership.type === 'TIME' ? 'Время' : 'Посещения',
                `${getDuration(membership.type, membership.duration)}`,
                membership.price,
            ]),
        ],
        [gymMemberships]
    );

    const filenameForCSV = `gogym_memberships_${Date.now()}`;

    const onAddMembershipBtnClick = useCallback(
        () => history.push('/dashboard/add-membership'),
        [history]
    );

    const goToGymMemberships = useCallback(
        () => history.push('/dashboard/gym-memberships'),
        [history]
    );

    const goToMemberships = useCallback(
        () => history.push('/dashboard/memberships'),
        [history]
    );

    const gymMembershipsWithGymTitle = gymMemberships.map((gymMembership) => {
        return {
            ...gymMembership,
            gyms: gymMembership.gyms.map((gymId) => {
                const gym = gyms.find((gym) => gym._id === gymId);
                return gym ? gym.title : gymId;
            }),
        };
    });

    const handleGymMemberships = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFoundGymMemberships(
            gymMembershipsWithGymTitle.filter((gymMembership) =>
                gymMembership.gyms
                    .toString()
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
    };

    return (
        <div>
            <h1>Абонементы</h1>

            <h3>Действия</h3>
            <div className={styles.actions}>
                <BoxButton
                    icon="gym"
                    title="Добавить абонемент"
                    onClick={onAddMembershipBtnClick}
                    className={styles.actionBtn}
                />

                <CSVLink filename={filenameForCSV} data={dataForCSV}>
                    <BoxButton
                        icon="spreadsheet"
                        title="Выгрузить данные"
                        onClick={() => {
                            // do nothing
                        }}
                        className={styles.actionBtn}
                    />
                </CSVLink>
            </div>

            <div className={styles.membershipsNav}>
                <BoxButton
                    icon=""
                    title="Абонементы фитнес-площадок"
                    onClick={goToGymMemberships}
                    className={styles.actionNav}
                />

                <BoxButton
                    icon=""
                    title="Абонементы пользователей"
                    onClick={goToMemberships}
                />
            </div>

            <input
                className={styles.input}
                type="search"
                placeholder="Поиск по названию фитнес-площадки"
                onChange={handleGymMemberships}
            />

            <Box className={styles.container}>
                <div className={styles.tableWrapper}>
                    {foundGymMemberships ? (
                        <GymMembershipListTable
                            gymMemberships={foundGymMemberships}
                        />
                    ) : (
                        <GymMembershipListTable
                            gymMemberships={gymMembershipsWithGymTitle}
                        />
                    )}
                </div>
            </Box>
        </div>
    );
};

export default MembershipList;
