import { AnyAction } from 'redux';

import {
    FETCH_BOOKINGS_STATISTICS_SUCCESS,
    FETCH_HR_STATISTICS_SUCCESS,
    FilterState,
    FILTER_HR_USERS_SUCCESS,
    FILTER_SUCCESS,
} from './types';

const initialState: FilterState = {
    dailyIncome: [],
    dailyBookings: [],
    filterRevenue: 0,
    numOfFilterBookings: 0,
    allBookings: 0,
    activeBookings: 0,
    totalRevenue: 0,
    allUsers: 0,
    totalBooking: 0,
    totalBookingTime: 0,
    averageBookingTime: 0,
    totalNumberOfVisitedClubs: 0,
    favoriteClub: '一',
    totalCostOfBooking: 0,
    averageCostOfBooking: 0,
    mostActiveEmployee: '一',
    dailyUsers: [],
    totalUsers: 0,
    dailyLastUpdated: 0,
    allLastUpdated: 0,
    HRLastUpdated: 0,
};

export function filterReducer(
    state = initialState,
    action: AnyAction
): FilterState {
    if (action.type === FILTER_SUCCESS) {
        return {
            ...state,
            dailyIncome: action.payload.dailyIncome,
            dailyBookings: action.payload.dailyBookings,
            filterRevenue: action.payload.filterRevenue,
            numOfFilterBookings: action.payload.numOfFilterBookings,
            dailyLastUpdated: Date.now(),
        };
    }

    if (action.type === FETCH_BOOKINGS_STATISTICS_SUCCESS) {
        return {
            ...state,
            allBookings: action.payload.allBookings,
            activeBookings: action.payload.activeBookings,
            totalRevenue: action.payload.totalRevenue,
            allLastUpdated: Date.now(),
        };
    }

    if (action.type === FETCH_HR_STATISTICS_SUCCESS) {
        return {
            ...state,
            allUsers: action.payload.allUsers,
            totalBooking: action.payload.totalBooking,
            totalBookingTime: action.payload.totalBookingTime,
            averageBookingTime: action.payload.averageBookingTime,
            totalNumberOfVisitedClubs: action.payload.totalNumberOfVisitedClubs,
            favoriteClub: action.payload.favoriteClub,
            totalCostOfBooking: action.payload.totalCostOfBooking,
            averageCostOfBooking: action.payload.averageCostOfBooking,
            mostActiveEmployee: action.payload.mostActiveEmployee,
            HRLastUpdated: Date.now(),
        };
    }

    if (action.type === FILTER_HR_USERS_SUCCESS) {
        return {
            ...state,
            dailyUsers: action.payload.dailyUsers,
            totalUsers: action.payload.totalUsers,
            HRLastUpdated: Date.now(),
        };
    }

    return state;
}
