import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { AsyncDispatch } from '@redux/types';
import BackButton from '@components/BackButton';
import { getGymMembership } from '@redux/modules/memberships/selectors';
import {
    deleteDocs,
    deleteGymMembership,
    fetchGymMembership,
    updateGymMembership,
    uploadDocs,
} from '@redux/modules/memberships/actions';
import { getGymsList } from '@redux/modules/gyms/selectors';

import styles from './styles.module.css';
import GymMembershipForm from './Form';
import { getKeyFromUrl } from '../GymDetails';

const MAX_LENGTH = 3;
const MAX_SIZE = 20000000;

export const GymMembershipDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const gyms = useSelector(getGymsList);
    const gymMembership = useSelector(getGymMembership(id || ''));
    const [selectedGyms, setSelectedGyms] = useState<string[]>(
        gymMembership?.gyms
    );
    const [disabled, setDisabled] = useState(true);
    const [isLoading, updateIsLoading] = useState<boolean>(false);
    const [isDeleting, updateIsDeleting] = useState<boolean>(false);
    const fileInput = useRef<null>(null);

    const gymsMembershipOfferUrls =
        gymMembership?.offer.filter((src) => src !== '') || [];

    useEffect(() => {
        if (!id) {
            return;
        }
        dispatch(fetchGymMembership(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (!gymMembership) {
            return;
        }
        setSelectedGyms(gymMembership.gyms);
    }, [gymMembership]);

    const onSubmit = useCallback(
        (values) => {
            if (!id) {
                return;
            }
            dispatch(updateGymMembership({ id, values, gyms: selectedGyms }))
                .then((gymMembership) => {
                    if (gymMembership) {
                        cogoToast.success('Абонемент обновлен', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                        setDisabled(true);
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при обновлении абонемента', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                });
        },
        [id, dispatch, selectedGyms]
    );

    const onDeleteGymMembership = useCallback(() => {
        if (!id) {
            return;
        }
        dispatch(deleteGymMembership(id))
            .then(() => {
                cogoToast.success('Абонемент удален', {
                    position: 'top-right',
                    hideAfter: 5,
                });
                history.push(`/dashboard/gym-memberships`);
            })
            .catch(() => {
                cogoToast.error('Ошибка при удалении абонемента', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    }, [dispatch, id]);

    const onUploadDocs = useCallback(async () => {
        const files = (fileInput.current || { files: new FileList() }).files;
        if (!files.length) {
            cogoToast.error(`Приложите файлы для загрузки`, {
                position: 'top-right',
                hideAfter: 4,
            });
            return;
        }
        updateIsLoading(true);
        await dispatch(uploadDocs({ files, membershipId: id }));
        updateIsLoading(false);
        setDisabled(true);
        const form: any = Array.from(
            document.getElementsByClassName('docs-input')
        );
        form[0][0].value = null;
    }, [dispatch]);

    const uploadMultipleFiles = (event: any) => {
        const numOfFiles = Array.from(event.target.files).length;
        if (!numOfFiles) {
            event.preventDefault();
            cogoToast.error(`Приложите файлы для загрузки`, {
                position: 'top-right',
                hideAfter: 4,
            });
            event.target.value = null;
            return;
        }
        if (numOfFiles + gymsMembershipOfferUrls.length > MAX_LENGTH) {
            event.preventDefault();
            cogoToast.error(`Нельзя загрузить более ${MAX_LENGTH} файлов`, {
                position: 'top-right',
                hideAfter: 4,
            });
            event.target.value = null;
            return;
        }
        for (let i = 0; i < numOfFiles; ++i) {
            const validExtensions = ['pdf'];
            const fileExtension = event.target.files[i].type.split('/')[1];
            const fileSize = event.target.files[i].size;

            if (!validExtensions.includes(fileExtension)) {
                event.preventDefault();
                cogoToast.error(`Можно загрузить файлы с расширением pdf`, {
                    position: 'top-right',
                    hideAfter: 4,
                });
                event.target.value = null;
                return;
            }

            if (fileSize > MAX_SIZE) {
                event.preventDefault();
                cogoToast.error(
                    `Нельзя загрузить файл размера более 20MB, файл "${event.target.files[i].name}" слишком большой`,
                    {
                        position: 'top-right',
                        hideAfter: 4,
                    }
                );
                event.target.value = null;
                return;
            }
        }
    };

    const onDeleteDocsClick = useCallback(
        async (event) => {
            try {
                if (!id) {
                    return;
                }
                updateIsDeleting(true);
                const s3Key: string = getKeyFromUrl(event.target.id);
                await dispatch(deleteDocs({ membershipId: id, key: s3Key }));
                updateIsDeleting(false);
            } catch (err) {
                cogoToast.error(`Ошибка при удалении документа: ${err}`, {
                    position: 'top-right',
                    hideAfter: 4,
                });
            }
        },
        [id, dispatch]
    );

    if (!gymMembership) {
        return null;
    }

    const initialValues = {
        membershipId: gymMembership._id,
        active: gymMembership.active,
        type: gymMembership.type,
        gyms: gymMembership.gyms,
        duration: gymMembership.duration,
        limit: gymMembership.limit,
        price: gymMembership.price,
        gymPrice: gymMembership.gymPrice,
        comment: gymMembership.comment,
    };

    const gymsOptions = gyms
        .filter((gym) => !gym.isHidden)
        .map((gym) => {
            return {
                label: gym.title,
                value: gym._id,
            };
        });

    const gymsValue = gymsOptions.filter((gymsOption) =>
        gymMembership.gyms.includes(gymsOption.value)
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <BackButton
                    to="/dashboard/gyms-memberships"
                    title="К списку абонементов"
                    className={styles.backBtn}
                />
                {gymsOptions.length > 0 && (
                    <div className={styles.box}>
                        <GymMembershipForm
                            form="GymMembershipInfo"
                            onSubmit={onSubmit}
                            initialValues={initialValues}
                            gymsOptions={gymsOptions}
                            gymsValue={gymsValue}
                            onDeleteGymMembership={onDeleteGymMembership}
                            setSelectedGyms={setSelectedGyms}
                            fileInput={fileInput}
                            onUploadDocs={onUploadDocs}
                            onDeleteDocsClick={onDeleteDocsClick}
                            gymsMembershipOfferUrls={gymsMembershipOfferUrls}
                            uploadMultipleFiles={uploadMultipleFiles}
                            isLoading={isLoading}
                            disabled={disabled}
                            setDisabled={setDisabled}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default GymMembershipDetails;
