import React from 'react';
import { IconProps } from './types';

export const Added: React.FC<IconProps> = ({ ...props }) => (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g>
            <g id="Icon-Plus" transform="translate(28.000000, 278.000000)">
                <path
                    d="M4-222.1c-13.2,0-23.9-10.7-23.9-23.9c0-13.2,10.7-23.9,23.9-23.9s23.9,10.7,23.9,23.9     C27.9-232.8,17.2-222.1,4-222.1L4-222.1z M4-267.3c-11.7,0-21.3,9.6-21.3,21.3s9.6,21.3,21.3,21.3s21.3-9.6,21.3-21.3     S15.7-267.3,4-267.3L4-267.3z"
                    id="Fill-38"
                />
                <polyline
                    id="Fill-39"
                    points="-8.7,-247.4 16.7,-247.4 16.7,-244.6 -8.7,-244.6    "
                />
                <polyline
                    id="Fill-40"
                    points="2.6,-258.7 5.4,-258.7 5.4,-233.3 2.6,-233.3    "
                />
            </g>
        </g>
    </svg>
);

export default Added;
