import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    SyntheticEvent,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select, { createFilter } from 'react-select';
import cogoToast from 'cogo-toast';

import {
    getCoupons,
    getCouponsAdCampaigns,
} from '@redux/modules/coupons/selectors';
import Box from '@components/Box';
import BoxButton from '@components/BoxButton';
import {
    fetchCoupons,
    fetchCouponsAdCampaigns,
    fetchScrollCoupons,
    searchCoupons,
} from '@redux/modules/coupons/actions';
import { AsyncDispatch } from '@redux/types';
import Button from '@components/Button';

import CouponsListTable from './CouponsListTable';
import styles from './styles.module.css';

export const CouponsList: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const coupons = useSelector(getCoupons);
    const couponsAdCampaigns = useSelector(getCouponsAdCampaigns);
    const LSselectedAdCampain = localStorage.getItem('selectedAdCampain');
    const [selectedAdCampain, setSelectedAdCampain] = useState<{
        label: string;
        value: string;
    }>(
        LSselectedAdCampain
            ? JSON.parse(LSselectedAdCampain)
            : { label: '', value: '' }
    );

    const [fetching, setFetching] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [couponIsOver, setCouponIsOver] = useState<boolean>(false);

    const couponSearchingField = useRef<any>();

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    useEffect(() => {
        dispatch(fetchCouponsAdCampaigns());
        if (selectedAdCampain) {
            dispatch(
                fetchCoupons({
                    adCampaignId: selectedAdCampain.value,
                    couponCount: 0,
                })
            )
                .then((coupon) => {
                    if (coupon.length < 99) {
                        setLoading(false);
                        setCouponIsOver(true);
                    } else {
                        setLoading(false);
                        setCouponIsOver(false);
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при загрузке промокодов', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => setFetching(false));
        } else {
            dispatch(
                fetchCoupons({
                    adCampaignId: '',
                    couponCount: 0,
                })
            )
                .then((coupon) => {
                    if (coupon.length < 99) {
                        setLoading(false);
                        setCouponIsOver(true);
                    } else {
                        setLoading(false);
                        setCouponIsOver(false);
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при загрузке промокодов', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => setFetching(false));
        }
    }, [dispatch, selectedAdCampain]);

    useEffect(() => {
        if (fetching && !couponIsOver && selectedAdCampain.value) {
            setLoading(true);
            dispatch(
                fetchScrollCoupons({
                    adCampaignId: selectedAdCampain.value,
                    couponCount: coupons.length,
                })
            )
                .then((coupon) => {
                    if (coupon.length < 99) {
                        setLoading(false);
                        setCouponIsOver(true);
                    } else {
                        setLoading(false);
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при загрузке промокодов', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => setFetching(false));
        } else if (fetching && !couponIsOver && !selectedAdCampain.value) {
            setLoading(true);
            dispatch(
                fetchScrollCoupons({
                    adCampaignId: '',
                    couponCount: coupons.length,
                })
            )
                .then((coupon) => {
                    if (coupon.length < 99) {
                        setLoading(false);
                        setCouponIsOver(true);
                    } else {
                        setLoading(false);
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при загрузке промокодов', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => setFetching(false));
        }
    }, [dispatch, fetching]);

    const scrollHandler = (event: any) => {
        if (
            event.target.documentElement.scrollHeight -
                (event.target.documentElement.scrollTop + window.innerHeight) <
                400 &&
            event.target.documentElement.scrollTop > 1000
        ) {
            setFetching(true);
        }
    };

    const handleChange = (value: any) => {
        setSelectedAdCampain(value);
        localStorage.setItem('selectedAdCampain', JSON.stringify(value));
    };

    const onAddCouponBtnClick = useCallback(
        () => history.push('/dashboard/add-coupon'),
        [history]
    );

    const onAddCouponsBtnClick = useCallback(
        () => history.push('/dashboard/add-coupons'),
        [history]
    );

    const adCampaignsOptions = couponsAdCampaigns.map((adCampaign) => {
        return {
            label: adCampaign.name,
            value: adCampaign._id,
        };
    });

    const adCampaignDefaultValue =
        LSselectedAdCampain && JSON.parse(LSselectedAdCampain);

    const handleSearchSubmit = (event: SyntheticEvent) => {
        event.preventDefault();
        const value = couponSearchingField.current?.value;
        dispatch(searchCoupons({ couponName: value }))
            .then((coupon) => {
                setLoading(false);
                setCouponIsOver(true);
                if (!coupon.length) {
                    cogoToast.error('Промокод не найден', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                }
            })
            .catch(() => {
                cogoToast.error('Ошибка при поиске промокода', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    };

    return (
        <div>
            <h1>Промокоды</h1>
            <h3>Действия</h3>
            <div className={styles.actions}>
                <BoxButton
                    icon="gym"
                    title="Добавить промокод"
                    onClick={onAddCouponBtnClick}
                    className={styles.actionBtn}
                />
                <BoxButton
                    icon="gym"
                    title="Добавить промокоды"
                    onClick={onAddCouponsBtnClick}
                    className={styles.actionBtn}
                />
            </div>
            <div className={styles.adCampaign}>
                <Select
                    className={styles.select}
                    styles={{
                        input: (provided) => ({
                            ...provided,
                            minHeight: 40,
                        }),
                    }}
                    onChange={handleChange}
                    defaultValue={adCampaignDefaultValue}
                    options={[
                        { label: 'Без рекламной кампании', value: '' },
                    ].concat(adCampaignsOptions)}
                    filterOption={createFilter({
                        stringify: (option) => `${option.label}`,
                    })}
                    placeholder="Выберите рекламную кампанию"
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: 'rgb(225, 129, 65)',
                        },
                    })}
                    noOptionsMessage={() => 'Не найдено'}
                />
            </div>
            <form className={styles.wpapper} onSubmit={handleSearchSubmit}>
                <input
                    className={styles.input}
                    // onChange={onChangeSearchGyms}
                    type="search"
                    name="value"
                    placeholder="Поиск по названию"
                    ref={couponSearchingField}
                />
                <Button
                    onClick={handleSearchSubmit}
                    type="button"
                    className={styles.btn}
                >
                    Поиск
                </Button>
            </form>
            <Box className={styles.container}>
                <div className={styles.tableWrapper}>
                    <CouponsListTable coupons={coupons} loading={loading} />
                </div>
            </Box>
        </div>
    );
};

export default CouponsList;
