import { AnyAction } from 'redux';

import {
    AuthState,
    FETCH_USER_SUCCESS,
    SIGN_IN_SUCCESS,
    SIGN_OUT,
    SIGN_UP_SUCCESS,
    SIGN_IN_2FA_SUCCESS,
    SIGN_IN_2FA_URL_SUCCESS,
} from './types';

const initialState: AuthState = {
    token: '',
    token2fa: '',
    dataUrl: '',
    qrVerified: true,
    user: null,
};

export function authReducer(
    state = initialState,
    action: AnyAction
): AuthState {
    if (action.type === SIGN_OUT) {
        return {
            ...state,
            token: '',
            token2fa: '',
            dataUrl: '',
            user: null,
        };
    }

    if (action.type === SIGN_IN_SUCCESS) {
        const { token } = action.payload;

        return {
            ...state,
            token,
        };
    }

    if (action.type === SIGN_IN_2FA_URL_SUCCESS) {
        const { dataUrl, token2fa, qrVerified } = action.payload;

        return {
            ...state,
            dataUrl,
            token2fa,
            qrVerified,
        };
    }

    if (action.type === SIGN_IN_2FA_SUCCESS) {
        const { token } = action.payload;

        return {
            ...state,
            token,
        };
    }

    if (action.type === SIGN_UP_SUCCESS) {
        const { token } = action.payload;

        return {
            ...state,
            token,
        };
    }

    if (action.type === FETCH_USER_SUCCESS) {
        const { user } = action.payload;
        delete user.user.secret;

        return {
            ...state,
            user: user.user,
        };
    }

    return state;
}
