import { AnyAction } from 'redux';

import {
    FETCH_GROUP_WORKOUTS_SCHEDULE_SUCCESS,
    FETCH_GROUP_WORKOUT_DETAIL_SUCCESS,
    FETCH_GROUP_WORKOUT_SUCCESS,
    FETCH_GYM_GROUP_WORKOUTS_SUCCESS,
    GroupWorkoutsState,
    SET_CALENDAR_VIEW,
} from './types';
import { GroupWorkoutNew } from '@t/gym';

const initialState: GroupWorkoutsState = {
    calendarView: 'week',
    ids: [],
    byId: {},
    calendar: [],
    detail: {},
};

export function groupWorkoutsReducer(
    state = initialState,
    action: AnyAction
): GroupWorkoutsState {
    if (action.type === SET_CALENDAR_VIEW) {
        return {
            ...state,
            calendarView: action.payload,
        };
    }

    if (action.type === FETCH_GYM_GROUP_WORKOUTS_SUCCESS) {
        const groupWorkouts = action.payload;
        const ids = groupWorkouts.map((gym: GroupWorkoutNew) => gym._id);
        const byId = groupWorkouts.reduce(
            (
                total: { [id: string]: GroupWorkoutNew },
                groupWorkout: GroupWorkoutNew
            ) => ({
                ...total,
                [groupWorkout._id]: groupWorkout,
            }),
            {}
        );

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === FETCH_GROUP_WORKOUT_SUCCESS) {
        const groupWorkout = action.payload;
        const included = state.ids.includes(groupWorkout._id);
        const ids = included ? state.ids : [...state.ids, groupWorkout._id];
        const byId = {
            ...state.byId,
            [groupWorkout._id]: groupWorkout,
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === FETCH_GROUP_WORKOUT_DETAIL_SUCCESS) {
        const groupWorkout = action.payload;
        const detail = {
            ...state.detail,
            [groupWorkout._id]: groupWorkout,
        };

        return {
            ...state,
            detail,
        };
    }

    if (action.type === FETCH_GROUP_WORKOUTS_SCHEDULE_SUCCESS) {
        const groupWorkouts = action.payload;

        return {
            ...state,
            calendar: groupWorkouts,
        };
    }

    return state;
}
