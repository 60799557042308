import React, { useEffect } from 'react';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import { WrappedFieldInputProps, WrappedFieldProps } from 'redux-form';
import TimePicker from 'rc-time-picker';

import styles from './styles.module.css';

export interface Props {
    label: string;
    name: string;
    type: 'email' | 'text';
    availableHours?: string[][];
    placeholder?: string;
    required?: boolean;
    defaultValue?: string;
    originDate?: string;
    disableMinutes?: boolean;
    className?: string;
}

const getDisabledHours = (
    input: WrappedFieldInputProps,
    availableHours: string[][] | undefined
): number[] => {
    let disabledHours = [];

    const day = moment(input.value).day();

    const openHour: number =
        availableHours && availableHours.length
            ? +availableHours[day][0].split(':')[0]
            : 0;
    disabledHours = Array.from(
        Array(openHour - 1 > 0 ? openHour - 1 : 0).keys()
    );
    const closeHour: number =
        availableHours && availableHours.length
            ? +availableHours[day][1].split(':')[0]
            : 23;
    for (let exceedHour = closeHour + 1; exceedHour < 24; ++exceedHour) {
        disabledHours.push(exceedHour);
    }

    return disabledHours;
};

const TimeInput: React.FC<Props & WrappedFieldProps> = ({
    input,
    meta,
    name,
    availableHours,
    originDate,
    label,
    defaultValue,
    disableMinutes,
    className,
}) => {
    let disabledHours: number[] = [];
    if (input.value) {
        disabledHours = getDisabledHours(input, availableHours);
    }

    useEffect(() => {
        const date = moment(input.value);

        if (originDate) {
            const origin = moment(originDate);
            date.set('year', origin.get('year'));
            date.set('month', origin.get('month'));
            date.set('date', origin.get('date'));
        }

        input.onChange(date.toISOString());
    }, [originDate]);

    input.value = input.value
        ? moment(input.value).utcOffset(0)
        : defaultValue
        ? moment(defaultValue).utcOffset(0)
        : moment().utcOffset(0).startOf('day');
    const value = input.value;
    const onChange = (newValue: Moment) => {
        const date = moment(newValue);

        if (originDate) {
            const origin = moment(originDate);
            date.set('year', origin.get('year'));
            date.set('month', origin.get('month'));
            date.set('date', origin.get('date'));
        }
        input.onChange(date.toISOString());
    };

    return (
        <div className={classNames(styles.wrapper, className)}>
            <label htmlFor={name} className={styles.label}>
                {label}
            </label>
            <TimePicker
                showSecond={false}
                showMinute={!disableMinutes}
                value={value}
                onChange={onChange}
                disabledHours={() => disabledHours}
                hideDisabledOptions
                className={styles.input}
                clearIcon={null}
            />
            {meta && meta.touched && meta.error && (
                <div className={styles.error}>{meta.error}</div>
            )}
        </div>
    );
};

export default TimeInput;
