import React, { useCallback, useState, useRef } from 'react';
import {
    InjectedFormProps,
    Field,
    reduxForm,
    formValueSelector,
    change,
} from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import TextInput from '@components/TextInput';
import Button from '@components/Button';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import SelectInput from '@components/SelectInput';
import { CouponType, CouponTypeOptions } from '@t/coupon';
import TextAreaInput from '@components/TextAreaInput';
import { ApplicationState } from '@redux/types';
import Dice from '@components/Icons/Dice';
import MultiSelectInput from '@components/MultiSelectInput';
import CheckBoxInput from '@components/CheckBoxInput';
import DateTimePickerInput from '@components/DateTimePickerInput';

import styles from './styles.module.css';

export interface FormData {
    couponName: string;
    couponType: CouponType;
    discount: number;
    activatedByUserId: string;
    activatedStart: Date;
    activatedEnd: Date;
    activatedCount: number;
    actionStart: string | Date;
    actionEnd: string | Date;
    adCampaignId: string;
    unlimited?: boolean;
    limitPerUser?: number;
    gymIds?: string[];
    userIds?: string;
    active: boolean;
    users?: Array<string>;
    initialPrice?: number;
    bookingDuration?: number;
    comment: string;
    perMinWorkouts: boolean;
    groupWorkouts: boolean;
}

export interface Props {
    gymsOptions: {
        title: string;
        value: string;
    }[];
    couponsAdCampaignsOptions: {
        title: string;
        value: string;
    }[];
}

const validate = (values: FormData) => {
    const errors: {
        couponName: string;
        couponType: string;
        discount: string;
        limitPerUser: string;
        initialPrice: string;
        bookingDuration: string;
        active: string;
        perMinWorkouts: string;
        groupWorkouts: string;
    } = {
        couponName: '',
        couponType: '',
        discount: '',
        limitPerUser: '',
        initialPrice: '',
        bookingDuration: '',
        active: '',
        perMinWorkouts: '',
        groupWorkouts: '',
    };

    if (!values.couponName) {
        errors.couponName = 'Обязательное поле';
    }

    if (!values.couponType) {
        errors.couponType = 'Обязательное поле';
    }

    if (isNaN(Number(values.discount))) {
        errors.discount = 'Должно быть числом';
    }

    if (isNaN(Number(values.limitPerUser))) {
        errors.limitPerUser = 'Должно быть числом';
    }

    if (isNaN(Number(values.initialPrice))) {
        errors.initialPrice = 'Должно быть числом';
    }

    if (isNaN(Number(values.bookingDuration))) {
        errors.bookingDuration = 'Должно быть числом';
    }

    if (!values.active) {
        errors.active = 'Обязательное поле';
    }

    if (!values.perMinWorkouts && !values.groupWorkouts) {
        errors.perMinWorkouts = 'Обязательное поле';
        errors.groupWorkouts = 'Обязательное поле';
    }

    return errors;
};

const AddCouponForm: React.FC<Props & InjectedFormProps<FormData, Props>> = ({
    handleSubmit,
    gymsOptions,
    couponsAdCampaignsOptions,
}) => {
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;

    const selector = formValueSelector('addCoupon');
    const couponTypeSelector = (state: ApplicationState) =>
        selector(state, 'couponType');
    const couponAdCampaignSelector = (state: ApplicationState) =>
        selector(state, 'adCampaignId');
    const couponType = useSelector(couponTypeSelector);
    const couponAdCampaign = useSelector(couponAdCampaignSelector);
    const isPercentOff = couponType === CouponType.percentOff;
    const isAmount = couponType === CouponType.amount;

    const [foundGyms, setFoundGyms] = useState<any>();
    const gymsSearchingField = useRef<any>();

    const onGenerateName = useCallback(() => {
        dispatch(
            change(
                'addCoupon',
                'couponName',
                `GOGYM-${Math.random().toString(36).slice(-5).toUpperCase()}`
            )
        );
    }, [dispatch]);

    const handleSearchGyms = () => {
        setFoundGyms(
            gymsOptions.filter((gymsOption) =>
                gymsOption.title
                    .toLowerCase()
                    .includes(gymsSearchingField.current.value.toLowerCase())
            )
        );
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Field
                name="couponType"
                label="Тип"
                placeholder="Тип"
                emptyTitle={'Выберите тип промокода...'}
                component={SelectInput}
                options={CouponTypeOptions}
            />

            {isAmount && (
                <Field
                    name="adCampaignId"
                    label="Рекламная кампания"
                    placeholder="Рекламная кампания"
                    emptyTitle={'Выберите рекламную кампанию...'}
                    component={SelectInput}
                    options={couponsAdCampaignsOptions}
                />
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <div className={styles.col}>
                    <Field
                        name="couponName"
                        label="Название"
                        placeholder="Название"
                        component={TextInput}
                    />
                    <div
                        onClick={() => onGenerateName()}
                        title="Сгенерировать название"
                        className={styles.wrap}
                    >
                        <Dice className={styles.icon} />
                    </div>
                </div>
            )}

            {isPercentOff && (
                <Field
                    name="discount"
                    label="Скидка"
                    placeholder="Скидка"
                    component={TextInput}
                />
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <Field
                    name="limitPerUser"
                    label="Количество тренировок"
                    placeholder="Количество тренировок"
                    component={TextInput}
                />
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <Field
                    name="activatedCount"
                    label="Сколько человек может использовать"
                    placeholder="Сколько человек может использовать"
                    component={TextInput}
                />
            )}

            {isAmount && couponAdCampaign && (
                <Field
                    name="initialPrice"
                    label="Сумма промокода"
                    placeholder="Сумма промокода"
                    component={TextInput}
                />
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <Field
                    name="actionStart"
                    label="Дата начала действия"
                    placeholder="Дата начала действия"
                    component={DateTimePickerInput}
                />
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <Field
                    name="actionEnd"
                    label="Дата конца действия"
                    placeholder="Дата конца действия"
                    component={DateTimePickerInput}
                />
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && foundGyms ? (
                <Field
                    name="gymIds"
                    label="Фитнес-площадки"
                    component={MultiSelectInput}
                    options={foundGyms}
                    defaultValue={''}
                />
            ) : (
                ((isAmount && couponAdCampaign) || isPercentOff) && (
                    <Field
                        name="gymIds"
                        label="Фитнес-площадки"
                        component={MultiSelectInput}
                        options={gymsOptions}
                        defaultValue={''}
                    />
                )
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <input
                    className={styles.input}
                    type="search"
                    placeholder="Поиск по названию"
                    ref={gymsSearchingField}
                    onChange={handleSearchGyms}
                />
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <Field
                    name="userIds"
                    label="Пользователи промокода"
                    placeholder="ID пользователя через ;"
                    component={TextInput}
                />
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <Field
                    name="comment"
                    label="Комментарий"
                    placeholder="Комментарий для промокода"
                    component={TextAreaInput}
                />
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <Field
                    name="unlimited"
                    label="Неограниченный"
                    component={CheckBoxInput}
                />
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <Field
                    name="perMinWorkouts"
                    label="Для поминутных тренировок"
                    component={CheckBoxInput}
                />
            )}

            {((isAmount && couponAdCampaign) || isPercentOff) && (
                <Field
                    name="groupWorkouts"
                    label="Для занятий"
                    component={CheckBoxInput}
                />
            )}

            {isSuperAdmin && (
                <Button type="submit" className={styles.btn}>
                    Добавить
                </Button>
            )}
        </form>
    );
};

export default reduxForm<FormData, Props>({
    form: 'addCoupon',
    validate,
})(AddCouponForm);
