export const FILTER_REQUEST = 'FILTER_REQUEST';
export const FILTER_SUCCESS = 'FILTER_SUCCESS';
export const FILTER_ERROR = 'FILTER_ERROR';

export const FETCH_BOOKINGS_STATISTICS_REQUEST =
    'FETCH_BOOKINGS_STATISTICS_REQUEST';
export const FETCH_BOOKINGS_STATISTICS_SUCCESS =
    'FETCH_BOOKINGS_STATISTICS_SUCCESS';
export const FETCH_BOOKINGS_STATISTICS_ERROR =
    'FETCH_BOOKINGS_STATISTICS_ERROR';

export const FETCH_HR_STATISTICS_REQUEST = 'FETCH_HR_STATISTICS_REQUEST';
export const FETCH_HR_STATISTICS_SUCCESS = 'FETCH_HR_STATISTICS_SUCCESS';
export const FETCH_HR_STATISTICS_ERROR = 'FETCH_HR_STATISTICS_ERROR';

export const FILTER_HR_USERS_REQUEST = 'FILTER_HR_USERS_REQUEST';
export const FILTER_HR_USERS_SUCCESS = 'FILTER_HR_USERS_SUCCESS';
export const FILTER_HR_USERS_ERROR = 'FILTER_HR_USERS_ERROR';

export interface FilterState {
    dailyIncome: { date: Date; revenue: number }[];
    dailyBookings: { date: Date; bookings: number }[];
    filterRevenue: number;
    numOfFilterBookings: number;
    allBookings: number;
    activeBookings: number;
    totalRevenue: number;
    allUsers: number;
    totalBooking: number;
    totalBookingTime: number;
    averageBookingTime: number;
    totalNumberOfVisitedClubs: number;
    favoriteClub: string;
    totalCostOfBooking: number;
    averageCostOfBooking: number;
    mostActiveEmployee: string;
    dailyUsers: { date: Date; users: number }[];
    totalUsers: number;
    dailyLastUpdated: number;
    allLastUpdated: number;
    HRLastUpdated: number;
}
