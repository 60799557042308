import axios from 'axios';

import { B2B_API_URL } from '@config';
import { AsyncActionCreator } from '@redux/types';
import { Config } from '@t/config';

import {
    FETCH_CONFIG_REQUEST,
    FETCH_CONFIG_SUCCESS,
    FETCH_CONFIG_ERROR,
} from './types';

export const fetchConfig: AsyncActionCreator<void, Config> = () => async (
    dispatch,
    getState
) => {
    dispatch({
        type: FETCH_CONFIG_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: config } = await axios.get(`${B2B_API_URL}/configs`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: FETCH_CONFIG_SUCCESS,
            payload: config,
        });

        return config;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_CONFIG_ERROR,
            });

            throw err;
        }
    }
};
