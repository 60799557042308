import { AnyAction } from 'redux';

import { AppUser } from '@t/app-user';
import { GymMembership, Membership } from '@t/membership';

import {
    FETCH_MEMBERSHIPS_SUCCESS,
    MembershipState,
    FETCH_MEMBERSHIP_SUCCESS,
    FETCH_GYM_MEMBERSHIP_SUCCESS,
    FETCH_GYM_MEMBERSHIPS_SUCCESS,
    GymMembershipState,
    FETCH_MEMBERSHIPS_USERS_SUCCESS,
    FETCH_MEMBERSHIP_USER_SUCCESS,
    UPDATE_GYM_MEMBERSHIP_SUCCESS,
    UPLOAD_GYM_MEMBERSHIP_DOCS_SUCCESS,
    DELETE_GYM_MEMBERSHIP_DOCS_SUCCESS,
} from './types';

const initialStateMembership: MembershipState = {
    ids: [],
    byId: {},
    usersByMembershipId: {},
    lastUpdated: 0,
};

const initialStateGymMembership: GymMembershipState = {
    ids: [],
    byId: {},
    lastUpdated: 0,
};

export function membershipsReducer(
    state = initialStateMembership,
    action: AnyAction
): MembershipState {
    if (action.type === FETCH_MEMBERSHIP_SUCCESS) {
        const membership = action.payload;
        const included = state.ids.includes(membership._id);
        const ids = included ? state.ids : [...state.ids, membership._id];
        const byId = {
            ...state.byId,
            [membership._id]: membership,
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === FETCH_MEMBERSHIPS_SUCCESS) {
        const { memberships } = action.payload;
        const ids = memberships.map((gym: Membership) => gym._id);
        const byId = memberships.reduce(
            (total: { [id: string]: Membership }, gym: Membership) => ({
                ...total,
                [gym._id]: gym,
            }),
            {}
        );
        const lastUpdated = Date.now();

        return {
            ...state,
            ids,
            byId,
            lastUpdated,
        };
    }

    if (action.type === FETCH_MEMBERSHIPS_USERS_SUCCESS) {
        const { memberships, users } = action.payload;
        const usersByMembershipId = memberships.reduce(
            (total: { [id: string]: Membership }, membership: Membership) => ({
                ...total,
                [membership._id]: users.find(
                    (user: AppUser) => user._id === membership.userId
                ),
            }),
            {}
        );

        return {
            ...state,
            usersByMembershipId,
        };
    }

    if (action.type === FETCH_MEMBERSHIP_USER_SUCCESS) {
        const { membership, user } = action.payload;
        const included = state.ids.includes(membership._id);
        const ids = included ? state.ids : [...state.ids, membership._id];
        const byId = {
            ...state.byId,
            [membership._id]: membership,
        };

        const usersByMembershipId = {
            ...state.usersByMembershipId,
            [membership._id]: user,
        };

        return {
            ...state,
            ids,
            byId,
            usersByMembershipId,
        };
    }

    return state;
}

export function gymMembershipsReducer(
    state = initialStateGymMembership,
    action: AnyAction
): GymMembershipState {
    if (action.type === FETCH_GYM_MEMBERSHIP_SUCCESS) {
        const gymMemberships = action.payload;
        const included = state.ids.includes(gymMemberships._id);
        const ids = included ? state.ids : [...state.ids, gymMemberships._id];
        const byId = {
            ...state.byId,
            [gymMemberships._id]: gymMemberships,
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === FETCH_GYM_MEMBERSHIPS_SUCCESS) {
        const { gymMemberships } = action.payload;
        const ids = gymMemberships.map((gym: GymMembership) => gym._id);
        const byId = gymMemberships.reduce(
            (
                total: { [id: string]: GymMembership },
                gymMembership: GymMembership
            ) => ({
                ...total,
                [gymMembership._id]: gymMembership,
            }),
            {}
        );
        const lastUpdated = Date.now();

        return {
            ...state,
            ids,
            byId,
            lastUpdated,
        };
    }

    if (action.type === UPDATE_GYM_MEMBERSHIP_SUCCESS) {
        const gymMembership = action.payload;
        const included = state.ids.includes(gymMembership._id);
        const ids = included ? state.ids : [...state.ids, gymMembership._id];
        const byId = {
            ...state.byId,
            [gymMembership._id]: {
                ...state.byId[gymMembership._id],
                ...gymMembership,
            },
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === UPLOAD_GYM_MEMBERSHIP_DOCS_SUCCESS) {
        const gymMembership = action.payload;
        const included = state.ids.includes(gymMembership._id);
        const ids = included ? state.ids : [...state.ids, gymMembership._id];
        const byId = {
            ...state.byId,
            [gymMembership._id]: {
                ...state.byId[gymMembership._id],
                ...gymMembership,
            },
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === DELETE_GYM_MEMBERSHIP_DOCS_SUCCESS) {
        const gymMembership = action.payload;
        const included = state.ids.includes(gymMembership._id);
        const ids = included ? state.ids : [...state.ids, gymMembership._id];
        const byId = {
            ...state.byId,
            [gymMembership._id]: {
                ...state.byId[gymMembership._id],
                ...gymMembership,
            },
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    return state;
}
