import React from 'react';
import Select, { createFilter, MultiValue } from 'react-select';
import { WrappedFieldProps } from 'redux-form';

import styles from './styles.module.css';

type OptionValue = { label: string; value: string };

interface MultiSelectInputV2Props {
    label: string;
    name: string;
    placeholder: string;
    onChange: (optionData: OptionValue | OptionValue[] | any) => void;
    isMulti: boolean;
    disabled: boolean;
    options: OptionValue[];
    initialValue: OptionValue | OptionValue[] | null;
}

const MultiSelectInputV2: React.FC<
    MultiSelectInputV2Props & WrappedFieldProps
> = ({
    meta,
    input,
    label,
    name,
    placeholder,
    disabled,
    isMulti,
    options,
    initialValue,
}) => {
    return (
        <div className={styles.selectContainer}>
            <span className={styles.selectLabel}>{label}</span>

            <Select
                className={styles.select}
                name={name}
                styles={{
                    input: (provided) => ({
                        ...provided,
                        minHeight: 40,
                        borderRadius: '0px 0px 20px 20px',
                    }),
                }}
                isDisabled={disabled}
                isMulti={isMulti}
                onChange={(val) =>
                    isMulti
                        ? input.onChange(
                              (val as MultiValue<OptionValue>).map(
                                  (c) => c.value
                              )
                          )
                        : input.onChange((val as OptionValue).value)
                }
                options={options}
                defaultValue={initialValue}
                filterOption={createFilter({
                    stringify: (option) => `${option.label}`,
                })}
                placeholder={placeholder}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: 'rgb(225, 129, 65)',
                    },
                })}
                noOptionsMessage={() => 'Не найдено'}
            />
            {meta && meta.touched && meta.error && (
                <div className={styles.error}>{meta.error}</div>
            )}
        </div>
    );
};

export default MultiSelectInputV2;
