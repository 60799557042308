import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { AsyncDispatch } from '@redux/types';
import { fetchGymsList } from '@redux/modules/gyms/actions';
import { getGymsList } from '@redux/modules/gyms/selectors';
import BackButton from '@components/BackButton';
import { GenderType } from '@t/gym';
import { fetchTagCategories, fetchTags } from '@redux/modules/tags/actions';

import { getTagCategories, getTags } from '@redux/modules/tags/selectors';
import GroupWorkoutNewForm, {
    GroupWorkoutNewFormData,
} from '@containers/Dashboard/GroupWorkoutNewForm';
import { createNewGroupWorkout } from '@redux/modules/group-workouts/actions';
import { FormMode } from '@t/utils';

import styles from './styles.module.css';

export const GroupWorkoutNewAdd: React.FC<{}> = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();

    const gymsList = useSelector(getGymsList);
    const tags = useSelector(getTags);
    const tagCategories = useSelector(getTagCategories);

    const [formMode, setFormMode] = useState<FormMode>('create');

    useEffect(() => {
        dispatch(fetchGymsList());
        dispatch(fetchTags());
        dispatch(fetchTagCategories());
    }, [dispatch]);

    const onChangeFormMode = useCallback(
        (mode: FormMode) => setFormMode(mode),
        []
    );

    const onSubmit = useCallback(
        (values: GroupWorkoutNewFormData) => {
            const {
                title,
                tags,
                gymId,
                limit,
                price,
                genderType,
                description,
                groupworkoutschedule,
            } = values;

            const normalizedData = {
                title,
                gymId,
                limit: Number(limit),
                price: Number(price),
                description,
                ...(tags && { tags }),
                ...(genderType && { genderType }),
                ...(groupworkoutschedule.dateStart && {
                    dateStart: groupworkoutschedule.dateStart,
                }),
                ...(groupworkoutschedule.dateEnd && {
                    dateEnd: groupworkoutschedule.dateEnd,
                }),
                ...(groupworkoutschedule.mon && {
                    mon: groupworkoutschedule.mon,
                }),
                ...(groupworkoutschedule.tue && {
                    tue: groupworkoutschedule.tue,
                }),
                ...(groupworkoutschedule.wed && {
                    wed: groupworkoutschedule.wed,
                }),
                ...(groupworkoutschedule.thu && {
                    thu: groupworkoutschedule.thu,
                }),
                ...(groupworkoutschedule.fri && {
                    fri: groupworkoutschedule.fri,
                }),
                ...(groupworkoutschedule.sat && {
                    sat: groupworkoutschedule.sat,
                }),
                ...(groupworkoutschedule.sun && {
                    sun: groupworkoutschedule.sun,
                }),
            };

            dispatch(createNewGroupWorkout({ values: normalizedData }))
                .then((data) => {
                    cogoToast.success('Групповая тренировка создана', {
                        position: 'top-right',
                        hideAfter: 5,
                    });
                    history.push('/dashboard/group-workouts');
                })
                .catch(() => {
                    cogoToast.error(
                        'Ошибка при создании групповой тренировки',
                        {
                            position: 'top-right',
                            hideAfter: 4,
                        }
                    );
                });
        },
        [dispatch]
    );

    const gymOptions = gymsList.reduce<{ label: string; value: string }[]>(
        (acc, gym) => {
            if (gym.hasGroupWorkouts) {
                acc.push({
                    label: gym.title,
                    value: gym._id,
                });
            }
            return acc;
        },
        []
    );

    const tagOptions = tags.reduce<{ label: string; value: string }[]>(
        (acc, tag) => {
            if (
                tag.tagType.find(
                    (type) => type.tagTypeValue === 'GROUPWORKOUTFILTER'
                )
            ) {
                acc.push({
                    label: tag.tagName,
                    value: tag._id,
                });
            }
            return acc;
        },
        []
    );

    const initialValues = useMemo(() => {
        const values: GroupWorkoutNewFormData = {
            title: '',
            tags: [],
            gymId: '',
            duration: '',
            limit: '',
            price: '',
            genderType: GenderType.ALL,
            description: '',
            groupworkoutschedule: {
                dateStart: new Date(),
                dateEnd: new Date(
                    new Date().setFullYear(new Date().getFullYear() + 1)
                ),
                mon: [
                    {
                        timeStart: null,
                        timeFinish: null,
                    },
                ],
                tue: [
                    {
                        timeStart: null,
                        timeFinish: null,
                    },
                ],
                wed: [
                    {
                        timeStart: null,
                        timeFinish: null,
                    },
                ],
                thu: [
                    {
                        timeStart: null,
                        timeFinish: null,
                    },
                ],
                fri: [
                    {
                        timeStart: null,
                        timeFinish: null,
                    },
                ],
                sat: [
                    {
                        timeStart: null,
                        timeFinish: null,
                    },
                ],
                sun: [
                    {
                        timeStart: null,
                        timeFinish: null,
                    },
                ],
            },
        };
        return values;
    }, [formMode]);

    return (
        <div className={styles.container}>
            <BackButton
                title="Все тренировки"
                className={styles.backBtn}
                onClick={() => history.push('/dashboard/group-workouts')}
            />

            <h2 className={styles.title}>Новая тренировка</h2>

            <div className={styles.box}>
                <GroupWorkoutNewForm
                    groupWorkoutId={''}
                    formMode={formMode}
                    onChangeFormMode={onChangeFormMode}
                    gymOptions={gymOptions}
                    tagOptions={tagOptions}
                    tags={tags}
                    tagCategories={tagCategories}
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                />
            </div>
        </div>
    );
};

export default GroupWorkoutNewAdd;
