import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Field, FormSubmitHandler, SubmissionError } from 'redux-form';
import { Link, useLocation } from 'react-router-dom';

import { signUp, fetchUser } from '@redux/modules/auth/actions';
import Form from '@components/Form';
import TextInput from '@components/TextInput';
import Button from '@components/Button';

import { DoNotTellAnyone } from '../DoNotTellAnyone';
import styles from './styles.module.css';

export interface FormData {
    email: string;
    password: string;
    password2: string;
}

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

export const SignUp: React.FC = () => {
    const query = useQuery();
    const salonId = query.get('salon_id');
    if (salonId) {
        localStorage.setItem('salonId', salonId);
    }

    const dispatch = useDispatch();
    const onSubmit: FormSubmitHandler<FormData> = async ({
        email,
        password,
        password2,
    }) => {
        if (password !== password2) {
            throw new SubmissionError({
                password2: 'Пароли не совпадают',
            });
        }

        try {
            await dispatch(signUp({ email, password }));
            await dispatch(fetchUser());
        } catch (err) {
            throw new SubmissionError({
                _error: 'Ошибка при регистрации',
            });
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.box}>
                <h2 className={styles.title}>Регистрация</h2>
                <p className={styles.subtitle}>
                    Платформа позволит Вам получить новых клиентов и увеличить
                    доход
                </p>
                <Form
                    shakeOnError
                    form="signUp"
                    onSubmit={onSubmit}
                    className={styles.form}
                >
                    <Field
                        required
                        type="email"
                        name="email"
                        label="Email"
                        placeholder="Email"
                        component={TextInput}
                    />

                    <Field
                        required
                        type="password"
                        name="password"
                        label="Пароль"
                        placeholder="Пароль"
                        component={TextInput}
                    />

                    <Field
                        required
                        type="password"
                        name="password2"
                        label="Повторите пароль"
                        placeholder="Повторите пароль"
                        component={TextInput}
                    />

                    <Button type="submit" className={styles.btn}>
                        Зарегистрироваться
                    </Button>

                    <Link to="/" className={styles.link}>
                        У меня уже есть аккаунт
                    </Link>
                </Form>
                <DoNotTellAnyone className={styles.doNotTellAnyoneBox} />
            </div>
        </div>
    );
};

export default SignUp;
