import { AppUser } from '@t/app-user';
import { ApplicationState } from '@redux/types';
import { Booking } from '@t/booking';

export const getAppUsers = (state: ApplicationState): AppUser[] =>
    state.appUsers.ids.map((id: string) => state.appUsers.byId[id]);

export const getAppUser = (id: string) => (state: ApplicationState): AppUser =>
    state.appUsers.byId[id];

export const getAppUsersCounter = () => (state: ApplicationState): number =>
    state.appUsers.users;

export const getAppUsersRegisteredLastWeekCounter = () => (
    state: ApplicationState
): number => state.appUsers.registeredLastWeek;

export const getAppUsersHaveDoneOneWorkoutCounter = () => (
    state: ApplicationState
): number => state.appUsers.usersHaveDoneOneWorkout;

export const getAppUsersIOSCounter = () => (state: ApplicationState): number =>
    state.appUsers.usersIOS;

export const getAppUsersAndroidCounter = () => (
    state: ApplicationState
): number => state.appUsers.usersAndroid;

export const getAppUsersLastUpdated = (state: ApplicationState): number =>
    state.appUsers.lastUpdated;

export const getDailyAppUsers = (
    state: ApplicationState
): { date: Date; appUsers: number }[] => {
    return state.appUsers.dailyAppUsers;
};

export const getFilterAppUsersCount = (state: ApplicationState): number =>
    state.appUsers.numOfFilterAppUsers;

export const getAppUserBookings = (id: string) => (
    state: ApplicationState
): Booking[] => state.appUsers.bookingsByUserId[id];

export const getInvitedUsers = (id: string) => (
    state: ApplicationState
): AppUser[] => state.appUsers.invitedUsersByUserId[id];
