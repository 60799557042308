import React, { memo } from 'react';
import { InjectedFormProps, Field, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import Select, { createFilter, MultiValue } from 'react-select';

import TextInput from '@components/TextInput';
import Button from '@components/Button';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import { tagTypeOptions } from '@t/tag';
import FileInput from '@components/FileInput';

import styles from './styles.module.css';

export interface AddTagFormData {
    tagType: { name: string; tagTypeValue: string }[];
    tagName: string;
    iconLink: string;
    comment: string;
}

export interface Props {
    selectedTagType: {
        name: string;
        tagTypeValue: string;
    }[];
    setSelectedTagType: React.Dispatch<
        React.SetStateAction<
            {
                name: string;
                tagTypeValue: string;
            }[]
        >
    >;
    fileInput: React.MutableRefObject<null>;
    onUploadFile: (event: any) => void;
    groupWorkoutFilterTagType: boolean;
    tagCategoriesOptions: {
        label: string;
        value: string;
    }[];
    selectedTagCategories: string[];
    setSelectedTagCategories: React.Dispatch<React.SetStateAction<string[]>>;
    tagError: boolean;
    setTagError: React.Dispatch<React.SetStateAction<boolean>>;
    tagCategoryError: boolean;
    setTagCategoryError: React.Dispatch<React.SetStateAction<boolean>>;
}

const validate = (values: AddTagFormData) => {
    const errors: { tagName: string; iconLink: string } = {
        tagName: '',
        iconLink: '',
    };

    if (!values.tagName) {
        errors.tagName = 'Обязательное поле';
    }

    return errors;
};

const AddTagForm: React.FC<
    Props & InjectedFormProps<AddTagFormData, Props>
> = memo(
    ({
        handleSubmit,
        selectedTagType,
        setSelectedTagType,
        fileInput,
        onUploadFile,
        groupWorkoutFilterTagType,
        tagCategoriesOptions,
        selectedTagCategories,
        setSelectedTagCategories,
        tagError,
        setTagError,
        tagCategoryError,
        setTagCategoryError,
    }) => {
        const user = useSelector(getUser);
        const isSuperAdmin = user?.role === UserRole.SuperAdmin;

        const handleChangeTagType = (
            value: MultiValue<{ label: string; value: string }>
        ) => {
            setSelectedTagType(
                value.map((type: { label: string; value: string }) => {
                    return {
                        name: type.label,
                        tagTypeValue: type.value,
                    };
                })
            );
            setTagError(false);
        };

        const onBlurTagType = () => {
            if (selectedTagType.length === 0) {
                setTagError(true);
            }
        };

        const handleChangeTagCategories = (
            value: MultiValue<{ label: string; value: string }>
        ) => {
            setSelectedTagCategories(value.map((tagC) => tagC.value));
            setTagCategoryError(false);
        };

        const onBlurTagCategories = () => {
            if (selectedTagCategories.length === 0) {
                setTagCategoryError(true);
            }
        };

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <Field
                    name="tagName"
                    label="Название"
                    placeholder="Название"
                    component={TextInput}
                />

                <span className={styles.label}>Типы тэгов</span>
                <Select
                    className={styles.select}
                    isMulti
                    styles={{
                        input: (provided) => ({
                            ...provided,
                            minHeight: 40,
                        }),
                    }}
                    onChange={handleChangeTagType}
                    onBlur={onBlurTagType}
                    options={tagTypeOptions}
                    filterOption={createFilter({
                        stringify: (option) => `${option.label}`,
                    })}
                    closeMenuOnSelect={true}
                    placeholder="Тип тэга"
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: 'rgb(225, 129, 65)',
                        },
                    })}
                    noOptionsMessage={() => 'Не найдено'}
                />
                {tagError && (
                    <div
                        style={{
                            fontSize: '14px',
                            marginTop: '3px',
                            color: '#ff3333',
                        }}
                    >
                        Обязательное поле
                    </div>
                )}

                {groupWorkoutFilterTagType && (
                    <>
                        <span className={styles.label}>Категории тэга</span>
                        <Select
                            className={styles.select}
                            isMulti
                            styles={{
                                input: (provided) => ({
                                    ...provided,
                                    minHeight: 40,
                                }),
                            }}
                            onChange={handleChangeTagCategories}
                            onBlur={onBlurTagCategories}
                            options={tagCategoriesOptions}
                            filterOption={createFilter({
                                stringify: (option) => `${option.label}`,
                            })}
                            closeMenuOnSelect={false}
                            placeholder="Категория"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: 'rgb(225, 129, 65)',
                                },
                            })}
                            noOptionsMessage={() => 'Не найдено'}
                        />
                        {tagCategoryError && (
                            <div
                                style={{
                                    fontSize: '14px',
                                    marginTop: '3px',
                                    color: '#ff3333',
                                }}
                            >
                                Обязательное поле
                            </div>
                        )}
                    </>
                )}

                <FileInput
                    name="iconLink"
                    label="Иконка"
                    accept=".svg"
                    fileInput={fileInput}
                    onChange={onUploadFile}
                />

                <Field
                    name="comment"
                    label="Описание"
                    placeholder="Описание иконки"
                    component={TextInput}
                />

                {isSuperAdmin && (
                    <Button type="submit" className={styles.btn}>
                        Добавить
                    </Button>
                )}
            </form>
        );
    }
);

export default reduxForm<AddTagFormData, Props>({
    form: 'AddTag',
    validate,
})(AddTagForm);
