import { ApplicationState } from '@redux/types';
import { PartnerReport, Report } from '@t/report';

export const getReports = (state: ApplicationState): Report[] =>
    state.reports.ids.map((id: string) => state.reports.byId[id]);

export const getBookingsReports = (state: ApplicationState): PartnerReport[] =>
    state.reports.idsBookings.map(
        (id: string) => state.reports.byIdBookings[id]
    );

export const getMembershipsReports = (
    state: ApplicationState
): PartnerReport[] =>
    state.reports.idsMemberships.map(
        (id: string) => state.reports.byIdMemberships[id]
    );

export const getReport = (id: string) => (state: ApplicationState): Report =>
    state.reports.byId[id];
