import { Booking } from '@t/booking';
import { ApplicationState } from '@redux/types';
import { AppUser, UserPaymentData } from '@t/app-user';
import { Coupon } from '@t/coupon';

export const getBookings = (state: ApplicationState): Booking[] =>
    state.bookings.ids.map((id: string) => state.bookings.byId[id]);

export const getBooking = (id: string) => (state: ApplicationState): Booking =>
    state.bookings.byId[id];

export const getActiveBookingsCount = (selectedGymId: string | null) => (
    state: ApplicationState
): number =>
    state.bookings.ids.filter(
        (id: string) =>
            state.bookings.byId[id].active &&
            (!selectedGymId || state.bookings.byId[id].gymId === selectedGymId)
    ).length;

export const getBookingsCount = (selectedGymId: string | null) => (
    state: ApplicationState
): number =>
    state.bookings.ids.filter(
        (id) =>
            !selectedGymId || state.bookings.byId[id].gymId === selectedGymId
    ).length;

export const getFilterBookingsCount = () => (state: ApplicationState): number =>
    state.dashboard.numOfFilterBookings;

export const getBookingsTotalPrice = (selectedGymId: string | null) => (
    state: ApplicationState
): number =>
    state.bookings.ids
        .filter(
            (id: string) =>
                !selectedGymId ||
                state.bookings.byId[id].gymId === selectedGymId
        )
        .reduce((sum: number, id: string) => {
            return sum + state.bookings.byId[id].price;
        }, 0);

export const getFilterBookingsTotalPrice = () => (
    state: ApplicationState
): number => state.dashboard.filterRevenue;

export const getBookingsLastUpdated = (state: ApplicationState): number =>
    state.bookings.lastUpdated;

export const getAppUser = (id: string) => (state: ApplicationState): AppUser =>
    state.bookings.usersByBookingId[id];

export const getCoupon = (id: string) => (state: ApplicationState): Coupon =>
    state.bookings.couponByBookingId[id];

export const getUnpaidBookings = (state: ApplicationState): Booking[] =>
    state.bookings.unpaidBookingsIds.map(
        (id: string) => state.bookings.unpaidBookingsById[id]
    );

export const getTestBookings = (state: ApplicationState): Booking[] =>
    state.bookings.testBookingsIds.map(
        (id: string) => state.bookings.testBookingById[id]
    );

export const getUserPaymentDatas = (id: string) => (
    state: ApplicationState
): UserPaymentData[] => state.bookings.bookingsByUserPaymentDatas[id];
