import React from 'react';
import Button from '../../../components/Button';
import styles from './styles.module.css';

export interface NextBtnProps {
    title: string;
}

export const NextBtn: React.FC<NextBtnProps> = ({ title }) => (
    <Button type="submit" className={styles.btn}>
        {title}
    </Button>
);

export default NextBtn;
