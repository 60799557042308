import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

import Shape from '@components/Icons/Shape';

import styles from './styles.module.css';

export interface Props {
    title: string;
    className: any;
    history: any;
    onClick?: () => void;
}

export const BackButton: React.FC<Props> = ({
    title,
    className,
    history,
    onClick,
}) => {
    const handleClick = () => history.goBack();

    return (
        <button
            type="button"
            onClick={onClick || handleClick}
            className={classNames(styles.btn, className)}
        >
            <Shape className={styles.icon} />
            <span className={styles.title}>{title}</span>
        </button>
    );
};

export default withRouter<any, any>(BackButton);
