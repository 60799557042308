import { AnyAction } from 'redux';

import { User } from '@t/user';

import {
    FETCH_MANAGERS_SUCCESS,
    ManagerState,
    FETCH_MANAGER_SUCCESS,
    DELETE_MANAGER_GYM_SUCCESS,
    FETCH_MANAGER_CORPORATE_DOMAINS_SUCCESS,
    UPDATE_MANAGER_SUCCESS,
    SELECT_MANAGER,
    FETCH_MANAGERS_LIST_SUCCESS,
    FETCH_SCROLL_MANAGERS_SUCCESS,
} from './types';

const initialState: ManagerState = {
    ids: [],
    byId: {},
    corporateDomains: [],
    managersList: [],
    selectedManagerId: '',
    lastUpdated: 0,
};

export function managerReducer(
    state = initialState,
    action: AnyAction
): ManagerState {
    if (action.type === FETCH_MANAGER_SUCCESS) {
        const manager = action.payload;
        const included = state.ids.includes(manager._id);
        const ids = included ? state.ids : [...state.ids, manager._id];
        const byId = {
            ...state.byId,
            [manager._id]: manager,
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === FETCH_MANAGERS_SUCCESS) {
        const { managers } = action.payload;
        const ids = managers.map((manager: User) => manager._id);
        const byId = managers.reduce(
            (total: { [id: string]: User }, manager: User) => ({
                ...total,
                [manager._id]: manager,
            }),
            {}
        );
        const lastUpdated = Date.now();

        return {
            ...state,
            ids,
            byId,
            lastUpdated,
        };
    }

    if (action.type === FETCH_SCROLL_MANAGERS_SUCCESS) {
        const { managers } = action.payload;
        const ids = managers.map((manager: User) => manager._id);
        const byId = managers.reduce(
            (total: { [id: string]: User }, manager: User) => ({
                ...total,
                [manager._id]: manager,
            }),
            {}
        );
        const lastUpdated = Date.now();

        return {
            ...state,
            ids: [...state.ids, ...ids],
            byId: { ...state.byId, ...byId },
            lastUpdated,
        };
    }

    if (action.type === DELETE_MANAGER_GYM_SUCCESS) {
        const manager = action.payload;
        const included = state.ids.includes(manager._id);
        const ids = included ? state.ids : [...state.ids, manager._id];
        const byId = {
            ...state.byId,
            [manager._id]: manager,
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === FETCH_MANAGER_CORPORATE_DOMAINS_SUCCESS) {
        const { corporateDomains } = action.payload;

        return {
            ...state,
            corporateDomains,
        };
    }

    if (action.type === UPDATE_MANAGER_SUCCESS) {
        const manager = action.payload;
        const included = state.ids.includes(manager._id);
        const ids = included ? state.ids : [...state.ids, manager._id];
        const byId = {
            ...state.byId,
            [manager._id]: {
                ...state.byId[manager._id],
                ...manager,
            },
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === FETCH_MANAGERS_LIST_SUCCESS) {
        const { managersList } = action.payload;

        return {
            ...state,
            managersList,
        };
    }

    if (action.type === SELECT_MANAGER) {
        const managerId = action.payload;
        return {
            ...state,
            selectedManagerId: managerId,
        };
    }

    return state;
}
