import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { AsyncDispatch } from '@redux/types';
import { createMembership } from '@redux/modules/memberships/actions';
import BackButton from '@components/BackButton';
import { getGymsList } from '@redux/modules/gyms/selectors';

import Form from './Form';
import styles from './styles.module.css';

const MAX_LENGTH = 3;
const MAX_SIZE = 20000000;

export const AddMembership: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const gyms = useSelector(getGymsList);
    const [selectedGyms, setSelectedGyms] = useState<string[]>([]);
    const fileInput = useRef<null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onSubmit = useCallback(
        (values) => {
            const files = (fileInput.current || { files: new FileList() })
                .files;
            setIsLoading(true);
            dispatch(createMembership({ values, gyms: selectedGyms, files }))
                .then((membership) => {
                    if (membership) {
                        cogoToast.success('Абонемент создан', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                        history.push('/dashboard/gym-memberships');
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при создании абонемента', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => setIsLoading(false));
        },
        [dispatch, history, selectedGyms]
    );

    const uploadMultipleFiles = (event: any) => {
        const numOfFiles = Array.from(event.target.files).length;
        if (!numOfFiles) {
            event.preventDefault();
            cogoToast.error(`Приложите файлы для загрузки`, {
                position: 'top-right',
                hideAfter: 4,
            });
            event.target.value = null;
            return;
        }
        if (numOfFiles > MAX_LENGTH) {
            event.preventDefault();
            cogoToast.error(`Нельзя загрузить более ${MAX_LENGTH} файлов`, {
                position: 'top-right',
                hideAfter: 4,
            });
            event.target.value = null;
            return;
        }
        for (let i = 0; i < numOfFiles; ++i) {
            const validExtensions = ['pdf'];
            const fileExtension = event.target.files[i].type.split('/')[1];
            const fileSize = event.target.files[i].size;

            if (!validExtensions.includes(fileExtension)) {
                event.preventDefault();
                cogoToast.error(`Можно загрузить файлы с расширением pdf`, {
                    position: 'top-right',
                    hideAfter: 4,
                });
                event.target.value = null;
                return;
            }

            if (fileSize > MAX_SIZE) {
                event.preventDefault();
                cogoToast.error(
                    `Нельзя загрузить файл размера более 20MB, файл "${event.target.files[i].name}" слишком большой`,
                    {
                        position: 'top-right',
                        hideAfter: 4,
                    }
                );
                event.target.value = null;
                return;
            }
        }
    };

    const gymsOptions = gyms
        .filter((gym) => !gym.isHidden)
        .map((gym) => {
            return {
                label: gym.title,
                value: gym._id,
            };
        });

    const initialValues = {
        active: true,
    };

    return (
        <div className={styles.container}>
            {isLoading ? (
                <div>Загрузка...</div>
            ) : (
                <>
                    <h1>Добавить новый абонемент</h1>

                    <BackButton
                        title="К списку абонементов"
                        className={styles.backBtn}
                    />

                    <Form
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        gymsOptions={gymsOptions}
                        setSelectedGyms={setSelectedGyms}
                        fileInput={fileInput}
                        uploadMultipleFiles={uploadMultipleFiles}
                    />
                </>
            )}
        </div>
    );
};

export default AddMembership;
