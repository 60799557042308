import moment, { Moment } from 'moment';
import { MomentTimeSlot } from '@containers/Dashboard/GroupWorkoutNewForm/types';
import { GroupWorkoutNewData, ITimeSlot, ReservationsType } from '@t/gym';
import { useState } from 'react';

type WeekDayStruct = {
    [key: string]: { date: any; timeSlot: string }[];
};

export const useGroupWorkoutNewFormHelpers = () => {
    const [tooltipShown, setTooltipShown] = useState<{
        [key: string]: boolean;
    }>({});
    const [timePickerShown, setTimePickerShown] = useState<{
        [key: string]: boolean;
    }>({});
    const [
        reservationsByWeekDay,
        setReservationsByWeekDay,
    ] = useState<WeekDayStruct>({
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
    });

    const [
        modalReservationSlotsControls,
        setModalReservationSlotsControls,
    ] = useState({
        active: false,
        weekDayKey: '',
    });
    const [modalAutocompleteControls, setModalAutocompleteControls] = useState({
        active: false,
        weekDayKey: '',
    });

    const generateTimeSlots = ({
        timeStart,
        timeFinish,
        duration,
    }: {
        timeStart: Moment;
        timeFinish: Moment;
        duration: number;
    }) => {
        const localTimeStart = moment(timeStart, 'HH:mm');
        const localTimeFinish = moment(timeFinish, 'HH:mm');
        const localDuration = duration || 60;

        const timeSlots: MomentTimeSlot[] = [];

        while (localTimeStart <= localTimeFinish) {
            timeSlots.push({
                timeStart: moment(localTimeStart),
                timeFinish: moment(localTimeStart).add(
                    localDuration,
                    'minutes'
                ),
            });
            localTimeStart.add(localDuration, 'minutes');
        }

        return timeSlots;
    };

    const generateMomentTimeSlotToValidData = (
        timeSlots: MomentTimeSlot[] | any[]
    ) => {
        const clonedTimeSlots = [...timeSlots];
        return clonedTimeSlots.reduce<{ timeStart: any; timeFinish: any }[]>(
            (acc, slot) => {
                if (slot.timeStart && slot.timeFinish) {
                    acc.push({
                        timeStart: moment(slot.timeStart).utc(true).format(),
                        timeFinish: moment(slot.timeFinish).utc(true).format(),
                    });
                }
                return acc;
            },
            []
        );
    };

    const generateMomentDateToValidStartDayData = (date: Date | null): any => {
        if (!date) {
            return null;
        }

        return moment(date).startOf('day').utc(true).format();
    };

    const getValidTimeSlots = (timeSlots: ITimeSlot[]) => {
        return timeSlots.filter((slot) => slot.timeStart && slot.timeFinish);
    };

    const getEqualTimeSlots = (
        valueMomentTimeSlot: { timeStart: Moment; timeFinish: Moment } | any,
        reservationsByWeekDayArr: { date: Moment }[]
    ) => {
        if (!valueMomentTimeSlot || !reservationsByWeekDayArr.length) {
            return false;
        }

        return reservationsByWeekDayArr.find((res) => {
            const valueTimeStart = valueMomentTimeSlot.timeStart;
            const reservationTimeStart = res.date;

            const timeStart = moment(valueTimeStart).format('HH:mm');
            const comparedTimeSlotTimeStart = moment(
                reservationTimeStart
            ).format('HH:mm');

            if (timeStart === comparedTimeSlotTimeStart) {
                return true;
            }

            return false;
        });
    };

    const getLatestReservationDateObject = ({
        reservations,
    }: {
        reservations: GroupWorkoutNewData['groupworkoutschedule']['reservations'];
    }) => {
        if (!reservations?.length) {
            return null;
        }

        return reservations.reduce((a, b) => {
            return new Date(a.date) > new Date(b.date) ? a : b;
        });
    };

    const generateReservationsByWeekDay = ({
        reservations,
        groupworkoutschedule,
    }: {
        reservations: GroupWorkoutNewData['groupworkoutschedule']['reservations'];
        groupworkoutschedule: {
            mon: GroupWorkoutNewData['groupworkoutschedule']['mon'];
            tue: GroupWorkoutNewData['groupworkoutschedule']['tue'];
            wed: GroupWorkoutNewData['groupworkoutschedule']['wed'];
            thu: GroupWorkoutNewData['groupworkoutschedule']['thu'];
            fri: GroupWorkoutNewData['groupworkoutschedule']['fri'];
            sat: GroupWorkoutNewData['groupworkoutschedule']['sat'];
            sun: GroupWorkoutNewData['groupworkoutschedule']['sun'];
        };
    }) => {
        if (!reservations?.length) {
            return;
        }
        const weekDayStruct: WeekDayStruct = {};
        const weekDayKeysArr = [
            'mon',
            'tue',
            'wed',
            'thu',
            'fri',
            'sat',
            'sun',
        ];

        for (const reservation of reservations) {
            const momentReservationDate = moment(reservation.date);
            const reservationWeekDay = momentReservationDate.isoWeekday();
            const reservationWeekDayKey =
                weekDayKeysArr[reservationWeekDay - 1];
            const reservationTimeSLotId = reservation.timeSlot;

            const timeStartByDay = groupworkoutschedule[
                reservationWeekDayKey as keyof typeof groupworkoutschedule
            ].filter((item) => item._id === reservationTimeSLotId);

            if (timeStartByDay.length) {
                const momentTimeStartByDay = moment(
                    timeStartByDay[0].timeStart
                );
                const resultDateWithTime = momentReservationDate
                    .hours(momentTimeStartByDay.hours())
                    .minutes(momentTimeStartByDay.minutes());
                if (!weekDayStruct[reservationWeekDayKey]) {
                    weekDayStruct[reservationWeekDayKey] = [];
                }
                weekDayStruct[reservationWeekDayKey].push({
                    date: resultDateWithTime,
                    timeSlot: reservationTimeSLotId,
                });
            }
        }

        setReservationsByWeekDay((prevState) => ({
            ...prevState,
            ...weekDayStruct,
        }));
    };

    const generateTooltipInitialState = (initialScheduleData: {
        mon: GroupWorkoutNewData['groupworkoutschedule']['mon'];
        tue: GroupWorkoutNewData['groupworkoutschedule']['tue'];
        wed: GroupWorkoutNewData['groupworkoutschedule']['wed'];
        thu: GroupWorkoutNewData['groupworkoutschedule']['thu'];
        fri: GroupWorkoutNewData['groupworkoutschedule']['fri'];
        sat: GroupWorkoutNewData['groupworkoutschedule']['sat'];
        sun: GroupWorkoutNewData['groupworkoutschedule']['sun'];
    }) => {
        const resultData: { [key: string]: boolean } = {};

        for (const [index, [key, value]] of Object.entries(
            Object.entries(initialScheduleData)
        )) {
            resultData[`groupworkoutschedule.${key}.${index}`] = false;
        }
    };

    const generateEqualDateMomentObject = (inputDate: Moment) => {
        if (!inputDate) {
            return null;
        }

        return moment({
            year: 1970,
            month: 0,
            day: 1,
            hour: inputDate.hour(),
            minute: inputDate.minute(),
            second: 0,
        });
    };

    return {
        tooltipShown,
        setTooltipShown,
        timePickerShown,
        setTimePickerShown,
        reservationsByWeekDay,
        modalReservationSlotsControls,
        setModalReservationSlotsControls,
        modalAutocompleteControls,
        setModalAutocompleteControls,
        getValidTimeSlots,
        getEqualTimeSlots,
        getLatestReservationDateObject,
        generateTimeSlots,
        generateMomentTimeSlotToValidData,
        generateMomentDateToValidStartDayData,
        generateReservationsByWeekDay,
        generateTooltipInitialState,
        generateEqualDateMomentObject,
    };
};
