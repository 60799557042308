import React from 'react';
import { ErrorMessage, Field } from 'formik';
import TextInput from '@components/TextInput';
import styles from './styles.module.css';

interface InputFieldProps {
    name: string;
    label: string;
    placeholder: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
    maxLength?: number;
    hint?: string;
    pattern?: string;
}

export const InputField: React.FC<InputFieldProps> = ({
    name,
    label,
    placeholder,
    onChange,
    value,
    hint,
    maxLength,
    pattern,
}) => {
    return (
        <>
            <Field
                name={name}
                label={label}
                placeholder={placeholder}
                maxLength={maxLength}
                pattern={pattern}
                input={{
                    onChange,
                    value,
                }}
                component={TextInput}
            />
            {hint && <div className={styles.hint}>{hint}</div>}
            <ErrorMessage
                className={styles.error}
                name={name}
                component="div"
            />
        </>
    );
};
