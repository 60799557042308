import React from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    IGroupWorkoutReservationNew,
    newReservationStatusMap,
    statusColors,
} from '@t/reservation';
import { DATE_FORMAT, TIME_FORMAT } from '@config';
import LoaderVertical from '@components/LoaderVertical';

import styles from './styles.module.css';

function getStatusColor(status: string): string {
    return statusColors[status] || '#414141';
}

export function shiftTimeByTimezone(createdAt: Date, timezone: string): string {
    if (!createdAt) {
        return '';
    }
    const createdAtMoment = moment.utc(createdAt);
    const timezoneOffset = parseInt(timezone.split('UTC')[1], 10);
    const shiftedTime = createdAtMoment.utcOffset(timezoneOffset * 60);
    return shiftedTime.format('DD.MM.YYYY, HH:mm');
}

export function calculateShiftedDate(
    timeStart: Date,
    timeFinish: Date,
    date: Date
): moment.Moment {
    const start = moment(timeStart);
    const finish = moment(timeFinish);
    const shiftedDate = moment(date);
    if (finish.diff(start, 'minutes') < 0) {
        shiftedDate.add(1, 'day');
    }
    return shiftedDate;
}

export interface Props {
    reservations: IGroupWorkoutReservationNew[];
    loading?: boolean;
}

const columns = [
    {
        Header: 'ID Брони',
        accessor: '_id',
    },
    {
        Header: 'Название тренировки',
        accessor: 'title',
    },
    {
        Header: 'Начало',
        accessor: (reservation: IGroupWorkoutReservationNew) => {
            return `${moment(reservation.date)
                .utcOffset(0)
                .format(DATE_FORMAT)}, ${moment(reservation.timeSlot.timeStart)
                .utcOffset(0)
                .format(TIME_FORMAT)}`;
        },
    },
    {
        Header: 'Конец',
        accessor: (reservation: IGroupWorkoutReservationNew) => {
            return `${moment(
                calculateShiftedDate(
                    reservation.timeSlot.timeStart,
                    reservation.timeSlot.timeFinish,
                    reservation.date
                )
            )
                .utcOffset(0)
                .format(DATE_FORMAT)}, ${moment(reservation.timeSlot.timeFinish)
                .utcOffset(0)
                .format(TIME_FORMAT)}`;
        },
    },
    {
        Header: 'Фитнес-площадка',
        accessor: 'gymTitle',
    },
    {
        Header: 'Пользователь',
        accessor: (reservation: IGroupWorkoutReservationNew) => {
            return `${reservation.userId?.firstName ?? 'Имя'} ${
                reservation.userId?.lastName[0] ?? 'Ф'
            }.`;
        },
    },
    {
        Header: 'Время брони',
        accessor: (reservation: IGroupWorkoutReservationNew) => {
            return shiftTimeByTimezone(
                reservation.createdAt,
                reservation.gymId.timezone
            );
        },
    },
    {
        Header: 'Статус',
        accessor: (reservation: IGroupWorkoutReservationNew) => {
            return (
                <span style={{ color: getStatusColor(reservation.status) }}>
                    {newReservationStatusMap[reservation.status]}
                </span>
            );
        },
    },
];

export const ReservationsListTable: React.FC<Props> = ({
    reservations,
    loading,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable(
        {
            // @ts-ignore
            columns,
            data: reservations || [],
            initialState: {
                pageIndex: 0,
                pageSize: 10000,
            },
        },
        useSortBy,
        usePagination
    );

    const history = useHistory();

    return (
        <>
            <table {...getTableProps()} className={styles.table}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={Math.random()}
                        >
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                    key={Math.random()}
                                >
                                    {column.render('Header')}
                                    <span className={styles.sorted}>
                                        <FontAwesomeIcon
                                            icon={
                                                column.isSorted
                                                    ? column.isSortedDesc
                                                        ? 'sort-up'
                                                        : 'sort-down'
                                                    : 'sort'
                                            }
                                        />
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        const onClick = () => {
                            const reservation = row.original;
                            history.push(
                                `/dashboard/reservations/${reservation._id}`
                            );
                        };
                        return (
                            <tr
                                {...row.getRowProps()}
                                className={styles.tableRow}
                                onClick={onClick}
                                key={Math.random()}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            key={Math.random()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {loading && <LoaderVertical />}
        </>
    );
};

export default ReservationsListTable;
