import React, { useState, useCallback } from 'react';
import { WrappedFieldProps } from 'redux-form';

import GoogleMap from '../GoogleMap';
import styles from './styles.module.css';

export interface Props {
    label: string;
    name: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
}

const getAddressByLocation = (
    location: google.maps.LatLngLiteral
): Promise<google.maps.GeocoderResult> => {
    const geoCoder = new google.maps.Geocoder();

    return new Promise((resolve, reject) => {
        geoCoder.geocode({ location }, (results, status) => {
            if (status !== google.maps.GeocoderStatus.OK || !results.length) {
                reject(status);

                return;
            }

            resolve(results[0]);
        });
    });
};

const getLocationByPlaceId = (
    placeId: string
): Promise<google.maps.GeocoderResult> => {
    const geoCoder = new google.maps.Geocoder();

    return new Promise((resolve, reject) => {
        geoCoder.geocode({ placeId }, function (results, status) {
            if (status !== 'OK' || !results.length) {
                reject();

                return;
            }

            resolve(results[0]);
        });
    });
};

const getSuggestionsByAddress = (
    address: string
): Promise<google.maps.places.AutocompletePrediction[]> => {
    const service = new google.maps.places.AutocompleteService();

    return new Promise(function (resolve, reject) {
        service.getPlacePredictions({ input: address }, (results, status) => {
            if (status !== 'OK') {
                reject();

                return;
            }

            resolve(results);
        });
    });
};

const GeoInput: React.FC<Props & WrappedFieldProps> = ({
    label,
    name,
    input,
    placeholder,
    required,
    disabled,
}) => {
    const [address, updateAddress] = useState(input.value?.address || '');
    const [suggestions, updateSuggestions] = useState<
        google.maps.places.AutocompletePrediction[]
    >([]);
    const [suggestionsLoading] = useState(false);

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            updateAddress(value);
            if (!value) {
                return;
            }
            getSuggestionsByAddress(value).then((results) => {
                updateSuggestions(results);
            });
        },
        []
    );

    const handleCoordinateChange = useCallback(
        (position: google.maps.LatLngLiteral) => {
            getAddressByLocation(position)
                .then((result) => {
                    updateAddress(result.formatted_address);
                    input.onChange({
                        position: result.geometry.location.toJSON(),
                        address: result.formatted_address,
                    });
                })
                .catch(() => {
                    // do something
                });
        },
        [input]
    );

    const onPickSuggestion = (
        suggestion: google.maps.places.AutocompletePrediction
    ) => {
        getLocationByPlaceId(suggestion.place_id)
            .then((result) => {
                updateAddress(result.formatted_address);
                updateSuggestions([]);
                input.onChange({
                    position: result.geometry.location.toJSON(),
                    address: result.formatted_address,
                });
            })
            .catch(() => {
                // do something
            });
    };

    return (
        <div className={styles.wrapper}>
            <label htmlFor={name} className={styles.label}>
                {label}
            </label>
            <input
                value={address}
                onChange={handleChange}
                name={name}
                placeholder={placeholder}
                required={required}
                disabled={disabled}
                className={styles.input}
            />
            <div className={styles.dropdown}>
                {suggestionsLoading && (
                    <div className={styles.loading}>Loading...</div>
                )}
                <ul className={styles.dropdownList}>
                    {(suggestions || []).map((suggestion: any) => {
                        const onClick = () => onPickSuggestion(suggestion);
                        return (
                            <li key={suggestion.id}>
                                <button
                                    type="button"
                                    className={styles.suggestion}
                                    onClick={onClick}
                                >
                                    <span>{suggestion.description}</span>
                                </button>
                            </li>
                        );
                    })}
                </ul>
            </div>
            {!!input.value?.position && (
                <GoogleMap
                    coordinate={input.value.position}
                    className={styles.mapWrapper}
                    onCoordinateUpdate={handleCoordinateChange}
                />
            )}
        </div>
    );
};

export default GeoInput;
