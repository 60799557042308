import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';
import Delete from '@components/Icons/Delete';

interface TooltipProps {
    active: boolean;
    onClose: () => void;
    children: React.ReactNode[] | React.ReactNode;
    contentContainerStyles?: string;
}

const Tooltip = ({
    active,
    onClose,
    children,
    contentContainerStyles,
}: TooltipProps) => {
    if (!active) {
        return null;
    }

    return (
        <div className={classNames(contentContainerStyles, styles.tooltip)}>
            <div className={styles.tooltipContent}>
                {children}
                <div onClick={onClose}>
                    <Delete className={styles.tooltipClose} />
                </div>
            </div>
        </div>
    );
};

export default Tooltip;
