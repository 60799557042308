import axios from 'axios';
import qs from 'qs';

import { GymMembership, Membership } from '@t/membership';
import { B2B_API_URL } from '@config';
import { AsyncActionCreator } from '@redux/types';
import { AddMembershipFormData } from '@containers/Dashboard/AddMembership/Form';
import { GymMembershipFormData } from '@containers/Dashboard/GymMembershipDetails/Form';

import {
    FETCH_MEMBERSHIPS_REQUEST,
    FETCH_MEMBERSHIPS_SUCCESS,
    FETCH_MEMBERSHIPS_ERROR,
    FETCH_MEMBERSHIP_REQUEST,
    FETCH_MEMBERSHIP_SUCCESS,
    FETCH_MEMBERSHIP_ERROR,
    CREATE_MEMBERSHIP_REQUEST,
    CREATE_MEMBERSHIP_SUCCESS,
    CREATE_MEMBERSHIP_ERROR,
    FETCH_GYM_MEMBERSHIPS_REQUEST,
    FETCH_GYM_MEMBERSHIPS_SUCCESS,
    FETCH_GYM_MEMBERSHIPS_ERROR,
    FETCH_GYM_MEMBERSHIP_REQUEST,
    FETCH_GYM_MEMBERSHIP_SUCCESS,
    FETCH_GYM_MEMBERSHIP_ERROR,
    UPDATE_GYM_MEMBERSHIP_REQUEST,
    UPDATE_GYM_MEMBERSHIP_SUCCESS,
    UPDATE_GYM_MEMBERSHIP_ERROR,
    DELETE_GYM_MEMBERSHIP_REQUEST,
    DELETE_GYM_MEMBERSHIP_SUCCESS,
    DELETE_GYM_MEMBERSHIP_ERROR,
    FETCH_MEMBERSHIPS_USERS_SUCCESS,
    FETCH_MEMBERSHIP_USER_SUCCESS,
    UPLOAD_GYM_MEMBERSHIP_DOCS_SUCCESS,
    UPLOAD_GYM_MEMBERSHIP_DOCS_ERROR,
    DELETE_GYM_MEMBERSHIP_DOCS_REQUEST,
    DELETE_GYM_MEMBERSHIP_DOCS_SUCCESS,
    DELETE_GYM_MEMBERSHIP_DOCS_ERROR,
} from './types';

export const fetchMemberships: AsyncActionCreator<
    string | null | void,
    Membership[]
> = (selectedGymId?: string | null | void) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_MEMBERSHIPS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data } = await axios.get(`${B2B_API_URL}/memberships`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                selectedGymId: selectedGymId,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        dispatch({
            type: FETCH_MEMBERSHIPS_SUCCESS,
            payload: {
                memberships: data.memberships,
            },
        });

        dispatch({
            type: FETCH_MEMBERSHIPS_USERS_SUCCESS,
            payload: {
                memberships: data.memberships,
                users: data.users,
            },
        });

        return data.memberships;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_MEMBERSHIPS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchMembership: AsyncActionCreator<string> = (
    id?: string
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_MEMBERSHIP_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data } = await axios.get(`${B2B_API_URL}/memberships/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: FETCH_MEMBERSHIP_SUCCESS,
            payload: data.membership,
        });

        dispatch({
            type: FETCH_MEMBERSHIP_USER_SUCCESS,
            payload: {
                membership: data.membership,
                user: data.user,
            },
        });

        return data.membership;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_MEMBERSHIP_ERROR,
            });

            throw err;
        }
    }
};

export const createMembership: AsyncActionCreator<
    { values: AddMembershipFormData; gyms: string[]; files: FileList },
    Membership
> = ({ values, gyms, files }) => async (dispatch, getState) => {
    dispatch({
        type: CREATE_MEMBERSHIP_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    const formData = new FormData();

    if (files?.length) {
        for (let k = 0; k < files.length; ++k) {
            formData.append('docs', files[k]);
        }
    }

    const newValues = { ...values, gyms };

    try {
        const { data: membership } = await axios.post(
            `${B2B_API_URL}/gym-memberships`,
            newValues,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (files?.length) {
            await axios.put(
                `${B2B_API_URL}/gym-memberships/docs-upload/${membership._id}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
        }

        dispatch({
            type: CREATE_MEMBERSHIP_SUCCESS,
            payload: membership,
        });

        return membership;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: CREATE_MEMBERSHIP_ERROR,
            });

            throw err;
        }
    }
};

export const fetchGymMemberships: AsyncActionCreator<
    string | null | void,
    GymMembership[]
> = (selectedGymId?: string | null | void) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_GYM_MEMBERSHIPS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: gymMemberships } = await axios.get(
            `${B2B_API_URL}/gym-memberships`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    selectedGymId: selectedGymId,
                },
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                },
            }
        );

        dispatch({
            type: FETCH_GYM_MEMBERSHIPS_SUCCESS,
            payload: {
                gymMemberships,
            },
        });

        return gymMemberships;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_GYM_MEMBERSHIPS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchGymMembership: AsyncActionCreator<string> = (
    id?: string
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_GYM_MEMBERSHIP_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: gymMembership } = await axios.get(
            `${B2B_API_URL}/gym-memberships/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_GYM_MEMBERSHIP_SUCCESS,
            payload: gymMembership,
        });

        return gymMembership;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_GYM_MEMBERSHIP_ERROR,
            });

            throw err;
        }
    }
};

export const updateGymMembership: AsyncActionCreator<{
    id: string;
    values: GymMembershipFormData;
    gyms: string[];
}> = ({ id, values, gyms }) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_GYM_MEMBERSHIP_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    const newValues = { ...values, gyms };

    try {
        const { data: gymMembership } = await axios.put(
            `${B2B_API_URL}/gym-memberships/${id}`,
            newValues,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: UPDATE_GYM_MEMBERSHIP_SUCCESS,
            payload: gymMembership,
        });

        return gymMembership;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPDATE_GYM_MEMBERSHIP_ERROR,
            });

            throw err;
        }
    }
};

export const deleteGymMembership: AsyncActionCreator<string> = (id) => async (
    dispatch,
    getState
) => {
    dispatch({
        type: DELETE_GYM_MEMBERSHIP_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: gymMembership } = await axios.delete(
            `${B2B_API_URL}/gym-memberships/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: DELETE_GYM_MEMBERSHIP_SUCCESS,
            payload: gymMembership,
        });

        return gymMembership;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: DELETE_GYM_MEMBERSHIP_ERROR,
            });

            throw err;
        }
    }
};

export const uploadDocs: AsyncActionCreator<
    { files: FileList; membershipId: string },
    string
> = ({ files, membershipId }) => async (dispatch, getState) => {
    const {
        auth: { token },
    } = getState();
    const bodyFormData = new FormData();

    for (let k = 0; k < files.length; ++k) {
        bodyFormData.append('docs', files[k]);
    }

    try {
        const {
            data: { gymMembership },
        } = await axios.put(
            `${B2B_API_URL}/gym-memberships/docs-upload/${membershipId}`,
            bodyFormData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        dispatch({
            type: UPLOAD_GYM_MEMBERSHIP_DOCS_SUCCESS,
            payload: gymMembership,
        });
        return gymMembership;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPLOAD_GYM_MEMBERSHIP_DOCS_ERROR,
            });

            throw err;
        }
    }
};

export const deleteDocs: AsyncActionCreator<
    { membershipId: string; key: string },
    string[]
> = ({ membershipId, key }) => async (dispatch, getState) => {
    dispatch({
        type: DELETE_GYM_MEMBERSHIP_DOCS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const {
            data: { gymMembership },
        } = await axios.put(
            `${B2B_API_URL}/gym-memberships/docs-delete/${membershipId}`,
            { key },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        dispatch({
            type: DELETE_GYM_MEMBERSHIP_DOCS_SUCCESS,
            payload: gymMembership,
        });

        return gymMembership;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: DELETE_GYM_MEMBERSHIP_DOCS_ERROR,
            });

            throw err;
        }
    }
};
