import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cogoToast from 'cogo-toast';

import { AsyncDispatch } from '@redux/types';
import { getGymsList } from '@redux/modules/gyms/selectors';
import { fetchGymsList, selectGym } from '@redux/modules/gyms/actions';
import BackButton from '@components/BackButton';
import { fetchTagCategories, fetchTags } from '@redux/modules/tags/actions';
import { getTagCategories, getTags } from '@redux/modules/tags/selectors';

import GroupWorkoutNewForm, {
    GroupWorkoutNewFormData,
} from '@containers/Dashboard/GroupWorkoutNewForm';
import {
    createNewGroupWorkout,
    deleteNewGroupWorkout,
    fetchGroupWorkoutNewDetail,
    updateNewGroupWorkout,
} from '@redux/modules/group-workouts/actions';
import moment from 'moment';
import { FormMode } from '@t/utils';
import Modal from '@components/Modal';
import Button from '@components/Button';
import classNames from 'classnames';
import { getGroupWorkoutDetail } from '@redux/modules/group-workouts/selectors';

import styles from './styles.module.css';
import { useGroupWorkoutNewFormNormalizeData } from '@containers/Dashboard/GroupWorkoutNewForm/useGroupWorkoutNewFormNormalizeData';
import { ReservationsType } from '@t/gym';

export const getDuration = (end: string, start: string): number =>
    Math.round(moment.duration(moment(end).diff(moment(start))).asMinutes());

function activeReservations(
    reservations: ReservationsType[]
): ReservationsType[] {
    const currentDate = moment().startOf('day').utc(false);
    return reservations.filter(
        (reservation: { date: string | number | Date }) =>
            moment(moment(reservation.date)).isSameOrAfter(currentDate)
    );
}

export const GroupWorkoutNewEdit: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const groupWorkoutNew = useSelector(getGroupWorkoutDetail(id));
    const history = useHistory();

    const gymsList = useSelector(getGymsList);
    const tags = useSelector(getTags);
    const tagCategories = useSelector(getTagCategories);

    const {
        normalizeDuration,
        normalizeScheduleByWeekDay,
    } = useGroupWorkoutNewFormNormalizeData();

    const [formMode, setFormMode] = useState<FormMode>('edit');
    const [
        confirmDeleteModalControls,
        setConfirmDeleteModalControls,
    ] = useState({
        active: false,
        currentId: '',
    });

    useEffect(() => {
        dispatch(fetchGroupWorkoutNewDetail({ id }));
    }, [dispatch, id]);

    useEffect(() => {
        dispatch(fetchGymsList());
        dispatch(fetchTags());
        dispatch(fetchTagCategories());
    }, [dispatch]);

    useEffect(() => {
        if (groupWorkoutNew?.gymId) {
            dispatch(selectGym(groupWorkoutNew.gymId));
        }
    }, [groupWorkoutNew?.gymId]);

    const onChangeFormMode = useCallback(
        (mode: FormMode) => setFormMode(mode),
        []
    );

    const initialValues: any = useMemo(() => {
        if (!groupWorkoutNew || !groupWorkoutNew?._id) {
            return {};
        }

        const values: GroupWorkoutNewFormData = {
            title: groupWorkoutNew.title,
            tags: groupWorkoutNew.tags,
            gymId: groupWorkoutNew.gymId,
            duration: normalizeDuration({
                mon: groupWorkoutNew.groupworkoutschedule.mon,
                tue: groupWorkoutNew.groupworkoutschedule.tue,
                wed: groupWorkoutNew.groupworkoutschedule.wed,
                thu: groupWorkoutNew.groupworkoutschedule.thu,
                fri: groupWorkoutNew.groupworkoutschedule.fri,
                sat: groupWorkoutNew.groupworkoutschedule.sat,
                sun: groupWorkoutNew.groupworkoutschedule.sun,
            }).toString(),
            limit: groupWorkoutNew.limit.toString(),
            price: groupWorkoutNew.price.toString(),
            genderType: groupWorkoutNew.genderType,
            description: groupWorkoutNew.description || '',
            groupworkoutschedule: {
                reservations: groupWorkoutNew.groupworkoutschedule.reservations,
                dateStart:
                    groupWorkoutNew.groupworkoutschedule.dateStart || null,
                dateEnd: groupWorkoutNew.groupworkoutschedule.dateEnd || null,
                mon: normalizeScheduleByWeekDay(
                    groupWorkoutNew.groupworkoutschedule.mon
                ),
                tue: normalizeScheduleByWeekDay(
                    groupWorkoutNew.groupworkoutschedule.tue
                ),
                wed: normalizeScheduleByWeekDay(
                    groupWorkoutNew.groupworkoutschedule.wed
                ),
                thu: normalizeScheduleByWeekDay(
                    groupWorkoutNew.groupworkoutschedule.thu
                ),
                fri: normalizeScheduleByWeekDay(
                    groupWorkoutNew.groupworkoutschedule.fri
                ),
                sat: normalizeScheduleByWeekDay(
                    groupWorkoutNew.groupworkoutschedule.sat
                ),
                sun: normalizeScheduleByWeekDay(
                    groupWorkoutNew.groupworkoutschedule.sun
                ),
            },
        };
        return values;
    }, [groupWorkoutNew, formMode]);

    const onSubmit = useCallback(
        (values: GroupWorkoutNewFormData) => {
            const {
                title,
                tags,
                gymId,
                limit,
                price,
                genderType,
                description,
                groupworkoutschedule,
            } = values;

            const normalizedData = {
                title,
                gymId,
                limit: Number(limit),
                price: Number(price),
                description,
                ...(tags && { tags }),
                ...(genderType && { genderType }),
                ...{
                    dateStart: groupworkoutschedule.dateStart,
                },
                ...{
                    dateEnd: groupworkoutschedule.dateEnd,
                },
                ...(groupworkoutschedule.mon && {
                    mon: groupworkoutschedule.mon,
                }),
                ...(groupworkoutschedule.tue && {
                    tue: groupworkoutschedule.tue,
                }),
                ...(groupworkoutschedule.wed && {
                    wed: groupworkoutschedule.wed,
                }),
                ...(groupworkoutschedule.thu && {
                    thu: groupworkoutschedule.thu,
                }),
                ...(groupworkoutschedule.fri && {
                    fri: groupworkoutschedule.fri,
                }),
                ...(groupworkoutschedule.sat && {
                    sat: groupworkoutschedule.sat,
                }),
                ...(groupworkoutschedule.sun && {
                    sun: groupworkoutschedule.sun,
                }),
            };

            if (formMode === 'create') {
                dispatch(createNewGroupWorkout({ values: normalizedData }))
                    .then(() => {
                        cogoToast.success('Групповая тренировка создана', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                        history.push(`/dashboard/group-workouts/${id}`);
                    })
                    .catch(() => {
                        cogoToast.error(
                            'Ошибка при создании групповой тренировки',
                            {
                                position: 'top-right',
                                hideAfter: 4,
                            }
                        );
                    });
                return;
            }

            if (formMode === 'edit') {
                dispatch(updateNewGroupWorkout({ id, values: normalizedData }))
                    .then(() => {
                        cogoToast.success('Групповая тренировка обновлена', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                        history.push(`/dashboard/group-workouts/${id}`);
                    })
                    .catch(() => {
                        cogoToast.error(
                            'Ошибка при обновлении групповой тренировки',
                            {
                                position: 'top-right',
                                hideAfter: 4,
                            }
                        );
                    });
                return;
            }
        },
        [dispatch]
    );

    const onDelete = useCallback(() => {
        if (
            groupWorkoutNew.groupworkoutschedule.reservations.length &&
            activeReservations(
                groupWorkoutNew.groupworkoutschedule.reservations
            ).length
        ) {
            return cogoToast.warn('Есть активные бронирования', {
                position: 'top-right',
                hideAfter: 4,
            });
        }

        setConfirmDeleteModalControls({
            active: true,
            currentId: id,
        });
    }, [dispatch, groupWorkoutNew]);

    const onConfirmCancel = useCallback(() => {
        setConfirmDeleteModalControls({
            active: false,
            currentId: '',
        });
    }, [dispatch]);

    const onConfirmDelete = useCallback(() => {
        dispatch(deleteNewGroupWorkout({ id }))
            .then(() => {
                cogoToast.success('Групповая тренировка удалена', {
                    position: 'top-right',
                    hideAfter: 5,
                });
                setConfirmDeleteModalControls({
                    active: false,
                    currentId: '',
                });
                history.push('/dashboard/group-workouts');
            })
            .catch(() => {
                cogoToast.error('Ошибка при удалении групповой тренировки', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    }, [dispatch]);

    const onDuplicate = useCallback(() => {
        history.push(`/dashboard/group-workouts/${id}/duplicate`);
    }, [dispatch]);

    const gymOptions = gymsList.reduce<{ label: string; value: string }[]>(
        (acc, gym) => {
            if (gym.hasGroupWorkouts) {
                acc.push({
                    label: gym.title,
                    value: gym._id,
                });
            }
            return acc;
        },
        []
    );

    const tagOptions = tags.reduce<{ label: string; value: string }[]>(
        (acc, tag) => {
            if (
                tag.tagType.find(
                    (type) => type.tagTypeValue === 'GROUPWORKOUTFILTER'
                )
            ) {
                acc.push({
                    label: tag.tagName,
                    value: tag._id,
                });
            }
            return acc;
        },
        []
    );

    if (!groupWorkoutNew || !groupWorkoutNew?._id) {
        return <div className={styles.container}>Загрузка...</div>;
    }

    return (
        <div className={styles.container}>
            <BackButton
                title="Все тренировки"
                className={styles.backBtn}
                onClick={() => history.push('/dashboard/group-workouts')}
            />

            <h2 className={styles.title}>Редактирование тренировки</h2>

            <div className={styles.box}>
                <GroupWorkoutNewForm
                    groupWorkoutId={id}
                    formMode={formMode}
                    gymOptions={gymOptions}
                    tagOptions={tagOptions}
                    tags={tags}
                    tagCategories={tagCategories}
                    onChangeFormMode={onChangeFormMode}
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                    onDuplicate={onDuplicate}
                    initialValues={initialValues}
                />
            </div>
            <Modal
                active={confirmDeleteModalControls.active}
                setActive={(prev) =>
                    setConfirmDeleteModalControls((prevState) => ({
                        ...prevState,
                        active: prev,
                    }))
                }
            >
                <div className={styles.modalContainer}>
                    <h2>Внимание!</h2>
                    <p>Это действие невозможно отменить!</p>
                    <p>
                        Всё расписание и бронирования будут отменены, а <br />
                        средства возвращены пользователям.
                    </p>
                    <div className={styles.modalActionControls}>
                        <Button
                            className={styles.modalActionBtn}
                            onClick={onConfirmCancel}
                            buttonType={'primary'}
                            type={'button'}
                        >
                            Отменить
                        </Button>
                        <Button
                            className={classNames(
                                styles.modalActionBtn,
                                styles.modalActionBtnSecondary
                            )}
                            onClick={onConfirmDelete}
                            buttonType={'primary'}
                            type={'button'}
                        >
                            Удалить тренировку
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default GroupWorkoutNewEdit;
