import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Route, Switch, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import BrandLogo from '@components/BrandLogo';
import Rocket from '@components/Icons/Rocket';
import CheckersDiv from '@components/CheckersDiv';
import Button from '@components/Button';
import { signOut } from '@redux/modules/auth/actions';
import { getUser } from '@redux/modules/auth/selectors';

import Name from './Steps/Name';
import GymInfo from './Steps/GymInfo';
import styles from './styles.module.css';

export const RegistrationSteps: React.FC = () => {
    const location = useLocation();
    const user = useSelector(getUser);
    const dispatch = useDispatch();
    const onSignOutBtnClick = useCallback(() => dispatch(signOut()), [
        dispatch,
    ]);

    const steps: {
        to: string;
        title: string;
        completed: boolean;
    }[] = useMemo(
        () => [
            {
                to: '/registration-steps',
                title: 'Имя и телефон',
                completed: !!user?.firstName,
            },
            {
                to: '/registration-steps/gym-info',
                title: 'Информация о фитнес-площадке',
                completed: !!user?.onboardingGymMeta,
            },
        ],
        [user]
    );

    if (!user) {
        return null;
    }

    return (
        <div className={styles.container}>
            <CheckersDiv className={styles.left}>
                <BrandLogo className={styles.logo} />
                <div className={styles.leftContent}>
                    {steps.map((step, index) => {
                        const isActive = location.pathname === step.to;
                        const isDisabled =
                            index > 0 &&
                            steps.length > index - 1 &&
                            !steps[index - 1].completed;

                        return (
                            <>
                                {index > 0 && (
                                    <div
                                        className={classNames(
                                            styles.stepDivider,
                                            isDisabled &&
                                                styles.stepDividerDisabled,
                                            step.completed &&
                                                styles.stepDividerCompleted
                                        )}
                                    />
                                )}
                                <div key={step.to} className={styles.step}>
                                    <div
                                        className={classNames(
                                            styles.stepNumber,
                                            isActive && styles.stepNumberActive,
                                            isDisabled &&
                                                styles.stepNumberDisabled,
                                            step.completed &&
                                                styles.stepNumberCompleted
                                        )}
                                    >
                                        <span>{index + 1}</span>
                                    </div>
                                    <Link
                                        to={!isDisabled ? step.to : '#'}
                                        className={classNames(
                                            styles.link,
                                            isDisabled && styles.linkDisabled,
                                            step.completed &&
                                                styles.linkCompleted,
                                            isActive && styles.activeLink
                                        )}
                                    >
                                        {step.title}
                                    </Link>
                                </div>
                            </>
                        );
                    })}
                </div>
                <Rocket className={styles.rocketIcon} />
            </CheckersDiv>
            <div className={styles.content}>
                <Button
                    buttonType="secondary"
                    className={styles.logoutBtn}
                    onClick={onSignOutBtnClick}
                >
                    Выйти из аккаунта
                </Button>
                <Switch>
                    <Route exact path="/registration-steps" component={Name} />
                    <Route
                        path="/registration-steps/gym-info"
                        component={GymInfo}
                    />
                </Switch>
            </div>
        </div>
    );
};
