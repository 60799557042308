import { ApplicationState } from '@redux/types';
import { Tag, TagCategorie } from '@t/tag';

export const getTags = (state: ApplicationState): Tag[] =>
    state.tags.ids.map((id: string) => state.tags.byId[id]);

export const getTag = (id: string) => (state: ApplicationState): Tag =>
    state.tags.byId[id];

export const getTagCategories = (state: ApplicationState): TagCategorie[] =>
    state.tagCategories.ids.map((id: string) => state.tagCategories.byId[id]);
