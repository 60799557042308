import 'dotenv/config';
export const API_URL =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_URL_DEV
        : process.env.REACT_APP_API_URL_PROD;

export const REPORTS_API_URL =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_REPORTS_API_URL_DEV
        : process.env.REACT_APP_REPORTS_API_URL_PROD;

export const B2B_API_URL =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_B2B_API_URL_DEV
        : process.env.REACT_APP_B2B_API_URL_PROD;

export const S3_REPORTS_BUCKET_NAME =
    process.env.NODE_ENV === 'development' ? 's3-reports-stage' : 's3-reports';

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_URL;

export const FILTER_DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export const DATE_FORMAT = 'DD.MM.YYYY';

export const TIME_FORMAT = 'HH:mm';

export const PAGE_SIZE = [10, 20, 30, 40, 50, 100];
export const CLOUDPAYMENTSFEE = 2.9;
export const ALFAFEE = 1;
export const MULTISPORT = '5f81d3110f5b9fda064c4fa3';
export const SURFBROTHERS = '60d9b92b78bc6f0379a2000c';
export const UFCARENA = '6093e114f47a675dca6ec625';
export const TESTGYM = '5e3ae7c17a62f6d09e492f9f';
export const GYMS_EXEMPTIONS = [
    '63ef7aaeca7a9f55123c2354',
    '63ef7cedca7a9f60583c5136',
    '63ef7e590e75f3fee4995e9a',
    '6470a435be75581f2ec3e73e',
];
export const ACQUIRINGFEE = 3.9;
export const EXCLUSIVE_TYPE_OF_COLLABORATION = [
    '617261903210ae0d299172c9',
    '617265433210ae0d2991789f',
    '617264383210ae0d2991775b',
];
export enum AbortReasonType {
    INTERNET_DISCONNECT = 'INTERNET_DISCONNECT',
    DISCHARGED_PHONE = 'DISCHARGED_PHONE',
    FORGOT_TO_SCAN_QR_CODE = 'FORGOT_TO_SCAN_QR_CODE',
    OTHER = 'OTHER',
}

export const ROUTES_WITH_GYM_FILTER_SUPPORT = [
    '/dashboard',
    '/dashboard/bookings',
    '/dashboard/reservations',
    '/dashboard/reports',
    '/dashboard/gym-memberships',
    '/dashboard/memberships',
];

export const ROUTES_WITH_GYM_GROUPWORKOUT_FILTER_SUPPORT = [
    '/dashboard/reservations',
];

export const REPORT_HEADER_CELLS = [
    'A1',
    'B1',
    'C1',
    'D1',
    'E1',
    'F1',
    'G1',
    'H1',
    'I1',
    'J1',
    'K1',
    'L1',
    'M1',
    'N1',
    'O1',
    'P1',
    'Q1',
    'R1',
    'S1',
    'T1',
    'U1',
    'V1',
    'W1',
    'X1',
    'Y1',
    'Z1',
    'AA1',
    'AB1',
    'AC1',
    'AD1',
    'AE1',
    'AF1',
    'AG1',
    'AH1',
    'AI1',
    'AJ1',
    'AK1',
    'AL1',
    'AM1',
    'AN1',
    'AO1',
    'AP1',
    'AQ1',
    'AR1',
    'AS1',
    'AT1',
    'AU1',
    'AV1',
    'AW1',
    'AX1',
    'AY1',
    'AZ1',
];
