import React from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Gym } from '@t/gym';
import Button from '@components/Button';

import styles from './styles.module.css';

export interface Props {
    hiddenGyms: Gym[];
}

const columns = [
    {
        Header: 'Название',
        accessor: 'title',
        Cell: ({ cell: { value } }: any) => (
            <Button className={styles.titleBtn}>{value}</Button>
        ),
    },
    {
        Header: 'Адрес фитнес-площадки',
        accessor: (gym: Gym) => gym.address || 'Не указан',
    },
    {
        Header: 'Цена',
        accessor: (gym: Gym) => `${gym.pricePerMinRub}₽/мин.`,
    },
];

export const GymListTable: React.FC<Props> = ({ hiddenGyms }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable(
        {
            // @ts-ignore
            columns,
            data: hiddenGyms || [],
            initialState: { pageIndex: 0, pageSize: 1000 },
        },
        useSortBy,
        usePagination
    );
    const history = useHistory();

    return (
        <>
            <table {...getTableProps()} className={styles.table}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={Math.random()}
                        >
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                    key={Math.random()}
                                >
                                    {column.render('Header')}
                                    <span className={styles.sorted}>
                                        <FontAwesomeIcon
                                            icon={
                                                column.isSorted
                                                    ? column.isSortedDesc
                                                        ? 'sort-up'
                                                        : 'sort-down'
                                                    : 'sort'
                                            }
                                        />
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        const onClick = () => {
                            const gym = row.original;
                            history.push(`/dashboard/hidden-gyms/${gym._id}`);
                        };
                        return (
                            <tr
                                className={styles.tableRow}
                                onClick={onClick}
                                {...row.getRowProps()}
                                key={Math.random()}
                            >
                                {row.cells.map((cell, idx) => {
                                    if (idx === 0) {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    }
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            key={Math.random()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};

export default GymListTable;
