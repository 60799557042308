import React from 'react';
import { Route } from 'react-router-dom';

import SignUp from './SignUp';
import SignIn from './SignIn';
import TwoFactorAuthentication from './TwoFactorAuthentication';
import ResetPassword from './ResetPassword';
import SavePassword from './SavePassword';

export const Auth: React.FC = () => (
    <div>
        <Route exact path="/auth/register" component={SignUp} />
        <Route exact path="/auth/login" component={SignIn} />
        <Route exact path="/auth/verify" component={TwoFactorAuthentication} />
        <Route exact path="/auth/reset-password" component={ResetPassword} />
        <Route
            exact
            path="/auth/reset-password/:token"
            component={SavePassword}
        />
    </div>
);
