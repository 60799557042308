import React from 'react';
import { InjectedFormProps, Field, reduxForm } from 'redux-form';
import TextInput from '@components/TextInput';
import TextAreaInput from '@components/TextAreaInput';

import styles from './styles.module.css';

export interface ProfileFormData {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

export interface Props {
    isSuperAdmin: boolean;
    isHR: boolean;
}

const BookingForm: React.FC<
    Props & InjectedFormProps<ProfileFormData, Props>
> = ({ handleSubmit, isSuperAdmin, isHR }) => {
    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Field
                disabled
                name="userId"
                label="ID пользователя"
                component={TextInput}
            />

            <Field
                disabled
                name="firstName"
                label="Имя"
                component={TextInput}
            />

            <Field disabled name="email" label="Email" component={TextInput} />

            <Field
                disabled
                name="phone"
                label="Номер телефона"
                component={TextInput}
            />

            {(isSuperAdmin || isHR) && (
                <Field
                    disabled
                    name="role"
                    label="Роль"
                    component={TextInput}
                />
            )}

            {isHR && (
                <Field
                    disabled
                    name="domain"
                    label="Домены"
                    component={TextAreaInput}
                />
            )}
        </form>
    );
};

export default reduxForm<ProfileFormData, Props>({})(BookingForm);
