import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { AsyncDispatch } from '@redux/types';
import BackButton from '@components/BackButton';
import { abortReservation } from '@redux/modules/reservations/actions';

import styles from './styles.module.css';
import Form, { FormData } from './Form';

export const ReservationCancel: React.FC<{}> = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();

    const onSubmit = useCallback(
        (values: FormData) => {
            dispatch(
                abortReservation({
                    id,
                    abortReasonMessage: values.abortReasonText,
                })
            )
                .then(() => {
                    cogoToast.success('Бронирование отменено', {
                        position: 'top-right',
                        hideAfter: 5,
                    });
                    history.push(`/dashboard/reservations`);
                })
                .catch(() => {
                    cogoToast.error('Ошибка при отмене бронирования', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                });
        },
        [id]
    );

    return (
        <div className={styles.container}>
            <BackButton
                to={`/dashboard/reservations/${id}`}
                title="Назад к бронированию"
                className={styles.backBtn}
            />

            <h2>Причина отмены бронирования</h2>

            <Form onSubmit={onSubmit} />
        </div>
    );
};

export default ReservationCancel;
