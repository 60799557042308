import React, { useEffect, useRef, useState, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { formValueSelector } from 'redux-form';
import cogoToast from 'cogo-toast';

import {
    getAppUsers,
    getAppUsersAndroidCounter,
    getAppUsersCounter,
    getAppUsersIOSCounter,
    getAppUsersRegisteredLastWeekCounter,
    getDailyAppUsers,
    getFilterAppUsersCount,
    getInvitedUsers,
} from '@redux/modules/app-users/selectors';
import {
    fetchAppUsers,
    fetchAppUserStatistics,
    filterAppUsers,
} from '@redux/modules/app-users/actions';
import Box from '@components/Box';
import Loader from '@components/Loader';
import { ApplicationState, AsyncDispatch } from '@redux/types';
import { AppUser } from '@t/app-user';
import AppUserCounter from '@components/AppUserCounter';
import AppUsersChart from '@components/AppUsersChart';
import { FILTER_DATE_FORMAT } from '@config';
import Button from '@components/Button';

import AppUserListTable from './AppUserListTable';
import styles from './styles.module.css';
import Form from './Form';

export const AppUserList: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const appUsers = useSelector(getAppUsers);
    const appUsersSearchingForm = useRef<HTMLFormElement>(null);
    const [foundAppUsers, setFoundAppUsers] = useState<AppUser[]>();
    const [invitedUsers, setInvitedUsers] = useState(false);

    const allUsers = useSelector(getAppUsersCounter());
    const registeredLastWeek = useSelector(
        getAppUsersRegisteredLastWeekCounter()
    );
    const ios = useSelector(getAppUsersIOSCounter());
    const android = useSelector(getAppUsersAndroidCounter());

    const iosInPercents = +(allUsers && (ios * 100) / allUsers).toFixed(1);
    const androidInPercents = +(allUsers && (android * 100) / allUsers).toFixed(
        1
    );

    const nowDate = new Date();
    const endDateDef = moment(new Date(nowDate)).format(FILTER_DATE_FORMAT);
    const startDateDef = moment(new Date(nowDate))
        .subtract(29, 'days')
        .format(FILTER_DATE_FORMAT);

    const selector = formValueSelector('filterUsers');
    const concatenatedDatesSelector = (state: ApplicationState) =>
        selector(state, 'concatenatedDates');
    let concatenatedDates = useSelector(concatenatedDatesSelector);

    if (!concatenatedDates) {
        concatenatedDates = `${startDateDef}:${endDateDef}`;
    }

    const [startDate, endDate] = concatenatedDates.split(':');

    useEffect(() => {
        const offset = new Date().getTimezoneOffset();
        dispatch(filterAppUsers({ startDate, endDate, offset }));
    }, [dispatch, endDate]);

    const dailyAppUsers = useSelector(getDailyAppUsers);
    const filterAppUsersCount = useSelector(getFilterAppUsersCount);
    const initialValueDates = { concatenatedDates: `${startDate}:${endDate}` };

    useEffect(() => {
        dispatch(fetchAppUsers(''));
        dispatch(fetchAppUserStatistics());
    }, [dispatch]);

    const handleSearchSubmit = (event: SyntheticEvent) => {
        event.preventDefault();
        const values = appUsersSearchingForm.current?.values.value;
        dispatch(fetchAppUsers(values))
            .then((user: AppUser[]) => {
                if (!user.length) {
                    cogoToast.error('Пользователь не найден', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                } else {
                    setFoundAppUsers(user);
                }
            })
            .catch(() => {
                cogoToast.error('Ошибка при поиске пользователя', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    };

    const location = useLocation<{
        appUserId: string;
    }>();

    const appInvitedUsers = useSelector(
        getInvitedUsers(location.state?.appUserId)
    );

    if (appInvitedUsers?.length && !invitedUsers) {
        setInvitedUsers(true);
    }

    const fetchAllUsers = () => {
        if (appInvitedUsers?.length) appInvitedUsers.length = 0;
        dispatch(fetchAppUsers('')).then(() => {
            setInvitedUsers(false);
        });
    };

    return (
        <div>
            <h1>Пользователи</h1>

            <div className={styles.row}>
                <AppUserCounter title="Всего пользователей:" count={allUsers} />
                <AppUserCounter
                    title="За последние 7 дней:"
                    count={registeredLastWeek}
                />
                <AppUserCounter title="iOS %" count={iosInPercents} />
                <AppUserCounter title="Android %" count={androidInPercents} />
            </div>

            <Form initialValues={initialValueDates} />
            <div className={styles.dailyAppUsers}>
                <AppUsersChart dailyAppUsers={dailyAppUsers} />
                <AppUserCounter
                    title="Всего за период:"
                    count={filterAppUsersCount}
                />
            </div>
            <div className={styles.filterWrapper}>
                <form
                    className={styles.actions}
                    ref={appUsersSearchingForm}
                    onSubmit={handleSearchSubmit}
                >
                    <input
                        className={styles.input}
                        required
                        type="search"
                        name="values"
                        placeholder="Поиск по id / телефон без +7 / email"
                    />
                </form>

                {invitedUsers && (
                    <Button
                        onClick={fetchAllUsers}
                        type="button"
                        className={styles.btn}
                    >
                        Все
                    </Button>
                )}
            </div>

            {appUsers.length ? (
                <Box className={styles.container}>
                    <div className={styles.tableWrapper}>
                        {foundAppUsers ? (
                            <AppUserListTable appUsers={foundAppUsers} />
                        ) : (
                            <AppUserListTable
                                appUsers={
                                    invitedUsers ? appInvitedUsers : appUsers
                                }
                            />
                        )}
                    </div>
                </Box>
            ) : (
                <Loader />
            )}
        </div>
    );
};

export default AppUserList;
