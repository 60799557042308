import React from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
    faCheckSquare,
    faChevronLeft,
    faChevronRight,
    faCoffee,
    faSignOutAlt,
    faSort,
    faSortUp,
    faSortDown,
    faSync,
} from '@fortawesome/free-solid-svg-icons';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'rc-time-picker/assets/index.css';

import { store, persistor } from './redux/store';
import { AppRoutes } from './AppRoutes';
import './App.css';

library.add(
    fab,
    faCheckSquare,
    faCoffee,
    faChevronLeft,
    faChevronRight,
    faSignOutAlt,
    faSort,
    faSortUp,
    faSortDown,
    faSync
);

moment.locale('ru');

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <AppRoutes />
                </Router>
            </PersistGate>
        </Provider>
    );
}

export default App;
