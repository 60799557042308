import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, FormSubmitHandler, SubmissionError } from 'redux-form';

import Form from '@components/Form';
import TextInput from '@components/TextInput';
import Button from '@components/Button';
import { AsyncDispatch } from '@redux/types';
import { fetchAdmin, fetchUser } from '@redux/modules/auth/actions';
import { getDataUrl, getQrVerified } from '@redux/modules/auth/selectors';

import styles from './styles.module.css';

export interface FormData {
    code: string;
}

export const TwoFactorAuthentication: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const isVerifiedQr = useSelector(getQrVerified);
    const dataUrl = useSelector(getDataUrl);

    useEffect(() => {
        dispatch(fetchAdmin(''));
    }, [dispatch]);

    const onSubmit: FormSubmitHandler<FormData> = async ({ code }) => {
        try {
            const user = await dispatch(fetchAdmin(code));

            if (user) {
                await dispatch(fetchUser());
            } else {
                throw new SubmissionError({});
            }
        } catch (err) {
            throw new SubmissionError({
                _error: 'Ошибка при авторизации',
            });
        }
    };

    const validate = (values: FormData) => {
        const errors: { code: string } = { code: '' };
        if (!values.code) {
            errors.code = 'Обязательное поле';
        }
        return errors;
    };

    return (
        <div className={styles.container}>
            <div className={styles.box}>
                <h2 className={styles.title}>2FA GOGYM Бизнес</h2>

                <Form
                    shakeOnError
                    validate={validate}
                    form="verify"
                    onSubmit={onSubmit}
                    className={styles.form}
                >
                    {!isVerifiedQr && (
                        <img className={styles.qr} src={`${dataUrl}`} />
                    )}

                    <Field
                        autoFocus
                        type="text"
                        name="code"
                        label="Одноразовый пароль"
                        placeholder="Одноразовый пароль"
                        component={TextInput}
                    />

                    <Button type="submit" className={styles.btn}>
                        Войти
                    </Button>
                </Form>
            </div>
        </div>
    );
};

export default TwoFactorAuthentication;
