import React from 'react';

import styles from './styles.module.css';

export interface SubtitleProps {
    message: string;
}

export const Subtitle: React.FC<SubtitleProps> = ({ message }) => (
    <p className={styles.subtitle}>{message}</p>
);

export default Subtitle;
