import React from 'react';
import moment from 'moment';
import { useTable, usePagination, useSortBy, Column } from 'react-table';
import XLSX from 'xlsx-js-style';
import fileSaver from 'file-saver';
import axios from 'axios';

import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import Downloading from '@components/Icons/Downloading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { monthNames, PartnerReport } from '@t/report';

import Pagination from './Pagination';
import styles from './styles.module.css';
import { useSelector } from 'react-redux';
import { REPORT_HEADER_CELLS } from '@config';

export interface Props {
    reports: PartnerReport[];
}

export const getDuration = (end: string, start: string): number =>
    Math.round(moment.duration(moment(end).diff(moment(start))).asMinutes());

export const isValidDate = (value: Date): boolean =>
    value?.toLocaleString() !== 'Invalid Date';

const onClick = async (report: PartnerReport) => {
    const response = await axios.get(report.file);
    const ws = XLSX.utils.json_to_sheet(response.data);
    for (const i of REPORT_HEADER_CELLS) {
        if (ws[i]) {
            ws[i].s = {
                font: {
                    bold: true,
                },
            };
        }
    }
    const totalAmount = [['ИТОГО', report.totalAmount]];

    XLSX.utils.sheet_add_aoa(ws, totalAmount, {
        origin: `R${response.data.length + 2}`,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'array',
    });
    const data = new Blob([excelBuffer], {
        type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    fileSaver.saveAs(
        data,
        `${monthNames[report.month]}-${report.year}-${moment().format(
            'DD-MM-YYYY-HH-mm'
        )}.xlsx`
    );
};

const columns: Column<PartnerReport>[] = [
    {
        Header: 'Год',
        accessor: 'year',
    },
    {
        Header: 'Месяц',
        accessor: (report: PartnerReport) => monthNames[report.month],
    },
    {
        Header: 'Итоговая сумма',
        accessor: (report: PartnerReport) => `${report.totalAmount} ₽`,
    },
    {
        Header: 'Скачать отчет',
        accessor: (report: PartnerReport) => report,
        Cell: ({ cell: { value } }: any) => (
            <div
                title="Скачать"
                className={styles.download}
                onClick={() => onClick(value)}
            >
                <Downloading />
            </div>
        ),
    },
];

const columnsForAdmin: Column<PartnerReport>[] = [
    {
        Header: 'Менеджер',
        accessor: (report: PartnerReport) => report.managerId,
    },
    {
        Header: 'Год',
        accessor: 'year',
    },
    {
        Header: 'Месяц',
        accessor: (report: PartnerReport) => monthNames[report.month],
    },
    {
        Header: 'Итоговая сумма',
        accessor: (report: PartnerReport) => `${report.totalAmount} ₽`,
    },
    {
        Header: 'Скачать отчет',
        accessor: (report: PartnerReport) => report,
        Cell: ({ cell: { value } }: any) => (
            <div
                title="Скачать"
                className={styles.download}
                onClick={() => onClick(value)}
            >
                <Downloading />
            </div>
        ),
    },
];

export const ReportsListTable: React.FC<Props> = ({ reports }) => {
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            // @ts-ignore
            columns: isSuperAdmin ? columnsForAdmin : columns,
            data: reports || [],
            initialState: {
                pageIndex: 0,
                pageSize: 100,
            },
        },
        useSortBy,
        usePagination
    );

    return (
        <>
            <table {...getTableProps()} className={styles.table}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={Math.random()}
                        >
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                    key={Math.random()}
                                >
                                    {column.render('Header')}
                                    <span className={styles.sorted}>
                                        <FontAwesomeIcon
                                            icon={
                                                column.isSorted
                                                    ? column.isSortedDesc
                                                        ? 'sort-up'
                                                        : 'sort-down'
                                                    : 'sort'
                                            }
                                        />
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                className={styles.tableRow}
                                key={Math.random()}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            key={Math.random()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <Pagination
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                pageSize={pageSize}
                setPageSize={setPageSize}
            />
        </>
    );
};

export default ReportsListTable;
