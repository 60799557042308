import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import cogoToast from 'cogo-toast';

import { DATE_TIME_FORMAT } from '@config';
import { AsyncDispatch } from '@redux/types';
import BackButton from '@components/BackButton';
import {
    getCorporateDomains,
    getManager,
} from '@redux/modules/managers/selectors';
import {
    fetchCorporateDomains,
    fetchManager,
    updateManager,
} from '@redux/modules/managers/actions';
import { deleteManager } from '@redux/modules/managers/actions';
import { getGymsList } from '@redux/modules/gyms/selectors';

import styles from './styles.module.css';
import ManagerForm from './Form';

export const ManagerDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const gyms = useSelector(getGymsList);
    const manager = useSelector(getManager(id || ''));
    const corporateDomains = useSelector(getCorporateDomains);
    const [disabled, setDisabled] = useState(true);
    const [selectedGyms, setSelectedGyms] = useState<string[]>(manager?.gyms);
    const [phone, setPhone] = useState<string>(manager?.phone);
    const [phoneError, setPhoneError] = useState<boolean>(false);

    useEffect(() => {
        if (!id) {
            return;
        }
        dispatch(fetchManager(id));
        dispatch(fetchCorporateDomains());
    }, [dispatch, id]);

    useEffect(() => {
        if (!manager) {
            return;
        }
        setSelectedGyms(manager.gyms);
        setPhone(manager.phone);
    }, [manager]);

    const onSubmit = useCallback(
        (values) => {
            if (!id) {
                return;
            }
            const phoneNumber = phone.replace(/[^\d]/g, '');
            if (phoneNumber.length === 11) {
                dispatch(
                    updateManager({ id, values, gyms: selectedGyms, phone })
                )
                    .then((manager) => {
                        if (manager) {
                            cogoToast.success('Менеджер обновлен', {
                                position: 'top-right',
                                hideAfter: 5,
                            });
                            setDisabled(true);
                        }
                    })
                    .catch(() => {
                        cogoToast.error('Ошибка при обновлении менеджера', {
                            position: 'top-right',
                            hideAfter: 4,
                        });
                    });
            } else {
                setPhoneError(true);
                cogoToast.error('Заполните телефон менеджера', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            }
        },
        [id, dispatch, selectedGyms, phone]
    );

    const onDeleteManager = useCallback(() => {
        dispatch(deleteManager(id))
            .then(() => {
                cogoToast.success('Менеджер удален', {
                    position: 'top-right',
                    hideAfter: 5,
                });
                history.push(`/dashboard/managers`);
            })
            .catch(() => {
                cogoToast.error('Ошибка при удалении менеджера', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    }, [dispatch, id]);

    if (!manager) {
        return null;
    }

    const initialValues = {
        managerId: manager._id,
        firstName: manager.firstName,
        lastName: manager.lastName,
        email: manager.email,
        phone: manager.phone,
        createdAt: moment(manager.createdAt).format(DATE_TIME_FORMAT),
        role: manager.role,
        domainsId: manager.domainsId,
        verified: manager.verified,
        yclientsId: manager.yclientsId,
    };

    const gymsOptions = gyms.map((gym) => {
        return {
            label: gym.title,
            value: gym._id,
        };
    });

    const gymsValue = gymsOptions.filter((gymsOption) =>
        manager.gyms.includes(gymsOption.value)
    );

    const corporateDomainsOption = corporateDomains.map((corporateDomain) => {
        return {
            title: `${corporateDomain.name} - ${corporateDomain.domain}`,
            value: corporateDomain._id,
        };
    });

    const yclientsGyms = manager.yclientsGyms.map((gym) => {
        return {
            label: `${gym.yclientsGymTitle} (${gym.yclientsId})`,
            value: gym.yclientsId,
        };
    });

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <BackButton
                    to="/dashboard/managers"
                    title="К списку менеджеров"
                    className={styles.backBtn}
                />
                {gymsOptions.length > 0 && (
                    <div className={styles.box}>
                        <ManagerForm
                            onSubmit={onSubmit}
                            initialValues={initialValues}
                            gymsOptions={gymsOptions}
                            gymsValue={gymsValue}
                            onDeleteManager={onDeleteManager}
                            corporateDomainsOption={corporateDomainsOption}
                            setSelectedGyms={setSelectedGyms}
                            disabled={disabled}
                            setDisabled={setDisabled}
                            phone={phone}
                            setPhone={setPhone}
                            phoneError={phoneError}
                            setPhoneError={setPhoneError}
                            yclientsId={initialValues.yclientsId}
                            yclientsGyms={yclientsGyms}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ManagerDetails;
