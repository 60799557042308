import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import { AsyncDispatch } from '@redux/types';
import classNames from 'classnames';

import BackButton from '@components/BackButton';
import {
    updateGoGymSubscription,
    fetchGoGymSubscription,
} from '@redux/modules/subscriptions/actions';
import { DownloadButton } from '@components/DownloadButton';
import { getGymsList } from '@redux/modules/gyms/selectors';
import { fetchGymsList } from '@redux/modules/gyms/actions';
import Button from '@components/Button';
import Modal from '@components/Modal';
import { useAddGoGymSubscription } from '@containers/Dashboard/AddGoGymSubscription/useAddGoGymSubscription';
import { getSubscription } from '@redux/modules/subscriptions/selectors';

import Form from './Form';
import styles from './styles.module.css';

export const GoGymSubscriptionDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const subscription = useSelector(getSubscription(id || ''));
    const history = useHistory();
    const gymsList = useSelector(getGymsList);

    const { onUploadFile } = useAddGoGymSubscription();

    const subscriptionIconFileRef = useRef<null>(null);
    const subscriptionAdvantagesIconFileRef = useRef({});
    const [cancelModalControls, setCancelModalControls] = useState({
        active: false,
    });

    useEffect(() => {
        if (id) {
            dispatch(fetchGoGymSubscription(id));
        }

        dispatch(fetchGymsList());
    }, [dispatch]);

    const onSubmit = useCallback(
        (values) => {
            const iconFiles = (
                subscriptionIconFileRef.current || { files: new FileList() }
            ).files;
            const advantagesIconFiles =
                subscriptionAdvantagesIconFileRef.current;
            const advantagesIconFilesValues: any[] = Object.values(
                advantagesIconFiles
            ).filter((input) => input);

            const iconsFormData = new FormData();

            if (iconFiles?.length) {
                for (let k = 0; k < iconFiles.length; ++k) {
                    iconsFormData.append('icons', iconFiles[k]);
                }
            }

            for (let i = 0; i < advantagesIconFilesValues.length; ++i) {
                for (
                    let j = 0;
                    j < advantagesIconFilesValues[i].files.length;
                    ++j
                ) {
                    iconsFormData.append(
                        'advantagesIconsEditIndexes',
                        i.toString()
                    );
                    iconsFormData.append(
                        `advantagesIcons`,
                        advantagesIconFilesValues[i].files[j]
                    );
                }
            }

            dispatch(
                updateGoGymSubscription({
                    id,
                    values,
                    iconsFormData,
                })
            )
                .then(() => {
                    cogoToast.success('Подписка обновлена успешно', {
                        position: 'top-right',
                        hideAfter: 5,
                    });
                    history.push('/dashboard/gogym-subscriptions');
                })
                .catch(() => {
                    cogoToast.error('Ошибка обновления подписки', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                });
        },
        [dispatch, history, subscriptionIconFileRef]
    );

    const onCancel = useCallback(() => {
        setCancelModalControls({
            active: true,
        });
    }, [dispatch]);

    const onConfirmCancel = useCallback(() => {
        setCancelModalControls({
            active: false,
        });
        history.push('/dashboard/gogym-subscriptions');
    }, [history]);

    const onConfirmAbandonCancel = useCallback(() => {
        setCancelModalControls({
            active: false,
        });
    }, []);

    const onDownloadInstruction = useCallback(() => {
        const fileName = 'create_update_subscription_instruction.pdf';
        axios
            .get(`https://api.gogym.fit/s3-gogym/${fileName}`, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/pdf',
                },
            })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                cogoToast.error('Ошибка при загрузке инструкции', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    }, []);

    const workoutTypesOptions = [
        {
            label: 'Поминутная тренировка',
            value: 'PER_MIN_WORKOUT',
        },
    ];

    const gymOptions = gymsList.reduce<{ label: string; value: string }[]>(
        (acc, gym) => {
            if (gym.hasGroupWorkouts) {
                acc.push({
                    label: gym.title,
                    value: gym._id,
                });
            }
            return acc;
        },
        []
    );

    if (!subscription) {
        return null;
    }

    const initialValues = {
        active: subscription.active,
        activeForProlongation: subscription.activeForProlongation,
        isAdmin: subscription.isAdmin,
        title: subscription.title,
        titleDescription: subscription.titleDescription,
        description: subscription.description,
        iconUrl: subscription.iconUrl,
        additionalDescription: subscription.additionalDescription,
        pricePerMonth: subscription.pricePerMonth,
        gyms: subscription.gyms,
        gymsExclusionList: subscription.gymsExclusionList,
        minPerMinPrice: subscription.minPerMinPrice,
        maxPerMinPrice: subscription.maxPerMinPrice,
        workoutType: subscription.workoutType,
        advantages: subscription.advantages,
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.headerColumn}>
                    <BackButton
                        title="К списку подписок"
                        className={styles.backBtn}
                        onClick={onCancel}
                    />
                </div>
                <div className={styles.headerColumn}>
                    <DownloadButton
                        title="Инструкция по заведению подписки"
                        onClick={onDownloadInstruction}
                    />
                </div>
            </div>

            <Form
                initialValues={initialValues}
                subscriptionIconFileRef={subscriptionIconFileRef}
                subscriptionAdvantagesIconFileRef={
                    subscriptionAdvantagesIconFileRef
                }
                onUploadFile={onUploadFile}
                onSubmit={onSubmit}
                onCancel={onCancel}
                gymOptions={gymOptions}
                workoutTypesOptions={workoutTypesOptions}
            />
            <Modal
                active={cancelModalControls.active}
                setActive={(prev) =>
                    setCancelModalControls((prevState) => ({
                        ...prevState,
                        active: prev,
                    }))
                }
            >
                <div className={styles.modalContainer}>
                    <h2>Внимание!</h2>
                    <p>
                        Создание подписки не завершено. После закрытия <br />
                        страницы все введенные данные будут утеряны.
                    </p>
                    <div className={styles.modalActionControls}>
                        <Button
                            className={styles.modalActionBtn}
                            onClick={onConfirmCancel}
                            buttonType={'primary'}
                            type={'button'}
                        >
                            Все равно закрыть
                        </Button>
                        <Button
                            className={classNames(
                                styles.modalActionBtn,
                                styles.modalActionBtnSecondary
                            )}
                            onClick={onConfirmAbandonCancel}
                            buttonType={'primary'}
                            type={'button'}
                        >
                            Вернуться к редактированию
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default GoGymSubscriptionDetails;
