import React from 'react';
import { useDispatch } from 'react-redux';
import { Field, FormSubmitHandler, SubmissionError } from 'redux-form';
import { Link, useHistory } from 'react-router-dom';

import Form from '@components/Form';
import TextInput from '@components/TextInput';
import Button from '@components/Button';
import { AsyncDispatch } from 'redux/types';
import { fetchUser, signIn } from 'redux/modules/auth/actions';

import { DoNotTellAnyone } from '../DoNotTellAnyone';
import styles from './styles.module.css';

export interface FormData {
    email: string;
    password: string;
}

export const SignIn: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const onSubmit: FormSubmitHandler<FormData> = async ({
        email,
        password,
    }) => {
        try {
            const token = await dispatch(signIn({ email, password }));

            if (!token) {
                throw new SubmissionError({});
            } else if (token === '2fa') {
                history.push('/auth/verify');
            } else {
                await dispatch(fetchUser());
            }
        } catch (err) {
            throw new SubmissionError({
                _error: 'Ошибка при авторизации',
            });
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.box}>
                <h2 className={styles.title}>Вход в GOGYM Бизнес</h2>

                <Form
                    shakeOnError
                    form="signIn"
                    onSubmit={onSubmit}
                    className={styles.form}
                >
                    <Field
                        required
                        type="email"
                        name="email"
                        label="Email"
                        placeholder="Email"
                        component={TextInput}
                    />
                    <Field
                        required
                        type="password"
                        name="password"
                        label="Пароль"
                        placeholder="Пароль"
                        toggleVisibility
                        component={TextInput}
                    />

                    <Button type="submit" className={styles.btn}>
                        Войти
                    </Button>

                    <Link to="/auth/reset-password" className={styles.link}>
                        Забыли пароль?
                    </Link>

                    <Link to="/auth/register" className={styles.link}>
                        Создать новый аккаунт
                    </Link>
                </Form>
                <DoNotTellAnyone className={styles.doNotTellAnyoneBox} />
            </div>
        </div>
    );
};

export default SignIn;
