import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { AbortReasonType } from '@config';
import SelectInput from '@components/SelectInput';
import TextInput from '@components/TextInput';
import Button from '@components/Button';

import styles from './styles.module.css';

export interface FormData {
    abortReasonOption: AbortReasonType;
    abortReasonText: string;
}

const OPTIONS = [
    {
        title: 'Отсутствие интернет соединения',
        value: AbortReasonType.INTERNET_DISCONNECT,
    },
    { title: 'Разряженный телефон', value: AbortReasonType.DISCHARGED_PHONE },
    {
        title: 'Забыл отсканировать QR код',
        value: AbortReasonType.FORGOT_TO_SCAN_QR_CODE,
    },
    { title: 'Другое', value: AbortReasonType.OTHER },
];

export interface Props {
    abortReasonOption: string;
}

const Form: React.FC<Props & InjectedFormProps<FormData, Props>> = ({
    handleSubmit,
    abortReasonOption,
}) => {
    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Field
                name="abortReasonOption"
                label="Причина отмены"
                placeholder="Разряженный телефон"
                options={OPTIONS}
                component={SelectInput}
            />
            {abortReasonOption === AbortReasonType.OTHER && (
                <Field
                    required
                    name="abortReasonText"
                    label="Введите причину отмены"
                    placeholder="Недостаточное количество участников"
                    component={TextInput}
                />
            )}

            <Button type="submit" className={styles.btn}>
                Завершить тренировку
            </Button>
        </form>
    );
};

export default reduxForm<FormData, Props>({
    form: 'cancelBooking',
})(Form);
