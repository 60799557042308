import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';

import { AsyncDispatch } from '@redux/types';
import { getSelectedGymId } from '@redux/modules/gyms/selectors';
import BoxButton from '@components/BoxButton';
import Box from '@components/Box';
import Calendar from '@components/Calendar';
import BackButton from '@components/BackButton';
import { fetchGroupWorkoutSchedule } from '@redux/modules/group-workouts/actions';
import { getGroupWorkoutsCalendar } from '@redux/modules/group-workouts/selectors';

import styles from './styles.module.css';

export function combineDates(date: Date, time: Date): Date {
    const utcdate = new Date(
        new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000
    );
    const utctime = new Date(
        new Date(time).getTime() + new Date(time).getTimezoneOffset() * 60000
    );
    const combinedDate = moment(utcdate)
        .set({
            hour: moment(utctime).hour(),
            minute: moment(utctime).minute(),
            second: moment(utctime).second(),
            millisecond: moment(utctime).millisecond(),
        })
        .toDate();
    return combinedDate;
}

export const GroupWorkoutCalendar: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const groupWorkouts = useSelector(getGroupWorkoutsCalendar());
    const selectedGymId = useSelector(getSelectedGymId);
    const history = useHistory();
    const [startWeek, setStartWeek] = useState(
        moment().utc(true).startOf('week').toISOString()
    );

    useEffect(() => {
        if (!id) {
            return;
        }
        dispatch(
            fetchGroupWorkoutSchedule({
                groupWorkoutId: id,
                date: startWeek,
            })
        );
    }, [dispatch, selectedGymId, startWeek]);

    const events = groupWorkouts.map(
        ({
            _id,
            timeSlotId,
            title,
            date,
            timeStart,
            timeFinish,
            limit,
            freeReserve,
        }) => ({
            groupWorkoutId: _id,
            timeSlotId,
            title: title || '',
            start: combineDates(date, timeStart),
            end: combineDates(date, timeFinish),
            reservedSlots: freeReserve,
            availableSlots: limit,
        })
    );

    const handleCalendarNavigate = (date: Date) => {
        setStartWeek(moment(date).startOf('week').utc(true).toISOString());
    };

    const goToGroupWorkout = useCallback(
        () => history.push(`/dashboard/group-workouts/${id}`),
        [history]
    );

    const goToReservations = useCallback(
        () => history.push(`/dashboard/group-workouts/${id}/reservations`),
        [history]
    );

    return (
        <div>
            <BackButton
                title="Все тренировки"
                className={styles.backBtn}
                onClick={() => history.push('/dashboard/group-workouts')}
            />
            <div className={styles.actions}>
                <div className={styles.nav}>
                    <BoxButton
                        icon=""
                        title="Общая информация"
                        onClick={goToGroupWorkout}
                        className={styles.actionBtn}
                    />
                    <BoxButton
                        icon=""
                        title="Календарь"
                        className={styles.actionNav}
                    />
                    <BoxButton
                        icon=""
                        title="Бронирования"
                        onClick={goToReservations}
                        className={styles.actionBtn}
                    />
                </div>
            </div>
            <Box>
                <h3>
                    Нажмите на необходимую вам дату / время для создания новой
                    тренировки
                </h3>
                <Calendar
                    events={events}
                    handleCalendarNavigate={handleCalendarNavigate}
                />
            </Box>
        </div>
    );
};

export default GroupWorkoutCalendar;
