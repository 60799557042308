import * as React from 'react';
import {
    PlacemarkGeometry,
    Placemark as RYMPlacemark,
    YMapsApi,
} from 'react-yandex-maps';

interface PlacemarkProps {
    geometry: PlacemarkGeometry;

    properties?: {
        iconCaption?: string;
        hintContent?: string;
        balloonContent?: string;
        balloonContentHeader?: string;
        balloonContentBody?: string;
        balloonContentFooter?: string;
        balloonImageHref?: string;
    };

    options?: {
        draggable?: boolean;
        preset?: string;
        iconColor?: string;
        iconLayout?: string;
        iconImageHref?: string;
        iconImageSize?: number[];
        iconImageOffset?: number[];
        hideIconOnBalloonOpen?: boolean;
        openEmptyBalloon?: boolean;
        openEmptyHint?: boolean;
        openHintOnHover?: boolean;
        zIndex?: number;
    };

    instanceRef?: (ref: YMapsApi) => void;

    onClick?: (event: ymaps.IEvent) => void;
    onDragStart?: (event: ymaps.IEvent) => void;
    onDrag?: (event: ymaps.IEvent) => void;
    onDragEnd?: (event: ymaps.IEvent) => void;
    onMouseEnter?: (event: ymaps.IEvent) => void;
    onMouseLeave?: (event: ymaps.IEvent) => void;
    onGeometryChange?: (event: ymaps.IEvent) => void;
}

export const Placemark = React.memo<PlacemarkProps>((props) => (
    <RYMPlacemark {...props} />
));
