import axios from 'axios';
import qs from 'qs';

import { Tag, TagCategorie } from '@t/tag';
import { B2B_API_URL } from '@config';
import { AsyncActionCreator } from '@redux/types';
import { TagFormData } from '@containers/Dashboard/TagDetails/Form';

import {
    FETCH_TAGS_REQUEST,
    FETCH_TAGS_SUCCESS,
    FETCH_TAGS_ERROR,
    FETCH_TAG_REQUEST,
    FETCH_TAG_SUCCESS,
    FETCH_TAG_ERROR,
    DELETE_TAG_REQUEST,
    DELETE_TAG_SUCCESS,
    DELETE_TAG_ERROR,
    UPDATE_TAG_REQUEST,
    UPDATE_TAG_SUCCESS,
    UPDATE_TAG_ERROR,
    CREATE_TAG_REQUEST,
    CREATE_TAG_SUCCESS,
    CREATE_TAG_ERROR,
    DELETE_TAG_ICON_REQUEST,
    DELETE_TAG_ICON_SUCCESS,
    DELETE_TAG_ICON_ERROR,
    FETCH_TAG_CATEGORIES_REQUEST,
    FETCH_TAG_CATEGORIES_SUCCESS,
    FETCH_TAG_CATEGORIES_ERROR,
} from './types';

export const fetchTags: AsyncActionCreator<string | null | void, Tag[]> = (
    selectedGymId?: string | null | void
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_TAGS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: tags } = await axios.get(`${B2B_API_URL}/tags`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                selectedGymId,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        dispatch({
            type: FETCH_TAGS_SUCCESS,
            payload: {
                tags,
            },
        });

        return tags;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_TAGS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchTag: AsyncActionCreator<string, Tag> = (
    id?: string
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_TAG_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: tag } = await axios.get(`${B2B_API_URL}/tags/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: FETCH_TAG_SUCCESS,
            payload: tag,
        });

        return tag;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_TAG_ERROR,
            });

            throw err;
        }
    }
};

export const createTag: AsyncActionCreator<
    {
        values: TagFormData;
        tagType: { name: string; tagTypeValue: string }[];
        categoryIds: string[];
        files: FileList;
    },
    Tag
> = ({ values, tagType, categoryIds, files }) => async (dispatch, getState) => {
    dispatch({
        type: CREATE_TAG_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    const formData = new FormData();

    if (files?.length) {
        for (let k = 0; k < files.length; ++k) {
            formData.append('icons', files[k]);
        }
    }

    const newValues = { ...values, tagType, categoryIds };

    try {
        const { data: tag } = await axios.post(
            `${B2B_API_URL}/tags`,
            newValues,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (files?.length) {
            await axios.put(
                `${B2B_API_URL}/tags/icon-upload/${tag._id}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-type': 'multipart/form-data',
                    },
                }
            );
        }

        dispatch({
            type: CREATE_TAG_SUCCESS,
            payload: tag,
        });

        return tag;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: CREATE_TAG_ERROR,
            });

            throw err;
        }
    }
};

export const deleteTag: AsyncActionCreator<string> = (id) => async (
    dispatch,
    getState
) => {
    dispatch({
        type: DELETE_TAG_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: tag } = await axios.delete(`${B2B_API_URL}/tags/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: DELETE_TAG_SUCCESS,
            payload: { tag },
        });

        return tag;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: DELETE_TAG_ERROR,
            });

            throw err;
        }
    }
};

export const updateTag: AsyncActionCreator<
    {
        id: string;
        values: TagFormData;
        tagType: { name: string; tagTypeValue: string }[];
        categoryIds: string[];
        files?: FileList;
    },
    Tag
> = ({ id, values, tagType, categoryIds, files }) => async (
    dispatch,
    getState
) => {
    dispatch({
        type: UPDATE_TAG_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    const newValues = { ...values, tagType, categoryIds };

    const formData = new FormData();

    if (files?.length) {
        for (let k = 0; k < files.length; ++k) {
            formData.append('icons', files[k]);
        }
    }

    try {
        const { data: tag } = await axios.put(
            `${B2B_API_URL}/tags/${id}`,
            newValues,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (files?.length) {
            const { data: tagInIcon } = await axios.put(
                `${B2B_API_URL}/tags/icon-upload/${tag._id}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-type': 'multipart/form-data',
                    },
                }
            );

            dispatch({
                type: UPDATE_TAG_SUCCESS,
                payload: tagInIcon,
            });
        } else {
            dispatch({
                type: UPDATE_TAG_SUCCESS,
                payload: tag,
            });
        }

        return tag;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPDATE_TAG_ERROR,
            });

            throw err;
        }
    }
};

export const deleteIcon: AsyncActionCreator<
    { id: string; key: string },
    string[]
> = ({ id, key }) => async (dispatch, getState) => {
    dispatch({
        type: DELETE_TAG_ICON_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const {
            data: { tag },
        } = await axios.post(
            `${B2B_API_URL}/tags/icon-delete/${id}`,
            { key },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: DELETE_TAG_ICON_SUCCESS,
            payload: tag,
        });

        return tag;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: DELETE_TAG_ICON_ERROR,
            });

            throw err;
        }
    }
};

export const fetchTagCategories: AsyncActionCreator<
    void,
    TagCategorie[]
> = () => async (dispatch, getState) => {
    dispatch({
        type: FETCH_TAG_CATEGORIES_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: tagCategories } = await axios.get(
            `${B2B_API_URL}/tags/categories`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_TAG_CATEGORIES_SUCCESS,
            payload: tagCategories,
        });

        return tagCategories;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_TAG_CATEGORIES_ERROR,
            });

            throw err;
        }
    }
};
