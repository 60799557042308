import axios from 'axios';
import qs from 'qs';

import { B2B_API_URL, REPORTS_API_URL } from '@config';
import {
    GroupWorkoutReservation,
    IGroupWorkoutReservationNew,
} from '@t/reservation';
import { AsyncActionCreator } from '@redux/types';

import {
    FETCH_GROUP_WORKOUT_RESERVATIONS_ERROR,
    FETCH_GROUP_WORKOUT_RESERVATIONS_REQUEST,
    FETCH_GROUP_WORKOUT_RESERVATIONS_SUCCESS,
    FETCH_RESERVATION_ERROR,
    FETCH_RESERVATION_REQUEST,
    FETCH_RESERVATION_SUCCESS,
    FETCH_RESERVATIONS_ERROR,
    FETCH_RESERVATIONS_FOR_REPORT_ERROR,
    FETCH_RESERVATIONS_FOR_REPORT_REQUEST,
    FETCH_RESERVATIONS_REQUEST,
    FETCH_RESERVATIONS_SUCCESS,
    FETCH_SCROLL_RESERVATIONS_ERROR,
    FETCH_SCROLL_RESERVATIONS_REQUEST,
    FETCH_SCROLL_RESERVATIONS_SUCCESS,
    FETCH_USER_RESERVATION_ERROR,
    FETCH_USER_RESERVATION_REQUEST,
    FETCH_USER_RESERVATION_SUCCESS,
    UPDATE_MULTIPLE_RESERVATION_ERROR,
    UPDATE_MULTIPLE_RESERVATION_REQUEST,
    UPDATE_MULTIPLE_RESERVATION_SUCCESS,
    UPDATE_RESERVATION_ERROR,
    UPDATE_RESERVATION_REQUEST,
    UPDATE_RESERVATION_SUCCESS,
} from './types';
import moment from 'moment';

export const fetchReservations: AsyncActionCreator<
    {
        selectedGymId?: string | null | void;
        groupWorkoutId?: string | null;
        timeSlotId?: string | null;
        date?: Date | null;
        isToggled: boolean;
        count: number;
    },
    IGroupWorkoutReservationNew[]
> = ({
    selectedGymId,
    groupWorkoutId,
    timeSlotId,
    date,
    isToggled,
    count,
}) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_RESERVATIONS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    const params: {
        count: number;
        gymId?: string | null;
        groupWorkoutId?: string | null;
        timeSlotId?: string | null;
        date?: Date | null;
        status?: string;
    } = {
        count,
    };
    if (selectedGymId) {
        params['gymId'] = selectedGymId;
    }
    if (groupWorkoutId) {
        params['groupWorkoutId'] = groupWorkoutId;
    }
    if (timeSlotId) {
        params['timeSlotId'] = timeSlotId;
    }
    if (date) {
        params['date'] = date;
    }
    if (!isToggled) {
        params['status'] = 'NEW';
    }

    try {
        const { data: items } = await axios.get(
            `${B2B_API_URL}/group-workouts-new/reservations/all`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params,
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                },
            }
        );

        dispatch({
            type: FETCH_RESERVATIONS_SUCCESS,
            payload: {
                items,
            },
        });

        return items;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_RESERVATIONS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchScrollReservations: AsyncActionCreator<
    {
        selectedGymId?: string | null | void;
        groupWorkoutId?: string | null;
        timeSlotId?: string | null;
        date?: Date | null;
        isToggled: boolean;
        count: number;
    },
    IGroupWorkoutReservationNew[]
> = ({
    selectedGymId,
    groupWorkoutId,
    timeSlotId,
    date,
    isToggled,
    count,
}) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_SCROLL_RESERVATIONS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    const params: {
        count: number;
        gymId?: string | null;
        groupWorkoutId?: string | null;
        timeSlotId?: string | null;
        date?: Date | null;
        status?: string;
    } = {
        count,
    };
    if (selectedGymId) {
        params['gymId'] = selectedGymId;
    }
    if (groupWorkoutId) {
        params['groupWorkoutId'] = groupWorkoutId;
    }
    if (timeSlotId) {
        params['timeSlotId'] = timeSlotId;
    }
    if (date) {
        params['date'] = date;
    }
    if (!isToggled) {
        params['status'] = 'NEW';
    }

    try {
        const { data: reservations } = await axios.get(
            `${B2B_API_URL}/group-workouts-new/reservations/all`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params,
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                },
            }
        );

        dispatch({
            type: FETCH_SCROLL_RESERVATIONS_SUCCESS,
            payload: reservations,
        });

        return reservations;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_SCROLL_RESERVATIONS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchReservation: AsyncActionCreator<
    string,
    IGroupWorkoutReservationNew
> = (id: string) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_RESERVATION_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: reservation } = await axios.get(
            `${B2B_API_URL}/group-workouts-new/reservations/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_RESERVATION_SUCCESS,
            payload: reservation,
        });

        return reservation;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_RESERVATION_ERROR,
            });

            throw err;
        }
    }
};

export const fetchUserReservations: AsyncActionCreator<
    {
        userId: string;
        gymId: string;
    },
    IGroupWorkoutReservationNew[]
> = ({ userId, gymId }) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_USER_RESERVATION_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: reservations } = await axios.get(
            `${B2B_API_URL}/group-workouts-new/reservations/all`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    userId,
                    gymId,
                },
            }
        );

        dispatch({
            type: FETCH_USER_RESERVATION_SUCCESS,
            payload: reservations,
        });

        return reservations;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_USER_RESERVATION_ERROR,
            });

            throw err;
        }
    }
};

export const fetchReservationsOfGroupWorkout: AsyncActionCreator<
    { groupWorkoutId: string; dateStart: Date },
    GroupWorkoutReservation[]
> = ({ groupWorkoutId, dateStart }) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_GROUP_WORKOUT_RESERVATIONS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: reservations } = await axios.get(
            `${B2B_API_URL}/reservations`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    groupWorkoutId,
                    dateStart: dateStart
                        ? new Date(
                              new Date(dateStart).getTime() -
                                  new Date(dateStart).getTimezoneOffset() *
                                      60000
                          )
                        : '',
                },
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                },
            }
        );
        dispatch({
            type: FETCH_GROUP_WORKOUT_RESERVATIONS_SUCCESS,
            payload: {
                reservations,
            },
        });

        return reservations;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_GROUP_WORKOUT_RESERVATIONS_ERROR,
            });

            throw err;
        }
    }
};

export const updateReservation: AsyncActionCreator<
    {
        id: string;
        status?: string;
        comment?: string;
    },
    GroupWorkoutReservation
> = ({ id, status, comment }) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_RESERVATION_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: reservation } = await axios.put(
            `${B2B_API_URL}/group-workouts-new/reservations/${id}`,
            { status, comment },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: UPDATE_RESERVATION_SUCCESS,
            payload: reservation,
        });

        return reservation;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPDATE_RESERVATION_ERROR,
            });

            throw err;
        }
    }
};

export const abortReservation: AsyncActionCreator<
    {
        id: string;
        abortReasonMessage: string;
    },
    GroupWorkoutReservation
> = ({ id, abortReasonMessage }) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_RESERVATION_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: reservation } = await axios.put(
            `${B2B_API_URL}/reservations/${id}`,
            { abortReasonMessage },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: UPDATE_RESERVATION_SUCCESS,
            payload: reservation,
        });

        return reservation;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPDATE_RESERVATION_ERROR,
            });

            throw err;
        }
    }
};

export const abortMultipleReservations: AsyncActionCreator<
    {
        groupWorkoutReservations: string[];
    },
    {
        groupWorkoutReservations: string[];
    }
> = ({ groupWorkoutReservations }) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_MULTIPLE_RESERVATION_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: reservation } = await axios.put(
            `${B2B_API_URL}/group-workouts-new/reservations`,
            { groupWorkoutReservations },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: UPDATE_MULTIPLE_RESERVATION_SUCCESS,
            payload: reservation,
        });

        return reservation;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPDATE_MULTIPLE_RESERVATION_ERROR,
            });

            throw err;
        }
    }
};

export const fetchReservationsReport: AsyncActionCreator<
    string | null | void,
    void
> = () => async (dispatch, getState) => {
    dispatch({
        type: FETCH_RESERVATIONS_FOR_REPORT_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data } = await axios.get(
            `${REPORTS_API_URL}/reservations/list`,
            {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `reservations-list-${moment().format('DD-MM-YYYY-HH-mm')}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_RESERVATIONS_FOR_REPORT_ERROR,
            });

            throw err;
        }
    }
};
