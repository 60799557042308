import React from 'react';
import { IconProps } from './types';

export const GoGym: React.FC<IconProps> = ({ ...props }) => (
    <svg
        width="142"
        height="35"
        viewBox="0 0 142 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M0.305195 8.70596C0.565284 4.13275 4.21045 0.514289 8.78491 0.277028C11.6919 0.126255 14.9107 0 17.5388 0C20.1486 0 23.3409 0.1245 26.2319 0.273878C30.8331 0.51163 34.4889 4.16877 34.7258 8.77008C34.8753 11.6721 35 14.8791 35 17.5C35 20.1209 34.8753 23.3279 34.7258 26.2299C34.4889 30.8312 30.8331 34.4884 26.2319 34.7261C23.3409 34.8755 20.1486 35 17.5388 35C14.9107 35 11.6919 34.8737 8.7849 34.723C4.21045 34.4857 0.565284 30.8672 0.305195 26.294C0.139209 23.3755 0 20.1406 0 17.5C0 14.8594 0.139209 11.6245 0.305195 8.70596Z"
            fill="#F07C2D"
        />
        <path
            d="M21.7471 5.36621L23.4361 6.46693V8.42283L12.6015 14.8578V22.004L18.9519 25.7634L22.3298 23.6466V14.4683L17.1448 17.4487V19.2522L20.6747 21.4537L20.7507 23.2572L18.9519 24.3579L13.6993 21.2166L13.6233 15.569L24.0357 9.52355L25.8428 10.5481V25.7888L18.8675 29.6329L9.08008 24.1208V12.741L21.7471 5.36621Z"
            fill="#FEFEFE"
        />
        <path
            d="M56.6675 18.7891C56.6665 18.7187 56.6855 18.6495 56.7223 18.5895C56.7591 18.5295 56.8122 18.4811 56.8754 18.4501L58.6916 17.5315C58.8005 17.4882 58.9219 17.4882 59.0308 17.5315L60.847 18.4501C60.9102 18.4811 60.9633 18.5295 61.0001 18.5895C61.0369 18.6495 61.0559 18.7187 61.0549 18.7891V25.7881C59.9996 26.5265 58.8269 27.0811 57.5866 27.4285C56.3156 27.8088 54.9964 28.004 53.6697 28.0082C51.9497 28.0523 50.2448 27.677 48.7025 26.9145C47.2646 26.1634 46.0638 25.0275 45.2343 23.6337C44.3823 22.2231 43.9461 20.6006 43.9761 18.9531C43.962 17.2933 44.4171 15.6633 45.289 14.2506C46.1257 12.8714 47.3357 11.7567 48.7791 11.0355C50.3225 10.2477 52.0354 9.8497 53.7682 9.87625C55.2667 9.86004 56.7537 10.1387 58.1446 10.6964C59.2885 11.1697 60.3181 11.8818 61.1643 12.7852C61.2338 12.8549 61.2728 12.9493 61.2728 13.0477C61.2728 13.1461 61.2338 13.2405 61.1643 13.3102L58.5494 15.6505C58.4765 15.7181 58.3808 15.7557 58.2813 15.7557C58.1819 15.7557 58.0861 15.7181 58.0133 15.6505C57.4992 15.1149 56.8828 14.6879 56.2006 14.3948C55.5184 14.1018 54.7843 13.9486 54.0417 13.9444C53.3676 13.9161 52.6949 14.0257 52.0646 14.2664C51.4344 14.5071 50.86 14.8739 50.3765 15.3443C49.9089 15.8196 49.545 16.3867 49.3077 17.0098C49.0704 17.6328 48.965 18.2982 48.9979 18.9641C48.9973 19.8602 49.2271 20.7414 49.6653 21.5231C50.0754 22.2628 50.681 22.8757 51.4159 23.2947C52.2127 23.7207 53.1055 23.9353 54.0089 23.9181C54.9374 23.9213 55.8556 23.7236 56.7004 23.3385L56.6675 18.7891Z"
            fill="#FEFEFE"
        />
        <path
            d="M72.8058 28.0079C71.0614 28.0334 69.3372 27.6316 67.7839 26.8378C66.3424 26.0796 65.1327 24.946 64.2828 23.557C63.4434 22.1495 63.0116 20.5367 63.0355 18.8983C62.9977 17.2663 63.4238 15.6572 64.2643 14.2576C65.1048 12.8581 66.3252 11.7255 67.7839 10.9915C69.3402 10.2115 71.0654 9.82831 72.8058 9.87604C74.5439 9.8438 76.2626 10.246 77.8058 11.0462C79.2594 11.7884 80.4764 12.9222 81.3194 14.3193C82.1623 15.7165 82.5976 17.3215 82.576 18.9529C82.5994 20.5957 82.1555 22.2113 81.2959 23.6117C80.4605 25.0084 79.2518 26.1446 77.8058 26.8925C76.2559 27.6685 74.5387 28.0516 72.8058 28.0079ZM72.8058 23.9179C73.6496 23.9302 74.4811 23.7148 75.2128 23.2945C75.9347 22.8794 76.5289 22.2741 76.9305 21.5448C77.3553 20.7399 77.5698 19.8409 77.5541 18.9311C77.5739 18.0278 77.359 17.1348 76.9305 16.3392C76.5326 15.6015 75.938 14.9883 75.2128 14.5676C74.4811 14.1473 73.6496 13.932 72.8058 13.9442C71.9587 13.9337 71.1241 14.1489 70.3878 14.5676C69.6684 14.9921 69.0783 15.6046 68.681 16.3392C68.2526 17.1348 68.0377 18.0278 68.0574 18.9311C68.0417 19.8409 68.2563 20.7399 68.681 21.5448C69.0819 22.271 69.6716 22.8756 70.3878 23.2945C71.1241 23.7132 71.9587 23.9284 72.8058 23.9179Z"
            fill="#FEFEFE"
        />
        <path
            d="M96.7115 18.7891C96.7105 18.7187 96.7295 18.6495 96.7663 18.5894C96.8031 18.5294 96.8562 18.4811 96.9194 18.45L98.7137 17.5314C98.8262 17.4875 98.9512 17.4875 99.0638 17.5314L100.88 18.45C100.942 18.483 100.993 18.5318 101.03 18.5914C101.066 18.651 101.086 18.7192 101.088 18.7891V25.7881C100.03 26.5224 98.8583 27.0767 97.6196 27.4285C96.3484 27.8081 95.0294 28.0033 93.7027 28.0081C91.9792 28.0523 90.2707 27.677 88.7246 26.9145C87.2866 26.1634 86.0858 25.0275 85.2563 23.6337C84.4119 22.2199 83.9763 20.5996 83.9981 18.9531C83.9729 17.2993 84.4085 15.6711 85.2563 14.2506C86.0958 12.8736 87.305 11.7596 88.7465 11.0354C90.3102 10.2389 92.0465 9.84067 93.8012 9.87621C95.2999 9.85742 96.7875 10.1362 98.1776 10.6964C99.3245 11.1641 100.355 11.8771 101.197 12.7852C101.267 12.8549 101.306 12.9493 101.306 13.0477C101.306 13.146 101.267 13.2404 101.197 13.3101L98.5933 15.6504C98.5187 15.7188 98.4211 15.7568 98.3198 15.7568C98.2185 15.7568 98.1209 15.7188 98.0463 15.6504C97.5283 15.1132 96.9059 14.6876 96.2172 14.3999C95.5285 14.1121 94.7882 13.9683 94.0419 13.9772C93.3754 13.9472 92.7097 14.0528 92.0852 14.2878C91.4608 14.5228 90.8907 14.8822 90.4095 15.3442C89.95 15.8176 89.5925 16.3802 89.3591 16.9971C89.1258 17.6141 89.0215 18.2724 89.0528 18.9312C89.0385 19.8339 89.253 20.7256 89.6765 21.5231C90.0909 22.2632 90.7001 22.8759 91.4379 23.2947C92.2308 23.7207 93.12 23.9354 94.02 23.918C94.9486 23.9223 95.867 23.7245 96.7115 23.3384V18.7891Z"
            fill="#FEFEFE"
        />
        <path
            d="M113.123 21.3917V27.2753C113.123 27.3768 113.083 27.4741 113.011 27.5459C112.939 27.6177 112.842 27.658 112.74 27.658H108.517C108.415 27.658 108.318 27.6177 108.246 27.5459C108.174 27.4741 108.134 27.3768 108.134 27.2753V21.3261L101.777 10.7838C101.743 10.7265 101.724 10.6612 101.723 10.5944C101.721 10.5276 101.738 10.4616 101.77 10.403C101.802 10.3444 101.849 10.2952 101.906 10.2603C101.963 10.2254 102.028 10.2061 102.095 10.2042H106.471C106.537 10.2042 106.602 10.2213 106.66 10.2539C106.717 10.2865 106.765 10.3334 106.799 10.3901L110.858 17.1376L114.917 10.3901C114.951 10.3326 114.998 10.2851 115.056 10.2524C115.114 10.2197 115.179 10.2031 115.246 10.2042H119.173C119.241 10.2042 119.308 10.2222 119.366 10.2563C119.425 10.2904 119.473 10.3394 119.506 10.3984C119.54 10.4573 119.557 10.524 119.556 10.5917C119.555 10.6594 119.536 10.7257 119.502 10.7838L113.123 21.3917Z"
            fill="#FEFEFE"
        />
        <path
            d="M137.292 27.6581C137.19 27.6581 137.093 27.6178 137.021 27.546C136.949 27.4743 136.909 27.3769 136.909 27.2754V18.4063L132.532 25.7553C132.499 25.8091 132.452 25.8534 132.397 25.884C132.341 25.9146 132.279 25.9305 132.215 25.9303H130.432C130.367 25.9314 130.302 25.9159 130.245 25.8853C130.188 25.8547 130.139 25.8099 130.104 25.7553L125.727 18.6578V27.2754C125.727 27.3769 125.687 27.4743 125.615 27.546C125.543 27.6178 125.446 27.6581 125.344 27.6581H121.515C121.413 27.6581 121.316 27.6178 121.244 27.546C121.172 27.4743 121.132 27.3769 121.132 27.2754V10.587C121.13 10.5364 121.139 10.4859 121.158 10.4388C121.177 10.3917 121.205 10.3489 121.241 10.313C121.277 10.2772 121.319 10.249 121.367 10.2303C121.414 10.2116 121.464 10.2028 121.515 10.2043H125.016C125.079 10.204 125.142 10.2199 125.197 10.2505C125.253 10.2812 125.3 10.3255 125.333 10.3792L131.406 20.2763L137.325 10.4339C137.358 10.3765 137.406 10.329 137.463 10.2963C137.521 10.2636 137.586 10.2469 137.653 10.248H141.154C141.254 10.248 141.349 10.2868 141.421 10.3562C141.492 10.4257 141.534 10.5202 141.537 10.6198V27.3191C141.537 27.4206 141.497 27.518 141.425 27.5898C141.353 27.6616 141.255 27.7019 141.154 27.7019L137.292 27.6581Z"
            fill="#FEFEFE"
        />
    </svg>
);

export default GoGym;
