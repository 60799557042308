import axios from 'axios';
import qs from 'qs';
import moment from 'moment';

import { Booking } from '@t/booking';
import { AbortReasonType, B2B_API_URL, REPORTS_API_URL } from '@config';
import { AsyncActionCreator, AsyncDispatch } from '@redux/types';
import { UserPaymentData } from '@t/app-user';

import {
    FETCH_BOOKINGS_REQUEST,
    FETCH_BOOKINGS_SUCCESS,
    FETCH_BOOKINGS_ERROR,
    FETCH_BOOKING_REQUEST,
    FETCH_BOOKING_SUCCESS,
    FETCH_BOOKING_ERROR,
    FETCH_BOOKING_USER_SUCCESS,
    UPDATE_BOOKING_REQUEST,
    UPDATE_BOOKING_SUCCESS,
    UPDATE_BOOKING_ERROR,
    ABORT_BOOKING_REQUEST,
    ABORT_BOOKING_SUCCESS,
    ABORT_BOOKING_ERROR,
    WRITEOFFCOST_BOOKING_REQUEST,
    WRITEOFFCOST_BOOKING_SUCCESS,
    WRITEOFFCOST_BOOKING_ERROR,
    FETCH_BOOKING_COUPON_SUCCESS,
    FETCH_SCROLL_BOOKINGS_REQUEST,
    FETCH_SCROLL_BOOKINGS_SUCCESS,
    FETCH_SCROLL_BOOKINGS_ERROR,
    FETCH_FILTER_BOOKINGS_REQUEST,
    FETCH_FILTER_BOOKINGS_SUCCESS,
    FETCH_FILTER_BOOKINGS_ERROR,
    FETCH_UNPAID_BOOKINGS_REQUEST,
    FETCH_UNPAID_BOOKINGS_SUCCESS,
    FETCH_UNPAID_BOOKINGS_ERROR,
    FETCH_BOOKINGS_FOR_REPORT_REQUEST,
    FETCH_BOOKINGS_FOR_REPORT_SUCCESS,
    FETCH_BOOKINGS_FOR_REPORT_ERROR,
    DELETE_BOOKING_REQUEST,
    DELETE_BOOKING_SUCCESS,
    DELETE_BOOKING_ERROR,
    FETCH_TEST_BOOKINGS_REQUEST,
    FETCH_TEST_BOOKINGS_SUCCESS,
    FETCH_TEST_BOOKINGS_ERROR,
    CLEAR_TEST_BOOKINGS_SUCCESS,
    CLEAR_UNPAID_BOOKINGS_SUCCESS,
    START_USER_REMOTE_BOOKING_REQUEST,
    START_USER_REMOTE_BOOKING_SUCCESS,
    START_USER_REMOTE_BOOKING_ERROR,
    RUN_AUTO_PAID_BOOKINGS_REQUEST,
    RUN_AUTO_PAID_BOOKINGS_SUCCESS,
    RUN_AUTO_PAID_BOOKINGS_ERROR,
} from './types';
import { User } from '@t/user';
import { UserSubscription } from '@t/subscriptions';

export const fetchBookings: AsyncActionCreator<
    {
        selectedGymId: string;
        bookingCount: number;
    },
    Booking[]
> = ({ selectedGymId, bookingCount }) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_BOOKINGS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data } = await axios.get(`${B2B_API_URL}/bookings`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                selectedGymId: selectedGymId,
                bookingCount,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        dispatch({
            type: FETCH_BOOKINGS_SUCCESS,
            payload: data.bookings,
        });

        return data.bookings;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_BOOKINGS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchScrollBookings: AsyncActionCreator<
    {
        selectedGymId: string;
        bookingCount: number;
    },
    Booking[]
> = ({ selectedGymId, bookingCount }) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_SCROLL_BOOKINGS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data } = await axios.get(`${B2B_API_URL}/bookings`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                selectedGymId: selectedGymId,
                bookingCount,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        dispatch({
            type: FETCH_SCROLL_BOOKINGS_SUCCESS,
            payload: data.bookings,
        });

        return data.bookings;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_SCROLL_BOOKINGS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchUnpaidBookings: AsyncActionCreator<
    {
        selectedGymId: string;
    },
    Booking[]
> = ({ selectedGymId }) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_UNPAID_BOOKINGS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data } = await axios.get(`${B2B_API_URL}/bookings/filter`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                selectedGymId: selectedGymId,
                type: 'UNPAID',
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        dispatch({
            type: FETCH_UNPAID_BOOKINGS_SUCCESS,
            payload: data.bookings,
        });

        return data.bookings;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_UNPAID_BOOKINGS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchTestBookings: AsyncActionCreator<
    {
        selectedGymId: string;
    },
    Booking[]
> = ({ selectedGymId }) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_TEST_BOOKINGS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data } = await axios.get(`${B2B_API_URL}/bookings/filter`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                selectedGymId: selectedGymId,
                type: 'TEST',
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        dispatch({
            type: FETCH_TEST_BOOKINGS_SUCCESS,
            payload: data.bookings,
        });

        return data.bookings;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_TEST_BOOKINGS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchBooking: AsyncActionCreator<
    string,
    {
        booking: Booking;
        user: User;
        userPaymentDatas: UserPaymentData;
        userSubscriptions: UserSubscription[];
    }
> = (id?: string) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_BOOKING_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data } = await axios.get(`${B2B_API_URL}/bookings/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: FETCH_BOOKING_SUCCESS,
            payload: data.booking,
        });

        dispatch({
            type: FETCH_BOOKING_USER_SUCCESS,
            payload: {
                booking: data.booking,
                user: data.user,
                userPaymentDatas: data.userPaymentDatas,
            },
        });

        dispatch({
            type: FETCH_BOOKING_COUPON_SUCCESS,
            payload: {
                booking: data.booking,
                coupon: data.coupon,
            },
        });

        return data;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_BOOKING_ERROR,
            });

            throw err;
        }
    }
};

export const updateBooking: AsyncActionCreator<
    {
        id: string;
        timeStarted: string;
        timeFinished: string;
        gymTitle: string;
        gymId: string;
        subscriptionId: string;
    },
    Booking
> = ({
    id,
    timeStarted,
    timeFinished,
    gymTitle,
    gymId,
    subscriptionId,
}) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_BOOKING_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    const newValues: {
        timeStarted: string;
        timeFinished: string;
        gymTitle: string;
        gymId: string;
        subscriptionId?: string;
    } = {
        timeStarted,
        timeFinished,
        gymTitle,
        gymId,
    };

    if (subscriptionId) {
        newValues['subscriptionId'] = subscriptionId;
    }

    try {
        const { data: booking } = await axios.put(
            `${B2B_API_URL}/bookings/${id}`,
            newValues,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    type: 'UPDATE',
                },
            }
        );

        dispatch({
            type: UPDATE_BOOKING_SUCCESS,
            payload: booking,
        });

        return booking;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPDATE_BOOKING_ERROR,
            });

            throw err;
        }
    }
};

export const abortBooking: AsyncActionCreator<
    {
        id: string;
        abortReasonType: AbortReasonType;
        abortReasonMessage: string;
    },
    Booking
> = ({ id, abortReasonType, abortReasonMessage }) => async (
    dispatch,
    getState
) => {
    dispatch({
        type: ABORT_BOOKING_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: booking } = await axios.put(
            `${B2B_API_URL}/bookings/${id}`,
            { abortReasonType, abortReasonMessage },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    type: 'ABORT',
                },
            }
        );

        dispatch({
            type: ABORT_BOOKING_SUCCESS,
            payload: { booking },
        });

        return booking;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: ABORT_BOOKING_ERROR,
            });

            throw err;
        }
    }
};

export const writeOffCostOfBooking: AsyncActionCreator<
    {
        id: string;
        userPaymentDataId: UserPaymentData;
    },
    UserPaymentData
> = ({ id, userPaymentDataId }) => async (dispatch, getState) => {
    dispatch({
        type: WRITEOFFCOST_BOOKING_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: booking } = await axios.put(
            `${B2B_API_URL}/bookings/pay/${id}`,
            { userPaymentDataId },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: WRITEOFFCOST_BOOKING_SUCCESS,
            payload: booking,
        });

        return booking;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: WRITEOFFCOST_BOOKING_ERROR,
            });

            throw err;
        }
    }
};

export const filterBookings: AsyncActionCreator<
    {
        startDate: string;
        endDate: string;
        selectedGymId?: string;
    },
    Booking[]
> = ({ selectedGymId, startDate, endDate }) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_FILTER_BOOKINGS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const {
            data: { bookings },
        } = await axios.get(`${B2B_API_URL}/bookings/filter`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                type: 'FILTER',
                startDate,
                endDate,
                selectedGymId,
            },
        });

        dispatch({
            type: FETCH_FILTER_BOOKINGS_SUCCESS,
            payload: bookings,
        });

        return bookings;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_FILTER_BOOKINGS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchCorporateBookings: AsyncActionCreator<
    {
        bookingCount: number;
    },
    Booking[]
> = ({ bookingCount }) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_BOOKINGS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data } = await axios.get(`${B2B_API_URL}/hr/bookings`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                bookingCount,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        dispatch({
            type: FETCH_BOOKINGS_SUCCESS,
            payload: data.bookings,
        });

        return data.bookings;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_BOOKINGS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchScrollCorporateBookings: AsyncActionCreator<
    {
        bookingCount: number;
    },
    Booking[]
> = ({ bookingCount }) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_SCROLL_BOOKINGS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data } = await axios.get(`${B2B_API_URL}/hr/bookings`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                bookingCount,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        dispatch({
            type: FETCH_SCROLL_BOOKINGS_SUCCESS,
            payload: data.bookings,
        });

        return data.bookings;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_SCROLL_BOOKINGS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchCorporateBookingsForReport: AsyncActionCreator<
    string | null | void,
    Booking[]
> = () => async (dispatch, getState) => {
    dispatch({
        type: FETCH_BOOKINGS_FOR_REPORT_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data } = await axios.get(`${REPORTS_API_URL}/hr/bookings`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: FETCH_BOOKINGS_FOR_REPORT_SUCCESS,
            payload: data.dataForXLSX,
        });

        return data.dataForXLSX;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_BOOKINGS_FOR_REPORT_ERROR,
            });

            throw err;
        }
    }
};

export const deleteBooking: AsyncActionCreator<string> = (id) => async (
    dispatch,
    getState
) => {
    dispatch({
        type: DELETE_BOOKING_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: booking } = await axios.delete(
            `${B2B_API_URL}/bookings/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: DELETE_BOOKING_SUCCESS,
            payload: booking,
        });

        return booking;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: DELETE_BOOKING_ERROR,
            });

            throw err;
        }
    }
};

export const startUserRemoteBooking: AsyncActionCreator<
    {
        userId: string;
        gymId: string;
        type: string;
        groupWorkoutReservationNewId?: string;
        timeStarted?: string;
        timeFinished?: string;
    },
    Booking
> = (bookingData) => async (dispatch, getState) => {
    dispatch({
        type: START_USER_REMOTE_BOOKING_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: booking } = await axios.post(
            `${B2B_API_URL}/bookings`,
            bookingData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: START_USER_REMOTE_BOOKING_SUCCESS,
            payload: booking,
        });

        return booking;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: START_USER_REMOTE_BOOKING_ERROR,
            });

            throw err;
        }
    }
};

export const runAutoPaidBookings: AsyncActionCreator<
    {
        selectedGymId: string;
    },
    { message: string[] }
> = ({ selectedGymId }) => async (dispatch, getState) => {
    dispatch({
        type: RUN_AUTO_PAID_BOOKINGS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data } = await axios.get(`${B2B_API_URL}/bookings/pay`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                selectedGymId,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        dispatch({
            type: RUN_AUTO_PAID_BOOKINGS_SUCCESS,
            payload: data,
        });

        return data;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: RUN_AUTO_PAID_BOOKINGS_ERROR,
            });

            throw err;
        }
    }
};

export const clearUnpaidBookings = () => (dispatch: AsyncDispatch): void => {
    dispatch({
        type: CLEAR_UNPAID_BOOKINGS_SUCCESS,
    });
};

export const clearTestBookings = () => (dispatch: AsyncDispatch): void => {
    dispatch({
        type: CLEAR_TEST_BOOKINGS_SUCCESS,
    });
};

export const fetchBookingsForReport: AsyncActionCreator<
    string | null | void,
    void
> = () => async (dispatch, getState) => {
    dispatch({
        type: FETCH_BOOKINGS_FOR_REPORT_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data } = await axios.get(`${REPORTS_API_URL}/bookings/list`, {
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `bookings-list-${moment().format('DD-MM-YYYY-HH-mm')}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_BOOKINGS_FOR_REPORT_ERROR,
            });

            throw err;
        }
    }
};
