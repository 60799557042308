import React from 'react';
import { InjectedFormProps, Field, reduxForm } from 'redux-form';

import TextInput from '@components/TextInput';
import TextAreaInput from '@components/TextAreaInput';
import Button from '@components/Button';
import SelectInput from '@components/SelectInput';
import {
    GroupWorkoutReservationNewType,
    ReservationNewTypeOptions,
} from '@t/reservation';
import { Config } from '@t/config';

import styles from './styles.module.css';

export interface ReservationFormData {
    reservationId: string;
    reservationTitle: string;
    dateStart: string;
    dateFinish: string;
    gymTitle: string;
    userName: string;
    reservationTime: string;
    status: string;
    comment: string;
}

export function filterOptionsByReservationType(
    reservationType: string,
    disabled: boolean
): {
    title: string;
    value: string;
}[] {
    if (disabled) return ReservationNewTypeOptions;
    switch (reservationType) {
        case GroupWorkoutReservationNewType.NEW:
            return ReservationNewTypeOptions.filter(
                (option) =>
                    option.value === GroupWorkoutReservationNewType.CONFIRMED ||
                    option.value ===
                        GroupWorkoutReservationNewType.CANCELLED_BY_MANAGER
            );
        case GroupWorkoutReservationNewType.CONFIRMED:
            return ReservationNewTypeOptions.filter(
                (option) =>
                    option.value ===
                    GroupWorkoutReservationNewType.CANCELLED_BY_MANAGER
            );
        default:
            return ReservationNewTypeOptions;
    }
}

const isDisabled = (status: string, disabled: boolean): boolean => {
    if (
        status === GroupWorkoutReservationNewType.CANCELLED_BY_MANAGER ||
        status === GroupWorkoutReservationNewType.CANCELLED_BY_USER ||
        status === GroupWorkoutReservationNewType.COMPLETED ||
        status === GroupWorkoutReservationNewType.SKIPPED ||
        disabled
    ) {
        return true;
    }
    return false;
};

export interface Props {
    disabled: boolean;
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    config: Config;
    reservationTip: string;
    status: string;
}

const ReservationForm: React.FC<
    Props & InjectedFormProps<ReservationFormData, Props>
> = ({ handleSubmit, disabled, setDisabled, reservationTip, status }) => {
    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Field
                disabled
                name="reservationId"
                label="ID брони"
                component={TextInput}
            />

            <Field
                disabled
                name="reservationTitle"
                label="Название тренировки"
                component={TextInput}
                className={styles.mb8}
            />

            <Field
                disabled
                name="gymTitle"
                label="Название студии"
                component={TextInput}
                className={styles.mb8}
            />

            <Field
                disabled
                name="gymPhone"
                label="Телефон студии"
                component={TextInput}
                className={styles.mb8}
            />

            <div className={styles.wrapper}>
                <Field
                    disabled
                    name="dateStart"
                    label="Начало"
                    component={TextInput}
                />

                <Field
                    disabled
                    name="dateFinish"
                    label="Конец"
                    component={TextInput}
                />
            </div>

            <Field
                disabled
                name="userName"
                label="Имя клиента"
                component={TextInput}
                className={styles.mb8}
            />

            <Field
                disabled
                name="userPhone"
                label="Телефон клиента"
                component={TextInput}
                className={styles.mb8}
            />

            <Field
                disabled
                name="reservationTime"
                label="Время бронирования"
                component={TextInput}
                className={styles.mb}
            />

            <Field
                disabled={isDisabled(status, disabled)}
                name="status"
                label="Статус"
                component={SelectInput}
                options={filterOptionsByReservationType(status, disabled)}
                emptyTitle={'Выберите статус...'}
            />
            <span className={styles.tip}>
                {!!reservationTip && reservationTip}
            </span>

            <Field
                disabled={disabled}
                name="comment"
                label="Комментарий"
                placeholder="Комментарий к бронированию"
                component={TextAreaInput}
                className={styles.mb8}
            />

            {disabled && (
                <Button
                    type="button"
                    onClick={() => setDisabled(false)}
                    className={styles.btn}
                >
                    Изменить
                </Button>
            )}

            {!disabled && (
                <Button type="submit" className={styles.btn}>
                    Сохранить изменения
                </Button>
            )}
        </form>
    );
};

export default reduxForm<ReservationFormData, Props>({})(ReservationForm);
