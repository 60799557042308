import React from 'react';
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from 'formik';
import SelectInput from '@components/SelectInput';
import Button from '@components/Button';
import Modal from '@components/Modal';
import styles from './styles.module.css';

type FormData = {
    tableTentVariantId: string;
};

interface TableTentModalFormProps {
    active: boolean;
    setActive: (prev: boolean) => void;
    initialValues: FormData;
    options: { title: string; value: string }[];
    onDownloadQR: (values: FormData) => void;
}

export const TableTentModalForm: React.FC<TableTentModalFormProps> = ({
    active,
    setActive,
    options,
    initialValues,
    onDownloadQR,
}) => {
    const formikTableTentModalForm = useFormik<FormData>({
        enableReinitialize: true,
        initialValues,
        onSubmit: (values, { resetForm, validateForm }) => {
            onDownloadQR(values);
        },
        validate: (values) => {
            const errors: Partial<{
                tableTentVariantId: string;
            }> = {};

            if (!values.tableTentVariantId) {
                errors.tableTentVariantId =
                    'Необходимо выбрать тип для скачивания';
            }

            return errors;
        },
    });

    const handleChangeFieldValue = (name: string) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = event.target;
        formikTableTentModalForm.setFieldValue(name, value);
    };

    return (
        <Modal active={active} setActive={setActive}>
            <div className={styles.modalContainer}>
                <FormikProvider value={formikTableTentModalForm}>
                    <Form autoComplete={'off'}>
                        <Field
                            name="tableTentVariantId"
                            label="Варианты тейбл-тента"
                            options={options}
                            input={{
                                placeholder: 'Выберите тейбл-тент...',
                                onChange: handleChangeFieldValue(
                                    'tableTentVariantId'
                                ),
                                value:
                                    formikTableTentModalForm.values
                                        .tableTentVariantId,
                            }}
                            component={SelectInput}
                        />
                        <ErrorMessage
                            className={styles.error}
                            name="tableTentVariantId"
                            component="div"
                        />

                        <div className={styles.modalActionControls}>
                            <Button
                                type="submit"
                                className={styles.modalActionBtn}
                                buttonType="primary"
                            >
                                Скачать QR код
                            </Button>
                        </div>
                    </Form>
                </FormikProvider>
            </div>
        </Modal>
    );
};
