import { AppUser } from '@t/app-user';
import { Subscription, UserSubscription } from '@t/subscriptions';

export const FETCH_SUBSCRIPTIONS_REQUEST = 'FETCH_SUBSCRIPTIONS_REQUEST';
export const FETCH_SUBSCRIPTIONS_SUCCESS = 'FETCH_SUBSCRIPTIONS_SUCCESS';
export const FETCH_SUBSCRIPTIONS_ERROR = 'FETCH_SUBSCRIPTIONS_ERROR';

export const FETCH_SUBSCRIPTION_REQUEST = 'FETCH_SUBSCRIPTION_REQUEST';
export const FETCH_SUBSCRIPTION_SUCCESS = 'FETCH_SUBSCRIPTION_SUCCESS';
export const FETCH_SUBSCRIPTION_ERROR = 'FETCH_SUBSCRIPTION_ERROR';

export const FETCH_USER_SUBSCRIPTIONS_REQUEST =
    'FETCH_USER_SUBSCRIPTIONS_REQUEST';
export const FETCH_USER_SUBSCRIPTIONS_SUCCESS =
    'FETCH_USER_SUBSCRIPTIONS_SUCCESS';
export const FETCH_USER_SUBSCRIPTIONS_ERROR = 'FETCH_USER_SUBSCRIPTIONS_ERROR';

export const FETCH_USER_SUBSCRIPTION_REQUEST =
    'FETCH_USER_SUBSCRIPTION_REQUEST';
export const FETCH_USER_SUBSCRIPTION_SUCCESS =
    'FETCH_USER_SUBSCRIPTION_SUCCESS';
export const FETCH_USER_SUBSCRIPTION_ERROR = 'FETCH_USER_SUBSCRIPTION_ERROR';

export const UPDATE_USER_SUBSCRIPTION_REQUEST =
    'UPDATE_USER_SUBSCRIPTION_REQUEST';
export const UPDATE_USER_SUBSCRIPTION_SUCCESS =
    'UPDATE_USER_SUBSCRIPTION_SUCCESS';
export const UPDATE_USER_SUBSCRIPTION_ERROR = 'UPDATE_USER_SUBSCRIPTION_ERROR';

export const FETCH_USER_SUBSCRIPTIONS_FOR_REPORT_REQUEST =
    'FETCH_USER_SUBSCRIPTIONS_FOR_REPORT_REQUEST';
export const FETCH_USER_SUBSCRIPTIONS_FOR_REPORT_SUCCESS =
    'FETCH_USER_SUBSCRIPTIONS_FOR_REPORT_SUCCESS';
export const FETCH_USER_SUBSCRIPTIONS_FOR_REPORT_ERROR =
    'FETCH_USER_SUBSCRIPTIONS_FOR_REPORT_ERROR';

export const SEARCH_USER_SUBSCRIPTIONS_REQUEST =
    'SEARCH_USER_SUBSCRIPTIONS_REQUEST';
export const SEARCH_USER_SUBSCRIPTIONS_SUCCESS =
    'SEARCH_USER_SUBSCRIPTIONS_SUCCESS';
export const SEARCH_USER_SUBSCRIPTIONS_ERROR =
    'SEARCH_USER_SUBSCRIPTIONS_ERROR';

export const CREATE_USER_SUBSCRIPTION_REQUEST =
    'CREATE_USER_SUBSCRIPTION_REQUEST';
export const CREATE_USER_SUBSCRIPTION_SUCCESS =
    'CREATE_USER_SUBSCRIPTION_SUCCESS';
export const CREATE_USER_SUBSCRIPTION_ERROR = 'CREATE_USER_SUBSCRIPTION_ERROR';

export const DELETE_USER_SUBSCRIPTION_REQUEST =
    'DELETE_USER_SUBSCRIPTION_REQUEST';
export const DELETE_USER_SUBSCRIPTION_SUCCESS =
    'DELETE_USER_SUBSCRIPTION_SUCCESS';
export const DELETE_USER_SUBSCRIPTION_ERROR = 'DELETE_USER_SUBSCRIPTION_ERROR';

/**
 * START: GOGYM_SUBSCRIPTION
 */

export const FETCH_GOGYM_SUBSCRIPTION_REQUEST =
    'FETCH_GOGYM_SUBSCRIPTION_REQUEST';
export const FETCH_GOGYM_SUBSCRIPTION_SUCCESS =
    'FETCH_GOGYM_SUBSCRIPTION_SUCCESS';
export const FETCH_GOGYM_SUBSCRIPTION_ERROR = 'FETCH_GOGYM_SUBSCRIPTION_ERROR';

export const CREATE_GOGYM_SUBSCRIPTION_REQUEST =
    'CREATE_GOGYM_SUBSCRIPTION_REQUEST';
export const CREATE_GOGYM_SUBSCRIPTION_SUCCESS =
    'CREATE_GOGYM_SUBSCRIPTION_SUCCESS';
export const CREATE_GOGYM_SUBSCRIPTION_ERROR =
    'CREATE_GOGYM_SUBSCRIPTION_ERROR';

export const UPDATE_GOGYM_SUBSCRIPTION_REQUEST =
    'UPDATE_GOGYM_SUBSCRIPTION_REQUEST';
export const UPDATE_GOGYM_SUBSCRIPTION_SUCCESS =
    'UPDATE_GOGYM_SUBSCRIPTION_SUCCESS';
export const UPDATE_GOGYM_SUBSCRIPTION_ERROR =
    'UPDATE_GOGYM_SUBSCRIPTION_ERROR';

/**
 * END: GOGYM_SUBSCRIPTION
 */

export interface SubscriptionState {
    ids: string[];
    byId: {
        [id: string]: Subscription;
    };
    lastUpdated: number;
}

export interface UserSubscriptionState {
    ids: string[];
    byId: {
        [id: string]: UserSubscription;
    };
    usersBySubscriptionId: {
        [id: string]: AppUser;
    };
    lastUpdated: number;
}
