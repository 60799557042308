export interface User {
    _id: string;
    gyms: string[];
    firstName?: string;
    lastName?: string;
    phone: string;
    email: string;
    verified: boolean;
    role: string;
    domainsId: string[];
    onboardingGymMeta?: {
        gymTitle: string;
        gymAddress: string;
        location?: {
            lat: number;
            lng: number;
        };
    };
    registrationCompleted?: boolean;
    createdAt: Date;
    secret: {
        otpauthUrl: string;
        qrVerified: boolean;
    };
    yclientsId: string;
    yclientsGyms: Array<{ yclientsGymTitle: string; yclientsId: string }>;
    gymsTitle: string[];
}

export interface CorporateDomains {
    _id: string;
    name: string;
    domain: string;
    offer: string;
}

export enum UserRole {
    SuperAdmin = 'SUPERADMIN',
    Admin = 'ADMIN',
    Manager = 'MANAGER',
    Finance = 'FINANCE',
    Analytics = 'ANALYTICS',
    HR = 'HR',
}

export enum Loyalty {
    Zero = 0,
    Three = 0.03,
    Five = 0.05,
    Ten = 0.1,
}

export const UserRoleOptions = [
    { title: 'Менеджер', value: UserRole.Manager },
    { title: 'Администратор', value: UserRole.Admin },
    { title: 'Супер администратор', value: UserRole.SuperAdmin },
    { title: 'Финансы', value: UserRole.Finance },
    { title: 'Аналитик', value: UserRole.Analytics },
    { title: 'HR', value: UserRole.HR },
];

export const LoyaltyOptions = [
    { title: '0%', value: Loyalty.Zero },
    { title: '3%', value: Loyalty.Three },
    { title: '5%', value: Loyalty.Five },
    { title: '10%', value: Loyalty.Ten },
];
