import React from 'react';
import { InjectedFormProps, Field, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';

import TextInput from '@components/TextInput';
import Button from '@components/Button';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import TextAreaInput from '@components/TextAreaInput';

import styles from './styles.module.css';

export interface FormData {
    name: string;
    address: string;
    actualAddress: string;
    inn: string;
    checkingAccount: string;
    correspondentAccount: string;
    bik: string;
    goGymFee: string;
    acquiringCommission: string;
}

export type Props = InjectedFormProps<FormData>;

const AddLegalEntityForm: React.FC<Props> = ({ handleSubmit }) => {
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Field
                required
                type="text"
                name="name"
                label="Название юридического лица"
                placeholder="Название юридического лица"
                component={TextInput}
            />

            <Field
                type="text"
                name="address"
                label="Юридический адрес"
                placeholder="Юридический адрес"
                component={TextAreaInput}
            />

            <Field
                type="text"
                name="actualAddress"
                label="Фактический адрес"
                placeholder="Фактический адрес"
                component={TextAreaInput}
            />

            <Field
                type="number"
                name="inn"
                label="ИНН"
                placeholder="ИНН"
                component={TextInput}
            />

            <Field
                type="number"
                name="checkingAccount"
                label="Расчетный счет"
                placeholder="Расчетный счет"
                component={TextInput}
            />

            <Field
                type="number"
                name="correspondentAccount"
                label="Корреспондентский счёт"
                placeholder="Корреспондентский счёт"
                component={TextInput}
            />

            <Field
                type="number"
                name="bik"
                label="БИК"
                placeholder="БИК"
                component={TextInput}
            />

            <Field
                type="number"
                name="goGymFee"
                label="Комиссия GOGYM"
                placeholder="Комиссия GOGYM"
                component={TextInput}
            />

            <Field
                type="number"
                name="acquiringCommission"
                label="Комиссия эквайринга"
                placeholder="Комиссия эквайринга"
                component={TextInput}
            />

            <Field
                type="text"
                name="comment"
                label="Комментарий"
                placeholder="Комментарий для юр лица"
                component={TextAreaInput}
            />

            {isSuperAdmin && (
                <Button type="submit" className={styles.btn}>
                    Добавить юр лицо
                </Button>
            )}
        </form>
    );
};

export default reduxForm<FormData>({
    form: 'addLegalEntity',
})(AddLegalEntityForm);
