import React, { useState, useRef } from 'react';
import {
    InjectedFormProps,
    Field,
    reduxForm,
    formValueSelector,
} from 'redux-form';
import { useSelector } from 'react-redux';

import TextInput from '@components/TextInput';
import Button from '@components/Button';
import CheckBoxInput from '@components/CheckBoxInput';
import TextAreaInput from '@components/TextAreaInput';
import SelectInput from '@components/SelectInput';
import { CouponType, CouponTypeOptions } from '@t/coupon';
import MultiSelectInput from '@components/MultiSelectInput';
import DateTimePickerInput from '@components/DateTimePickerInput';
import { ApplicationState } from '@redux/types';
import Modal from '@components/Modal';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';

import styles from './styles.module.css';

export interface CouponsFormData {
    couponName: string;
    couponType: CouponType;
    discount: number;
    activatedByUserId: string;
    activatedStart: Date;
    activatedEnd: Date;
    activatedCount: number;
    actionStart: string;
    actionEnd: string;
    adCampaignId: string;
    unlimited?: boolean;
    unlimitedPerUser: boolean;
    unlimitedUsersCount: boolean;
    limitPerUser: number;
    gymIds?: string[];
    userIds?: string;
    active: boolean;
    users?: Array<string>;
    initialPrice: number;
    bookingDuration?: number;
    comment: string;
}

export interface Props {
    onDeleteCoupon: () => void;
    gymsOptions: {
        title: string;
        value: string;
    }[];
    activatedByUserId: string;
    adCampaign: string;
    couponsAdCampaignsOptions: {
        title: string;
        value: string;
    }[];
    actionStart: Date;
    actionEnd: Date;
}

const validate = (values: CouponsFormData) => {
    const errors: {
        couponName: string;
        discount: string;
        initialPrice: string;
    } = { couponName: '', discount: '', initialPrice: '' };

    if (!values.couponName) {
        errors.couponName = 'Обязательное поле';
    }

    if (!values.discount) {
        errors.discount = 'Обязательное поле';
    }

    if (Number(values.discount) === 0) {
        errors.discount = 'Должно быть больше 0';
    }

    if (!values.initialPrice) {
        errors.initialPrice = 'Обязательное поле';
    }

    if (Number(values.initialPrice) === 0) {
        errors.initialPrice = 'Должно быть больше 0';
    }

    return errors;
};

const CouponsForm: React.FC<
    Props & InjectedFormProps<CouponsFormData, Props>
> = ({
    handleSubmit,
    onDeleteCoupon,
    gymsOptions,
    adCampaign,
    couponsAdCampaignsOptions,
    actionStart,
    actionEnd,
}) => {
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;
    const [foundGyms, setFoundGyms] = useState<any>();
    const [disabled, setDisabled] = useState(true);
    const gymsSearchingField = useRef<any>();
    const [modalActive, setModalActive] = useState(false);

    const selector = formValueSelector('couponInfo');
    const couponTypeSelector = (state: ApplicationState) =>
        selector(state, 'couponType');
    const couponType = useSelector(couponTypeSelector);
    const isPercentOff = couponType === CouponType.percentOff;
    const isAmount = couponType === CouponType.amount;

    const handleSearchGyms = () => {
        setFoundGyms(
            gymsOptions.filter((gymsOption) =>
                gymsOption.title
                    .toLowerCase()
                    .includes(gymsSearchingField.current.value.toLowerCase())
            )
        );
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Field
                disabled
                name="couponId"
                label="ID промокода"
                component={TextInput}
            />

            <Field
                disabled
                name="createdAt"
                label="Дата создания"
                component={TextInput}
            />

            {adCampaign && (
                <Field
                    disabled={disabled}
                    name="adCampaignId"
                    label="Рекламная кампания"
                    placeholder="Рекламная кампания"
                    component={SelectInput}
                    options={couponsAdCampaignsOptions}
                />
            )}

            <Field
                disabled={disabled}
                name="couponName"
                label="Название"
                placeholder="Название"
                component={TextInput}
            />

            <Field
                disabled={disabled}
                name="couponType"
                label="Тип"
                placeholder="Тип"
                emptyTitle={'Выберите тип промокода...'}
                component={SelectInput}
                options={CouponTypeOptions}
            />

            {isPercentOff && (
                <Field
                    disabled={disabled}
                    name="discount"
                    label="Скидка"
                    placeholder="Скидка"
                    component={TextInput}
                />
            )}

            <Field
                disabled={disabled}
                name="limitPerUser"
                label="Количество тренировок"
                placeholder="Количество тренировок"
                component={TextInput}
            />

            {isAmount && (
                <Field
                    disabled={disabled}
                    name="initialPrice"
                    label="Сумма промокода"
                    placeholder="Сумма промокода"
                    component={TextInput}
                />
            )}

            {isPercentOff && (
                <Field
                    disabled={disabled}
                    name="activatedCount"
                    label="Сколько человек может использовать"
                    placeholder="Сколько человек может использовать"
                    component={TextInput}
                />
            )}

            {isPercentOff && (
                <Field
                    disabled
                    name="activatedUsers"
                    label="Сколько человек использовало"
                    placeholder="Сколько человек использовало"
                    component={TextInput}
                />
            )}

            {actionStart && (
                <Field
                    disabled={disabled}
                    name="actionStart"
                    label="Дата начала действия"
                    placeholder="Дата начала действия"
                    component={DateTimePickerInput}
                />
            )}

            {actionEnd && (
                <Field
                    disabled={disabled}
                    name="actionEnd"
                    label="Дата конца действия"
                    placeholder="Дата конца действия"
                    component={DateTimePickerInput}
                />
            )}

            {!disabled && (
                <input
                    className={styles.input}
                    type="search"
                    placeholder="Поиск по названию фитнес-площадки"
                    ref={gymsSearchingField}
                    onChange={handleSearchGyms}
                />
            )}

            {foundGyms ? (
                <Field
                    disabled={disabled}
                    name="gymIds"
                    label="Фитнес-площадки"
                    component={MultiSelectInput}
                    options={foundGyms}
                    defaultValue={''}
                />
            ) : (
                <Field
                    disabled={disabled}
                    name="gymIds"
                    label="Фитнес-площадки"
                    component={MultiSelectInput}
                    options={gymsOptions}
                    defaultValue={''}
                />
            )}

            <Field
                disabled={disabled}
                name="userIds"
                label="Пользователи промокода"
                placeholder="ID пользователя через ;"
                component={TextInput}
            />

            <Field
                disabled={disabled}
                name="comment"
                label="Комментарий"
                placeholder="Комментарий для промокода"
                component={TextAreaInput}
            />

            <Field
                disabled={disabled}
                name="unlimited"
                label="Неограниченный"
                component={CheckBoxInput}
            />

            <Field
                disabled={disabled}
                name="active"
                label="Активный"
                component={CheckBoxInput}
            />

            {!disabled && (
                <Button type="submit" className={styles.btn}>
                    Обновить
                </Button>
            )}

            {disabled && (
                <Button
                    type="button"
                    onClick={() => setDisabled(false)}
                    className={styles.btn}
                >
                    Изменить
                </Button>
            )}

            <Modal active={modalActive} setActive={setModalActive}>
                <h3>Вы действительно хотите удалить промокод?</h3>
                <Button
                    type="button"
                    className={styles.btn}
                    onClick={onDeleteCoupon}
                >
                    Удалить
                </Button>
            </Modal>

            {isSuperAdmin && (
                <Button
                    onClick={() => setModalActive(true)}
                    type="button"
                    className={styles.btn}
                >
                    Удалить
                </Button>
            )}
        </form>
    );
};

export default reduxForm<CouponsFormData, Props>({
    form: 'couponInfo',
    validate,
})(CouponsForm);
