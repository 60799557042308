import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import styles from './styles.module.css';

export interface Props {
    hidden?: boolean;
    label: string;
    name: string;
    options: { title: string; value: string }[];
    defaultVal?: number | string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    emptyTitle?: string;
    toggleVisibility?: boolean;
}

const SelectInput: React.FC<Props & WrappedFieldProps> = ({
    input,
    meta,
    name,
    options,
    label,
    defaultVal,
    required,
    disabled,
    emptyTitle,
    hidden,
}) => {
    const optionsList =
        options.length > 0 &&
        options.map((item, i) => {
            return (
                <option key={i} value={item.value}>
                    {item.title}
                </option>
            );
        });
    return (
        <div className={styles.wrapper} hidden={hidden}>
            <label htmlFor={name} className={styles.label}>
                {label}
            </label>
            <select
                className={styles.select}
                onChange={input.onChange}
                value={input.value ? input.value : defaultVal}
                name={name}
                defaultValue={defaultVal}
                required={required}
                disabled={disabled}
            >
                {emptyTitle && <option value="">{emptyTitle}</option>}
                {optionsList}
            </select>
            {meta && meta.touched && meta.error && (
                <div className={styles.error}>{meta.error}</div>
            )}
        </div>
    );
};

export default SelectInput;
