import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cogoToast from 'cogo-toast';
import XLSX from 'xlsx-js-style';
import fileSaver from 'file-saver';
import moment from 'moment';
import { Field, formValueSelector } from 'redux-form';

import {
    fetchAccountingReportMonth,
    fetchAccountingReportYear,
    fetchAllBookings,
    fetchAllCorporate,
    fetchAllGyms,
    fetchAllLegalEntities,
    fetchAllMemberships,
    fetchAllOnlineWorkouts,
    fetchAllUserSubscriptions,
    fetchAllUsers,
    fetchGymafon,
    fetchMonthBookings,
    fetchMonthMemberships,
    fetchYearBookings,
} from '@redux/modules/reports/actions';
import BoxButton from '@components/BoxButton';
import { ApplicationState, AsyncDispatch } from '@redux/types';
import { REPORT_HEADER_CELLS } from '@config';
import ModalAccounting from '@components/Modal';
import ModalBookings from '@components/Modal';
import ModalMemberships from '@components/Modal';
import Form from '@components/Form';
import Button from '@components/Button';
import SelectInput from '@components/SelectInput';
import { MonthsOptions, ReportTypeOptions, YearsOptions } from '@t/report';

import styles from './styles.module.css';

export const Reporting: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const [loadingAllBookings, setLoadingAllBookings] = useState<boolean>(
        false
    );
    const [loadingAllUsers, setLoadingAllUsers] = useState<boolean>(false);
    const [loadingAllGyms, setLoadingAllGyms] = useState<boolean>(false);
    const [
        loadingAllLegalEntities,
        setLoadingAllLegalEntities,
    ] = useState<boolean>(false);
    const [loadingAllCorporate, setLoadingAllCorporate] = useState<boolean>(
        false
    );
    const [
        loadingAllOnlineWorkouts,
        setLoadingAllOnlineWorkouts,
    ] = useState<boolean>(false);
    const [
        loadingAccountingReport,
        setLoadingAccountingReport,
    ] = useState<boolean>(false);
    const [loadingAllMemberships, setLoadingAllMemberships] = useState<boolean>(
        false
    );
    const [
        loadingAllUserSubscriptions,
        setLoadingAllUserSubscriptions,
    ] = useState<boolean>(false);
    const [modalActiveAccounting, setModalActiveAccounting] = useState<boolean>(
        false
    );
    const [modalActiveBookings, setModalActiveBookings] = useState<boolean>(
        false
    );
    const [
        modalActiveMemberships,
        setModalActiveMemberships,
    ] = useState<boolean>(false);
    const [loadingGymafon, setLoadingGymafon] = useState<boolean>(false);

    const selectorAccounting = formValueSelector('accountingReport');
    const typeAccountingSelector = (state: ApplicationState) =>
        selectorAccounting(state, 'type');
    const monthAccountingSelector = (state: ApplicationState) =>
        selectorAccounting(state, 'month');
    const yearAccountingSelector = (state: ApplicationState) =>
        selectorAccounting(state, 'year');
    const accountingReportType = useSelector(typeAccountingSelector);
    const accountingReportMonth = useSelector(monthAccountingSelector);
    const accountingReportYear = useSelector(yearAccountingSelector);

    const selectorBookings = formValueSelector('bookingsReport');
    const monthBookingsSelector = (state: ApplicationState) =>
        selectorBookings(state, 'month');
    const yearBookingsSelector = (state: ApplicationState) =>
        selectorBookings(state, 'year');
    const bookingsReportMonth = useSelector(monthBookingsSelector);
    const bookingsReportYear = useSelector(yearBookingsSelector);

    const selectorMemberships = formValueSelector('membershipsReport');
    const monthMembershipsSelector = (state: ApplicationState) =>
        selectorMemberships(state, 'month');
    const yearMembershipsSelector = (state: ApplicationState) =>
        selectorMemberships(state, 'year');
    const membershipsReportMonth = useSelector(monthMembershipsSelector);
    const membershipsReportYear = useSelector(yearMembershipsSelector);

    const onFetchAllBookings = useCallback(() => {
        const { hide } = cogoToast.loading(
            'Формируем отчет по тренировкам...',
            {
                position: 'top-right',
                hideAfter: 0,
            }
        );
        setLoadingAllBookings(true);
        setModalActiveBookings(false);
        dispatch(fetchAllBookings())
            .then(() => {
                cogoToast.success('Отчет по тренировкам готов', {
                    position: 'top-right',
                    hideAfter: 5,
                });
            })
            .catch(() => {
                cogoToast.error(
                    'Ошибка при формировании отчета по тренировкам',
                    {
                        position: 'top-right',
                        hideAfter: 4,
                    }
                );
            })
            .finally(() => {
                setLoadingAllBookings(false);
                hide && hide();
            });
    }, [dispatch]);

    const onFetchMonthBookings = useCallback(
        (values) => {
            const { hide } = cogoToast.loading(
                'Формируем отчет по тренировкам...',
                {
                    position: 'top-right',
                    hideAfter: 0,
                }
            );
            setLoadingAllBookings(true);
            setModalActiveBookings(false);
            dispatch(fetchMonthBookings(values))
                .then((report) => {
                    if (!report.length) {
                        hide && hide();
                        setLoadingAllBookings(false);
                        return cogoToast.warn('Нет данных для отчета', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                    }

                    const ws = XLSX.utils.json_to_sheet(report);
                    for (const i of REPORT_HEADER_CELLS) {
                        if (ws[i]) {
                            ws[i].s = {
                                font: {
                                    bold: true,
                                },
                            };
                        }
                    }
                    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
                    const excelBuffer = XLSX.write(wb, {
                        bookType: 'xlsx',
                        type: 'array',
                    });
                    const data = new Blob([excelBuffer], {
                        type:
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
                    });
                    fileSaver.saveAs(
                        data,
                        `all-bookings-${values.month}-${
                            values.year
                        }-${moment().format('DD-MM-YYYY-HH-mm')}.xlsx`
                    );
                    cogoToast.success('Отчет по тренировкам готов', {
                        position: 'top-right',
                        hideAfter: 5,
                    });
                })
                .catch(() => {
                    cogoToast.error(
                        'Ошибка при формировании отчета по тренировкам',
                        {
                            position: 'top-right',
                            hideAfter: 4,
                        }
                    );
                })
                .finally(() => {
                    setLoadingAllBookings(false);
                    hide && hide();
                });
        },
        [dispatch]
    );

    const onFetchYearBookings = useCallback(
        (values) => {
            const { hide } = cogoToast.loading(
                'Формируем отчет по тренировкам...',
                {
                    position: 'top-right',
                    hideAfter: 0,
                }
            );
            setLoadingAllBookings(true);
            setModalActiveBookings(false);
            dispatch(fetchYearBookings(values))
                .then(() => {
                    cogoToast.success('Отчет по тренировкам готов', {
                        position: 'top-right',
                        hideAfter: 5,
                    });
                })
                .catch(() => {
                    cogoToast.error(
                        'Ошибка при формировании отчета по тренировкам',
                        {
                            position: 'top-right',
                            hideAfter: 4,
                        }
                    );
                })
                .finally(() => {
                    setLoadingAllBookings(false);
                    hide && hide();
                });
        },
        [dispatch]
    );

    const onFetchAllUsers = useCallback(() => {
        const { hide } = cogoToast.loading(
            'Формируем отчет по пользователям...',
            {
                position: 'top-right',
                hideAfter: 0,
            }
        );
        setLoadingAllUsers(true);
        dispatch(fetchAllUsers())
            .then(() => {
                cogoToast.success('Отчет по пользователям готов', {
                    position: 'top-right',
                    hideAfter: 5,
                });
            })
            .catch(() => {
                cogoToast.error(
                    'Ошибка при формировании отчета по пользователям',
                    {
                        position: 'top-right',
                        hideAfter: 4,
                    }
                );
            })
            .finally(() => {
                setLoadingAllUsers(false);
                hide && hide();
            });
    }, [dispatch]);

    const onFetchAllGyms = useCallback(() => {
        const { hide } = cogoToast.loading(
            'Формируем отчет по фитнес-площадкам...',
            {
                position: 'top-right',
                hideAfter: 0,
            }
        );
        setLoadingAllGyms(true);
        dispatch(fetchAllGyms())
            .then((report) => {
                const ws = XLSX.utils.json_to_sheet(report);
                for (const i of REPORT_HEADER_CELLS) {
                    if (ws[i]) {
                        ws[i].s = {
                            font: {
                                bold: true,
                            },
                        };
                    }
                }
                const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, {
                    bookType: 'xlsx',
                    type: 'array',
                });
                const data = new Blob([excelBuffer], {
                    type:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
                });
                fileSaver.saveAs(
                    data,
                    `all-gyms-${moment().format('DD-MM-YYYY-HH-mm')}.xlsx`
                );
                cogoToast.success('Отчет по фитнес-площадкам готов', {
                    position: 'top-right',
                    hideAfter: 5,
                });
            })
            .catch(() => {
                cogoToast.error(
                    'Ошибка при формировании отчета по фитнес-площадкам',
                    {
                        position: 'top-right',
                        hideAfter: 4,
                    }
                );
            })
            .finally(() => {
                setLoadingAllGyms(false);
                hide && hide();
            });
    }, [dispatch]);

    const onFetchAllLegalEntities = useCallback(() => {
        const { hide } = cogoToast.loading('Формируем отчет по юр лицам...', {
            position: 'top-right',
            hideAfter: 0,
        });
        setLoadingAllLegalEntities(true);
        dispatch(fetchAllLegalEntities())
            .then((report) => {
                const ws = XLSX.utils.json_to_sheet(report);
                for (const i of REPORT_HEADER_CELLS) {
                    if (ws[i]) {
                        ws[i].s = {
                            font: {
                                bold: true,
                            },
                        };
                    }
                }
                const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, {
                    bookType: 'xlsx',
                    type: 'array',
                });
                const data = new Blob([excelBuffer], {
                    type:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
                });
                fileSaver.saveAs(
                    data,
                    `all-legal-entities-${moment().format(
                        'DD-MM-YYYY-HH-mm'
                    )}.xlsx`
                );
                cogoToast.success('Отчет по юр лицам готов', {
                    position: 'top-right',
                    hideAfter: 5,
                });
            })
            .catch(() => {
                cogoToast.error('Ошибка при формировании отчета по юр лицам', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            })
            .finally(() => {
                setLoadingAllLegalEntities(false);
                hide && hide();
            });
    }, [dispatch]);

    const onFetchAllCorporate = useCallback(() => {
        const { hide } = cogoToast.loading(
            'Формируем отчет по корпоротивным тренировкам...',
            {
                position: 'top-right',
                hideAfter: 0,
            }
        );
        setLoadingAllCorporate(true);
        dispatch(fetchAllCorporate())
            .then((report) => {
                const ws = XLSX.utils.json_to_sheet(report);
                for (const i of REPORT_HEADER_CELLS) {
                    if (ws[i]) {
                        ws[i].s = {
                            font: {
                                bold: true,
                            },
                        };
                    }
                }
                const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, {
                    bookType: 'xlsx',
                    type: 'array',
                });
                const data = new Blob([excelBuffer], {
                    type:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
                });
                fileSaver.saveAs(
                    data,
                    `all-corporate-${moment().format('DD-MM-YYYY-HH-mm')}.xlsx`
                );
                cogoToast.success('Отчет по корпоротивным тренировкам готов', {
                    position: 'top-right',
                    hideAfter: 5,
                });
            })
            .catch(() => {
                cogoToast.error(
                    'Ошибка при формировании отчета по корпоротивным тренировкам',
                    {
                        position: 'top-right',
                        hideAfter: 4,
                    }
                );
            })
            .finally(() => {
                setLoadingAllCorporate(false);
                hide && hide();
            });
    }, [dispatch]);

    const onFetchAllOnlineWorkouts = useCallback(() => {
        const { hide } = cogoToast.loading(
            'Формируем отчет по онлайн тренировкам...',
            {
                position: 'top-right',
                hideAfter: 0,
            }
        );
        setLoadingAllOnlineWorkouts(true);
        dispatch(fetchAllOnlineWorkouts())
            .then((report) => {
                const ws = XLSX.utils.json_to_sheet(report);
                for (const i of REPORT_HEADER_CELLS) {
                    if (ws[i]) {
                        ws[i].s = {
                            font: {
                                bold: true,
                            },
                        };
                    }
                }
                const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, {
                    bookType: 'xlsx',
                    type: 'array',
                });
                const data = new Blob([excelBuffer], {
                    type:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
                });
                fileSaver.saveAs(
                    data,
                    `all-completed-online-workouts-${moment().format(
                        'DD-MM-YYYY-HH-mm'
                    )}.xlsx`
                );
                cogoToast.success('Отчет по онлайн тренировкам готов', {
                    position: 'top-right',
                    hideAfter: 5,
                });
            })
            .catch(() => {
                cogoToast.error(
                    'Ошибка при формировании отчета по онлайн тренировкам',
                    {
                        position: 'top-right',
                        hideAfter: 4,
                    }
                );
            })
            .finally(() => {
                setLoadingAllOnlineWorkouts(false);
                hide && hide();
            });
    }, [dispatch]);

    const onFetchAccountingReportMonth = useCallback(
        (values) => {
            const { hide } = cogoToast.loading(
                'Формируем отчет для бухгалтерии...',
                {
                    position: 'top-right',
                    hideAfter: 0,
                }
            );
            setLoadingAccountingReport(true);
            setModalActiveAccounting(false);
            dispatch(fetchAccountingReportMonth(values))
                .then((report) => {
                    if (!report.length) {
                        hide && hide();
                        setLoadingAccountingReport(false);
                        return cogoToast.warn('Нет данных для отчета', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                    }

                    const ws = XLSX.utils.json_to_sheet(report);
                    for (const i of REPORT_HEADER_CELLS) {
                        if (ws[i]) {
                            ws[i].s = {
                                font: {
                                    bold: true,
                                },
                            };
                        }
                    }
                    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
                    const excelBuffer = XLSX.write(wb, {
                        bookType: 'xlsx',
                        type: 'array',
                    });
                    const data = new Blob([excelBuffer], {
                        type:
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
                    });
                    fileSaver.saveAs(
                        data,
                        `accounting-report-month-${moment().format(
                            'DD-MM-YYYY-HH-mm'
                        )}.xlsx`
                    );
                    cogoToast.success('Отчет для бухгалтерии готов', {
                        position: 'top-right',
                        hideAfter: 5,
                    });
                })
                .catch(() => {
                    cogoToast.error(
                        'Ошибка при формировании отчета для бухгалтерии',
                        {
                            position: 'top-right',
                            hideAfter: 4,
                        }
                    );
                })
                .finally(() => {
                    setLoadingAccountingReport(false);
                    hide && hide();
                });
        },
        [dispatch]
    );

    const onFetchAccountingReportYear = useCallback(
        (year: string, type: string) => {
            const { hide } = cogoToast.loading(
                'Формируем отчет для бухгалтерии...',
                {
                    position: 'top-right',
                    hideAfter: 0,
                }
            );
            setLoadingAccountingReport(true);
            setModalActiveAccounting(false);
            dispatch(fetchAccountingReportYear({ year, type }))
                .then(() => {
                    cogoToast.success('Отчет для бухгалтерии готов', {
                        position: 'top-right',
                        hideAfter: 5,
                    });
                })
                .catch(() => {
                    cogoToast.error(
                        'Ошибка при формировании отчета для бухгалтерии',
                        {
                            position: 'top-right',
                            hideAfter: 4,
                        }
                    );
                })
                .finally(() => {
                    setLoadingAccountingReport(false);
                    hide && hide();
                });
        },
        [dispatch]
    );

    const onFetchAllMemberships = useCallback(() => {
        const { hide } = cogoToast.loading(
            'Формируем отчет по абонементам...',
            {
                position: 'top-right',
                hideAfter: 0,
            }
        );
        setLoadingAllMemberships(true);
        setModalActiveMemberships(false);
        dispatch(fetchAllMemberships())
            .then((report) => {
                if (!report.length) {
                    hide && hide();
                    setLoadingAllMemberships(false);
                    cogoToast.warn('Нет данных для отчета', {
                        position: 'top-right',
                        hideAfter: 5,
                    });
                    return;
                }

                const ws = XLSX.utils.json_to_sheet(report);
                for (const i of REPORT_HEADER_CELLS) {
                    if (ws[i]) {
                        ws[i].s = {
                            font: {
                                bold: true,
                            },
                        };
                    }
                }
                const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, {
                    bookType: 'xlsx',
                    type: 'array',
                });
                const data = new Blob([excelBuffer], {
                    type:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
                });
                fileSaver.saveAs(
                    data,
                    `all-memberships-${moment().format(
                        'DD-MM-YYYY-HH-mm'
                    )}.xlsx`
                );
                cogoToast.success('Отчет по абонементам готов', {
                    position: 'top-right',
                    hideAfter: 5,
                });
            })
            .catch(() => {
                cogoToast.error(
                    'Ошибка при формировании отчета по абонементам',
                    {
                        position: 'top-right',
                        hideAfter: 4,
                    }
                );
            })
            .finally(() => {
                setLoadingAllMemberships(false);
                hide && hide();
            });
    }, [dispatch]);

    const onFetchMonthMemberships = useCallback(
        (values) => {
            const { hide } = cogoToast.loading(
                'Формируем отчет по абонементам...',
                {
                    position: 'top-right',
                    hideAfter: 0,
                }
            );
            setLoadingAllMemberships(true);
            setModalActiveMemberships(false);
            dispatch(fetchMonthMemberships(values))
                .then((report) => {
                    if (!report.length) {
                        hide && hide();
                        setLoadingAllMemberships(false);
                        return cogoToast.warn('Нет данных для отчета', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                    }

                    const ws = XLSX.utils.json_to_sheet(report);
                    for (const i of REPORT_HEADER_CELLS) {
                        if (ws[i]) {
                            ws[i].s = {
                                font: {
                                    bold: true,
                                },
                            };
                        }
                    }
                    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
                    const excelBuffer = XLSX.write(wb, {
                        bookType: 'xlsx',
                        type: 'array',
                    });
                    const data = new Blob([excelBuffer], {
                        type:
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
                    });
                    fileSaver.saveAs(
                        data,
                        `all-memberships-month-${moment().format(
                            'DD-MM-YYYY-HH-mm'
                        )}.xlsx`
                    );
                    cogoToast.success('Отчет по абонементам готов', {
                        position: 'top-right',
                        hideAfter: 5,
                    });
                })
                .catch(() => {
                    cogoToast.error(
                        'Ошибка при формировании отчета по абонементам',
                        {
                            position: 'top-right',
                            hideAfter: 4,
                        }
                    );
                })
                .finally(() => {
                    setLoadingAllMemberships(false);
                    hide && hide();
                });
        },
        [dispatch]
    );

    const onFetchAllUserSubscriptions = useCallback(() => {
        const { hide } = cogoToast.loading('Формируем отчет по подпискам...', {
            position: 'top-right',
            hideAfter: 0,
        });
        setLoadingAllUserSubscriptions(true);
        dispatch(fetchAllUserSubscriptions())
            .then((report) => {
                if (!report.length) {
                    hide && hide();
                    setLoadingAllUserSubscriptions(false);
                    return cogoToast.warn('Нет данных для отчета', {
                        position: 'top-right',
                        hideAfter: 5,
                    });
                }
                const ws = XLSX.utils.json_to_sheet(report);
                for (const i in ws) {
                    if (Object.prototype.hasOwnProperty.call(ws, i)) {
                        if (i === `${i[0]}1` || REPORT_HEADER_CELLS.includes(i))
                            ws[i].s = {
                                font: {
                                    bold: true,
                                },
                            };
                    }
                }
                const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, {
                    bookType: 'xlsx',
                    type: 'array',
                });
                const data = new Blob([excelBuffer], {
                    type:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
                });
                fileSaver.saveAs(
                    data,
                    `all-subscriptions-${moment().format(
                        'DD-MM-YYYY-HH-mm'
                    )}.xlsx`
                );
                cogoToast.success('Отчет по подпискам готов', {
                    position: 'top-right',
                    hideAfter: 5,
                });
            })
            .catch(() => {
                cogoToast.error('Ошибка при формировании отчета по подпискам', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            })
            .finally(() => {
                setLoadingAllUserSubscriptions(false);
                hide && hide();
            });
    }, [dispatch]);

    const onFetchGymafon = useCallback(() => {
        const { hide } = cogoToast.loading(
            'Формируем отчет по пользователям...',
            {
                position: 'top-right',
                hideAfter: 0,
            }
        );
        setLoadingGymafon(true);
        dispatch(fetchGymafon())
            .then(() => {
                cogoToast.success('Отчет по гумафону готов', {
                    position: 'top-right',
                    hideAfter: 5,
                });
            })
            .catch(() => {
                cogoToast.error('Ошибка при формировании отчета по гумафону', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            })
            .finally(() => {
                setLoadingGymafon(false);
                hide && hide();
            });
    }, [dispatch]);

    return (
        <div>
            <h1>Отчетность</h1>

            <div className={styles.actions}>
                <BoxButton
                    icon="spreadsheet"
                    title="Тренировки"
                    onClick={() => setModalActiveBookings(true)}
                    className={styles.actionBtn}
                    disabled={loadingAllBookings}
                />
                <BoxButton
                    icon="spreadsheet"
                    title="Абонементы"
                    onClick={() => setModalActiveMemberships(true)}
                    className={styles.actionBtn}
                    disabled={loadingAllMemberships}
                />
                <BoxButton
                    icon="spreadsheet"
                    title="Подписки"
                    onClick={onFetchAllUserSubscriptions}
                    className={styles.actionBtn}
                    disabled={loadingAllUserSubscriptions}
                />
                <BoxButton
                    icon="spreadsheet"
                    title="Пользователи"
                    onClick={onFetchAllUsers}
                    className={styles.actionBtn}
                    disabled={loadingAllUsers}
                />
                <BoxButton
                    icon="spreadsheet"
                    title="Фитнес-площадки"
                    onClick={onFetchAllGyms}
                    className={styles.actionBtn}
                    disabled={loadingAllGyms}
                />
                <BoxButton
                    icon="spreadsheet"
                    title="Юр лица"
                    onClick={onFetchAllLegalEntities}
                    className={styles.actionBtn}
                    disabled={loadingAllLegalEntities}
                />
                <BoxButton
                    icon="spreadsheet"
                    title="Корпоративные"
                    onClick={onFetchAllCorporate}
                    className={styles.actionBtn}
                    disabled={loadingAllCorporate}
                />
                <BoxButton
                    icon="spreadsheet"
                    title="Онлайн тренировки"
                    onClick={onFetchAllOnlineWorkouts}
                    className={styles.actionBtn}
                    disabled={loadingAllOnlineWorkouts}
                />
                <BoxButton
                    icon="spreadsheet"
                    title="Для бухгалтерии"
                    onClick={() => setModalActiveAccounting(true)}
                    className={styles.actionBtn}
                    disabled={loadingAccountingReport}
                />
                <BoxButton
                    icon="spreadsheet"
                    title="Гумафон"
                    onClick={onFetchGymafon}
                    className={styles.actionBtn}
                    disabled={loadingGymafon}
                />
            </div>

            <ModalAccounting
                active={modalActiveAccounting}
                setActive={setModalActiveAccounting}
            >
                <Form
                    form="accountingReport"
                    onSubmit={onFetchAccountingReportMonth}
                    className={styles.accountingReportForm}
                >
                    <Field
                        required
                        type="text"
                        name="type"
                        label="Тип"
                        placeholder="Тип"
                        component={SelectInput}
                        options={ReportTypeOptions}
                        emptyTitle={'Выберите тип отчета...'}
                    />

                    <Field
                        required
                        type="text"
                        name="year"
                        label="Год"
                        placeholder="Год"
                        component={SelectInput}
                        options={YearsOptions}
                        emptyTitle={'Выберите год...'}
                    />

                    <Field
                        required
                        type="text"
                        name="month"
                        label="Месяц"
                        placeholder="Месяц"
                        component={SelectInput}
                        options={MonthsOptions}
                        emptyTitle={'Выберите месяц...'}
                    />

                    {accountingReportType &&
                        accountingReportYear &&
                        !accountingReportMonth && (
                            <Button
                                type="button"
                                className={styles.btn}
                                onClick={() =>
                                    onFetchAccountingReportYear(
                                        accountingReportYear,
                                        accountingReportType
                                    )
                                }
                            >
                                Получить отчет за год
                            </Button>
                        )}

                    {accountingReportType &&
                        accountingReportMonth &&
                        accountingReportYear && (
                            <Button type="submit" className={styles.btn}>
                                Получить отчет за месяц
                            </Button>
                        )}
                </Form>
            </ModalAccounting>

            <ModalBookings
                active={modalActiveBookings}
                setActive={setModalActiveBookings}
            >
                <Form
                    form="bookingsReport"
                    onSubmit={onFetchMonthBookings}
                    className={styles.accountingReportForm}
                >
                    <Field
                        required
                        type="text"
                        name="year"
                        label="Год"
                        placeholder="Год"
                        component={SelectInput}
                        options={YearsOptions}
                        emptyTitle={'Выберите год...'}
                    />

                    <Field
                        required
                        type="text"
                        name="month"
                        label="Месяц"
                        placeholder="Месяц"
                        component={SelectInput}
                        options={MonthsOptions}
                        emptyTitle={'Выберите месяц...'}
                    />

                    {bookingsReportYear && !bookingsReportMonth && (
                        <Button
                            type="button"
                            className={styles.btn}
                            onClick={() =>
                                onFetchYearBookings(bookingsReportYear)
                            }
                        >
                            Получить отчет за год
                        </Button>
                    )}

                    {bookingsReportMonth && bookingsReportYear && (
                        <Button type="submit" className={styles.btn}>
                            Получить отчет за месяц
                        </Button>
                    )}

                    {!bookingsReportMonth && !bookingsReportYear && (
                        <Button
                            type="button"
                            className={styles.btn}
                            onClick={() => {
                                onFetchAllBookings();
                            }}
                        >
                            Получить полный отчет
                        </Button>
                    )}
                </Form>
            </ModalBookings>

            <ModalMemberships
                active={modalActiveMemberships}
                setActive={setModalActiveMemberships}
            >
                <Form
                    form="membershipsReport"
                    onSubmit={onFetchMonthMemberships}
                    className={styles.accountingReportForm}
                >
                    <Field
                        required
                        type="text"
                        name="year"
                        label="Год"
                        placeholder="Год"
                        component={SelectInput}
                        options={YearsOptions}
                        emptyTitle={'Выберите год...'}
                    />

                    <Field
                        required
                        type="text"
                        name="month"
                        label="Месяц"
                        placeholder="Месяц"
                        component={SelectInput}
                        options={MonthsOptions}
                        emptyTitle={'Выберите месяц...'}
                    />

                    {membershipsReportMonth && membershipsReportYear && (
                        <Button type="submit" className={styles.btn}>
                            Получить отчет за месяц
                        </Button>
                    )}

                    {!membershipsReportYear && !membershipsReportMonth && (
                        <Button
                            type="button"
                            className={styles.btn}
                            onClick={() => {
                                onFetchAllMemberships();
                            }}
                        >
                            Получить полный отчет
                        </Button>
                    )}
                </Form>
            </ModalMemberships>
        </div>
    );
};

export default Reporting;
