import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getHRStatisticsLastUpdated } from '@redux/modules/dashboard/selectors';
import { fetchHRStatistics } from '@redux/modules/dashboard/actions';

import styles from './styles.module.css';

const getTimeFromNow = (timestamp: number) => {
    if (!timestamp) {
        return 'Не обновлялось';
    }

    return moment(timestamp).fromNow();
};

export interface Props {
    title: string;
    count?: number;
    name?: string;
    price?: number;
}

export const HRStatisticsCounter: React.FC<Props> = ({
    title,
    count,
    name,
    price,
}) => {
    const [lastUpdated, setLastUpdated] = useState('');
    const dispatch = useDispatch();

    const HRLastUpdated = useSelector(getHRStatisticsLastUpdated);

    const onRefresh = () => dispatch(fetchHRStatistics());

    useEffect(() => {
        setLastUpdated(getTimeFromNow(HRLastUpdated));

        const interval = setInterval(() => {
            setLastUpdated(getTimeFromNow(HRLastUpdated));
        }, 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, [HRLastUpdated]);

    return (
        <div className={styles.container}>
            <div className={styles.note}>{lastUpdated}</div>
            <button className={styles.refreshBtn}>
                <FontAwesomeIcon icon="sync" onClick={onRefresh} />
            </button>
            <h4 className={styles.subtitle}>{title}</h4>
            <h1 className={styles.title}>{count}</h1>
            <h1 className={styles.name}>{name}</h1>
            <h1 className={styles.titlePrice}>{price}</h1>
        </div>
    );
};

export default HRStatisticsCounter;
