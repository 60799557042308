import { AnyAction } from 'redux';

import { FETCH_CONFIG_SUCCESS, ConfigState } from './types';

const initialState: ConfigState = {
    isServerUnavailable: false,
    minVersionAndroid: '',
    minVersionIOS: '',
    whatsNewAndroid: '',
    whatsNewIOS: '',
    isAuthUnavailable: false,
    updateRequired: false,
    courses: {
        ios: [],
        android: [],
    },
    reservationMinHours: 0,
    freeCancellationMinHours: 0,
    confirmationLimit: 0,
    subscriptions: {
        iconUrl: '',
        title: '',
        description: '',
    },
    groupworkoutreservations: {
        iconUrl: '',
        title: '',
        description: '',
    },
    certificates: {
        active: false,
        webUrl: '',
        iconUrl: '',
        title: '',
        description: '',
    },
};

export function configReducer(
    state = initialState,
    action: AnyAction
): ConfigState {
    if (action.type === FETCH_CONFIG_SUCCESS) {
        const config = action.payload;

        return {
            ...state,
            ...config,
        };
    }

    return state;
}
