import React, { useState, useCallback, useEffect } from 'react';
import cogoToast from 'cogo-toast';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'hooks/debounce';

import {
    fetchAddressByLocation,
    fetchSuggestionsByAddress,
} from '@redux/modules/gyms/actions';
import { FeatureMemberEntity, GeoObject } from '@redux/modules/gyms/types';
import { AsyncDispatch } from '@redux/types';

import YandexMap from '../YandexMap';
import styles from './styles.module.css';

export interface Props {
    label: string;
    name: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
}

const GeoInput: React.FC = (props: any) => {
    const dispatch = useDispatch<AsyncDispatch>();
    const [address, updateAddress] = useState<string>(
        props?.value?.address || ''
    );
    const [suggestions, updateSuggestions] = useState<FeatureMemberEntity[]>(
        []
    );
    const [coordinates, updateCoordinates] = useState<number[]>(
        props?.value?.position
            ? [props.value.position.lng, props.value.position.lat]
            : []
    );

    const [dropdown, setDropdown] = useState<boolean>(false);
    const debounced = useDebounce(address);

    useEffect(() => {
        if (!debounced || debounced.length < 3) {
            return;
        }
        dispatch(fetchSuggestionsByAddress(debounced))
            .then((response) => {
                updateSuggestions(response.GeoObjectCollection.featureMember);
            })
            .catch(() => {
                cogoToast.error('Ошибка при загрузке адреса', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    }, [debounced]);

    const onPickSuggestion = useCallback((suggestion: GeoObject) => {
        updateAddress(suggestion.metaDataProperty.GeocoderMetaData.text);
        updateSuggestions([]);
        updateCoordinates(suggestion.Point.pos.split(' ').map(Number));
        setDropdown(false);

        const { setFieldValue, setShortAddressCount } = props;

        setFieldValue?.('geo.position', {
            lat: Number(suggestion.Point.pos.split(' ')[1]),
            lng: Number(suggestion.Point.pos.split(' ')[0]),
        });
        setFieldValue?.(
            'geo.address',
            suggestion.metaDataProperty.GeocoderMetaData.text
        );
        setFieldValue?.('geo.shortAddress', suggestion.name);
        setFieldValue?.('shortAddress', suggestion.name);
        setShortAddressCount?.(suggestion.name.length);
    }, []);

    const handleCoordinateChange = useCallback(
        async (position: string[]) => {
            const { setFieldValue, setShortAddressCount } = props || {};

            try {
                const result = await dispatch(fetchAddressByLocation(position));

                const suggestion =
                    result.GeoObjectCollection.featureMember[0].GeoObject;
                updateCoordinates(suggestion.Point.pos.split(' ').map(Number));
                updateAddress(
                    suggestion.metaDataProperty.GeocoderMetaData.text
                );
                setFieldValue?.('geo.position', {
                    lat: Number(suggestion.Point.pos.split(' ')[1]),
                    lng: Number(suggestion.Point.pos.split(' ')[0]),
                });
                setFieldValue?.(
                    'geo.address',
                    suggestion.metaDataProperty.GeocoderMetaData.text
                );
                setFieldValue?.('geo.shortAddress', suggestion.name);
                setFieldValue?.('shortAddress', suggestion.name);
                setShortAddressCount?.(suggestion.name.length);
            } catch (err) {
                cogoToast.error('Ошибка при загрузке адреса', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            }
        },
        [props]
    );

    return (
        <div className={styles.wrapper}>
            <label htmlFor={props?.name} className={styles.label}>
                {props?.label}
            </label>
            <input
                value={address}
                onChange={(e) => {
                    updateAddress(e.target.value);
                    setDropdown(true);
                    props.setFieldValue('geo.address', '');
                }}
                name={props?.name}
                placeholder={props?.placeholder}
                required={props?.required}
                disabled={props?.disabled}
                className={styles.input}
            />
            <div className={styles.dropdown}>
                {dropdown && (
                    <ul className={styles.dropdownList}>
                        {(suggestions || []).map(
                            (suggestion: FeatureMemberEntity) => {
                                const onClick = () =>
                                    onPickSuggestion(suggestion.GeoObject);

                                return (
                                    <li key={suggestion.GeoObject.Point.pos}>
                                        <button
                                            type="button"
                                            className={styles.suggestion}
                                            onClick={onClick}
                                        >
                                            <span>
                                                {
                                                    suggestion.GeoObject
                                                        .metaDataProperty
                                                        .GeocoderMetaData.text
                                                }
                                            </span>
                                        </button>
                                    </li>
                                );
                            }
                        )}
                    </ul>
                )}
            </div>
            <props.errorMessage
                className={styles.error}
                name="geo"
                component="div"
            />
            {!!props.value?.position && (
                <YandexMap
                    coordinate={coordinates}
                    onCoordinateUpdate={handleCoordinateChange}
                    isNew
                />
            )}
        </div>
    );
};

export default GeoInput;
