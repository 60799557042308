import { City, GroupWorkout, GroupWorkoutNewData, Gym } from '@t/gym';

export const FETCH_GYMS_LIST_REQUEST = 'FETCH_GYMS_LIST_REQUEST';
export const FETCH_GYMS_LIST_SUCCESS = 'FETCH_GYMS_LIST_SUCCESS';
export const FETCH_GYMS_LIST_ERROR = 'FETCH_GYMS_LIST_ERROR';

export const FETCH_CITIES_REQUEST = 'FETCH_CITIES_REQUEST';
export const FETCH_CITIES_SUCCESS = 'FETCH_CITIES_SUCCESS';
export const FETCH_CITIES_ERROR = 'FETCH_CITIES_ERROR';

export const FETCH_GYMS_REQUEST = 'FETCH_GYMS_REQUEST';
export const FETCH_GYMS_SUCCESS = 'FETCH_GYMS_SUCCESS';
export const FETCH_GYMS_ERROR = 'FETCH_GYMS_ERROR';

export const FETCH_SCROLL_GYMS_REQUEST = 'FETCH_SCROLL_GYMS_REQUEST';
export const FETCH_SCROLL_GYMS_SUCCESS = 'FETCH_SCROLL_GYMS_SUCCESS';
export const FETCH_SCROLL_GYMS_ERROR = 'FETCH_SCROLL_GYMS_ERROR';

export const FETCH_HIDDEN_GYMS_REQUEST = 'FETCH_HIDDEN_GYMS_REQUEST';
export const FETCH_HIDDEN_GYMS_SUCCESS = 'FETCH_HIDDEN_GYMS_SUCCESS';
export const FETCH_HIDDEN_GYMS_ERROR = 'FETCH_HIDDEN_GYMS_ERROR';

export const CREATE_GYM_REQUEST = 'CREATE_GYM_REQUEST';
export const CREATE_GYM_SUCCESS = 'CREATE_GYM_SUCCESS';
export const CREATE_GYM_ERROR = 'CREATE_GYM_ERROR';

export const FETCH_HIDDEN_GYM_REQUEST = 'FETCH_HIDDEN_GYM_REQUEST';
export const FETCH_HIDDEN_GYM_SUCCESS = 'FETCH_HIDDEN_GYM_SUCCESS';
export const FETCH_HIDDEN_GYM_ERROR = 'FETCH_HIDDEN_GYM_ERROR';

export const RESTORE_GYM_REQUEST = 'RESTORE_GYM_REQUEST';
export const RESTORE_GYM_SUCCESS = 'RESTORE_GYM_SUCCESS';
export const RESTORE_GYM_ERROR = 'RESTORE_GYM_ERROR';

export const DELETE_GYM_REQUEST = 'DELETE_GYM_REQUEST';
export const DELETE_GYM_SUCCESS = 'DELETE_GYM_SUCCESS';
export const DELETE_GYM_ERROR = 'DELETE_GYM_ERROR';

export const UPDATE_GYM_REQUEST = 'UPDATE_GYM_REQUEST';
export const UPDATE_GYM_SUCCESS = 'UPDATE_GYM_SUCCESS';
export const UPDATE_GYM_ERROR = 'UPDATE_GYM_ERROR';

export const SEARCH_GYMS_REQUEST = 'SEARCH_GYMS_REQUEST';
export const SEARCH_GYMS_SUCCESS = 'SEARCH_GYMS_SUCCESS';
export const SEARCH_GYMS_ERROR = 'SEARCH_GYMS_ERROR';

export const UPDATE_MANAGER_GYM_REQUEST = 'UPDATE_MANAGER_GYM_REQUEST';
export const UPDATE_MANAGER_GYM_SUCCESS = 'UPDATE_MANAGER_GYM_SUCCESS';
export const UPDATE_MANAGER_GYM_ERROR = 'UPDATE_MANAGER_GYM_ERROR';

export const UPDATE_LEGALENTITY_GYM_REQUEST = 'UPDATE_LEGALENTITY_GYM_REQUEST';
export const UPDATE_LEGALENTITY_GYM_SUCCESS = 'UPDATE_LEGALENTITY_GYM_SUCCESS';
export const UPDATE_LEGALENTITY_GYM_ERROR = 'UPDATE_LEGALENTITY_GYM_ERROR';

export const UPDATE_GYM_IMAGES_REQUEST = 'UPDATE_GYM_IMAGES_REQUEST';
export const UPDATE_GYM_IMAGES_SUCCESS = 'UPDATE_GYM_IMAGES_SUCCESS';
export const UPDATE_GYM_IMAGES_ERROR = 'UPDATE_GYM_IMAGES_ERROR';

export const FETCH_GYM_REQUEST = 'FETCH_GYM_REQUEST';
export const FETCH_GYM_SUCCESS = 'FETCH_GYM_SUCCESS';
export const FETCH_GYM_ERROR = 'FETCH_GYM_ERROR';

export const CREATE_GROUP_WORKOUT_REQUEST = 'CREATE_GROUP_WORKOUT_REQUEST';
export const CREATE_GROUP_WORKOUT_SUCCESS = 'CREATE_GROUP_WORKOUT_SUCCESS';
export const CREATE_GROUP_WORKOUT_ERROR = 'CREATE_GROUP_WORKOUT_ERROR';

export const UPDATE_GROUP_WORKOUT_REQUEST = 'UPDATE_GROUP_WORKOUT_REQUEST';
export const UPDATE_GROUP_WORKOUT_SUCCESS = 'UPDATE_GROUP_WORKOUT_SUCCESS';
export const UPDATE_GROUP_WORKOUT_ERROR = 'UPDATE_GROUP_WORKOUT_ERROR';

export const CANCEL_GROUP_WORKOUT_REQUEST = 'CANCEL_GROUP_WORKOUT_REQUEST';
export const CANCEL_GROUP_WORKOUT_SUCCESS = 'CANCEL_GROUP_WORKOUT_SUCCESS';
export const CANCEL_GROUP_WORKOUT_ERROR = 'CANCEL_GROUP_WORKOUT_ERROR';

export const ABORT_BOOKING_REQUEST = 'ABORT_BOOKING_REQUEST';
export const ABORT_BOOKING_SUCCESS = 'ABORT_BOOKING_SUCCESS';
export const ABORT_BOOKING_ERROR = 'ABORT_BOOKING_ERROR';

export const SELECT_GYM = 'SELECT_GYM';

export const UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';

export const UPLOAD_ICON_ERROR = 'UPLOAD_ICON_ERROR';
export const UPLOAD_ICON_SUCCESS = 'UPLOAD_ICON_SUCCESS';

export const FETCH_RESERVATION_STATUS_REQUEST =
    'FETCH_RESERVATION_STATUS_REQUEST';
export const FETCH_RESERVATION_STATUS_SUCCESS =
    'FETCH_RESERVATION_STATUS_SUCCESS';
export const FETCH_RESERVATION_STATUS_ERROR = 'FETCH_RESERVATION_STATUS_ERROR';

export const FETCH_GYMS_IMAGES_URLS_REQUEST = 'FETCH_GYMS_IMAGES_URLS_REQUEST';
export const FETCH_GYMS_IMAGES_URLS_SUCCESS = 'FETCH_GYMS_IMAGES_URLS_SUCCESS';
export const FETCH_GYMS_IMAGES_URLS_ERROR = 'FETCH_GYMS_IMAGES_URLS_ERROR';

export const DELETE_GYM_IMAGE_REQUEST = 'DELETE_GYM_IMAGE_REQUEST';
export const DELETE_GYM_IMAGE_SUCCESS = 'DELETE_GYM_IMAGE_SUCCESS';
export const DELETE_GYM_IMAGE_ERROR = 'DELETE_GYM_IMAGE_ERROR';

export const DELETE_GYM_ICON_REQUEST = 'DELETE_GYM_ICON_REQUEST';
export const DELETE_GYM_ICON_SUCCESS = 'DELETE_GYM_ICON_SUCCESS';
export const DELETE_GYM_ICON_ERROR = 'DELETE_GYM_ICON_ERROR';

export const ADD_SPECIAL_PRICE_REQUEST = 'ADD_SPECIAL_PRICE_REQUEST';
export const ADD_SPECIAL_PRICE_SUCCESS = 'ADD_SPECIAL_PRICE_SUCCESS';
export const ADD_SPECIAL_PRICE_ERROR = 'ADD_SPECIAL_PRICE_ERROR';

export const REMOVE_SPECIAL_PRICE_REQUEST = 'REMOVE_SPECIAL_PRICE_REQUEST';
export const REMOVE_SPECIAL_PRICE_SUCCESS = 'REMOVE_SPECIAL_PRICE_SUCCESS';
export const REMOVE_SPECIAL_PRICE_ERROR = 'REMOVE_SPECIAL_PRICE_ERROR';

export const FETCH_GYMS_SPECIAL_PRICE_REQUEST =
    'FETCH_GYMS_SPECIAL_PRICE_REQUEST';
export const FETCH_GYMS_SPECIAL_PRICE_SUCCESS =
    'FETCH_GYMS_SPECIAL_PRICE_SUCCESS';
export const FETCH_GYMS_SPECIAL_PRICE_ERROR = 'FETCH_GYMS_SPECIAL_PRICE_ERROR';
export const FILTER_GYMS_SPECIAL_PRICE_SUCCESS =
    'FILTER_GYMS_SPECIAL_PRICE_SUCCES';

export const FETCH_TABLETENT_REQUEST = 'FETCH_TABLETENT_REQUEST';
export const FETCH_TABLETENT_SUCCESS = 'FETCH_TABLETENT_SUCCESS';
export const FETCH_TABLETENT_ERROR = 'FETCH_TABLETENT_ERROR';

export interface GymState {
    ids: string[];
    byId: {
        [id: string]: Gym;
    };
    hiddenGymsIds: string[];
    hiddenGymsById: {
        [id: string]: Gym;
    };
    foundGymsIds: string[];
    foundGymsById: {
        [id: string]: Gym;
    };
    withSpecialPriceIds: string[];
    withSpecialPriceById: {
        [id: string]: Gym;
    };
    groupWorkoutsIds: string[];
    groupWorkoutsById: {
        [id: string]: GroupWorkout;
    };
    groupWorkoutsNewById: {
        [id: string]: GroupWorkoutNewData;
    };
    calendarGroupWorkouts: GroupWorkout[];
    selectedGymId: string;
    reservationStatuses: {
        [groupWorkoutId: string]: { [dateStart: string]: number };
    };
    currentGym: string | undefined;
    gymsList: Gym[];
    cities: City[];
}

export interface GeoObjectCollectionYandex {
    GeoObjectCollection: GeoObjectCollection;
}

export interface GeoObjectCollection {
    metaDataProperty: MetaDataProperty;
    featureMember: FeatureMemberEntity[];
}

export interface MetaDataProperty {
    GeocoderResponseMetaData: GeocoderResponseMetaData;
}

export interface GeocoderResponseMetaData {
    request: string;
    results: string;
    found: string;
}

export interface FeatureMemberEntity {
    GeoObject: GeoObject;
}

export interface GeoObject {
    metaDataProperty: MetaDataProperty;
    name: string;
    description: string;
    boundedBy: BoundedBy;
    Point: Point;
}

export interface MetaDataProperty {
    GeocoderMetaData: GeocoderMetaData;
}

export interface GeocoderMetaData {
    precision: string;
    text: string;
    kind: string;
    Address: Address;
    AddressDetails: AddressDetails;
}

export interface Address {
    country_code: string;
    formatted: string;
    Components?: ComponentsEntity[] | null;
    postal_code?: string | null;
}

export interface ComponentsEntity {
    kind: string;
    name: string;
}

export interface AddressDetails {
    Country: Country;
}

export interface Country {
    AddressLine: string;
    CountryNameCode: string;
    CountryName: string;
    AdministrativeArea: AdministrativeArea;
}

export interface AdministrativeArea {
    AdministrativeAreaName: string;
    Locality?: Locality | null;
    SubAdministrativeArea?: SubAdministrativeArea | null;
}

export interface Locality {
    LocalityName: string;
    Thoroughfare: Thoroughfare;
}

export interface Thoroughfare {
    ThoroughfareName: string;
    Premise: Premise;
}

export interface Premise {
    PremiseNumber: string;
}

export interface SubAdministrativeArea {
    SubAdministrativeAreaName: string;
    Locality: Locality1;
}

export interface Locality1 {
    LocalityName: string;
    Thoroughfare?: Thoroughfare1 | null;
    DependentLocality?: DependentLocality | null;
}

export interface Thoroughfare1 {
    ThoroughfareName: string;
    Premise: Premise1;
}

export interface Premise1 {
    PremiseNumber: string;
    PostalCode?: PostalCode | null;
}

export interface PostalCode {
    PostalCodeNumber: string;
}

export interface DependentLocality {
    DependentLocalityName: string;
    Thoroughfare: Thoroughfare2;
}

export interface Thoroughfare2 {
    ThoroughfareName: string;
    Premise: Premise2;
}

export interface Premise2 {
    PremiseNumber: string;
    PostalCode: PostalCode1;
}

export interface PostalCode1 {
    PostalCodeNumber: string;
}

export interface BoundedBy {
    Envelope: Envelope;
}

export interface Envelope {
    lowerCorner: string;
    upperCorner: string;
}

export interface Point {
    pos: string;
}
