import { AnyAction } from 'redux';

import { Subscription, UserSubscription } from '@t/subscriptions';

import {
    UserSubscriptionState,
    SubscriptionState,
    FETCH_SUBSCRIPTIONS_SUCCESS,
    FETCH_USER_SUBSCRIPTIONS_SUCCESS,
    FETCH_SUBSCRIPTION_SUCCESS,
    FETCH_USER_SUBSCRIPTION_SUCCESS,
    UPDATE_USER_SUBSCRIPTION_SUCCESS,
    FETCH_GOGYM_SUBSCRIPTION_SUCCESS,
} from './types';

const initialStateSubscription: SubscriptionState = {
    ids: [],
    byId: {},
    lastUpdated: 0,
};

const initialStateUserSubscription: UserSubscriptionState = {
    ids: [],
    byId: {},
    usersBySubscriptionId: {},
    lastUpdated: 0,
};

export function subscriptionsReducer(
    state = initialStateSubscription,
    action: AnyAction
): SubscriptionState {
    if (
        action.type === FETCH_SUBSCRIPTION_SUCCESS ||
        action.type === FETCH_GOGYM_SUBSCRIPTION_SUCCESS
    ) {
        const subscription = action.payload;
        const included = state.ids.includes(subscription._id);
        const ids = included ? state.ids : [...state.ids, subscription._id];
        const byId = {
            ...state.byId,
            [subscription._id]: subscription,
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === FETCH_SUBSCRIPTIONS_SUCCESS) {
        const { subscriptions } = action.payload;
        const ids = subscriptions.map(
            (subscription: Subscription) => subscription._id
        );
        const byId = subscriptions.reduce(
            (
                total: { [id: string]: Subscription },
                subscription: Subscription
            ) => ({
                ...total,
                [subscription._id]: subscription,
            }),
            {}
        );
        const lastUpdated = Date.now();

        return {
            ...state,
            ids,
            byId,
            lastUpdated,
        };
    }

    return state;
}

export function userSubscriptionsReducer(
    state = initialStateUserSubscription,
    action: AnyAction
): UserSubscriptionState {
    if (action.type === FETCH_USER_SUBSCRIPTION_SUCCESS) {
        const userSubscriptions = action.payload;
        const included = state.ids.includes(userSubscriptions._id);
        const ids = included
            ? state.ids
            : [...state.ids, userSubscriptions._id];
        const byId = {
            ...state.byId,
            [userSubscriptions._id]: userSubscriptions,
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === FETCH_USER_SUBSCRIPTIONS_SUCCESS) {
        const { userSubscriptions } = action.payload;
        const ids = userSubscriptions.map(
            (userSubscription: UserSubscription) => userSubscription._id
        );
        const byId = userSubscriptions.reduce(
            (
                total: { [id: string]: UserSubscription },
                userSubscription: UserSubscription
            ) => ({
                ...total,
                [userSubscription._id]: userSubscription,
            }),
            {}
        );
        const lastUpdated = Date.now();

        return {
            ...state,
            ids,
            byId,
            lastUpdated,
        };
    }

    if (action.type === UPDATE_USER_SUBSCRIPTION_SUCCESS) {
        const subscription = action.payload;
        const included = state.ids.includes(subscription._id);
        const ids = included ? state.ids : [...state.ids, subscription._id];
        const byId = {
            ...state.byId,
            [subscription._id]: {
                ...state.byId[subscription._id],
                ...subscription,
            },
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    return state;
}
