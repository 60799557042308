import React from 'react';
import { IconProps } from './types';

export const Shape: React.FC<IconProps> = ({ ...props }) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_1126_13598)">
            <path
                d="M3.75196 6.16409C3.08338 6.16409 2.74855 6.97195 3.22131 7.44442L4.98106 9.2031C6.40416 10.6253 7.11571 11.3364 7.9999 11.3364C8.8841 11.3364 9.59565 10.6253 11.0187 9.20309L12.7785 7.44442C13.2513 6.97195 12.9164 6.16409 12.2478 6.16409C12.0488 6.16409 11.8579 6.24311 11.7172 6.38376L9.95743 8.14243C9.21588 8.88353 8.76423 9.3304 8.39681 9.61056C8.22723 9.73987 8.12363 9.79497 8.0668 9.81865C8.02386 9.83655 8.0087 9.83647 8.00091 9.83643L7.9999 9.83643L7.9989 9.83643C7.99111 9.83647 7.97594 9.83655 7.93301 9.81865C7.87617 9.79497 7.77258 9.73987 7.603 9.61056C7.23558 9.3304 6.78393 8.88353 6.04237 8.14243L4.28262 6.38376C4.14188 6.24311 3.951 6.16409 3.75196 6.16409Z"
                fill="#1C1C1C"
            />
        </g>
        <defs>
            <clipPath id="clip0_1126_13598">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default Shape;
