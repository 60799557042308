import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { AsyncDispatch } from '@redux/types';
import BackButton from '@components/BackButton';
import { fetchReport } from '@redux/modules/reports/actions';
import { getReport } from '@redux/modules/reports/selectors';

import styles from './styles.module.css';
import ReportForm from './Form';

export const HRReportDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const report = useSelector(getReport(id || ''));

    useEffect(() => {
        if (!id) {
            return;
        }
        dispatch(fetchReport(id));
    }, [dispatch, id]);

    if (!report) {
        return null;
    }

    const initialValues = {
        date: moment(report.date).format('MM-YYYY'),
        status: report.status,
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <BackButton
                    to="/dashboard/reports"
                    title="К списку отчетов"
                    className={styles.backBtn}
                />
                <div className={styles.box}>
                    <ReportForm initialValues={initialValues} report={report} />
                </div>
            </div>
        </div>
    );
};

export default HRReportDetails;
