import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import styles from './styles.module.css';

export interface Props {
    label: string;
    name: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
}

const DateTimePickerInput: React.FC<Props & WrappedFieldProps> = ({
    input,
    meta,
    name,
    label,
    required,
    disabled,
}) => {
    return (
        <div className={styles.wrapper}>
            <label htmlFor={name} className={styles.label}>
                {label}
            </label>
            <input
                {...input}
                className={styles.input}
                type="datetime-local"
                required={required}
                disabled={disabled}
            />
            {meta && meta.touched && meta.error && (
                <div className={styles.error}>{meta.error}</div>
            )}
        </div>
    );
};

export default DateTimePickerInput;
