import { AnyAction } from 'redux';

import {
    GroupWorkoutReservation,
    IGroupWorkoutReservationNew,
} from '@t/reservation';

import {
    FETCH_GROUP_WORKOUT_RESERVATIONS_SUCCESS,
    FETCH_RESERVATION_SUCCESS,
    FETCH_RESERVATIONS_SUCCESS,
    FETCH_SCROLL_RESERVATIONS_SUCCESS,
    ReservationsState,
    UPDATE_RESERVATION_SUCCESS,
} from './types';

const initialState: ReservationsState = {
    ids: [],
    byId: {},
    lastUpdated: 0,
};

export function reservationsReducer(
    state = initialState,
    action: AnyAction
): ReservationsState {
    if (action.type === FETCH_RESERVATION_SUCCESS) {
        const reservation = action.payload;
        const included = state.ids.includes(reservation._id);
        const ids = included ? state.ids : [...state.ids, reservation._id];
        const byId = {
            ...state.byId,
            [reservation._id]: reservation,
        };

        return {
            ...state,
            ids,
            byId,
        };
    }
    if (action.type === FETCH_RESERVATIONS_SUCCESS) {
        const { items } = action.payload;
        const ids = items.map((item: IGroupWorkoutReservationNew) => item._id);
        const byId = items.reduce(
            (
                total: { [id: string]: IGroupWorkoutReservationNew },
                item: IGroupWorkoutReservationNew
            ) => ({
                ...total,
                [item._id]: item,
            }),
            {}
        );
        const lastUpdated = Date.now();

        return {
            ...state,
            ids,
            byId,
            lastUpdated,
        };
    }

    if (action.type === FETCH_SCROLL_RESERVATIONS_SUCCESS) {
        const reservations = action.payload;
        const ids = reservations.map(
            (reservation: IGroupWorkoutReservationNew) => reservation._id
        );
        const byId = reservations.reduce(
            (
                total: { [id: string]: IGroupWorkoutReservationNew },
                reservation: IGroupWorkoutReservationNew
            ) => ({
                ...total,
                [reservation._id]: reservation,
            }),
            {}
        );
        const lastUpdated = Date.now();

        return {
            ...state,
            ids: [...state.ids, ...ids],
            byId: { ...state.byId, ...byId },
            lastUpdated,
        };
    }

    if (action.type === FETCH_GROUP_WORKOUT_RESERVATIONS_SUCCESS) {
        const { reservations } = action.payload;
        const ids = reservations.map(
            (item: GroupWorkoutReservation) => item._id
        );
        const byId = reservations.reduce(
            (
                total: { [id: string]: GroupWorkoutReservation },
                item: GroupWorkoutReservation
            ) => ({
                ...total,
                [item._id]: item,
            }),
            {}
        );
        const lastUpdated = Date.now();

        return {
            ...state,
            ids,
            byId,
            lastUpdated,
        };
    }

    if (action.type === UPDATE_RESERVATION_SUCCESS) {
        const reservation = action.payload;
        const included = state.ids.includes(reservation._id);
        const ids = included ? state.ids : [...state.ids, reservation._id];
        const byId = {
            ...state.byId,
            [reservation._id]: {
                ...state.byId[reservation._id],
                ...reservation,
            },
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    return state;
}
