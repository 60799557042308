import React from 'react';
import classNames from 'classnames';
import InputMask from 'react-input-mask';

import styles from './styles.module.css';

export interface Props {
    hidden?: boolean;
    label: string;
    name: string;
    type?: string;
    maxLength?: number;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    toggleVisibility?: boolean;
    className?: string;
    phone: string;
    setPhone: any;
    phoneError?: boolean;
    setPhoneError?: any;
}

const PhoneInput: React.FC<Props> = ({
    name,
    type,
    label,
    placeholder,
    required,
    disabled,
    hidden,
    className,
    phone,
    setPhone,
    phoneError,
    setPhoneError,
}) => {
    const handleInput = ({
        target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(value);
        setPhoneError(false);
    };

    return (
        <div className={classNames(styles.wrapper, className)} hidden={hidden}>
            <label htmlFor={name} className={styles.label}>
                {label}
            </label>
            <InputMask
                name={name}
                type={type}
                required={required}
                disabled={disabled}
                mask="+7 (999) 999-99-99"
                className={styles.input}
                placeholder={placeholder}
                value={phone}
                onChange={handleInput}
            />
            {phoneError && (
                <div
                    style={{
                        fontSize: '14px',
                        marginTop: '3px',
                        color: '#ff3333',
                    }}
                >
                    Обязательное поле
                </div>
            )}
        </div>
    );
};

export default PhoneInput;
