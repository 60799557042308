import { GymMembership, Membership } from '@t/membership';
import { ApplicationState } from '@redux/types';
import { AppUser } from '@t/app-user';

export const getMemberships = (state: ApplicationState): Membership[] =>
    state.memberships.ids.map((id: string) => state.memberships.byId[id]);

export const getMembership = (id: string) => (
    state: ApplicationState
): Membership => state.memberships.byId[id];

export const getGymMemberships = (state: ApplicationState): GymMembership[] =>
    state.gymMemberships.ids.map((id: string) => state.gymMemberships.byId[id]);

export const getGymMembership = (id: string) => (
    state: ApplicationState
): GymMembership => state.gymMemberships.byId[id];

export const getMembershipAppUser = (id: string) => (
    state: ApplicationState
): AppUser => state.memberships.usersByMembershipId[id];
