import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import cogoToast from 'cogo-toast';

import { cancelGroupWorkout } from '@redux/modules/gyms/actions';
import { AsyncDispatch } from '@redux/types';
import { getGroupWorkoutById } from '@redux/modules/gyms/selectors';

import styles from './styles.module.css';
import Form from './Form';

export const GroupWorkoutCancel: React.FC<{}> = () => {
    const { id } = useParams<{ id: string }>();
    const location = useLocation<{
        dateStart: Date;
        dateFinish: Date;
        cancelParticularEvent: boolean;
    }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();

    const { dateStart, cancelParticularEvent } = location.state;

    const currentDate = moment();
    const groupWorkout = useSelector(getGroupWorkoutById(id || ''));
    if (
        (moment(groupWorkout.dateStart).diff(currentDate, 'hours', true) < 1 &&
            !groupWorkout.isRecurring) ||
        (moment(dateStart).diff(currentDate, 'hours', true) < 2 &&
            groupWorkout.isRecurring)
    ) {
        return (
            <div className={styles.container}>
                <Link to={`/dashboard/group-workouts`}>
                    Назад к календарю групповых тренировок
                </Link>
                <h2>
                    Групповую тренировку можно отменить минимум за 2 часа до ее
                    начала
                </h2>
            </div>
        );
    }

    const onSubmit = useCallback(
        (values) => {
            const cancelReason = values.cancelReason;
            dispatch(
                cancelGroupWorkout({
                    id,
                    cancelReason,
                    dateStart,
                    cancelParticularEvent,
                })
            )
                .then(() => {
                    cogoToast.success('Групповая тренировка отменена', {
                        position: 'top-right',
                        hideAfter: 5,
                    });
                    history.push(`/dashboard/group-workouts`);
                })
                .catch(() => {
                    cogoToast.error('Ошибка при отмене групповой тренировки', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                });
        },
        [dispatch]
    );
    return (
        <div className={styles.container}>
            <Link to={`/dashboard/group-workouts`}>
                Назад к календарю групповых тренировок
            </Link>

            <h2>Причина отмены тренировки</h2>

            <Form onSubmit={onSubmit} />
        </div>
    );
};

export default GroupWorkoutCancel;
