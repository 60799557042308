import { Tag, TagCategorie } from '@t/tag';

export const FETCH_TAGS_REQUEST = 'FETCH_TAGS_REQUEST';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_ERROR = 'FETCH_TAGS_ERROR';

export const FETCH_TAG_REQUEST = 'FETCH_TAG_REQUEST';
export const FETCH_TAG_SUCCESS = 'FETCH_TAG_SUCCESS';
export const FETCH_TAG_ERROR = 'FETCH_TAG_ERROR';

export const DELETE_TAG_REQUEST = 'DELETE_TAG_REQUEST';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_ERROR = 'DELETE_TAG_ERROR';

export const UPDATE_TAG_REQUEST = 'UPDATE_TAG_REQUEST';
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';
export const UPDATE_TAG_ERROR = 'UPDATE_TAG_ERROR';

export const CREATE_TAG_REQUEST = 'CREATE_TAG_REQUEST';
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';
export const CREATE_TAG_ERROR = 'CREATE_TAG_ERROR';

export const DELETE_TAG_ICON_REQUEST = 'DELETE_TAG_ICON_REQUEST';
export const DELETE_TAG_ICON_SUCCESS = 'DELETE_TAG_ICON_SUCCESS';
export const DELETE_TAG_ICON_ERROR = 'DELETE_TAG_ICON_ERROR';

export const FETCH_TAG_CATEGORIES_REQUEST = 'FETCH_TAG_CATEGORIES_REQUEST';
export const FETCH_TAG_CATEGORIES_SUCCESS = 'FETCH_TAG_CATEGORIES_SUCCESS';
export const FETCH_TAG_CATEGORIES_ERROR = 'FETCH_TAG_CATEGORIES_ERROR';

export interface TagState {
    ids: string[];
    byId: { [id: string]: Tag };
    lastUpdated: number;
}

export interface TagCategorieState {
    ids: string[];
    byId: { [id: string]: TagCategorie };
    lastUpdated: number;
}
