import axios from 'axios';

import { B2B_API_URL } from '@config';
import { AsyncActionCreator } from '@redux/types';

import {
    FILTER_REQUEST,
    FILTER_SUCCESS,
    FILTER_ERROR,
    FETCH_BOOKINGS_STATISTICS_REQUEST,
    FETCH_BOOKINGS_STATISTICS_SUCCESS,
    FETCH_BOOKINGS_STATISTICS_ERROR,
    FETCH_HR_STATISTICS_REQUEST,
    FETCH_HR_STATISTICS_SUCCESS,
    FETCH_HR_STATISTICS_ERROR,
    FILTER_HR_USERS_REQUEST,
    FILTER_HR_USERS_SUCCESS,
    FILTER_HR_USERS_ERROR,
} from './types';

export const filter: AsyncActionCreator<
    {
        selectedGymId: string | null | void;
        startDate: string;
        endDate: string;
        offset: number;
    },
    { date: Date; revenue: number }[]
> = ({ selectedGymId, startDate, endDate, offset }) => async (
    dispatch,
    getState
) => {
    dispatch({
        type: FILTER_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const {
            data: { dailyIncome, dailyBookings, periodRevenue, totalBookings },
        } = await axios.post(
            `${B2B_API_URL}/bookings/daily-stat`,
            { selectedGymId, startDate, endDate, offset },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FILTER_SUCCESS,
            payload: {
                dailyIncome,
                dailyBookings,
                filterRevenue: periodRevenue,
                numOfFilterBookings: totalBookings,
            },
        });

        return dailyIncome;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FILTER_ERROR,
            });

            throw err;
        }
    }
};

export const fetchBookingsStatistics: AsyncActionCreator<
    {
        selectedGymId: string | null | void;
    },
    { allBookings: number; activeBookings: number; totalRevenue: number }[]
> = ({ selectedGymId }) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_BOOKINGS_STATISTICS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const {
            data: { allBookings, activeBookings, totalRevenue },
        } = await axios.post(
            `${B2B_API_URL}/bookings/stat`,
            { selectedGymId },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_BOOKINGS_STATISTICS_SUCCESS,
            payload: {
                allBookings,
                activeBookings,
                totalRevenue,
            },
        });

        return allBookings;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_BOOKINGS_STATISTICS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchHRStatistics: AsyncActionCreator<
    void,
    {
        allUsers: number;
        totalBookingTime: number;
        averageBookingTime: number;
        totalNumberOfVisitedClubs: number;
        favoriteClub: string;
        totalCostOfBooking: number;
        averageCostOfBooking: number;
    }
> = () => async (dispatch, getState) => {
    dispatch({
        type: FETCH_HR_STATISTICS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const {
            data: {
                allUsers,
                totalBooking,
                totalBookingTime,
                averageBookingTime,
                totalNumberOfVisitedClubs,
                favoriteClub,
                totalCostOfBooking,
                averageCostOfBooking,
                mostActiveEmployee,
            },
        } = await axios.get(`${B2B_API_URL}/hr/statistics`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: FETCH_HR_STATISTICS_SUCCESS,
            payload: {
                allUsers,
                totalBooking,
                totalBookingTime,
                averageBookingTime,
                totalNumberOfVisitedClubs,
                favoriteClub,
                totalCostOfBooking,
                averageCostOfBooking,
                mostActiveEmployee,
            },
        });

        return allUsers;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_HR_STATISTICS_ERROR,
            });

            throw err;
        }
    }
};

export const filterHRUsers: AsyncActionCreator<
    {
        startDate: string;
        endDate: string;
    },
    { date: Date; appUsers: number }[]
> = ({ startDate, endDate }) => async (dispatch, getState) => {
    dispatch({
        type: FILTER_HR_USERS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const {
            data: { dailyUsers, totalUsers },
        } = await axios.post(
            `${B2B_API_URL}/hr/daily-statistics`,
            { startDate, endDate },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FILTER_HR_USERS_SUCCESS,
            payload: {
                dailyUsers,
                totalUsers,
            },
        });

        return dailyUsers;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FILTER_HR_USERS_ERROR,
            });

            throw err;
        }
    }
};
