import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ErrorMessage, Form, Field, useFormik, FormikProvider } from 'formik';
import cogoToast from 'cogo-toast';
import moment from 'moment';

import Button from '@components/Button';
import SelectInput from '@components/SelectInput';
import { Gym } from '@t/gym';
import { BookingType } from '@t/booking';
import Loader from '@components/Loader';
import { fetchUserReservations } from '@redux/modules/reservations/actions';
import {
    GroupWorkoutReservationNewType,
    IGroupWorkoutReservationNew,
} from '@t/reservation';
import { AsyncDispatch } from '@redux/types';
import TextInput from '@components/TextInput';
import DateTimePickerInput from '@components/DateTimePickerInput';
import { getDuration } from '@containers/Dashboard/BookingList/BookingListTable';
import QuestionMark from '@components/Icons/QuestionMark';
import Delete from '@components/Icons/Delete';
import { DATE_FORMAT, TIME_FORMAT } from '@config';

import styles from './styles.module.css';

export interface AppUserStartBookingFormData {
    gymId: string;
    type: string;
    groupWorkoutReservationNewId: string;
    timeStarted?: string;
    timeFinished?: string;
}

export interface Props {
    initialValues: AppUserStartBookingFormData;
    userId: string;
    onSubmit: (
        values: AppUserStartBookingFormData,
        setSubmitting: (isSubmitting: boolean) => void
    ) => void;
    onCancel: () => void;
    gymOptions: { title: string; value: string }[];
    gymsList: Gym[];
}

const AppUserStartBookingForm: React.FC<Props> = ({
    initialValues,
    onSubmit,
    onCancel,
    gymOptions,
    gymsList,
    userId,
}) => {
    const dispatch = useDispatch<AsyncDispatch>();
    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values, { setSubmitting }) => {
            onSubmit(values, setSubmitting);
        },
        validate: (values) => {
            const errors: Partial<AppUserStartBookingFormData> = {};

            if (!values.gymId) {
                errors.gymId = 'Необходимо выбрать зал';
            }

            if (!values.type) {
                errors.type = 'Необходимо выбрать тип тренировки';
            }

            if (
                values.type === BookingType.GroupWorkout &&
                !values.groupWorkoutReservationNewId
            ) {
                errors.type =
                    'Невозможно начать групповую тренировку без брони';
            }

            if (
                values.type === BookingType.GroupWorkout &&
                !values.timeStarted
            ) {
                errors.timeStarted =
                    'Невозможно начать групповую тренировку без времени начала';
            }

            if (
                values.type === BookingType.GroupWorkout &&
                !values.timeFinished
            ) {
                errors.timeFinished =
                    'Невозможно начать групповую тренировку без времени конца';
            }

            return errors;
        },
    });

    const [currentWorkoutOptions, setCurrentWorkoutOptions] = useState<
        { title: string; value: string }[]
    >([]);
    const [
        currentGroupWorkoutsOptions,
        setCurrentGroupWorkoutsOptions,
    ] = useState<{ title: string; value: string }[]>([]);
    const [reservations, setReservations] = useState<
        IGroupWorkoutReservationNew[]
    >([]);
    const [tooltips, setTooltips] = useState({
        timeStarted: false,
    });

    const calculateGymOptions = (gymId: string) => {
        const currentGym = gymsList.find((gym) => gym._id === gymId);

        const workoutsTypes = [];

        if (currentGym?.hasPerMinWorkouts) {
            workoutsTypes.push({
                title: 'Поминутная тренировка',
                value: BookingType.PerMinWorkout,
            });
        }

        if (currentGym?.hasSingleWorkouts) {
            workoutsTypes.push({
                title: 'Разовая тренировка',
                value: BookingType.SingleWorkout,
            });
        }

        if (currentGym?.hasGroupWorkouts) {
            workoutsTypes.push({
                title: 'Групповая тренировка',
                value: BookingType.GroupWorkout,
            });
        }

        return workoutsTypes;
    };

    useEffect(() => {
        if (formik.values.gymId && gymsList.length) {
            const options = calculateGymOptions(formik.values.gymId);

            formik.setFieldValue('type', '');
            formik.setFieldValue('groupWorkoutReservationId', '');

            setCurrentWorkoutOptions(options);
            setCurrentGroupWorkoutsOptions([]);

            if (options.length) {
                formik.setFieldValue('type', options[0].value);
            }
        }
    }, [formik.values.gymId, gymsList.length]);

    useEffect(() => {
        if (formik.values.type === BookingType.GroupWorkout) {
            const gymId = formik.values.gymId;

            dispatch(fetchUserReservations({ userId, gymId })).then(
                (reservations) => {
                    setReservations(reservations);
                    let filteredReservations = [...reservations];

                    filteredReservations = filteredReservations.filter(
                        (item) =>
                            item.status ===
                                GroupWorkoutReservationNewType.CONFIRMED ||
                            item.status ===
                                GroupWorkoutReservationNewType.SKIPPED
                    );

                    if (!filteredReservations.length) {
                        cogoToast.error(
                            'Забронированные тренировки не найдены',
                            {
                                position: 'top-right',
                                hideAfter: 4,
                            }
                        );
                        setCurrentGroupWorkoutsOptions([]);
                        return;
                    }

                    const reservationsOptions = filteredReservations.map(
                        (item) => ({
                            title: `${item.title} ${moment(item.date)
                                .utcOffset(0)
                                .format(DATE_FORMAT)}, ${moment(
                                item.timeSlot.timeStart
                            )
                                .utcOffset(0)
                                .format(TIME_FORMAT)}`,
                            value: item._id,
                        })
                    );

                    setCurrentGroupWorkoutsOptions(reservationsOptions);
                    formik.setFieldValue(
                        'groupWorkoutReservationNewId',
                        reservationsOptions[0]?.value
                    );
                    formik.setFieldValue(
                        'timeStarted',
                        `${moment(filteredReservations[0].date)
                            .utcOffset(0)
                            .format('YYYY-MM-DD')}T${moment(
                            filteredReservations[0].timeSlot.timeStart
                        )
                            .utcOffset(0)
                            .format(TIME_FORMAT)}`
                    );
                    formik.setFieldValue(
                        'timeFinished',
                        `${moment(filteredReservations[0].date)
                            .utcOffset(0)
                            .format('YYYY-MM-DD')}T${moment(
                            filteredReservations[0].timeSlot.timeFinish
                        )
                            .utcOffset(0)
                            .format(TIME_FORMAT)}`
                    );
                }
            );
        } else {
            setCurrentGroupWorkoutsOptions([]);
        }
    }, [formik.values.type, currentWorkoutOptions]);

    const handleChangeSelectValue = (
        name: keyof AppUserStartBookingFormData
    ) => (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        formik.setFieldValue(name, value);
        if (name === 'type') {
            formik.setFieldValue('timeStarted', '');
            formik.setFieldValue('timeFinished', '');
            formik.setFieldValue('duration', '');
        } else if (name === 'groupWorkoutReservationNewId') {
            formik.setFieldValue('groupWorkoutReservationNewId', value);
            const reservation = reservations.find((item) => item._id === value);
            if (reservation) {
                formik.setFieldValue(
                    'timeStarted',
                    `${moment(reservation.date)
                        .utcOffset(0)
                        .format('YYYY-MM-DD')}T${moment(
                        reservation.timeSlot.timeStart
                    )
                        .utcOffset(0)
                        .format(TIME_FORMAT)}`
                );
                formik.setFieldValue(
                    'timeFinished',
                    `${moment(reservation.date)
                        .utcOffset(0)
                        .format('YYYY-MM-DD')}T${moment(
                        reservation.timeSlot.timeFinish
                    )
                        .utcOffset(0)
                        .format(TIME_FORMAT)}`
                );
                formik.setFieldValue(
                    'duration',
                    getDuration(
                        reservation.timeSlot.timeFinish.toString(),
                        reservation.timeSlot.timeStart.toString()
                    )
                );
            }
        }
    };

    if (!gymOptions.length || formik.isSubmitting) {
        return <Loader />;
    }

    const showChangeTypeSelect = currentWorkoutOptions.length > 0;
    const showChangeGroupWorkoutSelect = currentGroupWorkoutsOptions.length > 0;

    return (
        <FormikProvider value={formik}>
            <Form>
                <div className={styles.row}>
                    <Field
                        name="gymId"
                        label="Фитнес-площадка"
                        styles={styles.input}
                        input={{
                            onChange: handleChangeSelectValue('gymId'),
                            value: formik.values.gymId,
                        }}
                        component={SelectInput}
                        options={gymOptions}
                    />
                    <ErrorMessage
                        className={styles.error}
                        name="gymId"
                        component="div"
                    />
                </div>
                {showChangeTypeSelect && (
                    <div className={styles.row}>
                        <Field
                            name="type"
                            label="Тип тренировки"
                            disabled={false}
                            styles={styles.input}
                            input={{
                                onChange: handleChangeSelectValue('type'),
                                value: formik.values.type,
                            }}
                            component={SelectInput}
                            options={currentWorkoutOptions}
                        />
                        <ErrorMessage
                            className={styles.error}
                            name="type"
                            component="div"
                        />
                    </div>
                )}
                {showChangeGroupWorkoutSelect && (
                    <div className={styles.row}>
                        <Field
                            name="groupWorkoutReservationId"
                            label="Бронирование"
                            styles={styles.input}
                            input={{
                                onChange: handleChangeSelectValue(
                                    'groupWorkoutReservationNewId'
                                ),
                                value:
                                    formik.values.groupWorkoutReservationNewId,
                            }}
                            component={SelectInput}
                            options={currentGroupWorkoutsOptions}
                        />
                        <ErrorMessage
                            className={styles.error}
                            name="groupWorkoutReservationNewId"
                            component="div"
                        />
                    </div>
                )}

                {showChangeTypeSelect &&
                    formik.values.type === BookingType.PerMinWorkout && (
                        <div className={styles.row}>
                            <Field
                                name="timeStarted"
                                label="Начало тренировки"
                                placeholder="Начало тренировки"
                                styles={styles.input}
                                input={{
                                    onChange: handleChangeSelectValue(
                                        'timeStarted'
                                    ),
                                    value: formik.values.timeStarted,
                                }}
                                component={DateTimePickerInput}
                            />
                            <ErrorMessage
                                className={styles.error}
                                name="timeStarted"
                                component="div"
                            />
                            <span
                                title="Подсказка про начало тренировки"
                                className={styles.info}
                                onClick={() =>
                                    setTooltips((prevState) => ({
                                        ...prevState,
                                        timeStarted: !prevState.timeStarted,
                                    }))
                                }
                            >
                                <QuestionMark />
                            </span>
                            {tooltips.timeStarted && (
                                <div className={styles.tooltip}>
                                    <p>
                                        Если тренировку нужно запустить сейчас,
                                        то оставьте поле “Начало тренировки” не
                                        заполненным.
                                    </p>
                                    <p>
                                        Для создания тренировки с точным
                                        временем старта или завершения
                                        учитывайте часовой пояс зала. Например,
                                        если вы находитесь в Москве, а
                                        пользователь посетил зал в Новосибирске,
                                        то укажите время тренировки по
                                        Новосибирскому времени.
                                    </p>
                                    <div
                                        onClick={() =>
                                            setTooltips((prevState) => ({
                                                ...prevState,
                                                timeStarted: false,
                                            }))
                                        }
                                    >
                                        <Delete
                                            className={styles.tooltipClose}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                {showChangeTypeSelect &&
                    formik.values.type === BookingType.PerMinWorkout && (
                        <div className={styles.row}>
                            <Field
                                name="timeFinished"
                                label="Конец тренировки"
                                placeholder="Конец тренировки"
                                styles={styles.input}
                                input={{
                                    onChange: handleChangeSelectValue(
                                        'timeFinished'
                                    ),
                                    value: formik.values.timeFinished,
                                }}
                                component={DateTimePickerInput}
                            />
                            <ErrorMessage
                                className={styles.error}
                                name="timeFinished"
                                component="div"
                            />
                        </div>
                    )}

                {showChangeTypeSelect &&
                    formik.values.type === BookingType.GroupWorkout &&
                    currentGroupWorkoutsOptions.length > 0 && (
                        <div className={styles.row}>
                            <Field
                                name="timeStarted"
                                label="Начало тренировки"
                                placeholder="Начало тренировки"
                                styles={styles.input}
                                input={{
                                    onChange: handleChangeSelectValue(
                                        'timeStarted'
                                    ),
                                    value: formik.values.timeStarted,
                                }}
                                component={DateTimePickerInput}
                            />
                            <ErrorMessage
                                className={styles.error}
                                name="timeStarted"
                                component="div"
                            />
                        </div>
                    )}

                {showChangeTypeSelect &&
                    formik.values.type === BookingType.GroupWorkout &&
                    currentGroupWorkoutsOptions.length > 0 && (
                        <div className={styles.row}>
                            <Field
                                name="timeFinished"
                                label="Конец тренировки"
                                placeholder="Конец тренировки"
                                styles={styles.input}
                                input={{
                                    onChange: handleChangeSelectValue(
                                        'timeFinished'
                                    ),
                                    value: formik.values.timeFinished,
                                }}
                                component={DateTimePickerInput}
                            />
                            <ErrorMessage
                                className={styles.error}
                                name="timeFinished"
                                component="div"
                            />
                        </div>
                    )}

                {showChangeTypeSelect &&
                    formik.values.type === BookingType.PerMinWorkout && (
                        <div className={styles.row}>
                            <Field
                                name="duration"
                                label="Длительность"
                                placeholder="0 мин."
                                styles={styles.input}
                                input={{
                                    value:
                                        formik.values.timeStarted &&
                                        formik.values.timeFinished
                                            ? getDuration(
                                                  formik.values.timeFinished,
                                                  formik.values.timeStarted
                                              )
                                            : '',
                                }}
                                disabled
                                component={TextInput}
                            />
                            <ErrorMessage
                                className={styles.error}
                                name="duration"
                                component="div"
                            />
                        </div>
                    )}

                {showChangeTypeSelect &&
                    formik.values.type === BookingType.GroupWorkout &&
                    currentGroupWorkoutsOptions.length > 0 && (
                        <div className={styles.row}>
                            <Field
                                name="duration"
                                label="Длительность"
                                placeholder="0 мин."
                                styles={styles.input}
                                input={{
                                    value:
                                        formik.values.timeStarted &&
                                        formik.values.timeFinished
                                            ? getDuration(
                                                  formik.values.timeFinished,
                                                  formik.values.timeStarted
                                              )
                                            : '',
                                }}
                                disabled
                                component={TextInput}
                            />
                            <ErrorMessage
                                className={styles.error}
                                name="duration"
                                component="div"
                            />
                        </div>
                    )}

                <Button className={styles.btn} onClick={formik.handleSubmit}>
                    Создать
                </Button>
                <Button className={styles.btn} onClick={onCancel}>
                    Отмена
                </Button>
            </Form>
        </FormikProvider>
    );
};

export default AppUserStartBookingForm;
