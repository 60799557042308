import React from 'react';
import { useDispatch } from 'react-redux';
import { FormSubmitHandler, SubmissionError } from 'redux-form';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { addManager } from '@redux/modules/auth/actions';
import BackButton from '@components/BackButton';
import { AsyncDispatch } from '@redux/types';

import Form from './Form';
import styles from './styles.module.css';

export interface FormData {
    email: string;
    password: string;
    password2: string;
    firstName: string;
    phone: string;
}

export const AddManager: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const onSubmit: FormSubmitHandler<FormData> = async ({
        email,
        password,
        password2,
        firstName,
        phone,
    }) => {
        if (password !== password2) {
            throw new SubmissionError({
                password2: 'Пароли не совпадают',
            });
        }

        try {
            dispatch(addManager({ email, password, firstName, phone }))
                .then(() => {
                    cogoToast.success('Менеджер создан', {
                        position: 'top-right',
                        hideAfter: 5,
                    });
                    history.push('/dashboard/add-partner-gym');
                })
                .catch(() => {
                    cogoToast.error('Ошибка при создании менеджера', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                });
        } catch (err) {
            throw new SubmissionError({
                _error: 'Ошибка при регистрации',
            });
        }
    };

    return (
        <div className={styles.container}>
            <h1>Зарегистрировать нового партнера</h1>

            <BackButton title="Назад" className={styles.backBtn} />

            <Form onSubmit={onSubmit} />
        </div>
    );
};

export default AddManager;
