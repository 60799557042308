import classNames from 'classnames';
import React, { useCallback, useMemo, useRef } from 'react';
import { ErrorMessage } from 'formik';

import styles from './styles.module.css';

export interface Props {
    name: string;
    label: string;
    accept: string;
    onChange?: any;
    fileRef?:
        | React.MutableRefObject<null>
        | React.MutableRefObject<HTMLInputElement>
        | any;
    hint?: string;
    value?: string;
}

export const FileUpload: React.FC<Props> = ({
    name,
    label,
    accept,
    hint,
    fileRef,
    onChange,
    value,
}) => {
    const localFileRef = useRef<null | HTMLInputElement>(null);

    const formattedValue = useMemo(() => {
        if (value) {
            const urlParts = value.split('/');

            return urlParts[urlParts.length - 1];
        }

        return '';
    }, []);

    const handleClick = useCallback(() => {
        if (localFileRef.current) {
            localFileRef.current.click();
        }
    }, [localFileRef.current]);

    return (
        <div className={styles.wrapper}>
            <form className="upload-image">
                <label className={styles.label}>{label}</label>
                <>
                    <input
                        type="file"
                        ref={(ref: any) => {
                            if (typeof fileRef === 'function') {
                                fileRef(ref);
                            } else {
                                fileRef.current = ref;
                            }
                            localFileRef.current = ref;
                        }}
                        className={classNames({
                            [styles.input]: !value,
                            [styles.inputHidden]: value,
                        })}
                        onChange={onChange}
                        accept={accept}
                    />
                    {value ? (
                        <div className={styles.input}>
                            <input
                                className={styles.inputCustomBtn}
                                type="button"
                                value={'Выберите файл'}
                                onClick={handleClick}
                            />
                            <span>{formattedValue}</span>
                        </div>
                    ) : null}
                </>
                {hint && <div className={styles.hint}>{hint}</div>}
            </form>
            <ErrorMessage
                className={styles.error}
                name={name}
                component="div"
            />
        </div>
    );
};
