import React from 'react';
import { IconProps } from './types';

export const Calendar: React.FC<IconProps> = ({ ...props }) => (
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_2538_3619)">
            <path
                d="M19 17.75C19.6904 17.75 20.25 18.3096 20.25 19C20.25 19.6904 19.6904 20.25 19 20.25C18.3096 20.25 17.75 19.6904 17.75 19C17.75 18.3096 18.3096 17.75 19 17.75Z"
                fill="#17C85F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 7.41921C10.0945 7.62649 9.39096 7.98098 8.68597 8.68597C7.3731 9.99884 7.27575 11.3068 7.08105 13.9228C7.03058 14.601 7 15.3005 7 16C7 16.6995 7.03058 17.399 7.08105 18.0772C7.27575 20.6932 7.3731 22.0012 8.68597 23.314C9.99884 24.6269 11.3068 24.7243 13.9228 24.9189C14.601 24.9694 15.3005 25 16 25C16.6995 25 17.399 24.9694 18.0772 24.9189C20.6932 24.7243 22.0012 24.6269 23.314 23.314C24.6269 22.0012 24.7243 20.6932 24.9189 18.0772C24.9694 17.399 25 16.6995 25 16C25 15.3005 24.9694 14.601 24.9189 13.9228C24.7243 11.3068 24.6269 9.99884 23.314 8.68597C22.609 7.98098 21.9055 7.62649 21 7.41921V6C21 5.44772 20.5523 5 20 5C19.4477 5 19 5.44772 19 6V7.15211C18.7111 7.12824 18.4044 7.10541 18.0772 7.08105C17.399 7.03058 16.6995 7 16 7C15.3005 7 14.601 7.03058 13.9228 7.08105C13.5956 7.10541 13.2889 7.12824 13 7.15211V6C13 5.44772 12.5523 5 12 5C11.4477 5 11 5.44772 11 6V7.41921ZM20 11C19.4477 11 19 10.5523 19 10V9.16169C18.6857 9.13349 18.3312 9.10549 17.9287 9.07554C17.2916 9.02812 16.6422 9 16 9C15.3578 9 14.7084 9.02812 14.0713 9.07554C13.6688 9.10549 13.3143 9.13349 13 9.16169V10C13 10.5523 12.5523 11 12 11C11.4477 11 11 10.5523 11 10V9.49739C10.6296 9.63368 10.3799 9.82046 10.1002 10.1002C9.49731 10.7031 9.3262 11.1662 9.16169 13H22.8383C22.6738 11.1662 22.5027 10.7031 21.8998 10.1002C21.6201 9.82046 21.3704 9.63368 21 9.49739V10C21 10.5523 20.5523 11 20 11ZM9.02136 15C9.00753 15.3335 9 15.6677 9 16C9 16.6422 9.02812 17.2916 9.07554 17.9287C9.28023 20.6791 9.39398 21.1936 10.1002 21.8998C10.8064 22.606 11.3209 22.7198 14.0713 22.9245C14.7084 22.9719 15.3578 23 16 23C16.6422 23 17.2916 22.9719 17.9287 22.9245C20.6791 22.7198 21.1936 22.606 21.8998 21.8998C22.606 21.1936 22.7198 20.6791 22.9245 17.9287C22.9719 17.2916 23 16.6422 23 16C23 15.6677 22.9925 15.3335 22.9786 15H9.02136Z"
                fill="#17C85F"
            />
        </g>
        <defs>
            <clipPath id="clip0_2538_3619">
                <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(4 4)"
                />
            </clipPath>
        </defs>
    </svg>
);

export default Calendar;
