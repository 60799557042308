import React from 'react';
import { InjectedFormProps, Field, reduxForm } from 'redux-form';
import Select, { createFilter } from 'react-select';

import TextInput from '@components/TextInput';
import SelectInput from '@components/SelectInput';
import { WeekDaySchedule } from '@t/gym';
import TextAreaInput from '@components/TextAreaInput';
import { GENDER_TYPES } from '@containers/Dashboard/GroupWorkoutForm';

import styles from './styles.module.css';

export interface TimeSlotFormData {
    title: string;
    dateStart: Date;
    dateFinish: Date;
    date: string;
    limit: number;
    price: number;
    weekDaySchedule: Array<WeekDaySchedule>;
    prepayment: number;
    withPrepayment: boolean;
    genderType: string;
    tags: string[];
}

export interface Props {
    gymId?: string;
    gymOptions: {
        label: string;
        value: string;
    }[];
    tagOptions: {
        label: string;
        value: string;
    }[];
    tagValue: {
        label: string;
        value: string;
    }[];
    gymValue: {
        label: string;
        value: string;
    }[];
    tagsCategoriesHelperItems: string[];
    goToGroupWorkout: () => void;
}

const TimeSlotForm: React.FC<
    Props & InjectedFormProps<TimeSlotFormData, Props>
> = ({
    handleSubmit,
    gymOptions,
    tagOptions,
    tagValue,
    gymValue,
    tagsCategoriesHelperItems,
    goToGroupWorkout,
}) => {
    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.wrapper}>
                <div className={styles.row}>
                    <Field
                        disabled
                        name="title"
                        label="Название тренировки"
                        component={TextInput}
                        className={styles.mb8}
                    />

                    <div className={styles.selectContainer}>
                        <span className={styles.selectLabel}>
                            Фитнес-площадка
                        </span>

                        <Select
                            className={styles.select}
                            styles={{
                                input: (provided) => ({
                                    ...provided,
                                    minHeight: 40,
                                    borderRadius: '0px 0px 20px 20px',
                                }),
                            }}
                            isDisabled
                            options={gymOptions}
                            defaultValue={gymValue}
                            filterOption={createFilter({
                                stringify: (option) => `${option.label}`,
                            })}
                            placeholder="Выберите из списка"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: 'rgb(225, 129, 65)',
                                },
                            })}
                            noOptionsMessage={() => 'Не найдено'}
                        />
                    </div>

                    <div className={styles.selectContainer}>
                        <span className={styles.selectLabel}>
                            Вид тренировки
                        </span>
                        <Select
                            className={styles.select}
                            isMulti
                            styles={{
                                input: (provided) => ({
                                    ...provided,
                                    minHeight: 40,
                                    borderRadius: '0px 0px 20px 20px',
                                }),
                            }}
                            isDisabled
                            options={tagOptions}
                            defaultValue={tagValue}
                            filterOption={createFilter({
                                stringify: (option) => `${option.label}`,
                            })}
                            placeholder="Выберите из списка"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: 'rgb(225, 129, 65)',
                                },
                            })}
                            noOptionsMessage={() => 'Не найдено'}
                        />
                        {Boolean(tagsCategoriesHelperItems.length) && (
                            <div className={styles.selectHelper}>
                                <div className={styles.selectHelperName}>
                                    Попадает в категории:
                                </div>
                                <div className={styles.selectHelperList}>
                                    {tagsCategoriesHelperItems.map(
                                        (itemName) => (
                                            <div
                                                key={itemName}
                                                className={
                                                    styles.selectHelperItem
                                                }
                                            >
                                                {itemName}
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    <Field
                        disabled
                        name="description"
                        label="Описание"
                        placeholder="Текстовое описание для текущей тренировки"
                        component={TextAreaInput}
                    />
                </div>

                <div className={styles.rightRow}>
                    <div className={styles.row}>
                        <Field
                            disabled
                            name="date"
                            label="Дата тренировки"
                            component={TextInput}
                            className={styles.mb8}
                        />

                        <Field
                            disabled
                            name="duration"
                            label="Длительность (мин.)"
                            component={TextInput}
                            className={styles.mb8}
                        />

                        <Field
                            disabled
                            name="limit"
                            label="Число участников"
                            component={TextInput}
                            className={styles.mb8}
                        />
                    </div>
                    <div className={styles.row}>
                        <Field
                            disabled
                            name="timeStart"
                            label="Время начала"
                            component={TextInput}
                            className={styles.mb8}
                        />

                        <Field
                            disabled
                            name="price"
                            label="Стоимость"
                            component={TextInput}
                            className={styles.mb8}
                        />

                        <Field
                            disabled
                            name="genderType"
                            label="Для кого тренировка"
                            options={GENDER_TYPES}
                            component={SelectInput}
                            className={styles.mb8}
                        />
                    </div>
                </div>
            </div>

            <div className={styles.btnContainer}>
                <button
                    className={styles.mainWorkoutBtn}
                    type="button"
                    onClick={goToGroupWorkout}
                >
                    Основная тренировка
                </button>

                <button className={styles.disabledSlotBtn} type="submit">
                    Отменить слот
                </button>
            </div>
        </form>
    );
};

export default reduxForm<TimeSlotFormData, Props>({})(TimeSlotForm);
