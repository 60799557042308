import React from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoaderVertical from '@components/LoaderVertical';
import { GroupWorkoutNew } from '@t/gym';

import styles from './styles.module.css';

export function shiftTimeByTimezone(createdAt: Date, timezone: string): string {
    if (!createdAt) {
        return '';
    }
    const createdAtMoment = moment.utc(createdAt);
    const timezoneOffset = parseInt(timezone.split('UTC')[1], 10);
    const shiftedTime = createdAtMoment.utcOffset(timezoneOffset * 60);
    return shiftedTime.format('DD.MM.YYYY, HH:mm');
}

function mapSchedule(schedule: string[] = []): string {
    const mapping: { [id: string]: string } = {
        mon: 'Пн',
        tue: 'Вт',
        wed: 'Ср',
        thu: 'Чт',
        fri: 'Пт',
        sat: 'Сб',
        sun: 'Вс',
    };

    const days = Object.keys(mapping);
    const mappedValues = days
        .map((day) => (schedule.includes(day) ? mapping[day] : null))
        .filter((day): day is string => day !== null);

    if (mappedValues.length === 7) {
        return 'Ежедневно';
    } else if (
        mappedValues.length === 5 &&
        mappedValues.every((day) =>
            ['Пн', 'Вт', 'Ср', 'Чт', 'Пт'].includes(day)
        )
    ) {
        return 'Будни';
    } else if (
        mappedValues.length === 2 &&
        mappedValues.includes('Сб') &&
        mappedValues.includes('Вс')
    ) {
        return 'Выходные';
    } else {
        return mappedValues.join(', ');
    }
}

function sortByDate(arr: GroupWorkoutNew[]): GroupWorkoutNew[] {
    return arr.sort(
        (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
}

export interface Props {
    groupWorkouts: GroupWorkoutNew[];
    loading?: boolean;
}

const columns = [
    {
        Header: 'Название',
        accessor: 'title',
    },
    {
        Header: 'Дата создания',
        accessor: (groupWorkout: GroupWorkoutNew) =>
            moment(groupWorkout.createdAt)
                .utcOffset(0)
                .format('DD.MM.YYYY, HH:mm'),
    },
    {
        Header: 'Расписание',
        accessor: (groupWorkout: GroupWorkoutNew) =>
            groupWorkout?.schedule?.length > 0
                ? mapSchedule(groupWorkout.schedule)
                : 'Нет данных',
    },
    {
        Header: 'Кол-во мест',
        accessor: (groupWorkout: GroupWorkoutNew) => groupWorkout.limit,
    },
    {
        Header: 'Стоимость',
        accessor: (groupWorkout: GroupWorkoutNew) => `${groupWorkout.price} ₽`,
    },
];

export const GroupWorkoutsTable: React.FC<Props> = ({
    groupWorkouts,
    loading,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable(
        {
            // @ts-ignore
            columns,
            data: sortByDate(groupWorkouts) || [],
            initialState: {
                pageIndex: 0,
                pageSize: 10000,
            },
        },
        useSortBy,
        usePagination
    );

    const history = useHistory();

    return (
        <>
            <table {...getTableProps()} className={styles.table}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={Math.random()}
                        >
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                    key={Math.random()}
                                >
                                    {column.render('Header')}
                                    <span className={styles.sorted}>
                                        <FontAwesomeIcon
                                            icon={
                                                column.isSorted
                                                    ? column.isSortedDesc
                                                        ? 'sort-up'
                                                        : 'sort-down'
                                                    : 'sort'
                                            }
                                        />
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        const onClick = () => {
                            const groupWorkout = row.original;
                            history.push(
                                `/dashboard/group-workouts/${groupWorkout._id}`
                            );
                        };
                        return (
                            <tr
                                {...row.getRowProps()}
                                className={styles.tableRow}
                                onClick={onClick}
                                key={Math.random()}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            key={Math.random()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {loading && <LoaderVertical />}
        </>
    );
};

export default GroupWorkoutsTable;
