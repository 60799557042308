import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select, { createFilter } from 'react-select';

import TextInput from '@components/TextInput';
import SingleDatePickerInput from '@components/SingleDatePickerInput';
import SelectInput from '@components/SelectInput';
import { GenderType, ITimeSlot } from '@t/gym';
import { Tag, TagCategorie } from '@t/tag';

import {
    ErrorMessage,
    Field,
    FieldArray,
    FieldArrayRenderProps,
    FieldProps,
    Form,
    FormikProvider,
    useFormik,
} from 'formik';
import TimePicker from 'rc-time-picker';
import moment, { Moment } from 'moment';
import Modal from '@components/Modal';
import Button from '@components/Button';
import Plus from '@components/Icons/Plus';
import Close from '@components/Icons/Close';
import { FormMode } from '@t/utils';
import classNames from 'classnames';
import Calendar from '@components/Icons/Calendar';
import { useGroupWorkoutNewFormHelpers } from '@containers/Dashboard/GroupWorkoutNewForm/useGroupWorkoutNewFormHelpers';
import Increase from '@components/Icons/Increase';
import { DATE_TIME_FORMAT } from '@config';
import Tooltip from '@components/Tooltip';

import styles from './styles.module.css';
import { useHistory } from 'react-router-dom';
import { morphNoun } from '@utils/format.helpers';

enum EWeekDays {
    mon = 'Понедельник',
    tue = 'Вторник',
    wed = 'Среда',
    thu = 'Четверг',
    fri = 'Пятница',
    sat = 'Суббота',
    sun = 'Воскресенье',
}

type WeekDaysParams = {
    key: keyof typeof EWeekDays;
    title: EWeekDays;
};

const GENDER_TYPES = [
    { title: 'Для всех', value: GenderType.ALL },
    { title: 'Для женщин', value: GenderType.FEMALE },
    { title: 'Для мужчин', value: GenderType.MALE },
];

const WEEKDAYS_PARAMS_LIST: WeekDaysParams[] = [
    {
        key: 'mon',
        title: EWeekDays.mon,
    },
    {
        key: 'tue',
        title: EWeekDays.tue,
    },
    {
        key: 'wed',
        title: EWeekDays.wed,
    },
    {
        key: 'thu',
        title: EWeekDays.thu,
    },
    {
        key: 'fri',
        title: EWeekDays.fri,
    },
    {
        key: 'sat',
        title: EWeekDays.sat,
    },
    {
        key: 'sun',
        title: EWeekDays.sun,
    },
];

export interface GroupWorkoutNewFormData {
    title: string;
    tags: string[];
    gymId: string;
    duration: string;
    limit: string;
    price: string;
    genderType: GenderType;
    description: string;
    timeline?: string;
    groupworkoutschedule: {
        reservations?: {
            _id: string;
            date: Date;
            groupWorkoutReservationsNew: string;
            timeSlot: string;
        }[];
        dateStart: Date | null;
        dateEnd: Date | null;
        mon: ITimeSlot[];
        tue: ITimeSlot[];
        wed: ITimeSlot[];
        thu: ITimeSlot[];
        fri: ITimeSlot[];
        sat: ITimeSlot[];
        sun: ITimeSlot[];
    };
}

type OptionValue = { label: string; value: string };
type WeekDays = keyof Pick<
    GroupWorkoutNewFormData['groupworkoutschedule'],
    'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun'
>;

export interface Props {
    formMode: FormMode;
    groupWorkoutId: string;
    onChangeFormMode: (mode: FormMode) => void;
    onSubmit: (
        values: GroupWorkoutNewFormData,
        setSubmitting: (isSubmitting: boolean) => void
    ) => void;
    initialValues: GroupWorkoutNewFormData;
    gymOptions: OptionValue[];
    tagOptions: OptionValue[];
    tagCategories: TagCategorie[];
    tags: Tag[];
    onDelete?: () => void;
    onDuplicate?: () => void;
}

const GroupWorkoutNewForm: React.FC<Props> = ({
    groupWorkoutId,
    formMode,
    onChangeFormMode,
    onSubmit,
    initialValues,
    gymOptions,
    tagOptions,
    tagCategories,
    tags = [],
    onDelete,
    onDuplicate,
}) => {
    const history = useHistory();
    const formEditMode = formMode === 'edit';
    const formReadMode = formMode === 'readonly';
    const formCreateMode = formMode === 'create';

    const {
        tooltipShown,
        setTooltipShown,
        timePickerShown,
        setTimePickerShown,
        reservationsByWeekDay,
        modalReservationSlotsControls,
        setModalReservationSlotsControls,
        modalAutocompleteControls,
        setModalAutocompleteControls,
        getValidTimeSlots,
        getEqualTimeSlots,
        getLatestReservationDateObject,
        generateMomentTimeSlotToValidData,
        generateMomentDateToValidStartDayData,
        generateTimeSlots,
        generateReservationsByWeekDay,
        generateTooltipInitialState,
        generateEqualDateMomentObject,
    } = useGroupWorkoutNewFormHelpers();

    const [tagsCategoriesHelperItems, setTagsCategoriesHelperItems] = useState<
        string[]
    >([]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: (values, { setSubmitting }) => {
            const normalizedValues = {
                ...values,
                groupworkoutschedule: {
                    ...values.groupworkoutschedule,
                    dateStart: generateMomentDateToValidStartDayData(
                        values.groupworkoutschedule.dateStart
                    ),
                    dateEnd: generateMomentDateToValidStartDayData(
                        values.groupworkoutschedule.dateEnd
                    ),
                    mon: generateMomentTimeSlotToValidData(
                        values.groupworkoutschedule.mon
                    ),
                    tue: generateMomentTimeSlotToValidData(
                        values.groupworkoutschedule.tue
                    ),
                    wed: generateMomentTimeSlotToValidData(
                        values.groupworkoutschedule.wed
                    ),
                    thu: generateMomentTimeSlotToValidData(
                        values.groupworkoutschedule.thu
                    ),
                    fri: generateMomentTimeSlotToValidData(
                        values.groupworkoutschedule.fri
                    ),
                    sat: generateMomentTimeSlotToValidData(
                        values.groupworkoutschedule.sat
                    ),
                    sun: generateMomentTimeSlotToValidData(
                        values.groupworkoutschedule.sun
                    ),
                },
            };

            onSubmit(normalizedValues, setSubmitting);
        },
        validate: (values) => {
            const errors: Partial<{
                title: string;
                gymId: string;
                tags: string;
                duration: string;
                price: string;
                limit: string;
                genderType: string;
                groupworkoutschedule: { dateStart: string; dateEnd: string };
                timeline: string;
            }> = {};

            if (!values.title) {
                errors.title = 'Необходимо указать название';
            }

            if (!values.gymId) {
                errors.gymId = 'Необходимо выбрать зал';
            }

            if (!values.tags.length) {
                errors.tags = 'Необходимо выбрать тип тренировки';
            }

            if (!values.groupworkoutschedule.dateStart) {
                if (errors.groupworkoutschedule) {
                    errors.groupworkoutschedule.dateStart =
                        'Необходимо выбрать дату начала периода';
                }
            }

            if (!values.price) {
                errors.price = 'Необходимо указать стоимость тренировки';
            }

            if (isNaN(Number(values.price))) {
                errors.price = 'Должно быть числом';
            }

            if (!values.duration) {
                errors.duration = 'Необходимо указать длительность тренировки';
            }

            if (isNaN(Number(values.duration))) {
                errors.duration = 'Должно быть числом';
            }

            if (!values.limit) {
                errors.limit = 'Необходимо указать число участников';
            }

            if (isNaN(Number(values.limit))) {
                errors.limit = 'Должно быть числом';
            }

            if (!values.genderType) {
                errors.genderType = 'Необходимо указать для кого тренировка';
            }

            if (
                !getValidTimeSlots(values.groupworkoutschedule.mon).length &&
                !getValidTimeSlots(values.groupworkoutschedule.tue).length &&
                !getValidTimeSlots(values.groupworkoutschedule.wed).length &&
                !getValidTimeSlots(values.groupworkoutschedule.thu).length &&
                !getValidTimeSlots(values.groupworkoutschedule.fri).length &&
                !getValidTimeSlots(values.groupworkoutschedule.sat).length &&
                !getValidTimeSlots(values.groupworkoutschedule.sun).length
            ) {
                errors.timeline = 'Необходимо заполнить хотя бы один день';
            }

            return errors;
        },
    });

    const formikAutocompleteModalForm = useFormik<{
        autocompleteTimeStart: null | Moment;
        autocompleteTimeFinish: null | Moment;
    }>({
        enableReinitialize: true,
        isInitialValid: false,
        initialValues: {
            autocompleteTimeStart: null,
            autocompleteTimeFinish: null,
        },
        onSubmit: (values, { resetForm, validateForm }) => {
            const { weekDayKey } = modalAutocompleteControls;
            const timeSlots = generateTimeSlots({
                timeStart: values.autocompleteTimeStart as Moment,
                timeFinish: values.autocompleteTimeFinish as Moment,
                duration: Number(formik.values.duration),
            });

            formik.setFieldValue(
                `groupworkoutschedule.${weekDayKey}`,
                timeSlots
            );
            resetForm({
                values: {
                    autocompleteTimeStart: null,
                    autocompleteTimeFinish: null,
                },
            });
            validateForm();
            setModalAutocompleteControls({
                active: false,
                weekDayKey: '',
            });
        },
        validate: (values) => {
            const errors: Partial<{
                autocompleteTimeStart: string;
                autocompleteTimeFinish: string;
            }> = {};

            if (!values.autocompleteTimeStart) {
                errors.autocompleteTimeStart =
                    'Необходимо выбрать время начала';
            }

            if (!values.autocompleteTimeFinish) {
                errors.autocompleteTimeFinish =
                    'Необходимо выбрать время конца';
            }

            return errors;
        },
    });

    useEffect(() => {
        const localDuration = Number(formik.values.duration);
        const weekDayStruct: any = {
            mon: [],
            tue: [],
            wed: [],
            thu: [],
            fri: [],
            sat: [],
            sun: [],
        };
        const weekDayKeysArr = Object.keys(weekDayStruct);

        for (const weekDayKey of weekDayKeysArr) {
            const scheduleByDay =
                formik.values.groupworkoutschedule[weekDayKey as WeekDays];

            const localDay: any[] = [];

            for (const scheduleByDayElement of scheduleByDay) {
                const { _id, timeStart, timeFinish } = scheduleByDayElement;

                if (timeStart && timeFinish) {
                    localDay.push({
                        _id,
                        timeStart,
                        timeFinish: moment(timeStart).add(
                            localDuration,
                            'minutes'
                        ),
                    });
                } else {
                    localDay.push(scheduleByDayElement);
                }
            }

            weekDayStruct[weekDayKey as keyof typeof weekDayStruct] = localDay;
        }

        for (const [key, value] of Object.entries(weekDayStruct)) {
            formik.setFieldValue(`groupworkoutschedule.${key}`, value);
        }
    }, [formik.values.duration]);

    useEffect(() => {
        if (formEditMode) {
            const localScheduleData = WEEKDAYS_PARAMS_LIST.map(
                (weekDayParam) => {
                    if (
                        formik.values.groupworkoutschedule[weekDayParam.key]
                            .length > 0
                    ) {
                        return {
                            key: weekDayParam.key,
                            values:
                                formik.values.groupworkoutschedule[
                                    weekDayParam.key
                                ],
                        };
                    } else {
                        return {
                            key: weekDayParam.key,
                            values: {
                                timeStart: null,
                                timeFinish: null,
                            },
                        };
                    }
                }
            );
            localScheduleData.forEach((scheduleData) => {
                formik.setFieldValue(scheduleData.key, scheduleData.values);
            });
        }
    }, [formMode]);

    useEffect(() => {
        const tagIds = formik.values.tags;
        const filteredTagByCategories = tags.filter(
            (tag) => tagIds.includes(tag._id) && tag.categoryIds
        );

        const categoryIds = new Set<string>();

        for (const filteredTag of filteredTagByCategories) {
            filteredTag.categoryIds.forEach((id) => categoryIds.add(id));
        }

        const categories = tagCategories.reduce<string[]>((acc, category) => {
            if (categoryIds.has(category._id)) {
                acc.push(category.title);
            }
            return acc;
        }, []);

        setTagsCategoriesHelperItems(categories);
    }, [tags, formik.values.tags]);

    useEffect(() => {
        if (formEditMode || formReadMode) {
            generateReservationsByWeekDay({
                reservations: initialValues.groupworkoutschedule.reservations,
                groupworkoutschedule: {
                    mon: initialValues.groupworkoutschedule.mon,
                    tue: initialValues.groupworkoutschedule.tue,
                    wed: initialValues.groupworkoutschedule.wed,
                    thu: initialValues.groupworkoutschedule.thu,
                    fri: initialValues.groupworkoutschedule.fri,
                    sat: initialValues.groupworkoutschedule.sat,
                    sun: initialValues.groupworkoutschedule.sun,
                },
            });
        }
    }, [formMode, initialValues.groupworkoutschedule?.reservations]);

    useEffect(() => {
        if (formEditMode) {
            generateTooltipInitialState({
                mon: initialValues.groupworkoutschedule.mon,
                tue: initialValues.groupworkoutschedule.tue,
                wed: initialValues.groupworkoutschedule.wed,
                thu: initialValues.groupworkoutschedule.thu,
                fri: initialValues.groupworkoutschedule.fri,
                sat: initialValues.groupworkoutschedule.sat,
                sun: initialValues.groupworkoutschedule.sun,
            });
        }
    }, []);

    const goToTimeSlotDetail = useCallback(
        (reservation: { date: Moment; timeSlot: string }) => {
            const { timeSlot: timeSlotId, date } = reservation;

            setModalReservationSlotsControls({
                active: false,
                weekDayKey: '',
            });

            setTimeout(() => {
                history.push(
                    `/dashboard/group-workouts/${groupWorkoutId}/timeslots/${timeSlotId}`,
                    {
                        dateStart: date.utc(true).toDate(),
                    }
                );
            }, 0);
        },
        [history]
    );

    const tagsDefaultValue = useMemo(() => {
        if (!formik.values.tags.length) {
            return null;
        }

        return formik.values.tags.reduce<{ label: string; value: string }[]>(
            (acc, tag) => {
                const selectedOption = tagOptions.find(
                    (tagOption) => tagOption.value === tag
                );
                if (selectedOption) {
                    acc.push({
                        label: selectedOption.label,
                        value: selectedOption.value,
                    });
                }
                return acc;
            },
            []
        );
    }, [formik.values.tags]);

    const gymDefaultValue = useMemo(() => {
        if (!formik.values.gymId) {
            return { label: 'Выберите из списка', value: '' };
        }
        return {
            label:
                gymOptions.find((gym) => gym.value === formik.values.gymId)
                    ?.label || '',
            value:
                gymOptions.find((gym) => gym.value === formik.values.gymId)
                    ?.value || '',
        };
    }, [formik.values.gymId, gymOptions]);

    const handleChangeFieldValue = (name: string) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = event.target;
        formik.setFieldValue(name, value);
    };

    const handleChangeSelectValue = (name: string) => (
        optionData: OptionValue | OptionValue[] | any
    ) => {
        if (Array.isArray(optionData)) {
            formik.setFieldValue(
                name,
                optionData.map((data) => data.value)
            );
        } else {
            formik.setFieldValue(name, optionData.value);
        }
    };

    const handleChangeSlotTimePickerValue = ({
        timeStartName,
        timeFinishName,
        weekDaysKey,
        value,
        idx,
    }: {
        timeStartName: string;
        timeFinishName: string;
        weekDaysKey: keyof typeof EWeekDays;
        value: Moment;
        idx: number;
    }) => {
        const currentTimeSlots =
            formik.values.groupworkoutschedule[weekDaysKey];
        const currentElement =
            formik.values.groupworkoutschedule[weekDaysKey][idx];
        const localCurrentTimeSlots = [...currentTimeSlots].filter(
            (item) => item.timeStart && item.timeFinish
        );
        const duration = formik.values.duration;

        const resultTimeStart = moment(value);
        const resultTimeFinish = moment(value).add(duration, 'minutes');

        if (currentTimeSlots.length === 1) {
            formik.setFieldValue(timeStartName, resultTimeStart);
            formik.setFieldValue(timeFinishName, resultTimeFinish);
            return;
        }

        const localNewCurrentTimeSlots: any[] = [...localCurrentTimeSlots];

        if (currentElement) {
            localNewCurrentTimeSlots[idx] = {
                _id: '',
                existed: true,
                timeStart: resultTimeStart,
                timeFinish: resultTimeFinish,
            };
        } else {
            localNewCurrentTimeSlots.push({
                _id: '',
                candidate: true,
                timeStart: resultTimeStart,
                timeFinish: resultTimeFinish,
            });
        }

        localNewCurrentTimeSlots.sort(
            (a, b) =>
                (generateEqualDateMomentObject(
                    a.timeStart
                ) as Moment).valueOf() -
                (generateEqualDateMomentObject(b.timeStart) as Moment).valueOf()
        );

        const result: any[] = [];

        for (let i = 0; i < localNewCurrentTimeSlots.length; i++) {
            const currentElement = localNewCurrentTimeSlots[i];
            const prevElement = localNewCurrentTimeSlots[i - 1];
            const nextElement = localNewCurrentTimeSlots[i + 1];

            const getDatesObject = () => ({
                currentTimeStart: moment(
                    generateEqualDateMomentObject(currentElement.timeStart),
                    'HH:mm'
                ),
                currentTimeFinish: moment(
                    generateEqualDateMomentObject(currentElement.timeFinish),
                    'HH:mm'
                ),
                ...(prevElement?.timeStart && {
                    prevTimeStart: moment(
                        generateEqualDateMomentObject(prevElement.timeStart),
                        'HH:mm'
                    ),
                }),
                ...(prevElement?.timeFinish && {
                    prevTimeFinish: moment(
                        generateEqualDateMomentObject(prevElement.timeFinish),
                        'HH:mm'
                    ),
                }),
                ...(nextElement?.timeStart && {
                    nextTimeStart: moment(
                        generateEqualDateMomentObject(nextElement.timeStart),
                        'HH:mm'
                    ),
                }),
                ...(nextElement?.timeFinish && {
                    nextTimeFinish: moment(
                        generateEqualDateMomentObject(nextElement.timeFinish),
                        'HH:mm'
                    ),
                }),
            });

            if (currentElement.existed) {
                const localDates = getDatesObject();

                if (
                    localDates.currentTimeStart.isBetween(
                        localDates.prevTimeStart,
                        localDates.prevTimeFinish,
                        null,
                        '()'
                    ) ||
                    localDates.currentTimeFinish.isBetween(
                        localDates.prevTimeStart,
                        localDates.prevTimeFinish,
                        null,
                        '()'
                    ) ||
                    localDates.currentTimeFinish.isBetween(
                        localDates.nextTimeStart,
                        localDates.nextTimeFinish,
                        null,
                        '()'
                    ) ||
                    localDates.currentTimeStart.isBetween(
                        localDates.nextTimeStart,
                        localDates.nextTimeFinish,
                        null,
                        '()'
                    ) ||
                    localDates.currentTimeStart.isSame(
                        localDates.nextTimeStart
                    ) ||
                    localDates.currentTimeStart.isSame(localDates.prevTimeStart)
                ) {
                    delete currentElement.existed;
                    continue;
                }
            }

            if (currentElement.candidate) {
                const localDates = getDatesObject();

                if (
                    !prevElement &&
                    localDates.currentTimeFinish.isBetween(
                        localDates.nextTimeStart,
                        localDates.nextTimeFinish,
                        null,
                        '()'
                    )
                ) {
                    delete currentElement.candidate;
                    continue;
                }

                if (
                    localDates.currentTimeStart.isBetween(
                        localDates.prevTimeStart,
                        localDates.prevTimeFinish,
                        null,
                        '()'
                    ) ||
                    localDates.currentTimeStart.isSame(localDates.prevTimeStart)
                ) {
                    delete currentElement.candidate;
                    continue;
                }

                if (
                    localDates.currentTimeFinish.isBetween(
                        localDates.nextTimeStart,
                        localDates.nextTimeFinish,
                        null,
                        '()'
                    )
                ) {
                    delete currentElement.candidate;
                    continue;
                }
            }

            if (currentElement.existed) {
                delete currentElement.existed;
            }

            if (currentElement.candidate) {
                delete currentElement.candidate;
            }
            result.push(currentElement);
        }

        const sortedResult = result.sort((a, b) => {
            if (a?.timeStart === null) {
                return 1;
            }

            if (b?.timeStart === null) {
                return -1;
            }

            return (
                (generateEqualDateMomentObject(
                    a.timeStart
                ) as Moment).valueOf() -
                (generateEqualDateMomentObject(b.timeStart) as Moment).valueOf()
            );
        });

        formik.setFieldValue(
            `groupworkoutschedule.${weekDaysKey}`,
            sortedResult
        );
    };

    const handleChangeDatePickerValue = (name: string) => (value: any) => {
        if (formEditMode && name === 'groupworkoutschedule.dateEnd') {
            const latestReservation = getLatestReservationDateObject({
                reservations: initialValues.groupworkoutschedule.reservations,
            });

            if (latestReservation) {
                const latestReservationMoment = moment(latestReservation.date)
                    .startOf('day')
                    .utc(false);
                const valueMoment = moment(value).startOf('day').utc(false);

                if (
                    valueMoment.isAfter(latestReservationMoment) ||
                    !valueMoment.isValid()
                ) {
                    formik.setFieldValue(name, value);
                    return;
                } else {
                    setTooltipShown((prevState) => ({
                        ...prevState,
                        'groupworkoutschedule.dateEnd': true,
                    }));
                    return;
                }
            }
        }

        formik.setFieldValue(name, value);
    };

    const handlePressAutocomplete = (weekDayKey: keyof typeof EWeekDays) => {
        setModalAutocompleteControls({
            active: true,
            weekDayKey,
        });
    };

    const renderTimeSlotFields = (weekDaysKey: keyof typeof EWeekDays) => (
        renderProps: FieldArrayRenderProps
    ) => {
        const localReservationsByWeekDay = reservationsByWeekDay?.[
            weekDaysKey
        ].filter((reservation) =>
            moment(reservation.date).isSameOrAfter(moment().startOf('day'))
        );
        const bHasReservationForTimeSlot = Boolean(
            formEditMode && localReservationsByWeekDay?.length
        );

        const getFieldDisabledState = (idx: number) => {
            const bEqualTimeSlots = getEqualTimeSlots(
                formik.values.groupworkoutschedule[weekDaysKey][idx],
                localReservationsByWeekDay
            );
            return Boolean(
                formEditMode &&
                    localReservationsByWeekDay?.length &&
                    bEqualTimeSlots
            );
        };

        const renderFieldActionButton = (idx: number) => {
            const bEqualTimeSlots = getEqualTimeSlots(
                formik.values.groupworkoutschedule[weekDaysKey][idx],
                localReservationsByWeekDay
            );
            const bHasToRenderCalendarButton = Boolean(
                (formEditMode || formReadMode) &&
                    localReservationsByWeekDay?.length &&
                    bEqualTimeSlots
            );

            const bFirstItem =
                formik.values.groupworkoutschedule[weekDaysKey].length === 1;
            const bLastTimeSlotValid =
                formik.values.groupworkoutschedule[weekDaysKey][idx]?.timeStart;
            const bHasToRenderDeleteButton =
                bLastTimeSlotValid && (formEditMode || formCreateMode);

            if (bHasToRenderCalendarButton) {
                return (
                    <button
                        type={'button'}
                        className={styles.timelineItemIcon}
                        onClick={() =>
                            bHasReservationForTimeSlot
                                ? setTooltipShown((prevState) => ({
                                      ...prevState,
                                      [`groupworkoutschedule.${weekDaysKey}.${idx}`]: true,
                                  }))
                                : setModalReservationSlotsControls({
                                      active: true,
                                      weekDayKey: weekDaysKey,
                                  })
                        }
                    >
                        <Calendar />
                    </button>
                );
            }

            if (bHasToRenderDeleteButton) {
                return (
                    <button
                        type={'button'}
                        className={styles.timelineItemIcon}
                        onClick={() => {
                            if (bFirstItem) {
                                renderProps.remove(idx);
                                renderProps.push({
                                    timeStart: null,
                                    timeFinish: null,
                                });
                                return;
                            }

                            renderProps.remove(idx);
                        }}
                    >
                        <Close />
                    </button>
                );
            }

            return null;
        };

        const renderAddButton = () => {
            const fieldsLength =
                formik.values.groupworkoutschedule[weekDaysKey].length;
            const bLastTimeSlotValid =
                formik.values.groupworkoutschedule[weekDaysKey][
                    fieldsLength - 1
                ]?.timeStart;
            const bCanBeRenderedByProcess = Boolean(
                formEditMode || formCreateMode
            );

            if (bLastTimeSlotValid && bCanBeRenderedByProcess) {
                return (
                    <button
                        type={'button'}
                        className={styles.timelineAddBtn}
                        onClick={() =>
                            renderProps.push({
                                timeStart: null,
                                timeFinish: null,
                            })
                        }
                    >
                        <Plus />
                    </button>
                );
            }

            return null;
        };

        return (
            <div className={styles.timelineContainer}>
                {formik.values.groupworkoutschedule[weekDaysKey].map(
                    (slot, idx) => (
                        <div key={idx} className={styles.timelineItemContainer}>
                            <Field
                                className={styles.timelineItemInput}
                                name={`groupworkoutschedule.${weekDaysKey}.${idx}.timeStart`}
                            >
                                {({ field }: FieldProps) => (
                                    <>
                                        <TimePicker
                                            name={field.name}
                                            disabled={
                                                formReadMode ||
                                                getFieldDisabledState(idx)
                                            }
                                            showSecond={false}
                                            placeholder={'--:--'}
                                            minuteStep={5}
                                            value={
                                                field.value
                                                    ? moment(field.value)
                                                    : field.value
                                            }
                                            open={
                                                timePickerShown[
                                                    `groupworkoutschedule.${weekDaysKey}.${idx}`
                                                ]
                                            }
                                            onOpen={() =>
                                                setTimePickerShown(
                                                    (prevState) => ({
                                                        ...prevState,
                                                        [`groupworkoutschedule.${weekDaysKey}.${idx}`]: true,
                                                    })
                                                )
                                            }
                                            onClose={() =>
                                                setTimePickerShown(
                                                    (prevState) => ({
                                                        ...prevState,
                                                        [`groupworkoutschedule.${weekDaysKey}.${idx}`]: false,
                                                    })
                                                )
                                            }
                                            focusOnOpen={true}
                                            inputReadOnly={true}
                                            onChange={(value) => {
                                                handleChangeSlotTimePickerValue(
                                                    {
                                                        weekDaysKey,
                                                        timeStartName: `groupworkoutschedule.${weekDaysKey}.${idx}.timeStart`,
                                                        timeFinishName: `groupworkoutschedule.${weekDaysKey}.${idx}.timeFinish`,
                                                        value,
                                                        idx,
                                                    }
                                                );
                                                setTimePickerShown(
                                                    (prevState) => ({
                                                        ...prevState,
                                                        [`groupworkoutschedule.${weekDaysKey}.${idx}`]: false,
                                                    })
                                                );
                                            }}
                                            hideDisabledOptions
                                            className={
                                                styles.timelineItemInputTimePicker
                                            }
                                        />
                                        {renderFieldActionButton(idx)}
                                    </>
                                )}
                            </Field>
                            <Tooltip
                                contentContainerStyles={
                                    styles.timelineItemTooltip
                                }
                                active={
                                    tooltipShown[
                                        `groupworkoutschedule.${weekDaysKey}.${idx}`
                                    ]
                                }
                                onClose={() =>
                                    setTooltipShown((prevState) => ({
                                        ...prevState,
                                        [`groupworkoutschedule.${weekDaysKey}.${idx}`]: false,
                                    }))
                                }
                            >
                                <p>
                                    Данный слот нельзя редактировать, пока у
                                    него есть активные бронирования.
                                </p>
                                <p>
                                    Отмените все бронирования слота, чтобы снять
                                    ограничение.
                                </p>
                            </Tooltip>
                            <ErrorMessage
                                className={styles.error}
                                name={`groupworkoutschedule.${weekDaysKey}.${idx}.timeStart`}
                                component="div"
                            />
                        </div>
                    )
                )}
                {renderAddButton()}
            </div>
        );
    };

    const renderWeeklyScheduleByDay = useCallback(() => {
        const getAutoCompleteButtonDisabledState = (
            weekDaysKey: keyof typeof EWeekDays
        ) => {
            const localReservationsByWeekDay =
                reservationsByWeekDay?.[weekDaysKey];
            return Boolean(formEditMode && localReservationsByWeekDay?.length);
        };

        const localWeekDaysParamsList = WEEKDAYS_PARAMS_LIST.reduce<
            WeekDaysParams[]
        >((acc, weekDay) => {
            if (formik.values.groupworkoutschedule[weekDay.key].length > 0) {
                acc.push(weekDay);
            }
            return acc;
        }, []);
        const localParamsList = formEditMode
            ? WEEKDAYS_PARAMS_LIST
            : localWeekDaysParamsList;

        return (
            <>
                <div className={styles.weeklyScheduleContainer}>
                    {localParamsList.map((wpl) => (
                        <div
                            className={styles.weeklyScheduleDayRow}
                            key={wpl.key}
                        >
                            <div className={styles.weeklyScheduleDayRowHeader}>
                                <div
                                    className={
                                        styles.weeklyScheduleDayRowHeaderTitle
                                    }
                                >
                                    {wpl.title}:
                                </div>
                                {Boolean(formEditMode || formCreateMode) && (
                                    <button
                                        type={'button'}
                                        disabled={getAutoCompleteButtonDisabledState(
                                            wpl.key
                                        )}
                                        className={classNames(
                                            styles.weeklyScheduleDayRowHeaderButton,
                                            {
                                                [styles.weeklyScheduleDayRowHeaderButtonDisabled]: getAutoCompleteButtonDisabledState(
                                                    wpl.key
                                                ),
                                            }
                                        )}
                                        onClick={() =>
                                            handlePressAutocomplete(wpl.key)
                                        }
                                    >
                                        Автозаполнение
                                    </button>
                                )}
                            </div>
                            <FieldArray
                                name={`groupworkoutschedule.${wpl.key}`}
                                render={renderTimeSlotFields(wpl.key)}
                            />
                        </div>
                    ))}
                </div>
                {formik.errors.timeline && (
                    <div className={styles.error}>{formik.errors.timeline}</div>
                )}
            </>
        );
    }, [
        formik.values,
        formik.errors,
        reservationsByWeekDay,
        tooltipShown,
        timePickerShown,
        formMode,
    ]);

    const renderActionButtons = () => {
        if (formReadMode) {
            return (
                <Button
                    type="button"
                    className={styles.btn}
                    onClick={() => onChangeFormMode('edit')}
                >
                    Редактировать
                </Button>
            );
        }

        if (formEditMode) {
            return (
                <div className={styles.actionBtnContainer}>
                    <Button type="submit" className={styles.btn}>
                        Сохранить изменения
                    </Button>
                    <Button
                        type="button"
                        className={classNames(styles.btn, styles.btnClone)}
                        onClick={onDuplicate}
                    >
                        Дублировать
                    </Button>
                    <Button
                        type="button"
                        className={classNames(styles.btn, styles.btnDelete)}
                        onClick={onDelete}
                    >
                        Удалить
                    </Button>
                </div>
            );
        }

        if (formCreateMode) {
            return (
                <Button type="submit" className={styles.btn}>
                    Создать тренировку
                </Button>
            );
        }
    };

    const renderAutocompleteTimeModal = () => {
        return (
            <Modal
                active={modalAutocompleteControls.active}
                setActive={(prev) => {
                    setModalAutocompleteControls((prevState) => ({
                        ...prevState,
                        active: prev,
                    }));
                    formikAutocompleteModalForm.resetForm({
                        values: {
                            autocompleteTimeStart: null,
                            autocompleteTimeFinish: null,
                        },
                    });
                    formikAutocompleteModalForm.validateForm();
                }}
            >
                <div className={styles.modalContainer}>
                    <h2>Автозаполнение</h2>
                    <div className={styles.modalContainerInner}>
                        <FormikProvider value={formikAutocompleteModalForm}>
                            <Form autoComplete={'off'}>
                                <div className={styles.label}>
                                    Выберите временной промежуток
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.modalCol}>
                                        <Field
                                            id={`autocompleteTimeStart`}
                                            name={`autocompleteTimeStart`}
                                        >
                                            {({ field }: FieldProps) => (
                                                <TimePicker
                                                    name={field.name}
                                                    showSecond={false}
                                                    placeholder={'--:--'}
                                                    minuteStep={5}
                                                    value={field.value}
                                                    onChange={(value) => {
                                                        formikAutocompleteModalForm.setFieldValue(
                                                            'autocompleteTimeStart',
                                                            value
                                                        );
                                                    }}
                                                    hideDisabledOptions
                                                    className={
                                                        styles.modalInputTimePicker
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className={styles.modalCol}>
                                        <Field
                                            id={`autocompleteTimeFinish`}
                                            name={`autocompleteTimeFinish`}
                                        >
                                            {({ field }: FieldProps) => (
                                                <>
                                                    <TimePicker
                                                        name={field.name}
                                                        showSecond={false}
                                                        placeholder={'--:--'}
                                                        minuteStep={5}
                                                        value={field.value}
                                                        onChange={(value) => {
                                                            formikAutocompleteModalForm.setFieldValue(
                                                                'autocompleteTimeFinish',
                                                                value
                                                            );
                                                        }}
                                                        hideDisabledOptions
                                                        className={
                                                            styles.modalInputTimePicker
                                                        }
                                                    />
                                                </>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <Button
                                        disabled={
                                            !formikAutocompleteModalForm.isValid
                                        }
                                        type="submit"
                                        className={styles.modalBtn}
                                    >
                                        Заполнить
                                    </Button>
                                </div>
                            </Form>
                        </FormikProvider>
                    </div>
                </div>
            </Modal>
        );
    };

    const renderReservationsSlotsModal = () => {
        const currentReservationsModalData =
            reservationsByWeekDay?.[modalReservationSlotsControls.weekDayKey];
        const filteredReservationsModalData = currentReservationsModalData?.filter(
            (reservation) =>
                moment(reservation.date).isSameOrAfter(moment().startOf('day'))
        );
        const bHasItems = currentReservationsModalData?.length > 0;

        return (
            <Modal
                active={modalReservationSlotsControls.active}
                setActive={() =>
                    setModalReservationSlotsControls({
                        active: false,
                        weekDayKey: '',
                    })
                }
            >
                <div className={styles.modalContainer}>
                    <h2>Слоты с бронированием</h2>
                    <div className={styles.modalContainerInner}>
                        {bHasItems ? (
                            <div className={styles.modalContainerList}>
                                {filteredReservationsModalData?.map(
                                    (reservation, idx) => (
                                        <div
                                            key={idx}
                                            className={
                                                styles.modalContainerListItem
                                            }
                                        >
                                            <span>
                                                {moment(
                                                    reservation.date
                                                ).format(DATE_TIME_FORMAT)}
                                            </span>
                                            <span>
                                                {
                                                    filteredReservationsModalData?.length
                                                }{' '}
                                                {morphNoun(
                                                    filteredReservationsModalData?.length,
                                                    [
                                                        'бронирование',
                                                        'бронирования',
                                                        'бронирований',
                                                    ]
                                                )}
                                            </span>
                                            <div
                                                onClick={() =>
                                                    goToTimeSlotDetail(
                                                        reservation
                                                    )
                                                }
                                            >
                                                <Increase />
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        ) : (
                            <div>Нет данных</div>
                        )}
                    </div>
                    <div>
                        <Button
                            className={classNames(
                                styles.modalBtn,
                                styles.modalBtnSecondary
                            )}
                            buttonType={'primary'}
                            type={'button'}
                            onClick={() =>
                                setModalReservationSlotsControls({
                                    active: false,
                                    weekDayKey: '',
                                })
                            }
                        >
                            Закрыть
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    };

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete={'off'}>
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <Field
                                name="title"
                                label="Название тренировки"
                                placeholder="Введите название"
                                disabled={formReadMode}
                                input={{
                                    onChange: handleChangeFieldValue('title'),
                                    value: formik.values.title,
                                }}
                                component={TextInput}
                            />
                            <ErrorMessage
                                className={styles.error}
                                name="title"
                                component="div"
                            />
                            <div className={styles.selectContainer}>
                                <span className={styles.selectLabel}>
                                    Фитнес-площадка
                                </span>

                                <Select
                                    className={styles.select}
                                    isDisabled={formReadMode}
                                    styles={{
                                        input: (provided) => ({
                                            ...provided,
                                            minHeight: 40,
                                            borderRadius: '0px 0px 20px 20px',
                                        }),
                                    }}
                                    onChange={handleChangeSelectValue('gymId')}
                                    options={gymOptions}
                                    defaultValue={gymDefaultValue}
                                    value={gymDefaultValue}
                                    filterOption={createFilter({
                                        stringify: (option) =>
                                            `${option.label}`,
                                    })}
                                    placeholder="Выберите из списка"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: 'rgb(225, 129, 65)',
                                        },
                                    })}
                                    noOptionsMessage={() => 'Не найдено'}
                                />
                                <ErrorMessage
                                    className={styles.error}
                                    name="gymId"
                                    component="div"
                                />
                            </div>
                            <div className={styles.selectContainer}>
                                <span className={styles.selectLabel}>
                                    Вид тренировки
                                </span>

                                <Select
                                    className={styles.select}
                                    isMulti
                                    isDisabled={formReadMode}
                                    styles={{
                                        input: (provided) => ({
                                            ...provided,
                                            minHeight: 40,
                                            borderRadius: '0px 0px 20px 20px',
                                        }),
                                    }}
                                    onChange={handleChangeSelectValue('tags')}
                                    options={tagOptions}
                                    defaultValue={tagsDefaultValue}
                                    filterOption={createFilter({
                                        stringify: (option) =>
                                            `${option.label}`,
                                    })}
                                    placeholder="Выберите из списка"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: 'rgb(225, 129, 65)',
                                        },
                                    })}
                                    noOptionsMessage={() => 'Не найдено'}
                                />
                                <ErrorMessage
                                    className={styles.error}
                                    name="tags"
                                    component="div"
                                />
                                {Boolean(tagsCategoriesHelperItems.length) && (
                                    <div className={styles.selectHelper}>
                                        <div
                                            className={styles.selectHelperName}
                                        >
                                            Попадает в категории:
                                        </div>
                                        <div
                                            className={styles.selectHelperList}
                                        >
                                            {tagsCategoriesHelperItems.map(
                                                (itemName) => (
                                                    <div
                                                        key={itemName}
                                                        className={
                                                            styles.selectHelperItem
                                                        }
                                                    >
                                                        {itemName}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className={styles.textareaContainer}>
                                <label
                                    htmlFor="description"
                                    className={styles.label}
                                >
                                    Описание
                                </label>
                                <Field
                                    as="textarea"
                                    id="description"
                                    name="description"
                                    disabled={formReadMode}
                                    placeholder="Ваш текст"
                                    rows="6"
                                    className={styles.textarea}
                                />
                                <ErrorMessage
                                    className={styles.error}
                                    name="description"
                                    component="div"
                                />
                            </div>
                        </div>

                        {/** RIGHT COL */}
                        <div className={styles.col}>
                            <div className={styles.row}>
                                <div className={styles.col}>
                                    <Field
                                        name="groupworkoutschedule.dateStart"
                                        disabled={formReadMode || formEditMode}
                                        label="Начало периода"
                                        placeholder="ДД.ММ.ГГГГ"
                                        readOnly={true}
                                        input={{
                                            onChange: handleChangeDatePickerValue(
                                                'groupworkoutschedule.dateStart'
                                            ),
                                            value:
                                                formik.values
                                                    .groupworkoutschedule
                                                    .dateStart,
                                        }}
                                        component={SingleDatePickerInput}
                                    />
                                    <ErrorMessage
                                        className={styles.error}
                                        name="groupworkoutschedule.dateStart"
                                        component="div"
                                    />
                                </div>
                                <div className={styles.col}>
                                    <div className={styles.fieldWrapper}>
                                        <Field
                                            name="groupworkoutschedule.dateEnd"
                                            disabled={formReadMode}
                                            label="Конец периода"
                                            placeholder="ДД.ММ.ГГГГ"
                                            readOnly={true}
                                            renderClearButton={true}
                                            input={{
                                                onChange: handleChangeDatePickerValue(
                                                    'groupworkoutschedule.dateEnd'
                                                ),
                                                value:
                                                    formik.values
                                                        .groupworkoutschedule
                                                        .dateEnd,
                                            }}
                                            component={SingleDatePickerInput}
                                        />
                                        <ErrorMessage
                                            className={styles.error}
                                            name="groupworkoutschedule.dateEnd"
                                            component="div"
                                        />
                                        <Tooltip
                                            contentContainerStyles={
                                                styles.fieldDateEndTooltip
                                            }
                                            active={
                                                tooltipShown[
                                                    `groupworkoutschedule.dateEnd`
                                                ]
                                            }
                                            onClose={() =>
                                                setTooltipShown(
                                                    (prevState) => ({
                                                        ...prevState,
                                                        'groupworkoutschedule.dateEnd': false,
                                                    })
                                                )
                                            }
                                        >
                                            <p>
                                                Нельзя менять период при наличии
                                                активных бронирований
                                            </p>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.col}>
                                    <Field
                                        name="duration"
                                        label="Длительность (мин)"
                                        disabled={formReadMode}
                                        placeholder="0"
                                        styles={styles.input}
                                        input={{
                                            onChange: handleChangeFieldValue(
                                                'duration'
                                            ),
                                            value: formik.values.duration || '',
                                        }}
                                        component={TextInput}
                                    />
                                    <ErrorMessage
                                        className={styles.error}
                                        name="duration"
                                        component="div"
                                    />
                                </div>
                                <div className={styles.col}>
                                    <Field
                                        name="price"
                                        disabled={formReadMode}
                                        label="Стоимость"
                                        placeholder="0 ₽"
                                        input={{
                                            onChange: handleChangeFieldValue(
                                                'price'
                                            ),
                                            value: formik.values.price,
                                        }}
                                        component={TextInput}
                                    />
                                    <ErrorMessage
                                        className={styles.error}
                                        name="price"
                                        component="div"
                                    />
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.col}>
                                    <Field
                                        name="limit"
                                        disabled={formReadMode}
                                        label="Число участников"
                                        placeholder="0"
                                        input={{
                                            onChange: handleChangeFieldValue(
                                                'limit'
                                            ),
                                            value: formik.values.limit,
                                        }}
                                        component={TextInput}
                                    />
                                    <ErrorMessage
                                        className={styles.error}
                                        name="limit"
                                        component="div"
                                    />
                                </div>
                                <div className={styles.col}>
                                    <Field
                                        name="genderType"
                                        disabled={formReadMode}
                                        label="Для кого тренировка"
                                        options={GENDER_TYPES}
                                        input={{
                                            onChange: handleChangeFieldValue(
                                                'genderType'
                                            ),
                                            value: formik.values.genderType,
                                        }}
                                        component={SelectInput}
                                    />
                                    <ErrorMessage
                                        className={styles.error}
                                        name="genderType"
                                        component="div"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.box}>
                        <h2>Расписание занятий</h2>
                        {renderWeeklyScheduleByDay()}
                        {renderActionButtons()}
                    </div>
                </Form>
            </FormikProvider>
            {renderAutocompleteTimeModal()}
            {renderReservationsSlotsModal()}
        </>
    );
};

export default GroupWorkoutNewForm;
