import React from 'react';
import { InjectedFormProps, Field, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import Select, { createFilter, MultiValue } from 'react-select';

import TextInput from '@components/TextInput';
import Button from '@components/Button';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import SingleDateRangePickerInput from '@components/DatePickerInput';

import styles from './styles.module.css';

export interface SpecialPriceFormData {
    gymsId: string[];
    price: number;
    actionStart: Date;
    actionEnd: Date;
}

export interface Props {
    gymsOptions: {
        label: string;
        value: string;
    }[];
    setSelectedGyms: React.Dispatch<React.SetStateAction<string[]>>;
}

const validate = (values: SpecialPriceFormData) => {
    const errors: {
        price: string;
        actionStart: string;
        actionEnd: string;
    } = {
        price: '',
        actionStart: '',
        actionEnd: '',
    };

    if (!values.price) {
        errors.price = 'Обязательное поле';
    }

    if (isNaN(Number(values.price))) {
        errors.price = 'Должно быть числом';
    }

    if (!values.actionStart) {
        errors.actionStart = 'Обязательное поле';
    }

    if (!values.actionEnd) {
        errors.actionEnd = 'Обязательное поле';
    }

    return errors;
};

const AddSpecialPriceForm: React.FC<
    Props & InjectedFormProps<SpecialPriceFormData, Props>
> = ({ handleSubmit, gymsOptions, setSelectedGyms }) => {
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;

    const handleChange = (
        value: MultiValue<{ label: string; value: string }>
    ) => {
        setSelectedGyms(
            value.map((gym: { label: string; value: string }) => gym.value)
        );
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <span className={styles.label}>Фитнес-площадки</span>
            <Select
                className={styles.select}
                isMulti
                styles={{
                    input: (provided) => ({
                        ...provided,
                        minHeight: 40,
                    }),
                }}
                onChange={handleChange}
                options={gymsOptions}
                filterOption={createFilter({
                    stringify: (option) => `${option.label}`,
                })}
                closeMenuOnSelect={false}
                placeholder="Выберите фитнес-площадку"
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: 'rgb(225, 129, 65)',
                    },
                })}
                noOptionsMessage={() => 'Не найдено'}
            />

            <Field
                name="price"
                label="Специальная стоимость"
                placeholder="Специальная стоимость"
                component={TextInput}
            />

            <Field
                name="actionStart"
                label="Дата начала действия"
                placeholder="Дата начала действия"
                component={SingleDateRangePickerInput}
            />

            <Field
                name="actionEnd"
                label="Дата конца действия"
                placeholder="Дата конца действия"
                component={SingleDateRangePickerInput}
            />

            {isSuperAdmin && (
                <Button type="submit" className={styles.btn}>
                    Добавить специальную стоимость
                </Button>
            )}
        </form>
    );
};

export default reduxForm<SpecialPriceFormData, Props>({
    form: 'addSpecialPrice',
    validate,
})(AddSpecialPriceForm);
