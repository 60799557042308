import React, {
    useEffect,
    useCallback,
    useState,
    memo,
    useRef,
    SyntheticEvent,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import XLSX from 'xlsx-js-style';
import fileSaver from 'file-saver';
import moment from 'moment';

import Box from '@components/Box';
import BoxButton from '@components/BoxButton';
import { getSelectedGymId } from '@redux/modules/gyms/selectors';
import {
    fetchSubscriptions,
    fetchUserSubscriptionForReport,
    fetchUserSubscriptions,
    searchUserSubscriptions,
} from '@redux/modules/subscriptions/actions';
import {
    getSubscriptions,
    getUserSubscriptions,
} from '@redux/modules/subscriptions/selectors';
import { REPORT_HEADER_CELLS } from '@config';
import { AsyncDispatch } from '@redux/types';

import SubscriptionsListTable from './SubscriptionsListTable';
import styles from './styles.module.css';

export const SubscriptionsList: React.FC = memo(() => {
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const subscriptions = useSelector(getSubscriptions);
    const userSubscriptions = useSelector(getUserSubscriptions);
    const selectedGymId = useSelector(getSelectedGymId);
    const [generateReport, setGenerateReport] = useState<boolean>(false);
    const searchingField = useRef<HTMLInputElement>(null);

    useEffect(() => {
        dispatch(fetchSubscriptions(selectedGymId));
        dispatch(fetchUserSubscriptions(selectedGymId));
    }, [dispatch, selectedGymId]);

    const goToGoGymSubscriptions = useCallback(
        () => history.push('/dashboard/gogym-subscriptions'),
        [history]
    );

    const goToSubscriptions = useCallback(
        () => history.push('/dashboard/subscriptions'),
        [history]
    );

    const goToAddSubscriptions = useCallback(
        () => history.push('/dashboard/add-subscriptions'),
        [history]
    );

    const generateReportSubscriptionsList = () => {
        const { hide } = cogoToast.loading('Формируем отчет по подпискам...', {
            position: 'top-right',
            hideAfter: 0,
        });
        setGenerateReport(true);
        dispatch(fetchUserSubscriptionForReport())
            .then((dataForXLSX) => {
                const ws = XLSX.utils.json_to_sheet(dataForXLSX);
                for (const i of REPORT_HEADER_CELLS) {
                    if (ws[i]) {
                        ws[i].s = {
                            font: {
                                bold: true,
                            },
                        };
                    }
                }

                const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, {
                    bookType: 'xlsx',
                    type: 'array',
                });
                const data = new Blob([excelBuffer], {
                    type:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
                });
                fileSaver.saveAs(
                    data,
                    `subscriptions-list-${moment().format(
                        'DD-MM-YYYY-HH-mm'
                    )}.xlsx`
                );
                cogoToast.success('Отчет по подпискам готов', {
                    position: 'top-right',
                    hideAfter: 5,
                });
            })
            .catch(() => {
                cogoToast.error('Ошибка при формирования отчета', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            })
            .finally(() => {
                hide && hide();
                setGenerateReport(false);
            });
    };

    const handleSearchSubmit = (event: SyntheticEvent) => {
        event.preventDefault();
        const value = searchingField.current?.value;
        if (!value) dispatch(fetchUserSubscriptions(selectedGymId));
        if (value && value.length > 4)
            dispatch(searchUserSubscriptions({ value }))
                .then((userSubscriptions) => {
                    if (!userSubscriptions.length) {
                        cogoToast.error('Подписка не найдена', {
                            position: 'top-right',
                            hideAfter: 4,
                        });
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при поиске подписки', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                });
    };

    const userSubscriptionsWithSubscriptionTitle = userSubscriptions.map(
        (userSubscription) => {
            return {
                ...userSubscription,
                subscriptionTitle:
                    subscriptions.find(
                        (subscription) =>
                            subscription._id === userSubscription.subscriptionId
                    )?.title || userSubscription.subscriptionId,
            };
        }
    );

    return (
        <div>
            <h1>Подписки</h1>

            <h3>Действия</h3>
            <div className={styles.actions}>
                <BoxButton
                    icon="gym"
                    title="Добавить подписку"
                    onClick={goToAddSubscriptions}
                    className={styles.actionBtn}
                />
                <BoxButton
                    icon="spreadsheet"
                    title={
                        generateReport
                            ? 'Формируем отчет...'
                            : 'Выгрузить данные'
                    }
                    onClick={() => generateReportSubscriptionsList()}
                    className={styles.actionBtn}
                    disabled={generateReport}
                />
            </div>

            <div className={styles.subscriptionsNav}>
                <BoxButton
                    icon=""
                    title="Подписки пользователей"
                    onClick={goToSubscriptions}
                    className={styles.actionNav}
                />

                <BoxButton
                    icon=""
                    title="Подписки GOGYM"
                    onClick={goToGoGymSubscriptions}
                />

                <input
                    className={styles.input}
                    type="search"
                    placeholder="Поиск по id"
                    onChange={handleSearchSubmit}
                    ref={searchingField}
                />
            </div>

            <Box className={styles.container}>
                <div className={styles.tableWrapper}>
                    <SubscriptionsListTable
                        userSubscriptions={
                            userSubscriptionsWithSubscriptionTitle
                        }
                    />
                </div>
            </Box>
        </div>
    );
});

export default SubscriptionsList;
