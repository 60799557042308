import { AnyAction } from 'redux';

import { Tag, TagCategorie } from '@t/tag';

import {
    FETCH_TAGS_SUCCESS,
    TagState,
    FETCH_TAG_SUCCESS,
    UPDATE_TAG_SUCCESS,
    DELETE_TAG_ICON_SUCCESS,
    TagCategorieState,
    FETCH_TAG_CATEGORIES_SUCCESS,
} from './types';

const initialStateTag: TagState = {
    ids: [],
    byId: {},
    lastUpdated: 0,
};

const initialStateTagCategorie: TagCategorieState = {
    ids: [],
    byId: {},
    lastUpdated: 0,
};

export function tagReducer(
    state = initialStateTag,
    action: AnyAction
): TagState {
    if (action.type === FETCH_TAG_SUCCESS) {
        const tag = action.payload;
        const included = state.ids.includes(tag._id);
        const ids = included ? state.ids : [...state.ids, tag._id];
        const byId = {
            ...state.byId,
            [tag._id]: tag,
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === FETCH_TAGS_SUCCESS) {
        const { tags } = action.payload;
        const ids = tags.map((tag: Tag) => tag._id);
        const byId = tags.reduce(
            (total: { [id: string]: Tag }, tag: Tag) => ({
                ...total,
                [tag._id]: tag,
            }),
            {}
        );
        const lastUpdated = Date.now();

        return {
            ...state,
            ids,
            byId,
            lastUpdated,
        };
    }

    if (action.type === UPDATE_TAG_SUCCESS) {
        const tag = action.payload;
        const included = state.ids.includes(tag._id);
        const ids = included ? state.ids : [...state.ids, tag._id];
        const byId = {
            ...state.byId,
            [tag._id]: {
                ...state.byId[tag._id],
                ...tag,
            },
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === DELETE_TAG_ICON_SUCCESS) {
        const tag = action.payload;
        const included = state.ids.includes(tag._id);
        const ids = included ? state.ids : [...state.ids, tag._id];
        const byId = {
            ...state.byId,
            [tag._id]: {
                ...state.byId[tag._id],
                ...tag,
            },
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    return state;
}

export function tagCategorieReducer(
    state = initialStateTagCategorie,
    action: AnyAction
): TagCategorieState {
    if (action.type === FETCH_TAG_CATEGORIES_SUCCESS) {
        const tagCategories = action.payload;
        const ids = tagCategories.map(
            (tagCategory: TagCategorie) => tagCategory._id
        );
        const byId = tagCategories.reduce(
            (
                total: { [id: string]: TagCategorie },
                tagCategory: TagCategorie
            ) => ({
                ...total,
                [tagCategory._id]: tagCategory,
            }),
            {}
        );
        const lastUpdated = Date.now();

        return {
            ...state,
            ids,
            byId,
            lastUpdated,
        };
    }

    return state;
}
