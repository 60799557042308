import React from 'react';
import { InjectedFormProps, Field, reduxForm } from 'redux-form';

import TextInput from '@components/TextInput';
import Button from '@components/Button';
import styles from './styles.module.css';

export interface FormData {
    cancelReason: string;
}

const Form: React.FC<InjectedFormProps<FormData>> = ({ handleSubmit }) => {
    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Field
                required
                name="cancelReason"
                label="Причина отмены"
                placeholder="Недостаточное количество участников"
                component={TextInput}
            />

            <Button type="submit" className={styles.btn}>
                Отменить групповую тренировку
            </Button>
        </form>
    );
};

export default reduxForm<FormData>({
    form: 'cancelGroupWorkout',
})(Form);
