import { PartnerReport, Report } from '@t/report';

export const FETCH_REPORTS_REQUEST = 'FETCH_REPORTS_REQUEST';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_ERROR = 'FETCH_REPORTS_ERROR';

export const FETCH_REPORTS_BOOKINGS_REQUEST = 'FETCH_REPORTS_BOOKINGS_REQUEST';
export const FETCH_REPORTS_BOOKINGS_SUCCESS = 'FETCH_REPORTS_BOOKINGS_SUCCESS';
export const FETCH_REPORTS_BOOKINGS_ERROR = 'FETCH_REPORTS_BOOKINGS_ERROR';

export const FETCH_REPORTS_MEMBERSHIPS_REQUEST =
    'FETCH_REPORTS_MEMBERSHIPS_REQUEST';
export const FETCH_REPORTS_MEMBERSHIPS_SUCCESS =
    'FETCH_REPORTS_MEMBERSHIPS_SUCCESS';
export const FETCH_REPORTS_MEMBERSHIPS_ERROR =
    'FETCH_REPORTS_MEMBERSHIPS_ERROR';

export const FETCH_REPORT_REQUEST = 'FETCH_REPORT_REQUEST';
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_ERROR = 'FETCH_REPORT_ERROR';

export const GENERATE_REPORTS_REQUEST = 'GENERATE_REPORTS_REQUEST';
export const GENERATE_REPORTS_SUCCESS = 'GENERATE_REPORTS_SUCCESS';
export const GENERATE_REPORTS_ERROR = 'GENERATE_REPORTS_ERROR';

export const CONFIRM_REPORT_REQUEST = 'CONFIRM_REPORT_REQUEST';
export const CONFIRM_REPORT_SUCCESS = 'CONFIRM_REPORT_SUCCESS';
export const CONFIRM_REPORT_ERROR = 'CONFIRM_REPORT_ERROR';

export const FETCH_BILLPAID_REQUEST = 'FETCH_BILLPAID_REQUEST';
export const FETCH_BILLPAID_SUCCESS = 'FETCH_BILLPAID_SUCCESS';
export const FETCH_BILLPAID_ERROR = 'FETCH_BILLPAID_ERROR';

export const FETCH_HR_REPORTS_REQUEST = 'FETCH_HR_REPORTS_REQUEST';
export const FETCH_HR_REPORTS_SUCCESS = 'FETCH_HR_REPORTS_SUCCESS';
export const FETCH_HR_REPORTS_ERROR = 'FETCH_HR_REPORTS_ERROR';

export const FETCH_HR_REPORT_REQUEST = 'FETCH_HR_REPORT_REQUEST';
export const FETCH_HR_REPORT_SUCCESS = 'FETCH_HR_REPORT_SUCCESS';
export const FETCH_HR_REPORT_ERROR = 'FETCH_HR_REPORT_ERROR';

export const GENERATE_HR_REPORTS_REQUEST = 'GENERATE_HR_REPORTS_REQUEST';
export const GENERATE_HR_REPORTS_SUCCESS = 'GENERATE_HR_REPORTS_SUCCESS';
export const GENERATE_HR_REPORTS_ERROR = 'GENERATE_HR_REPORTS_ERROR';

export const FETCH_REPORT_ALL_BOOKINGS_REQUEST =
    'FETCH_REPORT_ALL_BOOKINGS_REQUEST';
export const FETCH_REPORT_ALL_BOOKINGS_SUCCESS =
    'FETCH_REPORT_ALL_BOOKINGS_SUCCESS';
export const FETCH_REPORT_ALL_BOOKINGS_ERROR =
    'FETCH_REPORT_ALL_BOOKINGS_ERROR';

export const FETCH_REPORT_ALL_USERS_REQUEST = 'FETCH_REPORT_ALL_USERS_REQUEST';
export const FETCH_REPORT_ALL_USERS_SUCCESS = 'FETCH_REPORT_ALL_USERS_SUCCESS';
export const FETCH_REPORT_ALL_USERS_ERROR = 'FETCH_REPORT_ALL_USERS_ERROR';

export const FETCH_REPORT_ALL_GYMS_REQUEST = 'FETCH_REPORT_ALL_GYMS_REQUEST';
export const FETCH_REPORT_ALL_GYMS_SUCCESS = 'FETCH_REPORT_ALL_GYMS_SUCCESS';
export const FETCH_REPORT_ALL_GYMS_ERROR = 'FETCH_REPORT_ALL_GYMS_ERROR';

export const FETCH_REPORT_ALL_LEGALENTITIES_REQUEST =
    'FETCH_REPORT_ALL_LEGALENTITIES_REQUEST';
export const FETCH_REPORT_ALL_LEGALENTITIES_SUCCESS =
    'FETCH_REPORT_ALL_LEGALENTITIES_SUCCESS';
export const FETCH_REPORT_ALL_LEGALENTITIES_ERROR =
    'FETCH_REPORT_ALL_LEGALENTITIES_ERROR';

export const FETCH_REPORT_ALL_CORPORATE_REQUEST =
    'FETCH_REPORT_ALL_CORPORATE_REQUEST';
export const FETCH_REPORT_ALL_CORPORATE_SUCCESS =
    'FETCH_REPORT_ALL_CORPORATE_SUCCESS';
export const FETCH_REPORT_ALL_CORPORATE_ERROR =
    'FETCH_REPORT_ALL_CORPORATE_ERROR';

export const FETCH_REPORT_ALL_ONLINE_WORKOUTS_REQUEST =
    'FETCH_REPORT_ALL_ONLINE_WORKOUTS_REQUEST';
export const FETCH_REPORT_ALL_ONLINE_WORKOUTS_SUCCESS =
    'FETCH_REPORT_ALL_ONLINE_WORKOUTS_SUCCESS';
export const FETCH_REPORT_ALL_ONLINE_WORKOUTS_ERROR =
    'FETCH_REPORT_ALL_ONLINE_WORKOUTS_ERROR';

export const FETCH_REPORT_ACCOUNTING_REQUEST =
    'FETCH_REPORT_ACCOUNTING_REQUEST';
export const FETCH_REPORT_ACCOUNTING_SUCCESS =
    'FETCH_REPORT_ACCOUNTING_SUCCESS';
export const FETCH_REPORT_ACCOUNTING_ERROR = 'FETCH_REPORT_ACCOUNTING_ERROR';

export const FETCH_REPORT_ALL_MEMBERSHIPS_REQUEST =
    'FETCH_REPORT_ALL_MEMBERSHIPS_REQUEST';
export const FETCH_REPORT_ALL_MEMBERSHIPS_SUCCESS =
    'FETCH_REPORT_ALL_MEMBERSHIPS_SUCCESS';
export const FETCH_REPORT_ALL_MEMBERSHIPS_ERROR =
    'FETCH_REPORT_ALL_MEMBERSHIPS_ERROR';

export const FETCH_REPORT_ALL_USER_SUBSCRIPTIONS_REQUEST =
    'FETCH_REPORT_ALL_USER_SUBSCRIPTIONS_REQUEST';
export const FETCH_REPORT_ALL_USER_SUBSCRIPTIONS_SUCCESS =
    'FETCH_REPORT_ALL_USER_SUBSCRIPTIONS_SUCCESS';
export const FETCH_REPORT_ALL_USER_SUBSCRIPTIONS_ERROR =
    'FETCH_REPORT_ALL_USER_SUBSCRIPTIONS_ERROR';

export const FETCH_REPORT_GYMAFON_REQUEST = 'FETCH_REPORT_GYMAFON_REQUEST';
export const FETCH_REPORT_GYMAFON_SUCCESS = 'FETCH_REPORT_GYMAFON_SUCCESS';
export const FETCH_REPORT_GYMAFON_ERROR = 'FETCH_REPORT_GYMAFON_ERROR';

export interface ReportsState {
    ids: string[];
    byId: { [id: string]: Report };
    idsBookings: string[];
    byIdBookings: { [id: string]: PartnerReport };
    idsMemberships: string[];
    byIdMemberships: { [id: string]: PartnerReport };
    lastUpdated: number;
}
