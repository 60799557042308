import React from 'react';
import { useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { signOut } from '@redux/modules/auth/actions';

import styles from './styles.module.css';

export const Verification: React.FC<{}> = () => {
    const dispatch = useDispatch();
    const onSignOutBtnClick = () => dispatch(signOut());

    return (
        <div className={styles.container}>
            <div className={styles.box}>
                <h2 className={styles.title}>Верификация</h2>
                <p className={styles.text}>
                    Ваш аккаунт находится в процесе верификации.
                    <br />
                    Пожалуйста, ожидайте ответа от администрации GOGYM.
                </p>
            </div>

            <button onClick={onSignOutBtnClick} className={styles.signOutBtn}>
                <FontAwesomeIcon
                    icon="sign-out-alt"
                    className={styles.signOutBtnIcon}
                />
                <span>Выйти из аккаунта</span>
            </button>
        </div>
    );
};
