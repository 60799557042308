import React, { useState, useEffect } from 'react';

import LeftArrow from '@components/Icons/LeftArrow';

import styles from './styles.module.css';

const ScrollToTop: React.FC = () => {
    const [isVisable, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <div
            onClick={() => scrollToTop()}
            title="Вверх"
            className={isVisable ? styles.wrap : styles.none}
        >
            <LeftArrow className={styles.iconArrow} />
        </div>
    );
};

export default ScrollToTop;
