import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';

export interface Props {
    id?: string;
    className?: string;
    type?: 'button' | 'submit';
    buttonType?: 'primary' | 'secondary';
    onClick?: any;
    disabled?: boolean;
}

const DeleteButton: React.FC<Props> = ({
    id,
    className,
    type = 'button',
    buttonType = 'primary',
    onClick,
    disabled,
}) => (
    <button
        type={type}
        disabled={disabled}
        className={classNames(className, {
            [styles.btn]: buttonType === 'primary',
            [styles.secondary]: buttonType === 'secondary',
            [styles.disabled]: disabled,
        })}
        onClick={onClick}
        id={id}
    >
        X
    </button>
);

export default DeleteButton;
