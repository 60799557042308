import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip as RechartsTooltip,
    CartesianGrid,
} from 'recharts';

import Tooltip from './Tooltip';
import XAxisTick from './XAxisTick';
import styles from './styles.module.css';

export interface Props {
    dailyUsers: { date: Date; users: number }[];
}

export const HRStatisticsChart: React.FC<Props> = ({ dailyUsers }) => {
    const data = dailyUsers;
    const daysCount = dailyUsers.length;

    return (
        <div className={styles.container}>
            <h4 className={styles.subtitle}>
                Количество тренировавшихся пользователей за {daysCount} дней:
            </h4>
            <LineChart
                width={1010}
                height={240}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" tick={XAxisTick} />
                <YAxis />
                <RechartsTooltip content={<Tooltip />} />
                <Line
                    type="monotone"
                    dataKey="users"
                    stroke="#F07C2D"
                    activeDot={{ r: 8 }}
                    strokeWidth={2}
                />
            </LineChart>
        </div>
    );
};

export default HRStatisticsChart;
