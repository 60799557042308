import axios from 'axios';
import { View as CalendarView } from 'react-big-calendar';

import { AsyncActionCreator, ReduxAction } from '../../types';
import {
    CREATE_GROUP_WORKOUT_ERROR,
    CREATE_GROUP_WORKOUT_REQUEST,
    CREATE_GROUP_WORKOUT_SUCCESS,
    DELETE_GROUP_WORKOUT_ERROR,
    DELETE_GROUP_WORKOUT_REQUEST,
    DELETE_GROUP_WORKOUT_SUCCESS,
    FETCH_GROUP_WORKOUTS_SCHEDULE_ERROR,
    FETCH_GROUP_WORKOUTS_SCHEDULE_REQUEST,
    FETCH_GROUP_WORKOUTS_SCHEDULE_SUCCESS,
    FETCH_GROUP_WORKOUT_DETAIL_ERROR,
    FETCH_GROUP_WORKOUT_DETAIL_REQUEST,
    FETCH_GROUP_WORKOUT_DETAIL_SUCCESS,
    FETCH_GROUP_WORKOUT_ERROR,
    FETCH_GROUP_WORKOUT_REQUEST,
    FETCH_GROUP_WORKOUT_SUCCESS,
    FETCH_GYM_GROUP_WORKOUTS_ERROR,
    FETCH_GYM_GROUP_WORKOUTS_REQUEST,
    FETCH_GYM_GROUP_WORKOUTS_SUCCESS,
    SET_CALENDAR_VIEW,
    UPDATE_GROUP_WORKOUT_ERROR,
    UPDATE_GROUP_WORKOUT_REQUEST,
    UPDATE_GROUP_WORKOUT_SUCCESS,
} from './types';
import { GroupWorkoutNew, GroupWorkoutNewCreate } from '@t/gym';
import { B2B_API_URL } from '@config';

export const setCalendarView = (
    payload: CalendarView
): ReduxAction<CalendarView> => ({
    type: SET_CALENDAR_VIEW,
    payload,
});

export const createNewGroupWorkout: AsyncActionCreator<
    { values: GroupWorkoutNewCreate },
    GroupWorkoutNewCreate
> = ({ values }) => async (dispatch, getState) => {
    dispatch({
        type: CREATE_GROUP_WORKOUT_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: createdGroupWorkout } = await axios.post(
            `${B2B_API_URL}/group-workouts-new`,
            {
                ...values,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: CREATE_GROUP_WORKOUT_SUCCESS,
            payload: createdGroupWorkout,
        });

        return createdGroupWorkout;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: CREATE_GROUP_WORKOUT_ERROR,
            });

            throw err;
        }
    }
};

export const updateNewGroupWorkout: AsyncActionCreator<
    { id: string; values: GroupWorkoutNewCreate },
    GroupWorkoutNewCreate
> = ({ id, values }) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_GROUP_WORKOUT_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: createdGroupWorkout } = await axios.put(
            `${B2B_API_URL}/group-workouts-new/${id}`,
            {
                ...values,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: UPDATE_GROUP_WORKOUT_SUCCESS,
            payload: createdGroupWorkout,
        });

        return createdGroupWorkout;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPDATE_GROUP_WORKOUT_ERROR,
            });

            throw err;
        }
    }
};

export const deleteNewGroupWorkout: AsyncActionCreator<
    { id: string },
    GroupWorkoutNew
> = ({ id }) => async (dispatch, getState) => {
    dispatch({
        type: DELETE_GROUP_WORKOUT_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: deletedWorkout } = await axios.delete(
            `${B2B_API_URL}/group-workouts-new/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: DELETE_GROUP_WORKOUT_SUCCESS,
            payload: deletedWorkout,
        });

        return deletedWorkout;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: DELETE_GROUP_WORKOUT_ERROR,
            });

            throw err;
        }
    }
};

export const fetchGroupWorkoutsByGymId: AsyncActionCreator<
    string,
    GroupWorkoutNew[]
> = (gymId: string) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_GYM_GROUP_WORKOUTS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: groupWorkouts } = await axios.get(
            `${B2B_API_URL}/group-workouts-new/all`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { gymId },
            }
        );

        dispatch({
            type: FETCH_GYM_GROUP_WORKOUTS_SUCCESS,
            payload: groupWorkouts,
        });

        return groupWorkouts;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_GYM_GROUP_WORKOUTS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchGroupWorkoutNew: AsyncActionCreator<
    { id?: string; timeSlotId?: string | null; date?: Date | null },
    GroupWorkoutNew
> = ({ id, timeSlotId, date }) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_GROUP_WORKOUT_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: groupWorkout } = await axios.get(
            `${B2B_API_URL}/group-workouts-new/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { timeSlotId, date },
            }
        );

        dispatch({
            type: FETCH_GROUP_WORKOUT_SUCCESS,
            payload: groupWorkout,
        });

        return groupWorkout;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_GROUP_WORKOUT_ERROR,
            });

            throw err;
        }
    }
};

export const fetchGroupWorkoutNewDetail: AsyncActionCreator<
    { id: string },
    GroupWorkoutNew
> = ({ id }) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_GROUP_WORKOUT_DETAIL_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: groupWorkout } = await axios.get(
            `${B2B_API_URL}/group-workouts-new/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_GROUP_WORKOUT_DETAIL_SUCCESS,
            payload: groupWorkout,
        });

        return groupWorkout;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_GROUP_WORKOUT_DETAIL_ERROR,
            });

            throw err;
        }
    }
};

export const cancelGroupWorkoutSlot: AsyncActionCreator<
    {
        id?: string;
        cancelledEvents: {
            cancelDate: string;
            timeSlot: string;
            reason?: string;
        }[];
    },
    GroupWorkoutNew
> = ({ id, cancelledEvents }) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_GROUP_WORKOUT_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: groupWorkout } = await axios.put(
            `${B2B_API_URL}/group-workouts-new/${id}`,
            { cancelledEvents },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_GROUP_WORKOUT_SUCCESS,
            payload: groupWorkout,
        });

        return groupWorkout;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_GROUP_WORKOUT_ERROR,
            });

            throw err;
        }
    }
};

export const fetchGymGroupWorkoutsSchedule: AsyncActionCreator<
    { gymId: string; date: string; forWeek?: boolean },
    GroupWorkoutNew[]
> = ({ gymId, date, forWeek = true }) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_GROUP_WORKOUTS_SCHEDULE_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const {
            data: { data },
        } = await axios.get(`${B2B_API_URL}/group-workouts-new/schedule`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: { gymId, date, forWeek },
        });

        dispatch({
            type: FETCH_GROUP_WORKOUTS_SCHEDULE_SUCCESS,
            payload: data,
        });

        return data;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_GROUP_WORKOUTS_SCHEDULE_ERROR,
            });

            throw err;
        }
    }
};

export const fetchGroupWorkoutSchedule: AsyncActionCreator<
    { groupWorkoutId: string; date: string; forWeek?: boolean },
    GroupWorkoutNew[]
> = ({ groupWorkoutId, date, forWeek = true }) => async (
    dispatch,
    getState
) => {
    dispatch({
        type: FETCH_GROUP_WORKOUTS_SCHEDULE_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const {
            data: { data },
        } = await axios.get(`${B2B_API_URL}/group-workouts-new/schedule`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: { groupWorkoutId, date, forWeek },
        });

        dispatch({
            type: FETCH_GROUP_WORKOUTS_SCHEDULE_SUCCESS,
            payload: data,
        });

        return data;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_GROUP_WORKOUTS_SCHEDULE_ERROR,
            });

            throw err;
        }
    }
};
