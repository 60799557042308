import React, {
    useEffect,
    useRef,
    useState,
    useCallback,
    SyntheticEvent,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cogoToast from 'cogo-toast';
import { useDebounce } from 'hooks/debounce';

import { getManagers } from '@redux/modules/managers/selectors';
import {
    fetchManagers,
    fetchScrollManagers,
    searchManagers,
} from '@redux/modules/managers/actions';
import Box from '@components/Box';
import BoxButton from '@components/BoxButton';
import { UserRole } from '@t/user';
import { getGymsList } from '@redux/modules/gyms/selectors';
import Loader from '@components/Loader';
import { getUser } from '@redux/modules/auth/selectors';
import { fetchGymsList } from '@redux/modules/gyms/actions';
import { AsyncDispatch } from '@redux/types';

import ManagerListTable from './ManagerListTable';
import styles from './styles.module.css';

export const ManagerList: React.FC = () => {
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const managers = useSelector(getManagers);
    const gyms = useSelector(getGymsList);
    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [managersIsOver, setManagersIsOver] = useState(false);
    const managerSearchingField = useRef<any>();
    const [searchValuse, setSearchValuse] = useState<string>('');

    const scrollHandler = (event: Event) => {
        if (
            (event.target as Document).documentElement.scrollHeight -
                ((event.target as Document).documentElement.scrollTop +
                    window.innerHeight) <
                500 &&
            (event.target as Document).documentElement.scrollTop > 1000
        ) {
            setFetching(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    useEffect(() => {
        setLoading(true);
        dispatch(fetchGymsList());
        dispatch(fetchManagers(0))
            .then((managers) => {
                if (managers.length < 99) {
                    setLoading(false);
                    setManagersIsOver(true);
                } else {
                    setLoading(false);
                    setManagersIsOver(false);
                }
            })
            .catch(() => {
                cogoToast.error('Ошибка при загрузке менеджеров', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            })
            .finally(() => setFetching(false));
    }, [dispatch]);

    useEffect(() => {
        if (fetching && !managersIsOver) {
            setLoading(true);
            dispatch(fetchScrollManagers(managers.length))
                .then((managers) => {
                    if (managers.length < 99) {
                        setLoading(false);
                        setManagersIsOver(true);
                    } else {
                        setLoading(false);
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при загрузке менеджеров', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => setFetching(false));
        }
    }, [dispatch, fetching]);

    const onAddManagerBtnClick = useCallback(
        () => history.push('/dashboard/add-manager'),
        [history]
    );

    const debounced = useDebounce(searchValuse, 500);

    useEffect(() => {
        if (!debounced || debounced.length < 3) {
            return;
        }
        dispatch(searchManagers(debounced))
            .then((gyms) => {
                setManagersIsOver(true);
                if (!gyms.length) {
                    cogoToast.error('Менеджер не найден', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                }
            })
            .catch(() => {
                cogoToast.error('Ошибка при поиске менеджеров', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            })
            .finally(() => setLoading(false));
    }, [debounced]);

    const handleSearchSubmit = (event: SyntheticEvent) => {
        event.preventDefault();
        const value = managerSearchingField.current?.value;
        if (!value) {
            dispatch(fetchManagers(0));
            setManagersIsOver(false);
        }
        setSearchValuse(value);
    };

    const managersWithGymsTitle = managers.map((manager) => {
        return {
            ...manager,
            gymsTitle: manager.gyms.map((gymId) => {
                const gym = gyms.find((gym) => gym._id === gymId);
                return gym ? gym.title : gymId;
            }),
        };
    });

    return (
        <div>
            {isSuperAdmin && (
                <>
                    <h1>Менеджеры</h1>
                    <h3>Действия</h3>
                    <div className={styles.actions}>
                        <BoxButton
                            icon="gym"
                            title="Добавить менеджера"
                            onClick={onAddManagerBtnClick}
                            className={styles.actionBtn}
                        />

                        <BoxButton
                            icon="spreadsheet"
                            title="Выгрузить данные"
                            onClick={() => {
                                // do nothing
                            }}
                            className={styles.actionBtn}
                        />
                    </div>
                    <input
                        className={styles.input}
                        type="search"
                        placeholder="Поиск по названию зала / email"
                        ref={managerSearchingField}
                        onChange={handleSearchSubmit}
                    />
                    {managers.length ? (
                        <Box className={styles.container}>
                            <div className={styles.tableWrapper}>
                                <ManagerListTable
                                    managers={managersWithGymsTitle}
                                    loading={loading}
                                />
                            </div>
                        </Box>
                    ) : managers.length === 0 ? null : (
                        <Loader />
                    )}
                </>
            )}
        </div>
    );
};

export default ManagerList;
