import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import Button from '@components/Button';
import TextAreaInput from '@components/TextAreaInput';

import styles from './styles.module.css';

export interface FormData {
    abortReasonText: string;
}

const Form: React.FC<InjectedFormProps<FormData>> = ({ handleSubmit }) => {
    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Field
                required
                name="abortReasonText"
                label="Введите причину отмены"
                placeholder="Недостаточное количество участников"
                component={TextAreaInput}
            />

            <Button type="submit" className={styles.btn}>
                Отменить бронирование
            </Button>
        </form>
    );
};

export default reduxForm<FormData>({
    form: 'cancelReason',
})(Form);
