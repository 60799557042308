import { CorporateDomains, User } from '@t/user';
import { ApplicationState } from '@redux/types';

export const getManagers = (state: ApplicationState): User[] =>
    state.managers.ids.map((id: string) => state.managers.byId[id]);

export const getManager = (id: string) => (state: ApplicationState): User =>
    state.managers.byId[id];

export const getCorporateDomains = (
    state: ApplicationState
): CorporateDomains[] => state.managers.corporateDomains;

export const getManagersList = (state: ApplicationState): User[] =>
    state.managers.managersList;

export const getSelectedManagerId = (state: ApplicationState): string =>
    state.managers.selectedManagerId;
