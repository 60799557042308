import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { AsyncDispatch } from '@redux/types';
import { createGym } from '@redux/modules/gyms/actions';
import BackButton from '@components/BackButton';
import { fetchLegalEntitiesList } from '@redux/modules/legal-entity/actions';
import { fetchManagersList } from '@redux/modules/managers/actions';
import { getLegalEntitiesList } from '@redux/modules/legal-entity/selectors';
import { getManagersList } from '@redux/modules/managers/selectors';

import Form from './Form';
import styles from './styles.module.css';

export const AddGym: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const legalEntitiesList = useSelector(getLegalEntitiesList);
    const managersList = useSelector(getManagersList);
    const onSubmit = useCallback(
        (values) => {
            dispatch(createGym(values))
                .then((gym) => {
                    if (gym) {
                        cogoToast.success('Фитнес площадка создана', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                        history.push('/dashboard/gyms');
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при создании фитнес-площадки', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                });
        },
        [dispatch, history]
    );

    useEffect(() => {
        dispatch(fetchLegalEntitiesList());
        dispatch(fetchManagersList());
    }, [dispatch]);

    const legalEntitysOptions = legalEntitiesList.map((legalEntity) => {
        return {
            title: legalEntity.name,
            value: legalEntity._id,
        };
    });

    const managersOptions = managersList.map((manager) => {
        return {
            title: `${manager.firstName}  -  ${manager.email}`,
            value: manager._id,
        };
    });

    return (
        <div className={styles.container}>
            <h1>Зарегистрировать нового партнера</h1>

            <BackButton title="Назад" className={styles.backBtn} />

            <Form
                onSubmit={onSubmit}
                legalEntitysOptions={legalEntitysOptions}
                managersOptions={managersOptions}
            />
        </div>
    );
};

export default AddGym;
