import { AnyAction } from 'redux';

import { PartnerReport, Report } from '@t/report';

import {
    FETCH_REPORT_SUCCESS,
    FETCH_REPORTS_SUCCESS,
    GENERATE_REPORTS_SUCCESS,
    ReportsState,
    FETCH_HR_REPORT_SUCCESS,
    FETCH_HR_REPORTS_SUCCESS,
    GENERATE_HR_REPORTS_SUCCESS,
    FETCH_REPORTS_BOOKINGS_SUCCESS,
    FETCH_REPORTS_MEMBERSHIPS_SUCCESS,
} from './types';

const initialState: ReportsState = {
    ids: [],
    byId: {},
    idsBookings: [],
    byIdBookings: {},
    idsMemberships: [],
    byIdMemberships: {},
    lastUpdated: 0,
};

export function reportReducer(
    state = initialState,
    action: AnyAction
): ReportsState {
    if (action.type === FETCH_REPORT_SUCCESS) {
        const report = action.payload;
        const included = state.ids.includes(report._id);
        const ids = included ? state.ids : [...state.ids, report._id];
        const byId = {
            ...state.byId,
            [report._id]: report,
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === FETCH_REPORTS_SUCCESS) {
        const reports = action.payload;
        const ids = reports.map((report: Report) => report._id);
        const byId = reports.reduce(
            (total: { [id: string]: Report }, report: Report) => ({
                ...total,
                [report._id]: report,
            }),
            {}
        );

        const lastUpdated = Date.now();

        return {
            ...state,
            ids,
            byId,
            lastUpdated,
        };
    }

    if (action.type === FETCH_REPORTS_BOOKINGS_SUCCESS) {
        const reports = action.payload;
        const idsBookings = reports.map((report: PartnerReport) => report._id);
        const byIdBookings = reports.reduce(
            (
                total: { [id: string]: PartnerReport },
                report: PartnerReport
            ) => ({
                ...total,
                [report._id]: report,
            }),
            {}
        );

        const lastUpdated = Date.now();

        return {
            ...state,
            idsBookings,
            byIdBookings,
            lastUpdated,
        };
    }

    if (action.type === FETCH_REPORTS_MEMBERSHIPS_SUCCESS) {
        const reports = action.payload;
        const idsMemberships = reports.map(
            (report: PartnerReport) => report._id
        );
        const byIdMemberships = reports.reduce(
            (
                total: { [id: string]: PartnerReport },
                report: PartnerReport
            ) => ({
                ...total,
                [report._id]: report,
            }),
            {}
        );

        const lastUpdated = Date.now();

        return {
            ...state,
            idsMemberships,
            byIdMemberships,
            lastUpdated,
        };
    }

    if (action.type === GENERATE_REPORTS_SUCCESS) {
        const reports = action.payload;
        const ids = reports.map((report: Report) => report._id);
        const byId = reports.reduce(
            (total: { [id: string]: Report }, report: Report) => ({
                ...total,
                [report._id]: report,
            }),
            {}
        );

        const lastUpdated = Date.now();

        return {
            ...state,
            ids,
            byId,
            lastUpdated,
        };
    }

    if (action.type === FETCH_HR_REPORT_SUCCESS) {
        const report = action.payload;
        const included = state.ids.includes(report._id);
        const ids = included ? state.ids : [...state.ids, report._id];
        const byId = {
            ...state.byId,
            [report._id]: report,
        };

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === FETCH_HR_REPORTS_SUCCESS) {
        const reports = action.payload;
        const ids = reports.map((report: Report) => report._id);
        const byId = reports.reduce(
            (total: { [id: string]: Report }, report: Report) => ({
                ...total,
                [report._id]: report,
            }),
            {}
        );

        const lastUpdated = Date.now();

        return {
            ...state,
            ids,
            byId,
            lastUpdated,
        };
    }

    if (action.type === GENERATE_HR_REPORTS_SUCCESS) {
        const reports = action.payload;
        const ids = reports.map((report: Report) => report._id);
        const byId = reports.reduce(
            (total: { [id: string]: Report }, report: Report) => ({
                ...total,
                [report._id]: report,
            }),
            {}
        );

        const lastUpdated = Date.now();

        return {
            ...state,
            ids,
            byId,
            lastUpdated,
        };
    }

    return state;
}
