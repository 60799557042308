import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import styles from './styles.module.css';

export interface Props {
    hidden?: boolean;
    label: string;
    name: string;
    type: 'email' | 'text' | 'password';
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    toggleVisibility?: boolean;
}

const CheckBoxInput: React.FC<Props & WrappedFieldProps> = ({
    input,
    meta,
    name,
    label,
    placeholder,
    required,
    hidden,
    disabled,
}) => {
    return (
        <>
            <div className={styles.wrapper} hidden={hidden}>
                <input
                    {...input}
                    type="checkbox"
                    checked={input.value}
                    onClick={() => input.onChange(!input.value)}
                    placeholder={placeholder}
                    required={required}
                    disabled={disabled}
                    className={styles.input}
                />
                <label htmlFor={name} className={styles.label}>
                    {label}
                </label>
            </div>
            {meta && meta.touched && meta.error && (
                <div className={styles.error}>{meta.error}</div>
            )}
        </>
    );
};

export default CheckBoxInput;
