import React from 'react';
import moment from 'moment';
import { TooltipProps } from 'recharts';

import styles from './styles.module.css';

export const Tooltip: React.FC<TooltipProps> = ({ payload, active, label }) => {
    if (!active || !payload || !payload.length) {
        return null;
    }

    const date = moment(label).format('DD/MM/YYYY');

    return (
        <div className={styles.container}>
            <h4>{date}</h4>
            <h2>{payload[0].value}</h2>
        </div>
    );
};

export default Tooltip;
